export const msalConfig = {
  auth: {
    clientId: "495aa30b-3dc8-474f-9e29-4ca02594f3a7",
    authority: "https://login.microsoftonline.com/a465261f-ac13-40dc-b7e9-dc2e401d8798",
    redirectUri: process.env.REACT_APP_SPA_REDIRECT_URI,
    enableSpaAuthorizationCode: true,
    postLogoutRedirectUri:  process.env.REACT_APP_SPA_REDIRECT_URI
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};


// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
