import update, { extend } from 'immutability-helper';
import { ADD_ACK_TO_ALARM, RESET_ALARM_UPDATED, UPDATE_ALARM_LIST, ALARM_PROCESSING_START, ALARM_PROCESSING_STOP,
  ADD_FILTER_TO_ALARM, SET_SYSTEM_STATUS, SET_ZABBIX_PROXIES, SET_ZABBIX_STATUS } from '../actions/alarms';

const initialState = {
  alarms: [],
  acks: {},
  filters: {},
  processing: {},
  maxeventid: 0,
  systemstatus: {
    zabbix: {
      status: true
    }
  }
};

export default function zabbixAlarms(state = initialState, action) {
  extend('$autoArray', function (value, object) {
    return object
      ? update(object, value)
      : update([], value);
  });
  switch (action.type) {
    case SET_SYSTEM_STATUS:
      return update(state,
        { systemstatus: { $set: action.data } });
    case SET_ZABBIX_PROXIES:
      return update(state,
        { systemstatus: {
          zabbix: {
            proxies: { $set: action.data }
          }
        } });
    case SET_ZABBIX_STATUS:
      return update(state,
        { systemstatus: {
          zabbix: {
            status: { $set: action.status }
          }
        } });
    case UPDATE_ALARM_LIST:
      let maxevent = 0;
      for (let i = 0; i < action.alarms.length; i++) {
        if (action.alarms[i].lastEvent !== undefined) {
          if (action.alarms[i].lastEvent.eventid > maxevent) {
            maxevent = action.alarms[i].lastEvent.eventid;
          }
        }
      }
      return update(state,
        {
          alarms: { $set: action.alarms },
          acks: { $set: action.acks },
          filters: { $set: action.filters },
          maxeventid: { $set: maxevent }
        });
    case RESET_ALARM_UPDATED:
      return update(state,
        { updated: { $set: false } });
    case ADD_ACK_TO_ALARM:
      return update(state, {
        acks: {
          [action.event]: {
            $autoArray: {
              $unshift: [{
                message: action.message,
                eventid: action.event,
                clock: Date.now() / 1000,
                acknowledgeid: Date.now()
              }]
            }
          }
        }
      });
    case ADD_FILTER_TO_ALARM:
      return update(state, {
        filters: {
          [action.event]: {
            $autoArray: {
              $unshift: [{
                comments: action.message,
                tags: action.tags
              }]
            }
          }
        }
      });
    case ALARM_PROCESSING_START:
      return update(state, {
        processing: {
          [action.trigger]: { $set: true }
        }
      });
    case ALARM_PROCESSING_STOP:
      return update(state, {
        processing: {
          $unset: [action.trigger]
        }
      });
    default:
      return state;
  }
}
