export const QUOTES_SET_LIST = 'QUOTES_SET_LIST';
export const QUOTES_ADD_QUOTE_TO_LIST = 'QUOTES_ADD_QUOTE_TO_LIST';
export const QUOTES_UPDATE_QUOTE_IN_LIST = 'QUOTES_UPDATE_QUOTE_IN_LIST';
export const QUOTES_SET_SEARCH_STRING = 'QUOTES_SET_SEARCH_STRING';
export const QUOTES_UPDATE_PREFERRED_IN_LIST = 'QUOTES_UPDATE_PREFERRED_IN_LIST';


export function setQuoteSearch(searchString){
  return {
    type: QUOTES_SET_SEARCH_STRING,
    searchString
  }
}

export function updatePreferredQuoteInList(quoteId){
  return {
    type: QUOTES_UPDATE_PREFERRED_IN_LIST,
    quoteId
  }
}

export function setQuotes(quotes){
  return {
    type: QUOTES_SET_LIST,
    quotes
  }
}

export function addQuoteToList(quote){
  return {
    type: QUOTES_ADD_QUOTE_TO_LIST,
    quote
  }
}

export function updateQuoteInList(quoteId, quoteData){
  return {
    type: QUOTES_UPDATE_QUOTE_IN_LIST,
    quoteId,
    quoteData
  }
}
