import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

 const BillArtifactModal = ({isOpen, handleDownload, modalArtifacts, toggle, billId})=>{
    return(
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          className="modal-lg"
          backdrop={"static"}
        >
          <ModalHeader toggle={toggle}>Artifacts</ModalHeader>
          <ModalBody>
                 <ul className="pl-3">
                   {
                    modalArtifacts.map((artifact)=>{
                      return <Button key={artifact.id} onClick={() =>{handleDownload(billId, artifact.id)}} outline color="primary" className="mr-3">{artifact.name}</Button>
                    })
                   }
                 </ul>
          </ModalBody>
        </Modal>
    )
}

export default BillArtifactModal;