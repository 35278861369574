import {useSelector} from "react-redux";
import {PriceListEnums} from "../utils/Constants/PriceList";
import { useCallback, useMemo } from 'react';

const getActivePriceListByType = (priceLists, priceListType) => {
  return  priceLists.find((priceList) => priceList.status.id === PriceListEnums.status.ACTIVE && priceList.type?.id === priceListType)
}

export const useGetActivePriceListByType = () => {
  const priceLists = useSelector((state) => state.priceLists.list)
  return useMemo(() => (priceListType) => getActivePriceListByType(priceLists, priceListType), [priceLists])
}

export const useIsPortalType = (priceList) => {
  return useCallback(() => {
    return priceList.form.type?.id === PriceListEnums.type.PORTAL;
  }, [priceList]);
};

export const useInDraftStatus = (priceList, isNew) => {
  return useCallback(() => {
    return priceList.form.status?.id === PriceListEnums.status.DRAFT || isNew;
  }, [priceList, isNew]);
};

export const useInDisabledStatus = (priceList) => {
  return useCallback(() => {
    return priceList.form.status?.id ===  PriceListEnums.status.DISABLED;
  }, [priceList]);
};

export const useInActiveStatus = (priceList) => {
  return useCallback(() => {
    return priceList.form.status?.id === PriceListEnums.status.ACTIVE;
  }, [priceList]);
};


