import {
  TASK_ADD_NEW_NOTE, TASK_REMOVE_NOTE,
  TASK_RESET,
  TASK_SET_AUDIT_HISTORY,
  TASK_SET_DATA,
  TASK_SET_FIELD,
  TASK_UPDATE_NOTE
} from '../actions/task';

const initialState = {
  form: {
    notes: []
  },
  original: {
    notes: []
  },
  audits: {}
};

export function task(state = initialState, action) {
  let notes;
  switch (action.type) {
    case TASK_SET_DATA:
      return {
        ...state,
        form: action.data,
        original: action.data
      };
    case TASK_RESET:
      return {
        ...initialState
      };
    case TASK_SET_FIELD:
      return {
        ...state,
        form: { ...state.form, ...action.field }
      };
    case TASK_UPDATE_NOTE:
      notes = state.form.notes || [];
      const newNotes = notes.map(note => note.id === action.note.id ? action.note : note);
      return {
        ...state,
        form: {
          ...state.form,
          notes: newNotes
        },
        original: {
          ...state.original,
          notes: newNotes
        }
      };
    case TASK_ADD_NEW_NOTE:
      return {
        ...state,
        form: {
          ...state.form,
          notes: [action.note, ...state.form.notes]
        },
        original: {
          ...state.original,
          notes: [action.note, ...state.original.notes]
        }
      };
    case TASK_REMOVE_NOTE:
      return {
        ...state,
        form: {
          ...state.form,
          notes: state.form.notes.filter(note => note.id !== action.note.id)
        },
        original: {
          ...state.original,
          notes: state.original.notes.filter(note => note.id !== action.note.id)
        }

      }
    case TASK_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;
  }
}
