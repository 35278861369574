import React, { Component } from 'react';
import { Alert, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { cancelConfirmDialog } from '../../actions/dialogs';
import { connect } from 'react-redux';
import HeadlessModal from './HeadlessModal';

class Confirmation extends Component {
    cancel = () =>{
      this.props.dispatch(cancelConfirmDialog());
      if(this.props.confirm.cancel){
        this.props.dispatch(this.props.confirm.cancel);
      }

    }

    proceed = () =>{
      this.props.dispatch(this.props.confirm.proceed);
      this.props.dispatch(cancelConfirmDialog());
    }

    render() {
      const { text, color } = this.props.confirm;
      return (
        <HeadlessModal
          open={this.props.confirm.open}
          toggle={this.cancel}
        >
          <Alert color={'light'} className={"mb-0"}>
            <h4 className="alert-heading text-warning">Are you sure?</h4>
            <p>{text}</p>
            <Row>
              <Col className={'d-flex justify-content-end'}>
                <Button size={'sm'} color="secondary" onClick={this.proceed}>Yes</Button>
                <Button className={'ml-2'} size={'sm'} color="light" onClick={this.cancel}>Cancel</Button>
              </Col>
            </Row>
          </Alert>
        </HeadlessModal>
      )
    }
}

function mapStateToProps({ dialogs }) {
  return {
    confirm: dialogs.confirm
  };
}
export default connect(mapStateToProps)(Confirmation);
