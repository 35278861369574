import { defaultErrorFeedback } from './feedback';
import { api_getProductTemplates, api_updateProductTemplate } from '../utils/Products/ProductService';

export const PRODUCT_TEMPLATES_SET = 'PRODUCT_TEMPLATES_SET'
export const PRODUCT_TEMPLATES_UPDATE_TEMPLATE = 'PRODUCT_TEMPLATES_UPDATE_TEMPLATE'
export const PRODUCT_TEMPLATES_SET_TEMPLATE = 'PRODUCT_TEMPLATES_SET_TEMPLATE'
export const PRODUCT_TEMPLATES_SET_TEMPLATE_FIELD = 'PRODUCT_TEMPLATES_SET_TEMPLATE_FIELD'



const setTemplates = (templates) =>{
  return {
    type: PRODUCT_TEMPLATES_SET,
    templates
  }
}

const updateTemplateInList = (template) =>{
  return {
    type: PRODUCT_TEMPLATES_UPDATE_TEMPLATE,
    template
  }
}

export const setProductTemplate = (template) => {
  return {
    type: PRODUCT_TEMPLATES_SET_TEMPLATE,
    template
  }
}

export const updateProductTemplateField = (field) => {
  return {
    type: PRODUCT_TEMPLATES_SET_TEMPLATE_FIELD,
    field
  }
}

export function getProductTemplates(){
  return (dispatch) => {
    return api_getProductTemplates().then(
      (result) => {
        if(result.status === 200){
          dispatch(setTemplates(result.data))
        }else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}

export function updateProductTemplate(id, data){
  return (dispatch) => {
    return api_updateProductTemplate(id, data).then(
      (result) => {
        if(result.status === 200){
          dispatch(updateTemplateInList(result.data))
          dispatch(setProductTemplate(result.data))
        }else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}

