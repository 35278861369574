import {
  FormGroup,
  Button,
  Col,
  Label,
  Input,
  Row, Card, CardBody
} from 'reactstrap';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import {
  addTesterAdGroup,
  removeTesterAdGroup,
  resetTesterADGroups,
  setTesterAdGroups
} from '../../actions/authentication';

const adGroups = [
  'Billing',
  'Developers',
  'Feasibility',
  'Finance Staff',
  'KenyaSales',
  'Legal',
  'Management',
  'Marketing',
  'NOC',
  'Portal-Order-Amendment',
  'Portal-Shifts-Admin',
  'Portal-Signoff-Technical',
  'Portal-Signoff-Business',
  'Portal-Credit-Request',
  'PreSales',
  'Sales',
  'SD Management Group',
  'Service Database Managers',
  'Service Delivery',
  'WeathermapEdit',



];

const TesterProfile = ({
  user
}) => {
  // redux
  const dispatch = useDispatch();

  const [checkedStates, setCheckedStates] = useState({});

  useEffect(() => {
    const groups = user.permissions.reduce((result, group) => {
      if(adGroups.includes(group)){
        result[group] = true
      }
      return result;
    }, {})
    setCheckedStates(groups);
    dispatch(setTesterAdGroups(Object.keys(groups)))
  }, [user.permissions])

  const handleInput = (event) => {
    setCheckedStates({
      ...checkedStates,
      [event.target.id]: event.target.checked
    })
    event.target.checked ?
      dispatch(addTesterAdGroup(event.target.id)) :
      dispatch(removeTesterAdGroup(event.target.id))

  }

  const reset = () => {
    dispatch(resetTesterADGroups())
  }

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <Row>
            <Col>
              {adGroups.map((group, index) => {
                return (
                  <FormGroup check key={index}>
                    <Label check>
                      <Input type="checkbox" id={group} onChange={handleInput} checked={Boolean(checkedStates[group])}/>{' '}
                      {group}
                    </Label>
                  </FormGroup>
                );
              })}
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Button onClick={reset} size={'sm'} block color={'secondary'}>Reset</Button>
            </Col>
          </Row>
        </CardBody>

      </Card>

    </div>
  )

};


function mapStateToProps({
  authenticationState,
}) {
  return {
    user: authenticationState.account
  };
}

export default connect(mapStateToProps)(TesterProfile);
