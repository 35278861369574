import React, { useEffect, useState } from 'react';
import FilteredTable from '../../FilteredTable';
import moment from 'moment';
import FilteredTableV2 from '../../FilteredTableV2';
import { Button, Col, Row } from 'reactstrap';
import Task from '../../../views/Task';
import HeadlessModal from '../../Modals/HeadlessModal';
import { SelectMod } from '../../Selects/SelectMod';

const TasksTable = ({
  includeColumns,
  excludeColumns,
  relatedTo,
  tasks,
  priorities,
  statusReason,
  types,
  defaultType,
  pageSize,
  withNew,
  onCreated,
  onUpdated
}) => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [groupOptionSet, setGroupOptionSet] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [typeFilter, setTypeFilter] = useState({
    value: null,
    label: 'All Types'
  });
  const [groupFilter, setGroupFilter] = useState({
    value: null,
    label: 'All Queues'
  });

  useEffect(() => {
    setGroupOptionSet(getGroupOptions())
  }, [tasks])
  const toggleTaskModal = () => {
    setShowTaskModal(prevState => !prevState);
  };
  const showTask = (task) => {
    setSelectedTask(task)
    toggleTaskModal()
  }

  const getStatusColour = (status) => {
    switch (status) {
      case 1:
        return 'success';
      case 2:
        return 'danger';
      case 0:
        return 'warning'
      default:
        return 'warning';
    }
  }

  const getTypeOptions = () => {
    let keep = {};
    return tasks.reduce((result, task) => {
      if (!keep[task.type]) {
        keep[task.type] = true;
        result.push(
          {
            value: task.type,
            label: types.options.find(x => x.value === task.type)?.label
          }
        );
      }
      return result;
    }, [{
      value: null,
      label: 'All Types'
    }]);
  }

  const getGroupOptions = () => {
    let keep = {};
    return tasks.reduce((result, task) => {
      if (task.assignedTo.data?.name && !keep[task.assignedTo.data?.name]) {
        keep[task.assignedTo.data.name] = true;
        result.push(
          {
            value: task.assignedTo.data.id,
            label: task.assignedTo.data.name
          }
        );
      }
      return result;
    }, [{
      value: null,
      label: 'All Queues'
    }]);
  }
  const onTypeFilterChange = (selected, tableProps) => {
    tableProps.column.setFilterValue(selected.value)
    setTypeFilter(selected)
  };
  const onGroupFilterChange = (selected, tableProps) => {
    tableProps.column.setFilterValue(selected.value)
    setGroupFilter(selected)
  };
  const columns = [
    {
      header: 'Subject',
      accessorKey: 'subject',
      minSize: 450
    },
    {
      header: (props) => {
        return <SelectMod
          options={groupOptionSet}
          isSearchable
          value={groupFilter}
          onChange={(selected) => onGroupFilterChange(selected, props)}
          size={'sm'}
        />
      },
      accessorKey: 'assignedTo',
      enableSorting: false,
      minSize: 200,
      cell: props => (
        <span>{props.getValue() !== null ? props.getValue().data?.name : ''}</span>
      ),
      filterFn: (row, columnId, value) => {
        return !value || row.original.assignedTo.data?.id === value
      },
    },
    {
      header: (props) => {
        return <SelectMod
          options={getTypeOptions()}
          isSearchable
          value={typeFilter}
          onChange={(selected) => onTypeFilterChange(selected, props)}
          size={'sm'}
        />
      },
      accessorKey: 'type',
      enableSorting: false,
      minSize: 200,
      cell: props => (
        <span>{props.getValue() !== null ? types.options?.find(x => x.value === props.getValue()).label : ''}</span>
      ),
      filterFn: (row, columnId, value) => {
        return !value || row.original.type === value
      },
    },
    {
      header: 'Priority',
      accessorKey: 'priority',
      cell: props => (
        <span>{props.getValue() !== null ? priorities.options?.find(x => x.value === props.getValue()).label : ''}</span>
      )

    },
    {
      header: 'Status',
      accessorKey: 'statusReason',
      cell: props => (
        <span className={`text-${getStatusColour(props.row.original.status)}`}>{statusReason.options?.find(x => x.value === props.getValue())?.label ?? ''}</span>
      )

    },
    {
      header: 'Modified',
      accessorKey: 'modifiedOn',
      cell: (props) => {
        return moment(props.getValue())
          .format('DD/MM/YYYY HH:mm:ss');
      }
    }
  ];

  const getColumns = () => {
    if(excludeColumns){
      return  columns.filter(column => !excludeColumns.includes(column.accessorKey))
    }
    return includeColumns ? columns.filter(column => includeColumns.includes(column.accessorKey)) : columns
  }
  return (
    <>
      <FilteredTableV2
        size={'sm'}
        hideFilter={true}
        data={tasks}
        columns={getColumns()}
        minRows={5}
        showPagination={tasks.length > (pageSize || 5 )}
        pageSizeOptions={[5,10, 20, 50, 100, 200]}
        defaultPageSize={pageSize || 5}
        multiSort={true}
        defaultSorted={[
         {
            id: 'modifiedOn',
            desc: true
          }]}
        noDataText={'No Tasks'}
        onRowClick={(task) => showTask(task)}
        extraButtons={(withNew || withNew === undefined) ? [
          <Button size={'sm'} color={'secondary'} onClick={() => {
            showTask(null)
          }}>New Task</Button>
        ] : []}
      />
      <HeadlessModal
        open={showTaskModal}
        size="xlg"
        toggle={toggleTaskModal}
      >
        <Row>
          <Col>
            <Task
              id={selectedTask?.id}
              defaultType={defaultType}
              relatedTo={relatedTo}
              toggleModal={toggleTaskModal}
              onCreated={onCreated}
              onUpdated={onUpdated}
            />
          </Col>
        </Row>

      </HeadlessModal>
    </>

  );
};

export default TasksTable;
