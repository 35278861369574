import {
  CASE_RESET_DATA,
  CASE_SET_DATA,
  CASE_SET_EMAILS,
  CASE_SET_NOTES,
  CASE_ADD_NEW_NOTE,
  CASE_UPDATE_NOTE,
  CASE_UPDATE_FIELD,
  CASE_SET_EMAIL,
  CASE_SET_SITES
} from '../actions/case';

const initialState = {
  searchOptions: {},
  original: {
    notes: [],
    emails: [],
    state: 0
  },
  data: {}
};

export default function caseReducer(state = initialState, action) {
  switch (action.type) {
    case CASE_UPDATE_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case CASE_RESET_DATA:
      return {
        ...state,
        original: { ...initialState.original },
        data: { ...initialState.data }
      };
    case CASE_SET_DATA:
      return {
        ...state,
        original: { ...state.original, ...action.data },
        data: { ...state.data, ...action.data }
      };
    case CASE_SET_NOTES:
      return {
        ...state,
        original: {
          ...state.original,
          notes: [...action.notes]
        }
      };
    case CASE_UPDATE_NOTE:
      const notes = state.original.notes || [];
      const newNotes = notes.map(note => note.id === action.note.id ? action.note : note);
      return {
        ...state,
        original: {
          ...state.original,
          notes: newNotes
        }
      }
    case CASE_ADD_NEW_NOTE:
      return {
        ...state,
        original: {
          ...state.original,
          notes: [action.note, ...state.original.notes]
        }
      };
    case CASE_SET_EMAILS:
      return {
        ...state,
        original: {
          ...state.original,
          emails: [...action.emails]
        }
      };
    case CASE_SET_EMAIL:
      return {
        ...state,
        original: {
          ...state.original,
          emails: state.original.emails.map((email) => (action.email.id === email.id) ? { ...email, ...action.email } : email)
        }
      };
    case CASE_SET_SITES:
      return {
        ...state,
        original: {
          ...state.original,
          customer: {
            ...state.original.customer,
            sites: action.sites
          }
        }
      };
    default:
      return state;
  }
}
