import React, { useEffect, useState, useReducer } from 'react';
import {connect} from "react-redux";
import {
  netapi_delRrGroupOnDevice,
  netapi_getNetworks,
  netapi_getRrGroups,
  netapi_setRrGroupOnDevice, netapi_updateDevice
} from "../../../actions/netapi";
import {
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
  Badge,
  CardHeader,
  CardFooter,
  FormGroup,
  Label,
  Button,
  Input
} from "reactstrap";
import {SelectMod} from "../../../components/Selects/SelectMod";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {successFeedback, errorFeedback} from "../../../actions/feedback";
import update from 'immutability-helper';
import { useNavigate, useParams } from 'react-router-dom'

const Device = ({
  delRrGroup,
  errorFeedback,
  fetchRrData,
  getNetworks,
  netapi,
  setRrGroup,
  successFeedback,
  updateDevice
}) => {

  // router
  const navigate = useNavigate();
  const { hostname } = useParams();

  useEffect(() => {
    fetchRrData();
    getNetworks();
  }, []);

  const data = netapi.devices.data.filter(d => d.hostname === hostname)[0];

  const [savingRrGroup, setSavingRrGroup] = useState(false);
  const [savingDevice, setSavingDevice] = useState(false);
  const [selectedRrGroup, setSelectedRrGroup] = useState(null);
  const [editData, setEditData] = useReducer((state, action) => {
    return update(state,{
      [action.name]: {$set: action.value}
    });
  }, data);

  if(netapi.devices.loading !== 0 || netapi.devices.data.length === 0) {
    return (<Spinner/>);
  }

  const deviceTypes = [
    {name: 'Core (No MPLS)', value: 'core_nompls'},
    {name: 'Core', value: 'core'},
    {name: 'VRR', value: 'vrr'},
    {name: 'Customer', value: 'customer'}
  ];

  const tableTypes = [
    {name: 'Full', value: 'full'},
    {name: 'Partial', value: 'partial'},
    {name: 'Default Only', value: 'defaultonly'},
    {name: 'None', value: 'none'}
  ];

  const snmpVersions = [
    {name: '2', value: 2},
    {name: '3', value: 3}
  ];

  const authTypes = [
    {name: 'None', value: 'none'},
    {name: 'MD5', value: 'md5'},
    {name: 'SHA', value: 'sha'}
  ];

  const privTypes = [
    {name: 'None', value: 'none'},
    {name: 'DES', value: 'des'},
    {name: 'AES', value: 'aes'}
  ];

  return (
    <Row>
      <Col md={6}>
        <Card className="w-100">
          <CardHeader>{data.hostname} Data</CardHeader>
          <CardBody>
            <FormGroup>
              <Label>Network</Label>
              <SelectMod
                isDisabled={data.rr_group_membership !== null}
                options={netapi.networks.data}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
                defaultValue={editData.network}
                onChange={(v) => {setEditData({name: 'network', value: v})}}
              />
            </FormGroup>
            <FormGroup>
              <Label>Hostname</Label>
              <Input type={"text"} name={"hostname"} value={editData.hostname} onChange={(e) => {
                setEditData({name: e.target.name, value: e.target.value});
              }}></Input>
            </FormGroup>
            <FormGroup>
              <Label>IP Address</Label>
              <Input type={"text"} name={"ipaddr"} value={editData.ipaddr} onChange={(e) => {
                setEditData({name: e.target.name, value: e.target.value});
              }}></Input>
            </FormGroup>
            <FormGroup>
              <Label>Vendor</Label>
              <Input type={"text"} name={"vendor"} value={editData.vendor} onChange={(e) => {
                setEditData({name: e.target.name, value: e.target.value});
              }}></Input>
            </FormGroup>
            <Card>
              <CardBody>
            <FormGroup>
              <Label>SNMP Version</Label>
              <SelectMod
                options={snmpVersions}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.value}
                defaultValue={snmpVersions.filter(d => d.value === data.snmp_version)[0]}
                onChange={(v) => {setEditData({name: 'snmp_version', value: v.value})}}
              />
            </FormGroup>
            {editData.snmp_version === 2 &&
              <FormGroup>
                <Label>SNMP Community</Label>
                <Input type={"text"} name={"snmp_user"} value={editData.snmp_user} onChange={(e) => {
                  setEditData({name: e.target.name, value: e.target.value});
                }}></Input>
              </FormGroup>
            }
            {editData.snmp_version === 3 &&
              <>
              <FormGroup>
                <Label>SNMP Username</Label>
                <Input type={"text"} name={"snmp_user"} value={editData.snmp_user} onChange={(e) => {
                  setEditData({name: e.target.name, value: e.target.value});
                }}></Input>
              </FormGroup>
                <FormGroup>
                  <Label>Auth Type</Label>
                  <SelectMod
                    options={authTypes}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.value}
                    defaultValue={authTypes.filter(d => d.value === data.snmp_auth_type)[0]}
                    onChange={(v) => {setEditData({name: 'snmp_auth_type', value: v.value})}}
                  />
                </FormGroup>
                {editData.snmp_auth_type !== 'none' &&
                  <FormGroup>
                    <Label>Auth Password</Label>
                    <Input type={"text"} name={"snmp_auth_pass"} value={editData.snmp_auth_pass} onChange={(e) => {
                      setEditData({name: e.target.name, value: e.target.value});
                    }}></Input>
                  </FormGroup>
                }
                <FormGroup>
                  <Label>Privacy Type</Label>
                  <SelectMod
                    options={privTypes}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.value}
                    defaultValue={privTypes.filter(d => d.value === data.snmp_priv_type)[0]}
                    onChange={(v) => {setEditData({name: 'snmp_priv_type', value: v.value})}}
                  />
                </FormGroup>
                {editData.snmp_priv_type !== 'none' &&
                  <FormGroup>
                    <Label>Privacy Password</Label>
                    <Input type={"text"} name={"snmp_priv_pass"} value={editData.snmp_priv_pass} onChange={(e) => {
                      setEditData({name: e.target.name, value: e.target.value});
                    }}></Input>
                  </FormGroup>
                }
              </>
            }
              </CardBody>
            </Card>
            <FormGroup>
              <Label>Country Code</Label>
              <Input type={"text"} name={"country_code"} value={editData.country_code} onChange={(e) => {
                setEditData({name: e.target.name, value: e.target.value});
              }}></Input>
            </FormGroup>
            <FormGroup>
              <Label>Router ID</Label>
              <Input type={"text"} name={"router_id"} value={editData.router_id} onChange={(e) => {
                setEditData({name: e.target.name, value: e.target.value});
              }}></Input>
            </FormGroup>
            <FormGroup>
              <Label>Latitude</Label>
              <Input type={"text"} name={"lat"} value={editData.lat} onChange={(e) => {
                setEditData({name: e.target.name, value: e.target.value});
              }}></Input>
            </FormGroup>
            <FormGroup>
              <Label>Longitude</Label>
              <Input type={"text"} name={"lon"} value={editData.lon} onChange={(e) => {
                setEditData({name: e.target.name, value: e.target.value});
              }}></Input>
            </FormGroup>
            <FormGroup>
              <Label>Device Type</Label>
              <SelectMod
                options={deviceTypes}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.value}
                defaultValue={deviceTypes.filter(d => d.value === data.dtype)[0]}
                onChange={(v) => {setEditData({name: 'dtype', value: v.value})}}
              />
            </FormGroup>
            <FormGroup>
              <Label>BGP Table Type</Label>
              <SelectMod
                options={tableTypes}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.value}
                defaultValue={tableTypes.filter(d => d.value === data.bgp_table_type)[0]}
                onChange={(v) => {setEditData({name: 'bgp_table_type', value: v.value})}}
              />
            </FormGroup>
          </CardBody>
          <CardFooter className="text-right">
            <LaddaButton
              className="btn btn-primary btn-ladda px-4"
              loading={savingDevice}
              data-color="blue"
              data-style={ZOOM_OUT}
              disabled={editData === data}
              onClick={() => {
                setSavingDevice(true);
                editData.net = editData.network.id;
                updateDevice(data.hostname, editData).then((r) => {
                  setSavingDevice(false);
                  successFeedback("Device Updated");
                }).catch((e) => {
                  setSavingDevice(false);
                  errorFeedback("Could not update device.");
                });
              }}
            >Save</LaddaButton>
          </CardFooter>
        </Card>
      </Col>
      <Col md={6}>
        {netapi.devices.data.filter((d) => d.dtype === 'vrr' && d.network.id === data.network.id).length > 0 &&
        data.dtype !== 'vrr' &&
        <Card className="w-100">
          <CardHeader>Route Reflector Group</CardHeader>
          <CardBody>
            <FormGroup>
              <Label>Group</Label>
              <SelectMod
                options={netapi.rrgroups.data}
                getOptionLabel={(o) => o.group_name}
                getOptionValue={(o) => o.id}
                defaultValue={data.rr_group_membership?.rrgroup}
                onChange={(val) => setSelectedRrGroup(val)}
              />
            </FormGroup>
          </CardBody>
          <CardFooter className="text-right">
            <Button color={"danger"}
                    disabled={data.rr_group_membership === null}
                    onClick={() => {
              delRrGroup(data.rr_group_membership.rrgroup.id, data.id).then((r) => {
                navigate('/toolbox/devices');
              }).catch((e) => errorFeedback("Could not remove from group."));
            }
            }>Remove Group</Button>
            {' '}
            <LaddaButton
              className="btn btn-primary btn-ladda px-4"
              loading={savingRrGroup}
              data-color="blue"
              data-style={ZOOM_OUT}
              disabled={selectedRrGroup === null || data.rr_group_membership?.rrgroup.id === selectedRrGroup?.id}
              onClick={() => {
                setSavingRrGroup(true);
                if(data.rr_group_membership === null) {
                  setRrGroup(selectedRrGroup.id, data.id, false, false).then((res) => {
                    setSavingRrGroup(false);
                    successFeedback("RR Group Saved")
                  });
                }
                else {
                  delRrGroup(data.rr_group_membership.rrgroup.id, data.id).then((res) => {
                    if(res.status === 204) {
                      setRrGroup(selectedRrGroup.id, data.id, false, false).then((res) => {
                        setSavingRrGroup(false);
                        successFeedback("RR Group Saved");
                      }).catch((err) => {
                        setSavingRrGroup(false);
                        errorFeedback("Could not add new group membership. " + err.data.message)
                      });
                    }
                    else {
                      errorFeedback("Could not remove old RR Group membership");
                      setSavingRrGroup(false);
                    }
                  }).catch((err) => {
                    errorFeedback("Couldn't do it :(");
                    setSavingRrGroup(false);
                  });
                }
              }}
            >
              Save Group
            </LaddaButton>
          </CardFooter>
        </Card>
        }
      </Col>
    </Row>
  );
};

const mapStateToProps = ({netapi}) => ({netapi});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRrData: () => dispatch(netapi_getRrGroups()),
    setRrGroup: (group, dev, is_primary, is_secondary) => dispatch(netapi_setRrGroupOnDevice(group, dev, is_primary, is_secondary)),
    delRrGroup: (group, id) => dispatch(netapi_delRrGroupOnDevice(group, id)),
    successFeedback: (message) => dispatch(successFeedback(message)),
    errorFeedback: (message) => dispatch(errorFeedback(message)),
    getNetworks: () => dispatch(netapi_getNetworks()),
    updateDevice: (device, data) => dispatch(netapi_updateDevice(device, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Device);
