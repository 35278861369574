import {
  LINE_ITEM_RESET_DATA,
  LINE_ITEM_SET_DATA,
  LINE_ITEM_SET_FORM_DATA,
  LINE_ITEM_UPDATE_FORM_FIELD
} from '../actions/lineItem';

const initialState = {
  lineItem: {},
  lineItemForm: {}
};

export default function lineItem(state = initialState, action) {
  switch (action.type) {
    case LINE_ITEM_SET_DATA:
      return {
        ...state,
        lineItem: { ...state.lineItem, ...action.lineItem }
      };
    case LINE_ITEM_RESET_DATA:
      return { ...initialState };
    case LINE_ITEM_SET_FORM_DATA:
      return {
        ...state,
        lineItemForm: { ...state.lineItemForm, ...action.lineItemForm }
      };
    case LINE_ITEM_UPDATE_FORM_FIELD:
      return {
        ...state,
        lineItemForm: {
          ...state.lineItemForm,
          [action.field]: action.value
        }
      };
    default:
      return state;
  }
}
