import React, { useState } from 'react';
import { Badge, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom'
import FilteredTableV2 from '../../FilteredTableV2';
import { ButtonIcon } from '../../ButtonIcon';
import moment from 'moment/moment';
import isEqual from 'lodash.isequal';

const RacksTable = ({
  table,
  fetchData,
  racks
}) => {

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({pageIndex : 0, pageSize: 20})
  const [sorting, setSorting] = useState([{ id: 'updatedAt', desc: true }])

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Location',
      accessorKey: 'site.name'
    },
    {
      header: 'Rack Units',
      accessorKey: 'size'
    },
    {
      header: 'hSo Managed',
      accessorKey: 'hsoManaged',
      cell: props => (
        <Badge style={{minWidth: '40px'}} className={`p-2 faded-${props.getValue() ? 'success': 'secondary'}`}>{props.getValue() ? 'Yes' : 'No'}</Badge>
      )
    },
    {
      header: 'Updated At',
      accessorKey: 'updatedAt',
      cell: (props) => moment(props.getValue()).format('DD/MM/YYYY HH:mm:ss')
    },
  ]

  const onSorting = (sortingState, searchString) => {
    const fixedSorting = getSorting(sortingState)
    if(!isEqual(fixedSorting, sorting)){
      fetchData(pagination.pageIndex + 1, pagination.pageSize, searchString,fixedSorting )
      setSorting(fixedSorting)
    }
  }

  const getSorting = (sortingState) => {
    return sortingState.map(sorting => {
      if(sorting.id === 'site_name'){
        return {
          ...sorting,
          id: 'sites|sites.name'
        }
      }
      return sorting
    })
  }

  return (
    <>
      <FilteredTableV2
        withServerSideFilter={(searchString) => {
          fetchData(1, pagination.pageSize, searchString, getSorting(sorting))
        }}
        minRows={pagination.pageSize}
        data={racks}
        columns={columns}
        onFetchData={(page, size, searchString) => {
          fetchData(page, size, searchString, getSorting(sorting))
        }}
        defaultSorted={sorting}
        pages={table.lastPage}
        pageSize={table.perPage}
        onPaginationChange={setPagination}
        pagination={pagination}
        showPagination
        defaultPageSize={20}
        onRowClick={(rack) => navigate('/sdb/racks/' + rack.id)}
        extraButtons={[
          <Button size={'sm'} color={'secondary'} onClick={() => {
            navigate('/sdb/racks/new')
          }}>New Rack</Button>,
          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={() => fetchData(pagination.pageIndex + 1, pagination.pageSize, '', getSorting(sorting))}/>
        ]}
        withServerSideSorting
        onSorting={(sorting, searchString) => onSorting(sorting, searchString)}
      />
    </>
  )
}

export default RacksTable
