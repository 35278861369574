import { defaultErrorFeedback } from './feedback';
import { api_getUserTasks } from '../utils/Task';

export const TASKS_SET_LIST = 'TASKS_SET_LIST';
export const TASKS_REMOVE_TASK = 'TASKS_REMOVE_TASK';
export const TASKS_UPDATE_TASK = 'TASKS_UPDATE_TASK';



export function updateTaskInList (task) {
  return {
    type: TASKS_UPDATE_TASK,
    task
  }
}

export function removeTaskFromList (task) {
  return {
    type: TASKS_REMOVE_TASK,
    task
  }
}

export function getUserTasks(status) {
  return (dispatch) => {
    return api_getUserTasks(status)
      .then((result) => {
        if (result.status === 200) {
          dispatch({type: TASKS_SET_LIST, list: result.data});
          return true;
        }
        dispatch(defaultErrorFeedback())
        return false;
      });
  };
}
