import {
  addNewDomainBlock,
  createDomain,
  deleteDomain,
  getDomain, removeDomainBlock,
  resetDomain,
  setDomainField,
  updateDomain, updateDomainBlock
} from '../../../../actions/ServiceDB/Ipam/domain';
import GenericCardForm from '../../../../components/GenericCardForm';
import { Col, Row } from 'reactstrap';
import SiteService from '../../../../utils/Site/SiteService';
import { connect, useDispatch } from 'react-redux'
import form from './form';
import Blocks from './Blocks';
import { useCallback, useEffect, useMemo, useState } from 'react'
import { addBreadcrumbs, resetBreadcrumbs } from '../../../../actions/breadcrumbs';
import { canAccessServiceDb } from '../../../../utils/Auth/AuthService';
import { useNavigate, useParams } from 'react-router-dom'

const Domain = ({ id = null, data, original }) => {

  // router
  const navigate = useNavigate();
  const params = useParams();

  // redux
  const dispatch = useDispatch();


  const [loading, setLoading] = useState(false);

  const domainId = useMemo(() => (id || params.domainId), [id, params.domainId])
  const isNew = useMemo(() => domainId === 'new' && !original.id, [domainId, original.id])

  useEffect(() => {
    if (original.name) {
      dispatch(addBreadcrumbs([{ name: original.name }]))
    }

    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [original.name]);

  const aSyncOptions = {
    site: (search) => SiteService.list([], [
      { id: 'id', value: search },
      { id: 'name', value: search }
    ], [], 'or', [], true)
      .then((result) => result.data || [])
  };

  const requestStructure = (data) => ({
    name: data.name,
    ownerId: data.owner?.accountnumber
  });

  const fetch = () => {
    setLoading(true);
    dispatch(getDomain(domainId, ['owner', 'blocks']))
      .then(() => setLoading(false));
  }

  return (
    <Row>
      <Col lg={6}>
        <GenericCardForm
          title={data.name || 'New Domain'}
          isNew={isNew}
          form={form}
          data={data}
          original={original}
          aSyncOptions={aSyncOptions}
          requestStructure={requestStructure}
          onFetch={fetch}
          onReset={() => dispatch(resetDomain())}
          setField={(field, value) => dispatch(setDomainField(field, value))}
          onCreate={(toCreate) => dispatch(createDomain(toCreate, ['owner', 'blocks']))}
          onCreated={(result) => navigate(`/sdb/ipam/domains/${result.id}`)}
          onUpdate={(toUpdate) => dispatch(updateDomain(domainId, toUpdate, ['owner', 'blocks']))}
          onDelete={() => dispatch(deleteDomain(domainId))}
          onDeleted={() => navigate('/sdb/ipam/domains')}
          canEdit={canAccessServiceDb()}
          deleteButtonDisabled={original.blocks.length > 0}
          deleteButtonDisabledTooltip="Domain has existing blocks"
        />
      </Col>
      {!isNew ? (
        <Col lg={6}>
          <Blocks
            blocks={original.blocks}
            loading={loading}
            domain={original}
            onCreated={(data) => dispatch(addNewDomainBlock(data))}
            onUpdated={(data) => dispatch(updateDomainBlock(data))}
            onDeleted={(id) => dispatch(removeDomainBlock(id))}
            canAddAndDelete={canAccessServiceDb()}
          />
        </Col>
      ) : ''}
    </Row>
  );
};

function mapStateToProps({ ipamDomain, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    data: ipamDomain.data,
    original: ipamDomain.original,
  };
}

export default connect(mapStateToProps)(Domain);
