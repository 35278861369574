import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button } from 'reactstrap';
import { ackAlarm } from '../../actions/alarms';
import { connect } from 'react-redux';

class AckModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ackmsg: '',
      messageinvalid: false
    };
    this.handleAck = this.handleAck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleParentUpdate = this.handleParentUpdate.bind(this);
  }

  handleParentUpdate() {
    this.props.parentSetState({ modal: false });
  }

  handleChange(event) {
    this.setState({ ackmsg: event.target.value });
  }

  handleAck() {
    const badPhrases = [
      'looking in to this',
      'looking at this',
      'looking into this',
      'known issue'
    ];

    if ((this.state.ackmsg.length <= 20 && this.state.ackmsg.match(/a[\s\t]{0,}c[\s\t]{0,}k/i)) || badPhrases.includes(this.state.ackmsg.toLowerCase())) {
      this.setState({ messageinvalid: true });
    } else {
      this.props.dispatch(ackAlarm(this.props.event, this.props.trigger, this.state.ackmsg));
      this.setState({
        ackmsg: '',
        messageinvalid: false
      });
      this.props.parentSetState({ modal: false });
    }
  }

  render() {
    return (
            <Modal isOpen={this.props.show} className={this.props.className}>
                <ModalHeader>Acknowledge Event</ModalHeader>
                <ModalBody>
                    <p>Ack the alarm below. Make sure you put something meaningful in the box... none of this "ack", "working on it", etc crap. Also if you put "Craig is working on it" you should have actually checked he is, and has seen the alarm!</p>
                    <Form>
                        <FormGroup>
                            <Label for="message">Enter Message:</Label>
                            <Input type="textarea" name="message" id="message" invalid={this.state.messageinvalid} onChange={this.handleChange} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.handleAck}>Acknowledge</Button>{' '}
                    <Button color="secondary" onClick={this.handleParentUpdate}>Cancel</Button>
                </ModalFooter>
            </Modal>
    );
  }
}

export default connect()(AckModal);
