import { apiRequest, netApiRequest } from '../Api/ApiRequest';

export function getComplianceDevices() {
  const url = '/compliance';
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function getComplianceDevice(hostname) {
  const url = '/compliance/' + hostname;
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function addComplianceDeviceException(hostname, port, protocol, reason) {
  const url = '/compliance/' + hostname + '/portexceptions';
  return netApiRequest({
    url,
    method: 'post',
    data: { port_num: port, protocol, reason }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function deleteComplianceDeviceException(hostname, port, protocol) {
  const url = '/compliance/' + hostname + '/portexceptions';
  const data = { port_num: port, protocol };
  return netApiRequest({
    url,
    method: 'delete',
    data
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function getConfigBackups() {
  const url = '/config';
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function searchConfigBackups(query) {
  const url = '/config/search?query=' + query;
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function getConfigBackupHost(hostname) {
  const url = '/config/' + hostname;
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function getConfigBackupIteration(hostname, iter) {
  const url = '/config/' + hostname + '/' + iter;
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function api_getServiceErrors() {
  return apiRequest({
    url: `/compliance/services`,
    method: 'get',
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_runServiceCheck(id) {
  return apiRequest({
    url: `/compliance/services/${id}`,
    method: 'post'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getServiceCheck(id) {
  return apiRequest({
    url: `/compliance/services/${id}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function getShadowServerInfo() {
  const url = '/shadowserver';
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function getShadowServerData(date, ips) {
  const url = '/shadowserver/search';
  return netApiRequest({
    url,
    method: 'post',
    data: {
      date,
      ips
    }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}


export function getCveForCpe(cpe) {
  const url = '/vulnmgmt/cpesearch';
  return netApiRequest({
    url,
    method: 'post',
    data: {
      cpe
    }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}