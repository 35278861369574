import { LIVETRAFFICDATA, RESETLIVETRAFFICDATA } from '../actions/realtimeTraffic';
import Ring from 'ringjs';
import { TimeEvent } from 'pondjs';
import update from 'immutability-helper';

const initialState = {
  data: new Ring(200)
};

export default function realtimeTraffic(state = initialState, action) {
  switch (action.type) {
    case RESETLIVETRAFFICDATA:
      return update(state,
        {
          data: { $set: new Ring(200) }
        });
    case LIVETRAFFICDATA:
      const newEvents = state.data;
      let event = new TimeEvent(new Date(action.t * 1000), action);
      newEvents.push(event);
      return update(state,
        {
          data: { $set: newEvents }
        });
    default:
      return state;
  }
}
