import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Input, Table } from 'reactstrap';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { IPv4, IPv6 } from 'ip-toolkit'

class IpTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    let ipaddr = event.target.elements.prefix.value;
    let mask = event.target.elements.prefixlen.value;

    const ip = IPv4.isValidIP(ipaddr) ? IPv4 : IPv6
    const detail = ip.parseCIDR(ipaddr + '/' + mask);
    const realLast = ip.long2ip(ip.ip2long(detail.lastHost) - 1);
    this.setState({
      data: { ...detail, realLast }
    });
  }

  render() {
    return (
            <div>
                <Row>
                    <Col md={4}>
                        <Card className="card-accent-info">
                            <CardHeader>Enter Details</CardHeader>
                            <CardBody>
                                <Form inline onSubmit={this.handleSubmit} id="add-prefix-form" style={{ marginBottom: '20px' }}>
                                    <FormGroup>
                                        <Input type="text" name="prefix" id="prefix" placeholder="Prefix" />
                                    </FormGroup>
                                    <FormGroup>
                                        <span className="input-group-addon">/</span>
                                        <Input type="text" name="prefixlen" id="prefixlen" placeholder="Len" style={{ width: '52px' }}/>
                                    </FormGroup>
                                    <LaddaButton
                                        className="btn btn-primary btn-ladda px-4"
                                        loading={this.state.loading}
                                        data-color="blue"
                                        data-style={ZOOM_OUT}
                                    >
                                        Generate Table
                                    </LaddaButton>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    {this.state.data.networkAddress !== undefined
                        && <Col md={4}>
                            <Card className="card-accent-info">
                                <CardHeader>IP Address Allocation</CardHeader>
                                <CardBody>
                                    <Table bordered>
                                        <tbody>
                                            <tr><th>Network</th><td>{this.state.data.networkAddress}</td></tr>
                                            <tr><th>Subnet Mask</th><td>{this.state.data.subnetMask}</td></tr>
                                            <tr><th>Gateway</th><td>{this.state.data.lastHost}</td></tr>
                                            <tr><th>IP Addresses From</th><td>{this.state.data.firstHost}</td></tr>
                                            <tr><th>IP Addresses To</th><td>{this.state.data.realLast}</td></tr>
                                            <tr><th>DNS Servers</th><td>217.196.224.136<br/>217.196.225.136</td></tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    }
                </Row>
            </div>
    );
  }
}

export default IpTable;
