import { deepFreeze } from '../Helpers/DeepFreeze';

export const LeadEnums = deepFreeze({
  status: {
    OPEN: 0,
    QUALIFIED: 1,
    DISQUALIFIED: 2
  }
});


