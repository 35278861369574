import { ConnectionService } from '../utils/ServiceDB/Connection';
import { defaultErrorFeedback } from './feedback';
import { ConnectionComponentService } from '../utils/ServiceDB/ConnectionComponent';
import { CrossConnectService } from '../utils/ServiceDB/CrossConnect';
import { CarrierCircuitService } from '../utils/ServiceDB/CarrierCircuit';
import { PatchLeadService } from '../utils/ServiceDB/PatchLead';
import { WavelengthService } from '../utils/ServiceDB/Wavelength';
import { LogicalServiceService } from '../utils/ServiceDB/LogicalService';
import AuditService from '../utils/Audit';

export const CONNECTION_SET_DATA = 'CONNECTION_SET_DATA';
export const CONNECTION_SET_FIELD = 'CONNECTION_SET_FIELD';
export const CONNECTION_RESET_DATA = 'CONNECTION_RESET_DATA';
export const CONNECTION_ADD_COMPONENT = 'CONNECTION_ADD_COMPONENT';
export const CONNECTION_UPDATE_COMPONENT = 'CONNECTION_UPDATE_COMPONENT';
export const CONNECTION_REMOVE_COMPONENT = 'CONNECTION_REMOVE_COMPONENT';
export const CONNECTION_MOVE_COMPONENT = 'CONNECTION_MOVE_COMPONENT';
export const CONNECTION_SET_COMPONENT_BY_TYPE_AND_ID = 'CONNECTION_UPDATE_COMPONENT_BY_TYPE_AND_ID';
export const CONNECTION_PERSIST_COMPONENT = 'CONNECTION_PERSIST_COMPONENT';
export const CONNECTION_DESTROY_COMPONENT = 'CONNECTION_DESTROY_COMPONENT';
export const CONNECTION_ADD_SERVICES = 'CONNECTION_ADD_SERVICES';
export const CONNECTION_ADD_SERVICE = 'CONNECTION_ADD_SERVICE';
export const CONNECTION_UPDATE_SERVICE = 'CONNECTION_UPDATE_SERVICE';
export const CONNECTION_REMOVE_SERVICE = 'CONNECTION_REMOVE_SERVICE';
export const CONNECTION_RESET_SERVICES = 'CONNECTION_RESET_SERVICES';
export const CONNECTION_SET_AUDIT_HISTORY = 'CONNECTION_SET_AUDIT_HISTORY';
export const CONNECTION_ADD_NOTE = 'CONNECTION_ADD_NOTE';
export const CONNECTION_UPDATE_NOTE = 'CONNECTION_UPDATE_NOTE';
export const CONNECTION_REMOVE_NOTE = 'CONNECTION_REMOVE_NOTE';

export function addConnectionNote(note) {
  return {
    type: CONNECTION_ADD_NOTE,
    note
  };
}

export function updateConnectionNote(note) {
  return {
    type: CONNECTION_UPDATE_NOTE,
    note
  };
}

export function removeConnectionNote(note) {
  return {
    type: CONNECTION_REMOVE_NOTE,
    note
  };
}

export function setConnectionDetail(data, mergeComponents = false) {
  return {
    type: CONNECTION_SET_DATA,
    data,
    mergeComponents
  };
}

export function addConnectionComponent(data) {
  return {
    type: CONNECTION_ADD_COMPONENT,
    data
  };
}

export function setConnectionComponent(id, data) {
  return {
    type: CONNECTION_UPDATE_COMPONENT,
    id,
    data
  };
}

export function persistConnectionComponent(id, data) {
  return {
    type: CONNECTION_PERSIST_COMPONENT,
    id,
    data
  };
}

export function setConnectionComponentByComponent(componentType, data) {
  return {
    type: CONNECTION_SET_COMPONENT_BY_TYPE_AND_ID,
    componentType,
    data
  };
}

export function removeConnectionComponent(id) {
  return {
    type: CONNECTION_REMOVE_COMPONENT,
    id
  };
}

export function moveConnectionComponent(direction, id) {
  return {
    type: CONNECTION_MOVE_COMPONENT,
    direction,
    id
  };
}

export function destroyConnectionComponent(id) {
  return {
    type: CONNECTION_DESTROY_COMPONENT,
    id
  };
}

export function setConnectionField(field, value) {
  return {
    type: CONNECTION_SET_FIELD,
    field,
    value
  };
}

export function resetConnection() {
  return {
    type: CONNECTION_RESET_DATA
  };
}

export function addNewConnectionLogicalServices(logicalServices) {
  return {
    type: CONNECTION_ADD_SERVICES,
    logicalServices
  };
}

export function addNewConnectionLogicalService(serviceType, serviceData) {
  return {
    type: CONNECTION_ADD_SERVICE,
    serviceType,
    serviceData
  };
}

export function updateConnectionLogicalService(serviceType, serviceData) {
  return {
    type: CONNECTION_UPDATE_SERVICE,
    serviceType,
    serviceData
  };
}

export function removeConnectionLogicalService(serviceType, serviceId) {
  return {
    type: CONNECTION_REMOVE_SERVICE,
    serviceType,
    serviceId
  };
}

export function resetConnectionLogicalServices() {
  return {
    type: CONNECTION_RESET_SERVICES
  };
}

export function getConnection(id, includes, withs, portWiths, nniWiths) {
  return (dispatch) => {
    return ConnectionService.getConnection(id, includes, withs, portWiths, nniWiths).then((result) => {
      if (result.status === 200) {
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createConnection(args, include = [], withs, portWiths, nniWiths) {
  return (dispatch) => {
    return ConnectionService.createConnection(args, include, withs, portWiths, nniWiths).then((result) => {
      if (result.status === 200) {
        dispatch(setConnectionDetail(result.data, true));
        return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      }else {
        dispatch(defaultErrorFeedback());
      }
      return false;
    });
  };
}

export function updateConnection(id, args, include = [], withs, portWiths, nniWiths) {
  return (dispatch) => {
    return ConnectionService.updateConnection(id, args, include, withs, portWiths, nniWiths).then((result) => {
      if (result.status === 200) {
        dispatch(setConnectionDetail(result.data));
        return result.data
      }else if(result.status === 422){
        return {errors: result.data}
      } else {
        dispatch(defaultErrorFeedback());
      }
      return false;
    });
  };
}

export function deleteConnection(id) {
  return (dispatch) => {
    return ConnectionService.deleteConnection(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createConnectionComponent(tempId, data = []) {
  return (dispatch) => {
    return ConnectionComponentService.createConnectionComponent(data)
      .then(result => {
        if (result.status === 200) {
          dispatch(persistConnectionComponent(tempId, result.data));
        } else {
          dispatch(defaultErrorFeedback());
        }
        return result;
      });
  };
}

export function updateConnectionComponent(id, data = []) {
  return (dispatch) => {
    return ConnectionComponentService.updateConnectionComponent(id, data)
      .then(result => {
        if (result.status === 200) {
          dispatch(persistConnectionComponent(id, result.data));
        } else {
          dispatch(defaultErrorFeedback());
        }
        return result;
      });
  };
}

export function deleteConnectionComponent(id) {
  return (dispatch) => {
    return ConnectionComponentService.deleteConnectionComponent(id).then((result) => {
      if (result.status === 204) {
        dispatch(destroyConnectionComponent(id));
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export const createComponent = (type, data) => {
  const createFn = () => {
    switch (type) {
      case 'crossConnect':
      default:
        return CrossConnectService.createCrossConnect(data);
      case 'carrierCircuit':
        return CarrierCircuitService.createCarrierCircuit(data);
      case 'patchLead':
        return PatchLeadService.createPatchLead(data);
      case 'wavelength':
        return WavelengthService.createWavelength(data);
    }
  };
  return (dispatch) => {
    return createFn()
      .then(result => {
        if (result.status === 200) {
          return result.data;
        }
        else if(result.status === 422){
          return {errors: result.data}
        }else{
          dispatch(defaultErrorFeedback());
        }
        return false;
      });
  };
};

export const updateComponent = ({ type, data }, diffData) => {
  const updateFn = () => {
    switch (type) {
      case 'crossConnect':
        return CrossConnectService.updateCrossConnect(data.id, diffData);
      case 'carrierCircuit':
        return CarrierCircuitService.updateCarrierCircuit(data.id, diffData);
      case 'patchLead':
        return PatchLeadService.updatePatchLead(data.id, diffData);
      case 'wavelength':
        return WavelengthService.updateWavelength(data.id, diffData);
    }
  };
  return (dispatch) => {
    return updateFn()
      .then(result => {
        if (result.status === 200) {
          dispatch(setConnectionComponentByComponent(type, result.data));
          return result.data;
        } else if(result.status === 422){
          return {errors: result.data}
        }
        dispatch(defaultErrorFeedback());
        return false;
      });
  };
};

export const deleteComponent = ({ id, type, data }) => {
  const deleteFn = () => {
    switch (type) {
      case 'crossConnect':
        return CrossConnectService.deleteCrossConnect(data.id);
      case 'carrierCircuit':
        return CarrierCircuitService.deleteCarrierCircuit(data.id);
      case 'patchLead':
        return PatchLeadService.deletePatchLead(data.id);
      case 'wavelength':
        return WavelengthService.deleteWavelength(data.id);
    }
  };
  return (dispatch) => {
    return deleteFn()
      .then(result => {
        if (result.status === 204) {
          dispatch(destroyConnectionComponent(id));
          return true;
        }
        dispatch(defaultErrorFeedback());
        return false;
      });
  };
};

export const getConnectionLogicalServices = (connectionId, logicalServiceTypes) => {
  return (dispatch) => {
    const promises = [];
    dispatch(resetConnectionLogicalServices())
    logicalServiceTypes.forEach(({ slug }) => {
      promises.push(LogicalServiceService.list(slug, [], [{id: 'connectionId', value: connectionId}])
        .then(result => {
          if (result.status === 200) {
            dispatch(addNewConnectionLogicalServices(result.data.map(service => ({ ...service, type: slug }))));
          } else {
            dispatch(defaultErrorFeedback())
          }
          return true;
        }));
    });
    return Promise.all(promises);
  };
};

export function getConnectionAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('sdb/connections', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: CONNECTION_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}
