import {
  SITE_RESET_DATA,
  SITE_SET_DATA,
  SITE_UPDATE_FORM_FIELD,
  SITE_SET_AUDIT_HISTORY
} from '../actions/site';

const initialState = {
  original: {},
  form: {},
  audits: []
};

export default function site(state = initialState, action) {
  switch (action.type) {
    case SITE_SET_DATA:
      return {
        ...state,
        form: { ...state.form, ...action.site },
        original: { ...state.original, ...action.site }
      };
    case SITE_UPDATE_FORM_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          [action.field]: action.value
        }
      };
    case SITE_RESET_DATA:
      return {
        ...initialState
      };
    case SITE_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;
  }
}
