import isEmpty from 'lodash.isempty';

export const NewQuote =  {
  name: { label: 'Name', type: 'text', mandatory: true, width: 12},
  status: { label: 'Status', type: 'select', mandatory: true},
  preferredQuote: { label: 'Preferred', type: 'select-boolean'},
}

export const ReadOnlyQuote = (currency) => ({
  name: {
    label: 'Name',
    type: 'text',
    plaintext: true
  },
  totalAmount: {
    label: 'Total Amount',
    type: 'text',
    format: {money: currency},
    plaintext: true
  },
  statusReason: {
    label: 'Status',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data, optionSets) => callBack(key, field, data, optionSets)
  },
  pricingToolQuote: {
    label: 'Pricing Tool Quote Id',
    type: 'text',
    plaintext: true
  },
  customer: {
    label: 'Customer',
    type: 'text',
    protected: true,
    plaintext: true,
    callBack: (key, field, data) => callBack(key, field, data)
  },
  owner: {
    label: 'Owner',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => callBack(key, field, data)
  }
});

export const QuoteProduct = {
  name: {
    label: 'Name',
    type: 'text',
    width: 12
  },
  orderType: {
    label: 'Order Type',
    type: 'select',
    readOnly: true,
    width: 4,
  },
  term: {
    label: 'Term',
    type: 'text',
    width: 4,
    readOnly: (key, field, data) => !data.id.startsWith('new-')
  },
  install: {
    label: 'Install',
    type: 'text',
    width: 4,
    readOnly: (key, field, data) => !data.id.startsWith('new-')
  },
  rental: {
    label: 'Rental',
    type: 'text',
    width: 4,
    readOnly: (key, field, data) => !data.id.startsWith('new-')
  },
  billingCycle: {
    label: 'Billing Cycle',
    type: 'select',
    width: 4,
    clearable: true
  },
  sla: {
    label: 'SLA',
    type: 'select',
    clearable: true,
    width: 4
  },
  committedDataRate: {
    label: 'Committed Data Rate',
    type: 'text',
    width: 5
  },
  overageRate: {
    label: 'Overage Rate',
    type: 'text',
    width: 4
  }
};

export const newQuoteProductInputForm = {
  priceList: {
    label: 'Price List',
    type: 'text',
    plaintext: true,
    width: 12,
    callBack: (key, field, data) => data.opportunity.priceList?.name ?? 'N/A'
  },
  product: {
    label: 'Product',
    type: 'select-async',
    entity: 'product',
    width: 12,
    minSearchLength: 4
  }
}

const callBack = (key, field, data, optionSets) => {
  if (key === 'statusReason') {
    return data[key] !== undefined ? optionSets.statusReason.options.find(x => x.value === data[key] && x.status === data.status ).label : '';
  }
  if (key === 'customer') {
    return data[key] !== undefined ? data[key].name : '';
  }
  if (key === 'owner') {
    return data[key] !== undefined ? data[key].name : '';
  }

  return null

}
