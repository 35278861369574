import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

const SiteService = {
  list: (sorted = [], search = [], include = [], searchJoin, withArr = [], activeOnly) => {
    let query = {
      sorted,
      search: search.map((value) => `${value.id}:${value.value}`).join(';'),
      searchFields: 'id:=;name:like',
      include,
      searchJoin,
      with: withArr ? withArr.join(';') : undefined,
      activeOnly
    };
    return apiRequest({
      url: `/sites${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  get: (siteId, data, include = ['primaryContact'], withArr = ['primaryContact']) => {
    const query = {
      include,
      with: withArr ? withArr.join(';') : undefined
    }
    return apiRequest({
      url: `/sites/${siteId}${queryStringFromFields(query)}`,
      method: 'get',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  update: (siteId, data, include = ['primaryContact'], withArr = ['primaryContact']) => {
    const query = {
      include,
      with: withArr ? withArr.join(';') : undefined
    }
    return apiRequest({
      url: `/sites/${siteId}${queryStringFromFields(query)}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  deactivate: (siteId) => {
    return apiRequest({
      url: `/sites/${siteId}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  restore: (siteId) => {
    return apiRequest({
      url: `/sites/${siteId}/restore`,
      method: 'put'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  create: (accountIdOrNumber, data) => {
    return apiRequest({
      url: `/accounts/${accountIdOrNumber}/sites?include=primaryContact`,
      method: 'post',
      data
    })
      .then(
        resp => resp
      )
      .catch(
        error => error
      );
  }
};

export default SiteService;
