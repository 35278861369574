import {apiRequest} from '../Api/ApiRequest';

export const GlobalSearchService = {

  search: (pattern) => {
    return apiRequest({
      url: `/search-index/search?pattern=${pattern}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}
