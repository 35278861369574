import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import DirectDebits from './DirectDebits';
import NewLineItems from './NewLineItems';
import BillingBooth from './BillingBooth';
import GenerateCdrs from './GenerateCdrs';
import BillTemplates from './BillTemplates';

class Admin extends React.Component {
    static contextTypes = {
      router: PropTypes.shape({
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
          replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
      }).isRequired
    };

    constructor(props) {
      super(props);
      this.state = {
        activeTab: 0
      };
    }

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab
        });
      }
    }

    render() {
      return (
            <Row>
                <Col>
                    <Nav tabs>
                        <NavItem>
                            <NavLink href="#"
                                     className={classnames({ active: this.state.activeTab === 0 })}
                                     onClick={() => {
                                       this.toggle(0);
                                     }}>New Line Items
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#"
                                     className={classnames({ active: this.state.activeTab === 1 })}
                                     onClick={() => {
                                       this.toggle(1);
                                     }}>Bill Templates
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#"
                                     className={classnames({ active: this.state.activeTab === 2 })}
                                     onClick={() => {
                                       this.toggle(2);
                                     }}>Direct Debit
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#"
                                     className={classnames({ active: this.state.activeTab === 3 })}
                                     onClick={() => {
                                       this.toggle(3);
                                     }}>BillingBooth
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#"
                                     className={classnames({ active: this.state.activeTab === 4 })}
                                     onClick={() => {
                                       this.toggle(4);
                                     }}>CDRs
                            </NavLink>
                        </NavItem>
                        <TabContent className={'col-md-12 d-flex flex-column'} activeTab={this.state.activeTab}>
                            <TabPane tabId={0}>
                                <NewLineItems/>
                            </TabPane>
                            <TabPane tabId={1}>
                                <BillTemplates/>
                            </TabPane>
                            <TabPane tabId={2}>
                                <DirectDebits/>
                            </TabPane>
                            <TabPane tabId={3}>
                                <BillingBooth/>
                            </TabPane>
                            <TabPane tabId={4}>
                                <GenerateCdrs/>
                            </TabPane>
                        </TabContent>
                    </Nav>
                </Col>
            </Row>
      );
    }
}
export default connect()(Admin);
