import FilteredTableV2 from '../../FilteredTableV2'
import React, { useCallback, useMemo, useState } from 'react'
import HeadlessModal from '../../Modals/HeadlessModal'
import {
  Badge,
  ButtonDropdown,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row
} from 'reactstrap'
import moment from 'moment/moment'
import Circuit from '../../../views/Circuit'
import { connect, useDispatch } from 'react-redux'
import { ButtonIcon } from '../../ButtonIcon'
import { CSVLink } from 'react-csv'
import omit from 'lodash.omit'
import CarrierCircuit from '../../../views/ServiceDB/CarrierCircuit'
import CircuitLookupModal from '../../Modals/CircuitLookupModal'
import { resetCarrierCircuit } from '../../../actions/carrierCircuit'
import { resetCircuit } from '../../../actions/circuit'
import FormValidationErrors from '../../Errors/FormValidationErrors'
import CrossConnect from '../../../views/ServiceDB/CrossConnect'
import { resetCrossConnect } from '../../../actions/crossConnect'
import isEmpty from 'lodash.isempty'

const CircuitsTable = (props) => {
  // redux
  const dispatch = useDispatch()

  const {
    errors,
    circuits,
    optionSets,
    fetchData,
    product,
    withNew,
    onCreated,
    onUpdated,
    remove,
    associate,
    noDataText,
    circuitState,
    carrierCircuitState,
  } = props

  // filter out customer ports
  const displayableCircuits = useMemo(() => circuits.filter((circuit) => circuit.circuitType !== 100000000), [circuits])

  const [showCircuitModal, setShowCircuitModal] = useState(false)
  const [showExistingCircuitModal, setShowExistingCircuitModal] = useState(false)
  const [circuitDropdownOpen, setCircuitDropdownOpen] = useState(false)
  const [selectedCircuit, setSelectedCircuit] = useState(null)
  const toggleModal = () => setShowCircuitModal(prevState => !prevState)
  const toggleExistingCircuitModal = () => setShowExistingCircuitModal(prevState => !prevState)
  const toggleCircuitDropdown = () => setCircuitDropdownOpen(prevState => !prevState)

  const showCircuit = useCallback((circuit) => {
    setSelectedCircuit(circuit)
    toggleModal()
  }, [carrierCircuitState.original?.id, circuitState.original?.id])

  const tableFilter = (row, columnId, value) => {
    const orderStatus = optionSets.orderStatus?.options.find(x => x.value === row.original.orderStatus)?.label
    const circuitType = optionSets.circuitType?.options.find(x => x.value === row.original.circuitType)?.label
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.carrierJobNumber)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.circuitRef)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.carrierCircuitNumber)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.assignedTo?.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(orderStatus)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(circuitType)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Assigned To',
      accessorKey: 'assignedTo',
      cell: props => props.getValue()?.name ?? ''
    },
    {
      header: 'Circuit Ref',
      accessorKey: 'circuitRef',
      cell: props => props.row.original.managedByServiceDB ? 'n/a' : props.getValue()
    },
    {
      header: 'Carrier Job Number',
      accessorKey: 'carrierJobNumber',
    },
    {
      header: 'Carrier Reference',
      accessorKey: 'carrierCircuitNumber',
    },
    {
      header: 'Order Status',
      accessorKey: 'orderStatus',
      cell: props => optionSets.orderStatus?.options.find(x => x.value === props.getValue())?.label ?? ''
    },
    {
      header: 'Circuit Type',
      accessorKey: 'circuitType',
      cell: props => optionSets.circuitType?.options.find(x => x.value === props.getValue())?.label ?? ''
    },
    {
      header: 'Managed By',
      accessorKey: 'managedByServiceDB',
      cell: props => <div className={'d-flex justify-content-between'}>
        {props.getValue() ?
          <Badge className={`p-2 text-wrap faded-info`}>ServiceDB</Badge> :
          <Badge className={`p-2 text-wrap faded-warning`}>CRM</Badge>
        }
        {remove &&
          <ButtonIcon
            size={'sm'}
            icon="fa fa-close"
            tooltip={'remove association'}
            onClick={event => remove(event, props.row.original.id)}
            className={'hover-content'}
          />
        }
      </div>
    },
  ]

  const getExportData = () => {
    return displayableCircuits.map(circuit => {
      return {
        ...omit(circuit, ['id'])
      }
    })
  }

  const getButtons = () => {
    const defaultButtons = [
      <div data-for={'export'} data-tip={'export'}>
        <CSVLink id={'export'}
                 data={getExportData()} filename={`circuits-${moment().format('DD-MM-YYYY')}.csv`}
                 style={{ textDecoration: 'none' }}>

          <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
        </CSVLink>
      </div>,

      <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
    ]
    if (withNew) {
      defaultButtons.unshift(<ButtonDropdown
        isOpen={circuitDropdownOpen}
        toggle={toggleCircuitDropdown}
        direction={'down'}
      >
        <DropdownToggle color="secondary" size={'sm'} className={'rounded'}>
          <i className="fa fa-plus"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => showCircuit({ id: 'new' }, props.circuitState, props.carrierCircuitState)}>Create
            New</DropdownItem>
          <DropdownItem onClick={toggleExistingCircuitModal}>Add Existing</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>)
    }

    return defaultButtons
  }

  const isCarrierCircuit = useMemo(() => {
    if (product?.priceListItem) {
      return /Cloud Connect|Broadband|Off-Net/.test(product.priceListItem.template.productGroup.name)
    }
    return !isEmpty(selectedCircuit?.carrierCircuit)
  }, [product?.priceListItem, selectedCircuit?.carrierCircuit])

  const isCrossConnect = useMemo(() => {
    if (product?.priceListItem) {
      return /Cross Connect/.test(product.priceListItem.template.name)
    }
    return !isEmpty(selectedCircuit?.carrierCircuit)
  }, [product?.priceListItem, selectedCircuit?.carrierCircuit])

  const isManagedByServiceDB = useMemo(() => {
    return selectedCircuit?.managedByServiceDB ||
      (selectedCircuit?.id === 'new' && (isCarrierCircuit || isCrossConnect))
  }, [selectedCircuit?.managedByServiceDB, selectedCircuit?.id, isCarrierCircuit, isCrossConnect])

  return (
    <>
      <FormValidationErrors errors={errors}/>
      <FilteredTableV2
        withFilter={tableFilter}
        data={displayableCircuits ?? []}
        columns={columns}
        minRows={4}
        onRowClick={circuit => showCircuit(circuit)}
        noDataText={noDataText ?? 'No Circuits'}
        defaultSorted={[{ id: 'circuitRef', desc: true }]}
        extraButtons={getButtons()}
        showPagination={displayableCircuits?.length > 20}
        defaultPageSize={20}
      />
      <HeadlessModal
        open={showCircuitModal}
        size="xxlg"
        toggle={toggleModal}
      >
        <Row>
          <Col>
            {isManagedByServiceDB ? (
              isCarrierCircuit ? (
                <CarrierCircuit
                  id={selectedCircuit?.id}
                  onCreated={onCreated}
                  onUpdated={onUpdated}
                  orderProduct={product}
                  closeModal={() => {
                    dispatch(resetCarrierCircuit())
                    toggleModal()
                  }}
                />
              ) : (
                <CrossConnect
                  id={selectedCircuit?.id}
                  onCreated={onCreated}
                  onUpdated={onUpdated}
                  orderProduct={product}
                  closeModal={() => {
                    dispatch(resetCrossConnect())
                    toggleModal()
                  }}
                />
              )
            ) : (
              <Circuit
                id={selectedCircuit?.id}
                name={selectedCircuit?.name}
                toggleModal={() => {
                  dispatch(resetCircuit())
                  toggleModal()
                }}
                product={product}
                onCreated={onCreated}
                onUpdated={onUpdated}
              />
            )}

          </Col>
        </Row>
      </HeadlessModal>


      <CircuitLookupModal
        account={product?.company}
        open={showExistingCircuitModal}
        toggle={toggleExistingCircuitModal}
        addCircuit={circuit => {
          associate(circuit)
          toggleExistingCircuitModal()
        }}
        product={product}
        circuits={displayableCircuits}
      />
    </>

  )
}

const mapStateToProps = ({
  helpers,
  circuit,
  carrierCircuit
}) => ({
  optionSets: helpers.optionSets.circuit,
  circuitState: circuit,
  carrierCircuitState: carrierCircuit,
  suppliers: helpers.suppliers
})

export default connect(mapStateToProps)(CircuitsTable)
