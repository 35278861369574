import {CurrencyEnums} from "../../utils/Constants/Currency";
import React from "react";
import numeral from "numeral";
import {resolveCurrencySign} from "../../utils/Helpers/Currency";

type TOwnProps = {
    currency: number;
    value: number;
}
const Currency: React.FC<TOwnProps> = (
    {
        currency,
        value
    }
) => {
    return (
        <span>{resolveCurrencySign(currency)}{numeral(value)
            .format('0,0.00')}</span>
    )
}

export default Currency