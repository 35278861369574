import { defaultErrorFeedback } from './feedback';
import {
  api_deleteDocument,
  api_downloadDocument,
  api_getDocument, api_updateDocument,
  api_uploadDocument
} from '../utils/Account/AccountServiceDeprecated';
import fileDownload from 'js-file-download';

export const DOCUMENT_SET_DOCUMENT = 'DOCUMENT_SET_DOCUMENT';
export const DOCUMENT_RESET_DOCUMENT = 'DOCUMENT_RESET_DOCUMENT';
export const DOCUMENT_UPDATE_FORM_FIELD = 'DOCUMENT_UPDATE_FORM_FIELD';

export const setDocument = (document) => {
  return {
    type: DOCUMENT_SET_DOCUMENT,
    document
  }
}

export const getDocument = (accountId, id) => (dispatch) => api_getDocument(accountId, id).then(result => {
  if (result.status === 200) {
    dispatch(setDocument(result.data));
    return true;
  } else {
    dispatch(defaultErrorFeedback());
    return false;
  }
});

export const resetDocument = () => ({
  type: DOCUMENT_RESET_DOCUMENT
});

export const downloadDocument = (accountId, documentId) => (dispatch) => api_downloadDocument(accountId, documentId)
  .then((result) => {
    if (result.status === 200) {
      const splitContentDisposition = result.headers['content-disposition'].split('=');
      fileDownload(result.data, splitContentDisposition[1].replaceAll('"', ''));
      return true;
    }
    dispatch(defaultErrorFeedback());
    return false;
  })

export const setDocumentFormField = (field, value) => {
  return {
    type: DOCUMENT_UPDATE_FORM_FIELD,
    field,
    value
  }
}

export const uploadDocument = (accountId, data) => (dispatch) => api_uploadDocument(accountId, data)
  .then((result) => {
    if (result.status === 200) {
      dispatch(setDocument(result.data))
      return result.data;
    }
    return false;
  })

export const deleteDocument = (accountId, documentId) => (dispatch) => api_deleteDocument(accountId, documentId)
  .then((result) => result.status === 204);


export const updateDocument = (accountId, documentId, data) => (dispatch) => api_updateDocument(accountId, documentId, data)
  .then((result) => {
    if (result.status === 200) {
      dispatch(setDocument(result.data))
      return result.data;
    }
    return false;
  })
