import isEmpty from 'lodash.isempty';

const WavelengthForm = {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true,
    width: 12
  },
  opticalSystem: {
    label: 'Optical System',
    type: 'select',
    mandatory: true,
    width: 12
  },
  channel: {
    label: 'Channel',
    type: 'select',
    mandatory: true,
    show: (field, data) => !isEmpty(data.opticalSystem),
    width: 12
  }
};

export default WavelengthForm;