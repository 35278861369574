import { OPTICAL_SYSTEMS_SET_DATA } from '../actions/opticalSystems';

const initialState = {
  opticalSystems: []
};

const opticalSystems = (state = initialState, action) => {
  switch (action.type) {
    case OPTICAL_SYSTEMS_SET_DATA:
      return {
        opticalSystems: [...action.data]
      };
    default:
      return state;
  }
};

export default opticalSystems;