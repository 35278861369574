import { CAMPAIGN_SET_LIST } from '../actions/campaigns';

const initialState = {
  list: []
};


export function campaigns(state = initialState, action) {
  switch (action.type) {
    case CAMPAIGN_SET_LIST:
      return {
        ...state,
        list: action.list
      }
    default:
      return state;
  }
}
