import React from 'react';
import Email from '../../views/Email';
import LoadingOverlay from '../LoadingOverlay';

const Emails = ({ emails, loadMore, loading }) => {
  return (
    <LoadingOverlay loading={loading} style={{minHeight: '100px'}}>
      {emails.map((email, index) => {
        return (
          <Email key={`note-${index}`} email={email} loadMore={() => loadMore(email.id)}/>
        );
      })}
    </LoadingOverlay>
  );
};

export default Emails;
