import {CurrencyEnums} from "../Constants/Currency";


export const resolveCurrencySign = (currency: number) => {
    switch (currency) {
        case CurrencyEnums.currency.GBP : return '£'
        case CurrencyEnums.currency.USD : return '$'
        case CurrencyEnums.currency.EUR : return '€'
        default: return '£'
    }
}