import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { SpinnerInfo } from '../../components/Spinner/Spinner';
import { WeathermapService } from '../../utils/Weathermaps/WeathermapService';
import { connect } from 'react-redux';
import { checkPermission } from '../../utils/Auth/AuthService';

class WeatherMaps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addform: false,
      data: {}
    };
    this.toggleAddForm = this.toggleAddForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addMap = this.addMap.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    WeathermapService.listWeathermaps().then((result) => {
      if (result.status === 200) {
        this.setState({ loading: false, data: result.data });
      }
    }).catch((error) => {
      alert(error);
    });
  }

  toggleAddForm() {
    this.setState(prevState => ({
      addform: !prevState.addform
    }));
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  addMap() {
    WeathermapService.createWeatherMap(this.state.name, this.state.description).then((result) => {
      if (result.status === 200) {
        this.setState({ addform: false, loading: true });
        WeathermapService.listWeathermaps().then((result) => {
          if (result.status === 200) {
            this.setState({ loading: false, data: result.data });
          }
        }).catch((error) => {
          alert(error);
        });
      }
    }).catch((error) => {
      alert(error);
    });
  }

  render() {
    return (
            <div>
                {this.state.loading
                && <div className="row justify-content-center">
                    <SpinnerInfo/>
                </div>
                }
                {!this.state.loading && this.state.data.length > 0
                && <Row>
                    {this.state.data.map((rt) => {
                      return (
                            <Col md={4} key={rt.id}>
                                <Card className="card-accent-info">
                                    <CardHeader>
                                      <Row>
                                        <Col md={8}><Link to={'/toolbox/maps/' + rt.id}>{rt.name}</Link></Col>
                                        <Col md={4} className="text-right">{rt.shared && <Badge color="warning">shared</Badge>}</Col>
                                      </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <p>{rt.description}</p>
                                        <p style={{ textAlign: 'right', marginBottom: 0 }}>
                                            <Link to={'/toolbox/maps/' + rt.id} className="btn btn-success">View</Link>{' '}
                                            {checkPermission('WeathermapEdit', this.props.auth.account.permissions)
                                                && <Link to={'/toolbox/maps/' + rt.id + '/edit'}
                                                  className="btn btn-primary">Edit</Link>
                                            }
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                      );
                    })}
                </Row>
                }
                {!this.state.loading && checkPermission('WeathermapEdit', this.props.auth.account.permissions)
                    && <Row>
                        <Col md={12}>
                           <Button onClick={this.toggleAddForm}>Add New</Button>
                        </Col>
                    </Row>
                }
                <Modal isOpen={this.state.addform} toggle={this.toggleAddForm} className={'modal-info'}>
                    <ModalHeader toggle={this.toggleAddForm}>New Weathermap</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="mapName">Title</Label>
                                <Input type="text" name="name" id="mapName" placeholder="Map Title" onChange={this.handleChange} value={this.state.name}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <Input type="textarea" name="description" id="description" onChange={this.handleChange} value={this.state.description}/>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.addMap}>Add</Button>{' '}
                        <Button color="secondary" onClick={this.toggleAddForm}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authenticationState
  };
}

export default connect(mapStateToProps)(WeatherMaps);
