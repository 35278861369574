import toastr from 'toastr';
import Push from 'push.js';
import { v4 } from 'uuid';
import Ring from 'ringjs';
import update from 'immutability-helper';

const initialState = {
  messages: new Ring(50)
};

export default function socketioToast(state = initialState, action) {
  switch (action.type) {
    case 'TOAST':
      const newMessage = state.messages;
      action.timestamp = new Date();
      newMessage.push(action);
      if (action.link) {
        toastr.options.onclick = function () {
          window.location = action.link;
        };
      } else {
        toastr.options.onclick = null;
      }
      toastr.options.positionClass = 'toast-bottom-right';
      let pushopts = {
        tag: v4(),
        body: action.msg,
        timeout: 10000
      };
      switch (action.msgtype) {
        case 'success':
          pushopts.icon = '/img/notifications/success.png';
          toastr.success(action.msg, action.title);
          break;
        case 'warning':
          pushopts.icon = '/img/notifications/warning.png';
          toastr.warning(action.msg, action.title);
          break;
        case 'error':
          pushopts.icon = '/img/notifications/error.png';
          toastr.error(action.msg, action.title);
          break;
        default:
          pushopts.icon = '/img/notifications/info.png';
          toastr.info(action.msg, action.title);
          break;
      }
      if (action.disablePush !== true) {
        Push.create(action.title, pushopts);
      }
      return update(state,
        {
          messages: { $set: newMessage }
        });
    default:
      return state;
  }
}
