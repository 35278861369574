import { DeviceDeploymentService } from '../utils/ServiceDB/DeviceDeployment';
import { defaultErrorFeedback } from './feedback';

export const DEVICE_DEPLOYMENTS_SET_DATA = 'DEVICE_DEPLOYMENTS_SET_DATA';
export const DEVICE_DEPLOYMENTS_UPDATE_DATA = 'DEVICE_DEPLOYMENTS_UPDATE_DATA';


function setDevicesDeployments(data) {
  return {
    type: DEVICE_DEPLOYMENTS_SET_DATA,
    data
  }
}

function updateDevicesDeployments(data) {
  return {
    type: DEVICE_DEPLOYMENTS_UPDATE_DATA,
    data
  }
}


export function getDeviceDeployments(page, pageSize, search, sorting, include, withs, searchFields, searchJoin){
  return (dispatch) => {
    return DeviceDeploymentService.getDeviceDeployments(page, pageSize, search, sorting, include, withs, searchFields, searchJoin).then((result) => {
      if (result.status === 200) {
        dispatch(setDevicesDeployments(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  }
}


export function getDeviceDeploymentsZabbixData(hostnames){
  return (dispatch) => {
    return DeviceDeploymentService.getZabbixData(hostnames).then((result) => {
      if (result.status === 200) {
        dispatch(updateDevicesDeployments(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  }
}
