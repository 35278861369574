import { defaultErrorFeedback } from './feedback';
import { api_getCompareUsers, api_getCompareV2Quotes } from '../utils/PricingTool';

export const PRICING_TOOL_QUOTES_SET_LIST = 'PRICING_TOOL_QUOTES_SET_LIST';
export const PRICING_TOOL_QUOTES_SET_SEARCH_STRING = 'PRICING_TOOL_QUOTES_SET_SEARCH_STRING';
export const PRICING_TOOL_QUOTES_SET_USER_FILTER = 'PRICING_TOOL_QUOTES_SET_USER_FILTER';
export const PRICING_TOOL_QUOTES_ADD_QUOTE = 'PRICING_TOOL_QUOTES_ADD_QUOTE';
export const PRICING_TOOL_QUOTES_UPDATE_QUOTE = 'PRICING_TOOL_QUOTES_UPDATE_QUOTE';
export const PRICING_TOOL_SET_USERS = 'PRICING_TOOL_SET_USERS';

export function setPricingToolQuoteSearchString(search) {
  return {
    type: PRICING_TOOL_QUOTES_SET_SEARCH_STRING,
    search
  };
}

export function setPricingToolQuoteUserFilter(user) {
  return {
    type: PRICING_TOOL_QUOTES_SET_USER_FILTER,
    user
  };
}

export function setPricingToolQuotes(quotes, table) {
  return {
    type: PRICING_TOOL_QUOTES_SET_LIST,
    quotes,
    table
  };
}

export function addNewPricingToolQuote(quote) {
  return {
    type: PRICING_TOOL_QUOTES_ADD_QUOTE,
    quote
  };
}

export function updatePricingToolQuote(quoteId, field) {
  return {
    type: PRICING_TOOL_QUOTES_UPDATE_QUOTE,
    quoteId,
    field
  };
}

export function getPricingToolQuotes(postcode = '', page, size, owner) {
  return (dispatch) => {
    return api_getCompareV2Quotes(postcode, page, size, owner)
      .then((result) => {
        if (result.status === 200) {
          const {
            items,
            currentPage,
            total,
            perPage,
            path,
            lastPage
          } = result.data;
          dispatch(setPricingToolQuotes(items, {
            currentPage,
            total,
            perPage,
            path,
            lastPage
          }));
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
  };
}

export function getPricingToolUsers() {
  return (dispatch) => {
    return api_getCompareUsers()
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: PRICING_TOOL_SET_USERS,
            users: result.data
          });
          return true;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };
}


