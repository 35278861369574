import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  createSubnet,
  deleteSubnet,
  getNextNetworkAddressForBlock,
  getNextNetworkAddressForParentSubnet,
  getSubnet,
  resetSubnet,
  setSubnetField,
  updateSubnet
} from '../../../../actions/ServiceDB/Ipam/subnet';
import GenericCardForm from '../../../../components/GenericCardForm';
import { Col, Row } from 'reactstrap';
import SiteService from '../../../../utils/Site/SiteService';
import form from './form';
import isEmpty from 'lodash.isempty';
import { SubnetService } from '../../../../utils/ServiceDB/Ipam/Subnet';
import { BlockService } from '../../../../utils/ServiceDB/Ipam/Block';
import Subnets from '../Block/Subnets';
import Addresses from './Addresses';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import ApiErrorResolver from '../../../../helpers/ApiErrorResolver';
import Validator from '../../../../helpers/Validator';
import { LogicalServiceService } from '../../../../utils/ServiceDB/LogicalService';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../../actions/breadcrumbs';
import { canAccessServiceDb } from '../../../../utils/Auth/AuthService';
import { useNavigate, useParams } from 'react-router-dom'

const Subnet = ({
  id = null,
  data,
  original,
  onCreated = null,
  onUpdated = null,
  onDeleted = null,
  location = null,
  logicalServiceTypes,
  hideChildren = false,
  hideAllocatedTo = false
}) => {

  // router
  const navigate = useNavigate();
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [networkAddressButtonLoading, setNetworkAddressButtonLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const subnetId = useMemo(() => (id || params.subnetId), [id, params.subnetId])
  const isNew = useMemo(() => subnetId === 'new' && !original.id, [subnetId, original.id])

  useEffect(() => {
    if (original.name && original.block?.name) {
      const parent = isEmpty(original.parentSubnet) ? original.block : original.parentSubnet;
      dispatch(addBreadcrumbs([
        { name: parent.name, path: `/sdb/ipam/${!isEmpty(original.parentSubnet) ? 'subnets' : 'blocks'}/${parent.id}` },
        { name: original.name }
      ]));
    }

    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [original.name, original.block?.name]);

  const options = {
    allocatedToType: logicalServiceTypes.map(service => ({
      value: service.slug,
      label: service.label
    }))
  };
  const aSyncOptions = {
    block: (search) => BlockService.list([], [
      { id: 'name', value: search },
      { id: 'networkAddress', value: search },
      { id: 'mask', value: search }
    ])
      .then((result) => result.data.map(block => {
        return {
          ...block,
          label: `${block.name} (${block.networkAddress}/${block.mask})${(location.ipVersion && (location.ipVersion !== block.ipVersion)) ? ` (IP must be of IP version ${location.ipVersion})` : ''}`,
          isDisabled: location.ipVersion && (location.ipVersion !== block.ipVersion)
        };
      }) || []),
    subnet: (search) => SubnetService.list(['addresses'], [
      { id: 'name', value: search },
      { id: 'networkAddress', value: search },
      { id: 'mask', value: search }
    ])
      .then((result) => result.data?.map(subnet => {
        const hasAddresses = !isEmpty(subnet.addresses);
        return {
          ...subnet,
          label: `${subnet.name} (${subnet.networkAddress}/${subnet.mask})${hasAddresses ? ' (has existing addresses)' : ''}`,
          isDisabled: hasAddresses
        };
      }) || []),
    logicalService: (search) => LogicalServiceService.list(data.allocatedTo?.type, [], [{
      id: 'name',
      value: search
    }])
      .then((result) => result.data.map(service => ({
        type: data.allocatedTo.type,
        data: service
      })) || []),
    site: (search) => SiteService.list([], [
      { id: 'id', value: search },
      { id: 'name', value: search }
    ], [], 'or', [], true)
      .then((result) => result.data || [])
  };

  const requestStructure = (data) => ({
    name: data.name,
    description: data.description,
    blockId: data.block?.id,
    allocatedToType: data.allocatedTo?.type,
    allocatedToId: data.allocatedTo?.data?.id,
    parentSubnetId: data.parentSubnet?.id,
    assignedToId: data.assignedTo?.accountnumber,
    networkAddress: data.networkAddress,
    mask: data.mask
  });

  const fetch = () => {
    setLoading(true);
    dispatch(getSubnet(subnetId, ['assignedTo', 'block', 'parentSubnet', 'childSubnets', 'addresses', 'allocatedTo']))
      .then(() => setLoading(false));
  };

  const onFetchAll = () => {
    const promises = [];
    if (location.block) {
      promises.push(dispatch(setSubnetField('block', location.block)));
    }
    if (location.parentSubnet) {
      promises.push(dispatch(setSubnetField('parentSubnet', location.parentSubnet)));
    }
    if (location.name) {
      promises.push(dispatch(setSubnetField('name', location.name)));
    }
    if (location.mask) {
      promises.push(dispatch(setSubnetField('mask', location.mask)));
    }
    return Promise.all(promises);
  };

  const extraFieldValidation = (key, value) => {
    const errorArr = [];
    if (key === 'networkAddress' && !(Validator.Ipam.validIpv4(data[key]) || Validator.Ipam.validIpv6(data[key]))) {
      errorArr.push(`The ${value.label} is invalid`);
    }
    if (key === 'mask' && (Validator.Ipam.validIpv4(data.networkAddress) || Validator.Ipam.validIpv6(data.networkAddress))) {
      const parent = data.parentSubnet || data.block;
      if (data[key] < parent.mask) {
        errorArr.push(`The ${value.label} must be between higher than the parent.`);
      }
      if (!Validator.Ipam.validMask(data.networkAddress, data[key])) {
        errorArr.push(`The ${value.label} must be between 1 and ${Validator.Ipam.validIpv4(data.networkAddress) ? '32' : '128'}.`);
      }
    }
    return errorArr;
  };

  const getNextNetworkAddressButton = () => {
    if (isEmpty(data.networkAddress) && ((!isEmpty(data.parentSubnet) && data.mask > data.parentSubnet.mask) || (!isEmpty(data.block) && data.mask > data.block.mask))) {
      return <ButtonIcon
        icon="fa fa-download"
        loading={networkAddressButtonLoading}
        tooltip="Find the next available network address"
        onClick={() => {
          setNetworkAddressButtonLoading(true);
          let action;
          if (!isEmpty(data.parentSubnet)) {
            action = getNextNetworkAddressForParentSubnet(data.parentSubnet.id, data.mask);
          } else if (!isEmpty(data.block)) {
            action = getNextNetworkAddressForBlock(data.block.id, data.mask);
          } else {
            return;
          }
          dispatch(action)
            .then((result) => {
              if (result.status === 422 && result.data) {
                setErrors([...new Set(ApiErrorResolver(result.data))]);
              } else if (result.status !== 200) {
                setErrors(['There was an issue getting the network address.']);
              }
              setNetworkAddressButtonLoading(false);
            });
        }}
        className="float-right"
      />;
    }
    return '';
  };

  const selectValue = {
    allocatedToType: () => options.allocatedToType.find(opt => data.allocatedTo?.type === opt.value) || null
  };

  const setField = (field, value) => {
    if (field === 'allocatedToType') {
      dispatch(setSubnetField('allocatedTo', { type: value, data: {} }));
    }
    dispatch(setSubnetField(field, value));
  };

  return (
    <Row>
      <Col lg={!hideChildren ? 6 : 12}>
        <GenericCardForm
          id={subnetId}
          title={data.name || 'New Subnet'}
          isNew={isNew}
          form={form}
          data={data}
          original={original}
          extraFormData={{
            blockProvided: !isEmpty(location.block),
            parentSubnetProvided: !isEmpty(location.parentSubnet),
            maskProvided: location.mask !== undefined,
            allocatedToType: data.allocatedTo?.type,
            hideAllocatedTo: !!hideAllocatedTo,
            getNextNetworkAddressButton
          }}
          selectValue={selectValue}
          extraErrors={errors}
          options={options}
          aSyncOptions={aSyncOptions}
          requestStructure={requestStructure}
          extraFieldValidation={extraFieldValidation}
          onFetch={fetch}
          onFetchAll={onFetchAll}
          onReset={() => dispatch(resetSubnet())}
          setField={setField}
          onCreate={(toCreate) => dispatch(createSubnet(toCreate, ['assignedTo', 'block', 'parentSubnet', 'childSubnets', 'addresses', 'allocatedTo']))}
          onCreated={(result) => onCreated ? onCreated(result) : navigate(`/sdb/ipam/subnets/${result.id}`)}
          onUpdate={(toUpdate) => dispatch(updateSubnet(subnetId, toUpdate, ['assignedTo', 'block', 'parentSubnet', 'childSubnets', 'addresses', 'allocatedTo']))}
          onUpdated={(result) => onUpdated ? onUpdated(result) : {}}
          onDelete={() => dispatch(deleteSubnet(subnetId))}
          onDeleted={() => onDeleted ? onDeleted(subnetId) : navigate(`/sdb/ipam/blocks/${data.block.id}`)}
          canEdit={canAccessServiceDb()}
          deleteButtonDisabled={original.addresses.length > 0 || original.childSubnets.length > 0}
          deleteButtonDisabledTooltip={original.addresses.length > 0 ? 'Subnet has existing addresses' : 'Subnet has existing children'}
        />
      </Col>
      {!hideChildren && !isNew ? (
        <Col lg={6}>
          {original.addresses.length === 0 ? (
            <Subnets
              subnets={original.childSubnets}
              loading={loading}
              parentSubnet={original}
              canAddAndDelete={canAccessServiceDb()}
            />
          ) : ''}
          {original.childSubnets.length === 0 ? (
            <Addresses
              addresses={original.addresses}
              loading={loading}
              subnet={original}
              canAddAndDelete={canAccessServiceDb()}
            />
          ) : ''}
        </Col>
      ) : ''}
    </Row>
  );
};

function mapStateToProps({ ipamSubnet, helpers, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    data: ipamSubnet.data,
    original: ipamSubnet.original,
    logicalServiceTypes: helpers.serviceDb.logicalServiceTypes
  };
}

export default connect(mapStateToProps)(Subnet);
