import React from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import ExtraButtons from '../../../utils/Helpers/ExtraButtons';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment/moment';
import { ButtonIcon } from '../../ButtonIcon';
import omit from 'lodash.omit';
import TableCellHighlightOnHover from '../../TableCellHighlightOnHover';

const ConfigItemsTable = ({
  configItems,
  optionSets,
  fetchData
}) => {

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      cell: props => <TableCellHighlightOnHover value={<a href={props.row.original.url} target="_blank" rel="noreferrer">{props.getValue()}</a>}/>,
      maxWidth: 150
    },
    {
      header: 'Assigned To',
      accessorKey: 'assignedTo',
      cell: props => props.getValue() ? props.getValue().name : null,
      minWidth: 100
    },
    {
      header: 'Location',
      accessorKey: 'location',
      cell: props => props.getValue() ? props.getValue().name : null
    },
    {
      header: 'Model',
      accessorKey: 'model',
      cell: props => {
        const option = optionSets.model.options.find(({ value }) => value === props.getValue());
        return option ? option.label : '';
      }
    },
    {
      header: 'Management IP',
      accessorKey: 'managementIp',
      maxWidth: 200
    },
    {
      header: 'Status Reason',
      accessorKey: 'statusCode',
      cell: props => {
        const option = optionSets.statusCode.options.find(({ value }) => value === props.getValue());
        return option ? option.label : '';
      },
      maxWidth: 200
    }
  ];

  const getExportData = () => {
    return configItems.map(configItem => {
      return {
        ...omit(configItem, ['id'])
      }
    })
  }

  const tableFilter = (row, columnId, value) => {
    const statusReason = optionSets.statusCode?.options.find(x => x.value === row.original.statusCode)?.label
    const model = optionSets.model?.options.find(x => x.value === row.original.model)?.label
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.assignedTo?.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.location?.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(statusReason)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(model)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }

  return (
    <FilteredTableV2
      withFilter={tableFilter}
      minRows={10}
      data={configItems}
      columns={columns}
      showPagination={configItems.length > 20}
      defaultPageSize={20}
      noDataText="No Configuration Items"
      extraButtons={[
        <div data-for={'export'} data-tip={'export'} >
          <CSVLink id={'export'}
                   data={getExportData()} filename={`config-items-${moment().format('DD-MM-YYYY')}.csv`}
                   style={{ textDecoration: 'none' }}>

            <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
          </CSVLink>
        </div>,

        <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
      ]}
    />
  )
}

const mapStateToProps = ({
  helpers
}) => ({
  optionSets: helpers.optionSets.configItem,
})

export default connect(mapStateToProps)(ConfigItemsTable)