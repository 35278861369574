import EntitySubFormCard from '../../../../components/Cards/EntitySubFormCard';
import { moveConnectionComponent } from '../../../../actions/connection';
import PatchLead from './PatchLead';
import CarrierCircuit from './CarrierCircuit';
import CrossConnect from './CrossConnect';
import Wavelength from './Wavelength';
import JSONPretty from 'react-json-pretty';
import JSONPretty1337 from 'react-json-pretty/dist/1337';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DeploymentInfoComponent from '../../DeviceDeployments/DeploymentInfoComponent';
import isEmpty from 'lodash.isempty';
import { ButtonIcon } from '../../../../components/ButtonIcon';

const ConnectionComponent = ({
  isSaved,
  componentData,
  onUpdate,
  connection,
  serviceTypes,
  suppliers,
  circuitOptionSets,
  onDetach
}) => {

  // router
  const navigate = useNavigate()

  // redux
  const dispatch = useDispatch();

  const [component, setComponent] = useState(null);

  useEffect(() => {
    if(componentData.type === 'node'){
      setComponent(getDeviceComponent(componentData.end))
    }
    if(componentData.type === 'component'){
      setComponent(connection.components.find(item => item.id === componentData.id))
    }
  }, [componentData]);

  useEffect(() => {
    setComponent(connection.components.find(item => item.id === componentData.id))
  }, [])

  useEffect(() => {
    setComponent(connection.components.find(item => item.id === componentData.id))
  }, [connection.components])

  const getDeviceComponent = (end) => {
    if (connection[end].type === 'nni') {
      return (
        <DeploymentInfoComponent deviceDeployment={connection[end].data.port.deviceDeployment}/>);
    }
    return (<DeploymentInfoComponent deviceDeployment={connection[end].data.deviceDeployment}/>);
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case 'carrierCircuit':
        return 'Carrier Circuit'
      case 'patchLead':
        return 'Patch Lead'
      case 'crossConnect':
        return 'Cross Connect'
      case 'wavelength':
        return 'Wavelength'
      default:
        return type
    }
  }


  const getButtons = () => {
    const data = connection.components.find(item => item.id === componentData.id)
    const higherComponent = connection.components.find(component => component.order > data.order);
    const lowerComponent = connection.components.find(component => component.order < data.order);
    const onMoveUp = higherComponent ? () => dispatch(moveConnectionComponent('up', componentData.id)) : undefined
    const onMoveDown = lowerComponent ? () => dispatch(moveConnectionComponent('down', componentData.id)) : undefined
    const buttons = [
      <ButtonIcon
        disabled={Boolean(!onMoveDown)}
        icon={`fa fa-chevron-left`}
        tooltip="Move left"
        outline
        colour={'info'}
        onClick={onMoveDown}
        className="ml-auto"
      />,
      <ButtonIcon
        disabled={Boolean(!onMoveUp)}
        icon={`fa fa-chevron-right`}
        tooltip="Move right"
        outline

        colour={'info'}
        onClick={onMoveUp}
        className="ml-2"
      />
    ]
    if (data.type !== 'carrierCircuit' || !isSaved) {
      buttons.push(<ButtonIcon
        icon={`fa fa-trash`}
        tooltip="Remove"
        outline
        colour={'danger'}
        onClick={() => onDetach(componentData.id)}
        className="ml-2"
      />)
    }

    if(data.type === 'crossConnect' || data.type === 'carrierCircuit'){
      buttons.push(<ButtonIcon
        className={'ml-2'}
        icon="fa fa-arrow-right"
        tooltip="view"
        onClick={() => navigate('/sdb/carrier-circuits/' + data.data.id)}
      />)
    }
    return buttons
  }

  const getComponent = (data) => {
    const higherComponent = connection.components.find(component => component.order > data.order);
    const lowerComponent = connection.components.find(component => component.order < data.order);
    const params = {}
    switch (data.type) {
      case 'patchLead':
        return (<PatchLead data={data.data} update={(field, value) => onUpdate(data.id, {[field] :value})}/>);
      case 'carrierCircuit':
        if (!lowerComponent && connection.aEnd?.type === 'nni') {
          params.nni = connection.aEnd.data;
        } else if (!higherComponent && connection.bEnd?.type === 'nni') {
          params.nni = connection.bEnd.data;
        }
        return (<CarrierCircuit
          {...params}
          onConnect={(value) => onUpdate(data.id, value)}
          data={data}
          serviceTypes={serviceTypes}
          circuitOptionSets={circuitOptionSets}
          aEnd={connection.aEnd}
          suppliers={suppliers}
          canEdit={!isSaved}
        />);
      case 'crossConnect':
        return (<CrossConnect
          data={data.data}
          onConnect={(value) => onUpdate(data.id, value)}/>);
      case 'wavelength':
        return (<Wavelength
          data={data.data}
          connection={connection}
          update={(field, value) => onUpdate(data.id, {[field] :value})}
        />);
      default:
        return (<JSONPretty id="json-pretty" data={data} theme={JSONPretty1337}/>);
    }
  }


  return (
    <>
      {
        componentData.type === 'component' && component &&
        <EntitySubFormCard grow title={getTypeLabel(component?.type)} headerExtraRight={getButtons()}>
          {getComponent(component)}
        </EntitySubFormCard>

      }
      {
        componentData.type === 'node' && getDeviceComponent(componentData.end)
      }
    </>

  )
}


function mapStateToProps({ connection, helpers }) {
  return {
    serviceTypes: helpers.optionSets.circuit.circuitType,
    circuitOptionSets: helpers.optionSets.carrierCircuit,
    suppliers: helpers.suppliers,
    connection: connection.data
  };
}


export default connect(mapStateToProps)(ConnectionComponent)
