import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const ReportService = {
  generate: (data) => {
    return apiRequest({
      url: `/reports/generate${queryStringFromFields(data)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}