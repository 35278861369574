import React from 'react'

const Sales = () => {
  return (
    <div className="animated fadeIn">
    </div>
  )

}

export default Sales
