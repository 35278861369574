import { deepFreeze } from '../Helpers/DeepFreeze';

export const CreditRequestEnums = deepFreeze({
  status: {
    PENDING: 1,
    NOT_STARTED: 5,
    CANCELED: 4,
    APPROVED: 2,
    REJECTED: 3,
  },
  reason: {
    SLA_BREACH: 2,
    GOODWILL: 1,
    INVOICE_ERROR: 3,
    OTHER: 4,
  }
});