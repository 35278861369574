import { defaultErrorFeedback } from './feedback';
import { api_getBillingQueue } from '../utils/Billing/BillingService';

export const BILLING_QUEUE_SET_JOBS = 'BILLING_QUEUE_SET_JOBS';
export const BILLING_QUEUE_TOGGLE_LOADING = 'BILLING_QUEUE_TOGGLE_LOADING';

function setBillingQueue(data) {
  return {
    type: BILLING_QUEUE_SET_JOBS,
    data
  };
}

export function toggleBillingQueueLoading() {
  return {
    type: BILLING_QUEUE_TOGGLE_LOADING
  };
}

export function getBillingQueueStatus() {
  return (dispatch) => {
    return api_getBillingQueue().then((result) => {
      if (result.status === 200) {
        dispatch(setBillingQueue(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}
