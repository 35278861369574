import { isObject } from 'reactstrap/lib/utils';
import { isArray } from '../middleware/socketio/helpers';

export default (data) => {
  return Object.entries(data).reduce((result, field) => {
    if (field[1] && field[1] instanceof Date) {
      result[field[0]] = field[1].toISOString();
    } else if (field[1] !== undefined) {
      result[field[0]] = isObject(field[1]) && !isArray(field[1]) ? field[1].id || field[1].accountid : field[1] === '' ? null : field[1];
    }
    return result;
  }, {});
};
