export const LIVETRAFFICDATA = 'LIVETRAFFICDATA';
export const RESETLIVETRAFFICDATA = 'RESETLIVETRAFFICDATA';
export const STOPLIVETRAFFIC = 'STOPLIVETRAFFIC';
export const LIVETRAFFIC = 'LIVETRAFFIC';

export function stopRealtimeTraffic() {
  return (dispatch) => {
    dispatch({
      type: STOPLIVETRAFFIC
    });
  };
}

export function startRealtimeTraffic(payload) {
  return (dispatch) => {
    dispatch({
      type: LIVETRAFFIC,
      payload
    });
  };
}

export function resetRealtimeTraffic() {
  return (dispatch) => {
    dispatch({
      type: RESETLIVETRAFFICDATA
    });
  };
}
