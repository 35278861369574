import { OpticalSystemService } from '../utils/ServiceDB/OpticalSystem';
import { defaultErrorFeedback } from './feedback';

export const OPTICAL_SYSTEM_SET_DATA = 'OPTICAL_SYSTEM_SET_DATA';
export const OPTICAL_SYSTEM_SET_FIELD = 'OPTICAL_SYSTEM_SET_FIELD';
export const OPTICAL_SYSTEM_RESET_DATA = 'OPTICAL_SYSTEM_RESET_DATA';

export function getOpticalSystem(id, include = []) {
  return (dispatch) => {
    return OpticalSystemService.getOpticalSystem(id, include).then((result) => {
      if (result.status === 200) {
        dispatch(setOpticalSystemData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createOpticalSystem(args, include = []) {
  return (dispatch) => {
    return OpticalSystemService.createOpticalSystem(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setOpticalSystemData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function updateOpticalSystem(id, args, include = []) {
  return (dispatch) => {
    return OpticalSystemService.updateOpticalSystem(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setOpticalSystemData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function deleteOpticalSystem(id) {
  return (dispatch) => {
    return OpticalSystemService.deleteOpticalSystem(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

function setOpticalSystemData(data) {
  return {
    type: OPTICAL_SYSTEM_SET_DATA,
    data
  };
}

export function setOpticalSystemField(field, value) {
  return {
    type: OPTICAL_SYSTEM_SET_FIELD,
    field,
    value
  };
}

export function resetOpticalSystem() {
  return {
    type: OPTICAL_SYSTEM_RESET_DATA
  };
}
