import { Badge, Button } from 'reactstrap';
import React, { useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { ButtonIcon } from '../../ButtonIcon';
import isEqual from 'lodash.isequal';
import { useNavigate } from 'react-router-dom'

const ConnectionsTable = ({
  connections,
  table,
  fetchData
}) => {

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({pageIndex : 0, pageSize: 20})
  const [sorting, setSorting] = useState([{  id: 'name', desc: false}])

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      enableSorting: false,
      header: 'A-End Device',
      id: 'A-End Device',
      accessorFn: row => row.aEnd.type,
      cell: (props) => {
        if (props.getValue() === 'port') {
          return (<span>{props.row.original.aEnd.data.deviceDeployment.hostname}</span>);
        }

        return (<span>{props.row.original.aEnd.data.port.deviceDeployment.hostname}</span>);
      }
    },
    {
      enableSorting: false,
      header: 'A-End Port',
      id: 'A-End Port',
      accessorFn: row => row.aEnd.type,
      cell: (props) => {
        if (props.getValue() === 'port') {
          return (<span>{props.row.original.aEnd.data.name}</span>);
        }

        return (
          <span>{props.row.original.aEnd.data.port.name} <Badge color={'primary'}>NNI</Badge></span>);
      }
    },
    {
      enableSorting: false,
      header: 'B-End Device',
      accessorKey: 'bEnd.type',
      id: 'B-End Device',
      accessorFn: row => row.bEnd.type,
      cell: (props) => {
        if (props.getValue() === undefined) { return (<span/>); }
        if (props.getValue() === 'port') {
          return (<span>{props.row.original.bEnd.data.deviceDeployment.hostname}</span>);
        }

        return (<span>{props.row.original.bEnd.data.port.deviceDeployment.hostname}</span>);
      }
    },
    {
      enableSorting: false,
      header: 'B-End Port',
      id: 'B-End Port',
      accessorFn: row => row.bEnd.type,
      cell: (props) => {
        if (props.getValue() === undefined) { return (<span/>); }
        if (props.getValue() === 'port') {
          return (<span>{props.row.original.bEnd.data.name}</span>);
        }

        return (
          <span>{props.row.original.bEnd.data.port.name} <Badge color={'primary'}>NNI</Badge></span>);
      }
    },
  ];
  const onSorting = (sortingState, searchString) => {
    if(!isEqual(sortingState, sorting)){
      fetchData(pagination.pageIndex + 1, pagination.pageSize, searchString,sortingState )
      setSorting(sortingState)
    }
  }

  return (
    <FilteredTableV2
      withServerSideFilter={(searchString) => {
        fetchData(1, pagination.pageSize, searchString, sorting)
      }}
      minRows={pagination.pageSize}
      data={connections}
      columns={columns}
      onFetchData={(page, size, searchString) => {
        fetchData(page, size, searchString, sorting)
      }}
      defaultSorted={sorting}
      pages={table.lastPage}
      pageSize={table.perPage}
      onPaginationChange={setPagination}
      pagination={pagination}
      showPagination
      defaultPageSize={20}
      onRowClick={(connection) => navigate('/sdb/connections/' + connection.uuid)}
      extraButtons={[
        <Button size={'sm'} color={'secondary'} onClick={() => {
          navigate(`/sdb/connections/new`)
        }}>New Connection</Button>,
        <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={() => fetchData(pagination.pageIndex + 1, pagination.pageSize, '', sorting)}/>
      ]}
      withServerSideSorting
      onSorting={(sorting, searchString) => onSorting(sorting, searchString)}
    />
  )
}

export default ConnectionsTable
