import React from 'react';
import numeral from 'numeral';
import ReactTable from 'react-table-v6';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';

const dateSort = (a, b) => {
  a = a ? moment(a, 'YYYY-MM-DD') : moment().add(10, 'years');
  b = b ? moment(b, 'YYYY-MM-DD') : moment().add(10, 'years');
  return b.isBefore(a) ? 1 : -1;
};

const tableConfig = {
  included: { columns: ['name', 'owner', 'billingCycle', 'typeOfCharge', 'billingStart', 'billingEnd', 'lastBilled', 'validToDate', 'rate', 'amount'], sortable: true },
  excluded: { columns: ['name', 'owner', 'billingCycle', 'typeOfCharge', 'goLiveDate', 'lastBilled', 'validToDate'], sortable: true },
  errors: { columns: ['name', 'errors'] },
  inCredit: { columns: ['name', 'owner', 'billingCycle', 'typeOfCharge', 'lastBilled', 'validToDate'] },
  retiring: { columns: ['name', 'owner', 'typeOfCharge', 'lastBilled', 'validToDate'] },
  ceasePending: { columns: ['name', 'owner', 'billingCycle', 'typeOfCharge', 'billingStart', 'billingEnd', 'lastBilled', 'validToDate', 'rate', 'amount'] }
};

const BillPreviewItems = ({ items, hasGroupedItems, optionSets, tableType, downloadVoiceArtifact }) => {
  const typeOfCharge = optionSets.product.typeOfCharge.options;
  const filterTypeOfCharge = (value) =>{
    return typeOfCharge.filter(option => option.value === value);
  };
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 200,
      Cell: props => {
        return <div className={'clearfix'}>
                    <a href={props.original.url} target="_blank" className={'text-primary text-wrap'}>{props.value}</a>
                    {props.original.artifact
                        && <div style={{ cursor: 'pointer' }} className="float-right text-danger h4 mb-0" onClick={(downloadVoiceArtifact ? downloadVoiceArtifact.bind(null, props.original) : downloadVoiceArtifact)}>
                            <i id={'artifact' + props.original.artifact} className="icon-cloud-download"></i>
                            <UncontrolledTooltip target={'artifact' + props.original.artifact}>
                                Download call records
                            </UncontrolledTooltip>
                        </div>
                    }
                </div>;
      }
    },
    {
      Header: 'Assigned To',
      accessor: 'owner',
      minWidth: 200,
      Cell: props => {
        return (
                    <div>
                        <p><span id={'assignedTo' + props.original.id}>{props.value.substring(0, 100)}</span>{props.value.length > 100 ? '...' : ''}</p>
                        {props.value.length > 100
                        && <UncontrolledTooltip placement="right" target={'assignedTo' + props.original.id}>
                            {props.value}
                        </UncontrolledTooltip>
                        }
                    </div>
        );
      }
    },
    {
      Header: 'Cycle',
      accessor: 'billingCycle'
    },
    {
      Header: 'Type',
      accessor: 'typeOfCharge',
      Cell: props => {
        const option = filterTypeOfCharge(props.value);
        return props.value && option ? option[0].label : '';
      }
    },
    {
      Header: 'Billing Start',
      accessor: 'billingStart',
      sortMethod: dateSort
    },
    {
      Header: 'Billing End',
      accessor: 'billingEnd',
      sortMethod: dateSort
    },
    {
      Header: 'Billed To',
      accessor: 'lastBilled',
      sortMethod: dateSort
    },
    {
      Header: 'Valid To',
      accessor: 'validToDate',
      sortMethod: dateSort
    },
    {
      Header: 'Rate',
      accessor: 'rate',
      Cell: props => (
        '£' + numeral(props.value).format('0,0.00')
      )
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: props => (
        '£' + numeral(props.value).format('0,0.00')
      )
    },
    {
      Header: 'Go Live Date',
      accessor: 'goLiveDate',
      sortMethod: dateSort
    },
    {
      Header: 'Error',
      accessor: 'errors',
      minWidth: 200,
      Cell: props => {
        return (
                    <ul>
                        {props.value.map((error, index) => <li key={'error' + index}>{error}</li>)}
                    </ul>
        );
      }
    }
  ];
  let filteredColumns = columns.filter(column => tableConfig[tableType].columns.includes(column.accessor));
  if (hasGroupedItems) {
    filteredColumns.unshift({
      Header: 'Group',
      accessor: 'group',
      minWidth: 200,
      Cell: props => (
                <a href={props.original.groupUrl} target="_blank" className={'text-primary text-wrap'}>{props.value}</a>
      )
    },);
  }
  return (
        <ReactTable
            className="animated fadeIn -highlight"
            NoDataComponent={() => null}
            data={items}
            columns={filteredColumns}
            minRows="2"
            filterable={items.length > 10}
            showPagination={items.length > 10}
            resizable={false}
            defaultPageSize={10}
            getTheadProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadTrProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadThProps={() =>{
              return {
                style: { border: 'none', whiteSpace: 'unset' }
              };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                style: { whiteSpace: 'unset', fontSize: '10pt' }
              };
            }}
            sortable={tableConfig[tableType].sortable === true}
            defaultSorted={tableConfig[tableType].sortable ? [{ id: 'name', desc: false }] : []}
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
        />
  );
};

function mapStateToProps({ helpers }) {
  return {
    optionSets: helpers.optionSets
  };
}
export default connect(mapStateToProps)(BillPreviewItems);
