import { api_getCloudWatchLog, api_getCloudWatchLogs } from '../utils/Logs';
import { defaultErrorFeedback } from './feedback';

export const LOGS_SET_LIST = 'LOGS_SET_LIST';
export const LOGS_SET_ERROR = 'LOGS_SET_ERROR';
export const LOGS_SET_LOG = 'LOGS_SET_LOG';
export const LOGS_TOGGLE_LOG_MODAL = 'LOGS_TOGGLE_LOG_MODAL';
export const LOGS_SET_SEARCH_STRING = 'LOGS_SET_SEARCH_STRING';

export function setLogList(data) {
  return {
    type: LOGS_SET_LIST,
    data
  };
}

export function setLogsSearchString(searchString) {
  return {
    type: LOGS_SET_SEARCH_STRING,
    searchString
  };
}

export function setLogError(data) {
  return {
    type: LOGS_SET_ERROR,
    data
  };
}

export function setSelectedLog(log) {
  return {
    type: LOGS_SET_LOG,
    log
  };
}

export function toggleLogModal() {
  return {
    type: LOGS_TOGGLE_LOG_MODAL
  };
}

export function getCloudWatchLogs(group, from, to, level) {
  return (dispatch) => {
    return api_getCloudWatchLogs(group, from, to, level)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch(setLogList(result.data));
          } else {
            dispatch(defaultErrorFeedback());
          }
        }
      );
  };
}

export function getCloudWatchLog(group, id) {
  return () => {
    return api_getCloudWatchLog(group, id)
      .then(
        (result) => {
          return result;
        }
      );
  };
}
