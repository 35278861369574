import moment from 'moment/moment';
import { ButtonIcon } from '../../ButtonIcon';
import React, { useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { Button, Badge } from 'reactstrap';
import { tableDateCompare } from '../../../utils/Helpers/TableDateCompare';
import { SelectMod } from '../../Selects/SelectMod';
import isEmpty from 'lodash.isempty';
import { useNavigate } from 'react-router-dom'


const statusOptions = [
  {label: 'All', value: null},
  {label: 'Available', value: 'available'},
  {label: 'Active', value: 'active'},
  {label: 'Expired', value: 'expired'},
  {label: 'Invalid', value: 'invalid'}
]

const LicencesTable = ({
  licenses,
  canEdit,
  fetchData,
  minRows,
  onDetach,
  onAttach,
  withoutNew,
  withoutFetch,
  extraButtons
}) => {

  const navigate = useNavigate();

  const [statusFilter, setStatusFilter] = useState({
    value: null,
    label: 'All'
  });
  const onStatusFilterChange = (selected, tableProps) => {
    tableProps.column.setFilterValue(selected.value)
    setStatusFilter(selected)
  };

  const isInvalid = license => moment(license.issuedAt).isAfter(moment().startOf('day'))
  const isExpired = license => moment(license.expiredAt).isBefore(moment().endOf('day'))
  const isAvailable = license => license.stockItemsCount < license.quota


  const getText = (license) => {
    if (isInvalid(license)) {
      return 'Invalid';
    }
    if (isExpired(license)) {
      return 'Expired';
    }
    if (isAvailable(license)) {
      return 'Available';
    }
    return 'Active';
  }

  const getBadgeColor =  (license) => {
    if (isInvalid(license)) {
      return 'warning';
    }
    if (isExpired(license)) {
      return 'danger';
    }
    if (isAvailable(license)) {
      return 'success';
    }
    return 'info';
  }
  const columns = [
    {
      header: 'Reference',
      accessorKey: 'reference'
    },
    {
      header: 'Activation Code',
      accessorKey: 'activationCode'
    },
    {
      header: 'Device Model',
      id: 'deviceModel',
      accessorFn: row => !isEmpty(row.deviceModel) ? `${row.deviceModel.vendor.name} ${row.deviceModel.name}` : ''
    },
    {
      header: 'Issued Date',
      id: 'issuedAt',
      cell: props => moment(props.getValue()).format('DD/MM/YYYY'),
      accessorFn: row => row.issuedAt ?? undefined,
      sortUndefined: 'last'
    },
    {
      header: 'Supported Until',
      id: 'supportedUntil',
      cell: props => props.getValue() ? moment(props.getValue()).format('DD/MM/YYYY') : null,
      sortingFn: (a, b) => tableDateCompare('supportedUntil', a,b),
      accessorFn: row => row.supportedUntil ?? undefined,
      sortUndefined: 'last'
    },
    {
      header: 'Expiry',
      id: 'expiredAt',
      accessorFn: row => row.expiredAt ?? undefined ,
      cell: props => props.getValue() ? moment(props.getValue()).format('DD/MM/YYYY') : null,
      sortUndefined: 'last'

    },
    {
      header: 'Quota',
      accessorKey: 'quota' ,
      sortUndefined: 'last'

    },
    {
      header: 'In Use',
      accessorKey: 'stockItemsCount' ,
      sortUndefined: 'last'

    },
    {
      accessorKey: 'id',
      enableSorting: false,
      header:  (props) => {
        return <SelectMod
          options={statusOptions}
          isSearchable
          value={statusFilter}
          onChange={(selected) => onStatusFilterChange(selected, props)}
          size={'sm'}
        />
      },
      cell: props => (
        <div className={'d-flex justify-content-between'}>
          <Badge className={`p-2 text-wrap faded-${getBadgeColor(props.row.original)}`}  style={{ minWidth: '80px' }}>
            {getText(props.row.original)}
          </Badge>
          {onDetach && canEdit ? (
            <Button
              className={'hover-content'}
              size={'sm'}
              onClick={(e) => {
                e.stopPropagation();
                onDetach(props.row.original.id);
              }}
            >Detach</Button>
          ) : ''}
          {onAttach && canEdit ? (
            <Button
              className={'hover-content'}
              size={'sm'}
              onClick={(e) => {
                e.stopPropagation();
                onAttach(props.row.original.id);
              }}
            >Attach</Button>
          ) : ''}
        </div>
      ),
      accessorFn: row => getText(row),
      filterFn: (row, columnId, value) => {
        if(value === 'available'){
          return isAvailable(row.original)
        } else if(value === 'invalid'){
          return isInvalid(row.original)
        } else if(value === 'expired'){
          return isExpired(row.original)
        } else if(value === 'active'){
          return !isAvailable(row.original) && !isInvalid(row.original) && !isExpired(row.original)
        } else {
          return true
        }

      },
    }
  ]

  const getButtons = () => {
    const buttons = []
    if(!withoutNew){
      buttons.push( <Button size={'sm'} color={'secondary'} onClick={() => {
        navigate(`/sdb/licenses/new`)
      }}>New Licence</Button>)
    }
    if(!withoutFetch){
      buttons.push( <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>)
    }
    return extraButtons? buttons.concat(extraButtons) : buttons
  }

  return (
    <FilteredTableV2
      withFilter={'includesString'}
      minRows={minRows ?? 10}
      columns={columns}
      data={licenses ?? []}
      noDataText="No Licences"
      showPagination={licenses?.length > (minRows ?? 10)}
      defaultPageSize={minRows ?? 10}
      defaultSorted={[{
        id: 'expiredAt',
        desc: false
      }]}
      onRowClick={(license) => {
        navigate(`/sdb/licenses/${license.id}`)
      }}
      extraButtons={getButtons()}
    />
  )
}


export default LicencesTable
