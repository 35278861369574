import { apiRequest } from '../Api/ApiRequest';

export const ConnectionComponentService = {
  createConnectionComponent: (data) => {
    return apiRequest({
      url: `/sdb/connections/components`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  updateConnectionComponent: (id, data) => {
    return apiRequest({
      url: `/sdb/connections/components/${id}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  deleteConnectionComponent: (id) => {
    return apiRequest({
      url: `/sdb/connections/components/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }

};