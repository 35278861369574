import { compareApiRequest } from '../Api/ApiRequest';

export function api_getPricingToolCarriers(args) {
  return compareApiRequest({
    url: `/carriers?include=products,technologies,nnis`
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getPricingToolFeasibilities(args) {
  return compareApiRequest({
    url: `/feasibilities`
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getPricingToolKits(args) {
  return compareApiRequest({
    url: `/kits?include=technology`
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getPricingToolInstallTypes(args) {
  return compareApiRequest({
    url: `/installs`
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getPricingToolNNIs(args) {
  return compareApiRequest({
    url: `/nnis`
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getPricingToolTechnologies(args) {
  return compareApiRequest({
    url: `/technologies`
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getPricingToolCarrierProducts(args) {
  return compareApiRequest({
    url: `/carriers/products`
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}
