import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const GenericModal = ({ show, toggle, title, onClosed, className, children }) => {
  return (
    <Modal isOpen={show} toggle={toggle} onClosed={onClosed} className={className || 'modal-xlg'}
           backdrop="static">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{show ? children : ''}</ModalBody>
    </Modal>
  );
};
