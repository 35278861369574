export const General = {
  topic: { label: 'Topic', type: 'text', mandatory: true },
  company: { label: 'Company', type: 'text', mandatory: true },
  industryType: { label: 'Industry Type', type: 'select'},
  totalEmployees: { label: 'Total Employees', type: 'text'},
  salutation: { label: 'Salutation', type: 'text'},
  firstName: { label: 'First Name', type: 'text', mandatory: true },
  lastName: { label: 'Last Name', type: 'text', mandatory: true },
  jobTitle: { label: 'Job Title', type: 'text' },
  email: { label: 'Email', type: 'text' },
  phone: { label: 'Phone', type: 'text' },
  campaign: { label: 'Source Campaign', type: 'select' },
  owner: { label: 'Owner', type: 'select-async', mandatory: true, entity: 'owner' },
  website: { label: 'Website', type: 'text'},
  calledIn: { label: 'Called In', type: 'select-boolean'},
  emailEnquiry: { label: 'Email Enquiry', type: 'select-boolean'},
  potentialBusinessPartner: { label: 'Potential Business Partner', type: 'select-boolean'},
  lastEmailContact: { label: 'Last Email Contact', type: 'date'},
  lastPhoneContact: { label: 'Last Phone Contact', type: 'date'},
  description: { label: 'Description', type: 'textarea', width: 12, mandatory: true
  }
};

export const Address = { addressLine1: { label: 'Street 1', type: 'text' },
  addressLine2: { label: 'Street 2', type: 'text' },
  addressLine3: { label: 'Street 3', type: 'text' },
  addressCity: { label: 'City', type: 'text' },
  addressPostcode: { label: 'Postcode', type: 'text' },
  addressCountry: { label: 'Country', type: 'text' }
};

export const Preferences = {
  bulkEmail: { label: 'Bulk Email', type: 'select-boolean', width: 3 },
  sendEmail: { label: 'Email', type: 'select-boolean' , width: 3},
  callPhone: { label: 'Phone', type: 'select-boolean', width: 3 },
  postalMail: { label: 'Post', type: 'select-boolean',width: 3 },
  followEmail: { label: 'Track Email', type: 'select-boolean', width: 3 },
  marketingMaterial: { label: 'Marketing', type: 'select-boolean', width: 3},
};
