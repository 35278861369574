import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import classnames from 'classnames';

const EntitySubFormCard = ({
  title,
  children,
  headerExtraRight,
  grow
}) => {


  return (
    <Card className={classnames('shadow-sm', { 'flex-1': grow })}>
      <CardHeader className={"d-flex justify-content-between font-weight-bold bg-secondary text-primary font-lg"}>
        {title}
        {headerExtraRight}
      </CardHeader>
      <CardBody>
        {children}
      </CardBody>
    </Card>
  )

}

export default EntitySubFormCard
