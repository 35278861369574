import React, {Component} from 'react';
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import {Card, Row, Col} from 'reactstrap';
import {SpinnerInfo} from '../../components/Spinner/Spinner';
import {fetchSnaps, SNAP_ERROR, takeSnap} from '../../actions/jsnapy';
import {ScaleLoader} from 'react-spinners';
import ReactTable from "react-table-v6";
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import ReactTooltip from "react-tooltip";
import AddRouter from "./CoreSnapAddRouter";
import {submitCheckSnaps} from "../../utils/CoreSnap/CoreSnapService";
import {FatalError} from "../../components/Errors/Errors";
import { useNavigate } from 'react-router-dom'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '35px',
    height: '35px',
    boxShadow: state.isFocused ? null : null,
    cursor: 'pointer'
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  option: (provided) => ({
    ...provided,
    cursor: 'pointer'
  }),
};

class CoreSnapLegacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      addRouter: false,
      loadError: false,
      snaps: {}
    };
    this.handleOptionCreate = this.handleOptionCreate.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchSnaps());

    let snapObj = {}
    this.interval = setInterval(() => {
      if (this.props.devices.length > 0) {
        this.props.devices.forEach((hosts) => {
          let pre = 0;
          let post = 0
          if (hosts.snaps.length > 1) {
            pre = 1;
          }
          snapObj[hosts.hostname] = {'pre': pre, "post": post}
        })
        clearInterval(this.interval);
        this.setState({snaps: snapObj});
      }
    }, 500)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.snapError !== this.props.snapError) {
      setTimeout(() => this.props.dispatch({type: SNAP_ERROR, result: false}), 2000);
    }
  }

  handleCheck = ({hostname, snaps}) => {
    const pre = snaps[this.state.snaps[hostname].pre];
    const post = snaps[this.state.snaps[hostname].post];
    this.setState({hoverCheck: false})
    if (pre.description === post.description) {
      this.props.dispatch({type: SNAP_ERROR, result: {hostname: hostname, msg: 'Snaps cant be the same'}});
      return
    }
    if (moment.unix(pre.timestamp).isAfter(moment.unix(post.timestamp))) {
      this.props.dispatch({type: SNAP_ERROR, result: {hostname: hostname, msg: 'Pre snap is newer than post'}});
      return
    }
    submitCheckSnaps(pre.description, post.description, hostname + '.hso-group.net')
      .then((response) => {
        if (response.status === 200) {
          this.props.navigate('/toolbox/coresnap/' + response.data.uuid);
        }
      }).catch(err => this.props.dispatch({type: SNAP_ERROR, result: {hostname: hostname, msg: err}}));
  }

  handleSelectChange = (inputValue, action, {hostname, snaps}, snapType) => {
    const index = snaps.findIndex(e => e.description === inputValue.description);
    let h = this.state.snaps[hostname];
    let newSnapIndexValue = {...h, [snapType]: index}
    this.setState({snaps: {...this.state.snaps, [hostname]: newSnapIndexValue}});
  }

  handleOptionCreate = (snapName, hostName) => {
    if (snapName) {
      this.props.dispatch(takeSnap(snapName, hostName));
    }
  }

  formatOptions = (obj, snaps) => {
    const index = snaps.findIndex(e => e.description === obj.description)

    return obj.__isNew__ ? obj.label :
      `Revision:${index} - ${obj.description} - ${moment.unix(obj.timestamp).fromNow()}`
  }

  render() {
    if (this.props.snapFetchError) {
      return <Row><Col><FatalError message={this.props.snapFetchError.data?.message}/></Col></Row>
    }
    const columns = [
      {
        Header: () => (<h5 className="m-0 pl-2">Hostname</h5>),
        filterable: true,
        accessor: 'hostname',
        headerClassName: 'd-flex align-items-center justify-content-start pl-2',
        className: 'd-flex align-items-center justify-content-start ',
        Cell: (e) => (
          <Row className="w-100">
            <Col xs={12} sm={10}>
              <h5 className="m-0 pl-2">{e.original.hostname}</h5>
            </Col>
            {
              this.props.snapError && this.props.snapError.hostname === e.original.hostname ?
                <Col xs={12} sm={10}>
                  <pre className="m-0 pl-2" style={{color: 'red'}}>{this.props.snapError.msg}</pre>
                </Col> : null
            }

          </Row>
        )
      },
      {
        Header: () => (<h5 className="m-0 pl-2">Pre-Snap</h5>),
        Cell: ({original}) => <CreatableSelect options={original.snaps}
                                               styles={customStyles}
                                               onChange={(obj, action) => this.handleSelectChange(obj, action, original, 'pre')}
                                               value={this.state.snaps[original.hostname] ?
                                                 original.snaps[this.state.snaps[original.hostname].pre] : null
                                               }
                                               onCreateOption={(i) => this.handleOptionCreate(i, original.hostname)}
                                               formatOptionLabel={(obj) => this.formatOptions(obj, original.snaps)}
                                               getOptionValue={obj => obj}
                                               formatCreateLabel={(e) => `Create snapshot: ${e}`}
                                               menuPortalTarget={document.body}
        />,
        headerClassName: 'd-flex align-items-center justify-content-start',
      },
      {
        width: 50,
        Cell: (<i className="fa fa-2x fa-arrows-h"/>),
        className: 'd-flex align-items-center justify-content-start ',
      },
      {
        Header: () => (<h5 className="m-0 pl-2">Post-Snap</h5>),
        Cell: ({original}) => <CreatableSelect options={original.snaps}
                                               styles={customStyles}
                                               onChange={(obj, action) => this.handleSelectChange(obj, action, original, 'post')}
                                               value={this.state.snaps[original.hostname] ?
                                                 original.snaps[this.state.snaps[original.hostname].post] : null
                                               }
                                               onCreateOption={(i) => this.handleOptionCreate(i, original.hostname)}
                                               formatOptionLabel={(obj) => this.formatOptions(obj, original.snaps)}
                                               getOptionValue={obj => obj}
                                               formatCreateLabel={(e) => `Create snapshot: ${e}`}
                                               menuPortalTarget={document.body}
        />,
        headerClassName: 'd-flex align-items-center justify-content-start',
      },
      {
        Header: (
          <div
            className="d-flex align-items-center h4 text-danger float-right mb-0"
            style={{cursor: 'pointer'}}
            onClick={() => this.setState({addRouter: !this.state.addRouter})}
          >
            <i
              className="icon-plus"
              data-for="add"
              data-tip="add"
            />
            <ReactTooltip
              id="add"
              type="dark"
              place="left"
              effect="float"
              multiline={false}
            >add router
            </ReactTooltip>
          </div>
        ),
        width: 50,
        Cell: ({original}) => (
          this.props.snapCreating === original.hostname ? <ScaleLoader size='5' color="red"/> :
            this.props.snapError && this.props.snapError.hostname === original.hostname ?
              <Col className="d-flex justify-content-center">
                <i className="fa fa-times-circle" style={{color: 'red'}}/>
              </Col>
              :
              <>
                <Col className="d-flex justify-content-center">
                  <i id={original.hostname}
                     className={`fa ${this.state.hoverCheck === original.hostname ? 'fa-2x' : null} fa-check`}
                     data-for="check"
                     data-tip="check"
                     style={{cursor: 'pointer', color: "green"}}
                     onClick={() => this.handleCheck(original)}
                     onMouseEnter={() => this.setState({hoverCheck: original.hostname})}
                     onMouseLeave={() => this.setState({hoverCheck: null})}
                  />
                  <ReactTooltip
                    id="check"
                    type="dark"
                    place="right"
                    effect="float"
                    multiline={false}
                  >Check
                  </ReactTooltip>
                </Col>
              </>
        ), className: "d-flex align-items-center justify-content-end"
      },
    ]

    if (this.props.snapFetching) {
      return <Col><SpinnerInfo/></Col>
    }
    return (
      <div className="animated fadeIn">

        <Row>
          <Col>
            <Card className="d-flex justify-content-center align-items-center">

              {
                this.state.addRouter ? <AddRouter {...this.props} /> : null
              }

              <ReactTable className="p-4 pt-2 w-100"
                          data={this.props.devices}
                          columns={columns}
                          minRows={5}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const CoreSnap = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return <CoreSnapLegacy {...props} navigate={navigate} dispatch={dispatch}/>
}

function mapStateToProps({jsnapy}) {
  return {
    devices: jsnapy.devices,
    snapFetching: jsnapy.snapFetching,
    snapCreating: jsnapy.snapCreating,
    snapChecking: jsnapy.snapChecking,
    snapError: jsnapy.snapError,
    snapFetchError: jsnapy.snapFetchError
  };
}

export default connect(mapStateToProps)(CoreSnap);
