import { defaultErrorFeedback } from './feedback';
import AuditService from '../utils/Audit';
import { CrossConnectService } from '../utils/ServiceDB/CrossConnect';

export const CROSS_CONNECT_SET_AUDIT_HISTORY = 'CROSS_CONNECT_SET_AUDIT_HISTORY';
export const CROSS_CONNECT_SET_DATA = 'CROSS_CONNECT_SET_DATA';
export const CROSS_CONNECT_SET_FIELD = 'CROSS_CONNECT_SET_FIELD';
export const CROSS_CONNECT_RESET = 'CROSS_CONNECT_RESET';
export const CROSS_CONNECT_REMOVE_PRODUCT = 'CROSS_CONNECT_REMOVE_PRODUCT';
export const CROSS_CONNECT_ADD_NOTE = 'CROSS_CONNECT_ADD_NOTE';
export const CROSS_CONNECT_UPDATE_NOTE = 'CROSS_CONNECT_UPDATE_NOTE';
export const CROSS_CONNECT_REMOVE_NOTE = 'CROSS_CONNECT_REMOVE_NOTE';


function setCrossConnectData(data) {
  return {
    type: CROSS_CONNECT_SET_DATA,
    data
  };
}

export function updateCrossConnectField(field, value) {
  return {
    type: CROSS_CONNECT_SET_FIELD,
    field,
    value
  };
}


export function resetCrossConnect() {
  return {
    type: CROSS_CONNECT_RESET
  };
}

export function removeCrossConnectProduct(id) {
  return {
    type: CROSS_CONNECT_REMOVE_PRODUCT,
    id
  };
}

export function addCrossConnectNote(note) {
  return {
    type: CROSS_CONNECT_ADD_NOTE,
    note
  };
}

export function updateCrossConnectNote(note) {
  return {
    type: CROSS_CONNECT_UPDATE_NOTE,
    note
  };
}

export function removeCrossConnectNote(note) {
  return {
    type: CROSS_CONNECT_REMOVE_NOTE,
    note
  };
}

export function getCrossConnect(id, include = []) {
  return (dispatch) => {
    return CrossConnectService.getCrossConnect(id, include).then((result) => {
      if (result.status === 200) {
        dispatch(setCrossConnectData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createCrossConnect(args, include = []) {
  return (dispatch) => {
    return CrossConnectService.createCrossConnect(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setCrossConnectData(result.data));
        return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      }
      dispatch(defaultErrorFeedback());
      return null;
    });
  };
}

export function updateCrossConnect(id, args, include = []) {
  return (dispatch) => {
    return CrossConnectService.updateCrossConnect(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setCrossConnectData(result.data));
        return result.data
      }else if(result.status === 422){
        return {errors: result.data}
      }
      dispatch(defaultErrorFeedback());
      return null;
    });
  };
}

export function deleteCrossConnect(id) {
  return (dispatch) => {
    return CrossConnectService.deleteCrossConnect(id)
      .then((result) => {
        if (result.status === 204) {
          return true;
        }
        dispatch(defaultErrorFeedback());
        return false;
      });
  };
}

export function getCrossConnectAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('sdb/connections/cross-connects', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: CROSS_CONNECT_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}