import React, { useEffect, useState } from 'react';
import { getCrossConnects } from '../../../actions/crossConnects';
import { Card, CardBody } from 'reactstrap';
import LoadingOverlay from '../../../components/LoadingOverlay';
import CrossConnectsTable from '../../../components/Tables/CrossConnects';
import { connect, useDispatch } from 'react-redux'

const CrossConnects = ({
  crossConnects,
  suppliers
}) => {
  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }
  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    toggleLoading()
    dispatch(getCrossConnects( ['component', 'component.connection']))
      .then(() => toggleLoading());
  };


  return (
    <Card className="w-100">
      <CardBody>
        <LoadingOverlay loading={loading}>
          <CrossConnectsTable
            createNew
            crossConnects={crossConnects.list ?? []}
            minRows={20}
            suppliers={suppliers}
            fetchData={fetchData}
          />
        </LoadingOverlay>
      </CardBody>
    </Card>
  )
}

function mapStateToProps({ crossConnects, helpers }) {
  return {
    crossConnects,
    suppliers: helpers.suppliers
  };
}

export default connect(mapStateToProps)(CrossConnects);
