import { api_getCases, api_searchCases } from '../utils/Cases/CasesService';
import { defaultErrorFeedback } from './feedback';

export const CASES_SET_CASES = 'CASES_SET_CASES';
export const CASES_ADD_CASE = 'CASES_ADD_CASE';
export const CASES_UPDATE_CASE = 'CASES_UPDATE_CASE';
export const CASES_SET_SEARCH_STRING = 'CASES_SET_SEARCH_STRING';

export function setCasesSearchString(search, optionSets = []) {
  return {
    type: CASES_SET_SEARCH_STRING,
    search,
    optionSets
  };
}

export function setCases(cases) {
  return {
    type: CASES_SET_CASES,
    cases
  };
}

export function addCasesCase(theCase) {
  return {
    type: CASES_ADD_CASE,
    theCase
  };
}

export function updateCasesCase(theCase) {
  return {
    type: CASES_UPDATE_CASE,
    theCase
  };
}

export function getCases(args = {}) {
  return (dispatch) => {
    return api_getCases(args)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch(setCases(result.data));
            return result.data;
          }
          dispatch(defaultErrorFeedback());
          return false;
        }
      );
  };
}

export function searchCases(number) {
  return (dispatch) => {
    return api_searchCases(number).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setCases(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    );
  }
}
