import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';
import isEmpty from 'lodash.isempty';

export const CarrierCircuitService = {
  getAvailableCarrierCircuits: () => {
    let query = {
      include: ['opticalSystem', 'component', 'component.connection', 'type', 'aEnd'],
    }
    return apiRequest({
      url: `/sdb/connections/carrier-circuits/available${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getCarrierCircuits: (page, pageSize, search, sorting) => {
    let query = {
      search,
      include: ['items.opticalSystem', 'items.component', 'items.component.connection', 'items.type'],
      sortedBy: !isEmpty(sorting) ? (sorting[0].desc ? 'desc' : 'asc') : null,
      orderBy: !isEmpty(sorting) ? sorting[0].id : null,
      page,
      pageSize
    }
    if(isEmpty(search)){

      delete query.search
    }
    return apiRequest({
      url: `/sdb/connections/carrier-circuits${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getCarrierCircuit: (id, include = []) => {
    return apiRequest({
      url: `/sdb/connections/carrier-circuits/${id}${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  createCarrierCircuit: (data, include = []) => {
    return apiRequest({
      url: `/sdb/connections/carrier-circuits${queryStringFromFields({ include })}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  updateCarrierCircuit: (id, data, include = []) => {
    return apiRequest({
      url: `/sdb/connections/carrier-circuits/${id}${queryStringFromFields({ include })}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  deleteCarrierCircuit: (id) => {
    return apiRequest({
      url: `/sdb/connections/carrier-circuits/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  nni: {
    list: (id) => {
      return apiRequest({
        url: `/sdb/nnis/${id}/carrier-circuits?include=component,opticalSystem`,
        method: 'get'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  }

};