import isEmpty from 'lodash.isempty';

const SiteForm = (handleClick, isNew) => {
  return {
    name: {
      label: 'Name',
      type: 'text',
      mandatory: true,
      maxlength: 160
    },
    accountnumber: {
      label: 'Account Number',
      type: 'text',
      readOnly: true
    },
    addressLine1: {
      label: 'Address Line 1',
      type: 'text',
      mandatory: true,
      maxlength: 250
    },
    addressLine2: {
      label: 'Address Line 2',
      type: 'text',
      maxlength: 250
    },
    addressLine3: {
      label: 'Address Line 3',
      type: 'text',
      maxlength: 250
    },
    addressCity: {
      label: 'City',
      type: 'text',
      mandatory: true,
      maxlength: 80
    },
    addressCounty: {
      label: 'County',
      type: 'text',
      maxlength: 50
    },
    addressCountry: {
      label: 'Country',
      type: 'select',
      mandatory: true
    },
    addressPostcode: {
      label: 'Postcode',
      type: 'text',
      mandatory: true,
      maxlength: 20
    },
    telephone: {
      label: 'Phone',
      type: 'text'
    },
    primaryContact: {
      label: 'Primary Contact',
      type: 'select',
      entity: 'contact',
      handleClick: () => {
        handleClick()
      },
      clearable: true,
      mandatory: true,
      readOnly: (key, field, data, permissions) => !isNew && isEmpty(data.accountid)
    },
  }
}
export default SiteForm
