import {
  addNetApiRrGroup,
  delNetAPIRrGroup,
  getNetAPIDevices,
  getNetAPINetworks,
  getNetAPIRrGroups,
  setNetAPIRrGroup, setNetApiRrGroupMemberMode, updateNetApiDevice, submitArpScannerJob
} from "../utils/NetAPI/NetAPI";
export const NETAPI_SET_LOADING_STATE = 'NETAPI_SET_LOADING_STATE';
export const NETAPI_SET_DEVICES_DATA = 'NETAPI_SET_DEVICES_DATA';
export const NETAPI_SET_RRGROUPS_DATA = 'NETAPI_SET_RRGROUPS_DATA';
export const NETAPI_SET_NETWORKS_DATA = 'NETAPI_SET_NETWORKS_DATA';


export function netapi_setLoadingState(item, state = 0) {
  return {
    type: NETAPI_SET_LOADING_STATE,
    item,
    state
  }
}

export function netapi_setDevicesData(data) {
  return {
    type: NETAPI_SET_DEVICES_DATA,
    data
  }
}

export function netapi_setRrGroupsData(data) {
  return {
    type: NETAPI_SET_RRGROUPS_DATA,
    data
  }
}

export function netapi_setNetworksData(data) {
  return {
    type: NETAPI_SET_NETWORKS_DATA,
    data
  }
}

export function netapi_getDevices() {
  return (dispatch) => {
    dispatch(netapi_setLoadingState('devices', 1));
    return getNetAPIDevices().then((result) => {
      if (result.status === 200) {
        dispatch(netapi_setLoadingState('devices', 0));
        dispatch(netapi_setDevicesData(result.data))
      }
      else {
        dispatch(netapi_setLoadingState('devices', -1));
      }
    }).catch(() => {
      dispatch(netapi_setLoadingState('devices', -1));
    });
  }
}

export function netapi_getRrGroups(detail = true) {
  return (dispatch) => {
    dispatch(netapi_setLoadingState('rrgroups', 1));
    return getNetAPIRrGroups(detail).then((result) => {
      if (result.status === 200) {
        dispatch(netapi_setLoadingState('rrgroups', 0));
        dispatch(netapi_setRrGroupsData(result.data))
      }
      else {
        dispatch(netapi_setLoadingState('rrgroups', -1));
      }
    }).catch(() => {
      dispatch(netapi_setLoadingState('rrgroups', -1));
    });
  }
}


export function netapi_setRrGroupOnDevice(group, dev, is_primary, is_secondary) {
  return (dispatch) => {
    return setNetAPIRrGroup(group, dev, is_primary, is_secondary);
  }
}

export function netapi_delRrGroupOnDevice(group, id) {
  return (dispatch) => {
    return delNetAPIRrGroup(group, id);
  }
}

export function netapi_getNetworks() {
  return (dispatch) => {
    dispatch(netapi_setLoadingState('networks', 1));
    return getNetAPINetworks().then((result) => {
      if (result.status === 200) {
        dispatch(netapi_setLoadingState('networks', 0));
        dispatch(netapi_setNetworksData(result.data))
      }
      else {
        dispatch(netapi_setLoadingState('networks', -1));
      }
    }).catch(() => {
      dispatch(netapi_setLoadingState('networks', -1));
    });
  }
}

export function netapi_updateDevice(device, data) {
  return (dispatch) => {
    return updateNetApiDevice(device, data);
  }
}

export function netapi_setRrGroupMemberMode(group, memberid, mode) {
  return (dispatch) => {
    return setNetApiRrGroupMemberMode(group, memberid, mode).then((r) => {dispatch(netapi_getRrGroups(true))});
  }
}

export function netapi_addRrGroup(group_name, vrr_1, vrr_2) {
  return (dispatch) => {
    return addNetApiRrGroup(group_name, vrr_1, vrr_2).then((r) => {dispatch(netapi_getRrGroups(true))});
  }
}

export function netapi_submitArpScannerJob(hostname) {
  return (dispatch) => {
    return submitArpScannerJob(hostname);
  }
}
