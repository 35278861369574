import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import { getNNIs } from '../../../actions/nnis';
import NnisTable from '../../../components/Tables/Nnis';
import LoadingOverlay from '../../../components/LoadingOverlay';

const NNIs = ({ nnis }) => {

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    dispatch(getNNIs(['carrier', 'port.deviceDeployment']))
      .then(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetch();
  }, []);

  return (

    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <NnisTable
              nnis={nnis}
              fetchData={fetch}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  );
};

function mapStateToProps({ nnis, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    nnis: nnis.nnis
  };
}

export default connect(mapStateToProps)(NNIs);
