import {
  WIP_ITEM_ADD_NOTE,
  WIP_ITEM_SET_ITEM,
  WIP_ITEM_SET_ENTITY,
  WIP_ITEM_RESET,
  WIP_ITEM_UPDATE_ITEM,
  WIP_ITEM_TOGGLE_CIRCUIT_VIEW
} from '../actions/wipItem';


const initialState = {
  showCircuit: false,
  original: {
    lineItem: {},
    order: {},
    product: {},
    circuit: {}
  },
  lineItem: {},
  order: {},
  product: {},
  circuit: {},
  relatedAccounts: []
};
export default function wipItem(state = initialState, action) {
  switch (action.type) {
    case WIP_ITEM_ADD_NOTE:
      let entity = Object.assign({}, state[action.entityType]);
      if (entity.notes) {
        entity.notes.unshift(action.note);
      } else {
        entity.notes = [action.note];
      }
      return {
        ...state,
        [action.entityType]: entity
      };
    case WIP_ITEM_UPDATE_ITEM:
      return {
        ...state,
        [action.entity]: { ...state[action.entity], [action.field]: action.value }

      };
    case WIP_ITEM_SET_ENTITY:
      return {
        ...state,
        [action.entityType]: action.item,
        original: { ...state.original, [action.entityType]: action.item }
      };
    case WIP_ITEM_SET_ITEM:
      return {
        ...state,
        ...action.item
      };
    case WIP_ITEM_RESET:
      return {
        ...initialState
      };
    case WIP_ITEM_TOGGLE_CIRCUIT_VIEW:
      return {
        ...state,
        showCircuit: !state.showCircuit
      };
    default:
      return state;
  }
}
