import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Row, Col, Badge, Progress } from 'reactstrap';
import { SpinnerInfo } from '../../components/Spinner/Spinner';
import { TimeEvent, TimeSeries } from 'pondjs';
import { connect } from 'react-redux';
import { addJTISubs, resetJTIData, stopAllJTI, saveJTISubs } from '../../actions/JTI';
import { ExtTrafficService } from '../../utils/ExtTraffic/ExtTrafficService';
import BeatLoader from 'react-spinners/BeatLoader';

class ExtTraffic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: {}
    };
    this.props.dispatch(resetJTIData());
  }

  componentDidMount() {
    this.setState({ loading: true });
    ExtTrafficService.getExtTraffic().then((result) => {
      if (result.status === 200) {
        this.setState({ loading: false, data: result.data });
        this.startJTI();
      }
    }).catch((error) => {
      alert(error);
    });
  }

  startJTI() {
    let subs = {};
    for (let t in this.state.data) {
      for (let p in this.state.data[t]) {
        if (subs[this.state.data[t][p].loopback] === undefined) {
          subs[this.state.data[t][p].loopback] = [];
        }
        subs[this.state.data[t][p].loopback].push(this.state.data[t][p].interface);
      }
    }
    let startob = {
      subs: []
    };
    for (let k in subs) {
      startob.subs.push({
        loopback: k,
        interfaces: subs[k]
      });
    }
    this.props.dispatch(saveJTISubs(startob));
    this.props.dispatch(addJTISubs(startob));
  }

  componentWillUnmount() {
    this.props.dispatch(stopAllJTI());
    this.props.dispatch(resetJTIData());
  }

  bitsToSize(bits, dp = 4) {
    if (bits === undefined) {
      return (<BeatLoader color={'#aeaeae'} size={8} />);
    }
    const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
    if (bits === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(Math.abs(bits)) / Math.log(1000)), 10);
    if (i === 0) return `${bits.toFixed(dp)} ${sizes[i]}`;
    return `${(bits / (1000 ** i)).toFixed(dp)} ${sizes[i]}`;
  }

  getColor(rate, speed, classmode = false) {
    const edgeColorMap = [
      { color: '#e20200', label: '>=75%', range: [75, 100], class: 'danger' },
      { color: '#ff582c', label: '50 - 75%', range: [50, 75], class: 'warning' },
      { color: '#e89c3f', label: '40 - 50%', range: [40, 50], class: 'warning' },
      { color: '#016c59', label: '20 - 40%', range: [20, 40], class: 'success' },
      { color: '#238b45', label: '10 - 20%', range: [10, 20], class: 'success' },
      { color: '#3690c0', label: '1 - 10%', range: [1, 10], class: 'info' },
      { color: '#74a9cf', label: '0 - 1%', range: [0, 1], class: 'primary' }
    ];
    const perc = (rate / speed) * 100;
    for (let c in edgeColorMap) {
      if (perc >= edgeColorMap[c].range[0] && perc <= edgeColorMap[c].range[1]) {
        if (classmode === false) {
          return edgeColorMap[c].color;
        }

        return edgeColorMap[c].class;
      }
    }
    return '#000000';
  }

  render() {
    if (this.state.loading === true) {
      return (<div className="row justify-content-center">
                <SpinnerInfo/>
            </div>);
    }
    return (
            <div>
                <Row>
                {Object.keys(this.state.data).map((key) => {
                  let itotal = 0;
                  let etotal = 0;
                  return (
                        <Col md={4}>
                            <Card className="card-accent-info">
                                <CardHeader>{key}</CardHeader>
                                <CardBody>
                                    <Row style={{ marginBottom: '6px' }}><Col style={{ textAlign: 'right' }}><b>Ingress</b></Col><Col style={{ textAlign: 'right' }}><b>Egress</b></Col><Col></Col></Row>
                                {this.state.data[key].map((port) => {
                                  let ingress;
                                  let egress;
                                  if (this.props.jtidata[port.loopback] !== undefined && this.props.jtidata[port.loopback][port.interface] !== undefined) {
                                    const eventSeries = new TimeSeries({
                                      name: 'raw',
                                      events: this.props.jtidata[port.loopback][port.interface].egress.toArray()
                                    });
                                    let rate = eventSeries.rate({ fieldSpec: ['octets'] }).atLast();
                                    const ieventSeries = new TimeSeries({
                                      name: 'raw',
                                      events: this.props.jtidata[port.loopback][port.interface].ingress.toArray()
                                    });
                                    let irate = ieventSeries.rate({ fieldSpec: ['octets'] }).atLast();
                                    if (rate !== undefined && irate !== undefined) {
                                      itotal += (irate.get('octets_rate') * 8);
                                      etotal += (rate.get('octets_rate') * 8);
                                      ingress = irate.get('octets_rate') * 8;
                                      egress = rate.get('octets_rate') * 8;
                                    }
                                  }
                                  return (
                                        <Row style={{ marginTop: '6px', marginBottom: '6px' }}>
                                            <Col>{port.name}</Col>
                                            <Col style={{ textAlign: 'right', color: this.getColor(ingress, port.speed) }}>{this.bitsToSize(ingress, 2)}</Col>
                                            <Col style={{ textAlign: 'right', color: this.getColor(egress, port.speed) }}>{this.bitsToSize(egress, 2)}</Col>
                                            <Col>
                                                <Progress style={{ height: '4px', marginTop: '3px' }} value={(ingress / port.speed) * 100} color={this.getColor(ingress, port.speed, true)}/>
                                                <Progress style={{ height: '4px', marginTop: '3px' }} value={(egress / port.speed) * 100} color={this.getColor(egress, port.speed, true)}/>
                                            </Col>
                                        </Row>
                                  );
                                })}
                                    <Row><Col><b>TOTAL</b></Col><Col style={{ textAlign: 'right' }}><b>{this.bitsToSize(itotal, 2)}</b></Col><Col style={{ textAlign: 'right' }}><b>{this.bitsToSize(etotal, 2)}</b></Col><Col></Col></Row>
                                </CardBody>
                            </Card>
                        </Col>
                  );
                })}
                </Row>
         </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    jtidata: state.JTI.data,
    auth: state.authenticationState
  };
}

export default connect(mapStateToProps)(ExtTraffic);
