import React, { useState } from 'react';

import {
  ListGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  Alert
} from 'reactstrap';
import NoteListItem from './NoteListItem';
import HeadlessModal from '../Modals/HeadlessModal';
import Note from '../../views/Note';
import orderBy from 'lodash.orderby'
import { NoteService } from '../../utils/Note';
import fileDownload from 'js-file-download';
import { defaultErrorFeedback } from '../../actions/feedback';
import LoadingOverlay from '../LoadingOverlay';
import { connect } from 'react-redux';
const Notes = ({
  withNew,
  notes,
  onCreated,
  onUpdated,
  onDeleted,
  refreshData,
  entity,
  relatedTo,
  dispatch
}) => {
  const [loading, setLoading] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const toggleNoteModal = () => {
    setShowNoteModal(prevState => !prevState);
  };

  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }
  const showNote = (note) => {
    setSelectedNote(note)
    toggleNoteModal()
  }

  const downloadAttachment = (id) => {
    toggleLoading()
    NoteService.downloadAttachment(id).then(result => {
      if (result.status === 200) {
        const splitContentDisposition = result.headers['content-disposition'].split('=');
        fileDownload(result.data, splitContentDisposition[1].replaceAll('"', ''), result.headers['content-type']);
      } else {
        dispatch(defaultErrorFeedback());
      }
      toggleLoading()
    })
  }
  return (
    <div className={'animated fadeIn'}>
      <LoadingOverlay loading={loading}>
        {withNew &&
          <Row>
            <Col className={'d-flex justify-content-end'}>
              <Button size={'sm'} onClick={() => showNote(null)}>New Note</Button>
              {/* <ButtonIcon size={'sm'} icon={'fa fa-refresh'} onClick={refreshData}/>*/}
            </Col>
          </Row>
        }

        <Row className={'mt-2'} style={{overflow: 'scroll', maxHeight:'600px'}}>
          <Col>
            <ListGroup>
              {orderBy(notes, ['createdOn'], ['desc']).map((note, index) => {
                return <NoteListItem key={index} note={note} handleEdit={showNote} handleDownload={downloadAttachment}/>;
              })}
            </ListGroup>
          </Col>
        </Row>
      </LoadingOverlay>
      <HeadlessModal
        open={showNoteModal}
        toggle={toggleNoteModal}
        size="lg"
      >
        <Row>
          <Col>
            <Note
              toggleModal={toggleNoteModal}
              relatedTo={relatedTo}
              id={selectedNote?.id}
              onCreated={onCreated}
              onUpdated={onUpdated}
              onDeleted={onDeleted}
            >

            </Note>
          </Col>
        </Row>

      </HeadlessModal>
    </div>
  );
};

export default connect()(Notes);
