import {
  PRICING_TOOL_QUOTES_ADD_QUOTE,
  PRICING_TOOL_QUOTES_SET_LIST,
  PRICING_TOOL_QUOTES_SET_SEARCH_STRING,
  PRICING_TOOL_QUOTES_SET_USER_FILTER, PRICING_TOOL_QUOTES_UPDATE_QUOTE, PRICING_TOOL_SET_USERS
} from '../actions/pricingTool';

const initialState = {
  searchString: '',
  userFilter: {
    value: 'all',
    label: 'All Owners'
  },
  users: [],
  list: [],
  filteredList: [],
  table: {
    lastPage: 1,
    currentPage: 1,
    perPage: 50
  },
  showModal: false
};

export function pricingTool(state = initialState, action) {
  switch (action.type) {
    case PRICING_TOOL_QUOTES_SET_LIST:
      return {
        ...state,
        list: [...action.quotes],
        table: action.table
      };
    case PRICING_TOOL_QUOTES_SET_USER_FILTER:
      return {
        ...state,
        userFilter: action.user
      };
    case PRICING_TOOL_QUOTES_SET_SEARCH_STRING:
      const searchString = action.search !== undefined ? action.search : state.searchString;
      const filteredList = state.list.filter((quote) => {
        return (String(quote.name)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1 ||
          String(quote.type)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1 ||
          String(quote.id)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1 ||
          String(quote.status)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1 ||
          String(quote.assigned_to)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
        );
      });

      return {
        ...state,
        searchString,
        filteredList
      };
    case PRICING_TOOL_QUOTES_ADD_QUOTE:
      return {
        ...state,
        list: [action.quote, ...state.list]
      };
    case PRICING_TOOL_QUOTES_UPDATE_QUOTE:
      return {
        ...state,
        list: state.list.map(quote => {
          if (quote.uuid === action.quoteId) {
            return { ...quote, ...action.field };
          }
          return quote;
        })
      };
    case PRICING_TOOL_SET_USERS:
      return {
        ...state,
        users: action.users
      };
    default:
      return state;
  }
}


