import { DomainService } from '../../../utils/ServiceDB/Ipam/Domain';
import { defaultErrorFeedback } from '../../feedback';

export const IPAM_DOMAINS_SET_DATA = 'IPAM_DOMAINS_SET_DATA';

function setDomains(data) {
  return {
    type: IPAM_DOMAINS_SET_DATA,
    data
  };
}

export const getDomains = (include = [], filtered = [], orderBy = 'id', sortedBy = 'asc') => {
  return (dispatch) => {
    return DomainService.list(include, [], orderBy, sortedBy).then((result) => {
      if (result.status === 200) {
        dispatch(setDomains(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    })
  }
}