import { defaultErrorFeedback } from './feedback';
import {
  api_createDirectDebitMandate,
  api_deactivateDirectDebit,
  api_getDirectDebitByMandateRef,
  api_getDirectDebitMandates,
  api_searchDirectDebitMandates
} from '../utils/Billing/DirectDebitService';

export const DIRECT_DEBITS_SET_SEARCH_OPTIONS = 'DIRECT_DEBITS_SET_SEARCH_OPTIONS';
export const DIRECT_DEBITS_SET_SELECTED_MANDATE = 'DIRECT_DEBITS_SET_SELECTED_MANDATE';
export const DIRECT_DEBITS_UPDATE_NEW_MANDATE = 'DIRECT_DEBITS_UPDATE_NEW_MANDATE';
export const DIRECT_DEBITS_SET_NEW_MANDATE = 'DIRECT_DEBITS_SET_NEW_MANDATE';
export const DIRECT_DEBITS_RESET_NEW_MANDATE = 'DIRECT_DEBITS_RESET_NEW_MANDATE';
export const DIRECT_DEBITS_ADD_TO_MANDATES = 'DIRECT_DEBITS_ADD_TO_MANDATES';
export const DIRECT_DEBITS_REMOVE_MANDATE = 'DIRECT_DEBITS_REMOVE_MANDATE';
export const DIRECT_DEBITS_LOADING_TABLE = 'DIRECT_DEBITS_LOADING_TABLE';
export const DIRECT_DEBITS_SET_MANDATES = 'DIRECT_DEBITS_SET_MANDATES';
export const DIRECT_DEBITS_LOADING_AUDDIS = 'DIRECT_DEBITS_LOADING_AUDDIS';
export const DIRECT_DEBITS_SET_AUDDIS_DATE_FROM = 'DIRECT_DEBITS_SET_AUDDIS_DATE_FROM';
export const DIRECT_DEBITS_SET_AUDDIS_DATE_TO = 'DIRECT_DEBITS_SET_AUDDIS_DATE_TO';
export const DIRECT_DEBITS_RESET_AUDDIS = 'DIRECT_DEBITS_RESET_AUDDIS';

function setSearchOptions(options) {
  return {
    type: DIRECT_DEBITS_SET_SEARCH_OPTIONS,
    options
  };
}

function setMandates(mandates) {
  return {
    type: DIRECT_DEBITS_SET_MANDATES,
    mandates
  };
}

export function resetNewMandateErrors() {
  return {
    type: DIRECT_DEBITS_UPDATE_NEW_MANDATE,
    field: 'errors',
    value: {}
  };
}

export function searchMandates(args) {
  return (dispatch) => {
    return api_searchDirectDebitMandates(args).then((result) => {
      if (result.status === 200) {
        return result.data
        //dispatch(setSearchOptions(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function createMandate(mandate) {
  return (dispatch) => {
    return api_createDirectDebitMandate(mandate).then((result) => {
      if (result.status === 200) {
        dispatch({ type: DIRECT_DEBITS_ADD_TO_MANDATES, mandate: result.data });
        return result.data;
      }
    });
  };
}

export function deactivateMandate(mandateId) {
  return (dispatch) => {
    return api_deactivateDirectDebit(mandateId).then((result) => {
      if (result.status === 204) {
        dispatch({ type: DIRECT_DEBITS_REMOVE_MANDATE, mandateId: mandateId });
        dispatch({ type: DIRECT_DEBITS_LOADING_TABLE, loading: false });
      }
    });
  };
}

export function validateMandateRef(ref) {
  return (dispatch) => {
    return api_getDirectDebitByMandateRef(ref).then((result) => {
      if (result.status === 200) {
        return result.data;
      }
    });
  };
}

export function getMandates() {
  return (dispatch) => {
    return api_getDirectDebitMandates().then((result) => {
      let mandates = {};
      if (result.status === 200) {
        result.data.forEach((mandate) => {
          mandates[mandate.id] = mandate;
        });
        dispatch(setMandates(mandates));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}
