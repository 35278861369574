import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment/moment';
import { SelectMod } from '../../Selects/SelectMod';
import numeral from 'numeral';
import React, { useState } from 'react';
import { isSalesManager, isServiceDelivery } from '../../../utils/Auth/AuthService';
import ExtraButtons from '../../../utils/Helpers/ExtraButtons';
import { ButtonIcon } from '../../ButtonIcon';
import FilteredTableV2 from '../../FilteredTableV2';
import { Badge } from 'reactstrap';
import { connect } from 'react-redux';
import business from 'moment-business';
import { tableDateCompare } from '../../../utils/Helpers/TableDateCompare';
import { CSVLink } from 'react-csv';
import TableCellHighlightOnHover from '../../TableCellHighlightOnHover';
import Currency from '../../Currency';

const OrdersTable = ({
  includeColumns,
  excludeColumns,
  user,
  fetchData,
  orders,
  optionSets,
  defaultSorted,
  defaultUserFilter
}) => {

  const navigate = useNavigate();

  const [userFilter, setUserFilter] = useState( defaultUserFilter ? {
    value: defaultUserFilter.id,
    label: defaultUserFilter.name
  } : {
    value: null,
    label: 'All Owners'
  });
  const [statusFilter, setStatusFilter] = useState({
    value: null,
    label: 'Status'
  });

  const onUserFilterChange = (selected, tableProps) => {
    tableProps.column.setFilterValue(selected.value)
    setUserFilter(selected)
  };

  const onStatusFilterChange = (selected, tableProps) => {
    tableProps.column.setFilterValue(selected.value)
    setStatusFilter(selected)
  };

  const getDateBGClass = (value) => {
    let today = moment();
    let date = moment(value);
    if (today.isSameOrAfter(date, 'day')) {
      return 'danger';
    } else if (business.addWeekDays(today, 5)
      .isAfter(date)) {
      return 'warning';
    } else {
      return 'success';
    }
  }
  const getColumns = () => {
    let columns = [
      {
        filterable: false,
        header: '#',
        accessorKey: 'orderNumber',
        minSize: 100
      },
      {
        filterable: false,
        header: 'Name',
        accessorKey: 'name',
        minSize: 250
      },
      {
        filterable: false,
        header: 'Customer',
        accessorKey: 'company',
        cell: props => {
          return <Link onClick={e => e.stopPropagation()} to={`/accounts/${props.row.original.company.accountid}`} className={'text-decoration-none'}><TableCellHighlightOnHover value={props.getValue()?.name}/></Link>;
        }
      },
      {
        header: 'Red File Received',
        accessorKey: 'redFileReceivedDate',
        cell: props => props.getValue() ? moment(props.getValue())
          .format('DD/MM/YYYY') : '',
        accessorFn: row => row.redFileReceivedDate ?? undefined,
        sortUndefined: 'last'
      },
      {
        header: 'Credit Check',
        accessorKey: 'creditCheckStatus',
        cell: props => props.getValue() ?
          <Badge color={getBadgeColor(props.getValue())}>
            <i className={`fa fa-${getIcon(props.getValue())}`}/>
            {optionSets.redFileStatus.options.find(x => x.value === props.getValue()).label}
          </Badge>
          : ''
      },
      {
        header: 'Sales Feasibility',
        accessorKey: 'salesStatus',
        cell: props => props.getValue() ?
          <Badge color={getBadgeColor(props.getValue())}>
            <i className={`fa fa-${getIcon(props.getValue())}`}/>
            {optionSets.redFileStatus.options.find(x => x.value === props.getValue()).label}
          </Badge> : ''
      },
      {
        header: 'Legal',
        accessorKey: 'legalStatus',
        cell: props => props.getValue() ?
          <Badge color={getBadgeColor(props.getValue())} >
            <i className={`fa fa-${getIcon(props.getValue())}`}/>
            {optionSets.redFileStatus.options.find(x => x.value === props.getValue()).label}
          </Badge> : ''
      },
      {
        header: 'Technical',
        accessorKey: 'technicalStatus',
        cell: props => props.getValue() ?
          <Badge color={getBadgeColor(props.getValue())}>
            <i className={`fa fa-${getIcon(props.getValue())}`}/>
            {optionSets.redFileStatus.options.find(x => x.value === props.getValue()).label}
          </Badge> : ''
      },
      {
        header: 'Business',
        accessorKey: 'businessStatus',
        cell: props => props.getValue() ?
          <Badge color={getBadgeColor(props.getValue())}>
            <i className={`fa fa-${getIcon(props.getValue())}`}/>
            {optionSets.redFileStatus.options.find(x => x.value === props.getValue()).label}
          </Badge> : ''
      },
      {
        filterable: false,
        accessorKey: 'statusReason',
        enableSorting: false,
        header: props => (
          <SelectMod
            options={getStatusOptions()}
            isSearchable
            value={statusFilter}
            onChange={(selected) => onStatusFilterChange(selected, props)}
            size={'sm'}
          />
        ),
        filterFn: (row, columnId, value) => {
          return !value || row.original.statusReason === value
        },
        cell: props => (
          optionSets.statusReason.options.find(x => x.value === props.getValue()).label
        )

      },
      {
        filterable: false,
        header: 'Total Amount',
        accessorKey: 'totalAmount',
        cell: props => <Currency currency={props.row.original.priceList?.currency.id} value={props.getValue()}/>,
      },
      {
        header: 'Next Customer Update',
        accessorKey: 'nextCustomerUpdate',
        cell: props => {
          const value = props.getValue()
          return <Badge style={{minWidth: '86px'}} className={`p-2 faded-${getDateBGClass(value)}`}>{value ? moment(value)
            .format('DD/MM/YYYY') : ''}</Badge>
        },
        accessorFn: row => row.nextCustomerUpdate ?? undefined,
        sortUndefined: 'last'
      },
      {
        filterable: false,
        header: 'Created',
        accessorKey: 'createdOn',
        cell: props => moment(props.getValue())
          .format('DD/MM/YYYY HH:MM')
      },
      {
        filterable: false,
        header: 'Modified',
        accessorKey: 'modifiedOn',
        cell: props => moment(props.getValue())
          .format('DD/MM/YYYY HH:mm')
      }
    ];
    if(isSalesManager(user.permissions) || isServiceDelivery(user.permissions)){
      columns.push({
        header: (props) => {
          return <SelectMod
            options={getUsersOptions()}
            isSearchable
            value={userFilter}
            onChange={(selected) => onUserFilterChange(selected, props)}
            size={'sm'}
          />
        },
        enableSorting: false,
        accessorKey: 'owner',
        filterFn: (row, columnId, value) => {
          return !value || row.original.owner.id === value
        },
        cell: props => props.getValue().name ?? '',
        minSize: 200
      })
    }

    if(excludeColumns){
      return  columns.filter(column => !excludeColumns.includes(column.accessorKey))
    }
    return includeColumns ? columns.filter(column => includeColumns.includes(column.accessorKey)) : columns
  }
  const getUsersOptions = () => {
    let keep = {};
    return orders.list.reduce((result, order) => {
      if (!keep[order.owner.id]) {
        keep[order.owner.id] = true;
        result.push(
          {
            value: order.owner.id,
            label: order.owner.name
          }
        );
      }
      return result;
    }, [{
      value: null,
      label: 'All Owners'
    }]);
  };
  const getStatusOptions = () => {
    let keep = {};
    return orders.list.reduce((result, order) => {
      if (!keep[order.statusReason]) {
        keep[order.statusReason] = true;
        result.push(
          {
            value: order.statusReason,
            label: optionSets.statusReason.options.find(x => x.value === order.statusReason).label
          }
        );
      }
      return result;
    }, [{
      value: null,
      label: 'Status'
    }]);
  };

  const tableFilter = (row, columnId, value) => {
    const status = optionSets.statusReason.options.find(x => x.value === row.original.statusReason)?.label
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.company?.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.orderNumber)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(status)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.owner?.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }
  const getBadgeColor = (value) => {
    switch (value) {
      case 1:
        return 'success';
      case 2:
        return 'danger';
      case 3:
        return 'warning'
      default:
        return '';
    }
  }
  const getIcon = (value) => {
    switch (value) {
      case 1:
        return 'check-circle'
      case 2:
        return 'times-circle'
      case 3:
        return 'exclamation-circle'
      default:
        return '';
    }
  }

  const getDefaultUserFilter = () => {
    return defaultUserFilter ? [
      {
        id: "owner",
        value: defaultUserFilter.id
      }
    ] : null
  }

  return (
    <>
      <FilteredTableV2
        extraButtons={ExtraButtons({
          others: [
            <div data-for={'export'} data-tip={'export'} >
              <CSVLink id={'export'}
                       data={[...orders.list]} filename={`orders-${moment().format('DD-MM-YYYY')}.csv`}
                       style={{ textDecoration: 'none' }}>

                <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
              </CSVLink>
            </div>,
            <ButtonIcon icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={fetchData}/>
          ]
        })}
        withFilter={tableFilter}
        columns={getColumns()}
        data={orders.list}
        minRows={10}
        defaultSorted={defaultSorted || [{
          id: 'createdOn',
          desc: true
        }]}
        onRowClick={(order) => navigate(`/sales/orders/${order.id}`)}
        showPagination
        defaultPageSize={100}
        defaultFilterValues={getDefaultUserFilter()}
      />
    </>

  )
}

function mapStateToProps({ orders, authenticationState, helpers }) {
  return {
    orders,
    user: authenticationState.account,
    optionSets: helpers.optionSets.order
  };
}
export default connect(mapStateToProps)(OrdersTable);
