import {
  POPS_SET_DATA,
  POPS_SET_STATUS,
  EXCHANGES_SET_STATUS,
  EXCHANGES_SET_DATA,
  EXCHANGES_SET_SERVING,
  EXCHANGE_ADD_SERVING,
  SET_SERVING_EXCHANGE_LIST,
  SET_SERVING_EXCHANGE_LIST_FAILED
} from "../actions/discovery";
import update from "immutability-helper";

const initialState = {
  pops: {
    status: 0,
    data: []
  },
  exchanges: {
    status: 0,
    data: [],
    serving: {}
  },
  serving: {}
};

export default function discovery(state = initialState, action) {
  switch (action.type) {
    case POPS_SET_DATA:
      return update(state, {
        pops: { data: { $set: action.data } }
      });
    case POPS_SET_STATUS:
      return update(state, {
        pops: { status: { $set: action.status } }
      });
    case EXCHANGES_SET_DATA:
      return update(state, {
        exchanges: { data: { $set: action.data } }
      });
    case EXCHANGES_SET_SERVING:
      return update(state, {
        exchanges: { serving: { $set: action.data } }
      });
    case EXCHANGES_SET_STATUS:
      return update(state, {
        exchanges: { status: { $set: action.status } }
      });
    case EXCHANGE_ADD_SERVING:
      return update(state, {
        serving: { [action.postcode]: { $set: { status: 0, data: {}} } }
      });
    case SET_SERVING_EXCHANGE_LIST:
      return update(state, {
        serving: { [action.postcode]: { $set: { status: 1, data: action.serving} } }
      });
    case SET_SERVING_EXCHANGE_LIST_FAILED:
      return update(state, {
        serving: { [action.postcode]: { $set: { status: 2, data: action.message} } }
      });
    default:
      return state;
  }
}
