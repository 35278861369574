import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import React, { useState } from 'react';

const AddConnectionButton = ({ onSubmit }) => {

  const [addConnectionButtonOpen, setAddConnectionButtonOpen] = useState(false);

  return (
    <>
      <ButtonDropdown
        isOpen={addConnectionButtonOpen}
        toggle={() => setAddConnectionButtonOpen(prevState => !prevState)}
        direction="left"
        className={'hover-content'}
      >
        <DropdownToggle size="sm" className="rounded">
         Connect
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => onSubmit('aEnd')}>
            Connect as A-End
          </DropdownItem>
          <DropdownItem onClick={() => onSubmit('bEnd')}>
            Connect as B-End
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  )
}

export default AddConnectionButton;