
export const USER_LOGOUT = 'USER_LOGOUT';
export const SOCKET_LOGOUT = 'SOCKET_LOGOUT';

export function logout() {
  return (dispatch) =>{
    dispatch({ type: USER_LOGOUT });
    dispatch({ type: SOCKET_LOGOUT });
    return true
  };
}
