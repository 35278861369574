import { defaultErrorFeedback, successFeedback } from './feedback';
import SiteService from '../utils/Site/SiteService';
import { setAccountSite } from './accounts';
import AuditService from "../utils/Audit";

export const SITE_SET_DATA = 'SITE_SET_DATA';
export const SITE_UPDATE_FORM_FIELD = 'SITE_UPDATE_FORM_FIELD';
export const SITE_RESET_DATA = 'SITE_RESET_DATA';
export const SITE_SET_AUDIT_HISTORY = 'SITE_SET_AUDIT_HISTORY';

export const setSiteData = (data) => {
  return {
    type: SITE_SET_DATA,
    site: data
  };
};

export const updateSiteFormField = (field, value) => {
  return {
    type: SITE_UPDATE_FORM_FIELD,
    field,
    value
  };
};

export const getSite = (id) => {
  return (dispatch) => {
    return SiteService.get(id, null, ['primaryContact', 'contacts'])
      .then((result) => {
        if (result.status === 200) {
          dispatch(setSiteData(result.data));
          return result.data
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
  };
};

export const updateSite = (id, data) => {
  return (dispatch) => {
    return SiteService.update(id, data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Site updated!'))
          return result.data
        } else if (result.status === 422){
          return {errors: result.data};
        }
        else {
          dispatch(defaultErrorFeedback())
        }
      });
  };
};

export const deactivateSite = (id) => {
  return (dispatch) => {
    return SiteService.deactivate(id)
      .then((result) => {
        if (result.status === 204) {
          dispatch(successFeedback('Site updated!'));
          return true
        } else {
          dispatch(defaultErrorFeedback());
          return false
        }
      });
  };
}

export const restoreSite = (id) => {
  return (dispatch) => {
    return SiteService.restore(id)
      .then((result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Site restored!'));
          return true
        } else {
          dispatch(defaultErrorFeedback());
          return false
        }
      });
  };
}

export const createSite = (accountIdOrNumber, data) => {
  return (dispatch) => {
    return SiteService.create(accountIdOrNumber, data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Site created!'));
          return result.data
        } else if (result.status === 422){
          return {errors: result.data};
        }
        else {
          dispatch(defaultErrorFeedback());
          return false
        }
      });
  };
}

export const resetSite = () => { return { type: SITE_RESET_DATA }; };

export function getSiteAuditHistory(id){
    return (dispatch) => {
        return AuditService.get('sites', id).then((result)=>{
            if (result.status === 200) {
                dispatch({ type: SITE_SET_AUDIT_HISTORY, data: result.data });
            }else{
                dispatch(defaultErrorFeedback());
            }
        })
    }
}
