import { api_getServiceDbLogicalServiceTypeFields } from '../../utils/Helpers/ServiceDbLogicalServiceTypes';
import { defaultErrorFeedback } from '../feedback';

export const LOGICAL_SERVICE_TYPE_SET_FIELDS = 'LOGICAL_SERVICE_TYPE_SET_FIELDS';

export function getServiceTypeFields(serviceTypeName) {
  return (dispatch) => {
    return api_getServiceDbLogicalServiceTypeFields(serviceTypeName).then((result) => {
      if (result.status === 200) {
        dispatch(setServiceTypeFields(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

function setServiceTypeFields(fields) {
  return {
    type: LOGICAL_SERVICE_TYPE_SET_FIELDS,
    fields
  };
}
