export default {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true
  },
  owner: {
    label: 'Owner',
    type: 'select-async',
    entity: 'site',
    mandatory: true,
    optionLabelCallback: (opt) => `${opt.name} (${opt.accountnumber})`
  }
}