import {
  CONFIG_ITEM_ADD_NOTES_NOTE,
  CONFIG_ITEM_UPDATE_NOTE,
  CONFIG_ITEM_RESET_DATA,
  CONFIG_ITEM_SET_DATA,
  CONFIG_ITEM_SET_NOTES,
  CONFIG_ITEM_UPDATE_FORM_FIELD
} from '../actions/configItem';

const initialState = {
  configItem: {
    assignedTo: {},
    location: {},
    manufacturer: {},
    notes: []
  },
  configItemForm: {
    assignedTo: {},
    location: {},
    manufacturer: {}
  }
};

export default function configItem(state = initialState, action) {
  switch (action.type) {
    case CONFIG_ITEM_SET_DATA:
      return {
        ...state,
        configItem: { ...initialState.configItem, ...action.configItem },
        configItemForm: { ...initialState.configItemForm, ...action.configItem }
      };
    case CONFIG_ITEM_UPDATE_FORM_FIELD:
      return {
        ...state,
        configItemForm: {
          ...state.configItemForm,
          [action.field]: action.value
        }
      };
    case CONFIG_ITEM_SET_NOTES:
      return {
        ...state,
        configItem: {
          ... state.configItem,
          notes: action.notes
        }
      };
    case CONFIG_ITEM_ADD_NOTES_NOTE:
      return {
        ...state,
        configItem: {
          ...state.configItem,
          notes: [action.note, ...state.configItem.notes]
        }
      };
    case CONFIG_ITEM_UPDATE_NOTE:
      const notes = state.configItem.notes || [];
      const newNotes = notes.map(note => note.id === action.note.id ? action.note : note);
      return {
        ...state.id,
        configItem: {
          ... state.configItem,
          notes: newNotes
        }
      }
    case CONFIG_ITEM_RESET_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
