import { isSdbManager } from '../../../utils/Auth/AuthService';

const form = {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true,
    plaintext: (key, field, data, permissions) => data.id && !isSdbManager(),
    width: 12
  },
  description: {
    label: 'Description',
    type: 'text',
    width: 12
  },
  speed: {
    label: 'Speed',
    type: 'number',
    min: 0,
    width: 12
  },
  duplex: {
    label: 'Duplex',
    type: 'select',
    mandatory: true,
    width: 12
  },
  deviceDeployment: {
    label: 'Device Deployment',
    type: 'text',
    callBack: (key, field, data) => data.deviceDeployment?.hostname,
    plaintext: true,
    width: 12
  },
}

export default form;