import React, { useEffect, useState } from 'react';
import { CarrierCircuitService } from '../../../../../utils/ServiceDB/CarrierCircuit';
import isEmpty from 'lodash.isempty';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import formFieldGenerator from '../../../../../helpers/FormFieldGenerator';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import CarrierCircuitComponentForm from './form';
import HeadlessModal from '../../../../../components/Modals/HeadlessModal';
import CarrierCircuitsTable from '../../../../../components/Tables/CarrierCircuits';
import { useDispatch } from 'react-redux'

const CarrierCircuit = ({
  aEnd,
  data: {
    data,
    order
  },
  onConnect,
  serviceTypes,
  circuitOptionSets,
  suppliers,
  canEdit
}) => {
  const [circuits, setCircuits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleLoading = () => setLoading(prevState => !prevState)
  const toggleModal = () => setShowModal(prevState => !prevState)

  useEffect(() => {
    if(isEmpty(data)){
      toggleModal()
    }
  }, [data]);


  const fetchCircuits = () => {
    toggleLoading()
    CarrierCircuitService.getAvailableCarrierCircuits().then(result => {
      if(result.status === 200){
        if(aEnd.type === 'nni' && order === 1){
          setCircuits(result.data.filter(circuit => circuit.aEnd.data?.id === aEnd.data.id))
        }else{
          setCircuits(result.data.filter(circuit => circuit.aEnd.type !== 'nni'))
        }
      }
      toggleLoading()
    })
  }

  return (
    <>
      <Row>
        {formFieldGenerator({
          data,
          fields: CarrierCircuitComponentForm(circuitOptionSets.type, serviceTypes)
        })}
        {canEdit && (
          <Col lg={12}>
            <Button
              color={'primary'}
              block
              outline
              onClick={toggleModal}
            >
              {data.name ? 'Replace' : 'Select'}
            </Button>
          </Col>
        )}
      </Row>
      {canEdit && (
        <HeadlessModal
          open={showModal}
          toggle={toggleModal}
          size={'xlg'}
          onOpened={fetchCircuits}
        >
          <div className="animated fadeIn">
            <Card className={'mb-0'}>
              <CardBody>
                <LoadingOverlay loading={loading}>
                  <CarrierCircuitsTable
                    noRowClick
                    connect={(circuit) => {
                      toggleModal()
                      onConnect(circuit)
                    }}
                    excludeColumns={['opticalSystem', 'cdr', 'bearerSpeed']}
                    circuits={circuits}
                    fetchData={fetchCircuits}
                    circuitOptionSets={circuitOptionSets}
                    suppliers={suppliers}
                    withoutNew
                    closeModal={toggleModal}
                  />
                </LoadingOverlay>
              </CardBody>
            </Card>
          </div>
        </HeadlessModal>
      )}
    </>
  );
};

export default CarrierCircuit;
