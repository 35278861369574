import { ACTIVITY_SET_DATA } from '../actions/activity';

const initialState = {
  activity: {}
};

export default function activity(state = initialState, action) {
  switch (action.type) {
    case ACTIVITY_SET_DATA:
      return {...state, activity: action.data };
    default:
      return state;
  }
}
