import {
  api_getRelatedSites,
  api_searchAccounts
} from '../utils/Account/AccountServiceDeprecated';
import { defaultErrorFeedback } from './feedback';
import {
  api_createCase,
  api_getCase,
  api_getCaseEmails,
  api_getCaseNotes,
  api_createCaseNote,
  api_updateCase
} from '../utils/Cases/CasesService';

export const CASE_SET_DATA = 'CASE_SET_DATA';
export const CASE_RESET_DATA = 'CASE_RESET_DATA';
export const CASE_UPDATE_FIELD = 'CASE_UPDATE_FIELD';
export const CASE_SET_ASYNC_SEARCH_OPTIONS = 'CASE_SET_ASYNC_SEARCH_OPTIONS';
export const CASE_TOGGLE_LOADING = 'CASE_TOGGLE_LOADING';
export const CASE_ADD_NEW_NOTE = 'CASE_ADD_NEW_NOTE';
export const CASE_SET_NOTES = 'CASE_SET_NOTES';
export const CASE_UPDATE_NOTE = 'CASE_UPDATE_NOTE';
export const CASE_SET_EMAILS = 'CASE_SET_EMAILS';
export const CASE_SET_EMAIL = 'CASE_SET_EMAIL';
export const CASE_SET_SITES = 'CASE_SET_SITES';

export function updateCaseField(field, value) {
  return {
    type: CASE_UPDATE_FIELD,
    field,
    value
  };
}

function setEntitySearchOptions(key, options) {
  return {
    type: CASE_SET_ASYNC_SEARCH_OPTIONS,
    key,
    options
  };
}

export function resetCase() {
  return {
    type: CASE_RESET_DATA
  };
}

export function toggleCaseLoading() {
  return {
    type: CASE_TOGGLE_LOADING
  };
}

export function addNewCaseNote(note) {
  return {
    type: CASE_ADD_NEW_NOTE,
    note
  };
}

export function updateCaseNote(note) {
  return {
    type: CASE_UPDATE_NOTE,
    note
  };
}

export function setCaseData(data) {
  return {
    type: CASE_SET_DATA,
    data
  };
}

function setCaseNotes(notes) {
  return {
    type: CASE_SET_NOTES,
    notes
  };
}

export function setCaseSites(sites) {
  return {
    type: CASE_SET_SITES,
    sites
  };
}

function setCaseEmails(emails) {
  return {
    type: CASE_SET_EMAILS,
    emails
  };
}

export function setCaseEmail(email) {
  return {
    type: CASE_SET_EMAIL,
    email
  };
}

export function searchAccounts(key, searchString, parentsOnly = false) {
  return (dispatch) => {
    return api_searchAccounts(searchString, parentsOnly).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setEntitySearchOptions(key, result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return [];
      }
    );
  };
}

export function createCase(args) {
  return (dispatch) => {
    return api_createCase(args).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setCaseData(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    );
  };
}

export function updateCase(id, args) {
  return (dispatch) => {
    return api_updateCase(id, args)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setCaseData(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      });
  };
}

export function getCaseData(idOrNumber) {
  return (dispatch) => {
    return api_getCase(idOrNumber, ['owner', 'customer']).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setCaseData(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    );
  };
}

export function getCaseNotes(idOrNumber) {
  return (dispatch) => {
    return api_getCaseNotes(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setCaseNotes(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    );
  };
}

export const createCaseNote = (id, data) => {
  return (dispatch) => {
    return api_createCaseNote(id, data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(addNewCaseNote(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return null;
      });
  };
};

export function getCaseEmails(idOrNumber) {
  return (dispatch) => {
    return api_getCaseEmails(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setCaseEmails(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    );
  };
}

export function getRelatedSites(siteId) {
  return (dispatch) => {
    return api_getRelatedSites(siteId)
      .then(result => {
        if (result.status === 200) {
          dispatch(setCaseSites(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      });
  };
}
