import {
  ORDER_SET_DATA,
  ORDER_REPLACE_DATA,
  ORDER_UPDATE_FIELD,
  ORDER_RESET_DATA,
  ORDER_LINE_ITEMS_SET_DATA,
  ORDER_NOTES_SET_DATA,
  ORDER_EMAILS_SET_DATA,
  ORDER_CASES_SET_DATA,
  ORDER_SET_EMAIL,
  ORDER_ADD_NOTE_IN_TABLE,
  ORDER_ADD_NEW_CASE,
  ORDER_UPDATE_CASE,
  ORDER_UPDATE_NOTE,
  ORDER_UPDATE_TASK,
  ORDER_ADD_TASK,
  ORDER_ADD_NOTE,
  ORDER_UPDATE_PRODUCT,
  ORDER_SET_AUDIT_HISTORY,
  ORDER_REMOVE_NOTE, ORDER_ADD_PRODUCT
} from '../actions/order';

const initialState = {
  original: {
    tasks: [],
    notes: [],
    cases: []
  },
  form: {
    tasks: [],
    notes: [],
    cases: []
  },
  lineItems: [],
  notes: [],
  emails: [],
  cases: [],
  audits: {}
};

export default function order(state = initialState, action) {
  const cases = state.form.cases || [];
  switch (action.type) {
    case ORDER_SET_DATA:
      return {
        ...state,
        original: { ...state.original, ...action.order },
        form: { ...state.form, ...action.order }
      };
    case ORDER_REPLACE_DATA:
      return {
        ...state,
        original: { ...action.order },
        form: { ...action.order }
      };
    case ORDER_UPDATE_FIELD:
      return {
        ...state,
        form: { ...state.form, [action.field]: action.value }
      };
    case ORDER_RESET_DATA:
      return {
        ...initialState
      };
    case ORDER_LINE_ITEMS_SET_DATA:
      return {
        ...state,
        lineItems: [ ...action.orderLineItems]
      }
    case ORDER_NOTES_SET_DATA:
      return {
        ...state,
        notes: action.orderNotes ? [...action.orderNotes] : []
      };
    case ORDER_EMAILS_SET_DATA:
      return {
        ...state,
        emails: action.orderEmails ? [...action.orderEmails] : []
      };
    case ORDER_SET_EMAIL:
      return {
        ...state,
        emails: state.emails.map((email) => (action.email.id === email.id) ? { ...email, ...action.email } : email)
      };
    case ORDER_CASES_SET_DATA:
      return {
        ...state,
        cases: action.orderCases ? [...action.orderCases] : []
      }
    case ORDER_ADD_NEW_CASE:
      return {
        ...state,
        form: { ...state.form, cases: [action.data, ...cases] },
        original: { ...state.original, cases: [action.data, ...cases] }
      };

    case ORDER_UPDATE_CASE:
      const newCases = cases.map(theCase => theCase.caseId === action.theCase.caseId ? action.theCase : theCase);
        return {
          ...state,
          data: { ...state.data, cases: newCases },
          original: { ...state.original, cases: newCases }
      };
    case ORDER_UPDATE_PRODUCT:
      const products = state.original.products.map(product => {
        if(product.id === action.product.id){
          return action.product
        }
        return product
      })
      return {
        ...state,
        form: {
          ...state.form,
          products: products
        },
        original: {
          ...state.original,
          products: products
        }
      }


    case ORDER_ADD_NOTE_IN_TABLE:
      return {
        ...state,
        notes: [action.note, ...state.notes]
        };
    case ORDER_UPDATE_TASK:
      let newTasks = state.form.tasks.map(task => {
        if(task.id === action.task.id){
          return action.task
        }
          return task
      })
      return {
        ...state,
        form: {
          ...state.form,
          tasks: newTasks,
        },
        original: {
          ...state.original,
          tasks: newTasks,
        }
      }
    case ORDER_ADD_TASK:
      return {
        ...state,
        form: {
          ...state.form,
          tasks: [...state.form.tasks, action.task],
        },
        original: {
          ...state.original,
          tasks: [...state.original.tasks, action.task],
        }
      }
      case ORDER_ADD_NOTE:
      return {
        ...state,
        form: {
          ...state.form,
          notes: [action.note, ...state.form.notes],
        },
        original: {
          ...state.original,
          notes: [action.note, ...state.original.notes],
        }
      }
    case ORDER_ADD_PRODUCT:
      return {
        ...state,
        form: {
          ...state.form,
          products: [action.product, ...state.form.products],
        },
        original: {
          ...state.original,
          products: [action.product, ...state.form.products],
        }
      }
    case ORDER_UPDATE_NOTE: {
      const notes = state.original.notes.map(note => {
        if(note.id === action.note.id){
          return action.note
        }
        return note
      })
      return {
        ...state,
        form: {
          ...state.form,
          notes
        },
        original: {
          ...state.original,
          notes
        }
      }
    }
    case ORDER_REMOVE_NOTE: {
      const notes = state.form.notes.filter(note => note.id !== action.note.id)
      return {
        ...state,
        form: {
          ...state.form,
          notes
        },
        original: {
          ...state.original,
          notes
        }
      }
    }
    case ORDER_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state
  }
}
