import { api_getPriceLists } from '../utils/PriceLists';
import { defaultErrorFeedback } from './feedback';

export const PRICE_LISTS_SET_LISTS = 'PRICE_LISTS_SET_LISTS';
export const PRICE_LISTS_SET_SEARCH_STRING = 'PRICE_LISTS_SET_SEARCH_STRING';
export const PRICE_LISTS_ADD_PRICE_LIST = 'PRICE_LISTS_ADD_PRICE_LIST';
export const PRICE_LISTS_REMOVE_PRICE_LIST = 'PRICE_LISTS_REMOVE_PRICE_LIST';
export const PRICE_LISTS_UPDATE_PRICE_LIST = 'PRICE_LISTS_UPDATE_PRICE_LIST';

function setPriceLists(lists){
  return {
    type: PRICE_LISTS_SET_LISTS,
    lists
  }
}

export function setPriceListsSearchString(search) {
  return {
    type: PRICE_LISTS_SET_SEARCH_STRING,
    search
  };
}

export function addPriceList(priceList){
  return {
    type: PRICE_LISTS_ADD_PRICE_LIST,
    priceList
  };
}

export function updatePriceListInList(id, field){
  return {
    type: PRICE_LISTS_UPDATE_PRICE_LIST,
    id,
    field
  };
}

export function removePriceList(priceListId){
  return {
    type: PRICE_LISTS_REMOVE_PRICE_LIST,
    priceListId
  };
}
export function getAllPriceLists(){
  return (dispatch) => {
    return api_getPriceLists().then(
      (result) => {
        if(result.status === 200){
          dispatch(setPriceLists(result.data))
          return result.data
        }else{
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    )
  }
}
