import FilteredTable from '../../components/FilteredTable'
import React, { useEffect, useState } from 'react'
import LoadingOverlay from '../../components/LoadingOverlay'
import { deleteRadiusUser, fetchRadiusUsers, searchRadiusAttribs, setActiveUser } from '../../actions/radius'
import debounce from 'debounce-promise'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import { Link, useNavigate } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { setConfirmDialog } from '../../actions/dialogs'
import AddUser from './AddUser'
import { connect, useDispatch } from 'react-redux'

const Radius = (props) => {
  // router
  const navigate = useNavigate()

  //redux
  const dispatch = useDispatch()

  const {radiusState} = props;

  const {fetchingUsers, radiusUsers, table} = radiusState;

  const [searchType, setSearchType] = useState('users');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filtered, setFiltered] = useState('');
  const [sorted, setSorted] = useState([{id: 'username', desc: false}]);

  const columns = [
    { Header: 'Username', accessor: 'username', filterable: true, sortable: true, enableColumnFilter: true },
    {
      filterable: false,
      sortable: false,
      width: 50,
      Cell: props => (
        <div
          className={'text-primary'}
          style={{ cursor: 'pointer' }}
          onClick={
            () => {
              dispatch(setActiveUser(props.original.username))
              navigate('/toolbox/radius/' + props.original.username)
            }
          }
        >
          <i className="fa fa-search"/>
        </div>
      )
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 50,
      Cell: props => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={
              () => {
                dispatch(setActiveUser(props.original.username))
                dispatch(setConfirmDialog({
                  color: 'danger',
                  text: 'Are you sure you want to delete the user  ' + props.original.username + '?',
                  proceed: () => {
                    dispatch(deleteRadiusUser(props.original.username))
                    if (searchType === 'users') {
                      dispatch(fetchRadiusUsers(page, filtered))
                    } else {
                      dispatch(searchRadiusAttribs(page, filtered))
                    }
                  },
                  cancel: () => {}
                }))
              }
            }
            className="text-primary"
          >
            <i className="fa fa-trash text-danger"
               id={props.original.username}
               data-for={props.original.username}
               data-tip="trash"
               style={{
                 cursor: 'pointer'
               }}
            />
            <ReactTooltip
              id={props.original.username}
              type="error"
              place="right"
              effect="float"
              multiline={false}
            >delete
            </ReactTooltip>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    if (searchType === 'users') {
      dispatch(fetchRadiusUsers(page, filtered))
    } else {
      dispatch(searchRadiusAttribs(page, filtered))
    }
  }, [])

  const updateSearchMode = () => {
    if (searchType === 'users') {
      setSearchType('attributes')
    } else {
      setSearchType('users')
    }
  }

  return <Row>
    <Col>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Button onClick={() => updateSearchMode()}>Search Mode: {searchType}</Button>
              <LoadingOverlay loading={fetchingUsers}>
                <FilteredTable
                  hideFilter={true}
                  data={radiusUsers}
                  columns={columns}
                  onFetchData={() => {}}
                  onPageChange={page => {
                    if (searchType === 'users') {
                      dispatch(fetchRadiusUsers(page + 1, filtered))
                    } else {
                      dispatch(searchRadiusAttribs(page + 1, filtered))
                    }
                  }}
                  onPageSizeChange={pageSize => {
                    setPageSize(pageSize)
                    if (searchType === 'users') {
                      dispatch(fetchRadiusUsers(page, filtered))
                    } else {
                      dispatch(searchRadiusAttribs(page, filtered))
                    }
                  }}
                  onFilteredChange={debounce(filtered => {
                    let fil = null
                    if (filtered.length > 0) {
                      fil = filtered[0]['value']
                    }
                    setFiltered(fil)
                    if (searchType === 'users') {
                      dispatch(fetchRadiusUsers(page, fil))
                    } else {
                      dispatch(searchRadiusAttribs(page, fil))
                    }
                  }, 500)}
                  onSortedChange={sorted => {
                    setSorted(sorted)
                    if (searchType === 'users') {
                      dispatch(fetchRadiusUsers(page, filtered))
                    } else {
                      dispatch(searchRadiusAttribs(page, filtered))
                    }
                  }}
                  defaultSorted={{ id: 'username', desc: false }}
                  pages={table.lastPage}
                  showPagination
                />
              </LoadingOverlay>
            </Col>
            <Col>
              <AddUser/>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
}

const mapStateToProps = ({ radius }) => {
  return {
    radiusState: radius
  };
};
export default connect(mapStateToProps)(Radius);
