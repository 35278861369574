import { apiRequest } from '../Api/ApiRequest';

export function api_circuitReport(circuits) {
  return apiRequest({
    url: '/reports/circuits',
    method: 'post',
    data: {
      circuitids: circuits
    }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
