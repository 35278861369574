export default {
  create: {
    name: {
      label: 'Name',
      type: 'text',
      width: 4
    },
    template: {
      label: 'Template',
      type: 'select',
      width: 4
    }
  },
  edit: {
    name: {
      label: 'Name',
      type: 'text',
      width: 4
    },
    template: {
      label: 'Template',
      type: 'text',
      plaintext: true,
      width: 4,
      callBack: (key, field, data, optionSets) => optionSets.find((template) => template.value === data.template)?.label || "Standard"
    }
  }
}
