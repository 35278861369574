import { connect, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
import numeral from 'numeral'
import React, { useState } from 'react'
import FilteredTableV2 from '../../FilteredTableV2'
import { ButtonIcon } from '../../ButtonIcon'
import { Button } from 'reactstrap'
import isEmpty from 'lodash.isempty'
import { CSVLink } from 'react-csv'
import omit from 'lodash.omit'
import HeadlessModal from '../../Modals/HeadlessModal'
import ProvisionalOrderRequest from '../../ProvisionalOrderRequest'
import { addAccountOrder, createAccountOrder } from '../../../actions/account'
import Currency from '../../Currency';

const AccountOrdersTable = ({ account, contacts, optionSets, orders, fetchData }) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [showProvisional, setShowProvisional] = useState(false)
  const [creatingOrder, setCreatingOrder] = useState(false)
  const toggleProvisional = () => setShowProvisional(prevState => !prevState)
  const toggleCreatingOrder = () => setCreatingOrder(prevState => !prevState)
  const columns = [
    {
      filterable: false,
      header: '#',
      accessorKey: 'orderNumber',
      minSize: 100,
      cell: props => {
        return (<Link target="_blank" to={props.row.original.url}>{props.getValue()}</Link>)
      }
    },
    {
      filterable: false,
      header: 'Name',
      accessorKey: 'name',
      minSize: 250
    },
    {
      filterable: false,
      header: 'Contact',
      accessorKey: 'contact.name',
      accessorFn: (row => !isEmpty(row.contact) ? row.contact.name : ''),
    },
    {
      filterable: false,
      header: 'Owner',
      accessorKey: 'owner.name',
      accessorFn: (row => !isEmpty(row.owner) ? row.owner.name : ''),
    },
    {
      filterable: false,
      header: 'Status',
      accessorKey: 'statusReason',
      cell: props => (
        optionSets.statusReason.options.find(x => x.value === props.getValue()).label ?? ''
      )

    },

    {
      filterable: false,
      header: 'Total Amount',
      accessorKey: 'totalAmount',
      cell: props => <Currency currency={props.row.original.priceList?.currency?.id} value={props.getValue()}/>,
    },
    {
      filterable: false,
      header: 'Year One Value',
      accessorKey: 'yearOneValue',
      cell: props => <Currency currency={props.row.original.priceList?.currency?.id} value={props.getValue()}/>,
    },
    {
      filterable: false,
      header: 'Created',
      accessorKey: 'createdOn',
      cell: props => moment(props.getValue())
        .format('DD/MM/YYYY HH:MM')
    },
    {
      filterable: false,
      header: 'Modified',
      accessorKey: 'modifiedOn',
      cell: props => moment(props.getValue())
        .format('DD/MM/YYYY HH:mm')
    }
  ]
  const getExportData = () => {
    return orders.map(order => {
      return {
        ...omit(order, ['id', 'engineer', 'customer']),
        contact: order.contact?.name,
        owner: order.owner?.name,
        status: optionSets.statusReason.options.find(x => x.value === order.statusReason).label ?? ''
      }
    })
  }
  const tableFilter = (row, columnId, value) => {
    const status = optionSets.statusReason.options.find(x => x.value === row.original.statusReason)?.label
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.company?.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.orderNumber)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(status)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.owner?.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }

  const createProvisionalOrder = (data) => {
    toggleCreatingOrder()
    dispatch(createAccountOrder(account.accountid, data)).then(result => {
      toggleCreatingOrder()
      if (result) {
        dispatch(addAccountOrder(result))
        navigate(`/sales/orders/${result.id}`)
      }
    })
  }
  const getButtons = () => {
    const buttons = [
      //<Button size={'sm'} className={'ml-2'} onClick={() => {}}>Credit Request</Button>,
      <div data-for={'export'} data-tip={'export'}>
        <CSVLink id={'export'}
                 data={getExportData()} filename={`services-${moment().format('DD-MM-YYYY')}.csv`}
                 style={{ textDecoration: 'none' }}>

          <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
        </CSVLink>
      </div>,

      <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
    ]

    if (account.isRM3808) {
      buttons.unshift(<Button size={'sm'} className={'ml-2'} onClick={toggleProvisional}>Provisional Order</Button>,)
    }
    return buttons
  }
  return (
    <>
      <FilteredTableV2
        withFilter={tableFilter}
        columns={columns}
        data={orders}
        minRows={10}
        defaultSorted={[{
          id: 'modifiedOn',
          desc: true
        }]}
        onRowClick={(order) => navigate(`/sales/orders/${order.id}`)}
        showPagination
        defaultPageSize={20}
        extraButtons={getButtons()}
      />
      <HeadlessModal
        open={showProvisional}
        toggle={toggleProvisional}
      >
        <ProvisionalOrderRequest
          loading={creatingOrder}
          contacts={contacts}
          submit={createProvisionalOrder}
          cancel={toggleProvisional}
        />
      </HeadlessModal>
    </>
  )
}

const mapStateToProps = ({ accounts, orders, helpers, authenticationState }) => ({
  optionSets: helpers.optionSets.order
})

export default connect(mapStateToProps)(AccountOrdersTable)
