import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const WavelengthService = {
  getWavelengths: (include = []) => {
    return apiRequest({
      url: `/sdb/connections/wavelengths${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getWavelength: (id, include = []) => {
    return apiRequest({
      url: `/sdb/connections/wavelengths/${id}${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  createWavelength: (data, include = []) => {
    return apiRequest({
      url: `/sdb/connections/wavelengths${queryStringFromFields({ include })}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  updateWavelength: (id, data, include = []) => {
    return apiRequest({
      url: `/sdb/connections/wavelengths/${id}${queryStringFromFields({ include })}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  deleteWavelength: (id) => {
    return apiRequest({
      url: `/sdb/connections/wavelengths/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }

};