import { apiRequest } from '../Api/ApiRequest';

const CRMSyncService = {
  syncProductTemplates: () => {
    return apiRequest({
      url: `/sync`,
      method: 'post',
      data: {entity: 'product-templates'}
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  orders: (orderId) => {
    return apiRequest({
      url: `/sync`,
      method: 'post',
      data: {entity: 'orders', entityId: orderId}
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}

export default CRMSyncService