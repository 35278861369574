import {
  CIRCUIT_ADD_NEW_CASE,
  CIRCUIT_ADD_NEW_NOTE,
  CIRCUIT_UPDATE_NOTE,
  CIRCUIT_REPLACE_DATA,
  CIRCUIT_RESET_DATA,
  CIRCUIT_SET_ASYNC_SEARCH_OPTIONS,
  CIRCUIT_SET_DATA,
  CIRCUIT_SET_EMAIL,
  CIRCUIT_SET_EMAILS,
  CIRCUIT_UPDATE_CASE,
  CIRCUIT_UPDATE_FIELD, CIRCUIT_REMOVE_NOTE, CIRCUIT_REMOVE_PRODUCT
} from '../actions/circuit';

const initialState = {
  loading: false,
  searchOptions: {},
  original: {
    emails: [],
    notes: [],
    products: []
  },
  form: {
    emails: [],
    notes: [],
    products: []
  },
  notes: []
};

export default function circuit(state = initialState, action) {
  const cases = state.form.cases || [];
  switch (action.type) {
    case CIRCUIT_SET_DATA:
      return {
        ...state,
        original: action.data,
        form: action.data
      };
    case CIRCUIT_REPLACE_DATA:
      return {
        ...state,
        original: action.data,
        form: action.data
      };
    case CIRCUIT_RESET_DATA:
      return {
        ...state,
        original: { ...initialState.original },
        form: { ...initialState.form }
      };
    case CIRCUIT_UPDATE_FIELD:
      return {
        ...state,
        form: { ...state.form, [action.field]: action.value }
      };
    case CIRCUIT_SET_ASYNC_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: { ...state.searchOptions, [action.key]: action.options }
      };
    case CIRCUIT_ADD_NEW_NOTE:
      return {
        ...state,
        form: { ...state.form, notes: [action.note, ...state.form.notes] },
        original: { ...state.original, notes: [action.note, ...state.original.notes] }
      };
    case CIRCUIT_UPDATE_NOTE:
      return {
        ...state,
        form: { ...state.form, notes: state.form.notes.map(note => note.id === action.note.id ? action.note : note)},
        original: {...state.original, notes: state.original.notes.map(note => note.id === action.note.id ? action.note : note)}
      }
    case CIRCUIT_REMOVE_NOTE:
      return {
        ...state,
        form: { ...state.form, notes: state.form.notes.filter(note => note.id !== action.note.id)},
        original: {...state.original, notes: state.original.notes.filter(note => note.id !== action.note.id)}
      }
    case CIRCUIT_ADD_NEW_CASE:
      return {
        ...state,
        form: { ...state.form, cases: [action.data, ...cases] },
        original: { ...state.original, cases: [action.data, ...cases] }
      };
    case CIRCUIT_UPDATE_CASE:
      const newCases = cases.map(theCase => theCase.caseId === action.theCase.caseId ? action.theCase : theCase);
      return {
        ...state,
        form: { ...state.form, cases: newCases },
        original: { ...state.original, cases: newCases }
      };
    case CIRCUIT_SET_EMAILS:
      return {
        ...state,
        original: {
          ...state.original,
          emails: [...action.emails]
        }
      };
    case CIRCUIT_SET_EMAIL:
      return {
        ...state,
        original: {
          ...state.original,
          emails: state.original.emails.map((email) => (action.email.id === email.id) ? { ...email, ...action.email } : email)
        }
      };
    case CIRCUIT_REMOVE_PRODUCT:
      return {
        ...state,
        form: {
          ...state.form,
          products: state.form.products.filter((product) => product.id !== action.id)
        },
        original: {
          ...state.original,
          products: state.original.products.filter((product) => product.id !== action.id)
        }
      };
    default:
      return state;
  }
}
