import {
  OPPORTUNITY_ADD_TASK,
  OPPORTUNITY_RESET, OPPORTUNITY_SET_AUDIT_HISTORY,
  OPPORTUNITY_SET_DATA, OPPORTUNITY_SET_QUOTE_REF_IN_PROGRESS,
  OPPORTUNITY_TOGGLE_LOADING,
  OPPORTUNITY_UPDATE_FIELD, OPPORTUNITY_UPDATE_TASK
} from '../actions/opportunity';

const initialState = {
  loading: false,
  quoteRefInProgress: '',
  form: {
    quotes: [],
    tasks: []
  },
  original: {
    quotes: [],
    tasks: [],
  },
  audits: []
}

export function opportunity(state = initialState, action){
  switch(action.type){
    case OPPORTUNITY_UPDATE_FIELD:
      return {
        ...state,
        form: {...state.form, ...action.field}
      }
    case OPPORTUNITY_SET_DATA:
      return {
        ...state,
        form: action.data,
        original: action.data
      }
    case OPPORTUNITY_RESET:
      return {
        ...initialState
      };
    case OPPORTUNITY_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case OPPORTUNITY_ADD_TASK:
      return {
        ...state,
        form: {
          ...state.form,
          tasks: [action.task, ...state.form.tasks]
        },
        original: {
          ...state.original,
          tasks: [action.task, ...state.original.tasks]
        }
      };
    case OPPORTUNITY_UPDATE_TASK:
      return {
        ...state,
        form: {
          ...state.form,
          tasks: state.form.tasks.map((task) => {
            if (task.id === action.task.id) {
              return action.task;
            }
            return task;
          })
        },
        original: {
          ...state.original,
          tasks: state.original.tasks.map((task) => {
            if (task.id === action.task.id) {
              return action.task;
            }
            return task;
          })
        }
      };
    case OPPORTUNITY_SET_QUOTE_REF_IN_PROGRESS:
      return {
        ...state,
        quoteRefInProgress: action.ref
      }
    case OPPORTUNITY_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;
  }
}
