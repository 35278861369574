import isEmpty from 'lodash.isempty';

const form = {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true
  },
  ref: {
    label: 'Reference',
    type: 'text',
  },
  carrier: {
    label: 'Carrier',
    type: 'select',
    mandatory: true
  },
  bearerSpeed: {
    label: 'Bearer Speed',
    type: 'select',
    mandatory: true
  },
  device: {
    label: 'Device',
    entity: 'device',
    type: 'select-async',
    placeholder: 'Search by name...',
    minSearchLength: 2,
    mandatory: true
  },
  port: {
    label: 'Port',
    type: 'select',
    mandatory: true,
    show: (field, data) => !isEmpty(data.device),
  },
}

export default form;