import { apiRequest } from '../Api/ApiRequest';
import { stringify } from 'qs';
import { queryStringFromFields } from '../Helpers/QueryString';
import isEmpty from 'lodash.isempty';

export const SignatureService = {
  list: (page, pageSize, search, sorting) => {
    let query = {
      ...search,
      include: 'items.signatories,items.company,items.relatedTo',
      sortedBy: !isEmpty(sorting) ? (sorting[0].desc ? 'desc' : 'asc') : null,
      orderBy: !isEmpty(sorting) ? sorting[0].id : null,
      page,
      pageSize
    }
    if(isEmpty(search)){

      delete query.search
    }
    return apiRequest({
      url: `/signatures${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  countSignatures: () => {
    return apiRequest({
      url: '/signatures/count',
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  get: (id) => {
    return apiRequest({
      url: `/signatures/${id}?include=documents,company,company.contacts,signatories,hellosign,relatedTo`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  updateSignature: (id, data) => {
    return apiRequest({
      url: `/signatures/${id}?include=documents,company,company.contacts,signatories,hellosign,relatedTo`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  createSignature: (data) => {
    return apiRequest({
      url: '/signatures?include=documents,company,company.contacts,signatories,hellosign,relatedTo',
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  sendSignature: (id) => {
    return apiRequest({
      url: `/signatures/${id}?include=documents,company,company.contacts,signatories,hellosign,relatedTo`,
      method: 'post'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  attachFile: (id, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return apiRequest({
      url: '/signatures/' + id + '/documents',
      method: 'post',
      data: formData
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  deleteFile: (id, file) => {
    return apiRequest({
      url: '/signatures/' + id + '/documents/' + file,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  attachReorder: (id, data) => {
    return apiRequest({
      url: '/signatures/' + id + '/documents/reorder',
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  cancelSignature: (id) => {
    return apiRequest({
      url: '/signatures/' + id + '/cancel?include=documents,company,company.contacts,signatories,hellosign,relatedTo',
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  resendSignatureEmail: (id) => {
    return apiRequest({
      url: '/signatures/' + id + '/resend',
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  downloadCurrentSignatureDocument: (id) => {
    return apiRequest({
      url: '/signatures/' + id + '/hsdownload',
      method: 'get',
      responseType: 'blob'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  downloadFinalSignatureDocument: (id) => {
    return apiRequest({
      url: '/signatures/' + id + '/download',
      method: 'get',
      responseType: 'blob'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }

};
