import OrderService, {
  api_getOrderEmails,
  api_getOrderCases
} from '../utils/Order/OrderService';
import { defaultErrorFeedback, successFeedback } from './feedback';
import AuditService from '../utils/Audit';

export const ORDER_SET_DATA = 'ORDER_SET_DATA';
export const ORDER_REPLACE_DATA = 'ORDER_REPLACE_DATA';
export const ORDER_UPDATE_FIELD = 'ORDER_UPDATE_FIELD';
export const ORDER_RESET_DATA = 'ORDER_RESET_DATA';
export const ORDER_LINE_ITEMS_SET_DATA = 'ORDER_LINE_ITEMS_SET_DATA';
export const ORDER_NOTES_SET_DATA = 'ORDER_NOTES_SET_DATA';
export const ORDER_EMAILS_SET_DATA = 'ORDER_EMAILS_SET_DATA';
export const ORDER_CASES_SET_DATA = 'ORDER_CASES_SET_DATA';
export const ORDER_SET_EMAIL = 'ORDER_SET_EMAIL';
export const ORDER_ADD_NOTE_IN_TABLE = 'ORDER_ADD_NOTE_IN_TABLE';
export const ORDER_ADD_NEW_CASE ='ORDER_ADD_NEW_CASE';
export const ORDER_UPDATE_CASE = 'ORDER_UPDATE_CASE';
export const ORDER_UPDATE_NOTE = 'ORDER_UPDATE_NOTE';
export const ORDER_UPDATE_TASK = 'ORDER_UPDATE_TASK';
export const ORDER_ADD_TASK = 'ORDER_ADD_TASK';
export const ORDER_ADD_NOTE = 'ORDER_ADD_NOTE';
export const ORDER_REMOVE_NOTE = 'ORDER_REMOVE_NOTE';
export const ORDER_UPDATE_PRODUCT = 'ORDER_UPDATE_PRODUCT';
export const ORDER_ADD_PRODUCT = 'ORDER_ADD_PRODUCT';
export const ORDER_SET_AUDIT_HISTORY = 'ORDER_SET_AUDIT_HISTORY';

export function resetOrder() {
  return {
    type: ORDER_RESET_DATA
  };
}

export function updateOrderField(field, value) {
  return {
    type: ORDER_UPDATE_FIELD,
    field,
    value
  };
}
export function addNoteInTable(note) {
  return {
    type: ORDER_ADD_NOTE_IN_TABLE,
    note
  };
}

export function updateOrderNote(note) {
  return {
    type: ORDER_UPDATE_NOTE,
    note
  };
}

export function setOrderEmail(email) {
  return {
    type: ORDER_SET_EMAIL,
    email
  };
}

export function addNewOrderCase(data) {
  return {
    type: ORDER_ADD_NEW_CASE,
    data
  };
}

export function updateOrderCase(data) {
  return {
    type: ORDER_UPDATE_CASE,
    data
  };
}

export function updateOrderTask(task) {
  return {
    type: ORDER_UPDATE_TASK,
    task
  };
}

export function addOrderTask(task) {
  return {
    type: ORDER_ADD_TASK,
    task
  };
}

export function addOrderNote(note) {
  return {
    type: ORDER_ADD_NOTE,
    note
  };
}
export function addOrderProduct(product) {
  return {
    type: ORDER_ADD_PRODUCT,
    product
  };
}

export function removeOrderNote(note) {
  return {
    type: ORDER_REMOVE_NOTE,
    note
  };
}
export function updateOrderProduct(product) {
  return {
    type: ORDER_UPDATE_PRODUCT,
    product
  };
}

export const getOrderData = (orderId) => {
  return (dispatch) => {
    return OrderService.get(orderId).then((result) => {
      if (result.status === 200) {
        dispatch({ type: ORDER_SET_DATA, order: result.data });
        return result.data;
      } else {
        dispatch(defaultErrorFeedback());
        return false;
      }
    });
  };
};

export function updateOrder(orderId, args) {
  return (dispatch) => {
    return OrderService.update(orderId, args).then((result) => {
      if (result.status === 200) {
        dispatch({ type: ORDER_REPLACE_DATA, order: result.data });
        dispatch(successFeedback('Order updated!'));
        return result.data;
      }else if(result.status === 422){
        return {errors: result.data}
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createOrderProduct(orderId, data) {
  return (dispatch) => {
    return OrderService.products.create(orderId, data).then((result) => {
      if (result.status === 200) {
        dispatch(successFeedback('Product created!'));
        return result.data;
      }else if(result.status === 422){
        return {errors: result.data}
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function getOrderEmails(orderId) {
  return (dispatch) => {
    return api_getOrderEmails(orderId).then((result) => {
      if (result.status === 200) {
        dispatch({ type: ORDER_EMAILS_SET_DATA, orderEmails: result.data });
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function getOrderCases(orderId){
  return (dispatch) => {
    return api_getOrderCases(orderId).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: ORDER_CASES_SET_DATA, orderCases: result.data });
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    })
  }
}

export function getOrderAuditHistory(orderId){
  return (dispatch) => {
    return AuditService.get('orders', orderId).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: ORDER_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}
