export default {
  title: {
    label: 'Title',
    type: 'text',
    mandatory: true,
    width: 12,
  },
  caseId: {
    label: 'Case Number',
    type: 'text',
    readOnly: true
  },
  nextAction: {
    label: 'Next Action',
    type: 'datetime',
  },
  customer: {
    label: 'Customer',
    type: 'select-async',
    entity: 'account',
    minSearchLength: 5,
    mandatory: true,
    readOnly: true
  },
  owner: {
    label: 'Owner',
    type: 'select-async',
    entity: 'user',
    placeholder: 'Leave empty for default NOC...',
  },
  status: {
    label: 'Status',
    type: 'select',
    show: (field, data) => Boolean(data.caseId)
  },
  subject: {
    label: 'Subject',
    type: 'select',
    mandatory: true,
  },
  origin: {
    label: 'Case Origin',
    type: 'select',
    mandatory: true,
  },
  type: {
    label: 'Case Type',
    type: 'select',
    mandatory: true,
  },
  priority: {
    label: 'Priority',
    type: 'select',
    mandatory: true,
  },
  serviceChangeDue: {
    label: 'Service Change Due',
    type: 'datetime'
  },
  description: {
    label: 'Description',
    type: 'textarea',
    width: 12,
    mandatory: true,
  }
};
