import {
  DEVICE_DEPLOYMENT_ADD_NOTE,
  DEVICE_DEPLOYMENT_ADD_PORT, DEVICE_DEPLOYMENT_REMOVE_NOTE, DEVICE_DEPLOYMENT_REMOVE_PORT,
  DEVICE_DEPLOYMENT_RESET_DATA, DEVICE_DEPLOYMENT_SET_AUDIT_HISTORY,
  DEVICE_DEPLOYMENT_SET_DATA,
  DEVICE_DEPLOYMENT_SET_FIELD, DEVICE_DEPLOYMENT_UPDATE_NOTE,
  DEVICE_DEPLOYMENT_UPDATE_PORT
} from '../actions/deviceDeployment';

const initialState = {
  data: {
    stockItem: {},
    rack: {},
    zabbix: {},
    portConnectionDetails: [],
    notes: []
  },
  original: {
    stockItem: {},
    rack: {},
    zabbix: {},
    portConnectionDetails: [],
    notes: []
  },
  audits: []
};

const deviceDeployment = (state = initialState, action) => {
  switch (action.type) {
    case DEVICE_DEPLOYMENT_SET_DATA:
      return {
        ...state,
        data: { ...action.data },
        original: { ...action.data }
      };
    case DEVICE_DEPLOYMENT_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case DEVICE_DEPLOYMENT_ADD_PORT:
      return {
        ...state,
        data: { ...state.data, portConnectionDetails: [...state.data.portConnectionDetails, action.portData] },
        original: { ...state.original, portConnectionDetails: [...state.original.portConnectionDetails, action.portData] }
      };
    case DEVICE_DEPLOYMENT_UPDATE_PORT:
      const updatedPortsWithUpdatedPort = state.data.portConnectionDetails.map((port) => port.id === action.portData.id ? action.portData : port);
      return {
        ...state,
        data: { ...state.data, portConnectionDetails: updatedPortsWithUpdatedPort },
        original: { ...state.original, portConnectionDetails: updatedPortsWithUpdatedPort }
      };
    case DEVICE_DEPLOYMENT_REMOVE_PORT:
      const updatedPortsWithoutDelete = state.data.portConnectionDetails.filter((port) => port.id !== action.portId);
      return {
        ...state,
        data: { ...state.data, portConnectionDetails: updatedPortsWithoutDelete },
        original: { ...state.original, portConnectionDetails: updatedPortsWithoutDelete }
      };
    case DEVICE_DEPLOYMENT_RESET_DATA:
      return { ...initialState };
    case DEVICE_DEPLOYMENT_ADD_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: [...state.data.notes, action.note]
        },
        original: {
          ...state.original,
          notes: [...state.original.notes, action.note]
        },

      }
    case DEVICE_DEPLOYMENT_UPDATE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
        original: {
          ...state.original,
          notes: state.original.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
      }
    case DEVICE_DEPLOYMENT_REMOVE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.filter(note => note.id !== action.note.id)
        },
        original: {
          ...state.original,
          notes: state.original.notes.filter(note => note.id !== action.note.id)
        }
      }
    case DEVICE_DEPLOYMENT_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;
  }
};

export default deviceDeployment;