export const migrations = {
  0: (state) => {
    return {
      ...state,
      shifts: {
        ...state.shifts,
        timeoffdata: {}
      }
    }
  },
  1: (state) => {
    return {
      ...state,
      authenticationState: {
        ...state.authenticationState,
        inactive: false
      }
    }
  },
  2: (state) => {
    return {
      ...state,
      authenticationState: {
        ...state.authenticationState,
        testerProfile: []
      }
    }
  }
};
