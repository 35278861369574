import { apiRequest } from '../Api/ApiRequest';

export function api_getAccountServiceUtilisationReportData(accountId) {
  return apiRequest({
    url: `accounts/${accountId}/generate-report/service-utilisation`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getAccountServiceUtilisationReport(accountId, from, to, data) {
  return apiRequest({
    url: `accounts/${accountId}/generate-report/service-utilisation`,
    method: 'post',
    data: { from, to, data }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
