import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import FilteredTable from '../../components/FilteredTable';
import ExtraButtons from '../../utils/Helpers/ExtraButtons';
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import {
  addCasesCase,
  getCases,
  searchCases,
  setCasesSearchString,
  updateCasesCase
} from '../../actions/cases';
import Case from '../Case';
import { SelectMod } from '../../components/Selects/SelectMod';
import moment from 'moment';
import SearchCaseForm from './SearchCaseForm';
import LoadingOverlay from '../../components/LoadingOverlay';

const Cases = ({
  cases,
  filtered,
  user,
  searchString,
  optionSets
}) => {
  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalCaseId, setModalCaseId] = useState('');
  const myCasesArgs = {
    userId: user.cui,
    status: null
  };
  const [dataOptions, setDataOptions] = useState(myCasesArgs);

  const getData = () => {
    setLoading(true);
    dispatch(getCases(dataOptions))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, [dataOptions]);

  const onChangeSearch = e => {
    const caseSearch = e.target.value.toString()
      .toLowerCase();
    dispatch(setCasesSearchString(caseSearch, optionSets));
  };

  const closeCaseModal = () => {
    setShowModal(false);
    setModalCaseId('');
  };

  const showCaseModal = (caseId) => {
    setShowModal(true);
    setModalCaseId(caseId);
  };

  const ShowDropdown = () => {
    const options = [
      {
        value: 'my_cases',
        label: 'My Cases'
      },
      {
        value: 'all_active',
        label: 'All Active Cases'
      }
    ];
    const onSelectChange = (selectedOption) => {
      let args;
      if (selectedOption.value === 'my_cases') {
        args = myCasesArgs;
      } else {
        args = {
          userId: null,
          status: 0
        };
      }
      setDataOptions(args);
    };
    return (
      <div style={{ minWidth: 200 }}>
        <SelectMod
          isSearchable
          options={options}
          defaultValue={options.filter(option => option.value === 'my_cases')}
          onChange={onSelectChange}
          size={'sm'}/>
      </div>
    );
  };

  const columns = [
    {
      filterable: false,
      sortable: true,
      Header: 'ID',
      accessor: 'caseId',
      width: 180,
      Cell: props => {
        return <a href={props.original.crmUrl} target="_blank" rel="noreferrer"
                  className={'text-primary'}>{props.value}</a>;
      }
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Title',
      accessor: 'title'
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Status',
      accessor: 'status',
      Cell: props => {
        if (Number.isInteger(props.value) && optionSets) {
          const option = optionSets.status.options.find(optionSet => optionSet.value === props.value);
          if (option) {
            return option.label;
          }
        }
        return props.value;
      },
      width: 200
    },
    {
      filterable: false,
      sortable: true,
      Header: 'State',
      accessor: 'state',
      Cell: props => {
        switch (props.value) {
          case 0:
            return 'Active';
          case 1:
            return 'Inactive';
          case 2:
            return 'Canceled';
          default:
            return props.value;
        }
      },
      width: 100
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Created',
      accessor: 'createdOn',
      Cell: props => moment(props.row.createdOn)
        .format('DD/MM/YYYY HH:mm'),
      width: 200
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Last modified',
      accessor: 'modifiedOn',
      Cell: props => moment(props.row.modifiedOn)
        .format('DD/MM/YYYY HH:mm'),
      width: 200
    }
  ];

  const searchCase = (searchText) => {
    setLoading(true);
    return dispatch(searchCases(searchText))
      .then(() => {
        setLoading(false);
      });
  };

  const addNew = () => {
    setShowModal(true);
  };

  const onCaseCreated = (data) => {
    setModalCaseId(data.caseId);
    if (data.owner.id === dataOptions.userId) {
      dispatch(addCasesCase(data));
    }
  };

  const onCaseUpdated = (data) => {
    dispatch(updateCasesCase(data));
  };

  return (
    <>
      <SearchCaseForm onSubmit={searchCase}/>
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <FilteredTable
              data={searchString ? filtered : cases}
              columns={columns}
              onChange={onChangeSearch}
              value={searchString}
              showPagination={cases && cases.length > 20}
              noDataText="No cases on the account"
              defaultSorted={{
                id: 'modifiedOn',
                desc: true
              }}
              onRowClick={theCase => showCaseModal(theCase.caseId)}
              extraButtons={ExtraButtons({
                addNew,
                refresh: getData,
                others: [ShowDropdown()]
              })}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>
      <Modal isOpen={showModal} toggle={closeCaseModal} className="modal-xlg"
             backdrop={'static'}>
        <ModalHeader toggle={closeCaseModal}>{modalCaseId}</ModalHeader>
        <ModalBody>
          <Case id={modalCaseId} onCreated={onCaseCreated} onUpdated={onCaseUpdated}/>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = ({
  authenticationState,
  cases,
  helpers
}) => {
  return {
    cases: cases.list,
    filtered: cases.filteredCases,
    searchString: cases.searchString,
    user: authenticationState.account,
    optionSets: helpers.optionSets.case
  };
};

export default connect(mapStateToProps)(Cases);
