import { BILLING_QUEUE_SET_JOBS, BILLING_QUEUE_TOGGLE_LOADING } from '../actions/billingQueue';

const initialState = {
  loading: false,
  jobs: [],
  failedJobs: []
};

export default function billingQueue(state = initialState, action) {
  switch (action.type) {
    case BILLING_QUEUE_SET_JOBS:
      return {
        ...state,
        jobs: action.data.jobs
      };
    case BILLING_QUEUE_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    default:
      return state;
  }
}
