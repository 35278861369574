import update from 'immutability-helper';
import {
  SIGNATURES_SET_ALL,
  SIGNATURES_SET_LOADING,
  SIGNATURES_UPDATE_FIELD,
  SIGNATURES_ADD_NEW,
  SIGNATURES_SET_TOSIGN,
  SIGNATURES_COUNT_DECREMENT,
  SIGNATURES_COUNT_INCREMENT,
  SIGNATURES_NEW,
  SIGNATURES_UPDATE,
  SIGNATURES_RESET_SIGNATURE,
  SIGNATURES_UPDATE_SIGNATURE_FIELD,
  SIGNATURES_SET_SIGNATURE,
  SIGNATURES_SET_SIGNATURE_COMPANY_CONTACTS,
  SIGNATURES_ADD_SIGNATURE_FILE,
  SIGNATURES_SET_SIGNATURE_FILES,
  SIGNATURES_REMOVE_SIGNATURE_FILE
} from '../actions/signatures';

const initialState = {
  signatureList: {
    currentPage: 1,
    total: 0,
    perPage: 20,
    lastPage: 1,
    items: []
  },
  toSign: 0,
  loading: false,
  active: {
    recipient: {}
  },
  activeLoading: false,
  activeSaving: false,
  addOpen: false,
  errors: {
    recipient: {}
  },
  list: [],
  table: {
    lastPage: 1
  },
  signature: {
    original: {},
    form: {}
  }
};

export default function signatures(state = initialState, action) {
  switch (action.type) {
    case SIGNATURES_UPDATE_FIELD:
      const fields = action.name.split('.');
      if (fields.length === 2) {
        return update(state, {
          active: {
            [fields[0]]: {
              [fields[1]]: {
                $set: action.value
              }
            }
          }
        });
      }

      return update(state, {
        active: {
          [action.name]: { $set: action.value }
        }
      });

    case SIGNATURES_SET_ALL:
      const { currentPage, total, perPage, path, lastPage, items } = action.signatures;
      return {
        ...state,
        list: items,
        table: {currentPage, total, perPage, path, lastPage}
      }
    case SIGNATURES_NEW:
      return update(state, {
        list: { $push: [action.data] }
      });
    case SIGNATURES_SET_LOADING:
      return update(state, {
        loading: { $set: action.loading }
      });
    case SIGNATURES_SET_TOSIGN:
      return update(state, {
        toSign: { $set: action.data }
      });
    case SIGNATURES_COUNT_INCREMENT:
      return update(state, {
        toSign: { $set: state.toSign + 1 }
      });
    case SIGNATURES_COUNT_DECREMENT:
      return update(state, {
        toSign: { $set: state.toSign - 1 }
      });
    case SIGNATURES_ADD_NEW:
      return update(state, {
        list: { items: { $unshift: [action.data] } }
      });




  case SIGNATURES_UPDATE:
    return {
      ...state,
      list: state.signatureList.items.map(obj => obj.id === action.data.id ? { ...action.data } : obj),
      signature: {
        ...state.signature,
        original: state.signature.original.id === action.data?.id ? { ...action.data } : state.signature.original,
        form: state.signature.form.id === action.data?.id ? { ...action.data } : state.signature.form,

      }
    };
    case SIGNATURES_SET_SIGNATURE:
      return {
        ...state,
        signature: {
          form: action.signature,
          original: action.signature
        }
      }
    case SIGNATURES_RESET_SIGNATURE:
      return {
        ...state,
        signature: {
          form: {},
          original: {}
        }
      }
    case SIGNATURES_UPDATE_SIGNATURE_FIELD:
      return {
        ...state,
        signature: {
          ...state.signature,
          form: {
            ...state.signature.form,
            ...action.field
          },
        }
      }
    case SIGNATURES_SET_SIGNATURE_COMPANY_CONTACTS:
      return {
        ...state,
        signature: {
          ...state.signature,
          form: {
            ...state.signature.form,
            company: {
              ...state.signature.form.company,
              contacts: action.contacts
            }
          },
        }
      }
    case SIGNATURES_ADD_SIGNATURE_FILE:
      return {
        ...state,
        signature: {
          ...state.signature,
          form: {
            ...state.signature.form,
            documents: [...state.signature.form.documents, action.data]
          },
          original: {
            ...state.signature.original,
            documents: [...state.signature.original.documents, action.data]
          },
        }
      }
    case SIGNATURES_SET_SIGNATURE_FILES:
      return {
        ...state,
        signature: {
          ...state.signature,
          form: {
            ...state.signature.form,
            documents: action.files
          },
          original: {
            ...state.signature.original,
            documents: action.files
          },
        }
      }
    case SIGNATURES_REMOVE_SIGNATURE_FILE:
      return {
        ...state,
        signature: {
          ...state.signature,
          form: {
            ...state.signature.form,
            documents: state.signature.form.documents.filter(document => document.id !== action.id )
          },
          original: {
            ...state.signature.original,
            documents: state.signature.original.documents.filter(document => document.id !== action.id )
          },
        }
      }
    default:
      return state;
  }
}
