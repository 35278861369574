import { CARRIER_CIRCUITS_SET_DATA } from '../actions/carrierCircuits';

const initialState = {
  carrierCircuits: [],
  table: {
    lastPage: 1
  }
}

const carrierCircuits = (state = initialState, action) => {
  switch (action.type) {
    case CARRIER_CIRCUITS_SET_DATA:
      const { currentPage, total, perPage, path, lastPage, items } = action.carrierCircuits;
      return {
        carrierCircuits: [...items],
        table: {currentPage, total, perPage, path, lastPage}
      }
    default:
      return state;
  }
}

export default carrierCircuits;