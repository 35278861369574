import { endpointStyle, getConnectionStyle } from '../constants';
import { ConcatenatedCircuit, Resizable } from 'react-network-diagrams-hso';
import React from 'react';
import isEmpty from 'lodash.isempty';

const ConnectionMap = ({ connection, selected, setSelected }) => {

  let memberList = [];
  if (!isEmpty(connection.aEnd)) {
    memberList.push({
      styleProperties: getConnectionStyle('node'),
      endpointStyle,
      endpointRadius: 0,
      endpointLabelZ: connection.aEnd?.type === 'nni' ? connection.aEnd.data?.port?.name : connection.aEnd?.data?.name,
      circuitLabel: connection.aEnd?.type === 'nni' ? connection.aEnd.data?.port?.deviceDeployment.hostname : connection.aEnd?.data?.deviceDeployment?.hostname,
      navTo: { type: 'node', end: 'aEnd' },
      selected: selected !== undefined && selected.end === 'aEnd'
    });
    if (connection.aEnd?.type === 'nni') {
      memberList.push({
        styleProperties: getConnectionStyle('nni'),
        endpointStyle,
        endpointLabelZ: connection.aEnd.data.name
      });
    }
  }

  function getComponentNameFromType(type) {
    switch (type) {
      case 'carrierCircuit':
        return 'Carrier Circuit';
      case 'patchLead':
        return 'Patch Lead';
      case 'crossConnect':
        return 'Cross Connect';
      case 'wavelength':
        return 'Wavelength';
      default:
        return type;
    }
  }

  connection.components
    .sort((a, b) => a.order - b.order)
    .forEach((component, idx) => {
      if (component.type === 'WaveLength') {
        memberList.push({
          styleProperties: getConnectionStyle('mux'),
          endpointStyle,
          circuitLabel: 'mux'
        });
      }
      memberList.push({
        styleProperties: getConnectionStyle(component.type),
        endpointStyle,
        circuitLabel: `${getComponentNameFromType(component.type)}: ${component.data.name || ''}`,
        navTo: { type: 'component', index: idx },
        selected: selected !== undefined && selected.id === component.id
      });
      if (component.type === 'WaveLength') {
        memberList.push({
          styleProperties: getConnectionStyle('mux'),
          endpointStyle,
          circuitLabel: 'mux'
        });
      }
    });
  if (!isEmpty(connection.bEnd)) {
    if (connection.bEnd.type === 'nni') {
      memberList.push({
        styleProperties: getConnectionStyle('nni'),
        endpointStyle,
        endpointLabelA: connection.bEnd.data.name
      });
    }
    memberList.push({
      styleProperties: getConnectionStyle('node'),
      endpointStyle,
      endpointRadius: 0,
      endpointLabelA: connection.bEnd.type === 'nni' ? connection.bEnd.data?.port?.name : connection.bEnd.data?.name,
      circuitLabel: connection.bEnd.type === 'nni' ? connection.bEnd.data?.port?.deviceDeployment.hostname : connection.bEnd.data?.deviceDeployment?.hostname,
      navTo: { type: 'node', end: 'bEnd' }
    });
  }

  return (
    <div style={{minHeight: 100}}>
      {!isEmpty(connection.aEnd) ? (
        <Resizable>
          <ConcatenatedCircuit
            memberList={memberList}
            hideTitle={true}
            endpointLabelPosition="bottomleftangled"
            height={150}
            endpointLabelOffset={20}
            yOffset={8}
            onSelectionChange={(sel, data) => {
              const newData = {...data};
              if (newData.type === 'component') {
                newData.id = connection.components.find((component, index) => index === newData.index).id
              }
              return setSelected(newData);
            }}
            deselect={() => setSelected(undefined)}
          />
        </Resizable>
      ) : ''}
    </div>
  );
};

export default ConnectionMap;