import {
  GLOBAL_SEARCH_SET_LIST,
  GLOBAL_SEARCH_SET_PATTERN,
  GLOBAL_SEARCH_TOGGLE_FORCE_REFRESH
} from '../actions/globalSearch';

const initialState = {
  pattern: '',
  list: []
};

export default function globalSearch(state = initialState, action) {

  switch (action.type) {
    case GLOBAL_SEARCH_SET_PATTERN:
      return {
        ...state,
        pattern: action.pattern
      }
    case GLOBAL_SEARCH_SET_LIST:
      return {
        ...state,
        list: action.list
      }
    default:
      return state
  }

}
