import React, { Fragment } from 'react';
import numeral from 'numeral';
import ReactTable from 'react-table-v6';

export const BillingRunBills = ({ bills, showBill }) => {
  const billArray = Object.values(bills);
  const columns = [
    {
      Header: 'Ref',
      accessor: 'ref'
    },
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Account',
      accessor: 'account'
    }, {
      Header: 'Items',
      accessor: 'items'
    }, {
      Header: 'Payment Method',
      accessor: 'paymentMethod'
    }, {
      Header: 'Total',
      accessor: 'totalValue',
      Cell: props => (
        '£' + numeral(props.value)
          .format('0,0.00')
      )
    }
  ];
  return (
        <ReactTable
            className="animated fadeIn -highlight -striped"
            NoDataComponent={() => null}
            data={billArray}
            columns={columns}
            minRows="2"
            filterable={billArray.length > 20}
            showPagination={billArray.length > 10}
            pageSizeOptions={[10, 20, 25, 50, 100]}
            defaultPageSize={10}
            resizable={false}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                onClick: e => showBill(rowInfo ? rowInfo.original : null),
                style: { cursor: rowInfo ? 'pointer' : '' }
              };
            }}
            getTheadProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadTrProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadThProps={() =>{
              return {
                style: { border: 'none', whiteSpace: 'unset' }
              };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                style: { border: 'none', whiteSpace: 'unset', fontSize: '10pt' }
              };
            }}
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
        />

  );
};
