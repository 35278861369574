import { LogicalServiceService } from '../../utils/ServiceDB/LogicalService';
import { defaultErrorFeedback } from '../feedback';

export const LOGICAL_SERVICES_SET_DATA = 'LOGICAL_SERVICES_SET_DATA';

function setServices(data) {
  return {
    type: LOGICAL_SERVICES_SET_DATA,
    data
  };
}

export const getServices = (serviceType, include = []) => {
  return (dispatch) => {
    return LogicalServiceService.list(serviceType, include).then((result) => {
      if (result.status === 200) {
        dispatch(setServices(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    })
  }
}