import { defaultErrorFeedback } from './feedback';
import { CrossConnectService } from '../utils/ServiceDB/CrossConnect';

export const CROSS_CONNECTS_SET_DATA = 'CROSS_CONNECTS_SET_DATA';

const setCrossConnects = (data) => ({
  type: CROSS_CONNECTS_SET_DATA,
  data
});

export const getCrossConnects = (include = []) => {
  return (dispatch) => {
    return CrossConnectService.getCrossConnects(include)
      .then(result => {
        if (result.status === 200) {
          dispatch(setCrossConnects(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      });
  };
};