import React from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

const styles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: '34px',
    backgroundColor: state.isDisabled ? '#e4e6eb' : 'white',
    borderColor: '#e4e6eb',
    overflow: 'hidden'
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#e4e6eb' : 'white'
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: '2px 8px'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '2px 8px'
  }),
  loadingIndicator: (styles) => ({
    ...styles,
    padding: '2px 8px'
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 3 // Without this menu will be overlapped by other fields
  }),
  container: base => ({
    ...base,
    flex: 1
  })
};
export const AsyncSelectMod = (props) => {
  const SingleValue = (selectProps) => {
    return (
      <div onClick={(e) => props.handleClick(e, selectProps)} className='hoverPointer'>
        <components.SingleValue {...selectProps}/>
      </div>
    );
  };

  return <AsyncSelect styles={styles} components={props.handleClick ? {SingleValue} : {}} {...props} />;
};
