import AccountService from '../utils/Account';
import { defaultErrorFeedback } from './feedback';
export const CREDIT_REQUEST_SET_DATA = 'CREDIT_REQUEST_SET_DATA'
export const CREDIT_REQUEST_SET_FORM_FIELD = 'CREDIT_REQUEST_SET_FORM_FIELD'
export const CREDIT_REQUEST_RESET = 'CREDIT_REQUEST_RESET'
export const CREDIT_REQUEST_ADD_PRODUCT= 'CREDIT_REQUEST_ADD_PRODUCT'
export const CREDIT_REQUEST_REMOVE_PRODUCT= 'CREDIT_REQUEST_REMOVE_PRODUCT'
export const CREDIT_REQUEST_UPDATE_PRODUCT= 'CREDIT_REQUEST_UPDATE_PRODUCT'
export const CREDIT_REQUEST_RESET_PRODUCTS= 'CREDIT_REQUEST_RESET_PRODUCTS'
export const CREDIT_REQUEST_SET_INVOICES= 'CREDIT_REQUEST_SET_INVOICES'
export const CREDIT_REQUEST_RESET_INVOICES= 'CREDIT_REQUEST_RESET_INVOICES'


export const updateCreditRequestFormField = (field) => {
  return {
    type: CREDIT_REQUEST_SET_FORM_FIELD,
    field
  }
}

export const resetCreditRequest = () => {
  return {
    type: CREDIT_REQUEST_RESET
  }
}


export const addCreditRequestProduct = (product) => {
  return {
    type: CREDIT_REQUEST_ADD_PRODUCT,
    product
  }
}

export const removeCreditRequestProduct = (product) => {
  return {
    type: CREDIT_REQUEST_REMOVE_PRODUCT,
    product
  }
}

export const updateCreditRequestProduct = (product, field) => {
  return {
    type: CREDIT_REQUEST_UPDATE_PRODUCT,
    product,
    field
  }
}

export const setCreditRequestInvoices = (invoices) => {
  return {
    type: CREDIT_REQUEST_SET_INVOICES,
    invoices
  }
}

export const resetCreditRequestInvoices = () => {
  return {
    type: CREDIT_REQUEST_RESET_INVOICES,
  }
}

export const resetCreditRequestProducts = () => {

  return {
    type: CREDIT_REQUEST_RESET_PRODUCTS
  }
}

export const createAccountCreditRequest = (idOrNumber, data) => {
  return (dispatch) => {
    return AccountService.creditRequests.create(idOrNumber, data).then(
      (result) => {
        if (result.status === 200) {
          dispatch({type: CREDIT_REQUEST_SET_DATA, data: result.data});
          return result.data
        } else if(result.status === 422){
          return {errors: result.data}
        }
        else {
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    );
  };
}

export const getCreditRequest = (id) => {
  return (dispatch) => {
    return AccountService.creditRequests.get(id).then(
      (result) => {
        if (result.status === 200) {
          dispatch({type: CREDIT_REQUEST_SET_DATA, data: result.data});
        }
        else {
          dispatch(defaultErrorFeedback());
        }
      }
    );
  };
}

export const updateCreditRequest = (id, data) => {
  return (dispatch) => {
    return AccountService.creditRequests.update(id, data).then(
      (result) => {
        if (result.status === 200) {
          dispatch({type: CREDIT_REQUEST_SET_DATA, data: result.data});
          return result.data
        }else if(result.status === 422){
          return {errors: result.data}
        }
        else {
          dispatch(defaultErrorFeedback());
          return null
        }
      }
    );
  };
}

export const authoriseCreditRequest = (id) => {
  return (dispatch) => {
    return AccountService.creditRequests.authorise(id).then(
      (result) => {
        if (result.status === 200) {
          dispatch({type: CREDIT_REQUEST_SET_DATA, data: result.data});
          return result.data
        }else if(result.status === 422){
          return {errors: result.data}
        }
        else {
          dispatch(defaultErrorFeedback());
          return null
        }
      }
    );
  };
}

export const cancelCreditRequest = (id) => {
  return (dispatch) => {
    return AccountService.creditRequests.cancel(id).then(
      (result) => {
        if (result.status === 200) {
          dispatch({type: CREDIT_REQUEST_SET_DATA, data: result.data});
          return result.data
        }else if(result.status === 422){
          return {errors: result.data}
        }
        else {
          dispatch(defaultErrorFeedback());
          return null
        }
      }
    );
  };
}