import { defaultErrorFeedback, successFeedback } from './feedback';
import {
  api_createConfigItem, api_createConfigItemNote,
  api_getConfigItem,
  api_getConfigItemNotes,
  api_updateConfigItem
} from '../utils/ConfigItems';
import { api_searchAccounts } from '../utils/Account/AccountServiceDeprecated';

export const CONFIG_ITEM_SET_DATA = 'CONFIG_ITEM_SET_DATA';
export const CONFIG_ITEM_UPDATE_FORM_FIELD = 'CONFIG_ITEM_UPDATE_FORM_FIELD';
export const CONFIG_ITEM_RESET_DATA = 'CONFIG_ITEM_RESET_DATA';
export const CONFIG_ITEM_SET_ASYNC_SEARCH_OPTIONS = 'CONFIG_ITEM_SET_ASYNC_SEARCH_OPTIONS';
export const CONFIG_ITEM_SET_NOTES = 'CONFIG_ITEM_SET_NOTES';
export const CONFIG_ITEM_ADD_NOTES_NOTE = 'CONFIG_ITEM_ADD_NOTES_NOTE';
export const CONFIG_ITEM_UPDATE_NOTE = 'CONFIG_ITEM_UPDATE_NOTE';

export const setConfigItemData = (data) => {
  return {
    type: CONFIG_ITEM_SET_DATA,
    configItem: data
  };
};

export const updateConfigItemFormField = (field, value) => {
  return {
    type: CONFIG_ITEM_UPDATE_FORM_FIELD,
    field,
    value
  };
};

export const getConfigItem = (id) => {
  return (dispatch) => {
    return api_getConfigItem(id)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setConfigItemData(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return null;
      });
  };
};

export const createConfigItem = (accountId,data) => {
  return (dispatch) => {
    return api_createConfigItem(accountId,data)
      .then((result) =>{
        if (result.status === 200) {
          dispatch(setConfigItemData(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return null;
      })
  }
}

export const updateConfigItem = (id, data) => {
  return (dispatch) => {
    return api_updateConfigItem(id, data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setConfigItemData(result.data));
          dispatch(successFeedback('Configuration Item updated!'));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return null;
      });
  };
};

export const resetConfigItem = () => { return { type: CONFIG_ITEM_RESET_DATA }; };

function setEntitySearchOptions(key, options) {
  return {
    type: CONFIG_ITEM_SET_ASYNC_SEARCH_OPTIONS,
    key,
    options
  };
}

export function searchAccounts(key, searchString, parentsOnly = false) {
  return (dispatch) => {
    return api_searchAccounts(searchString, parentsOnly)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch(setEntitySearchOptions(key, result.data));
            return result.data;
          }
          dispatch(defaultErrorFeedback());
          return [];
        }
      );
  };
}

export const setConfigItemNotes = (notes) => {
  return {
    type: CONFIG_ITEM_SET_NOTES,
    notes
  };
};

export const addConfigItemNote = (note) => {
  return {
    type: CONFIG_ITEM_ADD_NOTES_NOTE,
    note
  };
};

export const updateConfigItemNote = (note) => {
  return {
    type: CONFIG_ITEM_UPDATE_NOTE,
    note
  };
}

export const getConfigItemNotes = (id) => {
  return (dispatch) => {
    return api_getConfigItemNotes(id)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setConfigItemNotes(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return null;
      });
  };
};

export const createConfigItemNote = (id, data) => {
  return (dispatch) => {
    return api_createConfigItemNote(id, data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(addConfigItemNote(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return null;
      });
  };
};
