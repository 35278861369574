import React, {useEffect, useState} from "react";
import {configTemplateRequest} from "../../utils/ConfigTemplate";
import {ADD_TAG, REMOVE_TAG} from "../../actions/configTemplate";
import {useDispatch, useSelector} from "react-redux";
import TagsInput from 'react-tagsinput';
import {checkPermission} from "../../utils/Auth/AuthService";


const Tags = () => {

    const auth = useSelector(state => state.authenticationState);
    const dispatch = useDispatch()
    const {activeEntry} = useSelector(state => state.configTemplate);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        setAuthenticated(!!checkPermission("ConfigTemplates", auth.account.permissions))
    })

    const removeTag = tag => {
        configTemplateRequest(
            activeEntry.id,
            `configtemplate/${activeEntry.id}/tags/${tag}`,
            'delete')
            .then((response) => {
                if (response.status === 204) {
                    dispatch({type: REMOVE_TAG, data: tag});
                }
            }).catch(() => null)
    }

    const addTag = tag => {
        if (tag.length > 2 && activeEntry.tags.find(e => e === tag) === undefined) {
            const tagName = tag.toLowerCase();
            configTemplateRequest(
                activeEntry.id,
                `configtemplate/${activeEntry.id}/tags/${tagName}`,
                'put')
                .then((response) => {
                    if (response.status === 204) {
                        dispatch({type: ADD_TAG, data: tagName});
                    }
                }).catch(() => null)
        }
    }

    const handleTagChange = (tags, tag) => {
        if (tags.length > activeEntry.tags.length) {
            addTag(tag[0])
        } else {
            removeTag(tag[0])
        }
    }

    return <TagsInput
        value={activeEntry.tags ?? []}
        onChange={handleTagChange}
        onlyUnique={true}
        className="config-tags"
        disabled={authenticated === false}
        inputProps={
            authenticated
                ? {placeholder: "Add a tag"}
                : {placeholder: ""}
        }
        tagProps={{
            className: 'config-tags-input',
            classNameRemove: 'config-tags-remove'
        }}
    />
}

export default Tags;
