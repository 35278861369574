import React from 'react';
import { Card, Col, Row, Button, CardTitle, CardBody, CardHeader } from 'reactstrap';

export const Reports = ({ triggerReport }) => {
  return (
        <div className="animated fadeIn">
            <Row className="mb-5">
                <Col>
                    <CardTitle>Generate Reports</CardTitle>
                    <Button color="primary" outline className="mr-2" onClick={triggerReport.bind(null, 'finance')}>Finance</Button>
                    <Button color="primary" outline className="mr-2" onClick={triggerReport.bind(null, 'acumatica')}>Acumatica</Button>
                    <Button color="primary" outline className="mr-2" onClick={triggerReport.bind(null, 'voice')}>Voice</Button>
                    <Button color="primary" outline onClick={triggerReport.bind(null, 'dd-collection')}>DD Collection</Button>
                </Col>
            </Row>
           {/* <Row>
                <Col md={6}>
                    <Card>
                        <CardHeader>Top Spenders</CardHeader>
                        <CardBody>
                            <Bar
                                data={{
                                    labels: ['HMRC', 'Rugby', 'Speedloan', 'Arsenal', 'Grimsby', 'Spurs'],
                                    datasets:[{data:[1000, 900, 800, 700, 300, -200], backgroundColor: "rgb(246,195,70)"}],

                                }}
                                options={{ legend: {display: false} }}
                            />
                        </CardBody>
                    </Card>

                </Col>
                <Col md={6}>
                    <Card>
                        <CardHeader>Top Spenders</CardHeader>
                        <CardBody>
                            <Bar
                                data={{
                                    labels: ['HMRC', 'Rugby', 'Speedloan', 'Arsenal', 'Grimsby', 'Spurs'],
                                    datasets:[{data:[1000, 900, 800, 700, 300, -200], backgroundColor: "rgb(226,203,202)"}],
                                }}
                                options={{ legend: {display: false} }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}
        </div>
  );
};
