import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const PatchLeadService = {
  getPatchLeads: (include = []) => {
    return apiRequest({
      url: `/sdb/connections/patch-leads${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getPatchLead: (id, include = []) => {
    return apiRequest({
      url: `/sdb/connections/patch-leads/${id}${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  createPatchLead: (data, include = []) => {
    return apiRequest({
      url: `/sdb/connections/patch-leads${queryStringFromFields({ include })}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  updatePatchLead: (id, data, include = []) => {
    return apiRequest({
      url: `/sdb/connections/patch-leads/${id}${queryStringFromFields({ include })}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  deletePatchLead: (id) => {
    return apiRequest({
      url: `/sdb/connections/patch-leads/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }

};