

export const BillTemplateGroupForm = {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true,
    width: 12
  },
  description: {
    label: 'Description',
    type: 'textarea',
    width: 12,
    rows: 5
  },
}