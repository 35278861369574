import {
  BILL_TEMPLATE_GROUP_REMOVE_PRODUCT, BILL_TEMPLATE_GROUP_REMOVE_PRODUCT_FROM_ORIGINAL, BILL_TEMPLATE_GROUP_RESET,
  BILL_TEMPLATE_GROUP_SET_DATA,
  BILL_TEMPLATE_GROUP_SET_FORM_FIELD
} from '../actions/billTemplateGroup';

const initialState = {
  form: {
    isActive: true,
    orderProducts: []
  },
  original: {
    isActive: true,
    orderProducts: []
  }
};
export function billTemplateGroup(state = initialState, action){
  switch (action.type) {
    case BILL_TEMPLATE_GROUP_SET_DATA:
      return {
        ...state,
        form: action.data,
        original: action.data,
      }
    case BILL_TEMPLATE_GROUP_RESET:
      return {...initialState}
    case BILL_TEMPLATE_GROUP_SET_FORM_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.field
        }
      }
    case BILL_TEMPLATE_GROUP_REMOVE_PRODUCT:
      return {
        ...state,
        form: {
          ...state.form,
          orderProducts: state.form.orderProducts.filter(product => product.id !== action.id)
        }
      }
    case BILL_TEMPLATE_GROUP_REMOVE_PRODUCT_FROM_ORIGINAL:
      return {
        ...state,
        original: {
          ...state.original,
          orderProducts: state.original.orderProducts.filter(product => product.id !== action.id)
        }
      }
    default:
      return state
  }
}