import { DEVICE_MODELS_SET_DEVICE_MODELS } from '../actions/deviceModels';

const initialState = {
  deviceModels: []
};

const deviceModels = (state = initialState, action) => {
  switch (action.type) {
    case DEVICE_MODELS_SET_DEVICE_MODELS:
      return {
        deviceModels: [...action.data]
      };
    default:
      return state;
  }
};

export default deviceModels;