import { deepFreeze } from '../Helpers/DeepFreeze';

export const CarrierCircuitEnums = deepFreeze({
  type: {
    ETHERNET: 3,
    DARK_FIBRE: 1,
    LIT_WAVELENGTH: 2,
    ADSL: 5,
    SOGEA: 4,
    SOADSL: 6,
    FTTP: 7,
  }
});