import {
  CONTACT_SET_DATA,
  CONTACT_REPLACE_DATA,
  CONTACT_UPDATE_FIELD,
  CONTACT_RESET_DATA,
  CONTACT_SET_AUDIT_HISTORY
} from '../actions/contact';

const initialState = {
  original: {
    sites: []
  },
  form: {
    sites: []
  },
  audits: []
};

export default function contact(state = initialState, action) {
  switch (action.type) {
    case CONTACT_SET_DATA:
      return {
        ...state,
        original: { ...state.original, ...action.contact },
        form: { ...state.form, ...action.contact }
      };
    case CONTACT_REPLACE_DATA:
      return {
        ...state,
        original: { ...action.contact },
        form: { ...action.contact }
      };
    case CONTACT_UPDATE_FIELD:
      return {
        ...state,
        form: { ...state.form, [action.field]: action.value }
      };
    case CONTACT_RESET_DATA:
      return {
        ...state,
        original: { ...initialState.original },
        form: { ...initialState.form }
      };
    case CONTACT_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;
  }
}
