import { DeviceModelService } from '../utils/ServiceDB/DeviceModel';
import { defaultErrorFeedback, successFeedback } from './feedback';
import { VENDOR_ADD_NOTE, VENDOR_REMOVE_NOTE, VENDOR_UPDATE_NOTE } from './vendor';
import AuditService from '../utils/Audit';

export const DEVICE_MODEL_SET_DATA = 'DEVICE_MODEL_SET_DATA';
export const DEVICE_MODEL_SET_FIELD = 'DEVICE_MODEL_SET_FIELD';
export const DEVICE_MODEL_RESET_DATA = 'DEVICE_MODEL_RESET_DATA';

export const DEVICE_MODEL_ADD_NOTE = 'DEVICE_MODEL_ADD_NOTE';
export const DEVICE_MODEL_UPDATE_NOTE = 'DEVICE_MODEL_UPDATE_NOTE';
export const DEVICE_MODEL_REMOVE_NOTE = 'DEVICE_MODEL_REMOVE_NOTE';
export const DEVICE_MODEL_SET_AUDIT_HISTORY = 'DEVICE_MODEL_SET_AUDIT_HISTORY';


function setDeviceModelData(data) {
  return {
    type: DEVICE_MODEL_SET_DATA,
    data
  };
}

export function updateDeviceModelField(field, value) {
  return {
    type: DEVICE_MODEL_SET_FIELD,
    field,
    value
  };
}

export function resetDeviceModel() {
  return {
    type: DEVICE_MODEL_RESET_DATA
  };
}

export function addDeviceModelNote(note) {
  return {
    type: DEVICE_MODEL_ADD_NOTE,
    note
  };
}

export function updateDeviceModelNote(note) {
  return {
    type: DEVICE_MODEL_UPDATE_NOTE,
    note
  };
}

export function removeDeviceModelNote(note) {
  return {
    type: DEVICE_MODEL_REMOVE_NOTE,
    note
  };
}



export function getDeviceModel(id, includes, withs) {
  return (dispatch) => {
    return DeviceModelService.getDeviceModel(id, includes, withs).then((result) => {
      if (result.status === 200) {
        dispatch(setDeviceModelData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createDeviceModel(args, include = []) {
  return (dispatch) => {
    return DeviceModelService.createDeviceModel(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setDeviceModelData(result.data));
        dispatch(successFeedback('Device Model created!'));
        return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      } else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

export function updateDeviceModel(id, args, include = []) {
  return (dispatch) => {
    return DeviceModelService.updateDeviceModel(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setDeviceModelData(result.data));
        dispatch(successFeedback('Device Model updated!'));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function deleteDeviceModel(id) {
  return (dispatch) => {
    return DeviceModelService.deleteDeviceModel(id)
      .then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function getDeviceModelAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('sdb/device-models', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: DEVICE_MODEL_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}
