import { Alert, CardText, Col, Input, Row } from 'reactstrap';
import FilteredTable from '../../components/FilteredTable';
import React, { useEffect, useRef, useState } from 'react';
import { ButtonIcon } from '../../components/ButtonIcon';
import { nanoid } from 'nanoid';
import isDecimal from 'validator/lib/isDecimal';
import isInt from 'validator/lib/isInt';
import { SelectMod } from '../../components/Selects/SelectMod';

const UnitPricing = ({
  pricingType,
  create,
  editableKeyToFocus,
  errors,
  updateData,
  getData,
  hasChanges
}) => {

  const {data} = pricingType
  const {unit, items} = data
  const initRow = {
    quantity: '0',
    '12': '0',
    '24': '0',
    '36': '0',
    '48': '0',
    '60': '0',
  }
  const units = ['TB', 'GB', 'CPU', 'Users']

  const addRow = () => {
    updateData(items ? {items: [...items, {id: nanoid(), ...initRow}]} : {items: [{id: nanoid(), ...initRow}]})
  }

  const removeRow = (id) => {
    updateData({items: items.filter(row => row.id !== id)})
  }

  const setUnit = (unit) => {
    updateData({unit})
  }

  const save = async () => {
    const errors = await validate()
    create(data, errors)
  }

  const validate = async () =>{
    let errors = [];
    const quantities = [];
    if(!unit){
      errors.push(`You must select the unit thing!`);
    }
    if(!items || items.length === 0){
      errors.push(`You must provide some data!`);
    }else{
      items.forEach((row, index) => {
        if(quantities.includes(row.quantity)){
          errors.push(`Duplicate quantity ${row.quantity}.`);
        }else{
          quantities.push(row.quantity)
        }
        for (const [key, value] of Object.entries(row)) {
          if (key === 'quantity' && !isInt(value)) {
            errors.push(`Quantity must be a whole number on row ${index + 1}.`);
          }else if (key !== 'id' && !isDecimal(`${value}`, {decimal_digits: '1,2'})){
            errors.push(`Multiplier must be a valid decimal number on row ${index + 1}.`);
          }else if(key !== 'id' && key !== 'quantity' && Math.sign(value) !== 1){
            errors.push(`Multiplier must be a positive number on row ${index + 1}.`);
          }
        }
      })
    }
    return errors

  }
  const handleInput = (id, field) => {
    updateData({items: items.map(row => {
      if(row.id === id){
        return {...row, ...field}
      }
      return row
    })})
  }

  const getUnitOptions = () => {
    return units.map(unit => {
      return {label: unit, value: unit}
    })
  }
  const getUnitOption = (unit) => {
    if(units.find(item => item === unit)){
      return {label: unit, value: unit}
    }
    return null
  }
  const EditableInput = ({value, id, field}) => {
    const key = `${id}-${field}`
    return <Input
      value={value}
      onChange={
        (event) => {
          editableKeyToFocus.current = key;
          handleInput(id, {[field]: event.target.value})
        }
      }
      autoFocus={editableKeyToFocus.current === key}
    />
  }
  const columns = [
    {
      filterable: false,
      Header: () => {
        return <div className='text-center'>
            <SelectMod
              menuPortalTarget={document.body}
              options={getUnitOptions()}
              value={getUnitOption(unit)}
              onChange={(selected) => {setUnit(selected.value)}}
            />
        </div>
      },
      accessor: 'quantity',

      sortable: false,
      Cell: (props) =>
        <EditableInput
          value={props.value}
          id={ props.original.id}
          field={'quantity'}
        />
    },
    {
      filterable: false,
      Header: '12 month',
      accessor: '12',
      Cell: (props) =>
        <EditableInput
          value={props.value}
          id={ props.original.id}
          field={'12'}
        />
    },
    {
      filterable: false,
      Header: '24 month',
      accessor: '24',
      Cell: (props) =>
        <EditableInput
          value={props.value}
          id={ props.original.id}
          field={'24'}
        />
    },
    {
      filterable: false,
      Header: '36 month',
      accessor: '36',
      Cell: (props) =>
        <EditableInput
          value={props.value}
          id={ props.original.id}
          field={'36'}
        />
    },
    {
      filterable: false,
      Header: '48 month',
      accessor: '48',
      Cell: (props) =>
        <EditableInput
          value={props.value}
          id={ props.original.id}
          field={'48'}
        />
    },
    {
      filterable: false,
      Header: '60 month',
      accessor: '60',
      Cell: (props) =>
        <EditableInput
          value={props.value}
          id={ props.original.id}
          field={'60'}
        />
    },
    {
      filterable: false,
      sortable: false,
      Header: () => {
        return <div className='text-center'>
            <span className='text-info hoverPointer' onClick={addRow}>
              <i className='fa fa-2x fa-plus'/>
            </span>
        </div>
      },
      minWidth: 40,
      accessor: 'id',
      Cell: (props) =>
        <div className='text-center'>
            <span className='text-danger hoverPointer' onClick={() => removeRow(props.value)}>
              <i className='fa fa-2x fa-close'/>
            </span>
        </div>
    }
  ]
  return (
    <div className="animated fadeIn">
      <div className={'d-flex justify-content-between'}>
        <CardText>Variable storage size on fixed term with custom multipliers.</CardText>
        <div>
          <ButtonIcon icon={'fa fa-save'} colour='secondary' onClick={save} tooltip={'save'}  disabled={!hasChanges}/>
          <ButtonIcon icon={'fa fa-refresh'} colour='secondary' onClick={getData} tooltip={'reload'}/>
        </div>

      </div>
      {errors.length > 0
      && <Row>
        <Col>
          <Alert color={'danger'}>
            {errors.map((error, index) => {
              return <div key={'error' + index}>{error}</div>;
            })}
          </Alert>
        </Col>
      </Row>
      }
      <Row>
        <Col>
          <FilteredTable
            hideFilter
            columns={columns}
            data={items}
          />
        </Col>
      </Row>
    </div>
  )
}

export default UnitPricing;
