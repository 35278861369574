import { MULTISEARCH_SET_LOADING, MULTISEARCH_SET_RESULTS } from '../actions/multiSearch';
import update from 'immutability-helper';

const initialState = {
  results: [],
  loading: false
};

export default function multiSearch(state = initialState, action) {
  switch (action.type) {
    case MULTISEARCH_SET_RESULTS:
      return update(state, {
        results: {
          $set: action.data
        }
      });
    case MULTISEARCH_SET_LOADING:
      return update(state, {
        loading: {
          $set: action.data
        }
      });
    default:
      return state;
  }
}
