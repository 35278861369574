import FilteredTableV2 from '../../FilteredTableV2';
import React, { useRef } from 'react';
import numeral from 'numeral';
import { Input } from 'reactstrap';
import { ButtonIcon } from '../../ButtonIcon';
const CreditRequestProductsTable = ({
  products,
  updateProduct,
  removeProduct,
  users,
  optionSets
}) => {

  let editableKeyToFocus = useRef(null);

  const columns = [
    {
      header: 'Product',
      accessorKey: 'name',
      cell: props => {
        if(props.row.original.product){
          return `${props.row.original.product.productNumber} - ${props.row.original.product.name}`
        }
        return props.getValue()
      }
    },
    {
      header: 'Description to appear on Credit Note',
      accessorKey: 'description',
      minSize: 500,
      cell: (props) => {
        return <Input
          type={'text'}
          value={props.getValue()}
          onChange={(event) => {
            editableKeyToFocus.current = `${props.row.original.id}-description`;
            updateProduct(props.row.original.id, {description: event.target.value})
          }}
          autoFocus={`${props.row.original.id}-description` === editableKeyToFocus.current}
        />
      }
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      cell: (props) => {
        return <Input
          type={'text'}
          value={props.getValue()}
          onChange={(event) => {
            editableKeyToFocus.current = `${props.row.original.id}-amount`;
            updateProduct(props.row.original.id, {amount: event.target.value})
          }}
          autoFocus={`${props.row.original.id}-amount` === editableKeyToFocus.current}
        />
      }
    },
    {
      header: '',
      accessorKey: 'id',
      cell: props => {
        return (
          <div className={'d-flex justify-content-end'}>
            <ButtonIcon
              outline
              icon={'fa fa-remove'}
              colour={'danger'}
              tooltip={'remove'}
              onClick={() => removeProduct(props.row.original.id)}
            />
          </div>)
      }
    }
  ]

  return (
    <FilteredTableV2
      minRows={5}
      defaultPageSize={5}
      pageSizeOptions={[5,10,20,50,100]}
      showPagination={products.length > 5}
      columns={columns}
      data={products ?? []}
      noDataText={'No Products'}
      autoResetPageIndex={false}
    />
  )

}

export default CreditRequestProductsTable