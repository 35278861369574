import {
  PARTNERSHIP_SET_DATA,
  PARTNERSHIP_RESET_DATA,
  PARTNERSHIP_UPDATE_FIELD
} from '../actions/partnership';

const initialState = {
  original: {},
  form: {}
};

export const partnership = (state = initialState, action) => {
  switch (action.type) {
    case PARTNERSHIP_UPDATE_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          [action.field]: action.value
        }
      };
    case PARTNERSHIP_RESET_DATA:
      return {
        ...state,
        original: { ...initialState.original },
        form: { ...initialState.form }
      };
    case PARTNERSHIP_SET_DATA:
      return {
        ...state,
        original: { ...action.data },
        form: { ...action.data }
      };
    default:
      return state;
  }
}
