import { Badge } from 'reactstrap';
import React from 'react';

const SignatureForm = (isNew, getBadgeColor) => {

  return {
    status: {
      label: 'Status',
      type: 'custom',
      callBack: (key, field, data) => <h5>
        <Badge className={`p-2 faded-${getBadgeColor(data.status)}`}>{!isNew ? data.status : 'New'}</Badge>
      </h5>
    },
    company: {
      label: 'Account',
      type: 'select-async',
      mandatory: true,
      width: 12,
      optionLabelCallback:(opt) => `${opt.name} (${opt.accountnumber})`,
      cacheOptions: true,
      plaintext: (key, field, data) => !isNew,
      callBack: (key, field, data) => data.company?.name ?? '',
    },
    relatedTo: {
      label: 'Reference',
      type: 'select-async',
      width: 12,
      plaintext: (key, field, data) => !data.company?.accountid || !isNew,
      callBack: (key, field, data) =>
        data.relatedTo?.data?.number ??
        data.relatedTo?.data?.requestNumber ??
        data.relatedTo?.data?.orderNumber ??
        data.relatedTo?.data?.name ??
        data.relatedTo?.data?.description ??
        'N/A',
    },
    recipient: {
      label: 'Recipient',
      type: 'select',
      mandatory: true,
      width: 12,
      plaintext: (key, field, data) => (!isNew && data.status !== 'draft') || !data.company?.accountid,
      callBack: (key, field, data) => data.signatories?.find(signatory => signatory.documentTag === 0)?.signatory.data.name ?? 'N/A',
    },
    hSoSignatory: {
      label: 'hSo Signatory',
      type: 'select',
      mandatory: true,
      width: 12,
      plaintext: (key, field, data) => !isNew && data.status !== 'draft',
      callBack: (key, field, data) => data.signatories?.find(signatory => signatory.documentTag === 1)?.signatory.data.name ?? '',
    },
    title: {
      label: 'Subject',
      type: 'text',
      mandatory: true,
      width: 12,
      plaintext: (key, field, data) => !isNew && data.status !== 'draft'
    },
    message: {
      label: 'Message',
      type: 'textarea',
      rows: 5,
      mandatory: true,
      width: 12,
      plaintext: (key, field, data) => !isNew && data.status !== 'draft'
    }
  }
}

export default SignatureForm