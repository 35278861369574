import React, { useCallback, useEffect, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  createOpticalSystem,
  getOpticalSystem,
  resetOpticalSystem,
  updateOpticalSystem,
  setOpticalSystemField,
  deleteOpticalSystem
} from '../../../actions/opticalSystem';
import GenericCardForm from '../../../components/GenericCardForm';
import form from './form';
import { DeviceDeploymentService } from '../../../utils/ServiceDB/DeviceDeployment';
import { CarrierCircuitService } from '../../../utils/ServiceDB/CarrierCircuit';
import isEmpty from 'lodash.isempty';
import { Row, Col } from 'reactstrap';
import OpticalSystemConnections from './OpticalSystemConnections';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import { DeviceDeploymentEnums } from '../../../utils/Constants/DeviceDeployment';
import { canAccessServiceDb } from '../../../utils/Auth/AuthService';
import { CarrierCircuitEnums } from '../../../utils/Constants/CarrierCircuits'
import { useNavigate, useParams } from 'react-router-dom'

const OpticalSystem = ({ id = null, data, original }) => {

  // router
  const navigate = useNavigate();
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  const opticalSystemId = useMemo(() => (id || params.opticalSystemId), [id, params.opticalSystemId])
  const isNew = useMemo(() => opticalSystemId === 'new' && !original.id, [opticalSystemId, original.id])

  useEffect(() => {
    if (original.name) {
      dispatch(addBreadcrumbs([{ name: original.name }]));
    }

    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [original.name]);

  const options = {
    type: [
      { value: 'cwdm', label: 'Coarse Wavelength Division Multiplexing' },
      { value: 'dwdm', label: 'Dense Wavelength Division Multiplexing' },
      { value: 'active', label: 'Active' }
    ]
  };

  const aSyncOptions = {
    carrierCircuit: (search) => CarrierCircuitService.getCarrierCircuits(1, 100, search)
      .then((result) => result.data?.items.filter((carrierCircuit) => carrierCircuit.type.id === CarrierCircuitEnums.type.DARK_FIBRE)
        .map(carrierCircuit => {
          // cant be a component or have an optical system already
          const inUseByOpticalSystem = !isEmpty(carrierCircuit.opticalSystem) && carrierCircuit.opticalSystem.id !== original.id;
          const inUseByConnection = !isEmpty(carrierCircuit.component.connection);
          return {
            ...carrierCircuit,
            label: `${carrierCircuit.name} (${carrierCircuit.carrierReference ?? ''}) ${inUseByOpticalSystem ? ` (in use by optical system '${carrierCircuit.opticalSystem.name}')` : inUseByConnection ? ` (in use by connection '${carrierCircuit.component.connection.name})'` : ''}`,
            isDisabled: inUseByOpticalSystem || inUseByConnection
          };
        }) || []),
    deviceDeployment: (search, key) => DeviceDeploymentService.getDeviceDeployments(
      0,
      100,
      `hostname:${search};type:${DeviceDeploymentEnums.type.mux},${DeviceDeploymentEnums.type.nte}`,
      undefined,
      'items.ports,items.opticalSystem',
      'ports;aOpticalSystem;bOpticalSystem',
      'type:notin',
      'and'
    )
      .then((result) => result.data?.items
        .map(deviceDeployment => {
          const inUse = deviceDeployment.opticalSystem && deviceDeployment.opticalSystem[key]?.id !== original.id;
          return {
            ...deviceDeployment,
            label: `${deviceDeployment.hostname}${inUse ? ` (in use by ${deviceDeployment.opticalSystem[key].hostname})` : ''}`,
            isDisabled: inUse > 0 ||
              (key === 'aEnd' && data.bEnd?.id === deviceDeployment.id) ||
              (key === 'bEnd' && data.aEnd?.id === deviceDeployment.id)
          };
        }) || [])
  };

  const requestStructure = (data) => ({
    name: data.name,
    carrierCircuitId: data.carrierCircuit?.uuid,
    type: data.type,
    channels: data.channels,
    startChannel: data.startChannel,
    spacing: data.spacing,
    aEnd: data.aEnd?.id,
    bEnd: data.bEnd?.id
  });

  const include = [
    'wavelengths',
    'wavelengths.component',
    'wavelengths.component.connection',
    'wavelengths.component.connection.aEnd',
    'wavelengths.component.connection.bEnd',
    'wavelengths.component.connection.aEnd.deviceDeployment',
    'wavelengths.component.connection.bEnd.deviceDeployment'
  ];

  return (
    <Row>
      <Col>
        <GenericCardForm
          id={opticalSystemId}
          title={data.name || 'New Optical System'}
          isNew={isNew}
          data={data}
          original={original}
          form={form}
          options={options}
          aSyncOptions={aSyncOptions}
          onFetch={() => dispatch(getOpticalSystem(opticalSystemId, include))}
          onReset={() => dispatch(resetOpticalSystem())}
          setField={(field, value) => dispatch(setOpticalSystemField(field, value))}
          requestStructure={requestStructure}
          onUpdate={(toUpdate) => dispatch(updateOpticalSystem(opticalSystemId, toUpdate, include))}
          onCreate={(toCreate) => dispatch(createOpticalSystem(toCreate, include))}
          onCreated={(result) => navigate(`/sdb/optical-systems/${result.id}`)}
          onDelete={() => dispatch(deleteOpticalSystem(opticalSystemId))}
          onDeleted={() => navigate('/sdb/optical-systems')}
          canEdit={canAccessServiceDb()}
          deleteButtonDisabled={original.wavelengthCount > 0}
          deleteButtonDisabledTooltip="Optical system has existing wavelengths"
        />
      </Col>
      {!isNew && original.id ? (
        <Col>
          <OpticalSystemConnections
            connections={original.wavelengths.filter(wavelength => !isEmpty(wavelength.component))
              .map(wavelength => ({
                ...wavelength.component.connection,
                wavelength: wavelength
              }))}
            opticalSystem={data}
          />
        </Col>
      ) : ''}
    </Row>
  );
};

function mapStateToProps({ opticalSystem, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    data: opticalSystem.data,
    original: opticalSystem.original
  };
}

export default connect(mapStateToProps)(OpticalSystem);
