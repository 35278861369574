import { netApiRequest } from '../Api/ApiRequest';

export function api_getK8sContexts() {
  const url = '/k8s';
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function api_updateK8sImage(context, image) {
  const url = '/k8s/githubhook/' + context;
  return netApiRequest({
    url,
    method: 'post',
    data : {
      event: 'deployment',
      repository: 'highspeedoffice/hso-admin-api',
      image: image
    }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}
