import {
  QUOTE_ADD_COMPARE_V2_SERVICES,
  QUOTE_ADD_COMPARE_V2_SITES,
  QUOTE_ADD_ORDER_FORM_SERVICE_LOCATION,
  QUOTE_ADD_PRODUCT,
  QUOTE_CLONE,
  QUOTE_CLONE_PRODUCT,
  QUOTE_REMOVE_ORDER_FORM_SERVICE_LOCATION,
  QUOTE_REMOVE_PRODUCT,
  QUOTE_RESET,
  QUOTE_RESET_ORDER_FORM,
  QUOTE_RESET_ORDER_FORM_SERVICE_LOCATIONS,
  QUOTE_SET_DATA,
  QUOTE_SET_LOADER,
  QUOTE_SET_PRICING_TOOL_PRODUCTS,
  QUOTE_SET_SITES,
  QUOTE_TOGGLE_ORDER_FORM_LOADING,
  QUOTE_UPDATE_FIELD,
  QUOTE_UPDATE_ORDER_FORM_FIELD,
  QUOTE_UPDATE_ORDER_FORM_NETWORK_SERVICE_ORDER,
  QUOTE_UPDATE_ORDER_FORM_OTHER_SERVICE_ORDER,
  QUOTE_UPDATE_ORDER_FORM_SERVICE_LOCATION_FIELD,
  QUOTE_UPDATE_QUOTE_PRODUCT_FIELD,
  QUOTE_UPDATE_PRODUCT_FIELD,
  QUOTE_RESET_PRODUCT_LINKED_PRODUCTS,
  QUOTE_ADD_NOTE,
  QUOTE_UPDATE_NOTE,
  QUOTE_REMOVE_NOTE,
  QUOTE_TOGGLE_CANCELLATION_FORM_LOADING, QUOTE_SET_AUDIT_HISTORY
} from '../actions/quote';
import {
  getProductForPricingToolKit,
  getProductCodeForPricingToolTech,
  getProductTemplate
} from '../helpers/Products';
import { v4 } from 'uuid';
import { roundToTwo } from '../helpers/numbers';

const initialState = {
  form: {
    status: 1,
    preferredQuote: false,
    pricingToolQuote: null,
    products: [],
    notes: []
  },
  original: {
    status: 1,
    preferredQuote: false,
    pricingToolQuote: null,
    products: [],
    notes: []
  },
  orderForm: {
    loading: false,
    networkServices: [],
    otherServices: [],
    supplySite: null,
    supplyContact: null,
    billingSite: null,
    billingContact: null,
    serviceLocations: [],
    comments: '',
    companyRegSub: ''
  },
  cancellationForm: {
    loading: false
  },
  contacts: [],
  sites: [],
  pricingToolQuoteSites: [],
  pricingToolProducts: [],
  audits: [],
  loading: false
};

export function quote(state = initialState, action) {
  let services;
  let products;
  let networkServices;
  let otherServices;
  let locations;
  let sites;
  const insertAndShift = (arr, from, to) => {
    let cutOut = arr.splice(from, 1) [0];
    arr.splice(to, 0, cutOut);
  };
  switch (action.type) {
    case QUOTE_SET_LOADER: {
      return {
        ...state,
        loading: action.load
      }

    }
    case QUOTE_SET_DATA:
      networkServices = action.data.products.filter((product) => /^HSO10-010|HSO10-011/.test(product.productNumber));
      otherServices = action.data.products.filter((product) => !/^HSO10-010|HSO10-011|HSO00-101/.test(product.productNumber));
      return {
        ...state,
        form: action.data,
        original: action.data,
        orderForm: {
          ...state.orderForm,
          networkServices,
          otherServices
        }
      };
    case QUOTE_SET_SITES:
      return {
        ...state,
        sites: action.sites
      };
    case QUOTE_UPDATE_FIELD:
      return {
        ...state,
        form: { ...state.form, ...action.field }
      };
    case QUOTE_UPDATE_QUOTE_PRODUCT_FIELD:
      products = state.form.products.map((product) => {
        if (action.id === product.id) {
          return { ...product, ...action.field };
        }
        return product;
      });
      return {
        ...state,
        form: {
          ...state.form,
          products
        }
      };

    case QUOTE_UPDATE_PRODUCT_FIELD:
      products = state.form.products.map((product) => {
        if (action.id === product.id && action.field.sla === undefined) {
          return {
            ...product,
            ...action.field
          };
        }else if(action.field.sla !== undefined){
          return {
            ...product,
            sla: action.field.sla
          };
        }
        return products;
      });
      return {
        ...state,
        form: {
          ...state.form,
          products: [...products]
        }
      };
    case QUOTE_RESET_PRODUCT_LINKED_PRODUCTS:
      products = state.form.products.map((product, index) => {
        if (index === action.productIndex) {
          return {
            ...product,
            linkedProducts: []
          };
        }else{
          return product
        }
      });
      return {
        ...state,
        form: {
          ...state.form,
          products
        }
      }
    case QUOTE_UPDATE_ORDER_FORM_OTHER_SERVICE_ORDER:
      otherServices = [...state.orderForm.otherServices];
      insertAndShift(otherServices, action.from, action.to);
      return {
        ...state,
        orderForm: {
          ...state.orderForm,
          otherServices
        }
      };
    case QUOTE_UPDATE_ORDER_FORM_NETWORK_SERVICE_ORDER:
      networkServices = [...state.orderForm.networkServices];
      insertAndShift(networkServices, action.from, action.to);
      return {
        ...state,
        orderForm: {
          ...state.orderForm,
          networkServices
        }
      };
    case QUOTE_UPDATE_ORDER_FORM_FIELD:
      return {
        ...state,
        orderForm: {
          ...state.orderForm,
          ...action.field
        }
      };
    case QUOTE_TOGGLE_ORDER_FORM_LOADING:
      return {
        ...state,
        orderForm: {
          ...state.orderForm,
          loading: !state.orderForm.loading
        }
      };
    case QUOTE_TOGGLE_CANCELLATION_FORM_LOADING:
      return {
        ...state,
        cancellationForm: {
          ...state.cancellationForm,
          loading: !state.cancellationForm.loading
        }
      };
    case QUOTE_ADD_ORDER_FORM_SERVICE_LOCATION:
      return {
        ...state,
        orderForm: {
          ...state.orderForm,
          serviceLocations: [...state.orderForm.serviceLocations, action.location]
        }
      };
    case QUOTE_RESET_ORDER_FORM_SERVICE_LOCATIONS:
      return {
        ...state,
        orderForm: {
          ...state.orderForm,
          serviceLocations: []
        }
      };
    case QUOTE_RESET_ORDER_FORM:
      networkServices = state.form.products.filter((service) => /^HSO10-010/.test(service.productNumber));
      otherServices = state.form.products.filter((service) => !/^HSO10-010/.test(service.productNumber));
      return {
        ...state,
        orderForm: {
          ...initialState.orderForm,
          networkServices,
          otherServices
        }
      };
    case QUOTE_REMOVE_ORDER_FORM_SERVICE_LOCATION:
      return {
        ...state,
        orderForm: {
          ...state.orderForm,
          serviceLocations: state.orderForm.serviceLocations.filter(location => location.siteId !== action.id)
        }
      };
    case QUOTE_UPDATE_ORDER_FORM_SERVICE_LOCATION_FIELD:
      locations = state.orderForm.serviceLocations.map((location) => {
        if (location.siteId === action.siteId) {
          return { ...location, ...action.field };
        }
        return location;
      });
      return {
        ...state,
        orderForm: {
          ...state.orderForm,
          serviceLocations: locations
        }
      };
    case QUOTE_RESET:
      return {
        ...initialState,
        loading: state.loading,
        pricingToolProducts: state.pricingToolProducts
      };
    case QUOTE_CLONE:
      return {
        ...initialState,
        form: {
          ...initialState.form,
          name: state.form.name,
          pricingToolQuote: state.form.pricingToolQuote,
          products: state.form.products.map((product) => {
            return {
              id: `new-${v4()}`,
              productNumber: getProductTemplate(product.productNumber),
              name: product.name,
              install: product.install,
              rental: product.rental,
              quantity: product.quantity,
              term: product.term,
              description: product.name,
              pricingToolQuote: product.pricingToolQuote,
              pricingToolQuoteSite: product.pricingToolQuoteSite,
              billingCycle: product.billingCycle,
              orderType: product.orderType,
              committedDataRate: product.committedDataRate,
              overageRate: product.overageRate,
              priceListItem: product.priceListItem?.id,
              sla: product.sla?.id,
              linkedProducts: product.linkedProducts,
            };
          })
        },
        contacts: [...state.contacts]
      };
    case QUOTE_ADD_PRODUCT:
      return {
        ...state,
        form: {
          ...state.form,
          products: [...state.form.products, action.product]
        }
      };
    case QUOTE_REMOVE_PRODUCT:
      const serviceToRemove = state.form.products[action.index];
      let removePricingToolQuoteId = false;
      services = state.form.products.filter((product, index) => {
        if (serviceToRemove.pricingToolQuote && product.pricingToolQuote) {
          removePricingToolQuoteId = true;
          return false;
        }
        return index !== action.index;
      });
      return {
        ...state,
        form: {
          ...state.form,
          pricingToolQuote: removePricingToolQuoteId ? null : state.form.pricingToolQuote,
          products: services
        }
      };
    case QUOTE_CLONE_PRODUCT:
      const serviceClone = {
        ...state.form.products[action.index],
        id: `new-${v4()}`
      };
      return {
        ...state,
        form: {
          ...state.form,
          products: [...state.form.products, serviceClone]
        }
      };
    case QUOTE_ADD_COMPARE_V2_SERVICES:
      sites = action.quote.sites.concat(action.quote.p2p || []);
      const compareV2Services = sites.reduce((result, site) => {
        const productNumber = getProductCodeForPricingToolTech(site.technology?.name);
        const product = action.products.find(product => product.template.productCode === productNumber)
        const preferredPrice = site.prices.find(price => price.preferred);
        const isRO2 = site.technology?.name === 'RO2';
        const isP2P = site.solution === 'p2p'
        const siteAddress =  site.address ?
          `${site.address} - ${site.postcode}` :
          `${site.a_postcode} - ${site.b_postcode}`;
        const kitProductCode = getProductForPricingToolKit(site.solution);
        const kitProduct= action.products.find(product => product.template.productCode === kitProductCode)
        const productName = `Network Service - ${siteAddress} - ${site.cdr}/${site.bearer_speed}`;
        const kitProductName = `${kitProduct.name} - ${isP2P ? site.a_postcode : siteAddress}`;
        result.push({
          id: `new-${v4()}`,
          productNumber,
          name : isRO2 ? `${productName} - Primary` : productName ,
          install: isRO2 ? roundToTwo(preferredPrice.install / 2) : preferredPrice.install,
          rental: isRO2 ? roundToTwo(preferredPrice.rental / 2) : preferredPrice.rental,
          quantity: 1,
          term: site.term * 12,
          description: `Compare quote`,
          pricingToolQuote: String(action.quote.id),
          pricingToolQuoteSite: site.id,
          sla: product.template.productSLA?.id,
          priceListItem: product.id,
          linkedProducts: [],
          orderType: 100000000,
        });
        if(site.kit.name !== 'Wires Only'){
          result.push({
            id: `new-${v4()}`,
            productNumber: kitProduct.template.productCode,
            name : `${isRO2 ? `${kitProductName} - Primary` : kitProductName} - ${site.kit.name}` ,
            install: roundToTwo(preferredPrice.kitInstall),
            rental: roundToTwo(preferredPrice.kitRental),
            quantity: 1,
            term: site.term * 12,
            description: `Compare quote`,
            pricingToolQuote: String(action.quote.id),
            pricingToolQuoteSite: site.id,
            sla: kitProduct.template.productSLA?.id,
            priceListItem: kitProduct.id,
            linkedProducts: [],
            orderType: 100000000,
          });
        }
        if(isRO2){
          result.push({
            id: `new-${v4()}`,
            productNumber,
            name: `${productName} - Secondary`,
            install: roundToTwo(preferredPrice.install / 2),
            rental: roundToTwo(preferredPrice.rental / 2),
            quantity: 1,
            term: site.term * 12,
            description: `Compare quote`,
            pricingToolQuote: String(action.quote.id),
            pricingToolQuoteSite: site.id,
            sla: product.template.productSLA?.id,
            priceListItem: product.id,
            linkedProducts: [],
            orderType: 100000000,
          });
        }
        if((isRO2 || isP2P) && site.kit.name !== 'Wires Only'){
          result.push({
            id: `new-${v4()}`,
            productNumber: kitProduct.template.productCode,
            name : `${ isP2P ? `${kitProductName} - ${site.b_postcode}` : `${kitProductName} - Secondary`} - ${site.kit.name}` ,
            install: roundToTwo(preferredPrice.kitInstall / 2),
            rental: roundToTwo(preferredPrice.kitRental / 2),
            quantity: 1,
            term: site.term * 12,
            description: `Compare quote`,
            pricingToolQuote: String(action.quote.id),
            pricingToolQuoteSite: site.id,
            sla: kitProduct.template.productSLA?.id,
            priceListItem: kitProduct.id,
            linkedProducts: [],
            orderType: 100000000,
          });
        }
        return result;
      }, []);
      return {
        ...state,
        form: {
          ...state.form,
          pricingToolQuote: action.quote.id,
          products: [...state.form.products, ...compareV2Services]
        },
        pricingToolQuoteSites: sites
      };
    case QUOTE_ADD_COMPARE_V2_SITES:
      sites = action.quote.sites.length ? action.quote.sites : action.quote.p2p;
      return {
        ...state,
        pricingToolQuoteSites: sites
      };
    case QUOTE_SET_PRICING_TOOL_PRODUCTS:
      return {
        ...state,
        pricingToolProducts: action.products
      }
    case QUOTE_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    case QUOTE_ADD_NOTE:
      return {
        ...state,
        form: {
          ...state.form,
          notes: [...state.form.notes, action.note],
        },
        original: {
          ...state.original,
          notes: [...state.original.notes, action.note],
        }
      }
    case QUOTE_UPDATE_NOTE: {
      const notes = state.original.notes.map(note => {
        if(note.id === action.note.id){
          return action.note
        }
        return note
      })
      return {
        ...state,
        form: {
          ...state.form,
          notes
        },
        original: {
          ...state.original,
          notes
        }
      }
    }
    case QUOTE_REMOVE_NOTE:
      const notes = state.form.notes.filter(note => note.id !== action.note.id)
      return {
        ...state,
        form: {
          ...state.form,
          notes
        },
        original: {
          ...state.original,
          notes
        }
      }
    default:
      return state;
  }
}
