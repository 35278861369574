import {
  QUEUES_DELETE_FAILED_JOB, QUEUES_RESET_SUMMARY,
  QUEUES_SET_FAILED_JOB,
  QUEUES_SET_FAILED_JOBS, QUEUES_SET_QUEUE,
  QUEUES_SET_SUMMARY,
  QUEUES_SET_TOGGLE_FAILED_JOBS_LOADING,
  QUEUES_SET_TOGGLE_SUMMARY_LOADING, QUEUES_TOGGLE_FAILED_JOB_MODAL
} from '../actions/queues';

const initialState = {
  loading: false,
  loadingFailedJobs: false,
  loadingFailedJob: false,
  queues: [],
  queue: '',
  failedJobs: [],
  failedJob: null,
  failedJobsTable: {
    lastPage: 1
  }
};

export default function queues(state = initialState, action) {
  switch (action.type) {
    case QUEUES_SET_SUMMARY:
      return {
        ...state,
        queues: [...state.queues, ...action.data]
      };
    case QUEUES_RESET_SUMMARY:
      return {
        ...state,
        queues: initialState.queues
      }
    case QUEUES_SET_QUEUE:
      return {
        ...state,
        queue: action.queue
      };
    case QUEUES_SET_FAILED_JOBS:
      return {
        ...state,
        failedJobs: action.jobs,
        failedJobsTable: action.table
      };
    case QUEUES_SET_FAILED_JOB:
      return {
        ...state,
        failedJob: { ...action.job }
      };
    case QUEUES_DELETE_FAILED_JOB:
      return {
        ...state,
        failedJobs: state.failedJobs.filter(job => job.id !== action.id)
      };
    case QUEUES_SET_TOGGLE_SUMMARY_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case QUEUES_SET_TOGGLE_FAILED_JOBS_LOADING:
      return {
        ...state,
        loadingFailedJobs: !state.loadingFailedJobs
      };
    case QUEUES_TOGGLE_FAILED_JOB_MODAL:
      return {
        ...state,
        failedJob: null
      };
    default:
      return state;
  }
}
