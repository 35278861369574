import { getApiData } from '../utils/Api/ApiRequest';
import { errorFeedback } from './feedback';

export const UPDATE_APP_VERSION_INFO = 'UPDATE_APP_VERSION_INFO';
export const SET_CURRENT_UI_VERSION = 'SET_CURRENT_UI_VERSION';

function setAppVersionData(data) {
  return {
    type: UPDATE_APP_VERSION_INFO,
    data
  };
}

export function getAppVersionData() {
  return (dispatch) => {
    return getApiData().then((result) => {
      if (result.status === 200) {
        dispatch(setAppVersionData(result.data));
      } else {
        dispatch(errorFeedback('Could not fetch application version data'));
      }
    });
  };
}
