import isEmpty from 'lodash.isempty';
import { Link } from 'react-router-dom';
import React from 'react';

export default {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true
  },
  block: {
    label: 'Block',
    labelCallback: (data) => <>
      Block {!isEmpty(data.block) ? <Link to={`/sdb/ipam/blocks/${data.block.id}`}
                                          className="float-right"><i
      className="fa fa-link"/></Link> : ''}
    </>,
    type: 'select-async',
    entity: 'block',
    mandatory: true,
    readOnly: (key, field, data) => data.blockProvided,
    optionLabelCallback: (opt) => `${opt.name} (${opt.networkAddress}/${opt.mask})`
  },
  parentSubnet: {
    label: 'Parent Subnet',
    labelCallback: (data) => <>
      Parent Subnet {!isEmpty(data.parentSubnet) ? <Link
      to={`/sdb/ipam/subnets/${data.parentSubnet.id}`}><i className="fa fa-link"/></Link> : ''}
    </>,
    type: 'select-async',
    entity: 'subnet',
    readOnly: (key, field, data) => data.parentSubnetProvided,
    optionLabelCallback: (opt) => `${opt.name} (${opt.networkAddress}/${opt.mask})`
  },
  assignedTo: {
    label: 'Assigned To',
    type: 'select-async',
    entity: 'site',
    mandatory: true,
    optionLabelCallback: (opt) => `${opt.name} (${opt.accountnumber})`
  },
  mask: {
    label: 'Mask',
    type: 'number',
    mandatory: true,
    readOnly: (key, field, data) => data.maskProvided,
  },
  networkAddress: {
    label: 'Network Address',
    labelCallback: (data) => <>
      Network Address{data.getNextNetworkAddressButton ? data.getNextNetworkAddressButton() : ''}
    </>,
    type: 'text',
    mandatory: true
  },
  allocatedToType: {
    label: 'Allocated To (Type)',
    type: 'select',
    mandatory: (data) => data.childSubnetCount <= 0 && !isEmpty(data?.allocatedTo?.data) && !data.hideAllocatedTo,
    show: (field, data) => data.childSubnetCount <= 0 && !data.hideAllocatedTo
  },
  allocatedTo: {
    label: 'Allocated To',
    type: 'select-async',
    entity: 'logicalService',
    mandatory: (data) => data.childSubnetCount <= 0 && !isEmpty(data?.allocatedTo?.type) && !data.hideAllocatedTo,
    show: (field, data) => data.childSubnetCount <= 0 && !data.hideAllocatedTo
  },
  description: {
    label: 'Description',
    type: 'textarea',
    width: 12
  }
};