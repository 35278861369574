import {
  VENDOR_ADD_NOTE, VENDOR_REMOVE_NOTE,
  VENDOR_RESET_DATA, VENDOR_SET_AUDIT_HISTORY,
  VENDOR_SET_DATA,
  VENDOR_SET_FIELD,
  VENDOR_UPDATE_NOTE
} from '../actions/vendor';

const initialState = {
  data: {
    deviceModels: [],
    notes: []
  },
  original: {
    deviceModels:[],
    notes: []
  },
  audits: []
};
const vendor = (state = initialState, action) => {
  switch (action.type) {
    case VENDOR_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case VENDOR_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case VENDOR_RESET_DATA:
      return {
        ...initialState
      };
    case VENDOR_ADD_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: [...state.data.notes, action.note]
        },
        original: {
          ...state.original,
          notes: [...state.original.notes, action.note]
        },

      }
    case VENDOR_UPDATE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
        original: {
          ...state.original,
          notes: state.original.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
      }
    case VENDOR_REMOVE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.filter(note => note.id !== action.note.id)
        },
        original: {
          ...state.original,
          notes: state.original.notes.filter(note => note.id !== action.note.id)
        }
      }
    case VENDOR_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;
  }
}

export default vendor;