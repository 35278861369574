import {
  BILL_TEMPLATE_ADD_GROUP, BILL_TEMPLATE_REPLACE_GROUP,
  BILL_TEMPLATE_RESET,
  BILL_TEMPLATE_SET_DATA,
  BILL_TEMPLATE_SET_FORM_FIELD
} from '../actions/billTemplate';

const initialState = {
  form: {
    default: false,
    isActive: true
  },
  original: {
    default: false,
    isActive: true
  }
};
export function billTemplate(state = initialState, action) {
  let groups
  switch (action.type) {
    case BILL_TEMPLATE_SET_DATA:
      return {
        ...state,
        original: action.data,
        form: action.data,
      }
    case BILL_TEMPLATE_SET_FORM_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.field
        }
      }
    case BILL_TEMPLATE_ADD_GROUP:
      return {
        ...state,
        form: {
          ...state.form,
          billTemplateGroups: [...state.form.billTemplateGroups, action.group]
        },
        original: {
          ...state.original,
          billTemplateGroups: [...state.original.billTemplateGroups, action.group]
        }
      }
    case BILL_TEMPLATE_REPLACE_GROUP:
      groups = state.form.billTemplateGroups.map(group => {
        if(group.id === action.group.id){
          return action.group
        }
        return group
      });
      return {
        ...state,
        form: {
          ...state.form,
          billTemplateGroups: groups
        },
        original: {
          ...state.original,
          billTemplateGroups: groups
        }
      }
    case BILL_TEMPLATE_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}