import React from 'react';
import ReactTable from 'react-table-v6';
import { Input } from 'reactstrap';

const SOATable = ({ data, handleInput, updating }) => {
  const records = [
    { name: 'Root Nameserver', value: data.rns },
    { name: 'Root Mail Box', value: data.mbox },
    { name: 'Refresh', value: '10 Minutes' },
    { name: 'Retry', value: '5 Minutes' },
    { name: 'TTL', value: '5 minutes' },
    { name: 'Serial', value: data.serial },
    { name: 'Expire', value: data.expire },
    { name: 'Minimum', value: data.minimum }

  ];
  const columns = [
    {
      Header: '',
      accessor: 'name',
      sortable: false,
      style: { whiteSpace: 'unset' },
      Cell: props => (
                <div className={'text-primary'}>{props.value}</div>
      )
    },
    {
      Header: '',
      accessor: 'value',
      sortable: false,
      style: { whiteSpace: 'unset' },
      Cell: props => {
        if (props.original.name === 'Expire') {
          return <ExpireSelect handleInput={handleInput} value={props.value}/>;
        }
        if (props.original.name === 'Minimum') {
          return <MinimumSelect handleInput={handleInput} value={props.value}/>;
        }
        return props.value;
      }

    }
  ];

  return <ReactTable
        getTheadProps={() =>{
          return {
            style: { border: 'none' }
          };
        }}
        getTheadTrProps={() =>{
          return {
            style: { border: 'none' }
          };
        }}
        getTheadThProps={() =>{
          return {
            style: { border: 'none' }
          };
        }}
        getTdProps={() =>{
          return {
            style: { border: 'none' }
          };
        }}
        style={{ border: 'none' }}
        className="animated fadeIn"
        loading={updating}
        loadingText={'Processing...'}
        data={records}
        columns={columns}
        minRows="2"
        defaultPageSize={10}
        showPagination={false}
        filterable={false}
    />;
};

const ExpireSelect = ({ handleInput, value }) =>{
  return (
        <Input type="select" id="expire" value={value} onChange={handleInput} disabled>
            <option value={604800}>1 week</option>
            <option value={864000}>10 days</option>
            <option value={1209600}>2 weeks</option>
        </Input>
  );
};
const MinimumSelect = ({ handleInput, value }) =>{
  return (
        <Input type="select" id="minimum" value={value} onChange={handleInput} disabled>
            <option value="300">5 minutes</option>
            <option value="1200">20 minutes</option>
            <option value="3600">1 hour</option>
            <option value="10800">3 hours</option>
            <option value="21600">6 hours</option>
            <option value="43200">12 hours</option>
            <option value="86400">1 day</option>
            <option value="172800">2 days</option>
            <option value="259200">3 days</option>
            <option value="604800">1 week</option>
        </Input>
  );
};

export default SOATable;
