import { apiRequest } from '../Api/ApiRequest';

export function api_getCloudWatchLogs(group, from, to, level) {
  return apiRequest({
    url: `/cloudwatch/${group}/logs?startTime=${from}&endTime=${to}&level=${level}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getCloudWatchLog(group, id) {
  return apiRequest({
    url: `/cloudwatch/${group}/logs/${id}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}
