import { ProductEnums } from '../../utils/Constants/Product';

const NewOrderProductRequestForm =  {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true,
    width: 12
  },
  priceList: {
    label: 'Price List',
    type: 'text',
    plaintext: true,
    width: 12,
    callBack: (key, field, data) => data.priceList?.name ?? 'N/A'
  },
  priceListItem: {
    label: 'Price List Item',
    type: 'select-async',
    width: 12,
    mandatory: true,
    minSearchLength: 5
  },
  install: {
    label: 'Install',
    type: 'text',
    mandatory: true,
    width: 6,
  },
  rental: {
    label: 'Rental',
    type: 'text',
    mandatory: true,
    width: 6,
  },
  term: {
    label: 'Term',
    type: 'number',
    mandatory: true,
    min: 1,
    max: 60,
  },
  orderType: {
    label: 'Order Type',
    type: 'select',
    mandatory: true,
  },
  linkedProducts: {
    label: 'Linked Products',
    type: 'select-multi',
    width: 12,
    mandatory: data => data.orderType && data.orderType !== ProductEnums.orderType.NEW_ITEM,
    readOnly: (key, field, data)=> data.orderType && data.orderType === ProductEnums.orderType.NEW_ITEM,
  },
  description: {
    label: 'Description',
    type: 'textarea',
    width: 12,
    rows: 16
  }

}

export default NewOrderProductRequestForm