export const ADDJTISUB = 'ADDJTISUB';
export const SAVEJTISUBS = 'SAVEJTISUBS';
export const DELJTISUB = 'DELJTISUB';
export const DELALLJTISUBS = 'DELALLJTISUBS';
export const RESETJTIDATA = 'RESETJTIDATA';

export const JTISTATUS = 'JTISTATUS';
export const JTIINGRESS = 'JTIINGRESS';
export const JTIEGRESS = 'JTIEGRESS';
export const JTIINGRESSERRORS = 'JTIINGRESSERRORS';
export const JTIEGRESSERRORS = 'JTIEGRESSERRORS';

export function stopAllJTI() {
  return (dispatch) => {
    dispatch({
      type: DELALLJTISUBS
    });
  };
}

export function addJTISubs(payload) {
  return (dispatch) => {
    dispatch({
      type: ADDJTISUB,
      payload
    });
  };
}

export function saveJTISubs(payload) {
  return (dispatch) => {
    dispatch({
      type: SAVEJTISUBS,
      payload
    });
  };
}

export function delJTISubs(payload) {
  return (dispatch) => {
    dispatch({
      type: DELJTISUB,
      payload
    });
  };
}

export function resetJTIData() {
  return (dispatch) => {
    dispatch({
      type: RESETJTIDATA
    });
  };
}
