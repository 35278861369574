import { apiRequest, netApiRequest } from '../Api/ApiRequest';

export function submitCdrRun(month, year, platform) {
  const url = '/jobs';
  return netApiRequest({
    url,
    method: 'put',
    data: {
      job: platform,
      params: [month, year]
    }
    }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function downloadCdrCsv(ref) {
  return apiRequest({
    url: '/billing/downloadcdr/' + ref,
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
