import { apiRequest } from '../Api/ApiRequest';

export function api_getNewLineItems() {
  return apiRequest({
    url: '/billing/lineitems/new',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_downloadNewLineItems() {
  return apiRequest({
    url: '/billing/lineitems/new/download',
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
