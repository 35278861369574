import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const LogicalServiceService = {
  list: (ServiceTypeName, include, search) => {
    return apiRequest({
      url: `/sdb/services/${ServiceTypeName}${queryStringFromFields({ include, search })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  get: (ServiceTypeName, id, include = []) => {
    return apiRequest({
      url: `/sdb/services/${ServiceTypeName}/${id}${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  create: (ServiceTypeName, data, include = []) => {
    return apiRequest({
      url: `/sdb/services/${ServiceTypeName}${queryStringFromFields({ include })}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  update: (ServiceTypeName, id, data, include = []) => {
    return apiRequest({
      url: `/sdb/services/${ServiceTypeName}/${id}${queryStringFromFields({ include })}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  delete: (ServiceTypeName, id) => {
    return apiRequest({
      url: `/sdb/services/${ServiceTypeName}/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}
