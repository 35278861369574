import HsoBar from '../../components/HsoBar/HsoBar';
import AzureLogo from '../../Microsoft_Azure.svg';
import { useMsal } from "@azure/msal-react";
import { Card, CardBody, CardGroup, Col, Row } from 'reactstrap';
import {FunctionComponent} from "react";
import {IPublicClientApplication} from "@azure/msal-browser";

function handleLogin(instance: IPublicClientApplication) {
  instance.loginRedirect().catch(e => {
    console.error(e);
  });
}

const SignInButton = () => {
  const { instance } = useMsal();
  return <span className="hoverPointer"><img src={AzureLogo} onClick={() => handleLogin(instance)}/></span>
}

const Login: FunctionComponent = () => {
  return (
    <div className="app flex-row align-items-center animated fadeIn" style={{ backgroundColor: '#333F48' }}>
      <div className="container">
        <Row className="justify-content-center">
          <Col lg={8}>
            <HsoBar/>
              <CardGroup>
                <Card className={'py-5'} style={{ borderTopLeftRadius: 0 }}>
                  <CardBody>
                    <div className="card-block text-center">
                      <SignInButton/>
                      <p>Log in with Azure by clicking above</p>
                    </div>
                  </CardBody>
                </Card>
                <Card className={'py-5'} style={{ borderTopRightRadius: 0 }}>
                  <CardBody>
                    <div className={' text-center'}>
                      <h2>hSo Admin</h2>
                      <i className="swg swg-darthvader-5 swg-6x" style={{
                        marginTop: '20px',
                        marginBottom: '20px'
                      }}/>
                      <h4>Do. Or do not. There is no try.</h4>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Login;
