import { Alert, Button } from 'reactstrap';
import React from 'react';
import { ButtonIcon } from '../ButtonIcon';

const UnsavedChangesAlert = ({
  save,
  withButton
}) => {
  return (
    <Alert className='ml-auto mt-0 mb-0 px-2 py-1' color={'warning'}>
      <span className='font-xs' >You have unsaved changes!
        {withButton &&
          <Button size={'sm'} color="secondary" onClick={save}>
            <i className={'fa fa-save'}/>
          </Button>
        }
      </span>
    </Alert>
  )
}

export default UnsavedChangesAlert
