import { DomainService } from '../../../utils/ServiceDB/Ipam/Domain';
import { defaultErrorFeedback } from '../../feedback';

export const IPAM_DOMAIN_SET_DATA = 'IPAM_DOMAIN_SET_DATA';
export const IPAM_DOMAIN_SET_FIELD = 'IPAM_DOMAIN_SET_FIELD';
export const IPAM_DOMAIN_RESET_DATA = 'IPAM_DOMAIN_RESET_DATA';
export const IPAM_DOMAIN_ADD_BLOCK = 'DOMAIN_ADD_BLOCK';
export const IPAM_DOMAIN_UPDATE_BLOCK = 'DOMAIN_UPDATE_BLOCK';
export const IPAM_DOMAIN_REMOVE_BLOCK = 'DOMAIN_REMOVE_BLOCK';

export function getDomain(id, include = []) {
  return (dispatch) => {
    return DomainService.get(id, include).then((result) => {
      if (result.status === 200) {
        dispatch(setDomainData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createDomain(args, include = []) {
  return (dispatch) => {
    return DomainService.create(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setDomainData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function updateDomain(id, args, include = []) {
  return (dispatch) => {
    return DomainService.update(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setDomainData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function deleteDomain(id) {
  return (dispatch) => {
    return DomainService.delete(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function addNewDomainBlock(blockData) {
  return {
    type: IPAM_DOMAIN_ADD_BLOCK,
    blockData
  };
}

export function updateDomainBlock(blockData) {
  return {
    type: IPAM_DOMAIN_UPDATE_BLOCK,
    blockData
  };
}

export function removeDomainBlock(blockId) {
  return {
    type: IPAM_DOMAIN_REMOVE_BLOCK,
    blockId
  };
}

function setDomainData(data) {
  return {
    type: IPAM_DOMAIN_SET_DATA,
    data
  };
}

export function setDomainField(field, value) {
  return {
    type: IPAM_DOMAIN_SET_FIELD,
    field,
    value
  };
}

export function resetDomain() {
  return {
    type: IPAM_DOMAIN_RESET_DATA
  };
}
