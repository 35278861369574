import { api_getLineItem } from '../utils/ServiceDelivery/WIP';
import { defaultErrorFeedback, successFeedback } from './feedback';
import { api_updateLineItem } from '../utils/LineItem/LineItemService';

export const LINE_ITEM_SET_DATA = 'LINE_ITEM_SET_DATA';
export const LINE_ITEM_SET_FORM_DATA = 'LINE_ITEM_SET_FORM_DATA';
export const LINE_ITEM_UPDATE_FORM_FIELD = 'LINE_ITEM_UPDATE_FORM_FIELD';
export const LINE_ITEM_RESET_DATA = 'LINE_ITEM_RESET_DATA';

export function setLineItem(data) {
  return {
    type: LINE_ITEM_SET_DATA,
    lineItem: data
  };
}

export function setLineItemFormData(data) {
  return {
    type: LINE_ITEM_SET_FORM_DATA,
    lineItemForm: data
  };
}

export function getLineItem(id) {
  return (dispatch) => {
    return api_getLineItem(id)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch(setLineItem(result.data));
            dispatch(setLineItemFormData(result.data));
          } else {
            dispatch(defaultErrorFeedback());
          }
        }
      );
  };
}
export function updateLineItemFormField(field, value) {
  return {
    type: LINE_ITEM_UPDATE_FORM_FIELD,
    field,
    value
  };
}

export function updateLineItem(id, data) {
  return (dispatch) => {
    return api_updateLineItem(id, data)
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: LINE_ITEM_SET_DATA,
            lineItem: data
          });
          dispatch(successFeedback('Line item updated!'));
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };
}

export function resetLineItem() {
  return {
    type: LINE_ITEM_RESET_DATA
  };
}
