import { defaultErrorFeedback, errorFeedback } from './feedback';
import {
  api_createPricingType,
  api_getPricingType,
  api_getPricingTypes,
  api_updatePricingType
} from '../utils/PricingTypes';

export const PRICING_TYPES_SET_LIST = 'PRICING_TYPES_SET_LIST';
export const PRICING_TYPES_SET_VALIDATION_ERRORS = 'PRICING_TYPES_SET_VALIDATION_ERRORS';
export const PRICING_TYPES_SET_SEARCH_STRING = 'PRICING_TYPES_SET_SEARCH_STRING';
export const PRICING_TYPES_ADD_TYPE_TO_LIST = 'PRICING_TYPES_ADD_TYPE_TO_LIST';
export const PRICING_TYPES_REPLACE_TYPE_IN_LIST = 'PRICING_TYPES_REPLACE_TYPE_IN_LIST';
export const PRICING_TYPES_SET_SELECTED_TYPE = 'PRICING_TYPES_SET_SELECTED_TYPE';
export const PRICING_TYPES_UPDATE_SELECTED_TYPE = 'PRICING_TYPES_UPDATE_SELECTED_TYPE';
export const PRICING_TYPES_UPDATE_SELECTED_TYPE_DATA = 'PRICING_TYPES_UPDATE_SELECTED_TYPE_DATA';

function setPricingList(list){
  return {
    type: PRICING_TYPES_SET_LIST,
    list
  }
}

export function setPricingTypeValidationErrors(errors){
  return {
    type: PRICING_TYPES_SET_VALIDATION_ERRORS,
    errors
  }
}

function addPricingTypeToList(pricingType){
  return {
    type: PRICING_TYPES_ADD_TYPE_TO_LIST,
    pricingType
  }
}

function replacePricingTypeInList(pricingType){
  return {
    type: PRICING_TYPES_REPLACE_TYPE_IN_LIST,
    pricingType
  }
}


export function setPricingTypesSearchString(search) {
  return {
    type: PRICING_TYPES_SET_SEARCH_STRING,
    search
  };
}

export function setSelectedPricingType(selected){
  return {
    type: PRICING_TYPES_SET_SELECTED_TYPE,
    selected
  };
}

export function updateSelectedPricingType(field){
  return {
    type: PRICING_TYPES_UPDATE_SELECTED_TYPE,
    field
  };
}

export function updateSelectedPricingTypeData(field){
  return {
    type: PRICING_TYPES_UPDATE_SELECTED_TYPE_DATA,
    field
  };
}


export function getAllPricingTypes(){
  return (dispatch) => {
    return api_getPricingTypes().then(
      (result) => {
        if(result.status === 200){
          dispatch(setPricingList(result.data))
        }else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}

export function createPricingType(data){
  return (dispatch) => {
    return api_createPricingType(data).then(
      (result) => {
        if(result.status === 200){
          dispatch(addPricingTypeToList(result.data))
          return result.data
        }else if (result.status === 422){
          dispatch(setPricingTypeValidationErrors(Object.values(result.data)));
        }else{
          dispatch(defaultErrorFeedback());
        }
        return false
      }
    )
  }
}

export function updatePricingType(id, data){
  return (dispatch) => {
    return api_updatePricingType(id, data).then(
      (result) => {
        if(result.status === 200){
          dispatch(replacePricingTypeInList(result.data))
          return result.data
        }else if (result.status === 422){
          dispatch(setPricingTypeValidationErrors(Object.values(result.data)));
        }else{
          dispatch(defaultErrorFeedback());
        }
        return false
      }
    )
  }
}


export function getPricingType(id){
  return (dispatch) => {
    return api_getPricingType(id).then(
      (result) => {
        if(result.status === 200){
          dispatch(replacePricingTypeInList(result.data))
          dispatch(setSelectedPricingType(result.data))
        }else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}
