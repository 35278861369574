import numeral from 'numeral';
import moment from 'moment';
import { Modal, ModalBody, Row } from 'reactstrap';
import React, { useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import OrderProduct from '../../../views/OrderProduct';
import HeadlessModal from '../../Modals/HeadlessModal';
import isEmpty from 'lodash.isempty';
import { connect } from 'react-redux';

const LinkedProductsTable = ({
  products,
  optionSets,
}) => {

  const columns = [
    {
      header: 'Product',
      accessorKey: 'productNumber',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 200,
    },
    {
      header: 'Site',
      accessorKey: 'site',
      minSize: 200,
      cell: props => !isEmpty(props.getValue()) ? props.getValue().name : ''
    },
    {
      header: 'Lifecycle Stage',
      accessorKey: 'lifecycleStage',
      cell: props => {
        return optionSets.lifecycleStage.options.find(type => type.value === props.getValue())?.label ?? ''
      }
    },
    {
      header: 'Order Type',
      accessorKey: 'orderType',
      cell: props => {
        return optionSets.orderType.options.find(type => type.value === props.getValue())?.label ?? ''
      }
    },
    {
      header: 'Go Live Date',
      accessorKey: 'goLiveDate',
      cell: props => props.getValue() ? (moment(props.getValue()).format('DD/MM/YYYY')) : '',
      maxSize: 100
    }
  ]

  return (
    <>
      <FilteredTableV2
        size={'sm'}
        minRows={4}
        columns={columns}
        data={products}
        noDataText={'No Linked Products'}
        loading={false}
        defaultPageSize={4}
      />
    </>

  )
}
const mapStateToProps = ({
  helpers,
}) => ({
  optionSets: helpers.optionSets.product
})

export default connect(mapStateToProps)(LinkedProductsTable)
