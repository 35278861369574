import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import Forms from './forms';
import numeral from 'numeral';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';

import LoadingOverlay from '../../components/LoadingOverlay';
import {
  getPricingToolQuote,
  resetPricingToolQuote,
  setPricingToolQuote, updatePricingToolQuoteField,
  updatePricingToolQuoteSitePreferredPrice, updateQuote, updateQuotePricePreferred
} from '../../actions/pricingToolQuote';
import generateFormFields from '../../helpers/FormFieldGenerator';
import { CarrierPrices } from '../../components/Tables/CarrierPrices';
import { updatedDiff } from 'deep-object-diff';
import { warningFeedback } from '../../actions/feedback';
import RePrice from './rePrice';
import { checkPermission } from '../../utils/Auth/AuthService';
import QuoteRequest from './QuoteRequest';
import { ButtonIcon } from '../../components/ButtonIcon';
import { api_downloadCompareV2QuoteFeasibility } from '../../utils/PricingTool';
import fileDownload from 'js-file-download';
import { Alert } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import { addBreadcrumbs, resetBreadcrumbs } from '../../actions/breadcrumbs';
import { useParams } from 'react-router-dom'

const PricingToolQuote = ({
  id = null,
  quote,
  user,
  users
}) => {

  // router
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [showRerun, setShowRerun] = useState(false);
  const [showReprice, setShowReprice] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const pricingToolQuoteId = useMemo(() => id || params.id, [id, params.id])

  useEffect(() => {
    setShowRerun(false);
    setShowReprice(false);
    getData();
    return () => {
      dispatch(resetPricingToolQuote());
      dispatch(resetBreadcrumbs());
    };
  }, [pricingToolQuoteId]);

  useEffect(() => {
    dispatch(addBreadcrumbs([{name: quote.data?.id}]))
  }, [quote.data?.id])
  const getData = () => {
    setLoading(true);
    dispatch(getPricingToolQuote(pricingToolQuoteId, ['sites.carrier_prices', 'sites.prices.nni', 'p2p.prices', 'p2p.carrier_prices']))
      .then((result) => {
        if (result) {
          dispatch(setPricingToolQuote(result));
        }
        setLoading(false);
      });
  };

  const setPreferredPrice = (siteId, priceId) => {
    const site = resolveSites()
      .find(site => site.id === siteId);
    const preferredPrice = getSitePreferredPrice(site);
    if (preferredPrice.id !== priceId) {
      dispatch(updatePricingToolQuoteSitePreferredPrice(siteId, priceId));

    }

  };
  const getCarrierPrices = (siteId) => {
    const quoteSite = resolveSites()
      .find(site => site.id === siteId);
    return quoteSite.carrier_prices.map((carrierPrice) => {
      const price = quoteSite.prices.find(price => carrierPrice.carrier.id === price.carrier.id) || {};
      return {
        id: price.id || carrierPrice.id,
        carrier: carrierPrice.carrier.name,
        nni: price.nni ? price.nni.name : '',
        install: price.install,
        installCost: carrierPrice.install,
        rental: price.rental,
        rentalCost: carrierPrice.rental,
        tcv: price.tcv,
        preferred: price.preferred,
        status: carrierPrice.status,
        ref: carrierPrice.reference,
        statusMessage: carrierPrice.status === 'FAILED' ? carrierPrice.meta?.msg : '',
        meta: carrierPrice.meta
      };

    });

  };

  const getSitePreferredPrice = (site) => {
    return site?.prices.find(price => price.preferred === true);
  };

  const hasSitePreferredPrice = () => {
    const preferredSites = quote.original.sites?.filter(site => getSitePreferredPrice(site))
    return preferredSites?.length === quote.original.sites?.length;
  };

  const save = () => {
    const toSave = updatedDiff(quote.original, quote.data);
    if (isEmpty(toSave)) {
      dispatch(warningFeedback('nothing to update'));
      return;
    }
    const arrayKey = quote.data.sites.length ? 'sites' : 'p2p';
    if (toSave.assigned_to) {
      setLoading(true);
      dispatch(updateQuote(quote.data.id, { assigned_to: toSave.assigned_to }))
        .then(() => {
          setLoading(false);
        });
    }
    if (toSave[arrayKey]) {
      setLoading(true);
      Object.keys(toSave[arrayKey])
        .forEach((key, index) => {
          Object.keys(toSave[arrayKey][key].prices)
            .forEach((priceKey) => {
              const price = quote.data[arrayKey][key].prices[priceKey];
              dispatch(updateQuotePricePreferred(price.id, price.preferred))
                .then(result => {
                  if (Object.keys(toSave[arrayKey]).length === (index + 1)) {
                    setLoading(false);
                  }
                });
            });
        });
    }
  };

  const rerun = () => {
    setShowRerun(true);
  };
  const rePrice = () => {
    setShowReprice(true);
  };

  const downloadFeasibility = () => {
    setDownloading(true);
    api_downloadCompareV2QuoteFeasibility(pricingToolQuoteId)
      .then((result) => {
        if (result) {
          if (result.status === 200) {
            let splitContentDisposition = result.headers['content-disposition'].split('=');
            fileDownload(result.data, splitContentDisposition[1]);
          }
          setDownloading(false);
        }
      });
  };

  const getSelectOptions = (field) => {
    if (field === 'assigned_to') {
      return users.map((user) => {
        return {
          label: user.name,
          value: user.id
        };
      });
    }
  };

  const getSelectedOption = (field) => {
    if (field === 'assigned_to') {
      return users.find((user) => user.username && user.username.toLowerCase() === quote.data.assigned_to) || null;
    }
  };
  const handleSelectInput = (field, selected) => {
    if (field === 'assigned_to') {
      const user = users.find(user => user.name === selected.label);
      dispatch(updatePricingToolQuoteField({ [field]: user.username.toLowerCase() }));
    }
  };
  const resolveSites = () => {
    return quote.data.p2p.concat(quote.data.sites);
  };
  const resolveSiteForm = (site) => {
    const form = site.solution === 'p2p' ? { ...Forms.p2pSite } : { ...Forms.site };
    if (displayWidget()) {
      form.widget = {
        label: 'Sole Occupier',
        type: 'text',
        plaintext: true,
        width: 4,
        callBack: () => quote.data.widget?.soleOccupier ? 'YES' : 'NO'
      };
    }
    return form;
  };
  const resolveSiteOrCircuit = (site) => {
    return site.solution === 'p2p' ? 'Circuit' : 'Site';
  };

  const displayWidget = () => {
    return quote.data.created_by === 'widget';
  }


  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        {quote.notFound ?
          <Alert color={'warning'}>Could not find quote <span className="font-weight-bold">{pricingToolQuoteId}</span>.</Alert>
          :
          <>
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader className={'d-flex justify-content-between'}>
                    <CardTitle className={'mb-0'}>{quote.data.id}</CardTitle>
                    <div className={'mb-0 d-flex'}>
                      <CardTitle className={'ml-2 mb-0'}>Install: <span
                        className={'text-danger'}>£{numeral(quote.data.install)
                        .format('0,0.00')}</span></CardTitle>
                      <CardTitle className={'ml-2 mb-0'}>Rental: <span
                        className={'text-danger'}>£{numeral(quote.data.rental)
                        .format('0,0.00')}</span></CardTitle>
                      <CardTitle className={'ml-2 mb-0'}>Kit Install: <span
                        className={'text-danger'}>£{numeral(quote.data.kitInstall)
                        .format('0,0.00')}</span></CardTitle>
                      <CardTitle className={'ml-2 mb-0'}>Kit Rental: <span
                        className={'text-danger'}>£{numeral(quote.data.kitRental)
                        .format('0,0.00')}</span></CardTitle>
                    </div>
                    <div className={'mb-0 d-flex'}>
                      {checkPermission('PreSales', user.permissions) &&
                      <>
                        <ButtonIcon onClick={downloadFeasibility} icon={'fa fa-download'} tooltip={'feasibility'}
                                    loading={downloading}/>
                        <ButtonIcon onClick={rePrice} icon={'fa fa-adjust'} tooltip={'re-price'} disabled={!hasSitePreferredPrice()}/>
                      </>
                      }
                      <ButtonIcon onClick={rerun} icon={'fa fa-clone'} tooltip={'re-run'}/>
                      <ButtonIcon onClick={save} icon={'fa fa-save'} tooltip={'save'}/>
                      <ButtonIcon onClick={getData} icon={'fa fa-refresh'} tooltip={'refresh'}/>
                    </div>

                  </CardHeader>
                  <CardBody>
                    <Row form>
                      {generateFormFields({
                        fields: Forms.info,
                        handleInput: () => {},
                        data: quote.data,
                        getSelectOptions,
                        getSelectedOption,
                        handleSelectInput
                      })}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {displayWidget() &&
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader className={'d-flex justify-content-between'}>
                    <CardTitle className={'mb-0'}>
                      Network Information
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row form>
                      {generateFormFields({
                        fields: Forms.network,
                        handleInput: () => {
                        },
                        data: quote.data.network,
                        getSelectOptions,
                        getSelectedOption,
                        handleSelectInput
                      })}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            }

            <Row>
              <Col>
                {resolveSites()
                  .map((site, index) => {
                    const isDoubleKit = site.solution === 'p2p' || site.technology?.name === 'RO2';
                    const preferredPrice = getSitePreferredPrice(site);
                    return (
                      <Card key={site.id}>
                        <CardHeader className={'d-flex justify-content-between'}>
                          <CardTitle className={'mb-0'}>{resolveSiteOrCircuit(site)} {`#${index + 1}`} {<Badge className={'ml-2'} color={'info'}>{site.solution}</Badge>}</CardTitle>
                          <div className={'mb-0 d-flex'}>
                            <CardTitle className={'ml-2 mb-0'}>Carrier: <span
                              className={'text-danger'}>{preferredPrice ? preferredPrice.carrier.name : ''}</span></CardTitle>
                            <CardTitle className={'ml-2 mb-0'}>Install: <span
                              className={'text-danger'}>£{preferredPrice ? numeral(preferredPrice.install)
                              .format('0,0.00') : 0}</span></CardTitle>
                            <CardTitle className={'ml-2 mb-0'}>Rental: <span
                              className={'text-danger'}>£{preferredPrice ? numeral(preferredPrice.rental)
                              .format('0,0.00') : 0}</span></CardTitle>
                            <CardTitle className={'ml-2 mb-0'}>Kit Install: <span
                              className={'text-danger'}>£{preferredPrice ? numeral(isDoubleKit ? preferredPrice.kitInstall * 2 : preferredPrice.kitInstall)
                              .format('0,0.00') : 0}</span></CardTitle>
                            <CardTitle className={'ml-2 mb-0'}>Kit Rental: <span
                              className={'text-danger'}>£{preferredPrice ? numeral(isDoubleKit ? preferredPrice.kitRental * 2 : preferredPrice.kitRental)
                              .format('0,0.00') : 0}</span></CardTitle>
                            {checkPermission('PreSales', user.permissions) &&
                            <CardTitle className={'ml-2 mb-0'}>Kit Cost: <span
                              className={'text-danger'}>£{preferredPrice ? numeral(isDoubleKit ? site.kit.cost * 2 : site.kit.cost)
                              .format('0,0.00') : 0}</span></CardTitle>

                            }
                          </div>

                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={4}>
                              <Row form>
                                {generateFormFields({
                                  fields: resolveSiteForm(site),
                                  handleInput: () => {},
                                  data: site
                                })}
                              </Row>
                            </Col>
                            <Col md={8}>
                              <Card color={'light'}>
                                <CardBody>
                                  <Row>
                                    <Col>
                                      <CarrierPrices
                                        data={getCarrierPrices(site.id)}
                                        user={user}
                                        site={site}
                                        setPreferred={(priceId) => setPreferredPrice(site.id, priceId)}
                                      />
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    );
                  })}
              </Col>
            </Row>
          </>
        }

      </LoadingOverlay>
      <Modal
        isOpen={showRerun}
        backdrop={'static'}
        keyboard={false}
        size="xlg"
      >
        <ModalHeader toggle={() => setShowRerun(!showRerun)}>Pricing Tool Quote Re-Run</ModalHeader>
        <ModalBody>
          <QuoteRequest rerun/>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showReprice}
        backdrop={'static'}
        keyboard={false}
        size='xlg'
      >
        <ModalHeader toggle={() => setShowReprice(!showReprice)}>Pricing Tool Quote Re-Price
          - {quote.data.id}</ModalHeader>
        <ModalBody>
          <RePrice/>
        </ModalBody>
      </Modal>
    </div>
  );
};

function mapStateToProps({
  pricingToolQuote,
  authenticationState,
  helpers
}) {
  return {
    quote: pricingToolQuote,
    user: authenticationState.account,
    users: helpers.systemUsers
  };
}

export default connect(mapStateToProps)(PricingToolQuote);
