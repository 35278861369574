import { defaultErrorFeedback } from '../../feedback';
import { SubnetService } from '../../../utils/ServiceDB/Ipam/Subnet';
import { BlockService } from '../../../utils/ServiceDB/Ipam/Block';

export const IPAM_SUBNET_SET_DATA = 'IPAM_SUBNET_SET_DATA';
export const IPAM_SUBNET_SET_FIELD = 'IPAM_SUBNET_SET_FIELD';
export const IPAM_SUBNET_RESET_DATA = 'IPAM_SUBNET_RESET_DATA';
export const IPAM_SUBNET_ADD_ADDRESS = 'IPAM_SUBNET_ADD_ADDRESS';
export const IPAM_SUBNET_UPDATE_ADDRESS = 'IPAM_SUBNET_UPDATE_ADDRESS';
export const IPAM_SUBNET_REMOVE_ADDRESS = 'IPAM_SUBNET_REMOVE_ADDRESS';
export const IPAM_SUBNET_ADD_CHILD = 'IPAM_SUBNET_ADD_CHILD';
export const IPAM_SUBNET_UPDATE_CHILD = 'IPAM_SUBNET_UPDATE_CHILD';
export const IPAM_SUBNET_REMOVE_CHILD = 'IPAM_SUBNET_REMOVE_CHILD';

export function getSubnet(id, include = []) {
  return (dispatch) => {
    return SubnetService.get(id, include).then((result) => {
      if (result.status === 200) {
        dispatch(setSubnetData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createSubnet(args, include = []) {
  return (dispatch) => {
    return SubnetService.create(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setSubnetData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function updateSubnet(id, args, include = []) {
  return (dispatch) => {
    return SubnetService.update(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setSubnetData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function deleteSubnet(id) {
  return (dispatch) => {
    return SubnetService.delete(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function addNewSubnetChild(subnetData) {
  return {
    type: IPAM_SUBNET_ADD_CHILD,
    subnetData
  };
}

export function updateSubnetChild(subnetData) {
  return {
    type: IPAM_SUBNET_UPDATE_CHILD,
    subnetData
  };
}

export function removeSubnetChild(subnetId) {
  return {
    type: IPAM_SUBNET_REMOVE_CHILD,
    subnetId
  };
}

export function addNewSubnetAddress(addressData) {
  return {
    type: IPAM_SUBNET_ADD_ADDRESS,
    addressData
  };
}

export function updateSubnetAddress(addressData) {
  return {
    type: IPAM_SUBNET_UPDATE_ADDRESS,
    addressData
  };
}

export function removeSubnetAddress(addressId) {
  return {
    type: IPAM_SUBNET_REMOVE_ADDRESS,
    addressId
  };
}

function setSubnetData(data) {
  return {
    type: IPAM_SUBNET_SET_DATA,
    data
  };
}

export function setSubnetField(field, value) {
  return {
    type: IPAM_SUBNET_SET_FIELD,
    field,
    value
  };
}

export function resetSubnet() {
  return {
    type: IPAM_SUBNET_RESET_DATA
  };
}

export function getNextNetworkAddressForParentSubnet(subnetId, mask) {
  return (dispatch) => {
    return SubnetService.getNextNetworkAddress(subnetId, mask).then((result) => {
      if (result.status === 200) {
        dispatch(setSubnetField('networkAddress', result.data.result));
      }
      return result;
    });
  };
}

export function getNextNetworkAddressForBlock(blockId, mask) {
  return (dispatch) => {
    return BlockService.getNextNetworkAddress(blockId, mask).then((result) => {
      if (result.status === 200) {
        dispatch(setSubnetField('networkAddress', result.data.result));
      }
      return result;
    });
  };
}
