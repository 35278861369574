import React from 'react';
import Select , {components} from 'react-select';

export const SelectMod = (props) => {
  const SingleValue = (selectProps) => {
    return (
      <div onClick={(e) => props.handleClick(e, selectProps)} className='hoverPointer'>
        <components.SingleValue {...selectProps}/>
      </div>
    );
  };
  const newStyles = {
    control: (styles, state) => {
      const control = {
        ...styles,
        height: props.size === 'sm' ? '29px' : '35px',
        backgroundColor: state.isDisabled ? '#e4e6eb' : 'white',
        minHeight: props.size === 'sm' ? '29px' : '35px',
        color:'black',
        borderColor: '#e4e6eb',
        overflow: 'hidden'
      };
      if(props.isMulti){
        delete control.height
      }
      return control
    },
    placeholder: (styles) => {
      return {
        ...styles,
        color: '#9DA0A7'
      };
    },
    valueContainer: (styles, state) => ({
      ...styles,
      padding: props.size === 'sm' ? '0 8px' : '2px 8px',
      backgroundColor: state.isDisabled ? '#e4e6eb' : 'white'
    }),
    clearIndicator: (styles) => ({
      ...styles,
      padding: props.size === 'sm' ? '0 8px' : '2px 8px'
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: props.size === 'sm' ? '0 8px' : '2px 8px'
    }),
    loadingIndicator: (styles) => ({
      ...styles,
      padding: props.size === 'sm' ? '0 8px' : '2px 8px'
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 3 // Without this menu will be overlapped by other fields
    }),
    container: base => ({
      ...base,
      flex: 1,

    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#6BA4B8' : isFocused ? "#e4e6eb" : undefined
      };
    }
  };
  return <Select
    styles={props.hideClearable? {...newStyles, ...props.hideClearable} : newStyles}
    components={props.handleClick ? {SingleValue} : {}}
    {...props}
  />;
};
