import { Badge, Button } from 'reactstrap';
import React, { useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { Link, useNavigate } from 'react-router-dom'
import AddConnectionButton from '../../../views/ServiceDB/DeviceDeployment/AddConnectionButton';
import HeadlessModal from '../../Modals/HeadlessModal';
import Port from '../../../views/ServiceDB/Port';

const PortsTable = ({
  ports,
  deviceDeployment,
  minRows,
  onUpdated,
  onCreated,
  onDeleted
}) => {

  const navigate = useNavigate();

  const [selectedPort, setSelectedPort] = useState(null);
  const [portModalOpen, setPortModalOpen] = useState(false);

  const togglePortModal = () => {
    setPortModalOpen(prevState => !prevState)
  }

  const isWiresOnly = (port) =>{
    return port.connectionType && !['site', 'nni', 'deviceDeployment'].includes(port.connectionToType)
  }
  const getToConnection = (port) => {
    switch (port.connectionType) {
      case 'nni':
        return <Link to={`/sdb/nnis/${port.connectionId}`}>{port.connectionName}</Link>;
      case 'connection':
        return <Link to={'/sdb/connections/' + port.connectionId}>{port.connectionName}</Link>;
    }
  };

  const getToDevice = (port) => {
    if (port.connectionType) {
      switch (port.connectionToType) {
        case 'site':
          return <Link to={`/sdb/nnis/${port.connectionId}`}>{port.connectionToName}</Link>;
        case 'nni':
          return <>
            <Link to={`/sdb/nnis/${port.connectionToId}`}>{port.connectionToName}</Link> <Badge
            color={'primary'}>NNI</Badge>
          </>;
        case 'deviceDeployment':
          return <Link to={`/sdb/device-deployments/${port.connectionToId}`}>
            {port.connectionToName}
          </Link>;
        default:
          return <span>Wires Only</span>;
      }
    }
    return <AddConnectionButton port={port}
                                onSubmit={(type) => {
                                  navigate(`/sdb/connections/new?type=port&side=${type}&id=${port.id}`)
                                }}/>;
  };

  const tableFilter = (row, columnId, value) => {
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.connectionType)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.connectionName)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      (!isWiresOnly(row.original) && String(row.original.connectionToName)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1) ||
      (isWiresOnly(row.original) && String('Wires Only')
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1)
    )
  }

  const columns = [
    {
      header: 'Port',
      accessorKey: 'name'
    },
    {
      header: 'Type',
      id: 'type',
      accessorFn: port => port.connectionType === 'nni' ? 'NNI' : port.connectionType ? 'Connected' : undefined,
      cell : props => (
        <Badge
          color={!props.getValue() ? 'secondary' : props.getValue() === 'NNI' ? 'primary' : 'success'}>{props.getValue() ?? 'Disconnected'}</Badge>
      ),
      sortUndefined: 'last'
    },
    {
      header: 'Name',
      accessorKey: 'connectionName',
      cell: props => <span onClick={e => e.stopPropagation()}>{getToConnection(props.row.original) ?? ''}</span>,
      sortUndefined: 'last'
    },
    {
      header: 'To',
      id: 'connectionToId',
      cell: props => <span onClick={e => e.stopPropagation()}>{getToDevice(props.row.original)}</span>,
      sortUndefined: 'last'
    }
  ]

  return (
    <>
      <FilteredTableV2
        multiSort
        withFilter={tableFilter}
        minRows={minRows ?? 10}
        columns={columns}
        data={ports ?? []}
        noDataText="No Ports"
        showPagination={ports?.length > (minRows ?? 10)}
        defaultPageSize={minRows ?? 10}
        defaultSorted={[{
          id: 'type',
          desc: true
        }, {
          id: 'name',
          desc: false
        }]}
        onRowClick={port => {
          togglePortModal()
          setSelectedPort(port.id)
        }}
        extraButtons={[
          <Button size={'sm'} color={'secondary'} onClick={() => {
            setSelectedPort('new')
            togglePortModal()
          }}>New Port</Button>
        ]}
      />
      <HeadlessModal
        open={portModalOpen}
        toggle={togglePortModal}
        size={'lg'}
      >
        <Port
          id={selectedPort}
          deviceDeployment={deviceDeployment}
          onCreated={onCreated}
          onUpdated={onUpdated}
          onDeleted={(id) => {
            setPortModalOpen(false);
            if (onDeleted) {
              onDeleted(id);
            }
          }}
          closeModal={togglePortModal}
        />
      </HeadlessModal>
    </>

  )

}

export default PortsTable
