import {
  CASES_ADD_CASE,
  CASES_SET_CASES,
  CASES_SET_SEARCH_STRING,
  CASES_UPDATE_CASE
} from '../actions/cases';
import moment from 'moment';

const initialState = {
  list: [],
  searchString: '',
  filteredCases: []
};

const resolveState = (state) => {
  switch (state) {
    case 0:
      return 'Active';
    case 1:
      return 'Inactive';
    case 2:
      return 'Canceled';
    default:
      return state;
  }
};

export default function cases(state = initialState, action) {
  switch (action.type) {
    case CASES_SET_CASES:
      return {
        ...state,
        list: action.cases
      };
    case CASES_ADD_CASE:
      return {
        ...state,
        list: state.cases.concat(action.theCase)
      };
    case CASES_UPDATE_CASE:
      return {
        ...state,
        list: state.cases.map(theCase => theCase.caseId === action.theCase.caseId ? action.theCase : theCase)
      };
    case CASES_SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.search,
        filteredCases: state.cases.filter((theCase) => {
          const altCreatedDate = moment(theCase.createdOn).format('DD/MM/YYYY');
          const altModifiedDate = moment(theCase.modifiedOn).format('DD/MM/YYYY');
          const statusOption = action.optionSets.status.options.find(({ value }) => value === theCase.status);
          const status = Number.isInteger(theCase.status) && statusOption ? statusOption.label : theCase.status;
          const caseState = resolveState(theCase.state);
          return String(theCase.caseId).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
            || String(theCase.title).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
            || String(status).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
            || String(caseState).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
            || String(altCreatedDate).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
            || String(altModifiedDate).toLowerCase().indexOf(action.search.toLowerCase()) !== -1;
        })
      };
    default:
      return state;
  }
}
