import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {
    Row, Col, Card, Nav, NavItem, NavLink, TabContent, TabPane, Button
} from "reactstrap";
import {configEntry, configTemplate} from "../../utils/ConfigTemplate";
import {SpinnerInfo} from "../../components/Spinner/Spinner";
import downloader from "js-file-download";
import Select from "react-select";
import moment from "moment";
import TabGenerate from "./TabGenerate";
import LinkedTemplateCard from './LinkedTemplates';
import TabTemplate from "./TabTemplate";
import {SET_ACTIVE_ENTRY} from "../../actions/configTemplate";
import Tags from "./Tags";
import ReactTooltip from "react-tooltip";
import {ScaleLoader} from "react-spinners";
import SchemaCard from "./Schema";
import GenerateTable from "./GenerateTable";
import Notes from "./Notes";
import {useNavigate, useParams} from 'react-router-dom'

const customStyles = {
    container: provided => ({
        ...provided,
        cursor: 'pointer',
        zIndex: '5'
    }),
    control: base => ({
        ...base,
        minHeight: 30,
        cursor: 'pointer'
    }),
    option: base => ({
        ...base,
        cursor: 'pointer',
        zIndex: 5,
    })
};

const ConfigTemplate = () => {
    const [loading, setLoading] = useState(true)
    const [templateLoading, setTemplateLoading] = useState(true);
    const [template, setTemplate] = useState([]);
    const [activeTab, setActiveTab] = useState('template');
    const [activeTemplateIndex, setActiveTemplateIndex] = useState(0);
    const [activeData, setActiveData] = useState(null);

    const navigate = useNavigate();
    const {entryName} = useParams();
    const dispatch = useDispatch();
    const {
        activeEntry,
        generated,
        expandCodeWindow,
        savingTemplate
    } = useSelector(state => state.configTemplate);

    useEffect(() => {
        getActiveEntry(entryName);
    }, []);

    useEffect(() => {
        setTemplateLoading(true);
        getTemplates();
        dispatch({type: "GENERATED", result: ""});
    }, [activeEntry.id]);

    useEffect(() => {
        if (activeTab !== "template") {
            setActiveTab("template")
        }
    }, []);

    const findTemplateIndex = needle => {
        return template.findIndex(e => e['date_modified'] === needle['date_modified']);
    }

    const formatLabels = data => {
        const index = findTemplateIndex(data);
        return `(Revision ${index}): ${moment(data['date_modified']).fromNow()}`
    }

    // Toggle active tab
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const selectOption = obj => {
        setActiveTemplateIndex(findTemplateIndex(obj));
        setActiveData(template[findTemplateIndex(obj)]['template'])
    }

    const downloadFile = () => {
        if (activeTab === 'template') {
            if (template.length > 0) {
                downloader(template[0]['template'], activeEntry.id + '-template.txt');
            }
        } else if (activeTab === 'generate') {
            downloader(generated['data'], activeEntry.id + '-generated.txt');
        }
    }

    const getActiveEntry = (entryName) => {
        configEntry(entryName).then(result => {
            if (result.status === 200) {
                dispatch({type: SET_ACTIVE_ENTRY, data: result.data})
            }
        });
        setLoading(false);
    }

    const getTemplates = () => {
        configTemplate(entryName).then(result => {
            if (result.status === 200) {
                setTemplateLoading(false);
                setTemplate(result.data['template']);

                if (result.data['template'].length > 0) {
                    setActiveData(result.data['template'][activeTemplateIndex]['template'])
                }
            }
        });
    }
    if (loading) {
        return <SpinnerInfo/>
    }
    return (
        <div className="animate fadeIn">
            <Row className='mb-2'>
                <Col><h4>{activeEntry.description}</h4></Col>
                <Col className='d-flex justify-content-end'><Button color="primary" className="m-1"
                                                                    onClick={() => navigate(-1)}>
                    <i className="icon-puzzle"/> Templates
                </Button>
                </Col>
            </Row>
            <Row>
                <Col ld={expandCodeWindow ? 12 : 8} md={expandCodeWindow ? 12 : 8} sm={12} className='h-100'>
                    <Card className='card-accent-primary p-2'>
                        <Row>
                            <Col>
                                <Tags/>
                                <hr/>
                            </Col>
                            {savingTemplate ? <ScaleLoader color="red"/> : null}
                            <Col md={6}>
                                <Select
                                    options={template}
                                    styles={customStyles}
                                    value={template[activeTemplateIndex]}
                                    formatOptionLabel={formatLabels}
                                    getOptionValue={obj => findTemplateIndex(obj)}
                                    onChange={obj => selectOption(obj)}
                                />
                                <Button size='sm' color='primary' onClick={downloadFile} className='m-1 float-right'>
                                    <i className="fa fa-download"/> Download
                                </Button>
                                <ReactTooltip
                                    id="expandCode"
                                    type="dark"
                                    place="right"
                                    effect="float"
                                    multiline={false}
                                >Expand Window
                                </ReactTooltip>
                            </Col>
                        </Row>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={activeTab === 'template'
                                        ? {cursor: 'pointer', fontWeight: 'bolder'}
                                        : {cursor: 'pointer'}
                                    }
                                    onClick={() => {
                                        toggle('template');
                                    }}
                                > Template
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={activeTab === 'generate'
                                        ? {cursor: 'pointer', fontWeight: 'bolder'}
                                        : {cursor: 'pointer'}
                                    }
                                    onClick={() => {
                                        toggle('generate');
                                    }}
                                > Generate
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className='p-0 h-100'>
                            <TabPane tabId="template">
                                <Row>
                                    <Col sm="12">
                                        <TabTemplate
                                            templateLoading={templateLoading}
                                            activeData={activeData}
                                            setActiveData={setActiveData}
                                            setTemplate={setTemplate}
                                            getActiveEntry={getActiveEntry}
                                            template={template}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="generate">
                                <Row>
                                    <Col sm="12">
                                        <TabGenerate id={activeEntry.id}
                                                     dispatch={dispatch}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Card>
                </Col>
                {!expandCodeWindow
                    ?
                    <>
                        <Col lg={4} md={4} sm={12}>
                            <LinkedTemplateCard getActiveEntry={getActiveEntry}
                                                data={activeEntry}
                                                setData={activeEntry}
                                                dispatch={dispatch}
                            />
                            {
                                activeTab === "template" ? <SchemaCard dispatch={dispatch}/>
                                    : <>
                                        <SchemaCard dispatch={dispatch}/>
                                        <GenerateTable dispatch={dispatch}/>
                                    </>
                            }
                            {
                                activeTab === "template" ? <Notes dispatch={dispatch}/> : null
                            }
                        </Col>
                    </>
                    : null
                }
            </Row>
        </div>
    )
}

export default ConfigTemplate;
