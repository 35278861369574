import React, { Fragment } from 'react';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupButton, Label } from 'reactstrap';

export const typeMap = {
  NS: [{ id: 'name', name: 'Hostname' }, { id: 'content', name: 'Nameserver' }],
  MX: [{ id: 'name', name: 'Hostname' }, { id: 'content', name: 'Handled By' }, { id: 'prio', name: 'Preference/Priority' }],
  A: [{ id: 'name', name: 'Hostname' }, { id: 'content', name: 'IP Address' }],
  AAAA: [{ id: 'name', name: 'Hostname' }, { id: 'content', name: 'IP Address' }],
  CNAME: [{ id: 'name', name: 'Hostname' }, { id: 'content', name: 'Hostname (to map)' }],
  TXT: [{ id: 'name', name: 'Hostname' }, { id: 'content', name: 'Data' }],
  SRV: [{ id: 'name', name: 'Hostname' }, { id: 'prio', name: 'Preference/Priority' }, { id: 'content', name: 'Data' }],
  PTR: [{ id: 'content', name: 'Hostname' }, { id: 'name', name: 'IP Address' }]
};

const NewRecord = ({ type, data, cancel, updateInput, create, name }) => {
  let rows = typeMap[type];
  let errors = data.errors ? data.errors : {};

  return (
        <Fragment>
            {rows.map((row, index) => {
              const error = errors[type] ? errors[type][row.id] : null;
              return (
                    <FormGroup row key={index}>
                        <Col md="3">
                            <Label
                                className="text-primary">{row.name}</Label>
                        </Col>
                        <Col xs="12" md="9">
                            {type === 'PTR' && row.id === 'name'
                                && <Fragment>
                                    <InputGroup>
                                        <Input onChange={updateInput} name={row.id} type="text" className={error ? 'is-invalid' : ''} value={data[type][row.id] ? data[type][row.id] : ''}/>
                                        <InputGroupAddon addonType={'append'}><Button color="secondary" className="text-dark" type={'submit'}>{name}</Button></InputGroupAddon>
                                    </InputGroup>
                                    <div className="text-danger">
                                        {error}
                                    </div>
                                </Fragment>

                            }

                            {(type !== 'PTR' || row.id !== 'name')
                                && <Input onChange={updateInput} name={row.id} type="text" className={error ? 'is-invalid' : ''} value={data[type][row.id] ? data[type][row.id] : ''}/>
                            }
                            {error
                                && <div className="invalid-feedback">
                                    {error}
                                </div>
                            }
                        </Col>
                    </FormGroup>
              );
            })
            }
            <div className="clearfix mt-3 mb-3">
                <Button className="float-left" outline={true} color="warning" onClick={cancel}>Cancel</Button>
                <Button className="float-right" outline={true} color="danger" onClick={create}>Create</Button>
            </div>
        </Fragment>

  );
};

export default NewRecord;
