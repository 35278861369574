import {
  OPPORTUNITIES_ADD_OPPORTUNITY,
  OPPORTUNITIES_REMOVE_OPPORTUNITY,
  OPPORTUNITIES_REPLACE_OPPORTUNITY,
  OPPORTUNITIES_SET_LIST,
  OPPORTUNITIES_TOGGLE_MODAL
} from '../actions/opportunities';

const initialState = {
  list: [],
  showModal: false
}
export function opportunities(state = initialState, action){
  switch (action.type) {
    case OPPORTUNITIES_SET_LIST:
      return {
        ...state,
        list: [...action.data]
      };
    case OPPORTUNITIES_REPLACE_OPPORTUNITY:
      return {
        ...state,
        list: state.list.map(opp => {
          if (opp.id === action.opportunity.id) {
            return action.opportunity;
          }
          return opp;
        })
      };
    case OPPORTUNITIES_TOGGLE_MODAL:
      return {
        ...state,
        showModal: !state.showModal
      }
    case OPPORTUNITIES_REMOVE_OPPORTUNITY:
      return {
        ...state,
        list: state.list.filter((opportunity) => opportunity.id !== action.id),
      }
    case OPPORTUNITIES_ADD_OPPORTUNITY:
      return {
        ...state,
        list: [...state.list, action.opportunity]
      }
    default:
      return state;
  }

}
