import React, { useEffect, useState } from 'react'
import { Badge, Card, CardBody } from 'reactstrap'
import { getStockItems } from '../../../actions/stock'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import isEmpty from 'lodash.isempty'
import LoadingOverlay from '../../../components/LoadingOverlay';
import StockItemsTable from '../../../components/Tables/StockItems';

const Stock = ({ stock, optionSets }) => {

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    dispatch(getStockItems({ includes: ['deviceModel', 'reservedFor'].join(','), withs: ['deviceModel', 'reservedFor'].join(';') }))
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  const columns = [
    {
      filterable: true,
      sortable: true,
      Header: 'ID',
      accessor: 'id',
      width: 100
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Asset Tag',
      accessor: 'assetTag'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Serial',
      accessor: 'serialNumber'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Model',
      id: 'deviceModel',
      accessor: (row) => `${row.deviceModel?.vendor?.name} ${row.deviceModel.name}`,
      Cell: ({ original, value }) => (
        <Link to={`/sdb/device-models/${original.deviceModel?.id}`}>{value}</Link>
      )
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Owner',
      accessor: 'owner',
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => {
            const value = event.target.value;
            return onChange(value)}
          }
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">Show All</option>
          {optionSets.owner?.options.map(optionSet => <option value={optionSet.value}>{optionSet.label}</option>)}
        </select>,
      Cell: props => {
        if (Number.isInteger(props.value) && optionSets) {
          const option = optionSets.owner.options.find(optionSet => optionSet.value === props.value);
          if (option) {
            return option.label;
          }
        }
        return props.value;
      }
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Available',
      accessor: 'available',
      filterMethod: (filter, row) => {
        return filter.value === (row.available);
      },
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => {
            const value = event.target.value;
            return onChange(value === 'true' ? true : value === 'false' ? false : '')}
          }
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">Show All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>,
      Cell: ({ original }) => (
        <Badge className={`p-2 faded-${(original.available ? 'success' : 'danger')}`}
               style={{ minWidth: '40px' }}>
          {original.available ? 'Yes' : 'No'}
        </Badge>
      )
    },
    {
      Header: 'Reserved',
      filterable: true,
      sortable: true,
      accessor: 'reservedFor',
      width: 200,
      filterMethod: (filter, row) => {
        return filter.value === !isEmpty(row.reservedFor);
      },
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => {
            const value = event.target.value;
            return onChange(value === 'true' ? true : value === 'false' ? false : '')}
          }
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">Show All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>,
      Cell: ({ original }) => (
        <Badge className={`p-2 faded-${(!isEmpty(original.reservedFor) ? 'success' : 'danger')}`}
               style={{ minWidth: '40px' }}>
          {!isEmpty(original.reservedFor) ? 'Yes' : 'No'}
        </Badge>
      )
    },
    {
      Header: 'Deployed',
      filterable: true,
      sortable: true,
      accessor: 'deployed',
      width: 200,
      filterMethod: (filter, row) => {
        return filter.value === row.deployed;
      },
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => {
            const value = event.target.value;
            return onChange(value === 'true' ? true : value === 'false' ? false : '')}
        }
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">Show All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>,
      Cell: ({ original }) => (
        <Badge className={`p-2 faded-${(original.deployed ? 'success' : 'danger')}`}
               style={{ minWidth: '40px' }}>
          {original.deployed ? 'Yes' : 'No'}
        </Badge>
      )
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Active Licenses',
      accessor: 'activeLicensesCount',
      width: 200,
      filterMethod: (filter, row) => {
        return filter.value === (row.activeLicensesCount > 0);
      },
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => {
            const value = event.target.value;
            return onChange(value === 'true' ? true : value === 'false' ? false : '')}
          }
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">Show All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>,
      Cell: ({ original }) => (
        <Badge className={`p-2 faded-${(original.activeLicensesCount > 0 ? 'success' : 'danger')}`}
               style={{ minWidth: '40px' }}>
          {original.activeLicensesCount > 0 ? 'Yes' : 'No'}
        </Badge>
      )
    }
  ];
  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <StockItemsTable
              stockItems={stock}
              fetchData={fetch}
              optionSets={optionSets}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>

    </div>
  )
};

function mapStateToProps({ stock, authenticationState, helpers }) {
  return {
    permissions: authenticationState.account.permissions,
    optionSets: helpers.optionSets.stockItem,
    stock: stock.stock
  };
}

export default connect(mapStateToProps)(Stock);
