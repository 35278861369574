import { defaultErrorFeedback } from './feedback';
import { VendorService } from '../utils/ServiceDB/Vendor';

export const VENDORS_SET_VENDORS = 'VENDORS_SET_VENDORS';
export const VENDORS_ADD_VENDOR = 'VENDORS_ADD_VENDOR';
export const VENDORS_UPDATE_VENDOR = 'VENDORS_UPDATE_VENDOR';

function setVendors(vendors) {
  return {
    type: VENDORS_SET_VENDORS,
    data: vendors
  };
}

export function addVendorToVendors(data) {
  return {
    type: VENDORS_ADD_VENDOR,
    data: data
  };
}

export function updateVendorToVendors(data) {
  return {
    type: VENDORS_UPDATE_VENDOR,
    data: data
  };
}

export function getVendors() {
  return (dispatch) => {
    return VendorService.getVendors().then((result) => {
      if (result.status === 200) {
        dispatch(setVendors(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}