import {
  PRICE_LIST_ADD_ITEM,
  PRICE_LIST_CLONE,
  PRICE_LIST_REMOVE_ITEM, PRICE_LIST_REPLACE_ITEM,
  PRICE_LIST_RESET_DATA,
  PRICE_LIST_SET_DATA,
  PRICE_LIST_SET_FIELD_VALUE, PRICE_LIST_SET_ORIGINAL_FIELD_VALUE,
  PRICE_LIST_SET_SEARCH_STRING,
  PRICE_LIST_UPDATE_ITEM_FIELD
} from '../actions/priceList';
import cloneDeep from 'lodash.clonedeep';

const initialState = {
  form: {
    items: [],
    status: {}
  },
  original: {
    items: [],
    status: {}
  },
  filteredItems: [],
  searchString: ''
};

export function priceList(state = initialState, action) {
  let items;
  switch (action.type) {
    case PRICE_LIST_SET_DATA:
      return {
        ...state,
        form: {...state.form, ...action.data},
        original: {...state.original, ...action.data},
      }
    case PRICE_LIST_RESET_DATA:
      return {
        ...initialState
      }
    case PRICE_LIST_CLONE:
      return {
        ...initialState,
        form: {
          name: state.form.name,
          type: state.form.type,
          company: state.form.company,
          currency: state.form.currency,
          items: cloneDeep(state.form.items)
        }
      }
    case PRICE_LIST_SET_FIELD_VALUE:
      return {
        ...state,
        form: {...state.form, ...action.field}
      }
    case PRICE_LIST_SET_ORIGINAL_FIELD_VALUE:
      return {
        ...state,
        original: {...state.form, ...action.field}
      }
    case PRICE_LIST_SET_SEARCH_STRING:
      const searchString = action.search !== undefined ? action.search : state.searchString;
      const filteredItems = state.form.items.filter((item) => {
        return (String(item.template.name)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1 ||
          String(item.template.productCode)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1 ||
          String(item.template.productGroup.name)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1 ||
          String(item.template.pricingType.name)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
        );
      });
      return {
        ...state,
        filteredItems,
        searchString
      };
    case PRICE_LIST_ADD_ITEM:
      return {
        ...state,
        form: {...state.form, items: [...state.form.items, action.item]}
      }
    case PRICE_LIST_UPDATE_ITEM_FIELD:
      items = state.form.items.map(item => {
        if(item.id === action.id){
          return {...item, ...action.field}
        }
        return item
      })
      return {
        ...state,
        form: {...state.form, items: items}
      }
    case PRICE_LIST_REMOVE_ITEM:
      items = state.form.items.filter(item => item.id !== action.id)
      return {
        ...state,
        form: {...state.form, items: items},
        original: {...state.original, items: action.fromOriginal ? items : state.original.items}
      }
    case PRICE_LIST_REPLACE_ITEM:
      items = state.form.items.map(item => {
        if(action.oldItem && item.id === action.oldItem.id){
          return action.item
        }
        if(item.id === action.item.id){
          return action.item
        }
        return item
      })
      return {
        ...state,
        form: {...state.form, items},
        original: {...state.original, items: items}
      }
    default:
      return state
  }

}
