import BillTemplateService from '../utils/BillTemplate';
import { defaultErrorFeedback } from './feedback';

export const BILL_TEMPLATE_SET_DATA = 'BILL_TEMPLATE_SET_DATA'
export const BILL_TEMPLATE_SET_FORM_FIELD = 'BILL_TEMPLATE_SET_FORM_FIELD'
export const BILL_TEMPLATE_RESET = 'BILL_TEMPLATE_RESET'
export const BILL_TEMPLATE_ADD_GROUP = 'BILL_TEMPLATE_ADD_GROUP'
export const BILL_TEMPLATE_REPLACE_GROUP = 'BILL_TEMPLATE_REPLACE_GROUP'


export const updateBillTemplateFormField = (field) => {
  return {
    type: BILL_TEMPLATE_SET_FORM_FIELD,
    field
  }
}

export const resetBillTemplate = () => {
  return {
    type: BILL_TEMPLATE_RESET
  }
}

export const addGroupToBillTemplate = (group) => {
  return {
    type: BILL_TEMPLATE_ADD_GROUP,
    group
  }
}

export const replaceBillTemplateGroup = (group) => {
  return {
    type: BILL_TEMPLATE_REPLACE_GROUP,
    group
  }
}


export function getBillTemplate(id) {
  return (dispatch) =>{
    return BillTemplateService.get(id).then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATE_SET_DATA, data: result.data});
      }else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

export function updateBillTemplate(id, data) {
  return (dispatch) =>{
    return BillTemplateService.update(id, data).then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATE_SET_DATA, data: result.data});
        return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      }else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

export function createBillTemplate(data) {
  return (dispatch) =>{
    return BillTemplateService.create(data).then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATE_SET_DATA, data: result.data});
        return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      }else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

export function deactivateBillTemplate(id) {
  return (dispatch) =>{
    return BillTemplateService.deactivate(id).then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATE_SET_DATA, data: result.data});
        return result.data
      } else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

export function activateBillTemplate(id) {
  return (dispatch) => {
    return BillTemplateService.activate(id).then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATE_SET_DATA, data: result.data});
        return result.data
      } else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

