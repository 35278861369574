import { NNIService } from '../utils/ServiceDB/NNI';
import { defaultErrorFeedback } from './feedback';

export const NNI_SET_DATA = 'NNI_SET_DATA';
export const NNI_SET_FIELD = 'NNI_SET_FIELD';
export const NNI_RESET_DATA = 'NNI_RESET_DATA';

export function getNNI(id, include = []) {
  return (dispatch) => {
    return NNIService.getNNI(id, include).then((result) => {
      if (result.status === 200) {
        dispatch(setNNIData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createNNI(args, include = []) {
  return (dispatch) => {
    return NNIService.createNNI(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setNNIData(result.data));
        return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      } else {
        dispatch(defaultErrorFeedback());
      }
      return null;
    });
  };
}

export function updateNNI(id, args, include = []) {
  return (dispatch) => {
    return NNIService.updateNNI(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setNNIData(result.data));
        return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      } else {
        dispatch(defaultErrorFeedback());
      }
      return null;
    });
  };
}

export function deleteNNI(id) {
  return (dispatch) => {
    return NNIService.deleteNNI(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

function setNNIData(data) {
  return {
    type: NNI_SET_DATA,
    data
  };
}

export function updateNNIField(field, value) {
  return {
    type: NNI_SET_FIELD,
    field,
    value
  };
}

export function resetNNI() {
  return {
    type: NNI_RESET_DATA
  };
}
