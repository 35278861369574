import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import React, { useState } from 'react';

const AddComponentButton = ({ onSubmit }) => {

  const [addConnectionButtonOpen, setAddConnectionButtonOpen] = useState(false);

  const componentTypeOptions = [
    { value: 'patchLead', label: 'Patch Lead' },
    { value: 'carrierCircuit', label: 'Carrier Circuit' },
    { value: 'crossConnect', label: 'Cross Connect' },
    { value: 'wavelength', label: 'Wavelength (Optical System)' }
  ];

  return (
    <>
      <ButtonDropdown
        isOpen={addConnectionButtonOpen}
        toggle={() => setAddConnectionButtonOpen(prevState => !prevState)}
        direction="left"
        className="ml-2"
      >
        <DropdownToggle size="sm" className="rounded" color={'primary'}>
          New Component
        </DropdownToggle>
        <DropdownMenu>
          {componentTypeOptions.map((componentType) =>
            <DropdownItem
              key={`drop-down-item-${componentType.value}`}
              onClick={() => onSubmit(componentType.value)}
            >
              {componentType.label}
            </DropdownItem>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
};

export default AddComponentButton;