import { defaultErrorFeedback, successFeedback } from './feedback';
import AccountService from '../utils/Account';
import { SignatureService } from '../utils/Signatures/SignatureService';
import fileDownload from 'js-file-download';
import AuditService from "../utils/Audit";

export const ACCOUNT_SET_INFO_DATA = 'ACCOUNT_SET_INFO_DATA'
export const ACCOUNT_SET_INFO_FIELD = 'ACCOUNT_SET_INFO_FIELD'
export const ACCOUNT_SET_CONTACTS = 'ACCOUNT_SET_CONTACTS'
export const ACCOUNT_RESET = 'ACCOUNT_RESET'
export const ACCOUNT_SET_SITES = 'ACCOUNT_SET_SITES'
export const ACCOUNT_SET_PARTNERSHIPS = 'ACCOUNT_SET_PARTNERSHIPS'
export const ACCOUNT_SET_ORDERS = 'ACCOUNT_SET_ORDERS'
export const ACCOUNT_ADD_ORDER = 'ACCOUNT_ADD_ORDER'
export const ACCOUNT_SET_SERVICES = 'ACCOUNT_SET_SERVICES'
export const ACCOUNT_SET_BILLS = 'ACCOUNT_SET_BILLS'
export const ACCOUNT_SET_CREDIT_REQUESTS = 'ACCOUNT_SET_CREDIT_REQUESTS'
export const ACCOUNT_ADD_CREDIT_REQUEST = 'ACCOUNT_ADD_CREDIT_REQUEST'
export const ACCOUNT_UPDATE_CREDIT_REQUEST = 'ACCOUNT_UPDATE_CREDIT_REQUEST'
export const ACCOUNT_SET_OPPORTUNITIES = 'ACCOUNT_SET_OPPORTUNITIES'
export const ACCOUNT_SET_CASES = 'ACCOUNT_SET_CASES'
export const ACCOUNT_SET_CONFIG_ITEMS = 'ACCOUNT_SET_CONFIG_ITEMS'
export const ACCOUNT_SET_CIRCUITS = 'ACCOUNT_SET_CIRCUITS'
export const ACCOUNT_SET_DOCUMENTS = 'ACCOUNT_SET_DOCUMENTS'
export const ACCOUNT_SET_SIGNATURES = 'ACCOUNT_SET_SIGNATURES'
export const ACCOUNT_SET_LOGS = 'ACCOUNT_SET_LOGS'
export const ACCOUNT_UPDATE_CONTACT = 'ACCOUNT_UPDATE_CONTACT';
export const ACCOUNT_ADD_CONTACT = 'ACCOUNT_ADD_CONTACT';
export const ACCOUNT_ADD_DOCUMENT = 'ACCOUNT_ADD_DOCUMENT';
export const ACCOUNT_UPDATE_DOCUMENT = 'ACCOUNT_UPDATE_DOCUMENT';
export const ACCOUNT_REMOVE_DOCUMENT = 'ACCOUNT_REMOVE_DOCUMENT';
export const ACCOUNT_ADD_SITE = 'ACCOUNT_ADD_SITE';
export const ACCOUNT_UPDATE_SITE = 'ACCOUNT_UPDATE_SITE';
export const ACCOUNT_ADD_PARTNERSHIP = 'ACCOUNT_ADD_PARTNERSHIP';
export const ACCOUNT_REMOVE_PARTNERSHIP = 'ACCOUNT_REMOVE_PARTNERSHIP';
export const ACCOUNT_SET_AUDIT_HISTORY = 'ACCOUNT_SET_AUDIT_HISTORY';


export const updateAccountInfoField = (field) => {
  return {
    type: ACCOUNT_SET_INFO_FIELD,
    field
  }
}

export const setAccountContacts = (contacts) => {
  return {
    type: ACCOUNT_SET_CONTACTS,
    contacts
  }
}

const setAccountData = (data) => {
  return {
    type: ACCOUNT_SET_INFO_DATA,
    data
  }
}


export const setAccountSites = (sites) => {
  return {
    type: ACCOUNT_SET_SITES,
    sites
  }
}

export const setAccountPartnerships = (partnerships) => {
  return {
    type: ACCOUNT_SET_PARTNERSHIPS,
    partnerships
  }
}

export const setAccountOrders = (orders) => {
  return {
    type: ACCOUNT_SET_ORDERS,
    orders
  }
}

export const setAccountServices = (services) => {
  return {
    type: ACCOUNT_SET_SERVICES,
    services
  }
}

export const setAccountBills = (bills) => {
  return {
    type: ACCOUNT_SET_BILLS,
    bills
  }
}

export const setAccountCreditRequests = (creditRequests) => {
  return {
    type: ACCOUNT_SET_CREDIT_REQUESTS,
    creditRequests
  }
}

export const addAccountCreditRequest = (creditRequest) => {
  return {
    type: ACCOUNT_ADD_CREDIT_REQUEST,
    creditRequest
  }
}

export const updateAccountCreditRequest = (creditRequest) => {
  return {
    type: ACCOUNT_UPDATE_CREDIT_REQUEST,
    creditRequest
  }
}
export const setAccountOpportunities = (opportunities) => {
  return {
    type: ACCOUNT_SET_OPPORTUNITIES,
    opportunities
  }
}

export const setAccountCases = (cases) => {
  return {
    type: ACCOUNT_SET_CASES,
    cases
  }
}

export const setAccountConfigItems = (configItems) => {
  return {
    type: ACCOUNT_SET_CONFIG_ITEMS,
    configItems
  }
}

export const setAccountCircuits = (circuits) => {
  return {
    type: ACCOUNT_SET_CIRCUITS,
    circuits
  }
}

export const setAccountDocuments = (documents) => {
  return {
    type: ACCOUNT_SET_DOCUMENTS,
    documents
  }
}

export const setAccountLogs = (logs) => {
  return {
    type: ACCOUNT_SET_LOGS,
    logs
  }
}
export const setAccountSignatures = (signatures) => {
  return {
    type: ACCOUNT_SET_SIGNATURES,
    signatures
  }
}
export function resetAccount() {
  return {
    type: ACCOUNT_RESET
  };
}

export function addAccountContact(contact) {
  return {
    type: ACCOUNT_ADD_CONTACT,
    contact
  };
}

export function updateAccountContact(contact) {
  return {
    type: ACCOUNT_UPDATE_CONTACT,
    contact
  };
}

export function updateAccountDocument(document) {
  return {
    type: ACCOUNT_UPDATE_DOCUMENT,
    document
  };
}

export function addAccountDocument(document) {
  return {
    type: ACCOUNT_ADD_DOCUMENT,
    document
  };
}

export function removeAccountDocument(documentId) {
  return {
    type: ACCOUNT_REMOVE_DOCUMENT,
    documentId
  };
}

export function addAccountSite(site) {
  return {
    type: ACCOUNT_ADD_SITE,
    site
  };
}

export function updateAccountSite(site) {
  return {
    type: ACCOUNT_UPDATE_SITE,
    site
  };
}

export function addAccountPartnership(partnership){
  return {
    type: ACCOUNT_ADD_PARTNERSHIP,
    partnership
  };
}

export function removeAccountPartnership(customerId){
  return {
    type: ACCOUNT_REMOVE_PARTNERSHIP,
    customerId
  };
}

export function addAccountOrder(order){
  return {
    type: ACCOUNT_ADD_ORDER,
    order
  }
}
export const getAccountInfo = (idOrNumber, includes) => {
  return (dispatch) => {
    return AccountService.get(idOrNumber, includes).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setAccountData(result.data));
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export const getAccountContacts = (idOrNumber, include = []) => {
  return (dispatch) => {
    return AccountService.contacts.list(idOrNumber, include).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export const getAccountOrders = (idOrNumber) => {
  return (dispatch) => {
    return AccountService.orders.list(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export const getAccountServices = (idOrNumber) => {
  return (dispatch) => {
    return AccountService.services.list(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export const getAccountOpportunities = (idOrNumber) => {
  return (dispatch) => {
    return AccountService.opportunities.list(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}
export const getAccountConfigItems = (idOrNumber) => {
  return (dispatch) => {
    return AccountService.configItems.list(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export const getAccountCircuits = (idOrNumber) => {
  return (dispatch) => {
    return AccountService.circuits.list(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export const getAccountDocuments = (idOrNumber) => {
  return (dispatch) => {
    return AccountService.documents.list(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export const deleteAccountDocument = (idOrNumber, documentId) => {
  return (dispatch) => {
    return AccountService.documents.delete(idOrNumber, documentId).then(
      (result) => {
        if (result.status === 204) {
          return true;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      }
    );
  };
}

export const getAccountSignatures = (idOrNumber, page, size, searchString, sorting) => {
  return (dispatch) => {
    return AccountService.signatures.list(idOrNumber, page, size, searchString, sorting).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}
export const getAccountLogs = (idOrNumber) => {
  return (dispatch) => {
    return AccountService.logs.list(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export const getAccountPartnerships = (idOrNumber, includes) => {
  return (dispatch) => {
    return AccountService.partnerships.list(idOrNumber, includes).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export const getAccountCreditRequests = (idOrNumber) => {
  return (dispatch) => {
    return AccountService.creditRequests.list(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export const createAccount = (data) => {
  return (dispatch) => {
    return AccountService.create(data).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setAccountData(result.data));
          return result.data
        } else if (result.status === 422) {
          return { errors: result.data};
        } else {
          dispatch(defaultErrorFeedback());
        }
      }
    );
  };
}

export const updateAccount = (idOrNumber, data) => {
  return (dispatch) => {
    return AccountService.update(idOrNumber, data).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setAccountData(result.data));
        } else if (result.status === 422) {
          return { errors: result.data};
        } else {
          dispatch(defaultErrorFeedback());
        }
      }
    );
  };
}

export function getAccountSites(accountIdOrNumber, include = []) {
  return (dispatch) => {
    return AccountService.sites.list(accountIdOrNumber, include).then(
      (result) => {
        if (result.status === 200) {
          return result.data
        } else {
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    );
  };
}

export function getAccountBills(accountIdOrNumber) {
  return (dispatch) => {
    return AccountService.bills.list(accountIdOrNumber).then(
      (result) => {
        if (result.status === 200) {
          return result.data
        } else {
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    );
  };
}

export function getAccountCases(accountIdOrNumber) {
  return (dispatch) => {
    return AccountService.cases.list(accountIdOrNumber).then(
      (result) => {
        if (result.status === 200) {
          return result.data
        } else {
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    );
  };
}

export function inviteToPortal(accountId, contactId) {
  return (dispatch) => {
    return AccountService.invite(accountId, contactId).then(
      (result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Invite sent!'))
          return result.data
        }else if(result.status === 422){
          return {errors: result.data};
        }
        else {
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    );
  };
}

export function downloadCurrentSignatureDocument(signatureId) {
  return () => {
    return SignatureService.downloadCurrentSignatureDocument(signatureId)
      .then((result) => {
        if (result.status === 200) {
          let splitContentDisposition = result.headers['content-disposition'].split('=');
          fileDownload(result.data, splitContentDisposition[1]);
        }
      });
  };
}

export function downloadFinalSignatureDocument(signatureId) {
  return () => {
    return SignatureService.downloadFinalSignatureDocument(signatureId)
      .then((result) => {
        if (result.status === 200) {
          let splitContentDisposition = result.headers['content-disposition'].split('=');
          fileDownload(result.data, splitContentDisposition[1]);
        }
      });
  };
}

export function dropAccountCache(accountId) {
  return (dispatch) => {
    return AccountService.cache(accountId).then(
      (result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Account caching started...'));
          return true;
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    );
  };
}

export const createAccountOrder = (accountId, data) => {
  return (dispatch) => {
    return AccountService.orders.create(accountId, data).then(
      (result) => {
        if (result.status === 200) {
          return result.data
        } else {
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    );
  };
}

export function getAccountAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('accounts', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: ACCOUNT_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}


