import { OPTICAL_SYSTEM_RESET_DATA, OPTICAL_SYSTEM_SET_DATA, OPTICAL_SYSTEM_SET_FIELD } from '../actions/opticalSystem';

const initialState = {
  data: {
    wavelengths: []
  },
  original: {
    wavelengths: []
  }
};
const opticalSystem = (state = initialState, action) => {
  switch (action.type) {
    case OPTICAL_SYSTEM_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case OPTICAL_SYSTEM_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case OPTICAL_SYSTEM_RESET_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default opticalSystem;