import React, { Component } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown
} from 'reactstrap'
import { MapLegend, TrafficMap } from 'react-network-diagrams-hso'
import { TimeEvent, TimeSeries } from 'pondjs'
import * as Immutable from 'immutable'
import { SpinnerInfo } from '../../components/Spinner/Spinner'
import _ from 'underscore'
import { WeathermapService } from '../../utils/Weathermaps/WeathermapService'
import WeatherMapEdge from './WeatherMapEdge'
import { addJTISubs, resetJTIData, saveJTISubs, stopAllJTI } from '../../actions/JTI'
import { connect, useDispatch } from 'react-redux'
import WeatherMapNode from './WeatherMapNode'
import moment from 'moment'
import { edgeThicknessMap, nodeLegendData, nodeSizeMap, stylesMap } from './Styles'
import { useParams } from 'react-router-dom'

class WeatherMapLegacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionType: null,
      loading: true,
      tloading: true,
      data: {},
      traffic: {},
      timer: null,
      mapName: this.props.params.mapName,
      labelMode: null
    };
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.getInfluxTraffic = this.getInfluxTraffic.bind(this);
    this.startJTI = this.startJTI.bind(this);
    this.findBEnd = this.findBEnd.bind(this);
    this.setLabelMode = this.setLabelMode.bind(this);
    const dateNow = new Date();
    this.lastUpdateDate = new Date(dateNow.getTime() - 5000);

    this.props.dispatch(resetJTIData());
  }

  handleSelectionChanged(selectionType, selection) {
    this.setState({ selectionType, selection });
  }

  bitsToSize(bits, dp = 4) {
    if (bits === undefined) { return '-'; }
    const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
    if (bits === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(Math.abs(bits)) / Math.log(1000)), 10);
    if (i === 0) return `${bits} ${sizes[i]})`;
    return `${(bits / (1000 ** i)).toFixed(dp)} ${sizes[i]}`;
  }

  shouldComponentUpdate() {
    const now = new Date();
    var seconds = (now.getTime() - this.lastUpdateDate.getTime()) / 1000;
    return seconds >= 1;
  }

  componentDidUpdate() {
    this.lastUpdateDate = new Date();
  }

  setLabelMode(mode) {
    this.setState({ labelMode: mode });
    this.setState({ labelMode: mode });
  }

  componentDidMount() {
    WeathermapService.getWeathermap(this.props.params.mapName).then((result) => {
      if (result.status === 200) {
        this.setState({ loading: false, data: result.data });
        let useinflux = false;
        let usejti = false;
        for (let i = 0; i < result.data.edges.length; i++) {
          if (result.data.edges[i].data_source === 'jti') {
            usejti = true;
          }
          if (result.data.edges[i].data_source === 'influx' || result.data.edges[i].data_source === undefined) {
            useinflux = true;
          }
          else {
            this.setState({'tloading': false});
          }
        }
        if (useinflux === true) {
          this.getInfluxTraffic(this.props.params.mapName);
          let timer = setInterval(() => this.getInfluxTraffic(this.props.params.mapName), 30000);
          this.setState({ timer });
        }
        if (usejti === true) {
          this.startJTI(result.data.edges);
        }
      }
    }).catch((error) => {
      alert(error);
    });
  }

  startJTI(edges) {
    let sublist = {};
    for (let i = 0; i < edges.length; i++) {
      if (edges[i].data_source === 'jti') {
        if (sublist[edges[i].source] === undefined) {
          sublist[edges[i].source] = [];
        }
        let pl = edges[i].source_int.split(',');
        for (let j = 0; j < pl.length; j++) {
          if (pl.length > 1 && pl[j].startsWith('ae')) {
            console.log('ignoring ' + pl[j]);
          } else {
            sublist[edges[i].source].push(pl[j]);
          }
        }
      }
    }
    let startob = {
      subs: []
    };
    for (let k in sublist) {
      startob.subs.push({
        loopback: k,
        interfaces: sublist[k]
      });
    }
    this.props.dispatch(saveJTISubs(startob));
    this.props.dispatch(addJTISubs(startob));
  }

  getInfluxTraffic(mapName) {
    WeathermapService.getWeathermapTraffic(mapName).then((result) => {
      let d = new Date();
      this.setState({ lastFetch: d.toLocaleString() });
      if (result.status === 200) {
        const dateNow = new Date();
        this.lastUpdateDate = new Date(dateNow.getTime() - 5000);
        this.setState({ tloading: false, traffic: result.data.traffic, timestamp: result.data.timestamp });
      }
    }).catch((error) => {
      alert(error);
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
    this.props.dispatch(stopAllJTI());
    this.props.dispatch(resetJTIData());
  }

  findBEnd(host, inter) {
    if (this.state.data.edges !== undefined) {
      for (let i = 0; i < this.state.data.edges.length; i++) {
        if(this.state.data.edges[i].source_int !== undefined) {
          let pl = this.state.data.edges[i].source_int.split(',');
          for (let j = 0; j < pl.length; j++) {
            if (this.state.data.edges[i].source === host && pl[j] === inter) {
              return this.state.data.edges[i].target;
            }
          }
        }
      }
    }
    return undefined;
  }

  render() {
    let edgeModeMap = {};
    let edgeTraffic = {};
    if (Object.keys(this.props.jtidata).length > 0) {
      for (let r in this.props.jtidata) {
        for (let pl in this.props.jtidata[r]) {
          pl.split(',').forEach(p => {
            let target = this.findBEnd(r, p);
            if (target !== undefined) {
              if (edgeTraffic[r + '--' + target] === undefined) {
                edgeTraffic[r + '--' + target] = 0;
              }
              if (edgeTraffic[target + '--' + r] === undefined) {
                edgeTraffic[target + '--' + r] = 0;
              }

              const eventSeries = new TimeSeries({
                name: 'raw',
                events: this.props.jtidata[r][p].egress.toArray()
              });

              let rate = eventSeries.rate({ fieldSpec: ['octets'] }).atLast();
              if (rate !== undefined) {
                let last = moment(eventSeries.end());
                let duration = moment.duration(moment(new Date()).diff(last));
                if (duration.asSeconds() > 45) {
                  edgeTraffic[r + '--' + target] = undefined;
                  edgeModeMap[r + '--' + target] = 'nodata';
                } else {
                  edgeTraffic[r + '--' + target] += rate.get('octets_rate') * 8;
                }
              }
              const ieventSeries = new TimeSeries({
                name: 'raw',
                events: this.props.jtidata[r][p].ingress.toArray()
              });
              let irate = ieventSeries.rate({ fieldSpec: ['octets'] }).atLast();
              if (irate !== undefined) {
                let last = moment(ieventSeries.end());
                let duration = moment.duration(moment(new Date()).diff(last));
                if (duration.asSeconds() > 45) {
                  edgeTraffic[target + '--' + r] = undefined;
                  edgeModeMap[target + '--' + r] = 'nodata';
                } else {
                  edgeTraffic[target + '--' + r] += irate.get('octets_rate') * 8;
                }
              }
            }
          });
        }
      }
    }
    const timestamp = 1431649302 * 1000;
    if (!this.state.tloading) {
      edgeTraffic = { ...this.state.traffic, ...edgeTraffic };
    }
    const traffic = new TimeEvent(timestamp, Immutable.Map(edgeTraffic));

    const mapSelection = {
      nodes: this.state.selectionType === 'node'
        ? [this.state.selection] : [],
      edges: this.state.selectionType === 'edge'
        ? [this.state.selection] : []
    };
    const edgeColorMap = [
      { color: '#e20200', label: '>=80%', range: [80, 100] },
      { color: '#ff7d30', label: '50 - 80%', range: [50, 80] },
      { color: '#e89c3f', label: '40 - 50%', range: [40, 50] },
      { color: '#016c59', label: '20 - 40%', range: [20, 40] },
      { color: '#238b45', label: '10 - 20%', range: [10, 20] },
      { color: '#3690c0', label: '1 - 10%', range: [1, 10] },
      { color: '#74a9cf', label: '0 - 1%', range: [0, 1] }
    ];

    const edgeTypes = _.map(edgeThicknessMap, (width, name) => {
      return {
        text: name,
        strokeWidth: width
      };
    });

    const colorSwatches = _.map(edgeColorMap, (color) => {
      return {
        text: color.label,
        stroke: color.color,
        fill: color.color
      };
    });

    const nodeTypes = _.map(nodeLegendData, (nodeInfo) => {
      return {
        text: nodeInfo.label,
        stroke: nodeInfo.color,
        fill: nodeInfo.color,
        radius: nodeInfo.radius,
        classed: nodeInfo.classed
      };
    });

    const bounds = {
      x1: 0,
      y1: 0,
      x2: 225,
      y2: 100
    };

    let nodeShapeMap = {};
    _.each(this.state.data.nodes, (node) => {
      if (node.name.match(/^to/)) {
        nodeShapeMap[node.name] = 'square';
      }
    });

    _.each(this.state.data.edges, edge => {
      if (edge.source_int === undefined) {
        edgeModeMap[edge.source + '--' + edge.target] = 'dashed';
      }
      if (this.props.lsdb.edges[edge.source + '--' + edge.target] && this.props.lsdb.edges[edge.source + '--' + edge.target].teMetric === 54321) {
        edgeModeMap[edge.source + '--' + edge.target] = 'maintenance';
      }
      if (this.props.lsdb.edges[edge.target + '--' + edge.source] && this.props.lsdb.edges[edge.target + '--' + edge.source].teMetric === 54321) {
        edgeModeMap[edge.target + '--' + edge.source] = 'maintenance';
      }
    });

    const labels = {};

    if (this.state.labelMode === 'metrics') {
      _.each(Object.keys(this.props.lsdb.edges), edge => {
        labels[edge] = this.props.lsdb.edges[edge].teMetric;
      });
    }
    if (this.state.labelMode === 'rsvp') {
      _.each(Object.keys(this.props.lsdb.edges), edge => {
        labels[edge] = this.bitsToSize(this.props.lsdb.edges[edge].maximumReservableBW - this.props.lsdb.edges[edge].unreservedBandwidth);
      });
    }

    return (
            <div className="animated fadeIn">
                {(this.state.loading || this.state.tloading)
                && <div className="row justify-content-center">
                    <SpinnerInfo/>
                </div>
                }
                {!this.state.loading && !this.state.tloading
                    && <div>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader><Row><Col>{this.state.data.name}</Col><Col>
                                    <UncontrolledButtonDropdown size="sm" style={{ float: 'right' }}>
                                        <DropdownToggle caret color="secondary">
                                            Show
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem key={'none'} style={{
                                              fontWeight: this.state.labelMode === null ? 'bold' : 'normal'
                                            }} onClick={() => this.setLabelMode(null)}>None</DropdownItem>
                                            <DropdownItem key={'metrics'} style={{
                                              fontWeight: this.state.labelMode === 'metrics' ? 'bold' : 'normal'
                                            }} onClick={() => this.setLabelMode('metrics')}>Metrics</DropdownItem>
                                            <DropdownItem key={'rspv'} style={{
                                              fontWeight: this.state.labelMode === 'rsvp' ? 'bold' : 'normal'
                                            }} onClick={() => this.setLabelMode('rsvp')}>RSVP RSV</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col></Row></CardHeader>
                                <CardBody>
                            <TrafficMap
                                        bounds={bounds}
                                        topology={this.state.data}
                                        traffic={traffic}
                                        edgeColorMap={edgeColorMap}
                                        edgeColorMode="percent"
                                        edgeDrawingMethod="bidirectionalArrow"
                                        edgeThicknessMap={edgeThicknessMap}
                                        nodeShapeMap={nodeShapeMap}
                                        nodeSizeMap={nodeSizeMap}
                                        stylesMap={stylesMap}
                                        selection={mapSelection}
                                        onSelectionChange={this.handleSelectionChanged}
                                        edgeModeMap={edgeModeMap}
                                        labels={labels}
                            />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {this.state.selectionType === 'edge'
                                    && <WeatherMapEdge
                                        mapId={this.props.params.mapName}
                                        edgeId={this.state.selection}
                                        traffic={edgeTraffic}
                                    />
                            }
                            {this.state.selectionType === 'node'
                                && <WeatherMapNode
                                    topology={this.state.data}
                                    nodeId={this.state.selection}
                                />
                            }
                            {this.state.selectionType === null
                            && <Card>
                                <CardBody>
                                    <svg width="300" height="200">
                                        <MapLegend
                                            x={10}
                                            y={10}
                                            itemsPerColumn={8}
                                            edgeTypes={edgeTypes}
                                            nodeTypes={nodeTypes}
                                            colorSwatches={colorSwatches}
                                            edgeColor={'#6D6E71'}
                                            columnWidth={95}
                                            exampleWidth={15}
                                        />
                                    </svg>
                                </CardBody>
                            </Card>
                            }
                        </Col>
                    </Row>
                    </div>
                    }
            </div>
    );
  }
}

/**
 *  For Redux 8
 */
const WeatherMap = ({jtidata, lsdb}) => {
  const dispatch = useDispatch();
  const params = useParams()
  return <WeatherMapLegacy jtidata={jtidata} lsdb={lsdb} dispatch={dispatch} params={params}/>
}

function mapStateToProps({ JTI, BGP }) {
  return {
    jtidata: JTI.data,
    lsdb: BGP
  };
}

export default connect(mapStateToProps)(WeatherMap);
