import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';
import startsWith from 'lodash.startswith';


const includes = ['linkedProducts','linkedProducts.site','notes','notes.createdBy', 'circuits', 'billTemplate', 'billTemplate.billTemplateGroups','billTemplateGroup', 'supplier', 'quoteProduct', 'order', 'order.priceList', 'order.priceList.currency', 'company', 'company.sites','company.billTemplates','company.billTemplates.billTemplateGroups','site', 'priceListItem', 'priceListItem.template', 'priceListItem.template.productGroup']
const withs = 'linkedProducts;linkedProducts.site;notes;notes.createdBy;billTemplate;billTemplate.billTemplateGroup;billTemplateGroup;supplier;quoteProduct;order;order.priceList;order.priceList.currency;company;company.sites;company.billTemplate;company.billTemplate.billTemplateGroup;site;priceListItem;priceListItem.template;priceListItem.template.productGroup'


const ProductService = {
  list: (sorted = [], search = [], include = [], searchJoin, withArr = [], activeOnly) => {
    let query = {
      sorted,
      search: search.map((value) => `${value.id}:${value.value}`).join(';'),
      include,
      searchJoin,
      activeOnly,
      with: withArr ? withArr.join(';') : undefined
    };
    return apiRequest({
      url: `/products${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  get: (productId) => {
    let query = {
      with: withs,
      include: includes
    }
    return apiRequest({
      url: `/products/${productId}${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  update: (id, data) => {
    let query = {
      with: withs,
      include: includes
    }
    return apiRequest({
      url: `/products/${id}${queryStringFromFields(query)}`,
      method: 'put',
      data
    })
      .then(
        resp => resp
      )
      .catch(
        error => error
      );
  },
  circuit: {
    create: (productId, circuitId) => {
      return apiRequest({
        url: `/products/${productId}/circuits/${circuitId}`,
        method: 'post'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    },
    delete: (productId, circuitId) => {
      return apiRequest({
        url: `/products/${productId}/circuits/${circuitId}`,
        method: 'delete'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  crossConnect: {
    create: (productId, circuitId) => {
      return apiRequest({
        url: `/products/${productId}/cross-connects/${circuitId}`,
        method: 'post'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    },
    delete: (productId, circuitId) => {
      return apiRequest({
        url: `/products/${productId}/cross-connects/${circuitId}`,
        method: 'delete'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  }
}

export function api_getProductGroups() {
  return apiRequest({
    url: '/products/groups',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
export function api_searchProducts(searchString) {
  return apiRequest({
    url: '/products/search?productCode=' + searchString,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getProductCircuits(productId){
  return apiRequest({
    url: `/products/${productId}/circuits`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getProductTemplates(productCodes, legacyOnly = true){
  let search = `?search=is_legacy:${legacyOnly ? 0 : 1}`;
  if(productCodes){
    search = `${search};product_code:${productCodes.join(',')}&searchFields=product_code:in&searchJoin:and`
  }
  return apiRequest({
    url: `/products/templates${search}&include=productSLA,productGroup,pricingType`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );

}

export function api_updateProductTemplate(id, data){
  return apiRequest({
    url: `/products/templates/${id}?include=productSLA,productGroup,pricingType`,
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );

}

export function api_getProductSLAs(){
  return apiRequest({
    url: `/products/slas`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_createProductSLA(data){
  return apiRequest({
    url: `/products/slas`,
    method: 'post',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateProductSLA(id, data){
  return apiRequest({
    url: `/products/slas/${id}`,
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateProductBatch(data){
  return apiRequest({
    url: `/products`,
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export const isCancelProduct = (productNumber) => {
  return startsWith(productNumber, 'HSO00-101')
}
export default ProductService
