export default {
  generalFields: {
    salutation: { label: 'Salutation', type: 'text', mandatory: false },
    firstName: { label: 'First Name', type: 'text', mandatory: true },
    lastName: { label: 'Last Name', type: 'text', mandatory: true },
    company: {
      label: 'Customer',
      type: 'button-link',
      getLink: (data) => data.company ? `/accounts/${data.company.accountid}` : '#',
      isDisabled: (data) => !data.company,
      callBack: (key, field, data) => data[key] !== undefined && data[key] !== null ? data[key].name : '',
    },
    email: { label: 'Email', type: 'text', mandatory: true },
    phone: { label: 'Phone', type: 'text', mandatory: true },
    jobTitle: { label: 'Job Title', type: 'text', mandatory: false },
    fax: { label: 'Fax', type: 'text' },
    addressLine1: { label: 'Address Line 1', type: 'text',  mandatory: false },
    addressLine2: { label: 'Address Line 2',  type: 'text' },
    addressLine3: { label: 'Address Line 3',  type: 'text' },
    addressCity: { label: 'City',  type: 'text',  mandatory: false },
    addressCounty: { label: 'County', type: 'text' },
    addressCountry: { label: 'Country', type: 'select', mandatory: false },
    addressPostcode: { label: 'Postcode', type: 'text', mandatory: false}
  }
};
