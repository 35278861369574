import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from 'reactstrap';
import { LOGTAIL_CLEAR_MESSAGES, LOGTAIL_CLOSE_DIALOG } from '../../actions/logtail';

class LogTailer extends Component {
  render() {
    if (this.props.display === false) {
      return false;
    }
    return (
      <div style={{
        borderRadius: '8px',
        backgroundColor: '#000',
        opacity: 0.8,
        position: 'fixed',
        maxHeight: '35%',
        bottom: '4px',
        right: '4px',
        width: '80%',
        color: '#fff',
        font: '0.8rem Inconsolata, monospace',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}>
        <div style={{ padding: '10px',
          overflow: 'auto',
          position: 'relative' }}>
          <div style={{ position: 'absolute', top: '10px', right: '12px' }}><Button size={'sm'} onClick={() => this.props.dispatch({ type: LOGTAIL_CLEAR_MESSAGES })}>clear</Button> <Button size={'sm'} onClick={() => this.props.dispatch({ type: LOGTAIL_CLOSE_DIALOG })}>close</Button></div>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {this.props.messages.reverse().map((logmsg, idx) => <li key={'ltm_' + idx} className={logmsg.msgtype === undefined ? '' : 'text-' + logmsg.msgtype}>{moment.unix(logmsg.date).format()} [{logmsg.category === undefined ? 'default' : logmsg.category}] {logmsg.msg}</li>)}
        </ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ LogTail }) {
  return {
    display: LogTail.display,
    messages: LogTail.messages.isEmpty ? [] : LogTail.messages.toArray()
  };
}
export default connect(mapStateToProps)(LogTailer);
