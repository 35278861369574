import { DeviceModelService } from '../utils/ServiceDB/DeviceModel';
import { defaultErrorFeedback } from './feedback';

export const DEVICE_MODELS_SET_DEVICE_MODELS = 'DEVICE_MODELS_SET_DEVICE_MODELS';

function setDeviceModels(deviceModels) {
  return {
    type: DEVICE_MODELS_SET_DEVICE_MODELS,
    data: deviceModels
  };
}

export function getDeviceModels() {
  return (dispatch) => {
    return DeviceModelService.getDeviceModels().then((result) => {
      if (result.status === 200) {
        dispatch(setDeviceModels(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}