import { colourStyles } from './styles';

const PatchLeadForm = {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true,
    width: 12
  },
  length: {
    label: 'Length',
    type: 'number',
    min: 0,
    mandatory: true,
    addon: 'm',
    width: 12
  },
  type: {
    label: 'Type',
    type: 'select',
    mandatory: true,
    width: 12
  },
  color: {
    label: 'Colour',
    type: 'select',
    mandatory: true,
    width: 12,
    styles: colourStyles
  }
};

export default PatchLeadForm;