import {
  createService,
  deleteService,
  getService,
  resetService,
  setServiceField,
  updateService
} from '../../../actions/ServiceDB/logicalService';
import GenericCardForm from '../../../components/GenericCardForm';
import { Col, Row } from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getServiceTypeFields } from '../../../actions/ServiceDB/logicalServiceType';
import isEmpty from 'lodash.isempty';
import { apiRequest } from '../../../utils/Api/ApiRequest';
import { queryStringFromFields } from '../../../utils/Helpers/QueryString';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import { GenericModal } from '../../../components/Modals/GenericModal';
import Subnet from '../Ipam/Subnet';
import Address from '../Ipam/Address';
import { canAccessServiceDb } from '../../../utils/Auth/AuthService';
import { useNavigate, useParams } from 'react-router-dom'

const LogicalService = ({
  serviceTypeName = null,
  id = null,
  logicalServiceType,
  logicalServiceTypes,
  data,
  original,
  onCreated = null,
  onUpdated = null,
  onSubmitted = null,
  onDeleted = null,
  connection = null
}) => {

  // router
  const navigate = useNavigate();
  const params = useParams()

  // redux
  const dispatch = useDispatch();

  const serviceType = useMemo(() => (serviceTypeName || params.serviceType), [serviceTypeName, params.serviceType])
  const serviceId = useMemo(() => (id || params.serviceId), [id, params.serviceId])
  const isNew = useMemo(() => serviceId === 'new' && !original.id, [serviceId, original.id])

  const [showSubnetModal, setShowSubnetModal] = useState(undefined);
  const [showIpAddressModal, setShowIpAddressModal] = useState(undefined);

  const resolveFieldKey = (key, entry) => entry.entity || key;
  const form = Object.fromEntries(Object.entries(logicalServiceType?.fields)
    .map(([field, value]) => {
      if (field === 'connection') {
        return [field, { ...value, readOnly: (key, field, data) => data.connectionProvided }];
      }
      if (['subnet', 'ipAddress'].includes(value.entity)) {
        if (value.type === 'select-async') {
          value.type = 'select-async-creatable';
        } else if (value.type === 'select-async-multi') {
          value.type = 'select-async-creatable-multi';
        }
      }
      return [field, value];
    }));

  const label = logicalServiceTypes?.find(service => service.slug === serviceType)?.label;
  const isExclusive = logicalServiceTypes?.find(service => service.slug === serviceType)?.isExclusive

  useEffect(() => {
    if (label && serviceType && original.name) {
      dispatch(addBreadcrumbs([
        { name: label, path: `/sdb/services/${serviceType}` },
        { name: original.name }
      ]));
    }

    return () => {
      dispatch(resetBreadcrumbs());
    };

  }, [label, serviceType, original.name]);

  const options = Object.fromEntries(Object.entries(logicalServiceType.fields)
    .filter(([, field]) => field.options !== undefined)
    .map(([key, field]) => [
      resolveFieldKey(key, field),
      field.in.map((value) => ({value, label: value}))
    ]));

  const aSyncOptions = () => {
    return Object.fromEntries(Object.entries(logicalServiceType.fields)
      .filter(([, field]) => field.fetchUrl !== undefined)
      .map(([, field]) => {
        return [field.entity, async (search, key) => {
          const field = logicalServiceType.fields[key];
          const query = {
            ...field.queryParams,
            search: [
              ...(field.searchFields ? field.searchFields.map((field) => ({id: field, value: search})) : [{id: 'name', value: search}]),
              ...(field.queryParams?.search || [])
            ],
          };
          const includeName  = `${serviceType}Services`
          if (field.entity === 'connection' && isExclusive) {
            query.include = `records.${includeName}`
          }
          return apiRequest({
            method: 'get',
            url: `${field.fetchUrl}${queryStringFromFields(query)}`
          })
            .then(result => (result.data?.records || result.data || [])
              ?.filter(item => {
                if (field.entity === 'connection' && isExclusive) {
                  return isEmpty(item[includeName]);
                }
                if (field.entity === 'subnet') {
                  return item.childSubnetCount <= 0;
                }
                return true;
              })
            )
            .catch(() => []);
        }]
      }));
    // add as needed
  };

  const onCreateOption = () => {
    return Object.fromEntries(Object.entries(logicalServiceType.fields)
      .filter(([, field]) => ['subnet', 'ipAddress'].includes(field.entity))
      .map(([key, field]) => {
        return [key, (input) => {
          if (field.entity === 'subnet') {
            const payload = { key, name: input };
            const requiredMask = field.queryParams?.search?.find(({id}) => id === 'requiredMask')
            if (requiredMask) {
              payload.mask = requiredMask.value;
            }
            const type = field.queryParams?.search?.find(({id}) => id === 'type')
            if (type) {
              payload.ipVersion = type.value;
            }
            setShowSubnetModal(payload);
          } else if (field.entity === 'ipAddress') {
            const payload = { key, name: input };
            const type = field.queryParams?.search?.find(({id}) => id === 'type')
            if (type) {
              payload.ipVersion = type.value;
            }
            setShowIpAddressModal(payload);
          }
        }];
      }));
  };

  const requestStructure = (data) => Object.fromEntries(Object.entries(logicalServiceType.fields)
    .map(([key, field]) => {
      let value;
      if (field.type === 'select-async-multi' || field.type === 'select-async-creatable-multi') {
        value = data[key]?.map(entity => entity.id);
      } else if (!isEmpty(field.entity)) {
        value = data[key]?.accountnumber || data[key]?.id || null;
      } else {
        value = data[key];
      }
      return [field.key, value];
    }));

  const includes = Object.entries(logicalServiceType.fields)
    .filter(([, field]) => !isEmpty(field.entity))
    .map(([key]) => key);

  const fetch = () => dispatch(getService(serviceType, id, includes));

  useEffect(() => {
    if (!isNew && !isEmpty(includes)) {
      fetch();
    }
  }, [logicalServiceType.fields]);

  const fetchAll = () => {
    const promises = [];
    if (isEmpty(logicalServiceType.fields)) {
      promises.push(dispatch(getServiceTypeFields(serviceType)));
    }
    if (!isEmpty(connection)) {
      promises.push(dispatch(setServiceField('connection', connection)));
    }
    return Promise.all(promises);
  };

  const onCreatedSubnet = (result) => {
    dispatch(setServiceField(showSubnetModal.key, result));
    setShowSubnetModal(undefined);
  }
  const onCreatedIpAddress = (result) => {
    dispatch(setServiceField(showIpAddressModal.key, result));
    setShowIpAddressModal(undefined);
  }

  return (
    <>
      <Row>
        <Col>
          <GenericCardForm
            title={data.name || 'New Service'}
            isNew={isNew}
            form={form}
            data={data}
            original={original}
            extraFormData={{ connectionProvided: !isEmpty(connection) }}
            options={options}
            aSyncOptions={aSyncOptions()}
            requestStructure={requestStructure}
            onFetch={fetch}
            onFetchAll={fetchAll}
            onReset={() => dispatch(resetService())}
            setField={(field, value) => dispatch(setServiceField(field, value))}
            onCreate={(toCreate) => dispatch(createService(serviceType, toCreate, includes))}
            onCreated={onCreated ? (result) => onCreated(serviceType, result) : (result) => navigate(`/sdb/services/${serviceType}/${result.id}`)}
            onUpdate={(toUpdate) => dispatch(updateService(serviceType, id, toUpdate, includes))}
            onUpdated={onUpdated ? (result) => onUpdated(serviceType, result) : undefined}
            onSubmitted={onSubmitted}
            onDelete={() => dispatch(deleteService(serviceType, id))}
            onDeleted={onDeleted ? () => onDeleted(serviceType, id) : () => navigate(`/sdb/services/${serviceType}`)}
            canEdit={canAccessServiceDb()}
            onCreateOption={onCreateOption()}
          />
        </Col>
      </Row>
      <GenericModal show={showSubnetModal !== undefined}
                    toggle={() => setShowSubnetModal(undefined)}>
        {showSubnetModal !== undefined ? (
          <Subnet
            id="new"
            location={{ name: showSubnetModal.name, mask: showSubnetModal.mask, ipVersion: showSubnetModal.ipVersion }}
            onCreated={onCreatedSubnet}
            hideChildren
            hideAllocatedTo
          />
        ) : ''}
      </GenericModal>
      <GenericModal show={showIpAddressModal !== undefined}
                    toggle={() => setShowIpAddressModal(undefined)}>
        {showIpAddressModal !== undefined ? (
          <Address
            id="new"
            location={{ name: showIpAddressModal.name, ipVersion: showIpAddressModal.ipVersion }}
            onCreated={onCreatedIpAddress}
            hideAllocatedTo
          />
        ) : ''}
      </GenericModal>
    </>
  );
};

function mapStateToProps({ logicalServiceType, logicalService, helpers, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    logicalServiceType,
    logicalServiceTypes: helpers.serviceDb.logicalServiceTypes,
    data: logicalService.data,
    original: logicalService.original
  };
}

export default connect(mapStateToProps)(LogicalService);
