import { BlockService } from '../../../utils/ServiceDB/Ipam/Block';
import { defaultErrorFeedback } from '../../feedback';

export const IPAM_BLOCKS_SET_DATA = 'IPAM_BLOCKS_SET_DATA';

function setBlocks(data) {
  return {
    type: IPAM_BLOCKS_SET_DATA,
    data
  };
}

export const getBlocks = (include = []) => {
  return (dispatch) => {
    return BlockService.list(include).then((result) => {
      if (result.status === 200) {
        dispatch(setBlocks(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    })
  }
}