import isEmpty from 'lodash.isempty';

const OrderForm = (withOpportunity, currency, handleClick) => {

  const form = {
    generalFields: {
      totalAmount: { label: 'TCV', type: 'text', plaintext: true,  format: {money: currency}, width: 4 },
      yearOneValue: { label: 'YOV', type: 'text',  plaintext: true,  format: {money: currency}, width: 4 },
      contractTermInMonths: { label: 'Term', type: 'text',  plaintext: true, width: 4 },
      company: {
        label: 'Customer',
        type: 'button-link',
        width: 12,
        getLink: (data) => data.company ? `/accounts/${data.company.accountid}` : '#',
        isDisabled: (data) => !data.company,
        callBack: (key, field, data) => data[key] !== undefined && data[key] !== null ? data[key].name : '',
      },
      name: {
        label: 'Name',
        type: 'text',
        mandatory: true,
        width: 12
      },
      statusReason: {
        label: 'Status',
        type: 'select',
        mandatory: true,
      },
      bookingDate: {
        label: 'Order Booking Date',
        type: 'date',
      },
      owner: {
        label: 'Owner',
        type: 'select',
        mandatory: true,
        entity: 'owner',
        width: 12
      },
      quote: {
        label: 'Quote',
        type: 'text',
        plaintext: true,
        width: 12,
        callBack: (key, field, data) => data.quote?.number ?? 'N/A',
      },
      opportunity: {
        label: 'Opportunity',
        type: 'text',
        plaintext: true,
        width: 12,
        callBack: (key, field, data) => data.quote?.opportunity?.name ??'N/A',
      },
      priceList: {
        label: 'Price List',
        type: 'text',
        plaintext: true,
        width: 12,
        callBack: (key, field, data) => data.priceList?.name ??'N/A',
      },
    },
    cease: {
      ceaseRequestedDate: {
        label: 'Cease Requested',
        type: 'date',
      },
      ceaseRequiredDate: {
        label: 'Cease Required',
        type: 'date',
      },
      serviceWithinInitialPeriod: {
        label: 'Service Within Initial Period',
        type: 'select-boolean',
      },
      initialPeriodEndDate: {
        label: 'Initial Period End',
        type: 'date',
      },
      billingToBeCeasedDate: {
        label: 'Billing To Be Ceased',
        type: 'date',
      },
      monthlyRecurringCancelled: {
        label: 'Monthly Recurring Cancelled',
        type: 'text',
      },
    },
    serviceDelivery: {
      engineer: {
        label: 'Engineer',
        type: 'select',
        entity: 'owner'
      },
      contact: {
        label: 'Prime Delivery Contact',
        type: 'select',
        entity: 'contact',
        handleClick: () => {
          handleClick('contact')
        },
        clearable: true,
        readOnly: (key, field, data, permissions) => isEmpty(data.customer)
      },
      customerGoLive: {
        label: 'Customer Go Live',
        type: 'date',
      },
      requestedDeliveryBy: {
        label: 'Requested Delivery By',
        type: 'date',
      },
      inJeopardy: {
        label: 'In Jeopardy',
        type: 'select-boolean',
      },
      jeopardyReason: {
        label: 'Jeopardy Reason',
        type: 'text',
      },
      nextCustomerUpdate: {
        label: 'Next Customer Update',
        type: 'date',
      },
      redFileReceivedDate: {
        label: 'Red File Received Date',
        type: 'date',
      },
    },
    documents: {
      feasibilityAttached: {
        label: 'Feasibility',
        type: 'select-boolean',
      },
      scopeOfWorksAttached: {
        label: 'Scope Of Works',
        type: 'select-boolean',
      },
      crfAttached: {
        label: 'CRF',
        type: 'select-boolean',
      },
    },
  }

  if(withOpportunity){
    form.generalFields.opportunity = {
      label: 'Opportunity',
      type: 'button-link',
      width: 12,
      getLink: (data) => `/sales/opportunities/${data.quote.opportunity.id}`,
      callBack: (key, field, data) => data.quote.opportunity.name,
      isDisabled: (data) => false,
    }
  }
  return form
}

export default OrderForm
