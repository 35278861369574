import { DeviceDeploymentService } from '../utils/ServiceDB/DeviceDeployment';
import { defaultErrorFeedback } from './feedback';
import AuditService from '../utils/Audit';

export const DEVICE_DEPLOYMENT_SET_DATA = 'DEVICE_DEPLOYMENT_SET_DATA';
export const DEVICE_DEPLOYMENT_RESET_DATA = 'DEVICE_DEPLOYMENT_RESET_DATA';
export const DEVICE_DEPLOYMENT_SET_FIELD = 'DEVICE_DEPLOYMENT_SET_FIELD';
export const DEVICE_DEPLOYMENT_ADD_PORT = 'DEVICE_DEPLOYMENT_ADD_PORT';
export const DEVICE_DEPLOYMENT_UPDATE_PORT = 'DEVICE_DEPLOYMENT_UPDATE_PORT';
export const DEVICE_DEPLOYMENT_REMOVE_PORT = 'DEVICE_DEPLOYMENT_REMOVE_PORT';
export const DEVICE_DEPLOYMENT_ADD_NOTE = 'DEVICE_DEPLOYMENT_ADD_NOTE';
export const DEVICE_DEPLOYMENT_UPDATE_NOTE = 'DEVICE_DEPLOYMENT_UPDATE_NOTE';
export const DEVICE_DEPLOYMENT_REMOVE_NOTE = 'DEVICE_DEPLOYMENT_REMOVE_NOTE';
export const DEVICE_DEPLOYMENT_SET_AUDIT_HISTORY = 'DEVICE_DEPLOYMENT_SET_AUDIT_HISTORY';

export function addDeviceDeploymentNote(note) {
  return {
    type: DEVICE_DEPLOYMENT_ADD_NOTE,
    note
  };
}

export function updateDeviceDeploymentNote(note) {
  return {
    type: DEVICE_DEPLOYMENT_UPDATE_NOTE,
    note
  };
}

export function removeDeviceDeploymentNote(note) {
  return {
    type: DEVICE_DEPLOYMENT_REMOVE_NOTE,
    note
  };
}

function setDeviceDeployment(data) {
  return {
    type: DEVICE_DEPLOYMENT_SET_DATA,
    data
  };
}

export function setDeviceDeploymentField(field, value) {
  return {
    type: DEVICE_DEPLOYMENT_SET_FIELD,
    field,
    value
  };
}

export function resetDeviceDeployment() {
  return {
    type: DEVICE_DEPLOYMENT_RESET_DATA
  };
}

export function getDeviceDeploymentAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('sdb/device-deployments', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: DEVICE_DEPLOYMENT_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}

export function getDeviceDeployment(id, include, withs) {
  return (dispatch) => {
    return DeviceDeploymentService.getDeviceDeployment(id, include, withs).then((result) => {
      if (result.status === 200) {
        dispatch(setDeviceDeployment(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createDeviceDeployment(data, include = []) {
  return (dispatch) => {
    return DeviceDeploymentService.createDeviceDeployment(data, include).then((result) => {
      if (result.status === 200) {
        dispatch(setDeviceDeployment(result.data));
        return result.data;
      } else if(result.status === 422){
        return {errors: result.data}
      } else {
        dispatch(defaultErrorFeedback());
        return false
      }
    });
  };
}

export function updateDeviceDeployment(id, data, include = []) {
  return (dispatch) => {
    return DeviceDeploymentService.updateDeviceDeployment(id, data, include).then((result) => {
      if (result.status === 200) {
        dispatch(setDeviceDeployment(result.data));
        return result.data;
      } else if(result.status === 422){
        return {errors: result.data}
      }else {
        dispatch(defaultErrorFeedback());
        return false
      }
    });
  };
}

export function deleteDeviceDeployment(id) {
  return (dispatch) => {
    return DeviceDeploymentService.deleteDeviceDeployment(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function addNewDeviceDeploymentPort(portData) {
  return {
    type: DEVICE_DEPLOYMENT_ADD_PORT,
    portData
  };
}

export function updateDeviceDeploymentPort(portData) {
  return {
    type: DEVICE_DEPLOYMENT_UPDATE_PORT,
    portData
  };
}

export function removeDeviceDeploymentPort(portId) {
  return {
    type: DEVICE_DEPLOYMENT_REMOVE_PORT,
    portId
  };
}