import { SignatureService } from '../utils/Signatures/SignatureService';
import { defaultErrorFeedback, errorFeedback, successFeedback } from './feedback';
import { apiRequest } from '../utils/Api/ApiRequest';

export const SIGNATURES_SET_ALL = 'SIGNATURES_SET_ALL';
export const SIGNATURES_SET_LOADING = 'SIGNATURES_SET_LOADING';
export const SIGNATURES_SET_ACTIVE = 'SIGNATURES_SET_ACTIVE';
export const SIGNATURES_SET_ACTIVE_LOADING = 'SIGNATURES_SET_ACTIVE_LOADING';

export const SIGNATURES_REMOVE_FILE = 'SIGNATURES_REMOVE_FILE';
export const SIGNATURES_UPDATE_FILES = 'SIGNATURES_UPDATE_FILES';
export const SIGNATURES_UPDATE_STATUS = 'SIGNATURES_UPDATE_STATUS';
export const SIGNATURES_UPDATE_FIELD = 'SIGNATURES_UPDATE_FIELD';
export const SIGNATURES_SET_ACTIVE_SAVING = 'SIGNATURES_SET_ACTIVE_SAVING';
export const SIGNATURES_TOGGLE_ADD = 'SIGNATURES_TOGGLE_ADD';
export const SIGNATURES_ADD_NEW = 'SIGNATURES_ADD_NEW';
export const SIGNATURES_SET_ERRORS = 'SIGNATURES_SET_ERRORS';
export const SIGNATURES_SET_TOSIGN = 'SIGNATURES_SET_TOSIGN';

// actions from sockets
export const SIGNATURES_NEW = 'SIGNATURES_NEW';
export const SIGNATURES_UPDATE = 'SIGNATURES_UPDATE';
export const SIGNATURES_COUNT_INCREMENT = 'SIGNATURES_COUNT_INCREMENT';
export const SIGNATURES_COUNT_DECREMENT = 'SIGNATURES_COUNT_DECREMENT';
export const SIGNATURES_RESET_SIGNATURE = 'SIGNATURES_RESET_SIGNATURE';
export const SIGNATURES_UPDATE_SIGNATURE_FIELD = 'SIGNATURES_UPDATE_SIGNATURE_FIELD';
export const SIGNATURES_SET_SIGNATURE = 'SIGNATURES_SET_SIGNATURE';
export const SIGNATURES_SET_SIGNATURE_COMPANY_CONTACTS = 'SIGNATURES_SET_SIGNATURE_COMPANY_CONTACTS';
export const SIGNATURES_ADD_SIGNATURE_FILE = 'SIGNATURES_ADD_SIGNATURE_FILE';
export const SIGNATURES_SET_SIGNATURE_FILES = 'SIGNATURES_SET_SIGNATURE_FILES';

export const SIGNATURES_REMOVE_SIGNATURE_FILE = 'SIGNATURES_REMOVE_SIGNATURE_FILE';

function setSignatures(signatures) {
  return {
    type: SIGNATURES_SET_ALL,
    signatures
  };
}

function setToSign(count) {
  return {
    type: SIGNATURES_SET_TOSIGN,
    data: count
  };
}

export function setActiveSignature(signature) {
  return {
    type: SIGNATURES_SET_SIGNATURE,
    signature
  };
}

export function updateSignatureFileList(files) {
  return {
    type: SIGNATURES_SET_SIGNATURE_FILES,
    files
  };
}

function setLoading(loading) {
  return {
    type: SIGNATURES_SET_LOADING,
    loading
  };
}

export function addSignatureFile(fileData) {
  return {
    type: SIGNATURES_ADD_SIGNATURE_FILE,
    data: fileData
  };
}

function removeSignatureFile(fileId) {
  return {
    type: SIGNATURES_REMOVE_SIGNATURE_FILE,
    id: fileId
  };
}




export const resetSignature = () => {
  return {
    type: SIGNATURES_RESET_SIGNATURE
  }
}

export const updateSignatureField = (field) => {
  return {
    type: SIGNATURES_UPDATE_SIGNATURE_FIELD,
    field
  }
}

const setSignature = (signature) => {
  return {
    type: SIGNATURES_SET_SIGNATURE,
    signature
  }
}

export const setSignatureCompanyContacts = (contacts) => {
  return {
    type: SIGNATURES_SET_SIGNATURE_COMPANY_CONTACTS,
    contacts
  }
}





export function getSignatures(page, pageSize, searchString, sorting) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return SignatureService.list(page, pageSize, searchString, sorting).then((result) => {
      dispatch(setLoading(false));
      if (result.status === 200) {
        dispatch(setSignatures(
          result.data
        ));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getSignaturesToSignCount() {
  return (dispatch) => {
    return SignatureService.countSignatures().then((result) => {
      if (result.status === 200) {
        dispatch(setToSign(result.data.count));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getSignature(sigId) {
  return (dispatch) => {
    return SignatureService.get(sigId).then((result) => {
      if (result.status === 200) {
        dispatch(setActiveSignature(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function deleteFile(sigId, fileId) {
  return (dispatch) => {
    return SignatureService.deleteFile(sigId, fileId).then((result) => {
      if (result.status === 204) {
        dispatch(removeSignatureFile(fileId));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function attachReorder(sigId, data) {
  return (dispatch) => {
    return SignatureService.attachReorder(sigId, data).then((result) => {
      if (result.status !== 204) {
        dispatch(errorFeedback('Could not save document order'));
      }
    });
  };
}

export function cancelSignature(sigId) {
  return (dispatch) => {
    return SignatureService.cancelSignature(sigId).then((result) => {
      if (result.status === 200) {
        dispatch(successFeedback('Signature Cancelled'));
        dispatch(setActiveSignature(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function resendSignature(sigId) {
  return (dispatch) => {
    return SignatureService.resendSignatureEmail(sigId).then((result) => {
      if (result.status === 200) {
        dispatch(successFeedback('Signature Email Re-Sent'));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function sendSignature(sigId) {
  return (dispatch) => {
    return SignatureService.sendSignature(sigId).then((result) => {
      if (result.status === 200) {
        dispatch(successFeedback('Signature Request Sent'));
        dispatch(setActiveSignature(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function updateSignature(sigId, data) {
  return (dispatch) => {
    return SignatureService.updateSignature(sigId, data).then((result) => {
      if (result.status === 200) {
        dispatch(successFeedback('Signature Saved'));
        dispatch(setActiveSignature(result.data));
        return result.data
      } else if (result.status === 422) {
        return { errors: result.data};
      } else {
        dispatch(defaultErrorFeedback());
        return false
      }
    });
  };
}

export function createSignature(data) {
  return (dispatch) => {
    return SignatureService.createSignature(data).then((result) => {
      if (result.status === 200) {
        dispatch(setSignature(result.data))
        return result.data
      } else if (result.status === 422) {
        return {errors: result.data}
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function downloadFinalDocument(sigId) {
  return apiRequest({
    url: '/signatures/' + sigId + '/download',
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function downloadCurrentDocument(sigId) {
  return apiRequest({
    url: '/signatures/' + sigId + '/hsdownload',
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function downloadDocument(sigId, fileId) {
  return apiRequest({
    url: '/signatures/' + sigId + '/documents/' + fileId,
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
