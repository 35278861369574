import {
  ADD_TAG,
  ADD_TEMPLATE_ENTRY,
  ADDING_ENTRY,
  DELETE_TEMPLATE_ENTRY,
  DELETING_ENTRY,
  EXPAND_CODE_WINDOW, FETCH_ENTRIES_FAILURE,
  FETCHING_ACTIVE_ENTRY,
  FETCHING_ENTRIES,
  GENERATED,
  LINK_TEMPLATE,
  LINKING_TEMPLATE,
  LOAD_TEMPLATE_ENTRIES,
  REMOVE_TAG, REMOVE_TEMPLATE_EDITOR, RENAME_ENTRY,
  SAVING_TEMPLATE,
  SET_ACTIVE_ENTRY, SET_NOTE, SET_RENAME_LIVE, SET_SCHEMA, SET_TEMPLATE_ERROR,
  UNLINK_TEMPLATE,
  UPDATE_JSON_ARGUMENT_DATA, UPDATING_ENTRY
} from "../actions/configTemplate";
import update from "immutability-helper";

const initialState = {
  fetching_entries: false,
  fetchEntryFailure: false,
  adding_entries: false,
  deleting_entries: false,
  template_entries: [],
  generated: {data: ""},
  activeEntry: {},
  fetchingActiveEntry: false,
  expandCodeWindow: false,
  savingTemplate: false,
  jsonArgumentData: "{}",
  updatingEntry: "",
  templateError: false,
  renameLive: false,
  linkingTemplate: false,
  removeTemplateEditor: false,
};

export default function configTemplate(state = initialState, action) {
  switch (action.type) {
    case UPDATE_JSON_ARGUMENT_DATA:
      return {...state, jsonArgumentData: action.data}
    case FETCHING_ENTRIES:
      return {...state, fetching_entries: false}
    case LOAD_TEMPLATE_ENTRIES:
      return {...state, template_entries: action.data}
    case ADDING_ENTRY:
      return {...state, adding_entries: false}
    case DELETING_ENTRY:
      return {...state, deleting_entries: action.result}
    case DELETE_TEMPLATE_ENTRY:
      const templateIndex = state.template_entries.findIndex(e => e.id === action.data);
      return update(state, {template_entries: {$splice: [[templateIndex, 1]]}});
    case ADD_TEMPLATE_ENTRY:
      return {...state, template_entries: [...state.template_entries, action.data]}
    case GENERATED:
      return {...state, generated: {data: action.result}}
    case FETCHING_ACTIVE_ENTRY:
      return {...state, fetchingActiveEntry: action.fetching}
    case SET_ACTIVE_ENTRY:
      return {...state, activeEntry: action.data}
    case ADD_TAG:
      return {...state, activeEntry: {...state.activeEntry, tags: [...state.activeEntry.tags, action.data]}}
    case REMOVE_TAG:
      const tagIndex = state.activeEntry['tags'].findIndex(e => e === action.data);
      return update(state, {activeEntry: {tags: {$splice: [[tagIndex, 1]]}}});
    case LINK_TEMPLATE:
      return {
        ...state, activeEntry: {
          ...state.activeEntry,
          required_templates: [...state.activeEntry.required_templates, action.data]
        }
      }
    case UNLINK_TEMPLATE:
      return update(state,
        {
          activeEntry:
            {
              required_templates: {
                $apply: () => state.activeEntry.required_templates.filter(
                  e => e.id !== action.childTemplate
                )
              }
            }
        });
    case SAVING_TEMPLATE:
      return {...state, savingTemplate: action.saving}
    case EXPAND_CODE_WINDOW:
      return {...state, expandCodeWindow: action.expand}
    case SET_TEMPLATE_ERROR:
      return {...state, templateError: action.error}
    case UPDATING_ENTRY:
      return {...state, updatingEntry: action.data}
    case SET_SCHEMA:
      return update(state, {activeEntry: {schema: {$set: action.data}}})
    case SET_NOTE:
      const notesIndex = state.template_entries.findIndex(e => e.id === state.activeEntry.id);
      return update(state, {template_entries: {[notesIndex]: {notes: {$set: action.data}}}})
    case RENAME_ENTRY:
      const renameIndex = state.template_entries.findIndex(e => e.id === action.data.id);
      return update(state, {template_entries: {[renameIndex]: {description: {$set: action.data.renameText}}}})
    case SET_RENAME_LIVE:
      return {...state, renameLive: action.data}
    case LINKING_TEMPLATE:
      return {...state, linkingTemplate: action.data}
    case FETCH_ENTRIES_FAILURE:
      return {...state, fetchEntryFailure: action.data}
    case REMOVE_TEMPLATE_EDITOR:
      return {...state, removeTemplateEditor: action.data}
    default:
      return state;
  }
}