import {shiftApiRequest} from "../Api/ApiRequest";

export function getShiftCheckinReport(page = 0, size = 50) {
    const url = `/checkin/report?page=${page}&size=${size}`;
    return shiftApiRequest({
        url,
        method: 'get'
    }).then(response => response)
        .catch(function (error) {
            return Promise.reject(error.response || error.message);
        });
}
