import { api_getAccountBilling, api_getActiveCustomers, api_updateAccountLastBilledDate } from '../utils/Billing/BillingService';
import { defaultErrorFeedback, successFeedback } from './feedback';
import AccountService from '../utils/Account';

export const SET_BILLING_PREVIEW_DATE = 'SET_BILLING_PREVIEW_DATE';
export const SET_BILLING_PREVIEW_SEARCH_OPTIONS = 'SET_BILLING_PREVIEW_SEARCH_OPTIONS';
export const SET_BILLING_PREVIEW_ACCOUNT = 'SET_BILLING_PREVIEW_ACCOUNT';
export const SET_BILLING_PREVIEW_BILLS = 'SET_BILLING_PREVIEW_BILLS';
export const SET_BILLING_PREVIEW_LOADING = 'SET_BILLING_PREVIEW_LOADING';
export const SET_BILLING_PREVIEW_LAST_BILLED_DATE = 'SET_BILLING_PREVIEW_LAST_BILLED_DATE';
export const SET_BILLING_PREVIEW_ERROR = 'SET_BILLING_PREVIEW_ERROR';

function setSearchOptions(searchOptions) {
  return {
    type: SET_BILLING_PREVIEW_SEARCH_OPTIONS,
    searchOptions
  };
}

function setBills(bills) {
  return {
    type: SET_BILLING_PREVIEW_BILLS,
    bills
  };
}

function setLoading(loading) {
  return {
    type: SET_BILLING_PREVIEW_LOADING,
    loading: loading === true
  };
}

function setError(error) {
  return {
    type: SET_BILLING_PREVIEW_ERROR,
    error: error
  };
}

export function searchActiveBillingCustomers(searchString) {
  return (dispatch) => {
    return AccountService.search(searchString, true).then((result) => {
      if (result.status === 200) {
        dispatch(setSearchOptions(result.data));
      } else {
        dispatch(defaultErrorFeedback());
        dispatch(setSearchOptions([]));
      }
    });
  };
}

export function getAccountBillingPreview(accountId, billingDate) {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setBills([]));
    dispatch(setError(null));
    return api_getAccountBilling(accountId, billingDate).then((result) => {
      if (result.status === 200) {
        dispatch(result.data.error ? setError(result.data.error) : setBills(result.data));
      } else {
        dispatch(defaultErrorFeedback());
        dispatch(setError(result.data.error));
        dispatch(setBills([]));
      }
      dispatch(setLoading(false));
    });
  };
}

export function updateAccountLastBilledDate(accountNumber, date) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return api_updateAccountLastBilledDate(accountNumber, date).then((result) => {
      if (result.status === 200) {
        dispatch(successFeedback('updated!'));
      } else {
        dispatch(defaultErrorFeedback());
      }
      dispatch(setLoading(false));
    });
  };
}
