import { apiRequest, netApiRequest } from '../Api/ApiRequest';

export const WeathermapService = {
  listWeathermaps: () => {
    return apiRequest({
      url: '/weathermaps',
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getWeathermap: (mapId) => {
    return apiRequest({
      url: '/weathermaps/' + mapId,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getWeathermapTraffic: (mapId) => {
    return apiRequest({
      url: '/weathermaps/' + mapId + '/traffic',
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getWeathermapEdge: (mapId, edgeId) => {
    return apiRequest({
      url: '/weathermaps/' + mapId + '/edge/' + edgeId,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getWeathermapNode: (nodeId) => {
    const url = '/devices/' + nodeId + '/info';
    return netApiRequest({
      url,
      method: 'get'
    }).then(response => response)
      .catch(function (error) {
        return Promise.reject(error.response || error.message);
      });
  },
  api_enterMaintenance: (from, to, user) => {
    return netApiRequest({
      url : '/jobs',
      method: 'put',
      data: {
        job: 'tasks.enter_maintenance',
        params: [from, to, user]
      }
    }).then(response => response)
      .catch(function (error) {
        return Promise.reject(error.response || error.message);
      });
  },
  api_exitMaintenance: (from, to, user) => {
    return netApiRequest({
      url : '/jobs',
      method: 'put',
      data: {
        job: 'tasks.exit_maintenance',
        params: [from, to, user]
      }
    }).then(response => response)
      .catch(function (error) {
        return Promise.reject(error.response || error.message);
      });
  },
  saveWeatherMap: (mapId, topology) => {
    return apiRequest({
      url: '/weathermaps/' + mapId,
      method: 'put',
      data: { data: topology }
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  createWeatherMap: (name, description) => {
    return apiRequest({
      url: '/weathermaps',
      method: 'post',
      data: { name: name, description: description }
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
};
