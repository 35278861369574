import React from 'react';
import { Col, Row } from 'reactstrap';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
const AccountCache = ({ handleCache, processing }) => {
  return (

        <div>
            <Row>
                <Col>
                    <LaddaButton
                        className="btn btn-primary btn-ladda px-4 mr-1"
                        loading={processing}
                        data-style={ZOOM_OUT}
                        onClick={handleCache}
                    >
                        Drop the cash!
                    </LaddaButton>
                </Col>
            </Row>
        </div>

  );
};

export default AccountCache;
