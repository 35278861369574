import { apiRequest } from '../Api/ApiRequest';

export function api_generateLeadTimesReport(year, quarter, format = 'json') {
  const queryString = quarter ? `quarter&quarter=${quarter}/${year}` : `year&year=${year}`;
  return apiRequest({
    url: `/lineitems/fulfilled?search=${queryString}&output=${format}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
