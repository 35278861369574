import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  downloadDocument,
  getDocument,
  resetDocument,
  setDocumentFormField,
  updateDocument,
  uploadDocument
} from '../../actions/document';
import LoadingOverlay from '../../components/LoadingOverlay';
import { Alert, Button, Col, FormGroup, Label, Row, Spinner } from 'reactstrap';
import generateFormFields from '../../helpers/FormFieldGenerator';
import Form from './form';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { updatedDiff } from 'deep-object-diff';
import OptionsModal from '../../components/OptionsModal';

const Document = ({ id, original, data, account, optionSets, onCreated, onUpdated, dispatch }) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showReplaceAlertModal, setShowReplaceAlertModal] = useState(false);
  const [errors, setErrors] = useState([]);

  const isNew = !id && !original.id;

  const getData = () => {
    setLoading(true);
    dispatch(getDocument(account.original.accountid, id || original.id))
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isNew) {
      getData();
    } else {
      setLoading(false);
    }
    return () => {
      dispatch(resetDocument());
    };
  }, []);

  const isMandatory = (key, value) => {
    return (!value.show || (value.show && value.show(key, { ...data[key], isNew }))) && value.mandatory;
  }

  const validated = (isNew, data) => {
    let errorArr = [];
    for (const [key, value] of Object.entries(Form)) {
      if (isMandatory(key, value) && (data[key] === undefined || data[key] === null || data[key].length === 0)) {
        errorArr.push(`You must provide a valid ${value.label}.`);
      }
    }
    if (!isNew) {
      const originalExt = /(?:\.([^.]+))?$/.exec(original.name);
      const dataExt = /(?:\.([^.]+))?$/.exec(data.name);
      if (originalExt[0] !== dataExt[0]) {
        errorArr.push(`The file extension must be ${originalExt[0]}`);
      }
    }
    if (isNew) {
      if (!data.file) {
        errorArr.push('You must provide a valid file.');
      }
    }

    setErrors(errorArr);
    return errorArr.length === 0;
  };

  const upload = () => {
    dispatch(uploadDocument(account.original.accountid, data))
      .then((result) => {
        if (result) {
          if (onCreated) {
            onCreated(result);
          }
        } else {
          setErrors(prevState => [...prevState, 'Upload failed. Please try again.']);
        }
        setSaving(false)
        setLoading(false);
      });
  };

  const save = () => {
    if (validated(isNew, data)) {
      setSaving(true);
      setLoading(true);
      if (isNew) {
        if (account.documents.find(document => document.name === data.file.name)) {
          setShowReplaceAlertModal(true);
        } else {
          upload();
        }
      } else {
        const updatedData = updatedDiff(original, data);
        dispatch(updateDocument(account.original.accountid, id, updatedData))
          .then((result) => {
            if (result) {
              if (onUpdated) {
                onUpdated(result);
              }
            } else {
              setErrors(prevState => [...prevState, 'Update failed. Please try again.']);
            }
            setSaving(false)
            setLoading(false);
          });
      }
    }
  };

  const handleInput = (event) => {
    dispatch(setDocumentFormField(event.target.id, event.target.value));
  };

  const handleFileDrop = (file) => {
    if (file.meta.status === 'done') {
      dispatch(setDocumentFormField('file', file.file));
    } else if (file.meta.size > 4000000) {
      setErrors(['File must be smaller than 4MB.']);
    }
  };

  const getSelectOptions = (field) => {
    switch (field) {
      case 'type':
      case 'status':
        return optionSets[field];
      default:
        return [];
    }
  };
  const getSelectedOption = (field) => {
    let selected = [];
    selected = optionSets[field].filter(option => data[field] === option.value);
    if (selected.length) {
      return selected[0];
    }
    return null;
  };

  const handleSelectInput = (key, selected) => {
    dispatch(setDocumentFormField(key, selected.value));
  };

  const handleDownload = (e) => {
    e.preventDefault();
    dispatch(downloadDocument(account.original.accountid, id || original.id));
  };

  useEffect(() => {
    if (saving) {
      upload();
    }
  }, [data.file])

  const resolveNextValidName = (originalName) => {
    let i = 0;
    const lastDot = originalName.lastIndexOf('.');
    const name = originalName.substring(0, lastDot);
    const ext = originalName.substring(lastDot + 1);
    while (account.documents.find(document => document.name === `${name}${i ? ` (${i})` : ''}.${ext}`)) {
      i++;
    }
    return `${name}${i ? ` (${i})` : ''}.${ext}`;
  };

  const keepBoth = () => {
    const newFile = new File([data.file], resolveNextValidName(data.file.name), {
      type: data.file.type,
      lastModified: data.file.lastModified
    });
    dispatch(setDocumentFormField('file', newFile));
  };

  const cancelUpload = () => {
    setShowReplaceAlertModal(false);
    setSaving(false)
    setLoading(false);
  }

  return (
    <div className={'animated fadeIn'}>
      <LoadingOverlay loading={loading}>
        <Row className="mb-3">
          <Col className="d-flex justify-content-end">
            {!isNew ? (
              <Button size="sm" color="info" onClick={handleDownload} title="Download"><i
                className="icon-cloud-download"/></Button>
            ) : ''}
            <Button className="ml-2" size="sm" color="primary" onClick={save}>
              {saving ? <Spinner size="sm" color="light"/> : <i className="fa fa-save"/>}
            </Button>
            {!isNew ? (
              <Button className="ml-2" size="sm" color="warning" onClick={getData}><i
                className="fa fa-refresh"/></Button>
            ) : ''}
          </Col>
        </Row>
        <Row>
          {errors.length > 0
            ? <Col>
              <Alert color="danger">
                {errors.map((error, index) => {
                  return <p key={'error' + index} className="mb-0">{error}</p>;
                })}
              </Alert>
            </Col> : ''}
        </Row>
        <Row form>
          {generateFormFields({
            fields: Form,
            handleInput,
            getSelectOptions,
            getSelectedOption,
            handleSelectInput,
            data: { ...data, isNew }
          })}
          {isNew ? (
            <Col xs={12}>
              <FormGroup>
                <Label className="control-label"
                       for="document-upload">{!isNew ? 'Replace' : 'Upload'} Document</Label>
                <Dropzone
                  id="document-upload"
                  autoUpload={false}
                  inputContent="Drop files or click to upload"
                  styles={{
                    dropzone: { border: '1px dashed #aeaeae', background: '#fafafa' },
                    inputLabel: { color: '#aeaeae' },
                    submitButton: { display: 'none' }
                  }}
                  accept={optionSets.mimetype.map(mimetype => mimetype.value).join(',')}
                  onChangeStatus={handleFileDrop}
                  multiple={false}
                  maxFiles={1}
                  maxSizeBytes={4 * 1024 * 1024}
                />
                <p className="text-muted">Supported File Types: Word, Excel, PDF</p>
              </FormGroup>
            </Col>
          ) : ''}
        </Row>
        <OptionsModal
          isOpen={showReplaceAlertModal}
          close={() => setShowReplaceAlertModal(false)}
          contentText="There is a document with this name already."
          options={[
            { text: 'Replace', onClick: upload },
            { text: 'Keep Both', onClick: keepBoth },
            { text: 'Cancel', onClick: cancelUpload, color: 'danger' }
          ]}
        />
      </LoadingOverlay>
    </div>

  );
};

const mapStateToProps = ({ document, helpers, account}) => {
  return {
    original: document.document,
    data: document.documentForm,
    optionSets: helpers.optionSets.document,
    account
  };
};

export default connect(mapStateToProps)(Document);
