import {
  api_dropAccountCache,
  api_getAccountData,
  api_getUserToken,
  api_searchAccounts,
  api_getAccountBills,
  api_getAccountServicesData,
  api_getAccountCases,
  api_updateAccountInfo,
  api_getAccountOrdersData,
  api_getAccountSites,
  api_addNewAccountSite,
  api_getAccountConfigItems,
  api_getAccountLogs,
  api_createAccount,
  api_getAccountDocuments,
  api_activatePortalAccess, api_getAccountPartnerships, AccountServiceDeprecated
} from '../utils/Account/AccountServiceDeprecated';
import { defaultErrorFeedback, successFeedback } from './feedback';
import accountInfoForms from '../views/Account/AccountInfo/form';
import { setSiteData } from './site';
import { api_getAccountCircuits } from '../utils/Circuits/CircuitService';
import { SignatureService } from '../utils/Signatures/SignatureService';
import fileDownload from 'js-file-download';
import AccountService from '../utils/Account';

export const ACCOUNTS_SET_SEARCH_RESULTS = 'ACCOUNTS_SET_SEARCH_RESULTS';
export const ACCOUNTS_SET_SEARCH_STRING = 'ACCOUNTS_SET_SEARCH_STRING';
export const ACCOUNTS_TOGGLE_SEARCH_LOADING = 'ACCOUNTS_TOGGLE_SEARCH_LOADING';
export const ACCOUNTS_SET_ACCOUNT = 'ACCOUNTS_SET_ACCOUNT';
export const ACCOUNTS_RESET_ACCOUNT = 'ACCOUNTS_RESET_ACCOUNT';
export const ACCOUNTS_TOGGLE_ACCOUNT_LOADING = 'ACCOUNTS_TOGGLE_ACCOUNT_LOADING';
export const ACCOUNTS_TOGGLE_CONTACT_EMULATING = 'ACCOUNTS_TOGGLE_CONTACT_EMULATING';
export const ACCOUNTS_SET_EMULATOR = 'ACCOUNTS_SET_EMULATOR';
export const ACCOUNTS_SET_ACCOUNT_INFO_FORM_DATA = 'ACCOUNTS_SET_ACCOUNT_INFO_FORM_DATA';
export const ACCOUNTS_UPDATE_ACCOUNT_INFO_FIELD = 'ACCOUNTS_UPDATE_ACCOUNT_INFO_FIELD';
export const ACCOUNTS_SET_PORTAL_ACTIVATION_CONTACT = 'ACCOUNTS_SET_PORTAL_ACTIVATION_CONTACT';
export const ACCOUNTS_SET_PORTAL_ACTIVATION_ERRORS = 'ACCOUNTS_SET_PORTAL_ACTIVATION_ERRORS';
export const ACCOUNTS_TOGGLE_CACHE_REQUEST_PROCESSING = 'ACCOUNTS_TOGGLE_CACHE_REQUEST_PROCESSING';
export const ACCOUNTS_CONTACTS_SET_SEARCH_STRING = 'ACCOUNTS_CONTACTS_SET_SEARCH_STRING';
export const ACCOUNTS_SET_BILLS = 'ACCOUNTS_SET_BILLS';
export const ACCOUNTS_SET_SERVICES = 'ACCOUNTS_SET_SERVICES';
export const ACCOUNTS_SERVICES_SET_SERVICE = 'ACCOUNTS_SERVICES_SET_SERVICE';
export const ACCOUNTS_SET_ORDERS = 'ACCOUNTS_SET_ORDERS';
export const ACCOUNTS_SET_SITES = 'ACCOUNTS_SET_SITES';
export const ACCOUNTS_SET_PARTNERSHIPS = 'ACCOUNTS_SET_PARTNERSHIPS';
export const ACCOUNTS_SET_CONFIG_ITEMS = 'ACCOUNTS_SET_CONFIG_ITEMS';
export const ACCOUNTS_SET_SITES_SITE = 'ACCOUNTS_SET_SITES_SITE';
export const ACCOUNTS_SET_CONFIG_ITEMS_ITEM = 'ACCOUNTS_SET_CONFIG_ITEMS_ITEM';
export const ACCOUNTS_ORDERS_SET_SEARCH_STRING = 'ACCOUNTS_ORDERS_SET_SEARCH_STRING';
export const ACCOUNTS_BILLS_SET_SEARCH_STRING = 'ACCOUNTS_BILLS_SET_SEARCH_STRING';
export const ACCOUNTS_SERVICE_SET_SEARCH_STRING = 'ACCOUNTS_SERVICE_SET_SEARCH_STRING';
export const ACCOUNTS_SITES_SET_SEARCH_STRING = 'ACCOUNTS_SITES_SET_SEARCH_STRING';
export const ACCOUNTS_PARTNERSHIPS_SET_PARTNERSHIP = 'ACCOUNTS_PARTNERSHIPS_SET_PARTNERSHIP';
export const ACCOUNTS_PARTNERSHIPS_REMOVE_PARTNERSHIP = 'ACCOUNTS_PARTNERSHIPS_REMOVE_PARTNERSHIP';
export const ACCOUNTS_PARTNERSHIPS_SET_SEARCH_STRING = 'ACCOUNTS_PARTNERSHIPS_SET_SEARCH_STRING';
export const ACCOUNTS_CONFIG_ITEM_SET_SEARCH_STRING = 'ACCOUNTS_CONFIG_ITEM_SET_SEARCH_STRING';
export const ACCOUNTS_SET_CASES = 'ACCOUNTS_SET_CASES';
export const ACCOUNTS_CASES_SET_SEARCH_STRING = 'ACCOUNTS_CASES_SET_SEARCH_STRING';
export const ACCOUNT_UPDATE_ORDER_IN_TABLE = 'ACCOUNT_UPDATE_ORDER_IN_TABLE';
//export const ACCOUNT_UPDATE_CONTACT_IN_TABLE = 'ACCOUNT_UPDATE_CONTACT_IN_TABLE';
export const ACCOUNTS_ADD_SITES_SITE = 'ACCOUNTS_ADD_SITES_SITE';
export const ACCOUNT_UPDATE_CASE_IN_TABLE = 'ACCOUNT_UPDATE_CASE_IN_TABLE';
export const ACCOUNT_ADD_CASES_CASE = 'ACCOUNT_ADD_CASES_CASE';
export const ACCOUNT_ADD_CONTACTS_CONTACT = 'ACCOUNT_ADD_CONTACTS_CONTACT';
export const ACCOUNTS_SET_CIRCUITS = 'ACCOUNTS_SET_CIRCUITS';
export const ACCOUNTS_CIRCUITS_SET_SEARCH_STRING = 'ACCOUNTS_CIRCUITS_SET_SEARCH_STRING';
export const ACCOUNTS_SET_LOGS = 'ACCOUNTS_SET_LOGS';
export const ACCOUNTS_ADD_LOGS_LOG = 'ACCOUNTS_ADD_LOGS_LOG';
export const ACCOUNTS_LOGS_SET_SEARCH_STRING = 'ACCOUNTS_LOGS_SET_SEARCH_STRING';
export const ACCOUNTS_SET_SIGNATURES = 'ACCOUNTS_SET_SIGNATURES';
export const ACCOUNTS_SET_DOCUMENTS = 'ACCOUNTS_SET_DOCUMENTS';
export const ACCOUNTS_DOCUMENTS_SET_DOCUMENT = 'ACCOUNTS_DOCUMENTS_SET_DOCUMENT';
export const ACCOUNTS_DOCUMENTS_SET_SEARCH_STRING = 'ACCOUNTS_DOCUMENTS_SET_SEARCH_STRING';
export const ACCOUNTS_DOCUMENTS_REMOVE_DOCUMENT = 'ACCOUNTS_DOCUMENTS_REMOVE_DOCUMENT';
export const ACCOUNTS_DOCUMENTS_UPDATE_DOCUMENT = 'ACCOUNTS_DOCUMENTS_UPDATE_DOCUMENT';

export function updateAccountOrderInTable(order) {
  return {
    type: ACCOUNT_UPDATE_ORDER_IN_TABLE,
    order
  };
}

export function addAccountsCasesCase(theCase) {
  return {
    type: ACCOUNT_ADD_CASES_CASE,
    theCase
  };
}

export function updateAccountCaseInTable(theCase) {
  return {
    type: ACCOUNT_UPDATE_CASE_IN_TABLE,
    theCase
  };
}

export function addAccountContactsContact(contact) {
  return {
    type: ACCOUNT_ADD_CONTACTS_CONTACT,
    contact
  };
}


export const toggleAccountSearchLoading = () => {
  return {
    type: ACCOUNTS_TOGGLE_SEARCH_LOADING
  };
};

export const toggleAccountDataLoading = () => {
  return {
    type: ACCOUNTS_TOGGLE_ACCOUNT_LOADING
  };
};

export const toggleEmulating = () => {
  return {
    type: ACCOUNTS_TOGGLE_CONTACT_EMULATING
  };
};

export const setEmulator = (url, portal) => {
  return {
    type: ACCOUNTS_SET_EMULATOR,
    emulator: { url, portal }
  };
};

export function resetAccount() {
  return {
    type: ACCOUNTS_RESET_ACCOUNT
  };
}

export function toggleAccountCacheProcessing() {
  return {
    type: ACCOUNTS_TOGGLE_CACHE_REQUEST_PROCESSING
  };
}

export function setPortalActivationContact(contact) {
  return {
    type: ACCOUNTS_SET_PORTAL_ACTIVATION_CONTACT,
    contact
  };
}

export function setPortalActivationErrors(errors) {
  return {
    type: ACCOUNTS_SET_PORTAL_ACTIVATION_ERRORS,
    errors
  }
}

export function setAccountServices(services){
  return {
    type: ACCOUNTS_SET_SERVICES,
    services
  }
}

export function activatePortalAccess(accountId, contactId) {
  return (dispatch) => api_activatePortalAccess(accountId, contactId)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch(successFeedback('Activation email sent!'));
            dispatch({
              type: ACCOUNTS_ADD_LOGS_LOG,
              data: result.data
            })
            return true;
          } else {
            dispatch(defaultErrorFeedback())
            if (result.status === 422 && result.data) {
              dispatch(setPortalActivationErrors(Object.values(result.data)))
            } else {
              dispatch(setPortalActivationErrors(['There was an error submitting the activation. Please try again or contact the Software team.']))
            }
            return false;
          }
        }
      );
}

export function updateAccountInfoField(field, value) {
  return {
    type: ACCOUNTS_UPDATE_ACCOUNT_INFO_FIELD,
    field,
    value
  };
}

export function searchAccounts(searchString, activeOnly = true){
    return (dispatch) => {
        return AccountService.search(searchString, activeOnly).then(
            (result) => {
                if (result.status === 200) {
                  dispatch({type: ACCOUNTS_SET_SEARCH_RESULTS, accounts: result.data })
                  return result.data
                }
                else{
                    dispatch(defaultErrorFeedback());
                }
            }
        )
    }
}

export function getAccountData(idOrNumber, includes = []) {
  return (dispatch) => {
    return AccountService.get(idOrNumber, includes).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: ACCOUNTS_SET_ACCOUNT, account: result.data });
          dispatch(setAccountInfoFormData(result.data));
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export function createAccount(args) {
  return (dispatch) => {
    return api_createAccount(args).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: ACCOUNTS_SET_ACCOUNT, account: result.data });
          dispatch(setAccountInfoFormData(result.data));
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      }
    );
  };
}

export function setAccountInfoFormData(data) {
  let formData = {};
  for (const form of Object.values(accountInfoForms)) {
    for (const key of Object.keys(data)) {
      if (key in form) {
        formData[key] = data[key];
      }
    }
  }
  return {
    type: ACCOUNTS_SET_ACCOUNT_INFO_FORM_DATA,
    data: formData
  };
}

export function getBills(accountIdOrNumber) {
  return (dispatch) => {
    return api_getAccountBills(accountIdOrNumber).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: ACCOUNTS_SET_BILLS, bills: result.data });
        } else {
          dispatch(defaultErrorFeedback());
        }
      }
    );
  };
}

export function setAccountSite(data) {
  return {
    type: ACCOUNTS_SET_SITES_SITE,
    data
  };
}

export function getCases(accountIdOrNumber, state = '0', from = null, to = null) {
  return (dispatch) => {
    return api_getAccountCases(accountIdOrNumber, state, from, to).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: ACCOUNTS_SET_CASES, cases: result.data });
        } else {
          dispatch(defaultErrorFeedback());
        }
      }
    );
  };
}

export function getSites(accountIdOrNumber, include = []) {
  return (dispatch) => {
    return api_getAccountSites(accountIdOrNumber, include).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: ACCOUNTS_SET_SITES, sites: result.data });
          return result.data
        } else {
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    );
  };
}

export function getPartnerships(accountIdOrNumber, include = []) {
  return (dispatch) => {
    return api_getAccountPartnerships(accountIdOrNumber, include).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: ACCOUNTS_SET_PARTNERSHIPS, partnerships: result.data });
        } else {
          dispatch(defaultErrorFeedback());
        }
      }
    );
  };
}

export function setAccountPartnership(data) {
  return {
    type: ACCOUNTS_PARTNERSHIPS_SET_PARTNERSHIP,
    data
  }
}

export function removeAccountPartnership(customerId) {
  return {
    type: ACCOUNTS_PARTNERSHIPS_REMOVE_PARTNERSHIP,
    customerId
  }
}

export function getConfigItems(accountIdOrNumber) {
  return (dispatch) => {
    return api_getAccountConfigItems(accountIdOrNumber).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: ACCOUNTS_SET_CONFIG_ITEMS, configItems: result.data });
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return null;
      }
    );
  };
}

export function getCircuits(accountIdOrNumber) {
  return (dispatch) => {
    return api_getAccountCircuits(accountIdOrNumber).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: ACCOUNTS_SET_CIRCUITS, circuits: result.data });
        } else {
          dispatch(defaultErrorFeedback());
        }
      }
    );
  };
}

export function getLogs(accountIdOrNumber) {
  return (dispatch) => {
    return api_getAccountLogs(accountIdOrNumber).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: ACCOUNTS_SET_LOGS, logs: result.data });
        } else {
          dispatch(defaultErrorFeedback());
        }
      }
    );
  };
}

export function setAccountDocument(documentData) {
  return {
    type: ACCOUNTS_DOCUMENTS_SET_DOCUMENT,
    data: documentData
  };
}

export function updateAccountDocument(documentData) {
  return {
    type: ACCOUNTS_DOCUMENTS_UPDATE_DOCUMENT,
    data: documentData
  };
}

export function getSignatures(account) {
  return (dispatch) => {
    return SignatureService.list(0, 250, [], [], account)
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: ACCOUNTS_SET_SIGNATURES,
            signatures: result.data.items
          });
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      });
  };
}

export function downloadCurrentSignatureDocument(signatureId) {
  return () => {
    return SignatureService.downloadCurrentSignatureDocument(signatureId)
      .then((result) => {
        if (result.status === 200) {
          let splitContentDisposition = result.headers['content-disposition'].split('=');
          fileDownload(result.data, splitContentDisposition[1]);
        }
      });
  };
}

export function downloadFinalSignatureDocument(signatureId) {
  return () => {
    return SignatureService.downloadFinalSignatureDocument(signatureId)
      .then((result) => {
        if (result.status === 200) {
          let splitContentDisposition = result.headers['content-disposition'].split('=');
          fileDownload(result.data, splitContentDisposition[1]);
        }
      });
  };
}

export function getDocuments(accountIdOrNumber) {

  return (dispatch) => api_getAccountDocuments(accountIdOrNumber).then(result => {
    if (result.status === 200) {
      dispatch({
        type: ACCOUNTS_SET_DOCUMENTS,
        documents: result.data
      });
      return true;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });

}

export function removeAccountDocument(documentId) {
  return {
    type: ACCOUNTS_DOCUMENTS_REMOVE_DOCUMENT,
    documentId
  };
}

export function setAccountConfigItem(data) {
  return {
    type: ACCOUNTS_SET_CONFIG_ITEMS_ITEM,
    data
  };
}

export function getEmulatorUrl(contactId) {
  return (dispatch) => {
    return api_getUserToken(contactId).then(
      (result) => {
        if (result.status === 200) {
          const url = `${process.env.REACT_APP_CUSTOMER_PORTAL_URL}/emulate?token=${result.data.access_token}&id_token=${result.data.id_token}`;
          return {url, portal: result.data.portal}
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    );
  };
}

export function dropAccountCache(accountId) {
  return (dispatch) => {
    return api_dropAccountCache(accountId).then(
      (result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Account caching started...'));
          return true;
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    );
  };
}

export function setContactsSearchString(search, contactTypes) {
  return {
    type: ACCOUNTS_CONTACTS_SET_SEARCH_STRING,
    search,
    contactTypes
  };
}

export function setServiceSearchString(search) {
  return {
    type: ACCOUNTS_SERVICE_SET_SEARCH_STRING,
    search
  };
}

export function setOrdersSearchString(search, optionSets) {
  return {
    type: ACCOUNTS_ORDERS_SET_SEARCH_STRING,
    search,
    optionSets
  };
}

export function setBillsSearchString(search) {
  return {
    type: ACCOUNTS_BILLS_SET_SEARCH_STRING,
    search
  };
}

export function getAccountServicesData(idOrNumber, allServices) {
  return (dispatch) => {
    return api_getAccountServicesData(idOrNumber, allServices).then(
      (result) => {
        if (result.status === 200) {
          return result.data
        } else {
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    );
  };
}

export function setAccountServicesService(id, service) {
  return {
    type: ACCOUNTS_SERVICES_SET_SERVICE,
    id,
    service
  };
}

export function getAccountOrdersData(idOrNumber) {
  return (dispatch) => {
    return api_getAccountOrdersData(idOrNumber).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: ACCOUNTS_SET_ORDERS, orders: result.data });
        } else {
          dispatch(defaultErrorFeedback());
        }
      }
    );
  };
}

export function setCasesSearchString(search, optionSets) {
  return {
    type: ACCOUNTS_CASES_SET_SEARCH_STRING,
    search,
    optionSets
  };
}

export function setSitesSearchString(search) {
  return {
    type: ACCOUNTS_SITES_SET_SEARCH_STRING,
    search
  };
}

export function setPartnershipSearchString(search, optionSets) {
  return {
    type: ACCOUNTS_PARTNERSHIPS_SET_SEARCH_STRING,
    search,
    optionSets
  };
}

export function setConfigItemsSearchString(search, optionSets) {
  return {
    type: ACCOUNTS_CONFIG_ITEM_SET_SEARCH_STRING,
    search,
    optionSets
  };
}

export function setCircuitSearchString(search) {
  return {
    type: ACCOUNTS_CIRCUITS_SET_SEARCH_STRING,
    search
  };
}

export function setDocumentSearchString(search, optionSets) {
  return {
    type: ACCOUNTS_DOCUMENTS_SET_SEARCH_STRING,
    search,
    optionSets
  };
}

export function setLogsSearchString(search, systemUsers) {
  return {
    type: ACCOUNTS_LOGS_SET_SEARCH_STRING,
    search,
    systemUsers
  };
}

export function updateAccountInfo(id, args) {
  return (dispatch) => {
    return api_updateAccountInfo(id, args)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch({ type: ACCOUNTS_SET_ACCOUNT, account: args });
            dispatch(successFeedback('Account updated!'));
            return true;
          }
          dispatch(defaultErrorFeedback());
          return false;
        }
      );
  };
}
