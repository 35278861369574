
import { defaultErrorFeedback } from './feedback';
import OrderService from '../utils/Order/OrderService';

export const ORDER_SET_ORDERS = 'ORDER_SET_ORDERS';
export const ORDER_RESET_ORDERS = 'ORDER_RESET_ORDERS';
export const ORDERS_REMOVE_ORDER_FROM_LIST = 'ORDERS_REMOVE_ORDER_FROM_LIST';
export const ORDERS_REPLACE_LIST = 'ORDERS_REPLACE_LIST';

function setOrders(orders){
  return {
    type: ORDER_SET_ORDERS,
    orders
  }
}

export function removeOrderFromList(order){
  return {
    type: ORDERS_REMOVE_ORDER_FROM_LIST,
    order
  }
}

export function replaceOrderFromList(order){
  return {
    type: ORDERS_REPLACE_LIST,
    order
  }
}

export function resetOrders(){
  return {
    type: ORDER_RESET_ORDERS
  }
}

export const getOrders = ({ template, search, searchFields, includes, withs } = {}) => {
  return (dispatch) => {
    return OrderService.list({ template, search, searchFields, includes, withs }).then((result) => {
      if (result.status === 200) {
        dispatch(setOrders(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
};
