import React from 'react';
import update from 'immutability-helper';

export const rackStyle1 = {
  stroke: '#737373',
  strokeWidth: 1,
  fill: '#D5D5D5'
};

export const equipColors = {
  Server: '#2ca02c',
  Core: '#1f77b4',
  CPE: '#2c4e95',
  MUX: '#9900ff',
  Switch: '#ff7f0e',
  PDU: '#4d4d4d',
  Panel: '#4df37a',
  NTE: '#550a4f',
};

export const componentColors = {
  carrierCircuit: '#1f77b4',
  crossConnect: '#2ca02c',
  patchLead: '#9900ff',
  wavelength: '#ff7f0e'
};

export const equips = {
  1: {
    line: {
      normal: {
        stroke: '#737373',
        strokeWidth: 1,
        fill: equipColors.Core
      },
      selected: {
        stroke: '#ff0000',
        strokeWidth: 2,
        fill: equipColors.Core
      },
      muted: {
        stroke: '#696969',
        strokeWidth: 1,
        opacity: 0.6,
        fill: equipColors.Core
      },
      highlighted: {
        stroke: '#4EC1E0',
        strokeWidth: 1,
        fill: equipColors.Core
      }
    },
    label: {
      normal: { fill: '#FFFFFF', fontFamily: 'verdana, sans-serif', fontSize: 10 },
      selected: { fill: '#FFFFFF', stroke: 'none', fontSize: 12 },
      muted: {
        fill: '#696969',
        stroke: 'none',
        fontSize: 9,
        opacity: 0.6
      }
    }
  },
  2: {
    line: {
      normal: {
        stroke: '#737373',
        strokeWidth: 1,
        fill: equipColors.CPE
      },
      selected: {
        stroke: '#ff0000',
        strokeWidth: 2,
        fill: equipColors.CPE
      },
      muted: {
        stroke: '#696969',
        strokeWidth: 1,
        opacity: 0.6,
        fill: equipColors.CPE
      },
      highlighted: {
        stroke: '#4EC1E0',
        strokeWidth: 1,
        fill: equipColors.CPE
      }
    },
    label: {
      normal: { fill: '#FFFFFF', fontFamily: 'verdana, sans-serif', fontSize: 10 },
      selected: { fill: '#FFFFFF', stroke: 'none', fontSize: 12 },
      muted: {
        fill: '#696969',
        stroke: 'none',
        fontSize: 9,
        opacity: 0.6
      }
    }
  },
  5: {
    line: {
      normal: {
        stroke: '#737373',
        strokeWidth: 1,
        fill: equipColors.MUX
      },
      selected: {
        stroke: '#ff0000',
        strokeWidth: 2,
        fill: equipColors.MUX
      },
      muted: {
        stroke: '#696969',
        strokeWidth: 1,
        opacity: 0.6,
        fill: equipColors.MUX
      },
      highlighted: {
        stroke: '#4EC1E0',
        strokeWidth: 1,
        fill: equipColors.MUX
      }
    },
    label: {
      normal: { fill: '#FFFFFF', fontFamily: 'verdana, sans-serif', fontSize: 10 },
      selected: { fill: '#FFFFFF', stroke: 'none', fontSize: 12 },
      muted: {
        fill: '#696969',
        stroke: 'none',
        fontSize: 9,
        opacity: 0.6
      }
    }
  },
  3: {
    line: {
      normal: {
        stroke: '#737373',
        strokeWidth: 1,
        fill: equipColors.Server
      },
      selected: {
        stroke: '#ff0000',
        strokeWidth: 2,
        fill: equipColors.Server
      },
      muted: {
        stroke: '#696969',
        strokeWidth: 1,
        opacity: 0.6,
        fill: equipColors.Server
      },
      highlighted: {
        stroke: '#4EC1E0',
        strokeWidth: 1,
        fill: equipColors.Server
      }
    },
    label: {
      normal: { fill: '#FFFFFF', fontFamily: 'verdana, sans-serif', fontSize: 10 },
      selected: { fill: '#FFFFFF', stroke: 'none', fontSize: 12 },
      muted: {
        fill: '#696969',
        stroke: 'none',
        fontSize: 9,
        opacity: 0.6
      }
    }
  },
  4: {
    line: {
      normal: {
        stroke: '#737373',
        strokeWidth: 1,
        fill: equipColors.Switch
      },
      selected: {
        stroke: '#ff0000',
        strokeWidth: 2,
        fill: equipColors.Switch
      },
      muted: {
        stroke: '#696969',
        strokeWidth: 1,
        opacity: 0.6,
        fill: equipColors.Switch
      },
      highlighted: {
        stroke: '#4EC1E0',
        strokeWidth: 1,
        fill: equipColors.Switch
      }
    },
    label: {
      normal: { fill: '#FFFFFF', fontFamily: 'verdana, sans-serif', fontSize: 10 },
      selected: { fill: '#FFFFFF', stroke: 'none', fontSize: 12 },
      muted: {
        fill: '#696969',
        stroke: 'none',
        fontSize: 9,
        opacity: 0.6
      }
    }
  },
  6: {
    line: {
      normal: {
        stroke: '#737373',
        strokeWidth: 1,
        fill: equipColors.PDU
      },
      selected: {
        stroke: '#ff0000',
        strokeWidth: 2,
        fill: equipColors.PDU
      },
      muted: {
        stroke: '#696969',
        strokeWidth: 1,
        opacity: 0.6,
        fill: equipColors.PDU
      },
      highlighted: {
        stroke: '#4EC1E0',
        strokeWidth: 1,
        fill: equipColors.PDU
      }
    },
    label: {
      normal: { fill: '#FFFFFF', fontFamily: 'verdana, sans-serif', fontSize: 10 },
      selected: { fill: '#FFFFFF', stroke: 'none', fontSize: 12 },
      muted: {
        fill: '#696969',
        stroke: 'none',
        fontSize: 9,
        opacity: 0.6
      }
    }
  },
  7: {
    line: {
      normal: {
        stroke: '#737373',
        strokeWidth: 1,
        fill: equipColors.Panel
      },
      selected: {
        stroke: '#ff0000',
        strokeWidth: 2,
        fill: equipColors.Panel
      },
      muted: {
        stroke: '#696969',
        strokeWidth: 1,
        opacity: 0.6,
        fill: equipColors.Panel
      },
      highlighted: {
        stroke: '#4EC1E0',
        strokeWidth: 1,
        fill: equipColors.Panel
      }
    },
    label: {
      normal: { fill: '#FFFFFF', fontFamily: 'verdana, sans-serif', fontSize: 10 },
      selected: { fill: '#FFFFFF', stroke: 'none', fontSize: 12 },
      muted: {
        fill: '#696969',
        stroke: 'none',
        fontSize: 9,
        opacity: 0.6
      }
    }
  },
  8: {
    line: {
      normal: {
        stroke: '#737373',
        strokeWidth: 1,
        fill: equipColors.NTE
      },
      selected: {
        stroke: '#ff0000',
        strokeWidth: 2,
        fill: equipColors.NTE
      },
      muted: {
        stroke: '#696969',
        strokeWidth: 1,
        opacity: 0.6,
        fill: equipColors.NTE
      },
      highlighted: {
        stroke: '#4EC1E0',
        strokeWidth: 1,
        fill: equipColors.NTE
      }
    },
    label: {
      normal: { fill: '#FFFFFF', fontFamily: 'verdana, sans-serif', fontSize: 10 },
      selected: { fill: '#FFFFFF', stroke: 'none', fontSize: 12 },
      muted: {
        fill: '#696969',
        stroke: 'none',
        fontSize: 9,
        opacity: 0.6
      }
    }
  }
};

export const endpointStyle = {
  node: {
    normal: { fill: 'none', stroke: '#DBDBDB', strokeWidth: 0 },
    selected: { fill: 'none', stroke: '#b1b1b1', strokeWidth: 6 },
    muted: {
      fill: 'none',
      stroke: '#DBDBDB',
      strokeWidth: 2,
      opacity: 0.6,
      cursor: 'pointer'
    },
    highlighted: { stroke: '#b1b1b1', strokeWidth: 4, fill: 'none' }
  },
  label: {
    normal: { fill: '#9D9D9D', fontSize: 10, fontFamily: 'verdana, sans-serif' },
    selected: { fill: '#333', stroke: 'none', fontSize: 11 },
    muted: {
      fill: '#696969',
      stroke: 'none',
      fontSize: 9,
      opacity: 0.6
    },
    highlighted: { fill: '#9D9D9D', fontSize: 10, fontFamily: 'verdana, sans-serif' }
  }
};

const baseStyle = {
  style: {
    node: {
      normal: {
        stroke: '#737373',
        strokeWidth: 4,
        fill: 'none'
      },
      highlighted: {
        stroke: '#b1b1b1',
        strokeWidth: 4,
        fill: '#b1b1b1'
      },
      muted: { fill: '#B0B0B0', stroke: '#9E9E9E', opacity: 0.6, cursor: 'pointer' }
    },
    line: {
      normal: {
        stroke: '#4b4b4b',
        strokeWidth: 3,
        fill: 'none'
      },
      highlighted: {
        stroke: '#4EC1E0',
        strokeWidth: 4,
        fill: '#4EC1E0'
      },
      muted: { fill: '#B0B0B0', stroke: '#9E9E9E', opacity: 0.6, cursor: 'pointer' }
    },
    label: {
      normal: {
        fill: '#9D9D9D',
        fontFamily: 'verdana, sans-serif',
        fontSize: 10
      },
      selected: {
        fill: '#9D9D9D',
        fontFamily: 'verdana, sans-serif',
        fontSize: 10
      },
      muted: { fill: '#696969',
        stroke: 'none',
        fontSize: 8,
        opacity: 0.6 }
    }
  },
  lineShape: 'linear'
};

export function getConnectionStyle(type) {
  if (type === 'node') {
    return update(baseStyle, {
      lineShape: { $set: 'square' },
      size: { $set: 36 },
      squareWidth: { $set: 36 },
      style: {
        line: {
          normal: { $set: {
            stroke: '#737373',
            strokeWidth: 1,
            fill: '#D5D5D5'
          }
          }
        }
      }
    });
  }
  if (type === 'mux') {
    return update(baseStyle, {
      lineShape: { $set: 'square' },
      size: { $set: 20 },
      squareWidth: { $set: 20 },
      style: {
        line: {
          normal: { $set: {
            stroke: '#737373',
            strokeWidth: 1,
            fill: '#444444'
          }
          }
        }
      }
    });
  }
  if (type === 'nni') {
    return update(baseStyle, {
      lineShape: { $set: 'square' },
      size: { $set: 14 },
      squareWidth: { $set: 60 },
      noNavigate: { $set: true },
      style: {
        line: {
          normal: { $set: {
            stroke: '#333F48',
            strokeWidth: 1,
            fill: '#333F48'
          }
          }
        }
      }
    });
  }
  if (componentColors[type] !== undefined) {
    return update(baseStyle, {
      style: {
        line: {
          normal: {
            stroke: { $set: componentColors[type] }
          }
        }
      }
    });
  }
  return baseStyle;
}

export const pattern = (
    <pattern id="Pattern" width="4" height="4" patternUnits="userSpaceOnUse">
        <line stroke="#EAEAEA" strokeWidth="20px" y2="4" />
        <path
            d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
            style={{ stroke: '#a0a0a0', strokeWidth: 1 }}
        />
    </pattern>
);

export const backStyle = { fill: '#b8b8b8' };
