import {useDispatch, useSelector} from "react-redux";
import {Row, Button, Card, CardBody, CardHeader, Col, Alert} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {useCodeMirror} from '@uiw/react-codemirror';
import {materialDark} from '@uiw/codemirror-theme-material';
import {json} from '@codemirror/lang-json'
import {configTemplateRequest} from "../../utils/ConfigTemplate";
import {errorFeedback, successFeedback} from "../../actions/feedback";
import {SET_SCHEMA} from "../../actions/configTemplate";
import {checkPermission} from "../../utils/Auth/AuthService";

const SchemaCard = () => {
    const [data, setData] = useState(undefined);
    const [schemaError, setSchemaError] = useState(false);
    const [schemaMsg, setSchemaMsg] = useState(false);
    const [buttonColor, setButtonColor] = useState("info");
    const editor = useRef();
    const auth = useSelector(state => state.authenticationState);
    const {activeEntry} = useSelector(state => state.configTemplate);
    const {schema} = useSelector(state => state.configTemplate.activeEntry);
    const dispatch = useDispatch();

    useEffect(() => (
        setData(prettifySchema(schema))
    ), [schema])

    const handleBeforeChange = data => setData(data)

    useCodeMirror({
        container: editor.current,
        extensions: [json()],
        theme: materialDark,
        basicSetup: {highlightActiveLine: false, highlightActiveLineGutter: false},
        value: data || "",
        onChange: handleBeforeChange,
    });

    const toggleSaveButtonColor = () => {
        setButtonColor("danger");
        setTimeout(() => setButtonColor("info"), 500);
    }
    const handleSchemaSave = () => {
        toggleSaveButtonColor();

        configTemplateRequest(
            activeEntry.id,
            `configtemplate/${activeEntry.id}/schema`,
            'put',
            data
        ).then(r => {
            if (r.status === 201) {
                dispatch(successFeedback('Saved schema'));
                dispatch({type: SET_SCHEMA, data: JSON.parse(data)});
                setSchemaError(false);
                setSchemaMsg("Schema updated. Remember to update template!");
                setTimeout(() => setSchemaMsg(false), 3000);
            } else if (r.response.status === 422) {
                setSchemaError(r.response.data.message)
                dispatch(errorFeedback('Schema save failed'));
            }
        })
    }

    const prettifySchema = schema => {
        try {
            return JSON.stringify(schema, null, 4)
        } catch {
            return schema
        }
    }

    return (
        <Card className='card-accent-warning fadeIn animated'>
            <CardHeader style={{paddingBottom: '8px'}}>
                <Row className="justify-content-center align-content-center align-items-center">
                    <Col md={6}>
                        <h5 className="m-0 mirror d-inline">Schema</h5>
                    </Col>
                    <Col>
                        {
                            checkPermission("ConfigTemplates", auth.account.permissions)
                                ?
                                <Button
                                    size='sm'
                                    style={{top: 50, right: 30, zIndex: 10, height: 33, width: 33}}
                                    color={buttonColor}
                                    className='m-1 float-right'
                                    onClick={() => handleSchemaSave()}
                                ><i className="fa fa-save"/>
                                </Button>
                                : null
                        }
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <div className='h-100 font-sm animated fadeIn'
                     ref={editor}>
                </div>
                {
                    schemaError ? <Alert color="warning">{schemaError}</Alert> : null
                }
                {
                    schemaMsg && schemaError !== true ? <Alert color="info">{schemaMsg}</Alert> : null
                }
            </CardBody>
        </Card>
    )
}


export default SchemaCard