import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import FilteredTable from '../../../../components/FilteredTable';
import Block from '../Block';
import { GenericModal } from '../../../../components/Modals/GenericModal';
import { ButtonIcon } from '../../../../components/ButtonIcon';

const Blocks = ({ loading, blocks, domain, onCreated, onUpdated, onDeleted, canAddAndDelete }) => {

  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const columns = [
    {
      filterable: true,
      sortable: true,
      Header: 'ID',
      accessor: 'id',
      width: 100
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Name',
      accessor: 'name'
    },
    {
      filterable: true,
      sortable: false,
      Header: 'Network Address/Mask',
      id: 'networkAddress/Mask',
      accessor: row => `${row.networkAddress}/${row.mask}`
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 50,
      Cell: props => (
        <Link to={'/sdb/ipam/blocks/' + props.value}><i className="icon-arrow-right"/></Link>
      )
    }
  ];
  return (
    <>
      <Row>
        <Col className={'d-flex'}>
          <Card className="w-100">
            <CardHeader>
              <Row>
                <Col>Blocks</Col>
                <Col className="text-right">
                  {canAddAndDelete ? (
                    <ButtonIcon
                      icon="fa fa-plus"
                      tooltip="Add block"
                      onClick={() => setBlockModalOpen('new')}/>
                  ) : ''}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FilteredTable
                columns={columns}
                data={blocks}
                minRows={1}
                filterable={false}
                loading={loading}
                hideFilter={true}
                showPagination={blocks.length > 10}
                onRowClick={block => setBlockModalOpen(block.id)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <GenericModal
        show={blockModalOpen}
        toggle={() => setBlockModalOpen(false)}
        title="Block"
        className="modal-lg"
      >
        <Block
          id={blockModalOpen}
          domain={domain}
          hideSubnets={true}
          onSubmitted={() => setBlockModalOpen(false)}
          onCreated={(result) => {
            setBlockModalOpen(result.id);
            onCreated(result);
          }}
          onUpdated={(result) => {
            setBlockModalOpen(result.id);
            onUpdated(result);
          }}
          onDeleted={(id) => {
            setBlockModalOpen(false);
            if (onDeleted) {
              onDeleted(id);
            }
          }}
        />
      </GenericModal>
    </>
  );
};

export default Blocks;