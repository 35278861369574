import { apiRequest } from '../Api/ApiRequest';

export function api_getDocumentOptionSets() {
  return apiRequest({
    url: '/documents/optionSets',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
