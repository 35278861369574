import { OpticalSystemService } from '../utils/ServiceDB/OpticalSystem';
import { defaultErrorFeedback } from './feedback';

export const OPTICAL_SYSTEMS_SET_DATA = 'OPTICAL_SYSTEMS_SET_DATA';

function setOpticalSystems(deviceModels) {
  return {
    type: OPTICAL_SYSTEMS_SET_DATA,
    data: deviceModels
  };
}

export function getOpticalSystems(sorted = [], filtered = [], include = []) {
  return (dispatch) => {
    return OpticalSystemService.getOpticalSystems(sorted, filtered, include).then((result) => {
      if (result.status === 200) {
        dispatch(setOpticalSystems(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}