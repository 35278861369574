import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import {
  DropdownMenu,
  DropdownToggle,
  Dropdown
} from 'reactstrap';
import { connect } from 'react-redux';
import Avatar from '../Avatar';
import {msalInstance} from "../../index";
import { logout } from '../../actions/user';

const propTypes = {
  notif: PropTypes.bool,
  accnt: PropTypes.bool,
  tasks: PropTypes.bool,
  mssgs: PropTypes.bool
};
const defaultProps = {
  notif: false,
  accnt: false,
  tasks: false,
  mssgs: false
};

class HeaderDropdown extends Component {
    static contextTypes = {
      router: PropTypes.shape({
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
          replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
      }).isRequired
    };

    constructor(props) {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.state = {
        dropdownOpen: false
      };
    }

    toggle() {
      this.setState({
        dropdownOpen: !this.state.dropdownOpen
      });
    }

    logout = () =>{
      this.props.dispatch(logout())
      msalInstance.logoutRedirect({
        account: msalInstance.getAllAccounts()[0],
        postLogoutRedirectUri: '/'
      });
    }

    dropAccnt() {
      const { account } = this.props.auth;
      return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <Avatar name={account.first_name + ' ' + account.last_name} email={account.email ? account.email.toLowerCase() : ''} size={32} className="img-avatar"/>
        </DropdownToggle>
        <DropdownMenu right className="logindropdown">
          <div className="acinfo">
              <Avatar name={account.first_name + ' ' + account.last_name} email={account.email ? account.email.toLowerCase() : ''} size={60} className="img-avatar"/>
              <p>{account.email}</p>
              <p><Button color="secondary" onClick={this.logout}>Logout</Button></p>
          </div>
        </DropdownMenu>
      </Dropdown>
      );
    }

    render() {
      const { accnt } = this.props;
      return (
        accnt ? this.dropAccnt() : null
      );
    }
}

HeaderDropdown.propTypes = propTypes;
HeaderDropdown.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    auth: state.authenticationState
  };
}

export default connect(mapStateToProps)(HeaderDropdown);
