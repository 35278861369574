import { combineReducers } from 'redux';
import helpers from './helpers';
import wipItem from './wipItem';
import wipReport from './wipReport';
import dialogs from './dialogs';
import product from './product';
import lineItem from './lineItem';
import socketioStatus from './socketioStatus';
import socketioToast from './socketioToast';
import socketioProgress from './socketioProgress';
import authenticationState from './authentication';
import { persistConfig } from '../configureStore';
import { purgeStoredState } from 'redux-persist';
import billingPreview from './billingPreview';
import billingRun from './billingRun';
import directDebits from './directDebits';
import zabbixAlarms from './alarms';
import { billingRunBill } from './billingRunBill';
import billingNewLineItems from './billingNewLineItems';
import appVersion from './appVersion';
import realtimeTraffic from './realtimeTraffic';
import accounts from './accounts';
import jsnapy from './jsnapy';
import JTI from './JTI';
import billingQueue from './billingQueue';
import billing from './billing';
import billingRuns from './billingRuns';
import billingBooth from './billingBooth';
import BGP from './BGP';
import signatures from './signatures';
import multiSearch from './multiSearch';
import radius from './radius';
import queues from './queues';
import circuit from './circuit';
import caseReducer from './case';
import leadTimesReport from './leadTimesReport';
import contact from './contact';
import order from './order';
import { opportunities } from './opportunities';
import { opportunity } from './opportunity';
import { quotes } from './quotes';
import { quote } from './quote';
import site from './site';
import activity from './activity';
import configItem from './configItem';
import cases from './cases';
import LogTail from './logtail';
import { orders } from './orders';
import logs from './logs';
import shifts from './shifts';
import { pricingTool } from './pricingTool';
import { task } from './task';
import { pricingToolQuote } from './pricingToolQuote';
import accountReports from './accountReports';
import configTemplate from './configTemplate';
import document from './document';
import { leads } from './leads';
import { lead } from './lead';
import { partnership } from './partnership';
import discovery from './discovery';
import { priceLists } from './priceLists';
import { priceList } from './priceList';
import netapi from './netapi';
import { productTemplates } from './productTemplates';
import { pricingTypes } from './pricingTypes';
import connection from './connection';
import connections from './connections';
import deviceDeployment from './deviceDeployment';
import deviceDeployments from './deviceDeployments';
import deviceModel from './deviceModel';
import deviceModels from './deviceModels';
import stockItem from './stockItem';
import stock from './stock';
import port from './port';
import nni from './nni';
import nnis from './nnis';
import vendors from './vendors';
import rack from './rack';
import racks from './racks';
import opticalSystems from './opticalSystems';
import opticalSystem from './opticalSystem';
import vendor from './vendor';
import carrierCircuit from './carrierCircuit';
import carrierCircuits from './carrierCircuits';
import shadowServer from "./shadowServer";
import license from "./ServiceDB/license";
import licenses from "./ServiceDB/licenses";
import globalSearch from './globalSearch';
import ipamDomain from "./ServiceDB/Ipam/domain";
import ipamDomains from "./ServiceDB/Ipam/domains";
import ipamBlock from "./ServiceDB/Ipam/block";
import ipamBlocks from "./ServiceDB/Ipam/blocks";
import ipamSubnet from "./ServiceDB/Ipam/subnet";
import ipamAddress from "./ServiceDB/Ipam/address";
import { productSLAs } from './productSLAs';
import { productGroups } from './productGroups';
import logicalService from './ServiceDB/logicalService';
import logicalServices from './ServiceDB/logicalServices';
import logicalServiceType from './ServiceDB/logicalServiceType';
import { breadcrumbs } from './breadcrumbs';
import { tasks } from './tasks';
import { campaigns } from './campaigns';
import account from './account';
import { billTemplates } from './billTemplates';
import { billTemplate } from './billTemplate';
import { app } from './app';
import { billTemplateGroup } from './billTemplateGroup';
import { creditRequest } from './creditRequest';
import socketioPaste from "./socketioPaste";
import crossConnects from './crossConnects';
import crossConnect from './crossConnect';

const appReducer = combineReducers({
  account,
  accounts,
  accountReports,
  activity,
  app,
  appVersion,
  authenticationState,
  BGP,
  billingPreview,
  billing,
  billingBooth,
  billingRun,
  billingRuns,
  billingRunBill,
  billingNewLineItems,
  billingQueue,
  billTemplate,
  billTemplates,
  billTemplateGroup,
  breadcrumbs,
  campaigns,
  theCase: caseReducer,
  cases,
  carrierCircuit,
  carrierCircuits,
  circuit,
  configItem,
  configTemplate,
  connection,
  connections,
  contact,
  creditRequest,
  crossConnect,
  crossConnects,
  deviceDeployment,
  deviceDeployments,
  deviceModel,
  deviceModels,
  dialogs,
  directDebits,
  discovery,
  document,
  globalSearch,
  helpers,
  leadTimesReport,
  ipamBlock,
  ipamBlocks,
  ipamDomain,
  ipamDomains,
  ipamSubnet,
  ipamAddress,
  jsnapy,
  JTI,
  lead,
  leads,
  license,
  licenses,
  lineItem,
  logicalService,
  logicalServices,
  logicalServiceType,
  LogTail,
  logs,
  multiSearch,
  netapi,
  nni,
  nnis,
  opportunities,
  opportunity,
  opticalSystem,
  opticalSystems,
  order,
  orders,
  partnership,
  port,
  priceList,
  priceLists,
  pricingTool,
  pricingToolQuote,
  productGroups,
  pricingTypes,
  product,
  productSLAs,
  productTemplates,
  queues,
  quote,
  quotes,
  rack,
  racks,
  radius,
  realtimeTraffic,
  shadowServer,
  shifts,
  signatures,
  site,
  socketioStatus,
  socketioToast,
  socketioProgress,
  socketioPaste,
  stockItem: stockItem,
  stock: stock,
  task,
  tasks,
  vendor,
  vendors,
  wipItem,
  wipReport,
  zabbixAlarms,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    purgeStoredState(persistConfig);
    state = undefined;
    localStorage.removeItem('hso-api-token');
  }
  return appReducer(state, action);
};

export default rootReducer;
