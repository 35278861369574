import { api_getBillingRuns } from '../utils/Billing/BillingService';
import { defaultErrorFeedback } from './feedback';

export const BILLING_RUN_TOGGLE_RUNS_LOADING = 'BILLING_RUN_TOGGLE_RUNS_LOADING';
export const BILLING_RUNS_SET_RUNS = 'BILLING_RUNS_SET_RUNS';
export const BILLING_RUNS_ADD_RUN = 'BILLING_RUNS_ADD_RUN';
export const BILLING_RUNS_REMOVE_RUN = 'BILLING_RUNS_REMOVE_RUN';

export function toggleBillingRunsLoading() {
  return {
    type: BILLING_RUN_TOGGLE_RUNS_LOADING
  };
}

function setBillingRuns(runs) {
  return {
    type: BILLING_RUNS_SET_RUNS,
    runs
  };
}

export function removeBillingRun(id) {
  return {
    type: BILLING_RUNS_REMOVE_RUN,
    id
  };
}

export function getBillingRuns(active = false) {
  return (dispatch) =>{
    return api_getBillingRuns(active).then((result) => {
      if (result.status === 200) {
        dispatch(setBillingRuns(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}
