import { defaultErrorFeedback, successFeedback } from './feedback';
import { api_getBill, api_rerunBill, api_updateBill } from '../utils/Billing/BillingService';
import { moveBillToExcluded, moveBillToIncluded, removeAccountFromRun } from './billingRun';

export const BILLING_RUN_BILL_SET_BILL = 'BILLING_RUN_BILL_SET_BILL';
export const BILLING_RUN_BILL_SET_LOADING = 'BILLING_RUN_BILL_SET_LOADING';
export const BILLING_RUN_BILL_TOGGLE_SENDING = 'BILLING_RUN_BILL_TOGGLE_SENDING';
export const BILLING_RUN_BILL_TOGGLE_CREDIT_MODAL = 'BILLING_RUN_BILL_TOGGLE_CREDIT_MODAL';
export const BILLING_RUN_BILL_UPDATE_CREDIT = 'BILLING_RUN_BILL_UPDATE_CREDIT';
export const BILLING_RUN_BILL_SET_CREDIT_ERROR = 'BILLING_RUN_BILL_SET_CREDIT_ERROR';
export const BILLING_RUN_BILL_TOGGLE_CREDIT_PROCESSING = 'BILLING_RUN_BILL_TOGGLE_CREDIT_PROCESSING';
export const BILLING_RUN_BILL_TOGGLE_EDIT_MODAL = 'BILLING_RUN_BILL_TOGGLE_EDIT_MODAL';
export const BILLING_RUN_BILL_UPDATE_EDIT_FIELD = 'BILLING_RUN_BILL_UPDATE_EDIT_FIELD';
export const BILLING_RUN_BILL_SET_EDIT_ERRORS = 'BILLING_RUN_BILL_SET_EDIT_ERRORS';
export const BILLING_RUN_BILL_TOGGLE_EDIT_PROCESSING = 'BILLING_RUN_BILL_TOGGLE_EDIT_PROCESSING';
export const BILLING_RUN_BILL_SET_PAID = 'BILLING_RUN_BILL_SET_PAID'

export function updateBillEditField(field) {
  return {
    type: BILLING_RUN_BILL_UPDATE_EDIT_FIELD,
    field
  };
}
export function setBillEditErrors(errors) {
  return {
    type: BILLING_RUN_BILL_SET_EDIT_ERRORS,
    errors
  };
}

export function toggleBillEditProcessing() {
  return {
    type: BILLING_RUN_BILL_TOGGLE_EDIT_PROCESSING
  };
}

export function getBill(runId, billId) {
  return (dispatch) => {
    return api_getBill(runId, billId).then((result) => {
      if (result.status === 200) {
        dispatch({ type: BILLING_RUN_BILL_SET_BILL, bill: result.data });
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function rerunBill(runId, billId) {
  return (dispatch) => {
    return api_rerunBill(runId, billId).then((result) => {
      if (result.status === 200) {
        dispatch(removeAccountFromRun(billId));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function toggleBillActiveState(runId, billId, active) {
  return (dispatch) => {
    return api_updateBill(billId, { active }).then((result) => {
      if (result.status === 200) {
        if (active) {
          dispatch(moveBillToIncluded(billId));
        } else {
          dispatch(moveBillToExcluded(billId));
        }
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function applyBillCredit(billId, credit) {
  return (dispatch) => {
    return api_updateBill(billId, { credit }).then((result) => {
      if (result.status === 200) {
        return true;
      }

      dispatch({ type: BILLING_RUN_BILL_SET_CREDIT_ERROR, error: result.data.error || 'There was an error with your request' });
      return false;
    });
  };
}

export function updateBill(billId, args) {
  return (dispatch) => {
    return api_updateBill(billId, args).then((result) => {
      if (result.status === 200) {
        dispatch(successFeedback('Bill updated!'));
        return true;
      }

      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}
