import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

const BillTemplateService = {
  get: (id) => {
    return apiRequest({
      url: `/billing/templates/${id}?include=company,billTemplateGroups,directDebit,products&with=company;billTemplateGroups;directDebit`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  list: () => {
    const query = {
      include : 'company',
      with:'company'
    }
    return apiRequest({
      url: `/billing/templates${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  update: (id, data) => {
    const query = {
      include : ['company','billTemplateGroups','directDebit','products'],
      with:'company;billTemplateGroups;directDebit'
    }
    return apiRequest({
      url: `/billing/templates/${id}${queryStringFromFields(query)}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  create: (data) => {
    const query = {
      include : ['company','billTemplateGroups','directDebit','products'],
      with:'company;billTemplateGroups;directDebit'
    }
    return apiRequest({
      url: `/billing/templates${queryStringFromFields(query)}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  deactivate: (id) => {
    const query = {
      include : ['company','billTemplateGroups','directDebit','products'],
      with:'company;billTemplateGroups;directDebit'
    }
    return apiRequest({
      url: `/billing/templates/${id}${queryStringFromFields(query)}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  activate: (id) => {
    const query = {
      include : ['company','billTemplateGroups','directDebit','products'],
      with:'company;billTemplateGroups;directDebit'
    }
    return apiRequest({
      url: `/billing/templates/${id}/restore${queryStringFromFields(query)}`,
      method: 'put'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}

export default BillTemplateService