import {
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel
} from '@tanstack/react-table'
import React, { useEffect, useMemo } from 'react';
import { Alert, Col, Input, InputGroup, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';
import { nanoid } from 'nanoid';
import ClickableRow from './ClickableRow';

const FilteredTableV2 = ({
  columns,
  data,
  size,
  striped,
  extraButtons,
  hideFilter,
  withExport,
  placeholder,
  showPagination,
  filterable,
  onRowClick,
  sortable,
  defaultSorted,
  noDataText,
  hideNoData,
  showPageSizeOptions,
  pageSizeOptions,
  withFilter,
  withServerSideFilter,
  withServerSideSorting,
  pages,
  pageSize,
  defaultPageSize,
  onFetchData,
  onPaginationChange,
  pagination,
  multiSort,
  loading,
  minRows,
  autoResetPageIndex,
  withDeactivatedRow,
  defaultFilterValues,
  columnFilters,
  columnFilterValue,
  onSorting
}) => {

  const [sorting, setSorting] = React.useState(defaultSorted ? defaultSorted : [])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [serverSideFilter, setServerSideFilter] = React.useState('')

  useEffect(() => {
    if(onSorting){
      onSorting(sorting, serverSideFilter ?? globalFilter)
    }
  }, [sorting])

  const getTableOptions = () => {
    let options = {
      data,
      columns,
      initialState: {
        columnFilters: defaultFilterValues ?? []
      },
      state: {
        sorting,
        globalFilter
      },
      filterable: filterable || false,
      onSortingChange: setSorting,
      onGlobalFilterChange: setGlobalFilter,
      enableSorting: sortable !== undefined ? Boolean(sortable) : true,
      enableMultiSort:  multiSort !== undefined ? Boolean(multiSort) : false,
      enableGlobalFilter: onFetchData === undefined,
      enableHiding: true,
      globalFilterFn: withFilter,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      autoResetPageIndex: autoResetPageIndex,
      manualSorting: Boolean(withServerSideSorting),
      columnResizeMode: 'onChange',
      columnResizeDirection: 'rtl',
      enableSortingRemoval: false
    }

    if(onFetchData){
      options.manualPagination = true;
      options.onPaginationChange = onPaginationChange;
      options.state = {...options.state, pagination}
      options.pageCount = pages
    }

    return options
  }
  const table = useReactTable(getTableOptions())
  const rows = useMemo(
    () => table.getRowModel().rows.map(row => (
      <ClickableRow
        key={row.id}
        id={row.id}
        row={row}
        onRowClick={onRowClick ? (event) => onRowClick(row.original, event) : undefined}
        withDeactivatedRow={withDeactivatedRow}
      >
        {row.getVisibleCells().map(cell => (
          <td
            key={cell.id}
            {...{
              style: {
                border: 'bottom',
                whiteSpace: 'unset',
                overflow: 'visible',
                width: cell.column.getSize()
              }
            }
          }

          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </ClickableRow>
    )), [data, sorting, globalFilter, columnFilterValue, table.getState().pagination]
  )

  const filteringColumn = useMemo(() => {
    return table.getAllColumns().filter((col) =>  columnFilters?.includes(col.id))[0];
  }, [columnFilters]);

  useEffect(() => {
    if (filteringColumn) {
      filteringColumn.setFilterValue(columnFilterValue);
    }
  }, [filteringColumn, columnFilterValue]);

  useEffect(() => {
    if(pageSize && pageSize !== pagination.pageSize){
      table.setPageSize(pageSize);
    }
  }, [pageSize])

  useEffect(() => {
    if(defaultPageSize){
      table.setPageSize(defaultPageSize);
    }
  }, [defaultPageSize])

  const hideGlobalFilterInput = () => {
    return withServerSideFilter !== undefined || withFilter === undefined || hideFilter
  }


  const hideServerSideFilterInput = () => {
    return withFilter !== undefined || withServerSideFilter === undefined || hideFilter
  }

  const addEmptyRows = () => {
    const rows = [];
    let rowCount = (minRows || 0 ) - table.getRowModel()?.rows.length;
    if(rowCount > 0){
      for(rowCount; rowCount > 0; rowCount--){
        rows.push(
          <tr key={nanoid()}>
            {table.getVisibleFlatColumns().map(() => {
              return <td key={nanoid()}><span className="text-hide">hidden</span></td>
            })}
        </tr>)
      }
    }
    return rows
  }

  const getPageOptions = (index) => {
    let options ;
    if(index === 1){
      options = [1,2,3];
    }else if(index === table.getPageCount()){
      options = [index - 2,index -1, index];
    }else{
      options = [index -1, index, index + 1]
    }
    return options.filter(pageNumber => table.getPageOptions() && table.getPageOptions().includes(pageNumber - 1))
  }

  const getPageSizeOptions = () => {
    return pageSizeOptions || [10, 20, 50, 100, 200, 500]
  }

  const noData = () => {
    return !hideNoData && (!loading && isEmpty(data) ) || (globalFilter && isEmpty(table.getFilteredRowModel().rows))
  }
  return (
    <>
      <Row className={classnames('d-flex', {'mb-2' : !hideFilter})}>
        {!hideGlobalFilterInput() ? (
          <Col md={4}>
            <InputGroup>
              <Input
                value={globalFilter ?? ''}
                onChange={event => setGlobalFilter(String(event.target.value))}
                placeholder={placeholder || 'Search...'}
              />
            </InputGroup>
          </Col>
        ) : ''}
        {!hideServerSideFilterInput() ? (
          <Col md={4}>
            <InputGroup>
              <Input
                value={serverSideFilter ?? ''}
                onChange={event => {
                  setServerSideFilter(String(event.target.value))
                  withServerSideFilter(String(event.target.value))
                }}
                placeholder={placeholder || 'Search...'}
              />
            </InputGroup>
          </Col>
        ) : ''}
        {extraButtons ? (
          <Col className='d-flex justify-content-end'>
            {extraButtons.map((button, index) => <div key={`extra-btn-${index}`}>{button}</div>)}
          </Col>
        ) : ''}
      </Row>
      <Row>
        <Col>
          {noData() &&
            <Row
              className='m-0'
              style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(255, 255, 255, 0.8)'
            }}>
              <Col md={3}><Alert className="text-center" color={'warning'}>{noDataText || 'No Data'}</Alert></Col>
            </Row>
          }
          <Table
            hover={table.getRowModel()?.rows.length !== 0}
            size={size}
            responsive
            striped={striped}
          >
            <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>

                {headerGroup.headers.map(header => {
                  return (
                    <th
                      className='border-top-0'
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                        style: {
                          width: header.getSize()
                        }
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: classnames(
                                {
                                  hoverPointer : header.column.getCanSort()
                                }
                              ),
                              onClick: header.column.getToggleSortingHandler(),
                              onDoubleClick: () => header.column.resetSize(),
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <span className={'ml-2 text-info'}><i className={'fa fa-arrow-up'}/></span>,
                              desc:  <span className={'ml-2 text-info'}><i className={'fa fa-arrow-down'}/></span>,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
            </thead>
            <tbody style={{position: "relative"}}>
            {rows}
            {addEmptyRows()}
            </tbody>
          </Table>
        </Col>
      </Row>
      {showPagination && (!globalFilter || (globalFilter && table.getFilteredRowModel().rows.length > 0)) &&
        <Row>
          <Col className="d-flex flex-row">
            <div>
              <Pagination>
                <PaginationItem disabled={!table.getCanPreviousPage()}>
                  <PaginationLink first onClick={
                    () => {
                      table.setPageIndex(0)
                      if(onFetchData && table.getCanPreviousPage()){
                        onFetchData(1, pagination.pageSize || 20, serverSideFilter, sorting)
                      }

                    }
                  }/>
                </PaginationItem>
                <PaginationItem disabled={!table.getCanPreviousPage()}>
                  <PaginationLink previous onClick={() => {
                    table.previousPage()
                    if(onFetchData && table.getCanPreviousPage()){
                      onFetchData(table.getState().pagination.pageIndex, pagination.pageSize || 20, serverSideFilter, sorting)
                    }
                  }}/>
                </PaginationItem>
                {getPageOptions(table.getState().pagination.pageIndex + 1)
                  .map(pageNumber => {
                    return (
                      <PaginationItem key={`key-${pageNumber}`} active={pageNumber === table.getState().pagination.pageIndex + 1}>
                        <PaginationLink onClick={
                          () => {
                            table.setPageIndex(pageNumber - 1)
                            if(onFetchData){
                              onFetchData(pageNumber, pagination.pageSize || 20, serverSideFilter, sorting)
                            }
                          }
                        }>
                          {`${pageNumber}`}
                        </PaginationLink>
                      </PaginationItem>
                    )
                  })}
                <PaginationItem disabled={!table.getCanNextPage()}>
                  <PaginationLink next onClick={() => {
                    table.nextPage()
                    if(onFetchData && table.getCanNextPage()){
                      onFetchData(table.getState().pagination.pageIndex + 2, pagination.pageSize || 20, serverSideFilter, sorting)
                    }
                  }}/>
                </PaginationItem>
                <PaginationItem disabled={!table.getCanNextPage()}>
                  <PaginationLink last onClick={() => {
                    table.setPageIndex(table.getPageCount() - 1)
                    if(onFetchData && table.getCanNextPage()){
                      onFetchData(table.getPageCount(), pagination.pageSize || 20, serverSideFilter, sorting)
                    }
                  }}/>
                </PaginationItem>
              </Pagination>
              <p>
                Page <span className='font-weight-bold'>{table.getState().pagination.pageIndex + 1}</span> of {table.getPageCount()}
              </p>
            </div>
            {(showPageSizeOptions === undefined || showPageSizeOptions === true) &&
              <div className={'ml-auto d-flex flex-row'}>
                <Input
                  type="select"
                  value={table.getState().pagination.pageSize}
                  onChange={e => {
                    table.setPageSize(Number(e.target.value))
                    if(onFetchData){
                      onFetchData(1, e.target.value, serverSideFilter, sorting)
                      table.setPageIndex(0)
                    }
                  }}
                >
                  {getPageSizeOptions().map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize} rows
                    </option>
                  ))}
                </Input>
              </div>
            }
          </Col>
        </Row>
      }
    </>

  )

}

export default FilteredTableV2;
