import React, { useEffect, useState } from 'react';
import {Card, CardBody } from 'reactstrap';
import { getRacks } from '../../../actions/racks';
import { connect } from 'react-redux';
import debounce from 'debounce-promise';
import LoadingOverlay from '../../../components/LoadingOverlay';
import RacksTable from '../../../components/Tables/Racks';

const Racks = ({ dispatch, racks, table }) => {

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }

  const fetchData = (page, size, searchString, sorting) =>{
    toggleLoading()
    dispatch(getRacks(page, size, searchString, sorting)).then(() => toggleLoading())
  }

  useEffect(() => {
    fetchData(page, pageSize, '', [{id: 'updatedAt', desc: true}]);
  }, []);

  const debounced = debounce((page, size, searchString, sorting) => fetchData(page, size, searchString, sorting), 500);

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <RacksTable
              racks={racks}
              fetchData={(page, size, searchString, sorting) => debounced(page, size, searchString, sorting)}
              table={table}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  )
};

function mapStateToProps({ racks, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    racks: racks.racks,
    table: racks.table
  };
}

export default connect(mapStateToProps)(Racks);