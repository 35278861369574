import {
  LOGICAL_SERVICE_RESET_DATA,
  LOGICAL_SERVICE_SET_DATA,
  LOGICAL_SERVICE_SET_FIELD,
} from '../../actions/ServiceDB/logicalService';

const initialState = {
  data: {},
  original: {}
};
const logicalService = (state = initialState, action) => {
  switch (action.type) {
    case LOGICAL_SERVICE_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case LOGICAL_SERVICE_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case LOGICAL_SERVICE_RESET_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default logicalService;