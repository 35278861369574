import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {Card, CardBody } from 'reactstrap';
import { getDeviceModels } from '../../../actions/deviceModels';
import { connect, useDispatch } from 'react-redux'
import DeviceModelsTable from '../../../components/Tables/DeviceModels';
import LoadingOverlay from '../../../components/LoadingOverlay'
const DeviceModels = ({ deviceModels }) => {
  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    dispatch(getDeviceModels())
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <DeviceModelsTable
              deviceModels={deviceModels}
              fetchData={fetch}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>

    </div>

  );
};

function mapStateToProps({ deviceModels, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    deviceModels: deviceModels.deviceModels,
  };
}

export default connect(mapStateToProps)(DeviceModels);
