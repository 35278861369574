import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { DateTimePicker } from 'react-widgets';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';
import Loadable from 'react-loading-overlay';
import {
  getAccountBillingPreview,
  searchActiveBillingCustomers,
  SET_BILLING_PREVIEW_ACCOUNT,
  SET_BILLING_PREVIEW_DATE, SET_BILLING_PREVIEW_ERROR,
  SET_BILLING_PREVIEW_LAST_BILLED_DATE,
  updateAccountLastBilledDate
} from '../../actions/billingPreview';
import BillPreview from './BillPreview';
import { AsyncSelectMod } from '../../components/Selects/AsyncSelectMod';

Moment.locale('en');
momentLocalizer();

class AccountPreview extends React.Component {
  constructor(props) {
    super(props);
  }

    static contextTypes = {
      router: PropTypes.shape({
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
          replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
      }).isRequired
    };

    componentDidMount() {
      if (!this.props.preview.billingDate) {
        this.props.dispatch({ type: SET_BILLING_PREVIEW_DATE, date: new Date().toISOString() });
      }
    }

    getActiveCustomers = (searchString) =>{
      if (!searchString || searchString.length <= 2) {
        return [];
      }
      return this.props.dispatch(searchActiveBillingCustomers(searchString)).then(
        () => {
          return this.props.preview.searchOptions;
        }
      );
    }

    handleBillingDate = (value) =>{
      this.props.dispatch({ type: SET_BILLING_PREVIEW_DATE, date: value.toISOString() });
    }

    handleLastBilledDate = (value) =>{
      this.props.dispatch({ type: SET_BILLING_PREVIEW_LAST_BILLED_DATE, date: value.toISOString() });
    }

    updateLastBilledDate = () =>{
      this.props.dispatch(updateAccountLastBilledDate(this.props.preview.account.accountnumber, this.props.preview.lastBilledDate));
    }

    handleInput = (selectedOption) => {
      this.props.dispatch({ type: SET_BILLING_PREVIEW_ACCOUNT, account: selectedOption });
      this.props.dispatch({ type: SET_BILLING_PREVIEW_ERROR, error: null });
    }

    handleSubmit = () => {
      this.props.dispatch(getAccountBillingPreview(this.props.preview.account.accountnumber, Moment(this.props.preview.billingDate).unix()));
    }

    render() {
      const { loading, billingDate, account, bills, error, searchOptions } = this.props.preview;
      return (
            <div className="animated fadeIn">
                <Loadable animate={false} active={loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                    <Card>
                        <CardBody >
                            <Row className="mt-3">
                                <Col md={6} sm={12} lg={4}>
                                    <FormGroup>
                                        <DateTimePicker
                                            format="DD-MM-YYYY"
                                            time={false}
                                            defaultValue={billingDate ? new Date(billingDate) : new Date()}
                                            onChange={this.handleBillingDate}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12} lg={4}>
                                    <FormGroup>
                                        <AsyncSelectMod
                                            isClearable={true}
                                            cacheOptions={true}
                                            value={account}
                                            onChange={this.handleInput}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ accountid }) => accountid}
                                            loadOptions={this.getActiveCustomers}
                                            options={searchOptions}
                                        />
                                    </FormGroup>
                                </Col>
                                {account
                                && <Col md={4} sm={6} lg={2}>
                                    <FormGroup>
                                        <Button block color="primary" className="text-white" onClick={this.handleSubmit}>RUN</Button>
                                    </FormGroup>
                                </Col>
                                }
                            </Row>
                            {account && error
                                && <Row>
                                    <Col>
                                        <Alert color={'danger'}>
                                            <p>{error.message || error}</p>
                                          <p>Click <a href={account.crmUrl} target="_blank"
                                                      className={'text-primary'}>here</a> to see account in CRM.</p>
                                        </Alert>
                                    </Col>
                                </Row>

                            }
                        </CardBody>
                    </Card>
                </Loadable>

                {bills.map((bill, index) =>{
                  return <Row key={'bill' + bill.billRef + '' + index}>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <BillPreview data={bill}/>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>;
                })}
            </div>
      );
    }
}

function mapStateToProps({ billingPreview }) {
  return {
    preview: billingPreview
  };
}
export default connect(mapStateToProps)(AccountPreview);
