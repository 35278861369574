import {
  PRICE_LISTS_ADD_PRICE_LIST, PRICE_LISTS_REMOVE_PRICE_LIST,
  PRICE_LISTS_SET_LISTS,
  PRICE_LISTS_SET_SEARCH_STRING, PRICE_LISTS_UPDATE_PRICE_LIST
} from '../actions/priceLists';

const initialState = {
  list: []
};


export function priceLists(state = initialState, action) {

  switch (action.type) {
    case PRICE_LISTS_SET_LISTS:
      return {
        ...state,
        list: action.lists
      }
    case PRICE_LISTS_ADD_PRICE_LIST:
      return {
        ...state,
        list: [...state.list, action.priceList]
      }
    case PRICE_LISTS_REMOVE_PRICE_LIST:
      return {
        ...state,
        list: state.list.filter(list => list.id !== action.priceListId)
      }
    case PRICE_LISTS_UPDATE_PRICE_LIST:
      return {
        ...state,
        list: state.list.map(list => {
          if(list.id === action.id){
            return {...list, ...action.field}
          }
          return list
        })
      }
    default:
      return state
  }
}
