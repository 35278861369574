import { isLoggedIn } from '../utils/Auth/AuthService';

const TOAST = 'TOAST';

function doFeedback(type, message) {
  if (isLoggedIn()) {
    return {
      type: TOAST,
      title: message,
      msgtype: type,
      disablePush: true
    };
  }
  return {
    type: 'NO_OPERATION'
  };
}

export function errorFeedback(message) {
  return doFeedback('error', message);
}

export function successFeedback(message) {
  return doFeedback('success', message);
}

export function warningFeedback(message) {
  return doFeedback('warning', message);
}

export function infoFeedback(message) {
  return doFeedback('info', message);
}

export function defaultErrorFeedback() {
  return doFeedback('error', 'There was an error with your request');
}
