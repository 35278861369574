import {
  Alert,
  Card,
  CardBody,
  CardHeader, CardTitle,
  Col,
  Row
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import LoadingOverlay from '../../components/LoadingOverlay';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import { setConfirmDialog } from '../../actions/dialogs';
import { ButtonIcon } from '../../components/ButtonIcon';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import { connect, useDispatch } from 'react-redux'
import formFieldGenerator from '../../helpers/FormFieldGenerator';
import NoteForm from './form';
import { NoteService } from '../../utils/Note';
import { defaultErrorFeedback, errorFeedback } from '../../actions/feedback';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import fileDownload from 'js-file-download';
import { formValidator } from '../../helpers/FormValidator';
import CollapsibleCard from '../../components/CollapsibleCard';
import AuditHistory from '../../components/AuditHistory';
import AuditService from '../../utils/Audit';

const mimeTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-visio.drawing.main+xml',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-project',
  'application/vnd.ms-office',
  'application/msproject',
  'application/mpp',
  'image/png',
  'image/jpeg',
  'application/pdf'
];

const initialState = {
  subject: '', description: '', attachment: null
}

const Note = ({
  relatedTo,
  id,
  toggleModal,
  onCreated,
  onUpdated,
  onDeleted
}) => {
  // redux
  const dispatch = useDispatch();

  const [noteForm, setNoteForm] = useState(initialState);
  const [noteOriginal, setNoteOriginal] = useState(initialState);
  const [audits, setAudits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const isNew = (!id || id === 'new') && !noteOriginal.id;

  const toggleLoading = () => setLoading(prevState => !prevState);

  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);

  useEffect(() => {
    if(!isNew){
      getNote()
    }

  }, [id])

  const getNote = () => {
    toggleLoading()
    NoteService.get(id || noteOriginal.id).then(result => {
      if(result.status === 200){
        setNoteOriginal(result.data)
        setNoteForm(result.data)
      }else{
        dispatch(defaultErrorFeedback())
      }
      toggleLoading()
    })
  }

  const getAudits = () => {
    toggleAuditsLoading()
    AuditService.get('notes', id || noteOriginal.id).then((result) => {
      if(result){
        setAudits(result.data)
      }
      toggleAuditsLoading()
    })
  }

  const saveNote = () => {
    if(validated()){
      if(isNew) {
        toggleLoading()
        NoteService.create({
          ...noteForm,
          relatedTo: relatedTo.data.id,
          relatedToType: relatedTo.type
        })
          .then(result => {
            if (result.status === 200) {
              setNoteOriginal(result.data)
              setNoteForm(result.data)
              if(onCreated){
                onCreated(result.data)
              }
            } else if (result.status === 422) {
              setErrors(ApiErrorResolver(result.data))
            } else {
              dispatch(defaultErrorFeedback())
            }
            toggleLoading()
          })
      }else{
        toggleLoading()
        const theDiff = diff(noteOriginal, noteForm);
        if(theDiff.attachment){
          theDiff.attachment = {
            ...noteForm.attachment
          }
        }
        NoteService.update(noteOriginal.id, {
          ...theDiff
        })
          .then(result => {
            if (result.status === 200) {
              setNoteOriginal(result.data)
              setNoteForm(result.data)
              if(onUpdated){
                onUpdated(result.data)
              }
            } else if (result.status === 422) {
              setErrors(ApiErrorResolver(result.data))
            } else {
              dispatch(defaultErrorFeedback())
            }
            toggleLoading()
          })
      }
    }
  }

  const deleteNote = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to delete this note for good.",
      proceed: () => {
        toggleLoading()
        NoteService.delete(noteOriginal.id).then(result => {
          if(result.status === 204){
            onDeleted(noteOriginal)
            toggleModal()
          } else {
            dispatch(defaultErrorFeedback())
          }
          toggleLoading()
        })
      }
    }))
  }

  const downloadAttachment = () => {
    setLoading(true)
    NoteService.downloadAttachment(id || noteOriginal.id).then(result => {
      if (result.status === 200) {
        const splitContentDisposition = result.headers['content-disposition'].split('=');
        fileDownload(result.data, splitContentDisposition[1].replaceAll('"', ''), result.headers['content-type']);
        setLoading(false)
      } else {
        dispatch(defaultErrorFeedback());
      }
    })
  }

  const validated = () => {
    let errorArr = formValidator(
      NoteForm(noteForm, fileHandler, removeAttachment, downloadAttachment),
      noteForm
    );
    if (!isEmpty(noteForm.attachment) && !mimeTypes.includes(noteForm.attachment.type)) {
      errorArr.push('invalid file type');
    }
    setErrors(errorArr);
    return isEmpty(errorArr);
  }

  const hasChanges = () => {
    const theDiff = diff(noteOriginal, noteForm);
    return !isNew && !loading && !isEmpty(theDiff)
  }
  const getMimeType = (file) => {
    if (file.type) {
      return file.type;
    }

    switch (file.name.split('.').pop()) {
      case 'vsdx':
        return 'application/vnd.ms-visio.drawing.main+xml';
      default:
        return "";
    }
  }

  const fileHandler = (files) => {
    setLoading(true);
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      setNoteForm({
        ...noteForm,
        attachment: {
          body: reader.result.split(',')[1],
          type: getMimeType(files[0]),
          name: files[0].name,
          size: files[0].size
        }
      });
      setLoading(false);
    };
    reader.onerror = () => {
      dispatch(errorFeedback('could not convert file'));
      setLoading(false);
    };
  };
  const onClosing = () => {
    if(hasChanges() || isNew){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => toggleModal()
      }))
    }else{
      toggleModal()
    }
  }

  const removeAttachment = () => {
    setNoteForm({
      ...noteForm,
      attachment: null
    })
  }


  return (
    <div className="animated fadeIn">
      <Card className='mb-0'>
        <CardHeader className={'d-flex'}>
            <CardTitle>Note</CardTitle>
          <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ml-auto')}>
            {
              hasChanges() && <UnsavedChangesAlert save={saveNote}/>
            }
            <ButtonIcon  disabled={loading || isNew} icon={'fa fa-trash'} tooltip={'Delete'}  onClick={deleteNote}/>
            <ButtonIcon  disabled={loading || (!isNew && !hasChanges())} icon={'fa fa-save'} tooltip={'Save'}  onClick={saveNote}/>
            <ButtonIcon  disabled={loading || isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={getNote}/>
            {toggleModal &&
              <ButtonIcon onClick={onClosing} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
            }
          </div>
        </CardHeader>
        <CardBody className='bg-light'>
          <LoadingOverlay loading={loading}>
            {errors.length > 0
              && <Row>
                <Col>
                  <Alert color={'danger'}>
                    {errors.map((error, index) => {
                      return <p className={'mb-0'} key={'error' + index}>{error}</p>;
                    })}
                  </Alert>
                </Col>
              </Row>}
            <Row>
              {formFieldGenerator({
                data: noteForm,
                fields: NoteForm(noteForm, fileHandler, removeAttachment, downloadAttachment),
                handleInput:(event) => {setNoteForm({
                  ...noteForm,
                  [event.target.id]: event.target.value
                })},
                handleInputGroupClick: removeAttachment
              })}
            </Row>
            {!isNew && <Row>
              <Col>
                <CollapsibleCard
                  title={'Audit History'}
                  onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                >
                  <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                </CollapsibleCard>
              </Col>
            </Row>}
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  )

}


export default connect()(Note);
