import { StockItemService } from '../utils/ServiceDB/Stock';
import { defaultErrorFeedback } from './feedback';

export const STOCK_SET_STOCK_ITEMS = 'STOCK_SET_STOCK_ITEMS';

function setStockItems(data) {
  return {
    type: STOCK_SET_STOCK_ITEMS,
    data
  }
}

export function getStockItems({ search, includes, withs } = {}) {
  return (dispatch) => {
    return StockItemService.list({ search, includes, withs }).then((result) => {
      if (result.status === 200) {
        dispatch(setStockItems(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}