import { defaultErrorFeedback } from './feedback';
import { api_getLeads, api_getUserLeads } from '../utils/Leads';

export const LEADS_SET_LEADS = 'LEADS_SET_LEADS';
export const LEADS_REMOVE_LEAD_FROM_LIST = 'LEADS_REMOVE_LEAD_FROM_LIST';
export const LEADS_REPLACE_LEAD_IN_LIST = 'LEADS_REPLACE_LEAD_IN_LIST';
export const LEADS_ADD_LEAD_TO_LIST = 'LEADS_ADD_LEAD_TO_LIST';

function setLeads(leads) {
  return {
    type: LEADS_SET_LEADS,
    leads
  };
}

export function removeLeadFromList(lead) {
  return {
    type: LEADS_REMOVE_LEAD_FROM_LIST,
    lead
  };
}

export function replaceLeadInList(lead) {
  return {
    type: LEADS_REPLACE_LEAD_IN_LIST,
    lead
  };
}

export function addLeadToList(lead) {
  return {
    type: LEADS_ADD_LEAD_TO_LIST,
    lead
  };
}

export const getLeads = (search) => {
  return (dispatch) => {
    return api_getLeads(search)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setLeads(result.data));
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
  };
};

export const getUserLeads = (userId, search) => {
  return (dispatch) => {
    return api_getUserLeads(userId, search)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setLeads(result.data));
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
  };
};

