import {
  getRadiusAttributes,
  radiusApiPut,
  radiusApiDelete,
  radiusApiPost, radiusApiGetQuery
} from '../utils/Radius/RadiusService';
import {defaultErrorFeedback} from './feedback';

export const RADIUS_UPDATE_USERS = 'RADIUS_UPDATE_USERS';
export const RADIUS_FETCHING_USERS = 'RADIUS_FETCHING_USERS';
export const RADIUS_ADDING_USER = 'RADIUS_ADDING_USER';
export const RADIUS_ADD_USER = 'RADIUS_ADD_USER';
export const RADIUS_DELETING_USER = 'RADIUS_DELETING_USER';
export const RADIUS_DELETE_USER = 'RADIUS_DELETE_USER';
export const RADIUS_RETURN_NONE = 'RADIUS_RETURN_NONE';
export const RADIUS_FETCHING_ATTRIBUTES = 'RADIUS_FETCHING_ATTRIBUTES';
export const RADIUS_SET_ACTIVE_USER = 'RADIUS_SET_ACTIVE_USER';
export const RADIUS_SETTING_ATTRIBUTES = 'RADIUS_SETTING_ATTRIBUTES';
export const RADIUS_UPDATE_USER_ATTRIBS = 'RADIUS_UPDATE_USER_ATTRIBS';
export const RADIUS_NEW_ATTRIB_BOX = 'RADIUS_NEW_ATTRIB_BOX';
export const RADIUS_SET_ATTRIB = 'RADIUS_SET_ATTRIB';
export const RADIUS_SET_ACTIVE_ATTRIB = 'RADIUS_SET_ACTIVE_ATTRIB';
export const RADIUS_DELETING_ATTRIBUTES = 'RADIUS_DELETING_ATTRIBUTES';
export const RADIUS_DELETE_ATTRIBUTE = 'RADIUS_DELETE_ATTRIBUTE';
export const RADIUS_SET_ACTIVE_BOX = 'RADIUS_SET_ACTIVE_BOX';
export const RADIUS_SET_ADD_USER_ERROR = 'RADIUS_SET_ADD_USER_ERROR';
export const RADIUS_SET_GROUPS = 'RADIUS_SET_GROUPS';

function radiusFetchingUsers(fetching) {
  return {
    type: RADIUS_FETCHING_USERS,
    fetching
  };
}

export function setActiveUser(username) {
  return {
    type: RADIUS_SET_ACTIVE_USER,
    username
  };
}

function updateUsers(data) {
  return {
    type: RADIUS_UPDATE_USERS,
    data
  };
}

function updateGroups(data) {
  return {
    type: RADIUS_SET_GROUPS,
    data
  };
}

function radiusFetchingAttribs(fetching) {
  return {
    type: RADIUS_FETCHING_ATTRIBUTES,
    fetching
  };
}

function updateUserAttribs(data) {
  return {
    type: RADIUS_UPDATE_USER_ATTRIBS,
    data
  };
}

export function newAttributeBox(show) {
  return {
    type: RADIUS_NEW_ATTRIB_BOX,
    show
  };
}

export function settingNewAttributes(setting) {
  return {
    type: RADIUS_SETTING_ATTRIBUTES,
    setting
  };
}

export function setNewAttrib(data, radType, key) {
  return {
    type: RADIUS_SET_ATTRIB,
    data,
    radType,
    key
  };
}

export function deletingAttributes(deleting) {
  return {
    type: RADIUS_DELETING_ATTRIBUTES,
    deleting
  };
}

export function deletingUser(deleting) {
  return {
    type: RADIUS_DELETING_USER,
    deleting
  };
}

export function deleteUser(username) {
  return {
    type: RADIUS_DELETE_USER,
    username
  };
}

export function deleteAttrib(data, radType, index) {
  return {
    type: RADIUS_DELETE_ATTRIBUTE,
    data,
    radType,
    index
  };
}

export function setActiveAttrib(attrib) {
  var newAttrib = attrib === 'ucheckattribs' ? 'check' : 'reply';
  return {
    type: RADIUS_SET_ACTIVE_ATTRIB,
    newAttrib
  };
}

export function setActiveBox(activeBox) {
  return {
    type: RADIUS_SET_ACTIVE_BOX,
    activeBox
  };
}

export function addingUser(adding) {
  return {
    type: RADIUS_ADDING_USER,
    adding
  };
}

export function addUser(username) {
  return {
    type: RADIUS_ADD_USER,
    username
  };
}

export function fetchRadiusUsers(page, filtered) {
  let query = {page: page};
  if (filtered) {
    query.filtered = filtered;
  }
  return (dispatch) => {
    dispatch(radiusFetchingUsers(true));
    return radiusApiGetQuery('/radius', {}, query).then((result) => {
      if (result.status === 200) {
        dispatch(updateUsers(result.data));
        dispatch(radiusFetchingUsers(false));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function searchRadiusAttribs(page, filtered = '') {
  let query = {page: page};
  query.filtered = filtered;

  return (dispatch) => {
    dispatch(radiusFetchingUsers(true));
    return radiusApiGetQuery('/radius/attributes/search', {}, query).then((result) => {
      if (result.status === 200) {
        dispatch(updateUsers(result.data));
        dispatch(radiusFetchingUsers(false));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function fetchRadiusGroups() {
  return (dispatch) => {
    return radiusApiGetQuery('/radius/groups', {}).then((result) => {
      if (result.status === 200) {
        dispatch(updateGroups(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function fetchRadiusAttribs(username, quiet = false) {
  return (dispatch) => {
    if (!quiet) {
      dispatch(radiusFetchingAttribs(true));
    }

    return getRadiusAttributes(username).then((result) => {
      if (result.status === 200) {
        if (!quiet) {
          dispatch(radiusFetchingAttribs(false));
        }
        dispatch(updateUserAttribs(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function addRadiusAttribs(username, attribs, type) {
  return (dispatch) => {
    dispatch(settingNewAttributes(true));

    return radiusApiPut(`/radius/attributes/${type}/` + username, attribs).then((result) => {
      if (result.status === 204) {
        dispatch(settingNewAttributes(false));
        dispatch(fetchRadiusAttribs(username, true));
      } else {
        dispatch(settingNewAttributes(false));
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function deleteRadiusAttribs(id, username, type) {
  return (dispatch) => {
    dispatch(deletingAttributes(true));

    return radiusApiDelete(`/radius/attributes/${type}/` + id).then((result) => {
      if (result.status === 204) {
        dispatch(deletingAttributes(false));
        dispatch(fetchRadiusAttribs(username, true));
        return true;
      }
    }).catch(error => dispatch(defaultErrorFeedback(error.text())));
  };
}

export function addRadiusUser(data) {
  return (dispatch) => {
    dispatch(addingUser(true));

    return radiusApiPost('/radius', data).then((result) => {
      if (result.status === 200) {
        dispatch(addUser(data.username));
      } else {
        dispatch(defaultErrorFeedback());
        dispatch({type: RADIUS_SET_ADD_USER_ERROR, error: result.data.username})
      }
      dispatch(addingUser(false));
    });
  };
}

export function deleteRadiusUser(username) {
  return (dispatch) => {
    dispatch(deletingUser(true));

    return radiusApiDelete('/radius/' + username).then((result) => {
      if (result.status === 204) {
        dispatch(deleteUser(username));
      } else {
        dispatch(defaultErrorFeedback());
      }
      dispatch(deletingUser(false));
    });
  };
}
