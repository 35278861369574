import {
  QUOTES_ADD_QUOTE_TO_LIST,
  QUOTES_SET_LIST,
  QUOTES_SET_SEARCH_STRING,
  QUOTES_UPDATE_PREFERRED_IN_LIST,
  QUOTES_UPDATE_QUOTE_IN_LIST
} from '../actions/quotes';

const initialState = {
  searchString: '',
  list: [],
  filteredList: []
}

export function quotes(state = initialState, action){
  switch (action.type) {
    case QUOTES_SET_LIST:
      return {
        ...state,
        list: action.quotes
      }
    case QUOTES_ADD_QUOTE_TO_LIST:
      return {
        ...state,
        list: [...state.list, action.quote]
      }
    case QUOTES_UPDATE_QUOTE_IN_LIST:
      return {
        ...state,
        list: state.list.map((quote) => {
          return quote.id === action.quoteId ? action.quoteData : quote;
        }),
      }
    case QUOTES_SET_SEARCH_STRING:
      const filteredList = state.list.filter((quote) => {
        return String(quote.name).toLowerCase().indexOf(action.searchString.toLowerCase()) !== -1 ||
          String(quote.number).toLowerCase().indexOf(action.searchString.toLowerCase()) !== -1 ||
          String(quote.pricingToolQuote).toLowerCase().indexOf(action.searchString.toLowerCase()) !== -1;
      });
      return {
        ...state,
        searchString: action.searchString,
        filteredList: filteredList
      }
    case QUOTES_UPDATE_PREFERRED_IN_LIST:
      return {
        ...state,
        list: state.list.map((quote) => {
          return {...quote, preferredQuote: quote.id === action.quoteId}
        }),
        filteredList: state.filteredList.map((quote) => {
          if(quote.id !== action.quoteId){
            return {...quote, preferredQuote: false}
          }
        })
      }

    default:
      return state;
  }

}
