import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button } from 'reactstrap';
import { filterAlarm } from '../../actions/alarms';
import { connect } from 'react-redux';
import TagsInput from 'react-tagsinput';
import Autosuggest from 'react-autosuggest';
import 'react-tagsinput/react-tagsinput.css';

function taglist() {
  return [
    { abbr: 'ONGOING', name: 'ONGOING' },
    { abbr: 'PROVISIONING', name: 'PROVISIONING' },
    { abbr: 'PLANNEDWORKS', name: 'PLANNEDWORKS' },
    { abbr: 'CEASING', name: 'CEASING' },
    { abbr: 'SUSPENDED', name: 'SUSPENDED' }
  ];
}

class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ackmsg: '',
      dateto: '',
      tags: [],
      messageinvalid: false
    };
    this.handleAck = this.handleAck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleParentUpdate = this.handleParentUpdate.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
  }

  handleParentUpdate() {
    this.props.parentSetState({ fmodal: false });
  }

  handleChange(event) {
    this.setState({ ackmsg: event.target.value });
  }

  handleDateChange(event) {
    this.setState({ dateto: event.target.value });
  }

  handleTagsChange(tags) {
    this.setState({ tags });
  }

  handleAck() {
    if (this.state.ackmsg.length <= 20) {
      this.setState({ messageinvalid: true });
    } else {
      this.props.dispatch(filterAlarm(this.props.event, this.props.trigger, this.state.ackmsg, this.state.dateto, this.state.tags));
      this.setState({
        ackmsg: '',
        dateto: '',
        tags: [],
        messageinvalid: false
      });
      this.props.parentSetState({ fmodal: false });
    }
  }

  render() {
    function autocompleteRenderInput({ addTag, ...props }) {
      const handleOnChange = (e, { newValue, method }) => {
        if (method === 'enter') {
          e.preventDefault();
        } else {
          props.onChange(e);
        }
      };

      const inputValue = (props.value && props.value.trim().toLowerCase()) || '';
      const inputLength = inputValue.length;

      let suggestions = taglist().filter((state) => {
        return state.name.toLowerCase().slice(0, inputLength) === inputValue;
      });

      return (
                <Autosuggest
                    ref={props.ref}
                    suggestions={suggestions}
                    shouldRenderSuggestions={(value) => value && value.trim().length > 0}
                    getSuggestionValue={(suggestion) => suggestion.name}
                    renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
                    inputProps={{ ...props, onChange: handleOnChange }}
                    onSuggestionSelected={(e, { suggestion }) => {
                      addTag(suggestion.name);
                    }}
                    onSuggestionsClearRequested={() => {}}
                    onSuggestionsFetchRequested={() => {}}
                />
      );
    }
    return (
            <Modal isOpen={this.props.show} className={this.props.className}>
                <ModalHeader>Filter Event</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="message">Enter Message:</Label>
                            <Input type="textarea" name="message" id="message" invalid={this.state.messageinvalid} onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="date">Filter Until:</Label>
                            <Input type="date" name="date" id="date" onChange={this.handleDateChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Tags:</Label>
                            <TagsInput renderInput={autocompleteRenderInput} value={this.state.tags} onChange={this.handleTagsChange} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.handleAck}>Acknowledge</Button>{' '}
                    <Button color="secondary" onClick={this.handleParentUpdate}>Cancel</Button>
                </ModalFooter>
            </Modal>
    );
  }
}

export default connect()(FilterModal);
