import {api_getClosestExchanges, api_getClosestHubs, api_getServingExchange} from '../utils/Discovery/DiscoveryService';
import { defaultErrorFeedback } from './feedback';

export const POPS_SET_DATA = 'POPS_SET_DATA';
export const POPS_SET_STATUS = 'POPS_SET_STATUS';

export const EXCHANGES_SET_DATA = 'EXCHANGES_SET_DATA';
export const EXCHANGES_SET_STATUS = 'EXCHANGES_SET_STATUS';
export const EXCHANGES_SET_SERVING = 'EXCHANGES_SET_SERVING';

export const EXCHANGE_FETCH_SERVING = 'EXCHANGE_FETCH_SERVING';
export const EXCHANGE_ADD_SERVING = 'EXCHANGE_ADD_SERVING';
export const SET_SERVING_EXCHANGE_LIST = 'SET_SERVING_EXCHANGE_LIST';
export const SET_SERVING_EXCHANGE_LIST_FAILED = 'SET_SERVING_EXCHANGE_LIST_FAILED';

const popsSetStatus = (status) => {
  return {
    type: POPS_SET_STATUS,
    status
  }
};

const exchangesSetStatus = (status) => {
  return {
    type: EXCHANGES_SET_STATUS,
    status
  }
};

export const getClosestHubs = (postcode) => {
  return (dispatch) => {
    dispatch(popsSetStatus(1));
    return api_getClosestHubs(postcode)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch(popsSetStatus(0));
            dispatch({
              type: POPS_SET_DATA,
              data: result.data
            });
            return result.data;
          }
          dispatch(popsSetStatus(0));
          dispatch(defaultErrorFeedback());
          return false;
        }
      );
  };
};


export const getClosestExchanges = (postcode) => {
  return (dispatch) => {
    dispatch(exchangesSetStatus(1));
    return api_getClosestExchanges(postcode)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch(exchangesSetStatus(0));
            dispatch({
              type: EXCHANGES_SET_DATA,
              data: result.data
            });
            return result.data;
          }
          dispatch(exchangesSetStatus(0));
          dispatch(defaultErrorFeedback());
          return false;
        }
      );
  };
};


export const getServingExchange = (postcode) => {
  return (dispatch) => {
    return api_getServingExchange(postcode)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch({
              type: EXCHANGES_SET_SERVING,
              data: result.data
            });
            return result.data;
          }
          dispatch(defaultErrorFeedback());
          return false;
        }
      );
  };
};
