const labelStyle = {
  normal: { fill: '#696969', stroke: 'none', fontSize: 9 },
  selected: { fill: '#333', stroke: 'none', fontSize: 11 },
  muted: { fill: '#696969',
    stroke: 'none',
    fontSize: 8,
    opacity: 0.6 }
};

const mutedStyle = { fill: '#B0B0B0', stroke: '#9E9E9E', opacity: 0.6, cursor: 'pointer' };

const selectedStyle = { fill: '#37B6D3', stroke: 'rgba(55, 182, 211, 0.22)', strokeWidth: 10, cursor: 'pointer' };

// Mapping of node type to style
export const stylesMap = {
  mx10k3: { name: 'MX10k3', node: { normal: { fill: '#E84B21', stroke: '#E84B21', cursor: 'pointer' }, selected: selectedStyle, muted: mutedStyle }, label: labelStyle },
  mx480: { name: 'MX480', node: { normal: { fill: '#6F81B5', stroke: '#6F81B5', cursor: 'pointer' }, selected: selectedStyle, muted: mutedStyle }, label: labelStyle },
  mx204: { name: 'MX204', node: { normal: { fill: '#318883', stroke: '#318883', cursor: 'pointer' }, selected: selectedStyle, muted: mutedStyle }, label: labelStyle },
  mx150: { name: 'MX150', node: { normal: { fill: '#7932a9', stroke: '#7932a9', cursor: 'pointer' }, selected: selectedStyle, muted: mutedStyle }, label: labelStyle },
  mx104: { name: 'MX104', node: { normal: { fill: '#195F83', stroke: '#195F83', cursor: 'pointer' }, selected: selectedStyle, muted: mutedStyle }, label: labelStyle },
  acx: { name: 'ACX5048', node: { normal: { fill: '#E8DB8B', stroke: '#E8DB8B', cursor: 'pointer' }, selected: selectedStyle, muted: mutedStyle }, label: labelStyle },
  ex: { name: 'EX Switch', node: { normal: { fill: '#CBCBCB', stroke: '#BEBEBE', cursor: 'pointer' }, selected: selectedStyle, muted: mutedStyle }, label: labelStyle },
  hub: { name: 'Hub', node: { normal: { fill: '#CBCBCB', stroke: '#BEBEBE', cursor: 'pointer' }, selected: selectedStyle, muted: mutedStyle }, label: labelStyle },
  esnet_site: { name: 'ESNet Site', node: { normal: { fill: '#B0B0B0', stroke: '#9E9E9E', cursor: 'pointer' }, selected: selectedStyle, muted: mutedStyle }, label: labelStyle }
};

export const nodeSizeMap = {
  hub: 5.5,
  esnet_site: 7,
  mx480: 7,
  mx150: 6,
  mx104: 6,
  mx204: 7,
  acx: 6,
  mx10k3: 8,
  ex: 5.5
};

export const nodeLegendData = Object.keys(stylesMap).map(function (key, index) {
  return { color: stylesMap[key].node.normal.fill, label: stylesMap[key].name, classed: key, radius: nodeSizeMap[key] };
});

export const edgeThicknessMap = {
  '100G': 6,
  '40G': 5,
  '30G': 4,
  '20G': 3.5,
  '10G': 3,
  '8G': 2.8,
  '4G': 2,
  '2G': 1.8,
  '1G': 1.5
};
