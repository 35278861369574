import { apiRequest, compareApiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';


export function api_getQuote(id, include, withArr) {
  return apiRequest({
    url: `/quotes/${id}${queryStringFromFields({include, with: withArr.join(';')})}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updatePreferredQuote(userId, id) {
  return apiRequest({
    url: `/users/${userId}/quotes/${id}/preferred`,
    method: 'put',
    data: {
      preferredQuote: true
    }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateQuote(id, data, include, withArr) {
  return apiRequest({
    url: `/quotes/${id}${queryStringFromFields({include, with: withArr.join(';')})}`,
    method: 'put',
    data: {...data}
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getCompareQuote(quoteId) {
  return apiRequest({
    url: `/compare/${quoteId}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getCompareV2Quote(quoteId) {
  return compareApiRequest({
    url: `/quotes/${quoteId}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_createAccountQuote(accountId, args, include, withArr) {
  return apiRequest({
    url: `/accounts/${accountId}/quotes${queryStringFromFields({include, with: withArr.join(';')})}`,
    method: 'post',
    data: { ...args }
  })
    .then(
      resp => resp
  ).catch(
    error => error
  );
}

export function api_downloadProposal(userId, quoteId, format, contact) {
  let queryString = contact ? `?format=${format}&contact=${contact}` : `?format=${format}`;
  return apiRequest({
    url: `/quotes/${quoteId}/proposal${queryString}`,
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_downloadOrderForm(quoteId, orderForm) {
  return apiRequest({
    url: `/quotes/${quoteId}/order-form`,
    method: 'post',
    responseType: 'blob',
    data: { ...orderForm }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_downloadCancellationForm(quoteId, data) {
  return apiRequest({
    url: `/quotes/${quoteId}/cancellation-form`,
    method: 'post',
    responseType: 'blob',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_convertQuoteToOrder(userId, quoteId, data) {
  return apiRequest({
    url: `/quotes/${quoteId}/orders`,
    method: 'post',
    data: {
      ...data,
      opportunityStatusCode: 3
    }
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}
