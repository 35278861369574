import FilteredTableV2 from '../../FilteredTableV2';
import { Button } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment/moment';
import { ButtonIcon } from '../../ButtonIcon';
import React, { useState } from 'react';

const ProductSlasTable = ({
  slas,
  fetchData,
  setSla
}) => {
  const columns = [
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'File',
      accessorKey: 's3Key'
    },
    {
      header: 'S3 Bucket',
      accessorKey: 's3Bucket'
    }
  ]


  return (
    <>
      <FilteredTableV2
        minRows={10}
        defaultPageSize={20}
        showPagination
        withFilter={'includesString'}
        columns={columns}
        data={slas}
        onRowClick={setSla}
        extraButtons={[
          <Button size={'sm'} color={'secondary'} onClick={() => setSla(null)}>New SLA</Button>,
          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
        ]}
      />
    </>
  )
}

export default ProductSlasTable