import { defaultErrorFeedback, errorFeedback } from './feedback';
import { api_getModelOptionSets } from '../utils/Helpers/ModelOptionSets';
import { api_getSuppliers } from '../utils/Helpers/Suppliers';
import { api_getSystemUsers } from '../utils/Helpers/SystemUsers';
import { api_getCRMSubjects } from '../utils/Helpers/CRMSubjects';
import {
  api_getPricingToolCarrierProducts,
  api_getPricingToolCarriers,
  api_getPricingToolFeasibilities, api_getPricingToolInstallTypes,
  api_getPricingToolKits, api_getPricingToolNNIs, api_getPricingToolTechnologies
} from '../utils/Helpers/PricingTool';
import { api_getDocumentOptionSets } from '../utils/Helpers/DocumentOptionSets';
import { api_getServiceDbLogicalServiceTypes } from '../utils/Helpers/ServiceDbLogicalServiceTypes';
import { QUOTE_SET_PRICING_TOOL_PRODUCTS } from './quote';
import { api_getPriceListItems } from '../utils/PriceLists';
import { api_getServiceDbL2tpOptions } from '../utils/Helpers/ServiceDBL2tpOptions';
import { api_getServiceDbNniOptions } from '../utils/Helpers/ServiceDbNniOptions';

export const HELPERS_SET_ENTITY_OPTION_SETS = 'HELPERS_SET_ENTITY_OPTION_SETS';
export const HELPERS_SET_CRM_SUPPLIERS = 'HELPERS_SET_CRM_SUPPLIERS';
export const HELPERS_ADD_SUPPLIER_CRM_SUPPLIERS = 'HELPERS_ADD_SUPPLIER_CRM_SUPPLIERS';
export const HELPERS_SET_SYSTEM_USERS = 'HELPERS_SET_SYSTEM_USERS';
export const HELPERS_SET_CRM_SUBJECTS = 'HELPERS_SET_CRM_SUBJECTS';
export const HELPERS_SET_PRODUCT_LIST = 'HELPERS_SET_PRODUCT_LIST';
export const HELPERS_SET_PRICING_TOOL_CARRIERS = 'HELPERS_SET_PRICING_TOOL_CARRIERS';
export const HELPERS_SET_PRICING_TOOL_INSTALL_TYPES = 'HELPERS_SET_PRICING_TOOL_INSTALL_TYPES';
export const HELPERS_SET_PRICING_TOOL_FEASIBILITIES = 'HELPERS_SET_PRICING_TOOL_FEASIBILITIES';
export const HELPERS_SET_PRICING_TOOL_KITS = 'HELPERS_SET_PRICING_TOOL_KITS';
export const HELPERS_SET_PRICING_TOOL_NNIS = 'HELPERS_SET_PRICING_TOOL_NNIS';
export const HELPERS_SET_PRICING_TOOL_TECHNOLOGIES = 'HELPERS_SET_PRICING_TOOL_TECHNOLOGIES';
export const HELPERS_SET_DOCUMENT_OPTION_SETS = 'HELPERS_SET_DOCUMENT_OPTION_SETS';
export const HELPERS_SET_PRICING_TOOL_CARRIER_PRODUCTS = 'HELPERS_SET_PRICING_TOOL_CARRIER_PRODUCTS';
export const HELPERS_SET_LOGICAL_SERVICE_TYPES = 'HELPERS_SET_LOGICAL_SERVICE_TYPES';
export const HELPERS_SET_SERVICE_DB_L2TP_OPTIONS = 'HELPERS_SET_SERVICE_DB_L2TP_OPTIONS';
export const HELPERS_SET_SERVICE_DB_NNI_OPTIONS = 'HELPERS_SET_SERVICE_DB_NNI_OPTIONS';
export const HELPERS_SET_DEFAULT_PRICING_TOOL_PRODUCTS = 'HELPERS_SET_DEFAULT_PRICING_TOOL_PRODUCTS';

function setProducts(data) {
  return {
    type: HELPERS_SET_PRODUCT_LIST,
    data
  };
}

function setAppOptionSets(data) {
  return {
    type: HELPERS_SET_ENTITY_OPTION_SETS,
    optionSets: data
  };
}

function setCRMSuppliers(data) {
  return {
    type: HELPERS_SET_CRM_SUPPLIERS,
    suppliers: data
  };
}

export function addSupplierToCRMSuppliers(data) {
  return {
    type: HELPERS_ADD_SUPPLIER_CRM_SUPPLIERS,
    supplier: data
  };
}

function setCRMSubjects(subjects) {
  return {
    type: HELPERS_SET_CRM_SUBJECTS,
    subjects
  };
}

function setSystemUsers(data) {
  return {
    type: HELPERS_SET_SYSTEM_USERS,
    users: data
  };
}

function setDocumentOptionSets(data) {
  return {
    type: HELPERS_SET_DOCUMENT_OPTION_SETS,
    optionSets: data
  };
}

function setLogicalServiceTypes(logicalServiceTypes) {
  return {
    type: HELPERS_SET_LOGICAL_SERVICE_TYPES,
    logicalServiceTypes
  };
}
function setL2tpOptions(options) {
  return {
    type: HELPERS_SET_SERVICE_DB_L2TP_OPTIONS,
    options
  };
}

function setNNIOptions(options) {
  return {
    type: HELPERS_SET_SERVICE_DB_NNI_OPTIONS,
    options
  };
}
export function getModelOptionSets() {
  return (dispatch) => {
    return api_getModelOptionSets().then((result) => {
      if (result.status === 200) {
        let optionSets = {};
        result.data.forEach((optionSet) => {
          if (!optionSets[optionSet.entity]) {
            optionSets[optionSet.entity] = {};
          }
          optionSets[optionSet.entity][optionSet.field] = { options: optionSet.options };
        });
        dispatch(setAppOptionSets(optionSets));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getSuppliers() {
  return (dispatch) => {
    return api_getSuppliers().then((result) => {
      if (result.status === 200) {
        let suppliers = {};
        result.data.forEach((supplier) => {
          suppliers[supplier.id] = supplier;
        });
        dispatch(setCRMSuppliers(suppliers));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getSystemUsers() {
  return (dispatch) => {
    return api_getSystemUsers().then((result) => {
      if (result.status === 200) {
        dispatch(setSystemUsers(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getCaseSubjects() {
  return (dispatch) => {
    return api_getCRMSubjects().then((result) => {
      if (result.status === 200) {
        dispatch(setCRMSubjects(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}
export function getPricingToolHelpers() {
  return (dispatch) => {
    api_getPricingToolCarriers()
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: HELPERS_SET_PRICING_TOOL_CARRIERS,
            carriers: result.data
          });
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
    api_getPricingToolFeasibilities()
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: HELPERS_SET_PRICING_TOOL_FEASIBILITIES,
            feasibilities: result.data
          });
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
    api_getPricingToolKits()
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: HELPERS_SET_PRICING_TOOL_KITS,
            kits: result.data
          });
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
    api_getPricingToolInstallTypes()
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: HELPERS_SET_PRICING_TOOL_INSTALL_TYPES,
            installs: result.data
          });
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
    api_getPricingToolNNIs()
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: HELPERS_SET_PRICING_TOOL_NNIS,
            nnis: result.data
          });
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
    api_getPricingToolTechnologies()
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: HELPERS_SET_PRICING_TOOL_TECHNOLOGIES,
            technologies: result.data
          });
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
    api_getPricingToolCarrierProducts()
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: HELPERS_SET_PRICING_TOOL_CARRIER_PRODUCTS,
            products: result.data
          });
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
  };
}

export function getDocumentOptionSets() {
  return (dispatch) => {
    return api_getDocumentOptionSets().then((result) => {
      if (result.status === 200) {
        let optionSets = {};
        Object.entries(result.data).forEach(([entity, entityOptionSets]) => {
          if (entity === 'status') {
            optionSets[entity] = entityOptionSets.map(value => ({
              value: value.id,
              label: value.label
            }));
          } else {
            optionSets[entity] = entityOptionSets.map(value => ({ value, label: value }));
          }
        });
        dispatch(setDocumentOptionSets(optionSets));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getServiceDbLogicalServiceTypes() {
  return (dispatch) => {
    return api_getServiceDbLogicalServiceTypes().then((result) => {
      if (result.status === 200) {
        dispatch(setLogicalServiceTypes(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getServiceDbL2tpOptions() {
  return (dispatch) => {
    return api_getServiceDbL2tpOptions().then((result) => {
      if (result.status === 200) {
        dispatch(setL2tpOptions(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getServiceDbNniOptions() {
  return (dispatch) => {
    return api_getServiceDbNniOptions().then((result) => {
      if (result.status === 200) {
        dispatch(setNNIOptions(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getDefaultPricingToolProducts(priceListId) {

  return (dispatch) => {
    return api_getPriceListItems(
      priceListId,
      ['HSO10-010','HSO10-011','HSO10-018','HSO10-921','HSO10-922','HSO30-262'],
      { includes: ['template', 'pricing', 'sla'], withs: ['productTemplate', 'pricingType', 'productSLA'] }
    )
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: HELPERS_SET_DEFAULT_PRICING_TOOL_PRODUCTS,
            products: result.data
          })
        } else {
          dispatch(errorFeedback('There was an error with your request'));
        }
      });
  };
}
