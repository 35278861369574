
const CircuitForm = (handleClick) => {
  return {
    generalFields: {
      name: { label: 'Name', type: 'text', mandatory: true, width: 12 },
      circuitRef: { label: 'Internal Circuit Ref', type: 'text', readOnly: true },
      circuitType: { label: 'Circuit Type', type: 'select' },
      orderStatus: { label: 'Order Status', type: 'select' },
      order: { label: 'Order', type: 'select-async', entity: 'order', minSearchLength: 5 },
      owner: { label: 'Owner', type: 'select-async', entity: 'account', width: 12, placeholder: 'Enter account name...' },
      pstnNumber: { label: 'PSTN Number', type: 'text' },
      hsoPSTN: { label: 'hSo PSTN', type: 'select-boolean' },
      ntuRef: { label: 'NTU Ref', type: 'text' },
      radiusUsername: { label: 'Radius Username', type: 'text' },
      parentCircuit: { label: 'Parent Circuit', type: 'select-async', entity: 'circuit', width: 12, placeholder: 'Enter circuit ref...', minSearchLength: 5 }
    },
    aEndFields: {
      aEnd: {
        label: 'Site',
        type: 'select-async',
        entity: 'site',
        width: 12,
        placeholder: 'Enter site name...',
        handleClick: () => {
          handleClick('siteA')
        },
        clearable: true,
      },
      aEndFloor: { label: 'Floor', type: 'text' },
      aEndRoom: { label: 'Room', type: 'text' },
      aEndPosition: { label: 'Position', type: 'text' },
      aEndCI: { label: 'Config Item', type: 'select-async', entity: 'configItem', width: 12, minSearchLength: 4, placeholder: 'Enter CI name...' },
      aEndCIPort: { label: 'Port', type: 'text' },
      aEndCIVLANId: {
        label: 'VLAN Id',
        type: 'number',
        min: 0,
        max: 100000
      }
    },
    bEndFields: {
      bEnd:  {
        label: 'Site',
        type: 'select-async',
        entity: 'site',
        width: 12,
        placeholder: 'Enter site name...',
        handleClick: () => {
          handleClick('siteB')
        },
        clearable: true,
      },
      bEndFloor: { label: 'Floor', type: 'text' },
      bEndRoom: { label: 'Room', type: 'text' },
      bEndPosition: { label: 'Position', type: 'text' },
      bEndCI: { label: 'Config Item', type: 'select-async', entity: 'configItem', width: 12, minSearchLength: 4, placeholder: 'Enter CI name...' },
      bEndCIPort: { label: 'Port', type: 'text' },
      bEndCIVLANId: {
        label: 'VLAN Id',
        type: 'number',
        min: 0,
        max: 100000
      }
    },
    supplierFields: {
      supplier: { label: 'Supplier', type: 'select-async', entity: 'supplier', placeholder: 'Enter supplier name...' },
      supplierProduct: { label: 'Supplier Product', type: 'select' },
      bandwidth: { label: 'Bandwidth', type: 'text', unit: 'bandwidthUnit', width: 3,min: 1, max: 1000 },
      physical: { label: 'Physical', type: 'text', unit: 'physicalUnit', width: 3, min: 1, max: 10000},
      carrierCircuitNumber: { label: 'Circuit Number', type: 'text' },
      carrierJobNumber: { label: 'Job Number', type: 'text' },
      carrierAcceptedDate: { label: 'Accepted Date', type: 'date' },
      carrierDeliveryDate: { label: 'Delivery Date', type: 'date' },
      actualDeliveryDate: { label: 'Actual Delivery Date', type: 'date' },
      tailCircuitSupplier: { label: 'Tail Circuit Supplier', type: 'select-async', entity: 'supplier', placeholder: 'Enter supplier name...' },
      tailCarrierRef: { label: 'Tail Carrier Ref', type: 'text', width: 12 },
      cancellationSubmittedDate: { label: 'Cancellation Submitted Date', type: 'date' },
      ceaseDate: { label: 'Cease Date', type: 'date' }
    }
  };
}

export default CircuitForm


