import {apiRequest} from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const NNIService = {
    list: (include = [], search, orderBy, sortedBy) => {
        let query = {
            orderBy,
            sortedBy,
            search,
            include
        };
        return apiRequest({
            url: `/sdb/nnis${queryStringFromFields(query)}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    getNNI: (id, include = []) => {
        return apiRequest({
            url: `/sdb/nnis/${id}${queryStringFromFields({include})}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    createNNI: (data, include = []) => {
        return apiRequest({
            url: `/sdb/nnis${queryStringFromFields({include})}`,
            method: 'post',
            data
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    updateNNI: (id, data, include = []) => {
        return apiRequest({
            url: `/sdb/nnis/${id}${queryStringFromFields({include})}`,
            method: 'put',
            data
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    deleteNNI: (id) => {
        return apiRequest({
            url: `/sdb/nnis/${id}`,
            method: 'delete'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    }

};