import React, { Component } from 'react';
import { warningFeedback } from '../../actions/feedback';

import {
  Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Col, Row, Nav
} from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
let moment = require('moment');
require('animate.css');

class HeaderZabbixAlarmsLegacy extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      updated: true
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  sort = alarms => {
    return alarms.sort((a, b) => {
      let aValue = a.lastchange;
      let bValue = b.lastchange;

      // Support string comparison
      const sortTable = { true: 1, false: -1 };

      // Order descending (Order.DESC)
      return bValue === aValue ? 0 : sortTable[bValue > aValue];
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const newProps = this.props;
    if (prevProps.maxeventid !== newProps.maxeventid) {
      if (prevProps.maxeventid !== 0) { this.props.dispatch(warningFeedback('New zabbix alarms detected')); }
      if (prevState.updated === false) {
        this.setState({ updated: true });
      }
      if (prevState.updated === true) {
        this.setState({ updated: false });
      }
    }
  }

  render() {
    if (this.props.alarms.length === 0) {
      return (<Nav style={{ paddingRight: '30px' }}><Badge style={{ margin: 0, position: 'static', borderBottomRightRadius: 0, borderTopRightRadius: 0 }}><i className="icon-bell"/></Badge>
        <Badge color="info" style={{ margin: 0, position: 'static', borderRadius: 0 }}>-</Badge>
        <Badge color="warning" style={{ backgroundColor: '#FFC859', margin: 0, position: 'static', borderRadius: 0 }}>-</Badge>
        <Badge color="warning" style={{ backgroundColor: '#FFA059', margin: 0, position: 'static', borderRadius: 0 }}>-</Badge>
        <Badge color="warning" style={{ backgroundColor: '#E97659', margin: 0, position: 'static', borderRadius: 0 }}>-</Badge>
        <Badge color="danger" style={{ backgroundColor: '#E45959', margin: 0, position: 'static', borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}>-</Badge></Nav>);
    }
    let primap = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    for (let i = 0; i < this.props.alarms.length; i++) {
      primap[this.props.alarms[i].priority]++;
    }
    const colMap = { 0: '', 1: 'info', 2: '#FFC859', 3: '#FFA059', 4: '#E97659', 5: '#E45959' };
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle} style={{ paddingRight: '30px' }} className={this.state.updated === true ? 'alarmlist animated heartBeat' : ''}>
        <DropdownToggle nav>
          <Badge style={{ margin: 0, position: 'static', borderBottomRightRadius: 0, borderTopRightRadius: 0 }}><i className="icon-bell"/></Badge>
          <Badge color="info" style={{ margin: 0, position: 'static', borderRadius: 0 }}>{primap[1]}</Badge>
          <Badge color="warning" style={{ backgroundColor: '#FFC859', margin: 0, position: 'static', borderRadius: 0 }}>{primap[2]}</Badge>
          <Badge color="warning" style={{ backgroundColor: '#FFA059', margin: 0, position: 'static', borderRadius: 0 }}>{primap[3]}</Badge>
          <Badge color="warning" style={{ backgroundColor: '#E97659', margin: 0, position: 'static', borderRadius: 0 }}>{primap[4]}</Badge>
          <Badge color="danger" style={{ backgroundColor: '#E45959', margin: 0, position: 'static', borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}>{primap[5]}</Badge>
        </DropdownToggle>
        <DropdownMenu right style={{ width: '800px', fontSize: '12px' }}>
          <DropdownItem header className="text-center"><strong>Latest 10 Alarms</strong></DropdownItem>
          {this.sort(this.props.alarms).slice(0, 10).map((alarm) => <DropdownItem className={'callout m-0 callout-info'} style={{ padding: '5px', borderLeftColor: colMap[alarm.priority] }} key={alarm.triggerid}>
            <Row><Col md={2}>{moment(alarm.lastchange * 1000).fromNow()}</Col><Col md={1}>
              {alarm.lastEvent.eventid in this.props.acks
                ? <Badge color="success">Yes ({this.props.acks[alarm.lastEvent.eventid].length})</Badge>
                : <Badge>No</Badge>}
            </Col><Col md={9} style={{ whiteSpace: 'nowrap' }}>{alarm.description}</Col></Row>
          </DropdownItem>)}
          <DropdownItem className="text-center" onClick={() => this.props.navigate('/toolbox/alarms')}><i className="icon-bell"/> View {this.props.alarms.length - 10} More Alarms</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const HeaderZabbixAlarms = ({ alarms, maxeventid, acks }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return <HeaderZabbixAlarmsLegacy alarms={alarms} maxeventid={maxeventid} acks={acks} navigate={navigate} dispatch={dispatch} />;
}

function mapStateToProps({ zabbixAlarms }) {
  return {
    alarms: zabbixAlarms.alarms,
    maxeventid: zabbixAlarms.maxeventid,
    acks: zabbixAlarms.acks
  };
}

export default connect(mapStateToProps)(HeaderZabbixAlarms);
