import { defaultErrorFeedback, successFeedback } from './feedback';
import { api_searchAccounts } from '../utils/Account/AccountServiceDeprecated';
import { api_searchConfigItems } from '../utils/ConfigItems';
import {
  api_createCircuit,
  api_getCircuit,
  api_getCircuitEmails,
  api_updateCircuit
} from '../utils/Circuits/CircuitService';
import { api_searchOrders } from '../utils/Orders';
import ProductService, { api_searchProducts } from '../utils/Products/ProductService';
import AccountService from '../utils/Account';
import SiteService from '../utils/Site/SiteService';

export const CIRCUIT_SET_DATA = 'CIRCUIT_SET_DATA';
export const CIRCUIT_RESET_DATA = 'CIRCUIT_RESET_DATA';
export const CIRCUIT_UPDATE_FIELD = 'CIRCUIT_UPDATE_FIELD';
export const CIRCUIT_REPLACE_DATA = 'CIRCUIT_REPLACE_DATA';
export const CIRCUIT_SET_ASYNC_SEARCH_OPTIONS = 'CIRCUIT_SET_ASYNC_SEARCH_OPTIONS';
export const CIRCUIT_ADD_NEW_NOTE = 'CIRCUIT_ADD_NEW_NOTE';
export const CIRCUIT_UPDATE_NOTE = 'CIRCUIT_UPDATE_NOTE';
export const CIRCUIT_REMOVE_NOTE = 'CIRCUIT_REMOVE_NOTE';
export const CIRCUIT_ADD_NEW_CASE = 'CIRCUIT_ADD_NEW_CASE';
export const CIRCUIT_SET_EMAILS = 'CIRCUIT_SET_EMAILS';
export const CIRCUIT_SET_EMAIL = 'CIRCUIT_SET_EMAIL';
export const CIRCUIT_UPDATE_CASE = 'CIRCUIT_UPDATE_CASE';
export const CIRCUIT_REMOVE_PRODUCT = 'CIRCUIT_REMOVE_PRODUCT';

export function updateCircuitField(field, value) {
  return {
    type: CIRCUIT_UPDATE_FIELD,
    field,
    value
  };
}

function setEntitySearchOptions(key, options) {
  return {
    type: CIRCUIT_SET_ASYNC_SEARCH_OPTIONS,
    key,
    options
  };
}

export function resetCircuit() {
  return {
    type: CIRCUIT_RESET_DATA
  };
}

export function addNewCircuitNote(note) {
  return {
    type: CIRCUIT_ADD_NEW_NOTE,
    note
  };
}

export function updateCircuitNote(note) {
  return {
    type: CIRCUIT_UPDATE_NOTE,
    note
  };
}

export function removeCircuitNote(note) {
  return {
    type: CIRCUIT_REMOVE_NOTE,
    note
  };
}

export function addNewCircuitCase(data) {
  return {
    type: CIRCUIT_ADD_NEW_CASE,
    data
  };
}

export function updateCircuitCase(data) {
  return {
    type: CIRCUIT_UPDATE_CASE,
    data
  };
}
export function removeCircuitProduct(id) {
  return {
    type: CIRCUIT_REMOVE_PRODUCT,
    id
  };
}


export function searchAccounts(key, searchString, parentsOnly = false) {
  return (dispatch) => {
    return AccountService.search(searchString, true).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setEntitySearchOptions(key, result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return [];
      }
    );
  };
}

export function searchSites(key, searchString) {
  return (dispatch) => {
    return SiteService.list(null, [{ id: 'name', value: searchString }] ).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setEntitySearchOptions(key, result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return [];
      }
    );
  };
}

export function searchConfigItems(key, searchString) {
  return (dispatch) => {
    return api_searchConfigItems(searchString).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setEntitySearchOptions(key, result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return [];
      }
    );
  };
}

export function searchOrders(key, searchString) {
  return (dispatch) => {
    return api_searchOrders(searchString).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setEntitySearchOptions(key, result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return [];
      }
    );
  };
}

export function searchProducts(sorted = [], search = [], include = [], searchJoin = 'and', withArr = [], activeOnly) {
  return (dispatch) => {
    return ProductService.list(sorted, search, include, searchJoin, withArr, activeOnly).then((result) => {
      if (result.status === 200) {
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return [];
    })
  };
}

export function createCircuit(circuit) {
  return (dispatch) => {
    return api_createCircuit(circuit).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return null;
        }
      }
    );
  };
}

export function updateCircuit(id, args) {
  return (dispatch) => {
    return api_updateCircuit(id, args).then(
      (result) => {
        if (result.status === 200) {
          dispatch({ type: CIRCUIT_REPLACE_DATA, data: result.data });
          dispatch(successFeedback('Circuit updated!'));
          return result.data
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    );
  };
}

export function getCircuit(id) {
  return (dispatch) => {
    return api_getCircuit(id).then((result) => {
      if (result.status === 200) {
        dispatch({ type: CIRCUIT_REPLACE_DATA, data: result.data });
      } else if (result.status === 404) {
        return result;
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getParentCircuit(id) {
  return (dispatch) => {
    return api_getCircuit(id).then((result) => {
      if (result.status === 200) {
        dispatch(setEntitySearchOptions('parentCircuit', [result.data]));
        return [result.data];
      }
      if (result.status === 404) {
        return [];
      }

      dispatch(defaultErrorFeedback());
    });
  };
}

export function getCircuitCases(id) {
  return (dispatch) => {
    return api_getCircuit(id).then((result) => {
      if (result.status === 200) {
        dispatch({ type: CIRCUIT_REPLACE_DATA, data: result.data });
      } else if (result.status === 404) {
        return result;
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function setCircuitEmail(email) {
  return {
    type: CIRCUIT_SET_EMAIL,
    email
  }
}

function setCircuitEmails(emails) {
  return {
    type: CIRCUIT_SET_EMAILS,
    emails
  };
}

export function getCircuitEmails(id) {
  return (dispatch) => {
    return api_getCircuitEmails(id).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setCircuitEmails(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    )
  }
}
