import { compareApiRequest } from '../Api/ApiRequest';

export function api_generateCompareQuote(args) {
  return compareApiRequest({
    url: `/quotes`,
    method: 'post',
    data: { ...args }
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getCompareV2Quote(id, includesArr) {
  let includes = includesArr ? '?include=' + includesArr.join(',') : '';
  return compareApiRequest({
    url: `/quotes/${id}${includes}`
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getCompareV2Quotes(postcode, page, size, owner) {
  let query = postcode ? `?postcode=${postcode}&page=${page}&size=${size}` : `?page=${page}&size=${size}`;
  if (owner) {
    query = `${query}&owner=${owner}`;
  }
  return compareApiRequest({
    url: `/quotes${query}`
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_updateQuoteSitePricePreferred(priceId, preferred) {
  return compareApiRequest({
    url: `/prices/${priceId}/preferred`,
    method: 'put',
    data: { preferred }
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_updateQuote(quoteId, args) {
  return compareApiRequest({
    url: `/quotes/${quoteId}`,
    method: 'put',
    data: args
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_repriceCompareQuote(quoteId, args) {
  return compareApiRequest({
    url: `/quotes/${quoteId}/re-price`,
    method: 'post',
    data: { ...args }
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_downloadCompareV2QuoteFeasibility(quoteId) {
  return compareApiRequest({
    url: `/quotes/${quoteId}/feasibility`,
    method: 'get',
    responseType: 'blob'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getCompareUsers() {

  return compareApiRequest({
    url: `/users`
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

