import { Card, CardBody } from 'reactstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
import OpportunitiesTable from '../../components/Tables/Opportunities';
import React, { useEffect, useState } from 'react';
import SignaturesTable from '../../components/Tables/Signatures';
import { connect } from 'react-redux';
import { getSignatures } from '../../actions/signatures';
import { addBreadcrumbs } from '../../actions/breadcrumbs';
import debounce from 'debounce-promise';

const Signatures = ({
  dispatch,
  signatures: {
    list,
    table
  }
}) => {
  const [loading, setLoading] = useState(false);
  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }

  useEffect(() => {
    fetchData(1, 50, '', [{id: 'updatedAt', desc:true}])
  }, [])

  const fetchData = (page, size, searchString, sorting) =>{
    toggleLoading()
    dispatch(getSignatures(page, size, searchString, sorting)).then(() => toggleLoading())
  }

  const debounced = debounce((page, size, searchString, sorting) => fetchData(page, size, searchString, sorting), 500);

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <SignaturesTable
              signatures={list}
              table={table}
              fetchData={(page, size, searchString, sorting) => debounced(page, size, searchString, sorting)}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>

    </div>
  )
}


function mapStateToProps({
  signatures,
  authenticationState
}) {
  return {
    signatures,
    user: authenticationState.account
  };
}

export default connect(mapStateToProps)(Signatures);