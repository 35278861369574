import {
  DEVICE_DEPLOYMENTS_SET_DATA,
  DEVICE_DEPLOYMENTS_UPDATE_DATA
} from '../actions/deviceDeployments';

const initialState = {
  deviceDeployments: [],
  table: {lastPage: 1}
};

const deviceDeployments = (state = initialState, action) => {
  switch (action.type) {
    case DEVICE_DEPLOYMENTS_SET_DATA:{
      const { currentPage, total, perPage, path, lastPage, items } = action.data;
      return {
        deviceDeployments: items,
        table: {currentPage, total, perPage, path, lastPage}
      };
    }
    case DEVICE_DEPLOYMENTS_UPDATE_DATA:
      const { items } = action.data;
      return  {
        ...state,
        deviceDeployments: state.deviceDeployments.map(deviceDeployment => ({...deviceDeployment, ...(items.find(record => record.id === deviceDeployment.id) || {})})),
      }
    default:
      return state;
  }
};

export default deviceDeployments;