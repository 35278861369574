import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import generateFormFields from '../../helpers/FormFieldGenerator';
import form from './form';
import {
  createPartnership,
  deletePartnership,
  getPartnershipData,
  resetPartnership,
  updatePartnershipFormField
} from '../../actions/partnership';
import { connect } from 'react-redux';
import { searchAccounts } from '../../actions/case';
import LoadingOverlay from '../../components/LoadingOverlay';
import { ButtonIcon } from '../../components/ButtonIcon';
import classnames from 'classnames';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import partnershipForm from './form';
import { formValidator } from '../../helpers/FormValidator';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import AccountService from '../../utils/Account';
import { setConfirmDialog } from '../../actions/dialogs';

const Partnership = ({
  customerId,
  original,
  form,
  dispatch,
  optionSets,
  account,
  onCreated,
  onDeleted,
  onClose
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const isNew = !customerId && !original.customerId;
  const toggleLoading = () => setLoading(prevState => !prevState);
  const getData = () => {
    toggleLoading()
    dispatch(getPartnershipData(account.original.accountid, customerId || original.customerId, ['partner', 'customer']))
      .then(() => {
        toggleLoading()
      });
  };

  useEffect(() => {
    if (!isNew) {
      getData();
    } else {
      dispatch(updatePartnershipFormField('partner', account.original));
    }
    return () => {
      dispatch(resetPartnership());
    };
  }, []);

  const save = () => {
    if (validated() && isNew) {
      toggleLoading()
      dispatch(createPartnership(
        account.original.accountid,
        {customerId: form.customer.accountid, type: form.type },
        ['partner', 'customer']
        )
      ).then((result) => {
        if (result && onCreated) {
          onCreated(result)
        }
        toggleLoading()
      });
    }
  };

  const remove = () => {
    toggleLoading()
    dispatch(deletePartnership(account.original.accountid, customerId || original.customerId)).then((response) => {
      toggleLoading()
      if (response && onDeleted) {
        onDeleted(customerId || original.customerId);
      }
    });
  };

  const getSelectOptions = (field) => {
    if (optionSets[field]) {
      return optionSets[field].options.map((option) => {
        return {
          value: option.value,
          label: option.label
        };
      });
    }
    return [];
  };

  const getSelectedOption = (field) => {
    let selected = [];
    if (optionSets[field]) {
      selected = optionSets[field].options.filter(option => option.value === form[field]);
    }
    if (selected.length) {
      return selected[0];
    }
    return null;
  };

  const handleInput = (e) => {
    dispatch(updatePartnershipFormField(e.target.id, e.target.value));
  };

  const handleSelectInput = (key, selected) => {
    dispatch(updatePartnershipFormField(key, selected.value));
  };

  const alreadySelected = (id) => {
    return account.partnerships.find(partnership => partnership.customer.accountid === id)
  };

  const handleAsyncInput = (entity, key, length, search) => {
    if (!search || search.length < length || !/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/.test(search)) {
      return new Promise(() => []);
    }
    if (entity === 'account') {
      return AccountService.search(search, true)
        .then((result) => {
          if(result.status === 200){
            return result.data.filter(account => !alreadySelected(account.accountid))
          }

        });
    }
  };

  const handleAsyncSelected = (key, selected) => {
    dispatch(updatePartnershipFormField(key, selected));
  };

  const validated = () => {
    setErrors([])
    const errors = formValidator(partnershipForm.new, form)
    setErrors(errors)
    return isEmpty(errors)
  };

  const onDelete = () => {
    dispatch(setConfirmDialog({
      text: "This will delete the partnership.",
      proceed: () => remove()
    }))
  }

  return (

    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ml-auto')}>
                  {isNew ?
                    <ButtonIcon disabled={loading} icon={'fa fa-save'} tooltip={'Save'}  onClick={save}/>
                    :
                    <ButtonIcon disabled={loading} icon={'fa fa-trash'} tooltip={'Delete'}  onClick={onDelete}/>
                  }

                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={getData}/>
                  <ButtonIcon onClick={onClose} icon='fa fa-close' tooltip={'Close Popup'}/>
                </div>

              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row form>
              {generateFormFields({
                fields: isNew ? partnershipForm.new : partnershipForm.readOnly,
                data: { ...form, isNew },
                handleInput,
                getSelectOptions,
                getSelectedOption,
                handleSelectInput,
                handleAsyncSelected,
                handleAsyncInput,
                optionSets
              })}
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  );
};

const mapStateToProps = ({ helpers, partnership, account }) => {
  return {
    optionSets: helpers.optionSets.partnership,
    form: partnership.form,
    original: partnership.original,
    account
  };
};
export default connect(mapStateToProps)(Partnership);
