import { Badge, Button } from 'reactstrap';
import { ButtonIcon } from '../../ButtonIcon';
import FilteredTableV2 from '../../FilteredTableV2';
import React, { useState } from 'react';
import moment from 'moment';
import BillTemplateGroup from '../../../views/Billing/BillTemplateGroup';
import HeadlessModal from '../../Modals/HeadlessModal';

const BillTemplateGroupsTable = ({
  groups,
  onCreate,
  onUpdate
}) => {
  const [showModal, setShowModal] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const toggleModal = () => setShowModal(prevState => !prevState)

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 250,
    },
    {
      header: 'Description',
      accessorKey: 'description'
    },
    {
      header: 'Created On',
      accessorKey: 'createdOn',
      cell: (props) => {
        return moment(props.getValue())
          .format('DD/MM/YYYY HH:mm:ss');
      }

    },
    {
      header: 'Modified On',
      accessorKey: 'modifiedOn',
      cell: (props) => {
        return moment(props.getValue())
          .format('DD/MM/YYYY HH:mm:ss');
      }
    },
    {
      header: 'Status',
      accessorKey: 'isActive',
      cell: props =>
        <Badge style={{minWidth: '86px'}} className={`p-2 faded-${props.getValue() ? 'success' : 'warning'}`}>{props.getValue() ? 'Active' : 'Deactivated'}</Badge>

    },
  ]

  return <>
    <FilteredTableV2
      withFilter={'includesString'}
      withDeactivatedRow
      minRows={5}
      columns={columns}
      data={groups}
      noDataText="No product groups"
      showPagination={groups.length > 5}
      defaultPageSize={5}
      defaultSorted={[{
        id: 'modifiedOn',
        desc: true
      }]}
      onRowClick={(group) => {
        setGroupId(group.id)
        toggleModal()
      }}
      extraButtons={[
        <Button
          onClick={toggleModal}
          colour={'secondary'}
          size='sm'
          color='secondary'>
          New Group
        </Button>
      ]}
    />
    <HeadlessModal
      open={showModal}
      onClosed={() => setGroupId(null)}
      size={'lg'}
      toggle={toggleModal}
    >
      <BillTemplateGroup
        id={groupId}
        closeModal={
        () => {
          toggleModal()
          setGroupId(null)
        }}
        onCreate={onCreate}
        onUpdate={onUpdate}
      />
    </HeadlessModal>
  </>
}

export default BillTemplateGroupsTable