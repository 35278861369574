import {apiRequest} from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const VendorService = {
    getVendors: (search, orderBy, sortedBy, searchJoin, includeArr = [], withArr = []) => {
        let query = {
            orderBy,
            sortedBy,
            searchJoin,
            search,
            include: includeArr.join(','),
            with: withArr.join(';')
        };
        return apiRequest({
            url: `/sdb/vendors${queryStringFromFields(query)}`,
            method: 'get'
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    getVendor: (id, include, withs) => {
        let query = {
            include,
            with: withs
        };
        return apiRequest({
            url: `/sdb/vendors/${id}${queryStringFromFields(query)}`,
            method: 'get'
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    createVendor: (data, include = []) => {
        return apiRequest({
            url: `/sdb/vendors${queryStringFromFields({ include })}`,
            method: 'post',
            data
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    updateVendor: (id, data, include = []) => {
        return apiRequest({
            url: `/sdb/vendors/${id}${queryStringFromFields({ include })}`,
            method: 'put',
            data
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    deleteVendor: (id) => {
        return apiRequest({
            url: `/sdb/vendors/${id}`,
            method: 'delete'
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    }
};