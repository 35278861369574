import { defaultErrorFeedback } from './feedback';
import {
  api_deleteCompareQueueFailedJob,
  api_deleteQueueFailedJob, api_getCompareQueueFailedJob,
  api_getQueueFailedJob, api_listCompareQueueFailedJobs, api_listCompareQueues,
  api_listQueueFailedJobs,
  api_listQueues, api_retryCompareQueueFailedJob, api_retryQueueFailedJob
} from '../utils/Queues';

export const QUEUES_SET_SUMMARY = 'QUEUES_SET_SUMMARY';
export const QUEUES_RESET_SUMMARY = 'QUEUES_RESET_SUMMARY';
export const QUEUES_SET_QUEUE = 'QUEUES_SET_QUEUE';
export const QUEUES_SET_FAILED_JOBS = 'QUEUES_SET_FAILED_JOBS';
export const QUEUES_SET_TOGGLE_SUMMARY_LOADING = 'QUEUES_SET_TOGGLE_SUMMARY_LOADING';
export const QUEUES_SET_TOGGLE_FAILED_JOBS_LOADING = 'QUEUES_SET_TOGGLE_FAILED_JOBS_LOADING';
export const QUEUES_DELETE_FAILED_JOB = 'QUEUES_DELETE_FAILED_JOB';
export const QUEUES_SET_FAILED_JOB = 'QUEUES_SET_FAILED_JOB';
export const QUEUES_TOGGLE_FAILED_JOB_MODAL = 'QUEUES_TOGGLE_FAILED_JOB_MODAL';

const setQueueSummary = (data) => {
  return {
    type: QUEUES_SET_SUMMARY,
    data
  };
};

export const resetQueueSummary = (data) => {
  return {
    type: QUEUES_RESET_SUMMARY,
    data
  };
};

const setQueueFailedJobs = (jobs, table) => {
  return {
    type: QUEUES_SET_FAILED_JOBS,
    jobs,
    table
  };
};

const removeFailedJob = (id) => {
  return {
    type: QUEUES_DELETE_FAILED_JOB,
    id
  };
};

const setFailedJob = (job) => {
  return {
    type: QUEUES_SET_FAILED_JOB,
    job
  };
};

export const toggleFailedJobModal = () => {
  return {
    type: QUEUES_TOGGLE_FAILED_JOB_MODAL
  };
};

export const setQueue = (queue) => {
  return {
    type: QUEUES_SET_QUEUE,
    queue
  };
};

export const toggleQueueSummaryLoading = () =>{
  return {
    type: QUEUES_SET_TOGGLE_SUMMARY_LOADING
  };
};

export const toggleQueueFailedJobsLoading = () =>{
  return {
    type: QUEUES_SET_TOGGLE_FAILED_JOBS_LOADING
  };
};

export function getQueueSummary() {
  return (dispatch) =>{
    return api_listQueues().then((result) => {
      if (result.status === 200) {
        dispatch(setQueueSummary(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getCompareQueueSummary() {
  return (dispatch) =>{
    return api_listCompareQueues().then((result) => {
      if (result.status === 200) {
        dispatch(setQueueSummary(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getQueueFailedJobs(queue, page, size) {
  return (dispatch) =>{
    return api_listQueueFailedJobs(queue, page, size).then((result) => {
      if (result.status === 200) {
        const { items, currentPage, total, perPage, path, lastPage } = result.data;
        dispatch(setQueueFailedJobs(items, { currentPage, total, perPage, path, lastPage }));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getCompareQueueFailedJobs(queue, page, size) {
  return (dispatch) =>{
    return api_listCompareQueueFailedJobs(queue, page, size).then((result) => {
      if (result.status === 200) {
        const { items, currentPage, total, perPage, path, lastPage } = result.data;
        dispatch(setQueueFailedJobs(items, { currentPage, total, perPage, path, lastPage }));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function deleteQueueFailedJob(id) {
  return (dispatch) =>{
    return api_deleteQueueFailedJob(id).then((result) => {
      if (result.status === 200) {
        dispatch(removeFailedJob(id));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function deleteCompareQueueFailedJob(id) {
  return (dispatch) =>{
    return api_deleteCompareQueueFailedJob(id).then((result) => {
      if (result.status === 200) {
        dispatch(removeFailedJob(id));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getQueueFailedJob(id) {
  return (dispatch) =>{
    return api_getQueueFailedJob(id).then((result) => {
      if (result.status === 200) {
        dispatch(setFailedJob(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getCompareQueueFailedJob(id) {
  return (dispatch) =>{
    return api_getCompareQueueFailedJob(id).then((result) => {
      if (result.status === 200) {
        dispatch(setFailedJob(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function retryQueueFailedJob(id) {
  return (dispatch) =>{
    return api_retryQueueFailedJob(id).then((result) => {
      if (result.status === 200) {
        dispatch(removeFailedJob(id));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function retryCompareQueueFailedJob(id) {
  return (dispatch) =>{
    return api_retryCompareQueueFailedJob(id).then((result) => {
      if (result.status === 200) {
        dispatch(removeFailedJob(id));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}
