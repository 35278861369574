import { deepFreeze } from '../Helpers/DeepFreeze';

export const OrderEnums = deepFreeze({
  status: {
    SUBMITTED: 1
  },
  statusReason: {
    NO_MONEY: 4,
    FULFILLED: 200012,
    HANDOFF_TO_BILLING: 200016,
    RED_FILE_UNDER_REVIEW: 200018,
    SALES_INITIAL: 200028,
    CANCELLED: 200030,
    ON_HOLD_CUSTOMER: 200031,
    RED_FILE_ACCEPTED_INTO_SD: 200004,
    SUPERSEDED: 279640000,
    CANCEL_WAITING_FOR_FINAL_BILL: 279640005,
    RED_FILE_REJECTED_BY_SD: 200023,
    CANCEL_INITIAL_PERIOD_CHECKED: 200029,
    CANCEL_PENDING: 279640002,
    CANCEL_SD_CHECKS: 279640003,
    CANCEL_FINANCE_PROCESSING: 279640004,
    RED_FILE_REJECTED: 200022,
    RED_FILE_APPROVED: 200021,
    SALES_SIGNED_CONTRACT_ORDER: 200020,
    RED_FILE_REJECTED_INTO_SD: 200004,
  }
});


