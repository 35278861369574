import { Badge, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import moment from 'moment/moment';
import RedFileSignOffBadge from '../../components/Badges/RedFileSignOffBadge';

const RedFileSignOff = ({
  order,
  optionSets,
  users
}) => {

  const groups =  {
    'Credit Check' : ['creditCheckSigner', 'creditCheckDate', 'creditCheckStatus'],
    Feasibility : ['salesSigner', 'salesDate', 'salesStatus'],
    Legal : ['legalSigner', 'legalDate', 'legalStatus'],
    Technical : ['technicalSigner', 'technicalDate', 'technicalStatus'],
    Business : ['businessSigner', 'businessDate', 'businessStatus']
  }

  const getBadgeColor = (value) => {
    switch (value) {
      case 1:
        return 'success';
      case 2:
        return 'danger';
      case 3:
        return 'warning'
      default:
        return '';
    }
  }

  const getBadgeText = (value) => {
    return optionSets.redFileStatus.options.find(option => option.value === value)?.label
  }

  const getUser = (value) => {
    return users.find(user => user.id === value)?.name ?? ''
  }

  return (
    <ListGroup className={'d-flex'} flush>
      {Object.keys(groups).map((group, index) => {
        const rfKey = groups[group][2]
        const signerKey = groups[group][0]
        return <ListGroupItem key={`rfso-${index}`} className={'d-flex flex-row align-items-center p-1'}>
          <span className='font-weight-bold'>{group}</span>
          <span className='font-weight-lighter ml-auto font-italic font-xs'>{getUser(order[signerKey])}</span>
          <span className='font-weight-lighter font-italic font-xs ml-2'>{order[groups[group][1]] ? moment(order[groups[group][1]]).format('DD/MM/YYYY HH:mm') : ''}</span>
          <div className='p-1 ml-2' ><RedFileSignOffBadge state={getBadgeText(order[rfKey])}/></div>


        </ListGroupItem>
      })}
    </ListGroup>

  )
}

export default RedFileSignOff;
