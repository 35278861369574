import { checkPermission } from '../../../utils/Auth/AuthService';

const AccountForm =  {
  infoFields: {
    accountnumber: {
      label: 'Account Number',
      type: 'text',
      plaintext: true,
      width: 12,
      show: (field, data, permissions) => Boolean(data.accountnumber)
    },
    name: {
      label: 'Name',
      type: 'text',
      mandatory: true,
      width: 12,
      maxlength: 160
    },
    accountAlias: {
      label: 'Alias',
      type: 'text',
      width: 12,
      maxlength: 100
    },
    accountManager: {
      label: 'Account Manager',
      type: 'select',
      mandatory: true,
      width: 12
    },
    primaryContact: {
      label: 'Primary Contact',
      type: 'select',
      width: 12,
      clearable: true,
      show: (field, data, permissions) => Boolean(data.accountnumber)
    },
    customerType: {
      label: 'Customer Type',
      type: 'select',
      mandatory: true,
      width: 12
    },
    industry: {
      label: 'Industry',
      type: 'select',
      mandatory: true,
      width: 12
    },
    isRM3808: {
      label: 'Is RM3808?',
      type: 'select-boolean',
      width: 12
    },
    totalEmployees: {
      label: 'Employees',
      type: 'text',
      width: 12
    },
    website: {
      label: 'Website',
      type: 'text',
      width: 12
    },
    popupText: {
      label: 'Popup Text',
      type: 'textarea',
      width: 12
    },
    projectManager: {
      label: 'Project Manager',
      type: 'select',
      clearable: true,
      width: 12
    },
    email: {
      label: 'Email',
      type: 'text',
      width: 12
    },
    priceListType: {
      label: 'Preferred Price List',
      type: 'select',
      width: 12
    },
  },
  contactFields: {
    addressLine1: {
      label: 'Address Line 1',
      type: 'text',
      mandatory: true,
      width: 6,
      maxlength: 250
    },
    addressLine2: {
      label: 'Address Line 2',
      type: 'text',
      width: 6,
      maxlength: 250
    },
    addressLine3: {
      label: 'Address Line 3',
      type: 'text',
      width: 6,
      maxlength: 250
    },
    addressCity: {
      label: 'City',
      type: 'text',
      mandatory: true,
      width: 6,
      maxlength: 80
    },
    addressCounty: {
      label: 'County',
      type: 'text',
      mandatory: false,
      width: 6,
      maxlength: 50
    },
    addressCountry: {
      label: 'Country',
      type: 'select',
      mandatory: true,
      width: 6
    },
    addressPostcode: {
      label: 'Postcode',
      type: 'text',
      mandatory: true,
      width: 6,
      maxlength: 20
    },
    telephone: {
      label: 'Phone',
      type: 'text',
      mandatory: true,
      width: 6,
      maxlength: 50
    },
    telephone2: {
      label: 'Phone 2',
      type: 'text',
      width: 6,
      maxlength: 50
    },
  },
  legalFields: {
    companyRegNum: {
      label: 'Company Reg',
      type: 'text',
      width: 12
    },
    vatNumber: {
      label: 'VAT Number',
      type: 'text',
      width: 12
    },
    legalCheck: {
      label: 'Legal Check',
      type: 'select',
      width: 12,
      callBack: (key, field, data, optionSets) => data[key] !== undefined && data[key] !== null ? optionSets.legalCheck.options.find(x => x.value === data[key]).label : '',
      plaintext: (key, field, data, permissions) => !checkPermission('Legal', permissions)
    },
    legalCheckDate: {
      label: 'Legal Check Date',
      type: 'date',
      width: 12,
      plaintext: (key, field, data, permissions) => !checkPermission('Legal', permissions)
    },
    legalSignOffUser: {
      label: 'Legal Sign Off',
      type: 'text',
      readOnly: true,
      width: 12,
      callBack: (key, field, data) => data[key] !== undefined && data[key] !== null ? data[key].name : '',
      plaintext: (key, field, data, permissions) => !checkPermission('Legal', permissions)
    }
  },
  billingFields: {
    creditLimit: {
      label: 'Credit Limit',
      type: 'text',
      width: 12
    },
    paymentMethod: {
      label: 'Payment Method',
      type: 'select',
      width: 12
    },
    billingFrequency: {
      label: 'Billing Frequency',
      type: 'select',
      width: 12
    },
    paymentTerms: {
      label: 'Payment Terms',
      type: 'select',
      width: 12
    },
    vatApplicable: {
      label: 'VAT Applicable',
      type: 'select-boolean',
      width: 12
    },
    qbCode: {
      label: 'QB Code',
      type: 'text',
      width: 12
    },
    autoBill: {
      label: 'Auto Charge',
      callBack: (key, field, data) => data.autoBill ? 'Yes' : 'No',
      show: (field, data) => data.paymentMethod === 864640002,
      plaintext: true,
      width: 12
    }
  }
};

export default AccountForm
