const productForm = (amendable, currency)  => {

  return {
    general: {
      install: {
        label: 'Install',
        type: 'text',
        plaintext: !amendable,
        format: !amendable ? {money: currency} : undefined,
        width: 3,
        mandatory: amendable
      },
      rental: {
        label: 'Rental',
        type: 'text',
        plaintext: !amendable,
        format: !amendable ? {money: currency} : undefined,
        width: 3,
        mandatory: amendable
      },
      quantity: {
        label: 'Quantity',
        type: 'text',
        plaintext: true,
        width: 3
      },
      term: {
        label: 'Term',
        type: 'number',
        plaintext: !amendable,
        width: 3,
        min:0,
        max:60,
        mandatory: amendable
      },
      name: {
        label: 'Name',
        type: 'text',
        width: 12,
        mandatory: true
      },
      priceListItem: {
        label: 'Price List Item',
        type: 'select-async',
        width: 12,
        mandatory: true,
        show: amendable
      },
      order: {
        width: 4,
        label: 'Order',
        type: 'button-link',
        getLink: (data) => data.order ? `/sales/orders/${data.order.id}` : '#',
        isDisabled: (data) => !data.order,
        callBack: (key, field, data) => data[key] !== undefined && data[key] !== null ? data[key].orderNumber : 'N/A',
      },
      orderType: {
        width: 4,
        label: 'Order Type',
        type: 'text',
        plaintext: true,
        callBack: (key, field, data, optionSets) => optionSets.orderType.options.find(option => option.value === data?.orderType)?.label ?? ''
      },
      lifecycleStage: {
        width: 4,
        label: 'Lifecycle Stage',
        type: 'select',
        mandatory: true
      },
      company: {
        width: 12,
        label: 'Customer',
        type: 'button-link',
        getLink: (data) => data.company ? `/accounts/${data.company.accountid}` : '#',
        isDisabled: (data) => !data.company,
        callBack: (key, field, data) => data[key] !== undefined && data[key] !== null ? data[key].name : '',
      },

      site: {
        label: 'Site',
        type: 'select',
        width: 12,
        mandatory: true
      },
      description: {
        label: 'Description',
        type: 'textarea',
        width: 12,
        rows: 16
      },
    },
    serviceDelivery: {
      isDelivered: {
        label: 'Delivered',
        type: 'select',
        clearable: false
      },
      goLiveDate: {
        label: 'Go Live',
        type: 'date'
      },
      internalRfsDate: {
        label: 'Internal RFS',
        type: 'date'
      },
      installDate: {
        label: 'Install',
        type: 'date'
      },
      readyForServiceDate: {
        label: 'Ready For Service',
        type: 'date'
      },
      supplier: {
        label: 'Supplier',
        type: 'select',
        width: 12
      },
    },
    billing: {
      isBilled: {
        label: 'Billed',
        type: 'select-boolean'
      },
      noticePeriodDays: {
        label: 'Notice Period',
        type: 'text'
      },
      affinityBillingCodeInstall: {
        label: 'Billing Code - Install',
        type: 'text'
      },
      affinityBillingCodeRental: {
        label: 'Billing Code - Rental',
        type: 'text'
      },
      //hasUsageCharges: { label: 'Has Usage Charges', type: 'select-boolean'},
      billTemplate: {
        label: 'Bill Template',
        type: 'select',
        placeholder: 'Leave blank for default'
      },
      billTemplateGroup: {
        label: 'Bill Group',
        type: 'select',
      },
      billingCycle: {
        label: 'Billing Cycle',
        type: 'select',
        mandatory: true
      },
      billedTo: {
        label: 'Billed To',
        type: 'date'
      },
      validTo: {
        label: 'Valid To',
        type: 'date'
      },
      overageRate: {
        label: 'Overage Rate Per Megabit',
        type: 'text'
      },
      committedDataRate: {
        label: 'Committed Data Rate',
        type: 'text'
      },
    },
  }
}

export default productForm