import { apiRequest } from '../Api/ApiRequest';

const CampaignService = {

  getAll: (search = '') => {
    return apiRequest({
      url:`/campaigns?${search}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}

export default CampaignService;
