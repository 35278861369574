import { RACKS_SET_DATA } from '../actions/racks';

const initialState = {
  racks: [],
  table: {
    lastPage: 1,
  },
};
const racks = (state = initialState, action) => {
  switch (action.type) {
    case RACKS_SET_DATA:
      const { currentPage, total, perPage, path, lastPage, items } = action.data;
      return {
        ...state,
        racks: [...items],
        table: {currentPage, total, perPage, path, lastPage}
      }
    default:
      return state;
  }
};

export default racks;