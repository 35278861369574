import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { quoteHeader, p2pQuote, wanQuote, wanGEAQuote } from './forms';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  FormGroup
} from 'reactstrap';

import LoadingOverlay from '../../components/LoadingOverlay';
import generateFormFields from '../../helpers/FormFieldGenerator';
import {
  addPricingToolQuoteRequestSite,
  generateCompareQuote,
  getPricingToolQuote,
  lookupSitePostcode,
  removePricingToolQuoteRequestSite,
  resetPricingToolQuote,
  restoreQuoteRequest,
  updatePricingToolQuoteRequestField,
  updatePricingToolQuoteRequestSiteField,
} from '../../actions/pricingToolQuote';
import { joinRooms, removeProgress } from '../../actions/socketio';
import { useNavigate } from 'react-router-dom'
import { addNewPricingToolQuote, updatePricingToolQuote } from '../../actions/pricingTool';
import { addCompareV2QuoteServices, addQuoteProduct, updateQuoteField } from '../../actions/quote';
import startsWith from 'lodash.startswith'
import isEmpty from 'lodash.isempty'
import QuoteRequestSite from './QuoteRequestSite';
import { ButtonIcon } from '../../components/ButtonIcon';
import omitBy from 'lodash.omitby';
import isNull from 'lodash.isnull';

const solutions = [
  {
    label: 'Internet',
    value: 'internet'
  },
  {
    label: 'MPLS',
    value: 'mpls'
  },
  {
    label: 'SD-WAN',
    value: 'sd-wan',
  },
  {
    label: 'P2P',
    value: 'p2p',
  }
];
const bearers = [100, 1000, 10000];
const cdrs = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 500, 1000, 2000, 3000, 4000, 5000, 10000];
const terms = [1, 2, 3, 4, 5];
const QuoteRequest = (props) => {
  const {
    rerun,
    onComplete,
    name
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [siteLoading, setSiteLoading] = useState({});
  const [errors, setErrors] = useState([]);

  const quote = useSelector(state => state.pricingToolQuote);
  const progressBars = useSelector(state => state.socketioProgress.progressBars);
  const options = useSelector(state => state.helpers.pricingTool);

  const quoteProgress = progressBars[quote.request.result.uuid];

  useEffect(() => {
    if (rerun) {
      dispatch(restoreQuoteRequest());

    } else {
      dispatch(resetPricingToolQuote());
      const defaultFeasibility = options.feasibility.find(option => option.name === 'Enterprise') || null;
      if (defaultFeasibility) {
        dispatch(updatePricingToolQuoteRequestField({ feasibility: defaultFeasibility.id }));
      }
      dispatch(addPricingToolQuoteRequestSite(getSiteDefaults(getFibreTech())));
      dispatch(updatePricingToolQuoteRequestField({ name: name || 'Sales Quote' }));
    }

  }, []);
  useEffect(() => {
    if (quoteProgress && quoteProgress.status === 'complete') {
      dispatch(removeProgress(quote.request.result.uuid));
      dispatch(getPricingToolQuote(quote.request.result.uuid))
        .then((result) => {
          if (result) {
            dispatch(updatePricingToolQuote(quote.request.result.uuid, {
              status: 'COMPLETE',
              tcv: result.tcv
            }));
            if (!onComplete) {
              navigate(`/sales/pricing-tool/${quote.request.result.uuid}`);
            } else {
              dispatch(addCompareV2QuoteServices(result));
              dispatch(updateQuoteField({ pricingToolQuote: result.id }));
              onComplete();
            }

          }
        });

    }
  }, [quoteProgress]);

  const addSite = () => {
    const lastSite = quote.request.sites.slice(-1).pop()
    const tech = getTechById(lastSite?.technology) || getFibreTech();
    const defaults = {...getSiteDefaults(tech, lastSite?.solution || 'internet')}
    dispatch(addPricingToolQuoteRequestSite(defaults));
  };

  const getSiteDefaults = (tech, solution) => {
    const selfInstall = options.install.find(install => install.name === 'Self Install');
    return {
      technology: tech.id,
      install: selfInstall.id,
      term: 3,
      cdr: getDefaultCdr(tech),
      bearer_speed: getDefaultBearer(tech),
      kit:  getDefaultKit(tech, solution || 'internet').id,
      solution: solution || 'internet'
    };
  };
  const resolveForm = (siteIndex) => {
    const site = getSiteByIndex(siteIndex);
    const solution = site?.solution
    const technology = options.technology.find(tech => tech.id === site?.technology)
    if (solution !== 'p2p') {
      return isTechGEA(technology) ? wanGEAQuote : wanQuote;
    }
    return p2pQuote;
  };
  const resolveAddressField = (key) => {
    switch (key) {
      case 'a_address_id':
        return 'a_addresses';
      case 'b_address_id':
        return 'b_addresses';
      default:
        return 'addresses';
    }
  };
  const generate = () => {
    if (validated()) {
      setLoading(true);
      let request = {
        ...quote.request,
        sites: quote.request.sites.filter(site => site.solution !== 'p2p').map(site => {
          let siteTransformed = { ...site };
          ['include_carriers', 'exclude_carriers', 'include_nnis', 'exclude_nnis'].forEach(field => {
            if (siteTransformed[field].length === 0) {
              delete siteTransformed[field];
            }
          });
          delete siteTransformed.addresses;
          if(!isTechGEA(getTechById(site.technology))){
            delete siteTransformed.product;
          }
          return omitBy(siteTransformed, isNull);
        }),
        p2p : quote.request.sites.filter(site => site.solution === 'p2p').map((site) => {
          let siteTransformed = { ...site };
          ['include_carriers', 'exclude_carriers'].forEach(field => {
            if (siteTransformed[field].length === 0) {
              delete siteTransformed[field];
            }
          });
          delete siteTransformed.a_addresses;
          delete siteTransformed.b_addresses;
          if (!siteTransformed.kit) {
            delete siteTransformed.kit;
          }
          return siteTransformed;
        })
      };
      if (isEmpty(request.sites)) {
        delete request.sites;
      }
      if (isEmpty(request.p2p)){
        delete request.p2p;
      }

      delete request.result;

      dispatch(generateCompareQuote(request))
        .then((result) => {
          if (result) {
            dispatch(updatePricingToolQuoteRequestField({ result: { uuid: result.uuid } }));
            dispatch(addNewPricingToolQuote(result));
            dispatch(joinRooms({ rooms: [result.uuid] }));
          } else {
            setLoading(false);
          }

        });

    }
  };
  const validated = () => {
    let errorArr = [];
    if (quote.request.name.length === 0) {
      errorArr.push(`You must provide the quote name`);
    }
    if (quote.request.sites.length === 0) {
      errorArr.push(`You must provide at least one site`);
    }
    quote.request.sites.forEach((site, index) => {
      for (const [key, value] of Object.entries(resolveForm(index))) {
        if (value.mandatory
          && (
            quote.request.sites[index][key] === undefined ||
            quote.request.sites[index][key] === null ||
            quote.request.sites[index][key].length === 0
          )
          || (['cdr', 'bearer_speed'].includes(key) && !validSpeed(key, quote.request.sites[index][key]))
          || (key === 'technology' && !validTechnology(quote.request.sites[index][key]))
        ) {
          errorArr.push(`You must provide a valid ${value.label} on site #${index + 1}`);
        }
        if(key === 'include_nnis' && !isEmpty(quote.request.sites[index][key])){
          quote.request.sites[index][key].forEach(id => {
            if(!validNNI(id)){
              const nni = options.nnis.find(nni => nni.id === id)
              errorArr.push(`Deprecated NNI: ${nni.name} on site #${index + 1}`);
            }
          })
        }
      }
    });

    setErrors(errorArr);
    return errorArr.length === 0;
  };

  const validNNI = (id) => {
    return options.nnis.find(nni => nni.id === id)?.isActive
  }
  const validTechnology = (id) => {
    return options.technology.find(tech => tech.id === id)?.enabled
  }
  const validSpeed = (type, value) => {
    if (type === 'bearer_speed' && bearers.includes(parseInt(value))) {
      return true;
    }
    if (type === 'cdr' && cdrs.includes(parseInt(value))) {
      return true;
    }
    return false;
  };
  const getBearerOptions = () => {
    return bearers.map(bearer => {
      return {
        value: bearer,
        label: bearer
      };
    });
  };
  const getCdrOptions = (siteIndex) => {
    const options = cdrs.map(cdr => {
      return {
        value: cdr,
        label: cdr
      };
    });
    const bearer = getSiteByIndex(siteIndex).bearer_speed;
    if (bearer === 10000) {
      return options.filter(option => option.value >= 1000);
    } else if (bearer === 1000) {
      return options.filter(option => option.value <= 1000 && option.value >= 100);
    }
    return options.filter(option => option.value <= bearer);
  };
  const getTermOptions = () => {
    return terms.map(term => {
      return {
        value: term,
        label: term
      };
    });
  };
  const getTechOptions = () => {
    return options.technology.filter(tech => tech.enabled)
      .map(tech => {
        return {
          value: tech.id,
          label: tech.name
        };
      });
  };
  const getCarrierOptions = (type, siteIndex) => {
    const site = getSiteByIndex(siteIndex);
    const excludes = type === 'include_carriers' ? site.exclude_carriers : site.include_carriers;
    const technology = site.technology;
    if (excludes.length) {
      return [];
    }
    return getAvailableCarrierByTech(technology).map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };
  const getCarrierProductOptions = (siteIndex) => {
    const result = [];
    const technology = getSiteByIndex(siteIndex).technology;
    options.carrier.forEach(carrier => {
      carrier.products.forEach(product => {
        if(product.technology_id === technology){
          result.push({
            label: product.name,
            value: product.id
          })
        }
      })
    })
    return result;
  }

  const getCarrierProductById = (id) => {
    return options.carrierProducts.find(product => product.id === id);
  }
  const getNNIOptions = (type, siteIndex) => {
    let nnis = [];
    const site = getSiteByIndex(siteIndex);
    const excludes = type === 'include_nnis' ? site.exclude_nnis : site.include_nnis;
    const includedCarriers = site.include_carriers || [];
    const excludedCarriers = site.exclude_carriers || [];
    const technology = site.technology;
    const carriers = getAvailableCarrierByTech(technology);
    if (excludes.length) {
      return [];
    }
    if (includedCarriers.length) {
      nnis = getCarriersNNIs(carriers.filter(carrier => includedCarriers.includes(carrier.id))).concat(nnis)
    }
    if (excludedCarriers.length) {
      nnis = getCarriersNNIs(carriers.filter(carrier => !excludedCarriers.includes(carrier.id))).concat(nnis)
    }
    if (!includedCarriers.length && !excludedCarriers.length) {
      nnis = getCarriersNNIs(carriers).concat(nnis)
    }
    return nnis.map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };
  const getKitOptions = (siteIndex) => {
    const site = getSiteByIndex(siteIndex);
    const bearer = site.bearer_speed;
    const cdr = site.cdr;
    const solution = site.solution;
    const technology = solution === 'p2p' ? getFibreTech() : getTechById(quote.request.sites[siteIndex].technology);
    if (!bearer || !cdr || !technology) {
      return [];
    }
    const kits = options.kit.filter(kit => {
      return kit.name === 'Wires Only' || (kit.maxBearer >= bearer
      && kit.maxCdr >= cdr
      && kit.solutions.includes(solution)
      && kit.technology.find(tech => tech.id === technology.id));
    });

    return kits.map((kit) => {
      return {
        label: kit.name,
        value: kit.id
      };
    });

  };
  const getCarriersNNIs = (carriers) => {
    const result = [];
    carriers.forEach(carrier => {
      carrier.nnis.forEach(nni => result.push(nni))
    })
    return result.filter(nni => nni.isActive)
  }
  const getAvailableCarrierByTech = (technology) => {
    return options.carrier.filter(carrier => {
      return carrier.technologies.filter(tech => tech.id === technology && tech.enabled).length > 0
    })
  }

  const getSiteSolution = (siteIndex) => {
    return getSiteByIndex(siteIndex).solution
  }

  const getSiteByIndex = (siteIndex) => {
    return quote.request.sites[siteIndex]
  }
  const getFibreTech = () => {
    return options.technology.find(tech => tech.name === 'Fibre');
  }
  const getDefaultKit = (tech, solution) => {
    const wiresOnly = options.kit.find(kit => kit.name === 'Wires Only')
    const found = options.kit.filter(kit => {
      return kit.maxBearer >= getDefaultBearer(tech) &&
        kit.maxCdr >= getDefaultCdr(tech) &&
        kit.technology.find(kitTech => kitTech.id === tech.id) &&
        kit.solutions.includes(solution) &&
        kit.name !== 'Wires Only'
    })
    return !isEmpty(found) ? found[0] : wiresOnly;
  }
  const getDefaultBearer = (tech) => {
    if(tech.name === 'Fibre' || tech.name === 'RO2' ){
      return 1000;
    }else if(tech.name === 'GEA-FTTC' || tech.name === 'GEA-SOGEA'){
      return 80;
    }else if(tech.name === 'GEA-FTTP') {
      return 160;
    }
    return null;
  }
  const getDefaultCdr = (tech) => {
    if(tech.name === 'Fibre' || tech.name === 'RO2' ){
      return 1000;
    }else if(tech.name === 'GEA-FTTC' || tech.name === 'GEA-SOGEA'){
      return 20;
    }else if(tech.name === 'GEA-FTTP') {
      return 30;
    }
    return null;
  }

  const getDefaultGEAProduct = (techId, bearer, cdr) => {
    return options.carrierProducts.filter(product => product.technology_id === techId && product.max_bearer === bearer &&  product.max_cdr === cdr).shift().id;
  }

  const setDefaultValues = (techId, solution, index) => {
    const tech = getTechById(techId) || getFibreTech();
    if(isTechGEA(tech)){
      dispatch(updatePricingToolQuoteRequestSiteField(index, {product: getDefaultGEAProduct(tech.id, getDefaultBearer(tech), getDefaultCdr(tech))}))
    }
  }
  const getTechById = (id) => {
    return options.technology.find(tech => tech.id === id);
  };
  const isTechGEA = (technology) => {
    return startsWith(technology?.name, 'GEA')
  }
  const isSiteLoading = (index) => {
    return siteLoading[index];
  };
  const handleInputGroupClick = (index, field) => {
    let addressField = 'addresses';
    let udprnField = 'address_id';
    if (field === 'a_postcode') {
      addressField = 'a_addresses';
      udprnField = 'a_address_id';
    } else if (field === 'b_postcode') {
      addressField = 'b_addresses';
      udprnField = 'b_address_id';
    }
    if (quote.request.sites[index][field]) {
      setSiteLoading({ [index]: true });
      dispatch(lookupSitePostcode(index, `${quote.request.sites[index][field]}`.trim(), addressField))
        .then((result) => {
          if(result){
            dispatch(updatePricingToolQuoteRequestSiteField(index, { [udprnField]: result[0]?.id }));
          }
          setSiteLoading({ [index]: false });
        });
    }

  };
  const handleInputGroup = (siteIndex, field, value) => {
    dispatch(updatePricingToolQuoteRequestSiteField(siteIndex, { [field]: value }));
  };
  const getSelectOptions = (field, siteIndex) => {

    if (field === 'solution'){
      if(rerun){
        const site = {...quote.request.sites[siteIndex]};
        return solutions.map(solution => {
          return {...solution,
            isDisabled:
              site.id &&
              ((site.solution === 'p2p' && solution.value !== 'p2p') ||
              (site.solution !== 'p2p' && solution.value === 'p2p'))
          }
        })
      }
      return solutions
    } else if (/address/.test(field)) {
      return getSiteByIndex(siteIndex)[resolveAddressField(field)].map(address => {
        return {
          value: address.id,
          label: `${address.address}, ${address.place}`
        };
      });
    } else if (field === 'term') {
      return getTermOptions();
    } else if (field === 'bearer_speed') {
      return getBearerOptions();
    } else if (field === 'cdr') {
      return getCdrOptions(siteIndex);
    } else if (field === 'technology') {
      return getTechOptions();
    } else if (field === 'include_carriers' || field === 'exclude_carriers') {
      return getCarrierOptions(field, siteIndex);
    } else if (field === 'include_nnis' || field === 'exclude_nnis') {
      return getNNIOptions(field, siteIndex);
    } else if (field === 'kit') {
      return getKitOptions(siteIndex);
    } else if (field === 'product') {
      return getCarrierProductOptions(siteIndex);
    }
    if (options[field]) {
      return options[field].map((option) => {
        return {
          label: option.name,
          value: option.id
        };
      });
    }
    return [];

  };
  const getSelectedOption = (field, siteIndex) => {
    let selected;
    const site = getSiteByIndex(siteIndex)
    if (field === 'solution') {
      return solutions.find((type) => type.value === site[field]) || null;
    }
    if (/address/.test(field)) {
      const siteAddress = site[resolveAddressField(field)].find(
        address => address.id === site[field]
      );
      if (siteAddress) {
        return {
          value: siteAddress.id,
          label: `${siteAddress.address}${siteAddress.place ? `, ${siteAddress.place}` : ''}`
        };
      }
      return null;

    }
    if (['term', 'bearer_speed', 'cdr'].includes(field)) {
      return {
        label: site[field],
        value: site[field]
      };
    }
    if (['include_carriers', 'exclude_carriers'].includes(field)) {
      selected = options.carrier.filter((carrier) => {
        return site[field] && site[field].includes(carrier.id);
      });
      return selected.map(select => {
        return {
          label: select.name,
          value: select.id
        };
      });
    }
    if (['include_nnis', 'exclude_nnis'].includes(field)) {
      selected = options.nnis.filter((nni) => {
        return site[field] && site[field].includes(nni.id);
      });
      return selected.map(select => {
        return {
          label: select.name,
          value: select.id
        };
      });
    }
    if(field === 'product'){
      selected = getCarrierProductById(site.product);
    }
    if (field === 'install' || field === 'kit' || field === 'technology') {
      selected = options[field].find(option => option.id === site[field]);
    } else if (options[field]) {
      selected = options[field].find(option => option.id === quote.request[field]);
    }
    if (selected) {
      return {
        label: selected.name,
        value: selected.id
      };
    }

    return null;
  };
  const handleSelectInput = (field, selected, siteIndex) => {
    if (field === 'bearer_speed') {
      dispatch(updatePricingToolQuoteRequestSiteField(siteIndex, {
        cdr: null,
        kit: null
      }));
    }
    if (field === 'solution') {
      dispatch(updatePricingToolQuoteRequestSiteField(siteIndex, getSiteDefaults(getFibreTech(), selected.value)))
    }else if(field === 'product'){
      const product = getCarrierProductById(selected.value );
      dispatch(updatePricingToolQuoteRequestSiteField(siteIndex, { product: selected.value , bearer_speed: product.max_bearer, cdr: product.max_cdr}));
    } else if (['term', 'bearer_speed', 'cdr', 'address_id', 'a_address_id', 'b_address_id', 'type', 'install', 'kit', 'technology'].includes(field)) {
      dispatch(updatePricingToolQuoteRequestSiteField(siteIndex, { [field]: selected.value }));
    } else if (['include_carriers', 'exclude_carriers', 'include_nnis', 'exclude_nnis'].includes(field)) {
      const selectedValues = selected ? selected.map((select) => {
        return select.value;
      }) : [];
      dispatch(updatePricingToolQuoteRequestSiteField(siteIndex, { [field]: selectedValues }));
    } else {
      dispatch(updatePricingToolQuoteRequestField({ [field]: selected.value }));
    }
    if(field === 'technology'){
      dispatch(updatePricingToolQuoteRequestSiteField(
        siteIndex,
        {
          kit: getDefaultKit(getTechById(selected.value), getSiteSolution(siteIndex) ).id,
          bearer_speed: getDefaultBearer(getTechById(selected.value)),
          cdr: getDefaultCdr(getTechById(selected.value))
        })
      );
    }

  };
  const handleInput = (event) => {
    dispatch(updatePricingToolQuoteRequestField({ [event.target.id]: event.target.value }));
  };

  return (
    <div className='animated fadeIn'>
      <LoadingOverlay loading={loading} text={'Request in progress...'}>
        <Card>
          <CardBody>
            {errors.length > 0 && (
              <Row>
                <Col>
                  <Alert color={'danger'}>
                    {errors.map((error, index) => {
                      return <p className={'mb-0'} key={'error' + index}>{error}</p>;
                    })}
                  </Alert>
                </Col>
              </Row>
            )}
            <Row>
              {generateFormFields({
                fields: quoteHeader,
                handleInput,
                getSelectOptions,
                getSelectedOption,
                handleSelectInput,
                data: quote.request
              })}
              <Col>
                <FormGroup className={'d-flex justify-content-end'}>
                  <Button color={'primary'} onClick={generate}>Generate</Button>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {!loading && quote.request.sites.map((site, index) => {
                  return (
                    <QuoteRequestSite
                      loading={isSiteLoading(index)}
                      key={`site-${index}`}
                      index={index}
                      rerun={rerun}
                      name={`Site #${index + 1}`}
                      site={site}
                      form={resolveForm(index)}
                      getSelectOptions={(field) => getSelectOptions(field, index)}
                      getSelectedOption={(field) => getSelectedOption(field, index)}
                      handleSelectInput={(field, selected) => handleSelectInput(field, selected, index)}
                      handleInputGroup={(field, value) => handleInputGroup(index, field, value)}
                      handleInputGroupClick={(field) => handleInputGroupClick(index, field)}
                      remove={() => dispatch(removePricingToolQuoteRequestSite(site.uuid))}
                      setDefaultValues={(tech, solution) => setDefaultValues(tech, solution, index)}
                    />
                  )
                })}
              </Col>
            </Row>
            <Row>
              <Col className={'d-flex justify-content-end'}>
                <ButtonIcon
                  onClick={addSite}
                  tooltip={'add site'}
                  icon={'fa fa-plus'}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>

    </div>
  );

};

export default QuoteRequest;
