import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { getVendors } from '../../../actions/vendors';
import { connect, useDispatch } from 'react-redux'
import { isSdbManager } from '../../../utils/Auth/AuthService';
import VendorsTable from '../../../components/Tables/Vendors';
import LoadingOverlay from '../../../components/LoadingOverlay'

const Vendors = ({ vendors }) => {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }
  const fetch = () => {
    toggleLoading();
    dispatch(getVendors())
      .then(() => {
        toggleLoading();
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <VendorsTable
              vendors={vendors}
              fetchData={fetch}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  );
};

function mapStateToProps({ vendors, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    vendors: vendors.vendors
  };
}

export default connect(mapStateToProps)(Vendors);
