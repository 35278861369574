import { Card, CardBody, CardHeader } from 'reactstrap';
import FilteredTableV2 from '../../FilteredTableV2';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import moment from 'moment/moment';
import LoadingOverlay from '../../LoadingOverlay';

export const MyCases = ({cases, onReload, loading}) => {

  const columns = [
    {
      header: 'Id',
      accessorKey: 'caseId',
      cell: props => {
        return <span className="text-nowrap"><a href={props.row.original.crmUrl} target="_blank" className={'text-primary'}>{props.getValue()}</a></span>
      }
    },

    { header: 'Title',
      accessorKey: 'title',
      cell: (props) => {
        return <>
          <span data-tip={props.getValue()} className="text-nowrap text-truncate">
             {props.getValue().length > 35 ? `${props.getValue().substr(0, 35).trim()}...` : props.getValue()}
          </span>
          <ReactTooltip/>
        </>;
      } },
    { header: 'Status', accessorKey: 'status' },
    {
      header: 'Modified',
      accessorKey: 'modifiedOn',
      cell:  props => moment(props.getValue()).format('DD/MM/YY HH:mm')
    }
  ];

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between">
        <span>Active Cases</span>
        <span className="hoverPointer" onClick={() => onReload()}>
          <i className={'fa fa-refresh'}/>
        </span>
      </CardHeader>
      <CardBody>
        <LoadingOverlay loading={loading}>
          <FilteredTableV2
            hideFilter
            columns={columns}
            data={cases}
            defaultSorted={[{
              id: 'modifiedOn',
              desc: true
            }]}
            size="sm"
            loading={loading}
            minRows={5}
            noDataText={'You have no active cases'}
          />
        </LoadingOverlay>

      </CardBody>
    </Card>
  )
}
