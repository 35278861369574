import { apiRequest } from '../Api/ApiRequest';

export function api_getActiveCustomers(searchString) {
  return apiRequest({
    url: '/billing/customers?search=' + searchString,
    method: 'post',
    data: { search: searchString }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
export function api_getAccountBilling(accountNumber, billingDate) {
  return apiRequest({
    url: '/billing/' + accountNumber + '?billingDate=' + billingDate,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateAccountLastBilledDate(accountNumber, lastBilledDate) {
  return apiRequest({
    url: '/billing/' + accountNumber + '/lastBilledDate',
    method: 'put',
    data: { lastBilledDate: lastBilledDate }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getBillingRun(id) {
  return apiRequest({
    url: '/billing/billingRuns/' + id + '?include=failedJobs,jobs,ceasing,ceasePending,bills',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getBillingRuns(active = false) {
  return apiRequest({
    url: '/billing/billingRuns',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getBillingRunFailedJobs(runId) {
  return apiRequest({
    url: '/billing/billingRuns/' + runId + '/failed',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_startBillingRun(accountNumber) {
  return apiRequest({
    url: '/billing/billingRuns',
    method: 'post',
    data: { accountNumber: accountNumber }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getBill(runId, billId) {
  return apiRequest({
    url: '/billing/billingRuns/' + runId + '/bills/' + billId + '?include=billItems',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateBill(billId, data) {
  return apiRequest({
    url: '/billing/bills/' + billId,
    method: 'put',
    data: data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateBillingRun(id, data) {
  return apiRequest({
    url: '/billing/billingRuns/' + id + '?include=jobs',
    method: 'put',
    data: data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_rerunBill(billingRunId, billId) {
  return apiRequest({
    url: '/billing/billingRuns/' + billingRunId + '/bills/' + billId + '/rerun',
    method: 'post',
    data: { billId: billId }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_deleteBillingRun(billingRunId) {
  return apiRequest({
    url: '/billing/billingRuns/' + billingRunId,
    method: 'delete'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function getBillingRunReport(billingRunId, type) {
  return apiRequest({
    url: '/billing/billingRuns/' + billingRunId + '/reports/' + type,
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getVoiceProductArtifacts(productId, billRef, invoiceRef) {
  return apiRequest({
    url: '/billing/product/' + productId + '/artifacts?billRef=' + billRef + '&invoiceRef=' + invoiceRef,
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_retryFailedBillingJob(runId, jobId) {
  return apiRequest({
    url: '/billing/billingRuns/' + runId + '/jobs/retry',
    method: 'post',
    data: { jobId }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_retryAllFailedBillingJobs(runId) {
  return apiRequest({
    url: '/billing/billingRuns/' + runId + '/jobs/retry/all',
    method: 'post'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}


export function api_downloadInvoice(billId, format = 'pdf') {
  return apiRequest({
    url: '/billing/bills/' + billId + '/invoice?format=' + format,
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_exportBillItems(billItems, billRef) {
  return apiRequest({
    url: '/billing/export',
    method: 'post',
    responseType: 'blob',
    data: { billItems, billRef }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_triggerEmailRun(runId) {
  return apiRequest({
    url: '/billing/billingRuns/' + runId + '/emails',
    method: 'post'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_triggerBillEmailRun(runId, billId) {
  return apiRequest({
    url: '/billing/billingRuns/' + runId + '/bills/' + billId + '/emails',
    method: 'post'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getBillingQueue() {
  return apiRequest({
    url: '/billing/queue',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_generateBillingBoothSummary() {
  return apiRequest({
    url: '/billing/voiceBilling/billingBooth/summary',
    method: 'post'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getBillingBoothSummary() {
  return apiRequest({
    url: '/billing/voiceBilling/billingBooth/summary',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_downloadBillArtifacts(id, documentId) {

  return apiRequest({
    url: `/billing/bills/${ id }/artifacts/${ documentId}`,
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );

}

export function api_markInvoiceAsPaid(id) {
  return apiRequest({
    url: `/billing/bills/${id}/paid`,
    method: 'post'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}