const BillTemplateForm = (isNew) => {
  const form = {
    general: {
      name: {
        label: 'Name',
        type: 'text',
        mandatory: true
      },
      reference: {
        label: 'Bill Ref',
        type: 'text',
        mandatory: true
      },
      company: {
        label: 'Account',
        type: 'select-async',
        mandatory: true,
        width: 12,
        readOnly: (key, field, data) => data.id && data.company
      },
      default: {
        label: 'Default Bill',
        type: 'select-boolean'
      },
      poNumber: {
        label: 'PO Number',
        type: 'text'
      },
    },
    address: {
      addressCompany: { label: 'Company', type: 'text', mandatory: true},
      addressStreet1: { label: 'Address Line 1', type: 'text', mandatory: true},
      addressStreet2: { label: 'Address Line 2', type: 'text'},
      addressStreet3: { label: 'Address Line 3', type: 'text'},
      addressCity: { label: 'City', type: 'text', mandatory: true},
      addressCounty: { label: 'County', type: 'text'},
      addressCountry: { label: 'Country', type: 'select', mandatory: true},
      addressPostcode: { label: 'Postcode', type: 'text', mandatory: true},
    },
    contacts: {
      contact1Email: { label: 'Contact 1', type: 'text', width: 8},
      contact1PlainText: { label: 'Plain Text', type: 'select-boolean',width: 4},
      contact2Email: { label: 'Contact 2', type: 'text', width: 8},
      contact2PlainText: { label: 'Plain Text', type: 'select-boolean',width: 4},
      contact3Email: { label: 'Contact 3', type: 'text', width: 8},
      contact3PlainText: { label: 'Plain Text', type: 'select-boolean',width: 4},
      contact4Email: { label: 'Contact 4', type: 'text', width: 8},
      contact4PlainText: { label: 'Plain Text', type: 'select-boolean',width: 4},
      contact5Email: { label: 'Contact 5', type: 'text', width: 8},
      contact5PlainText: { label: 'Plain Text', type: 'select-boolean',width: 4},
    }
  }


  if(!isNew){
    form.general.company = {
      label: 'Account',
      type: 'button-link',
      width: 12,
      getLink: (data) => data.company ? `/accounts/${data.company.accountid}` : '#',
      isDisabled: (data) => !data.company,
      callBack: (key, field, data) => data[key] !== undefined && data[key] !== null ? data[key].name : '',
    }
  }
  return form
};

export default BillTemplateForm