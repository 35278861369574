import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import { addBillTemplate, getBillTemplates, replaceBillTemplate } from '../../../actions/billTemplates';
import LoadingOverlay from '../../../components/LoadingOverlay';
import BillTemplatesTable from '../../../components/Tables/BillTemplates';

const BillTemplates = ({
  billTemplates
}) => {
  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)
  const toggleLoading = () => setLoading(prevState => !prevState)

  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = () => {
    toggleLoading()
    dispatch(getBillTemplates()).then(() => toggleLoading())
  }
  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <BillTemplatesTable
          templates={billTemplates.list} fetchData={fetchData}
          onCreate={template => dispatch(addBillTemplate(template))}
          onUpdate={template => dispatch(replaceBillTemplate(template))}
        />
      </LoadingOverlay>
    </div>
  )
}

function mapStateToProps({ billTemplates }) {
  return {
    billTemplates
  };
}

export default connect(mapStateToProps)(BillTemplates);
