import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import formFieldGenerator from '../../../../../helpers/FormFieldGenerator';
import HeadlessModal from '../../../../../components/Modals/HeadlessModal';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import CrossConnectComponentForm from './form';
import isEmpty from 'lodash.isempty';
import { CrossConnectService } from '../../../../../utils/ServiceDB/CrossConnect';
import CrossConnectsTable from '../../../../../components/Tables/CrossConnects';
import { useDispatch } from 'react-redux'

const CrossConnect = ({
  data,
  onConnect,
  suppliers
}) => {
  const [crossConnects, setCrossConnects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleLoading = () => setLoading(prevState => !prevState)
  const toggleModal = () => setShowModal(prevState => !prevState)

  useEffect(() => {
    if(isEmpty(data)){
      toggleModal()
    }
  }, [data]);
  //const [data, setData] = useState({});

  const types = [
    { value: 'copper', label: 'Copper' },
    { value: 'smf', label: 'Singlemode Fibre' },
    { value: 'mmf', label: 'Multimode Fibre' }
  ]

  const fetchCrossConnects = () => {
    toggleLoading()
    CrossConnectService.getCrossConnectsAvailable().then((result) => {
      if(result.status === 200){
        setCrossConnects(result.data)
      }
      toggleLoading()
    })
  }

  return (
    <>
      <Row>
        {formFieldGenerator({
          data,
          fields: CrossConnectComponentForm(types, suppliers)
        })}
        <Col lg={12}>
          <Button
            color={'primary'}
            block
            outline
            onClick={toggleModal}
          >
            {data.name ? 'Replace' : 'Select'}
          </Button>
        </Col>
      </Row>
      <HeadlessModal
        open={showModal}
        toggle={toggleModal}
        size={'xlg'}
        onOpened={fetchCrossConnects}
      >
        <div className="animated fadeIn">
          <Card className={'mb-0'}>
            <CardBody>
              <LoadingOverlay loading={loading}>
                <CrossConnectsTable
                  noRowClick
                  connect={(circuit) => {
                    toggleModal()
                    onConnect(circuit)
                  }}
                  crossConnects={crossConnects}
                  suppliers={suppliers}
                  closeModal={toggleModal}
                  fetchData={fetchCrossConnects}
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </div>
      </HeadlessModal>
    </>
  );
};

export default CrossConnect;
