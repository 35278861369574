import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import form from './form';
import isEmpty from 'lodash.isempty';
import {
  addPortNote,
  createPort,
  deletePort,
  getPort, getPortAuditHistory, removePortNote,
  resetPortData,
  setPortField,
  updatePort, updatePortNote
} from '../../../actions/port';
import LoadingOverlay from '../../../components/LoadingOverlay';

import ApiErrorResolver from '../../../helpers/ApiErrorResolver';
import { diff } from 'deep-object-diff';
import resolveArgs from '../../../helpers/ArgumentResolver';
import { formValidator } from '../../../helpers/FormValidator';
import classnames from 'classnames';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import { canAccessServiceDb, isSdbManager } from '../../../utils/Auth/AuthService';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import GenericForm from '../../../components/GenericForm';
import { setConfirmDialog } from '../../../actions/dialogs';
import CollapsibleCard from '../../../components/CollapsibleCard';
import AuditHistory from '../../../components/AuditHistory';
import Notes from '../../../components/Notes';

const includes = ['deviceDeployment', 'nni', 'connection', 'notes']
const Port = ({
  dispatch,
  id,
  data,
  original,
  audits,
  onUpdated,
  onCreated,
  onDeleted,
  closeModal,
  deviceDeployment
}) => {

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const isNew = id === 'new' && !original.id

  const toggleLoading = () => setLoading(prevState => !prevState)
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);

  useEffect(() => {
    if(!isNew){
      fetchData()
    }else{
      dispatch(setPortField('deviceDeployment', deviceDeployment))
    }
    return () => dispatch(resetPortData())
  }, [])

  const options = {
    duplex: [
      { value: 'auto', label: 'Auto' },
      { value: 'full', label: 'Full' }
    ]
  };

  const fetchData = () => {
    toggleLoading()
    dispatch(getPort(original.id ?? id, includes)).then((result) => {
      toggleLoading()
    })
  }

  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        dispatch(createPort(resolveArgs(data), includes)).then(result => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }else if(result && onCreated){
            onCreated(result)
          }
          toggleLoading()
        })
      }else{
        const toSave = diff(original, data);
        toggleLoading()
        dispatch(updatePort(id, resolveArgs(toSave), includes)).then(result => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }else if(result && onUpdated){
            onUpdated(result)
          }
          toggleLoading()
        })
      }

    }
  }

  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getPortAuditHistory(original.id)).then(() => toggleAuditsLoading())
  }
  const validated = () => {
    let errorArr = formValidator(form, data);
    setErrors(errorArr);
    return isEmpty(errorArr);
  }
  const hasChanges = () => {
    const changes = diff(original, data);
    return !isEmpty(changes)
  }
  const canDelete = () => isEmpty(data.connection) && isEmpty(data.nni) && isSdbManager()
  const onClose = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else{
      closeModal()
    }
  }
  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to delete this Port!",
      proceed: () => {
        toggleLoading()
        dispatch(deletePort(id)).then(result => {
          toggleLoading()
          if(result && onDeleted){
            onDeleted(id)
          }
        })
      }
    }))
  }
  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className={classnames('bg-light', 'border-0', { 'mb-3': !closeModal }, {'mb-0' : closeModal})}>
          <CardBody>
            <Row className='mb-2'>
              <Col className="d-flex justify-content-between">
                <CardTitle>
                  {original.name ?? ''}
                </CardTitle>
                <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {!isNew && canDelete() && <ButtonIcon
                    disabled={!canDelete()}
                    icon={'fa fa-trash'}
                    tooltip={'Delete'}
                    onClick={onDeleting}
                  />}
                  <ButtonIcon disabled={!canAccessServiceDb() || loading || !hasChanges()}
                              icon={'fa fa-save'} tooltip={'Save'} onClick={save}/>
                  <ButtonIcon icon={'fa fa-refresh'} tooltip={'Reload'} onClick={fetchData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClose} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row className={'d-flex'}>
              <Col className={"d-flex col-12"}>
                <EntityMainFormCard grow>
                  <GenericForm
                    data={data}
                    form={form}
                    setField={(field, value) => dispatch(setPortField(field, value))}
                    options={options}
                  />
                </EntityMainFormCard>
              </Col>
            </Row>
            {!isNew &&
              <>
                <Row>
                  <Col>
                    <CollapsibleCard
                      open
                      title={'Notes'}
                    >
                      <Notes
                        withNew
                        notes={data.notes}
                        relatedTo={{
                          type: 'port',
                          data
                        }}
                        onCreated={note => dispatch(addPortNote(note))}
                        onUpdated={note => dispatch(updatePortNote(note))}
                        onDeleted={note => dispatch(removePortNote(note))}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Audit History'}
                      onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                    >
                      <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                    </CollapsibleCard>
                  </Col>
                </Row>
              </>
            }

          </CardBody>
        </Card>
      </LoadingOverlay>

    </div>
    /*<GenericCardForm
      title={data.name || 'New Port'}
      isNew={id === 'new' || (!id && !original.id)}
      data={data}
      original={original}
      extraFormData={{deviceDeploymentProvided: !isEmpty(deviceDeployment)}}
      form={form}
      requestStructure={requestStructure}
      options={options}
      aSyncOptions={aSyncOptions}
      setField={(field, value) => dispatch(setPortField(field, value))}
      onFetch={() => dispatch(getPort(id, ['deviceDeployment', 'nni', 'connection']))}
      onFetchAll={() => {
        const promises = [];
        if (deviceDeployment) {
          promises.push(dispatch(setPortField('deviceDeployment', deviceDeployment)));
        }
        return Promise.all(promises);
      }}
      onReset={() => dispatch(resetPortData())}
      onCreate={(toCreate) => dispatch(createPort(toCreate, ['deviceDeployment', 'nni', 'connection']))}
      onCreated={onCreated}
      onUpdate={(toUpdate) => dispatch(updatePort(id, toUpdate, ['deviceDeployment', 'nni', 'connection']))}
      onUpdated={onUpdated}
      onSubmitted={onSubmitted}
      onDelete={() => dispatch(deletePort(id))}
      onDeleted={() => onDeleted(id)}
    />*/
  );
};

function mapStateToProps({ port }) {
  return {
    data: port.data,
    original: port.original,
    audits: port.audits,
  };
}

export default connect(mapStateToProps)(Port);