import FilteredTableV2 from '../../FilteredTableV2';
import { CSVLink } from 'react-csv';
import moment from 'moment/moment';
import { ButtonIcon } from '../../ButtonIcon';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { SelectMod } from '../../Selects/SelectMod';
import sortBy from 'lodash.sortby'

const ProductTemplatesTable = ({
  templates,
  onRowClick,
  fetchData,
  sync
}) => {

  const [groupFilter, setGroupFilter] = useState({
    value: null,
    label: 'All Groups'
  });
  const getGroups = () => {
    let keep = {};
    return sortBy(templates.reduce((result, template) => {
      if (!keep[template.productGroup.id]) {
        keep[template.productGroup.id] = true;
        result.push(
          {
            value: template.productGroup.id,
            label: template.productGroup.name
          }
        );
      }
      return result;
    }, [{
      value: null,
      label: 'All Groups'
    }]), ['label']);
  }
  const onGroupFilterChange = (selected, tableProps) => {
    tableProps.column.setFilterValue(selected.value)
    setGroupFilter(selected)
  };
  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 400
    },
    {
      header: 'Product Code',
      accessorKey: 'productCode'
    },
    {
      header: (props) => {
        return <SelectMod
          options={getGroups()}
          isSearchable
          value={groupFilter}
          onChange={(selected) => onGroupFilterChange(selected, props)}
          size={'sm'}
        />
      },
      enableSorting: false,
      accessorKey: 'productGroup',
      filterFn: (row, columnId, value) => {
        return !value || row.original.productGroup.id === value
      },
      cell: props => props.getValue().id ?  props.getValue().name : props.getValue(),
      minSize: 200
    },
    {
      header: 'Pricing',
      accessorKey: 'pricingType.name'
    },
    {
      header: 'SLA',
      accessorKey: 'productSLA',
      cell: props => props.getValue()?.name ?? ''
    },
    {
      header: 'Billing Code - Install',
      accessorKey: 'affinityBillingCodeInstall'
    },
    {
      header: 'Billing Code - Rental',
      accessorKey: 'affinityBillingCodeRental'
    },
  ]

  const tableFilter = (row, columnId, value) => {
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.productGroup?.name)
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.productCode)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.pricingType?.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(String(row.original.productSLA?.name))
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(String(row.original.affinityBillingCodeInstall))
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(String(row.original.affinityBillingCodeInstall))
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }
  return (
    <>
      <FilteredTableV2
        minRows={20}
        withFilter={tableFilter}
        columns={columns}
        data={templates}
        showPagination
        defaultPageSize={50}
        noDataText={'No Templates'}
        onRowClick={onRowClick}
        extraButtons={[
          <Button size={'sm'} onClick={sync}>Sync with CRM</Button>,
          <div data-for={'export'} data-tip={'export'} >
            <CSVLink id={'export'}
                     data={[...templates]} filename={`product-templates-${moment().format('DD-MM-YYYY')}.csv`}
                     style={{ textDecoration: 'none' }}>

              <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
            </CSVLink>
          </div>,

          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
        ]}
        defaultSorted={[{id: 'name', desc: false}]}
      />
    </>
  )


}

export default ProductTemplatesTable