import { BILLING_RUN_SET_ACCOUNT, BILLING_RUN_SET_ACCOUNT_RUN_LOADING, BILLING_RUN_SET_ACCOUNT_SEARCH_OPTIONS, BILLING_RUN_SET_BATCH_RUN_LOADING } from '../actions/billing';

const initialState = {
  account: null,
  searchOptions: [],
  accountRunLoading: false,
  batchRunLoading: false
};

export default function billing(state = initialState, action) {
  switch (action.type) {
    case BILLING_RUN_SET_ACCOUNT_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: action.searchOptions
      };
    case BILLING_RUN_SET_ACCOUNT:
      return {
        ...state,
        account: action.account
      };
    case BILLING_RUN_SET_BATCH_RUN_LOADING:
      return {
        ...state,
        batchRunLoading: action.loading
      };
    case BILLING_RUN_SET_ACCOUNT_RUN_LOADING:
      return {
        ...state,
        accountRunLoading: action.loading
      };
    default:
      return state;
  }
}
