import { defaultErrorFeedback } from './feedback';
import { api_generateLeadTimesReport } from '../utils/ServiceDelivery/LeadTimesReport';

export const LEAD_TIMES_REPORT_SET_LINE_ITEMS = 'LEAD_TIMES_REPORT_SET_LINE_ITEMS';
export const LEAD_TIMES_REPORT_SET_YEAR = 'LEAD_TIMES_REPORT_SET_YEAR';
export const LEAD_TIMES_REPORT_SET_YEAR_OPTIONS = 'LEAD_TIMES_REPORT_SET_YEAR_OPTIONS';
export const LEAD_TIMES_REPORT_SET_QUARTER = 'LEAD_TIMES_REPORT_SET_QUARTER';

export function setLeadTimesReportLineItems(lineItems) {
  return {
    type: LEAD_TIMES_REPORT_SET_LINE_ITEMS,
    data: lineItems
  };
}

export function setLeadTimesReportYearOptions(options) {
  return {
    type: LEAD_TIMES_REPORT_SET_YEAR_OPTIONS,
    options
  };
}

export function setLeadTimesReportYear(year) {
  return {
    type: LEAD_TIMES_REPORT_SET_YEAR,
    year
  };
}

export function setLeadTimesReportQuarter(quarter) {
  return {
    type: LEAD_TIMES_REPORT_SET_QUARTER,
    quarter
  };
}

export function generateLeadTimesReport(year, quarter = null) {
  return (dispatch) => {
    return api_generateLeadTimesReport(year, quarter).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setLeadTimesReportLineItems(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return [];
      }
    );
  };
}
