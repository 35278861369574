import Loadable from 'react-loading-overlay'

const LoadingOverlay = ({
  loading,
  text,
  children
}) => {
  return (
    <Loadable
      animate={false}
      active={loading}
      spinner
      spinnerSize={'60px'}
      color={'rgb(62,81,91)'}
      background={'rgba(255,255,255, 0.8)'}
      text={text || ''}
      style={{minHeight: loading ? '100px' : '0'}}
      fadeSpeed={200}
    >
      {children}
    </Loadable>
  );
};

export default LoadingOverlay;
