

const CarrierCircuitComponentForm = (types,serviceTypes) =>  ({
  id: {
    label: 'ID',
    type: 'text',
    plaintext: true,
    width: 12,
  },
  name: {
    label: 'Name',
    type: 'text',
    plaintext: true,
    width: 12
  },
  type: {
    label: 'Type',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => {
      return data.id ? types.options.find(type => type.value === (data.type?.id ?? data.type))?.label : data.type
    },
    width: 6
  },
})

export default CarrierCircuitComponentForm