import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody
} from 'reactstrap';
import { connect } from 'react-redux';
import LoadingOverlay from '../../components/LoadingOverlay';
import ExtraButtons from '../../utils/Helpers/ExtraButtons';
import React, { useEffect, useState } from 'react';
import { isMarketing, isSalesManager } from '../../utils/Auth/AuthService';
import { getLeads, getUserLeads } from '../../actions/leads';
import { SelectMod } from '../../components/Selects/SelectMod';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import FilteredTableV2 from '../../components/FilteredTableV2';
import { ButtonIcon } from '../../components/ButtonIcon';
import isEmpty from 'lodash.isempty';
import { getCampaigns } from '../../actions/campaigns';

const Leads = ({
  dispatch,
  leads,
  user,
  optionSets,
  campaigns
}) => {
  const [loading, setLoading] = useState(false);
  const [userFilter, setUserFilter] = useState({
    value: null,
    label: 'All Owners'
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!leads.list.length) {
      getData();
    }
  }, []);
  const getData = () => {
    setLoading(true);
    const promises = [];
    if (isSalesManager(user.permissions) || isMarketing(user.permissions)) {
      promises.push(dispatch(getLeads('status:0')));
    } else {
      promises.push( dispatch(getUserLeads(user.cui, 'status:0')));
    }
    if(isEmpty(campaigns.list)){
      promises.push(dispatch(getCampaigns()));
    }
    Promise.all(promises).then(() =>  setLoading(false))
  };

  const getOwnersOptions = () => {
    let keep = {};
    return leads.list.reduce((result, lead) => {
      if (!keep[lead.owner.id]) {
        keep[lead.owner.id] = true;
        result.push(
          {
            value: lead.owner.id,
            label: lead.owner.name
          }
        );
      }
      return result;
    }, [{
      value: null,
      label: 'All Owners'
    }]);
  };

  const onUserFilterChange = (selected, tableProps) => {
    tableProps.column.setFilterValue(selected.value)
    setUserFilter(selected)
  };

  const tableFilter = (row, columnId, value) => {
    const fullName = `${row.original.firstName || ''} ${row.original.lastName || ''}`;

    return (String(row.original.topic)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.company)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.firstName)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.lastName)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.phone)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(fullName)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }


  const getColumns = () => {
    let columns = [
      {
        header: 'Topic',
        accessorKey: 'topic',
        cell: props => {
          return (<Link target="_blank" to={props.row.original.url}>{props.getValue()}</Link>);
        }
      },
      {
        filterable: false,
        header: 'Company',
        accessorKey: 'company'
      },
      {
        filterable: false,
        header: 'Name',
        accessorKey: 'id',
        cell: props => `${props.row.original.firstName || ''} ${props.row.original.lastName || ''}`.trim()
      },
      {
        filterable: false,
        header: 'Business Phone',
        accessorKey: 'phone'
      },
      {
        filterable: false,
        header: 'Status',
        accessorKey: 'statusReason',
        cell: props => (
          <div>{optionSets.statusReason.options.find(x => x.value === props.getValue())?.label || ''}</div>
        )
      },
      {
        filterable: false,
        header: 'Created On',
        accessorKey: 'createdAt',
        cell: props => moment(props.getValue())
          .format('DD/MM/YYYY HH:mm')
      },
    ];
    if(isSalesManager(user.permissions) || isMarketing(user.permissions) ){
      columns.push({
        header: (props) => {
          return <SelectMod
            options={getOwnersOptions()}
            isSearchable
            value={userFilter}
            onChange={(selected) => onUserFilterChange(selected, props)}
            size={'sm'}
          />
        },
        enableSorting: false,
        accessorKey: 'owner',
        filterFn: (row, columnId, value) => {
          return !value || row.original.owner.id === value
        },
        cell: props => props.getValue().name,
        minSize: 200
      })
    }
    return columns
  }
  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <FilteredTableV2
              minRows={10}
              extraButtons={ExtraButtons({
                others: [
                  <Button
                    onClick={lead => navigate(`/sales/leads/new`)}
                    colour={'secondary'}
                    size='sm'
                    color='secondary'>
                    New Lead
                  </Button>,
                  <ButtonIcon icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={getData}/>
                ]
              })}
              columns={getColumns()}
              data={leads.list}
              onRowClick={lead => navigate(`/sales/leads/${lead.id}`)}
              showPagination={leads.list.length > 20}
              defaultSorted={[{
                id: 'createdAt',
                desc: true
              }]}
              withFilter={tableFilter}
              noDataText={'No Results'}
              loading={loading}
              defaultPageSize={50}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = ({
  leads,
  lead,
  helpers,
  authenticationState,
  campaigns
}) => ({
  leads,
  lead,
  optionSets: helpers.optionSets.lead,
  user: authenticationState.account,
  campaigns
});

export default connect(mapStateToProps)(Leads);
