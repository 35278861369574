import {
  DEVICE_MODE_SET_AUDIT_HISTORY, DEVICE_MODEL_ADD_NOTE, DEVICE_MODEL_REMOVE_NOTE,
  DEVICE_MODEL_RESET_DATA, DEVICE_MODEL_SET_AUDIT_HISTORY,
  DEVICE_MODEL_SET_DATA,
  DEVICE_MODEL_SET_FIELD, DEVICE_MODEL_UPDATE_NOTE
} from '../actions/deviceModel';
import { VENDOR_ADD_NOTE, VENDOR_REMOVE_NOTE, VENDOR_SET_AUDIT_HISTORY, VENDOR_UPDATE_NOTE } from '../actions/vendor';

const initialState = {
  data: {
    notes: [],
    stock: []
  },
  original: {
    notes: [],
    stock: []
  },
  audits: []
};
const deviceModel = (state = initialState, action) => {
  switch (action.type) {
    case DEVICE_MODEL_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case DEVICE_MODEL_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case DEVICE_MODEL_RESET_DATA:
      return {
        ...initialState
      };
    case DEVICE_MODEL_ADD_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: [...state.data.notes, action.note]
        },
        original: {
          ...state.original,
          notes: [...state.original.notes, action.note]
        },

      }
    case DEVICE_MODEL_UPDATE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
        original: {
          ...state.original,
          notes: state.original.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
      }
    case DEVICE_MODEL_REMOVE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.filter(note => note.id !== action.note.id)
        },
        original: {
          ...state.original,
          notes: state.original.notes.filter(note => note.id !== action.note.id)
        }
      }
    case DEVICE_MODEL_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;
  }
}

export default deviceModel;