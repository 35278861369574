import {
  RADIUS_UPDATE_USERS, RADIUS_FETCHING_USERS, RADIUS_RETURN_NONE,
  RADIUS_UPDATE_USER_ATTRIBS, RADIUS_SET_ACTIVE_USER, RADIUS_FETCHING_ATTRIBUTES,
  RADIUS_NEW_ATTRIB_BOX, RADIUS_SET_ACTIVE_BOX, RADIUS_SETTING_ATTRIBUTES,
  RADIUS_DELETING_ATTRIBUTES, RADIUS_DELETE_ATTRIBUTE, RADIUS_SET_ACTIVE_ATTRIB,
  RADIUS_SET_ATTRIB, RADIUS_DELETING_USER, RADIUS_DELETE_USER, RADIUS_ADDING_USER,
  RADIUS_ADD_USER, RADIUS_SET_ADD_USER_ERROR, RADIUS_SET_GROUPS
} from '../actions/radius';
import update from 'immutability-helper';

const initialState = {
  fetchingUsers: true,
  fetchingAttribs: false,
  radiusUsers: [],
  currentUsers: [],
  activeUser: null,
  activeUserAttribs: {},
  activeAttrib: '',
  settingNewAttrib: false,
  newAttribBox: false,
  deletingAttrib: false,
  activeBox: '',
  deletingUser: false,
  addingUser: false,
  addUserErrors: '',
  table: {},
  groups: []
};

export default function radius(state = initialState, action) {
  switch (action.type) {
    case RADIUS_FETCHING_USERS:
      return update(state, {
        fetchingUsers: { $set: action.fetching }
      });
    case RADIUS_UPDATE_USERS:
      return update(state, {
        radiusUsers: { $set: [...action.data.items] },
        table: {
          $set: {
            currentPage: action.data.currentPage,
            lastPage: action.data.lastPage,
            total: action.data.total,
            perPage: action.data.perPage
          }
        }
      });
    case RADIUS_SET_GROUPS:
      return {
        ...state,
        groups: action.data.map(item => item.groupname)
          .filter((value, index, self) => self.indexOf(value) === index)
      };
    case RADIUS_FETCHING_ATTRIBUTES:
      return {
        ...state,
        fetchingAttribs: action.fetching
      };
    case RADIUS_RETURN_NONE:
      return {
        ...state,
        currentUsers: []
      };
    case RADIUS_SET_ACTIVE_USER:
      return {
        ...state,
        activeUser: action.username
      };
    case RADIUS_UPDATE_USER_ATTRIBS:
      return {
        ...state,
        activeUserAttribs: action.data
      };
    case RADIUS_NEW_ATTRIB_BOX:
      return {
        ...state,
        newAttribBox: action.show
      };
    case RADIUS_SETTING_ATTRIBUTES:
      return {
        ...state,
        settingNewAttrib: action.setting
      };
    case RADIUS_SET_ATTRIB:
      return update(state,
        {
          activeUserAttribs: { [action.key]: { $push: [action.data] } }
        });
    case RADIUS_DELETING_ATTRIBUTES:
      return {
        ...state,
        deletingAttrib: action.deleting
      };
    case RADIUS_DELETE_ATTRIBUTE:
      return update(state,
        {
          activeUserAttribs: { [action.radType]: { $unset: [action.index] } }
        });
    case RADIUS_SET_ACTIVE_ATTRIB:
      return {
        ...state,
        activeAttrib: action.newAttrib
      };
    case RADIUS_SET_ACTIVE_BOX:
      return {
        ...state,
        activeBox: action.activeBox
      };
    case RADIUS_DELETING_USER:
      return {
        ...state,
        deletingUser: action.deleting
      };
    case RADIUS_DELETE_USER:
      return update(state,
        {
          radiusUsers: {
            $apply: () => state.radiusUsers.filter(
              e => e.username !== action.username
            )
          }
        });
    case RADIUS_ADDING_USER:
      return {
        ...state,
        addingUser: action.adding
      };
    case RADIUS_ADD_USER:
      return update(state,
        {
          radiusUsers: { $push: [{ username: action.username }] }
        });
    case RADIUS_SET_ADD_USER_ERROR:
      return {
        ...state,
        addUserErrors: action.error
      };
    default:
      return state;
  }
}
