import isEmpty from 'lodash.isempty';
import { Link } from 'react-router-dom';
import React from 'react';
import { Button } from 'reactstrap';
import { isSdbManager } from '../../../utils/Auth/AuthService';

const form = {
  assetTag: {
    label: 'Asset Tag',
    type: 'number',
    min: 1,
    max: 4294967295
  },
  serialNumber: {
    label: 'Serial Number',
    type: 'text',
    mandatory: true,
    plaintext: (key, field, data) => Boolean(data.id) && !isSdbManager()
  },
  deviceModel: {
    label: 'Device Model',
    type: 'select',
    mandatory: true,
  },
  owner: {
    label: 'Owner',
    type: 'select',
    mandatory: true
  },
  reservedFor: {
    label: 'Reserved For',
    type: 'select-async',
    entity: 'reservedFor',
    optionLabelCallback: (opt) => opt.orderNumber || opt.name,
    mandatory: false,
    show: (field, data, permissions) => isEmpty(data.deviceDeployment) && !data.disposalStatus
  },
  disposalStatus: {
    label: 'Disposal Status',
    type: 'select',
    mandatory: false,
    show: (field, data, permissions) => isEmpty(data.deviceDeployment) && isEmpty(data.reservedFor)
  },
  toBeRecoveredFrom: {
    label: 'To Be Recovered From',
    type: 'text',
    readOnly: true,
    callBack: (key, field, data) => data[key] !== undefined && data[key] !== null ? data[key].name : '',
    show: (field, data, permissions) => !isEmpty(data.toBeRecoveredFrom)
  },
  deviceDeployment: {
    label: 'Deployed as:',
    type: 'custom',
    callBack: (key, field, data) => <Link to={`/sdb/device-deployments/${data.deviceDeployment.id}`} style={{ textDecoration: 'none' }}>
      <Button className={'faded-info'} block>{data.deviceDeployment.hostname}</Button>
    </Link>,
    show: (field, data) => !isEmpty(data.deviceDeployment)
  },
}

export default form;
