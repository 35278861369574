import isEmpty from 'lodash.isempty';
import { Link, useNavigate } from 'react-router-dom'
import React from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';

const AccountsTable = ({
  withFilter,
  accounts,
  users,
  optionSets,
  hideNoData,
  noDataText
}) => {

  const navigate = useNavigate();

  const getCustomerType = (value) =>{
    return optionSets.customerType.options.find(option => option.value === value)?.label || '';
  }

  const getAccountManager = (value) =>{
    return users.find(option => option.id === value)?.name || '';
  }

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 200
    },
    {
      header: 'Account Number',
      accessorKey: 'accountnumber'
    },
    {
      header: 'Type',
      accessorKey: 'customerType',
      cell: props => getCustomerType(props.getValue())
    },
    {
      header: 'Account Manager',
      accessorKey: 'accountManager',
      cell: props => getAccountManager(props.getValue())
    },
    {
      header: 'Primary Contact',
      accessorKey: 'primaryContact',
      cell: props => props.getValue()?.name || ''
    },
    {
      header: 'Phone',
      accessorKey: 'telephone'
    },
    {
      header: 'Status',
      accessorKey: 'isActive',
      cell: props =>
        <Badge style={{minWidth: '86px'}} className={`p-2 faded-${props.getValue() ? 'success' : 'warning'}`}>{props.getValue() ? 'Active' : 'Deactivated'}</Badge>

    },
  ];

  return (
    <>
      <FilteredTableV2
        hideNoData={hideNoData}
        withDeactivatedRow
        withFilter={withFilter ? 'includeString' : undefined}
        minRows={10}
        columns={columns}
        data={accounts}
        showPagination
        defaultPageSize={20}
        pageSizeOptions={[20,50,100,200]}
        noDataText={noDataText || 'No Accounts'}
        onRowClick={(account) => {
          navigate(`/accounts/${account.accountid}`);
        }}
        defaultSorted={[{ id: 'isActive', desc: true }]}
      />
    </>

  )
}

function mapStateToProps({ helpers }) {
  return {
    users: helpers.systemUsers,
    optionSets: helpers.optionSets.account
  };
}
export default connect(mapStateToProps)(AccountsTable);
