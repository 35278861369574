import { defaultErrorFeedback } from '../feedback';
import { LicenseService } from '../../utils/ServiceDB/License';
import AuditService from '../../utils/Audit';

export const LICENSE_SET_DATA = 'LICENSE_SET_DATA';
export const LICENSE_SET_FIELD = 'LICENSE_SET_FIELD';
export const LICENSE_RESET_DATA = 'LICENSE_RESET_DATA';
export const LICENSE_ADD_STOCK_ITEM = 'LICENSE_ADD_STOCK_ITEM';
export const LICENSE_UPDATE_STOCK_ITEM = 'LICENSE_UPDATE_STOCK_ITEM';
export const LICENSE_REMOVE_STOCK_ITEM = 'LICENSE_REMOVE_STOCK_ITEM';

export const LICENSE_ADD_NOTE = 'LICENSE_ADD_NOTE';
export const LICENSE_UPDATE_NOTE = 'LICENSE_UPDATE_NOTE';
export const LICENSE_REMOVE_NOTE = 'LICENSE_REMOVE_NOTE';
export const LICENSE_SET_AUDIT_HISTORY = 'LICENSE_SET_AUDIT_HISTORY';


export function addLicenseNote(note) {
  return {
    type: LICENSE_ADD_NOTE,
    note
  };
}

export function updateLicenseNote(note) {
  return {
    type: LICENSE_UPDATE_NOTE,
    note
  };
}

export function removeLicenseNote(note) {
  return {
    type: LICENSE_REMOVE_NOTE,
    note
  };
}

export const setLicenseData = (data) => ({
  type: LICENSE_SET_DATA,
  data
});

export const setLicenseField = (field, value) => ({
  type: LICENSE_SET_FIELD,
  field,
  value
});

export const resetLicenseData = () => ({
  type: LICENSE_RESET_DATA
});

export function addNewLicenseStockItem(stockItemData) {
  return {
    type: LICENSE_ADD_STOCK_ITEM,
    stockItemData
  };
}

export function updateLicenseStockItem(stockItemData) {
  return {
    type: LICENSE_UPDATE_STOCK_ITEM,
    stockItemData
  };
}

export function removeLicenseStockItem(stockItemId) {
  return {
    type: LICENSE_REMOVE_STOCK_ITEM,
    stockItemId
  };
}

export const getLicense = (id, include = []) => (dispatch) => {
  return LicenseService.get(id, include).then((result) => {
    if (result.status === 200) {
      dispatch(setLicenseData(result.data));
      return result.data;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });
};

export const updateLicense = (id, data, include = []) => (dispatch) => {
  return LicenseService.update(id, data, include).then((result) => {
    if (result.status === 200) {
      dispatch(setLicenseData(result.data));
    } else {
      dispatch(defaultErrorFeedback());
    }
    return result;
  });
};

export const createLicense = (data, include = []) => (dispatch) => {
  return LicenseService.create(data, include).then((result) => {
    if (result.status === 200) {
      dispatch(setLicenseData(result.data));
      return result.data
    }  else if(result.status === 422){
      return {errors: result.data}
    }else {
      dispatch(defaultErrorFeedback());
      return null
    }
  });
};

export const deleteLicense = (id) => (dispatch) => {
  return LicenseService.delete(id).then((result) => {
    if (result.status === 204) {
      return true;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });
};

export function attachLicenseStockItem(id, stockItemId) {
  return (dispatch) => {
    return LicenseService.attachStockItem(id, stockItemId).then((result) => {
      if (result.status === 200) {
        dispatch(addNewLicenseStockItem(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function detachLicenseStockItem(id, stockItemId) {
  return (dispatch) => {
    return LicenseService.detachStockItem(id, stockItemId).then((result) => {
      if (result.status === 204) {
        dispatch(removeLicenseStockItem(stockItemId));
        return true;
      } else {
        dispatch(defaultErrorFeedback());
        return false;
      }
    });
  };
}

export function getLicenseAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('sdb/licenses', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: LICENSE_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}