import { apiRequest } from '../Api/ApiRequest';

const BillTemplateGroupService = {
  get: (templateId, id) => {
    return apiRequest({
      url: `/billing/templates/${templateId}/groups/${id}?include=orderProducts&with=orderProducts`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  create: (templateId, data) => {
    return apiRequest({
      url: `/billing/templates/${templateId}/groups?include=orderProducts&with=orderProducts`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  update: (templateId, id, data) => {
    return apiRequest({
      url: `/billing/templates/${templateId}/groups/${id}?include=orderProducts&with=orderProducts`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  deactivate: (templateId, id) => {
    return apiRequest({
      url: `/billing/templates/${templateId}/groups/${id}?include=orderProducts&with=orderProducts`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  restore: (templateId, id) => {
    return apiRequest({
      url: `/billing/templates/${templateId}/groups/${id}/restore?include=orderProducts&with=orderProducts`,
      method: 'put'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}

export default BillTemplateGroupService