import { apiRequest } from '../Api/ApiRequest';

export function api_setPrivacy(mode) {
  return apiRequest({
    url: '/wallboard/privacy/'+mode,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_reloadScreens() {
  return apiRequest({
    url: '/wallboard/reload',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_reloadScreensQueue(id) {
  return apiRequest({
    url: '/wallboard/reloadqueue/'+id,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}


export function api_toast(message, type) {
  return apiRequest({
    url: '/wallboard/toast',
    method: 'post',
    data: {
      message,
      type
    }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
