import {
  BILL_TEMPLATES_ADD_TEMPLATE,
  BILL_TEMPLATES_REPLACE_TEMPLATE,
  BILL_TEMPLATES_SET_TEMPLATES
} from '../actions/billTemplates';

const initialState = {
  list: []
};
export function billTemplates(state = initialState, action) {
  switch (action.type) {
    case BILL_TEMPLATES_SET_TEMPLATES:
      return {
        ...state,
        list: action.templates
      }
    case BILL_TEMPLATES_REPLACE_TEMPLATE:
      return {
        ...state,
        list: state.list.map(template => {
          if(template.id === action.template.id){
            return action.template
          }
          return template
        })
      }
    case BILL_TEMPLATES_ADD_TEMPLATE:
      return {
        ...state,
        list: [...state.list, action.template]
      }
    default:
      return state;
  }
}