import { defaultErrorFeedback } from './feedback'
import { api_getOpportunities, api_getUserOpportunities } from '../utils/Opportunities'

export const OPPORTUNITIES_SET_LIST = 'OPPORTUNITIES_SET_LIST';
export const OPPORTUNITIES_TOGGLE_MODAL = 'OPPORTUNITIES_TOGGLE_MODAL';
export const OPPORTUNITIES_REMOVE_OPPORTUNITY = 'OPPORTUNITIES_REMOVE_OPPORTUNITY';
export const OPPORTUNITIES_ADD_OPPORTUNITY = 'OPPORTUNITIES_ADD_OPPORTUNITY';
export const OPPORTUNITIES_REPLACE_OPPORTUNITY = 'OPPORTUNITIES_REPLACE_OPPORTUNITY';

export function setOpportunitiesData(data) {
  return {
    type: OPPORTUNITIES_SET_LIST,
    data
  }
}

export function addNewOpportunityToList(opportunity) {
  return {
    type: OPPORTUNITIES_ADD_OPPORTUNITY,
    opportunity
  };
}

export function replaceOpportunityInList(opportunity) {
  return {
    type: OPPORTUNITIES_REPLACE_OPPORTUNITY,
    opportunity
  };
}

export function removeOpportunityFromList(id) {
  return {
    type: OPPORTUNITIES_REMOVE_OPPORTUNITY,
    id
  };
}

export function getUserOpportunities(userId){
  return (dispatch) => {
    return api_getUserOpportunities(userId).then((result) => {
      if (result.status === 200) {
        dispatch(setOpportunitiesData(result.data));
      }
      else {
        dispatch(defaultErrorFeedback());
      }
    });
  }
}

export function getOpportunities(){
  return (dispatch) => {
    return api_getOpportunities().then((result) => {
      if (result.status === 200) {
        dispatch(setOpportunitiesData(result.data));
      }
      else {
        dispatch(defaultErrorFeedback());
      }
    });
  }
}



