import React, {Component} from 'react';
import {Badge, Tooltip} from 'reactstrap';
import nextId from "react-id-generator";

class ZabbixStatusIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toolTipOpen: false
        }
        this.htmlId = nextId('zabbix-icon-');
    }

    toggle = () => {
        this.setState({toolTipOpen: !this.state.toolTipOpen});
    };

    render() {
        let color = "secondary";
        let text = "Host not found in Zabbix";
        if(this.props.id !== undefined) {
            text = "Host found in zabbix and "
            if (this.props.agent === 1) {
                color = "success";
            } else {
                color = "danger";
                text += " not ";
            }
            text += "available";
        }

        return(
            <div style={{display: 'inline'}}>
                <Badge color={color} id={this.htmlId}>Z</Badge>
                <Tooltip placement="right" isOpen={this.state.toolTipOpen} target={this.htmlId} toggle={this.toggle}>
                    {text}
                </Tooltip>
            </div>
        )
    }
}

export default ZabbixStatusIcon;
