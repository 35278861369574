import React from 'react';

const form = {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true,
  },
  rackUnits: {
    label: 'Rack Units',
    type: 'number',
    min: 1,
    mandatory: true
  },
  rackWidth: {
    label: 'Rack Width',
    type: 'select',
    mandatory: true
  },
  vendor: {
    label: 'Vendor',
    type: 'select',
    mandatory: true
  }
};

export default form;