import { apiRequest } from '../Api/ApiRequest';

export function api_getCRMSubjects() {
  return apiRequest({
    url: '/subjects',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
