import { VendorService } from '../utils/ServiceDB/Vendor';
import { defaultErrorFeedback } from './feedback';
import AuditService from '../utils/Audit';

export const VENDOR_SET_DATA = 'VENDOR_SET_DATA';
export const VENDOR_SET_FIELD = 'VENDOR_SET_FIELD';
export const VENDOR_RESET_DATA = 'VENDOR_RESET_DATA';
export const VENDOR_ADD_NOTE = 'VENDOR_ADD_NOTE';
export const VENDOR_UPDATE_NOTE = 'VENDOR_UPDATE_NOTE';
export const VENDOR_REMOVE_NOTE = 'VENDOR_REMOVE_NOTE';
export const VENDOR_SET_AUDIT_HISTORY = 'VENDOR_SET_AUDIT_HISTORY';


export function addVendorNote(note) {
  return {
    type: VENDOR_ADD_NOTE,
    note
  };
}

export function updateVendorNote(note) {
  return {
    type: VENDOR_UPDATE_NOTE,
    note
  };
}

export function removeVendorNote(note) {
  return {
    type: VENDOR_REMOVE_NOTE,
    note
  };
}


function setVendorData(data) {
  return {
    type: VENDOR_SET_DATA,
    data
  };
}

export function setVendorField(field, value) {
  return {
    type: VENDOR_SET_FIELD,
    field,
    value
  };
}

export function resetVendor() {
  return {
    type: VENDOR_RESET_DATA
  };
}

export function getVendor(id, include, withs) {
  return (dispatch) => {
    return VendorService.getVendor(id, include, withs).then((result) => {
      if (result.status === 200) {
        dispatch(setVendorData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createVendor(args, include = []) {
  return (dispatch) => {
    return VendorService.createVendor(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setVendorData(result.data));
        return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      } else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

export function updateVendor(id, args, include = []) {
  return (dispatch) => {
    return VendorService.updateVendor(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setVendorData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function deleteVendor(id) {
  return (dispatch) => {
    return VendorService.deleteVendor(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function getVendorAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('sdb/vendors', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: VENDOR_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}