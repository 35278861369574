import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader, InputGroup, Form, Row, Col, Button} from "reactstrap";
import {getClosestHubs} from "../../../actions/discovery";

class Pops extends Component {

  constructor(props) {
    super(props);
    this.pcRef = React.createRef();
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(e) {
    e.preventDefault();
    const postcode = this.pcRef.current.value;
    this.props.dispatch(getClosestHubs(postcode));
  }

  render() {
    return(<div>
      <Card className={"card-accent-primary"}>
        <CardHeader>
          Closest hSo PoP Search
        </CardHeader>
        <CardBody>
          <Form onSubmit={this.handleSearch}>
            <InputGroup>
              <input type="text" className="form-control" placeholder="Postcode" disabled={this.props.pops.status > 0} ref={this.pcRef}/>
            </InputGroup>
            <div className="clearfix" style={{ marginTop: 10 + 'px', marginBottom: 20 + 'px' }}>
              <Button type="submit" color="primary" className="float-right" style={{ minWidth: 113 + 'px', minHeight: 35 + 'px' }} disabled={this.props.pops.status > 0}>{this.props.pops.status > 0 ? <div style={{ marginTop: 2 + 'px' }}></div> : 'Check'}</Button>
            </div>
          </Form>
        {this.props.pops.data.length > 0 &&
          <Row><Col>
        <Card>
          <CardHeader className={"card-accent-info"}>
            <Row><Col md={5}>Name</Col><Col md={5}>Address</Col><Col md={1}>Postcode</Col><Col md={1}>Distance (km)</Col></Row>
          </CardHeader>
          <CardBody>
          {this.props.pops.data.map((pop) => {
            return <Row><Col md={5}>{pop.name}</Col><Col md={5}>{pop.address}</Col><Col md={1}>{pop.postcode}</Col><Col md={1}>{(pop.distance/1000).toFixed(3)}</Col></Row>
          }) }
          </CardBody>
        </Card>
          </Col></Row>
            }
        </CardBody>
      </Card>
        </div>)
  }
}

function mapStateToProps({discovery}) {
  return {
    pops: discovery.pops
  };
}

export default connect(mapStateToProps)(Pops);
