import moment from 'moment/moment';
import { Badge, Button } from 'reactstrap';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import FilteredTableV2 from '../../FilteredTableV2';
import { CSVLink } from 'react-csv';
import { ButtonIcon } from '../../ButtonIcon';
import omit from 'lodash.omit';
import HeadlessModal from '../../Modals/HeadlessModal';
import Document from '../../../views/Document';
import { addAccountDocument, updateAccountDocument } from '../../../actions/account';
import { GenericModal } from '../../Modals/GenericModal';
import { removeAccountDocument } from '../../../actions/accounts';
import { DocumentEnums } from '../../../utils/Constants/Document';

const AccountDocumentsTable = ({
  documents,
  optionSets,
  fetchData,
  remove,
  dispatch
}) => {

  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const toggleDocumentModal = () => setShowDocumentModal(prevState => !prevState);
  const showDocument = (document) => {
    setSelectedDocument(document)
    toggleDocumentModal()
  }

  const getStatusColour = (status) => {
    if(status === DocumentEnums.status.APPROVED){
      return 'success'
    }
    return status === DocumentEnums.status.REJECTED ? 'danger' : 'warning'
  }
  const columns = [
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Doc Type',
      accessorKey: 'type'
    },
    {
      header: 'Approval Status',
      accessorKey: 'status',
      cell: props => <Badge style={{minWidth: '86px'}} className={`faded-${getStatusColour(props.getValue())} p-2`}>{optionSets?.status.find(({ value }) => value === props.getValue())?.label ?? ''}</Badge>
    },
    {
      header: 'Last updated',
      accessorKey: 'updatedAt',
      cell: (props) => moment(props.getValue())
        .format('DD/MM/YYYY HH:mm')
    },
    {
      header: '',
      accessorKey: 'id',
      cell: props => <div className={"d-flex justify-content-center"}>
        <Button size="sm" color="danger" outline onClick={(e) => {
          e.stopPropagation()
          remove(props.getValue());
        }}><i className="fa fa-trash"/></Button>
      </div>,
      width: 40
    }
  ];

  const getExportData = () => {
    return documents.map(document => {
      return {
        ...omit(document, ['id'])
      }
    })
  }

  return (
    <>
      <FilteredTableV2
        withFilter={'includesString'}
        minRows={10}
        showPagination={documents.length > 10}
        noDataText="No Documents"
        data={documents}
        columns={columns}
        onRowClick={(document) => showDocument(document)}
        extraButtons={[
          <Button
            onClick={() => showDocument({})}
            colour={'secondary'}
            size='sm'
            color='secondary'>
            New Document
          </Button>,
          <div data-for={'export'} data-tip={'export'} >
            <CSVLink id={'export'}
                     data={getExportData()} filename={`documents-${moment().format('DD-MM-YYYY')}.csv`}
                     style={{ textDecoration: 'none' }}>

              <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
            </CSVLink>
          </div>,

          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
        ]}
      />
      <GenericModal
        show={showDocumentModal}
        toggle={toggleDocumentModal}
        className={'modal-lg'}
      >
        <Document
          id={selectedDocument?.id || null}
          onCreated={(savedData) => {
            dispatch(addAccountDocument(savedData));
            toggleDocumentModal()
          }}
          onUpdated={(savedData) => dispatch(updateAccountDocument(savedData))}
        />

      </GenericModal>
    </>
  )
}


const mapStateToProps = ({
  helpers
}) => ({
  optionSets: helpers.optionSets.document,
})

export default connect(mapStateToProps)(AccountDocumentsTable)