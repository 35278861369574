import { apiRequest } from '../Api/ApiRequest';
import { msalInstance } from '../../index';
import isEmpty from 'lodash.isempty';
import {store} from '../../index';
import { USER_LOGOUT } from '../../actions/user';
import { setAuthInteractionRequired } from '../../actions/authentication';

export function isLoggedIn() {
  return !isEmpty(msalInstance.getAllAccounts()) && !store.getState().authenticationState?.interactionRequired;
}


export const logoutAndRedirect = () => {
  const request = {
    account: msalInstance.getAllAccounts()[0]
  };
  msalInstance.logoutRedirect(request)
}
export const getAPIToken = (scope = "api://adminapi/Access") => {
  store.dispatch(setAuthInteractionRequired(false))
  const request = {
    scopes: ["openid", "email", "profile", scope],
    account: msalInstance.getAllAccounts()[0]
  };
  return msalInstance.acquireTokenSilent(request).then((response) => {
    return response.accessToken
  }).catch(err => {
    if (err.name === "InteractionRequiredAuthError") {
      store.dispatch(setAuthInteractionRequired(true))
      return msalInstance.acquireTokenPopup(request).then(response => {
          return response.accessToken
        })
    }
  });
}

export const getCustomerAPIToken = () => {
  store.dispatch(setAuthInteractionRequired(false))
  const request = {
    scopes: ["openid", "api://9a8958ae-4a9e-4a21-8fc7-f7c952652279/Emulate.Emulate"],
    account: msalInstance.getAllAccounts()[0]
  };
  return msalInstance.acquireTokenSilent(request).then((response) => {
    return response.accessToken
  }).catch(err => {
    if (err.name === "InteractionRequiredAuthError") {
      store.dispatch(setAuthInteractionRequired(true))
      return msalInstance.acquireTokenPopup(request).then(response => {
        return response.accessToken
      })
    }
  });
}

export function getAuthStatus() {
  return apiRequest({
    url: '/auth',
    method: 'get'
  }).then(
    resp => resp.data
  ).catch(
    error => error
  );
}

export function checkPermission(needle, haystack) {
  if(Array.isArray(needle)){
    return needle.filter((perm)=> haystack.includes(perm)).length > 0;
  }else{
    return haystack.includes(needle);
  }
}
export const isSalesManager = (permissions) => {
  return checkPermission(['PreSales', 'SalesManager'], permissions);
};

export const isSdbManager = () => {
  const permissions = store.getState().authenticationState?.account?.permissions;
  return checkPermission(['Service Database Managers'], permissions);
};

export const isMarketing = (permissions) => {
  return checkPermission('Marketing', permissions);
};

export const isSales = (permissions) => {
  return checkPermission(['Sales', 'FieldSales', 'KenyaSales', 'PreSales'], permissions);
};

export const isSDManager = () => {
  const permissions = store.getState().authenticationState?.account?.permissions;
  return checkPermission('SD Management Group', permissions);
};

export const isServiceDelivery = (permissions) => {
  return checkPermission('SD Management Group', permissions) || checkPermission('Service Delivery', permissions);
};

export const isNoc = (permissions) => {
  return checkPermission('NOC', permissions);
}

export const isFieldOps = (permissions) => {
  return checkPermission('FieldOps', permissions);
}

export const isQATester = () => {
  const permissions = store.getState().authenticationState?.account?.permissions;
  return permissions && checkPermission('QA Testers', permissions);
}

export const isShiftAdmin = (permissions) => {
  return checkPermission('Portal-Shifts-Admin', permissions);
}

export const inCreditRequestGroups = (permissions) => {
  return checkPermission(['Portal-Signoff-Business', 'Portal-Credit-Request'], permissions)
}


export const canViewTasks = (permissions) => {
  return checkPermission([
    'PreSales',
    'Sales',
    'KenyaSales',
    'Service Delivery',
    'Finance Staff',
    'Feasibility',
    'Legal',
    'Red File Signoff - Sales',
    'Red File Signoff - Finance',
    'Red File Signoff Service Delivery',
    'Portal-Signoff-Technical',
    'Portal-Signoff-Business',
    'Portal-Credit–Request',
  ], permissions);
}

export const canCreateCarrierCircuit = (permissions) => {
  return isServiceDelivery(permissions) ||
  isFieldOps(permissions) ||
  isNoc(permissions) ||
  isSdbManager();
}

export const canCreateRack = (permissions) => {
  return isServiceDelivery(permissions) ||
    isFieldOps(permissions) ||
    isNoc(permissions) ||
    isSdbManager();
}

export const canCreateNNI = (permissions) => {
  return isServiceDelivery(permissions) ||
    isFieldOps(permissions) ||
    isNoc(permissions) ||
    isSdbManager();
}
export const canAmendOrder = (permissions) => {
  return checkPermission(
    'Portal-Order-Amendment'
  , permissions);
}

export const canSyncWithCRM = () => {
  const permissions = store.getState().authenticationState?.account?.permissions;
  return checkPermission(
    'Developers'
    , permissions);
}

export const canAccessServiceDb = () => {
  const permissions = store.getState().authenticationState?.account?.permissions;
  return isNoc(permissions) ||
    isSdbManager() ||
    isSalesManager(permissions) ||
    isFieldOps(permissions) ||
    isServiceDelivery(permissions)
}

