import { defaultErrorFeedback } from './feedback';
import BillTemplateService from '../utils/BillTemplate';

export const BILL_TEMPLATES_SET_TEMPLATES = 'BILL_TEMPLATES_SET_TEMPLATES'
export const BILL_TEMPLATES_REPLACE_TEMPLATE = 'BILL_TEMPLATES_REPLACE_TEMPLATE'
export const BILL_TEMPLATES_ADD_TEMPLATE = 'BILL_TEMPLATES_ADD_TEMPLATE'


export const replaceBillTemplate = template => {
  return {type: BILL_TEMPLATES_REPLACE_TEMPLATE, template}
}

export const addBillTemplate = template => {
  return {type: BILL_TEMPLATES_ADD_TEMPLATE, template}
}
export function getBillTemplates() {
  return (dispatch) =>{
    return BillTemplateService.list().then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATES_SET_TEMPLATES, templates: result.data});
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}