import { CROSS_CONNECTS_SET_DATA } from '../actions/crossConnects';

const initialState = {
  list: [],
}

const crossConnects = (state = initialState, action) => {
  switch (action.type) {
    case CROSS_CONNECTS_SET_DATA:
      return {
        ...state,
        list: action.data
      }
    default:
      return state;
  }
}

export default crossConnects