

export const formValidator = (form, data, extraFieldValidation) => {
  let errors = []
  Object.entries(form)
    .forEach(([key, value]) => {
      if (extraFieldValidation) {
        const extra = extraFieldValidation(key, value);
        if (extra) {
          errors.push(...extra);
        }
      }
      if (data[key] === undefined || data[key] === null || data[key].length === 0) {
        const mandatory = value.mandatory === true || (typeof value.mandatory === 'function' && value.mandatory(data));
        if (mandatory) {
          errors.push(`You must provide a valid ${value.label}.`);
        }
      } else {
        if (value.minlength && data[key].length < value.minlength) {
          errors.push(`${value.label} must be at least ${value.minlength} characters.`);
        }
        if (value.min && data[key] < value.min) {
          errors.push(`${value.label} must be at least ${value.min}.`);
        }
        if (value.max && data[key] > value.max) {
          errors.push(`${value.label} must not be greater than ${value.max}.`);
        }
      }
    });

  return errors
}