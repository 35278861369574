import React, { useEffect, useState } from 'react';
import { Alert, Col, Row, } from 'reactstrap';
import generateFormFields from '../../../helpers/FormFieldGenerator';
import AccountInfoForm from './form';
import HeadlessModal from '../../../components/Modals/HeadlessModal';
import {getAccountAuditHistory, updateAccountInfoField} from '../../../actions/account';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import EntitySubFormCard from '../../../components/Cards/EntitySubFormCard';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import omit from 'lodash.omit';
import { connect, useDispatch } from 'react-redux'
import CollapsibleCard from "../../../components/CollapsibleCard";
import AuditHistory from "../../../components/AuditHistory";
import isEmpty from 'lodash.isempty';

const AccountInfo = ({
  account: {
    form,
    original,
    contacts,
    audits
  },
  optionSets,
  errors,
  loading,
  user,
  systemUsers,
  isNew
}) => {
  // redux
  const dispatch = useDispatch();

  const [showPopupText, setShowPopupText] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);

  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);

  useEffect(() => {
    if(!form.accountnumber){
      setDefaultValues();
    }
    if(!isNew && form.popupText){
      togglePopupText()

    }
  }, [form.accountnumber])

  const togglePopupText = () => {
    setShowPopupText((prevState) => !prevState)
  }

  const setDefaultValues = () => {
    handleSelectInput('isRM3808', {value: false})
    handleSelectInput('addressCountry', optionSets.addressCountry.options.find(country => country.label === 'United Kingdom'))
    handleSelectInput('customerType', optionSets.customerType.options.find(type => type.label === 'Prospect'))
    handleSelectInput('accountManager', {value: systemUsers.find(user => user.id === user.cui)?.id})
  }
  const getSelectOptions = (field) => {
    if (field === 'accountManager' || field === 'projectManager') {
      return Object.values(systemUsers)
        .map((option) => {
          return {
            value: option.id,
            label: option.name
          };
        });
    } else if (field === 'primaryContact') {
      return Object.values(contacts)
        .map((option) => {
          return {
            value: option.id,
            label: option.name,
            isDisabled: !option.isActive
          };
        });
    }
    else if (optionSets[field]) {
      return optionSets[field].options.map((option) => {
        return {
          value: option.value,
          label: option.label
        };
      });
    }
    return [];
  };

  const getSelectedOption = (field) => {
    let selected = [];
    if (field === 'accountManager' || field === 'projectManager'){
      selected = systemUsers.filter((option) => {
        return form[field] && option.id === form[field];
      });
    } else if (field === 'primaryContact'){
      selected = contacts.filter((option) => {
        return form[field] && (option.id === form[field].id || option.id === form[field] ) ;
      });
    } else if (optionSets[field]) {
      selected = optionSets[field].options.filter(option => option.value === form[field]);
    }
    if (selected.length) {
      return selected[0];
    }

    return null;
  };

  const handleInput = (event) => {
    const field = event.target.id;
    const value = event.target.value;
    dispatch(updateAccountInfoField({[field]: value}));
  };

  const handleInputDate = (field, value) => {
    dispatch(updateAccountInfoField({[field]: value}));
  };

  const handleSelectInput = (key, selected) => {
    if(key === 'primaryContact'){
      const contact = contacts.find(contact => contact.id === selected?.value)
      dispatch(updateAccountInfoField({[key] : contact ? omit(contact, ['portalUser']) : null }));
    }else{
      dispatch(updateAccountInfoField({[key] :selected?.value ?? null}));
    }

  };

  const getAudits = () => {
    toggleAuditsLoading();
    dispatch(getAccountAuditHistory(original.accountid)).then(() => toggleAuditsLoading())
  }

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <FormValidationErrors errors={errors}/>
        <Row>
          <Col md={4}>
            <EntityMainFormCard>
              <Row form>
                {generateFormFields({
                  fields: AccountInfoForm.infoFields,
                  handleInput,
                  getSelectOptions,
                  getSelectedOption,
                  handleSelectInput,
                  data: form
                })}
              </Row>
            </EntityMainFormCard>
          </Col>
          <Col >
            <Row >
              <Col md={12}>
                <EntitySubFormCard title={'Address'}>
                  <Row form>
                    {generateFormFields({
                      fields: AccountInfoForm.contactFields,
                      handleInput,
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      data: form
                    })}
                  </Row>
                </EntitySubFormCard>
              </Col>
              <Col className={"d-flex"}>
                <Row className={"d-flex"}>
                  <Col className={"d-flex"}>
                    <EntitySubFormCard title={'Billing'}>
                      <Row form>
                        {generateFormFields({
                          fields: AccountInfoForm.billingFields,
                          handleInput,
                          getSelectOptions,
                          getSelectedOption,
                          handleSelectInput,
                          handleInputDate,
                          data: form
                        })}
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                  <Col className={"d-flex"} md={6}>
                    <EntitySubFormCard title={'Legal'}>
                      <Row form>
                        {generateFormFields({
                          fields: AccountInfoForm.legalFields,
                          handleInput,
                          getSelectOptions,
                          getSelectedOption,
                          handleSelectInput,
                          handleInputDate,
                          data: form,
                          permissions: user.permissions,
                          optionSets
                        })}
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <CollapsibleCard
                title={'Audit History'}
                onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
            >
              <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
            </CollapsibleCard>
          </Col>
        </Row>
      </LoadingOverlay>
      <HeadlessModal
        open={showPopupText}
        toggle={togglePopupText}
      >
        <Alert toggle={togglePopupText} color={'light'} className={"mb-0"}>
          <h4 className="alert-heading text-warning">Important!</h4>
          <p style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>{form.popupText}</p>
        </Alert>
      </HeadlessModal>
    </div>
  );
};
function mapStateToProps({account, helpers, authenticationState, priceLists }) {
  return {
    optionSets: {...helpers.optionSets.account,priceListType:  helpers.optionSets.priceList.type},
    account,
    systemUsers: helpers.systemUsers,
    user: authenticationState.account
  };
}
export default connect(mapStateToProps)(AccountInfo);
