import { LogicalServiceService } from '../../utils/ServiceDB/LogicalService';
import { defaultErrorFeedback } from '../feedback';

export const LOGICAL_SERVICE_SET_DATA = 'LOGICAL_SERVICE_SET_DATA';
export const LOGICAL_SERVICE_SET_FIELD = 'LOGICAL_SERVICE_SET_FIELD';
export const LOGICAL_SERVICE_RESET_DATA = 'LOGICAL_SERVICE_RESET_DATA';

export function getService(serviceTypeName, id, include = []) {
  return (dispatch) => {
    return LogicalServiceService.get(serviceTypeName, id, include).then((result) => {
      if (result.status === 200) {
        dispatch(setServiceData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createService(serviceTypeName, args, include = []) {
  return (dispatch) => {
    return LogicalServiceService.create(serviceTypeName, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setServiceData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function updateService(serviceTypeName, id, args, include = []) {
  return (dispatch) => {
    return LogicalServiceService.update(serviceTypeName, id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setServiceData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function deleteService(serviceTypeName, id) {
  return (dispatch) => {
    return LogicalServiceService.delete(serviceTypeName, id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

function setServiceData(data) {
  return {
    type: LOGICAL_SERVICE_SET_DATA,
    data
  };
}

export function setServiceField(field, value) {
  return {
    type: LOGICAL_SERVICE_SET_FIELD,
    field,
    value
  };
}

export function resetService() {
  return {
    type: LOGICAL_SERVICE_RESET_DATA
  };
}
