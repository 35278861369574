import React, { useEffect, useState } from 'react';
import WavelengthForm from './form';
import { getOpticalSystems } from '../../../../../actions/opticalSystems';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import GenericForm from '../../../../../components/GenericForm';
import LoadingOverlay from '../../../../../components/LoadingOverlay';

const Wavelength = ({
  dispatch,
  connection,
  opticalSystems,
  data,
  update
}) => {

  const [availableChannels, setAvailableChannels] = useState([]);
  const [loading, setLoading] = useState(false);
  const toggleLoading = () => setLoading(prevState => !prevState)

  const channelIsUsed = (i) => data.opticalSystem.wavelengths?.find(
    wavelength => Number(wavelength.channel) === i) ||
    connection.components.find(component => component.type === 'wavelength' &&
      component.data.opticalSystem?.id === data.opticalSystem.id &&
      component.data.channel === i
    )

  useEffect(() => {
    const channels = [];
    if (!isEmpty(data.opticalSystem)) {
      for (let i = data.opticalSystem.startChannel; i < (data.opticalSystem.startChannel + data.opticalSystem.channels); i++) {
        if (data.channel === i || !channelIsUsed(i)) {
          channels.push(i);
        }
      }
    }
    setAvailableChannels(channels);
  }, [data?.opticalSystem]);

  useEffect(() => {
    if (!isEmpty(opticalSystems) && data.opticalSystem && isEmpty(data.opticalSystem.wavelengths)) {
      const opticalSystem = opticalSystems.find(opticalSystem => opticalSystem.id === data.opticalSystem.id);
      //update('opticalSystem', opticalSystem);
    }
  }, [opticalSystems, data?.opticalSystem]);

  useEffect(() => {
    if (isEmpty(opticalSystems)) {
      toggleLoading()
      dispatch(getOpticalSystems([], [], ['wavelengths'])).then(() => toggleLoading())
    }
  }, [])

  const options = {
    opticalSystem: opticalSystems.map(opticalSystem => ({
      value: opticalSystem,
      label: opticalSystem.name
    })),
    channel: availableChannels.map(availableChannel => ({
      value: availableChannel,
      label: availableChannel
    }))
  };

  return (
    <LoadingOverlay loading={loading}>
      <GenericForm
        data={data}
        form={WavelengthForm}
        setField={(field, value) => update(field, value)}
        options={options}
      />
    </LoadingOverlay>

    /*<ComponentCardForm
      id={original.id}
      isNew={!Number.isInteger(original.id)}
      onFetchAll={() => {
        const promises = [
          setData(original)
        ];
        if (!opticalSystems.length) {
          promises.push(dispatch(getOpticalSystems([], [], ['wavelengths'])));
        }
        return Promise.all(promises);
      }}
      title={data.name ? `Wavelength: ${data.name}` : 'New Wavelength'}
      original={original}
      data={data}
      form={form}
      setField={(field, value) => setData(prevState => ({ ...prevState, [field]: value }))}
      options={options}
      requestStructure={requestStructure}
      onSave={onSave}
      onDelete={onDelete}
      onDetach={onDetach}
      onMoveUp={onMoveUp}
      onMoveDown={onMoveDown}
    />*/
  );
};

function mapStateToProps({ opticalSystems }) {
  return {
    ...opticalSystems
  };
}

export default connect(mapStateToProps)(Wavelength);