import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  createAddress,
  deleteAddress,
  getAddress,
  getNextAddressForSubnet,
  resetAddress,
  setAddressField,
  updateAddress
} from '../../../../actions/ServiceDB/Ipam/address';
import GenericCardForm from '../../../../components/GenericCardForm';
import { Col, Row } from 'reactstrap';
import form from './form';
import isEmpty from 'lodash.isempty';
import { SubnetService } from '../../../../utils/ServiceDB/Ipam/Subnet';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import ApiErrorResolver from '../../../../helpers/ApiErrorResolver';
import Validator from '../../../../helpers/Validator';
import { LogicalServiceService } from '../../../../utils/ServiceDB/LogicalService';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../../actions/breadcrumbs';
import { canAccessServiceDb, checkPermission } from '../../../../utils/Auth/AuthService';
import { setSubnetField } from '../../../../actions/ServiceDB/Ipam/subnet';
import { useNavigate, useParams } from 'react-router-dom'

const Address = ({
  id = null,
  data,
  original,
  onCreated = null,
  onUpdated = null,
  onDeleted = null,
  location = null,
  logicalServiceTypes,
  hideAllocatedTo = false
}) => {

  // router
  const navigate = useNavigate();
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  const [addressButtonLoading, setAddressButtonLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const addressId = useMemo(() => (id || params.addressId), [id, params.addressId])
  const isNew = useMemo(() => addressId === 'new' && !original.id, [addressId, original.id])

  useEffect(() => {
    if (original.name && original.subnet?.name) {
      dispatch(addBreadcrumbs([
        { name: original.subnet.name, path: `/sdb/ipam/subnets/${original.subnet.id}` },
        { name: original.name }
      ]))
    }

    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [original.name, original.subnet?.name]);

  const options = {
    allocatedToType: logicalServiceTypes.map(service => ({ value: service.slug, label: service.label }))
  };
  const aSyncOptions = {
    subnet: (search) => SubnetService.list(['childSubnets'], [
      { id: 'name', value: search },
      { id: 'networkAddress', value: search },
      { id: 'mask', value: search }
    ])
      .then((result) => result.data?.map(subnet => {
        const hasChildSubnets = !isEmpty(subnet.childSubnets);
        const invalidType = (location.ipVersion && (location.ipVersion !== subnet.ipVersion));
        return {
          ...subnet,
          label: `${subnet.name} (${subnet.networkAddress}/${subnet.mask})${hasChildSubnets ? ' (has existing child subnets)' : ''}${invalidType ? ` (IP must be of IP version ${location.ipVersion})` : ''}`,
          isDisabled: hasChildSubnets || invalidType
        };
      }) || []),
    logicalService: (search) => LogicalServiceService.list(data.allocatedTo?.type, [], [{
      id: 'name',
      value: search
    }])
      .then((result) => result.data.map(service => ({
        type: data.allocatedTo.type,
        data: service
      })) || [])
  };

  const requestStructure = (data) => ({
    name: data.name,
    description: data.description,
    subnetId: data.subnet?.id,
    address: data.address,
    allocatedToType: data.allocatedTo?.type,
    allocatedToId: data.allocatedTo?.data?.id
  });

  const onFetchAll = () => {
    const promises = [];
    if (location.subnet) {
      promises.push(dispatch(setAddressField('subnet', location.subnet)));
    }
    if (location.name) {
      promises.push(dispatch(setSubnetField('name', location.name)));
    }
    return Promise.all(promises);
  };

  const extraFieldValidation = (key, value) => {
    const errorArr = [];
    if (key === 'address' && !(Validator.Ipam.validIpv4(data[key]) || Validator.Ipam.validIpv6(data[key]))) {
      errorArr.push(`The ${value.label} is invalid`);
    }
    return errorArr;
  };

  const getNextAddressButton = () => {
    if (!isEmpty(data.subnet) && isEmpty(data.address)) {
      return <ButtonIcon
        icon={'fa fa-download'}
        loading={addressButtonLoading}
        tooltip=""
        onClick={() => {
          setAddressButtonLoading(true);
          dispatch(getNextAddressForSubnet(data.subnet.id))
            .then((result) => {
              if (result.status === 422 && result.data) {
                setErrors([...new Set(ApiErrorResolver(result.data))]);
              } else if (result.status !== 200) {
                setErrors(['There was an issue getting the network address.']);
              }
              setAddressButtonLoading(false);
            });
        }}
        className="float-right"
      />;
    }
    return '';
  };

  const selectValue = {
    allocatedToType: () => options.allocatedToType.find(opt => data.allocatedTo?.type === opt.value) || null
  };

  const setField = (field, value) => {
    if (field === 'allocatedToType') {
      dispatch(setAddressField('allocatedTo', { type: value, data: {} }));
    }
    dispatch(setAddressField(field, value));
  };

  return (
    <Row>
      <Col>
        <GenericCardForm
          id={addressId}
          title={data.name || 'New Address'}
          isNew={isNew}
          form={form}
          data={data}
          original={original}
          extraFormData={{
            subnetProvided: !isEmpty(location.subnet),
            allocatedToType: data.allocatedTo?.type,
            hideAllocatedTo: !!hideAllocatedTo,
            getNextAddressButton
          }}
          selectValue={selectValue}
          options={options}
          extraErrors={errors}
          aSyncOptions={aSyncOptions}
          requestStructure={requestStructure}
          extraFieldValidation={extraFieldValidation}
          onFetch={() => dispatch(getAddress(addressId, ['subnet', 'allocatedTo']))}
          onFetchAll={onFetchAll}
          onReset={() => dispatch(resetAddress())}
          setField={setField}
          onCreate={(toCreate) => dispatch(createAddress(toCreate, ['subnet', 'allocatedTo']))}
          onCreated={(result) => onCreated ? onCreated(result) : navigate(`/sdb/ipam/addresses/${result.id}`)}
          onUpdate={(toUpdate) => dispatch(updateAddress(addressId, toUpdate, ['subnet', 'allocatedTo']))}
          onUpdated={(result) => onUpdated ? onUpdated(result) : {}}
          onDelete={() => dispatch(deleteAddress(addressId))}
          onDeleted={() => onDeleted ? onDeleted(addressId) : navigate(`/sdb/ipam/subnets/${data.subnet.id}`)}
          canEdit={canAccessServiceDb()}
        />
      </Col>
    </Row>
  );
};

function mapStateToProps({ ipamAddress, helpers, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    data: ipamAddress.data,
    original: ipamAddress.original,
    logicalServiceTypes: helpers.serviceDb.logicalServiceTypes
  };
}

export default connect(mapStateToProps)(Address);
