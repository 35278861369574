import { errorFeedback, successFeedback } from './feedback';
import { api_getAlarms, api_ackAlarm, api_ackAlarmWithCase, api_filterAlarm, api_systemStatus } from '../utils/Alarms/AlarmsService';

export const UPDATE_ALARM_LIST = 'UPDATE_ALARM_LIST';
export const RESET_ALARM_UPDATED = 'RESET_ALARM_UPDATED';
export const ADD_ACK_TO_ALARM = 'ADD_ACK_TO_ALARM';
export const ALARM_PROCESSING_START = 'ALARM_PROCESSING_START';
export const ALARM_PROCESSING_STOP = 'ALARM_PROCESSING_STOP';
export const ADD_FILTER_TO_ALARM = 'ADD_FILTER_TO_ALARM';
export const SET_SYSTEM_STATUS = 'SET_SYSTEM_STATUS';
export const SET_ZABBIX_STATUS = 'SET_ZABBIX_STATUS';
export const SET_ZABBIX_PROXIES = 'SET_ZABBIX_PROXIES';

export function setAlarmData(alarms, acks, filters) {
  return {
    type: UPDATE_ALARM_LIST,
    alarms,
    acks,
    filters
  };
}

function setSystemStatus(data) {
  return {
    type: SET_SYSTEM_STATUS,
    data
  };
}

function resetAlarmUpdated() {
  return {
    type: RESET_ALARM_UPDATED
  };
}

function addAckToAlarm(event, message) {
  return {
    type: ADD_ACK_TO_ALARM,
    event,
    message
  };
}

function addFilterToAlarm(event, message, dateto, tags) {
  return {
    type: ADD_FILTER_TO_ALARM,
    event,
    message,
    dateto,
    tags
  };
}

function alarmProcessingStart(trigger) {
  return {
    type: ALARM_PROCESSING_START,
    trigger
  };
}

function alarmProcessingStop(trigger) {
  return {
    type: ALARM_PROCESSING_STOP,
    trigger
  };
}

export function updateAlarmList() {
  return (dispatch) => {
    return api_getAlarms().then((result) => {
      if (result.status === 200 && result.data.data) {
        dispatch(setAlarmData(result.data.data, result.data.acks, result.data.filters));
      } else {
        dispatch(errorFeedback('Could not fetch alarm data'));
      }
    });
  };
}

export function ackAlarm(event, trigger, message) {
  return (dispatch) => {
    dispatch(alarmProcessingStart(trigger));
    return api_ackAlarm(event, message).then((result) => {
      dispatch(alarmProcessingStop(trigger));
      if (result.status === 200) {
        dispatch(addAckToAlarm(event, message));
        dispatch(successFeedback('Event ' + event + ' acknowledged'));
      } else {
        dispatch(errorFeedback('Could not acknowledge event.'));
      }
    });
  };
}

export function ackAlarmWithCase(event, trigger) {
  return (dispatch) => {
    dispatch(alarmProcessingStart(trigger));
    return api_ackAlarmWithCase(event).then((result) => {
      dispatch(alarmProcessingStop(trigger));
      if (result.status === 200) {
        dispatch(addAckToAlarm(event, 'Case created'));
        dispatch(successFeedback('Event ' + event + ' acknowledged and case created'));
      } else {
        dispatch(errorFeedback('Could not acknowledge event and create case.'));
      }
    });
  };
}

export function filterAlarm(event, trigger, message, dateto, tags) {
  return (dispatch) => {
    dispatch(alarmProcessingStart(trigger));
    return api_filterAlarm(event, message, dateto, tags).then((result) => {
      dispatch(alarmProcessingStop(trigger));
      if (result.status === 200) {
        dispatch(addAckToAlarm(event, message));
        dispatch(addFilterToAlarm(event, message, dateto, tags));
        dispatch(successFeedback('Event ' + event + ' filtered'));
      } else {
        dispatch(errorFeedback('Could not filter the event.'));
      }
    });
  };
}

export function getSystemStatus() {
  return (dispatch) => {
    return api_systemStatus().then((result) => {
      if (result.status === 200) {
        dispatch(setSystemStatus(result.data));
      } else {
        dispatch(errorFeedback('Could not fetch system status.'));
      }
    });
  };
}
