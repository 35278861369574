import { netApiRequest } from '../Api/ApiRequest';

export function getCeleryQueues() {
  return netApiRequest({
    url: '/jobs/queues',
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}
