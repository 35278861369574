import {
  IPAM_ADDRESS_RESET_DATA,
  IPAM_ADDRESS_SET_DATA,
  IPAM_ADDRESS_SET_FIELD,
} from '../../../actions/ServiceDB/Ipam/address';

const initialState = {
  data: {},
  original: {}
};
const address = (state = initialState, action) => {
  switch (action.type) {
    case IPAM_ADDRESS_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case IPAM_ADDRESS_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case IPAM_ADDRESS_RESET_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default address;