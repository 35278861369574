import React, { Component } from 'react'
import { connect } from 'react-redux';
import ReactTable from 'react-table-v6'
import moment from 'moment'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const BillingRunsTable = () => {

  // router
  const navigate = useNavigate()

  const { runs } = useSelector(state => state.billingRuns)

  const columns = [
    { Header: 'Id', accessor: 'id', filterable: false, sortable: false, maxWidth: 50 },
    { Header: 'Started By', accessor: 'startedBy', filterable: false, sortable: true },
    {
      Header: 'Started At',
      accessor: 'startedAt',
      filterable: false,
      sortable: true,
      Cell: props => moment(props.value).format('DD/MM/YYYY')
    },
    { Header: 'Status', accessor: 'status', filterable: false, sortable: true },
    {
      Header: 'Total Value',
      accessor: 'totalValue',
      filterable: false,
      sortable: true,
      Cell: props => '£' + numeral(props.value).format('0,0.00')
    }
  ]

  return (
    <ReactTable
      NoDataComponent={() => null}
      getTheadProps={() => {
        return {
          style: { border: 'none' }
        }
      }}
      getTheadTrProps={() => {
        return {
          style: { border: 'none' }
        }
      }}
      getTheadThProps={() => {
        return {
          style: { border: 'none' }
        }
      }}
      getTdProps={(state, rowInfo, column) => {
        return {
          style: { border: 'none', fontWeight: column.Header === 'Name' ? 'bold' : 'regular' }
        }
      }}
      getTrProps={(state, rowInfo, column) => {
        return {
          style: { cursor: rowInfo ? 'pointer' : 'auto' },
          onClick: () => {
            navigate('/billing/run/' + rowInfo.row.id)
          }
        }
      }}
      className="animated fadeIn -highlight -striped"
      noDataText="No Accounts found."
      data={runs}
      columns={columns}
      minRows="10"
      filterable={false}
      defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
      defaultSorted={[{ id: 'startedAt', desc: true }]}
      showPagination={[].length > 20}
    />
  )
}

function mapStateToProps ({ billingRuns }) {
  return {
    billingRuns
  }
}

export default connect(mapStateToProps)(BillingRunsTable)
