import {Badge, Button, Row} from "reactstrap";
import React from "react";
import {BorderedText} from "../../BorderedText";
import moment from "moment/moment";
import {ColumnSort, createColumnHelper, SortingFn} from "@tanstack/react-table";
import {PriceList} from "../../../types";
import FilteredTableV2 from "../../FilteredTableV2";
import {useNavigate} from "react-router-dom";
import {ButtonIcon} from "../../ButtonIcon";
import {resolveCurrencySign} from "../../../utils/Helpers/Currency";

type TOwnProps = {
    priceLists: PriceList[]
    defaultSorted?: ColumnSort
    fetchData: Function
}

const PriceListsTable: React.FC<TOwnProps> = (
    {
        priceLists,
        defaultSorted,
        fetchData
    }) => {

    const columnHelper = createColumnHelper<PriceList>();
    const navigate = useNavigate();

    const sortStatusFn: SortingFn<PriceList> = (rowA, rowB, _columnId) => {
        const statusA = rowA.original.status.id
        const statusB = rowB.original.status.id
        return statusA < statusB ? -1 : statusA > statusB ? 1 : 0
    }

    const resolveStatusColour = (status: string) => {
        switch (status){
            case 'Active':
                return 'success'
            case 'Draft':
                return 'warning'
            default:
                return 'danger'
        }
    }

    const resolveTypeColour = (type: string) => {
        switch (type){
            case 'enterprise':
                return 'primary'
            case 'wholesale':
                return 'success'
            default:
                return 'info'
        }
    }



    const columns = [
        columnHelper.accessor('name', {
            header: 'Name',
        }),
        columnHelper.accessor(row => row.company.name || '', {
            header: 'Account',
            sortUndefined: 'last'
        }),
        columnHelper.accessor('type.name', {
            id: 'typeName',
            header: 'Type',
            cell: props => <BorderedText borderColour={resolveTypeColour(props.getValue())} textColour={resolveTypeColour(props.getValue())} text={props.getValue()}/>,
        }),
        columnHelper.accessor('currency.id', {
            id: 'currencyName',
            header: 'Currency',
            cell: props => resolveCurrencySign(props.getValue())

        }),
        columnHelper.accessor('status.name', {
            id: 'status',
            header: 'Status',
            cell: props => <Badge color={resolveStatusColour(props.getValue())}>{props.getValue()}</Badge>,
            sortingFn: sortStatusFn
        }),
        columnHelper.accessor('createdAt', {
            header: 'Created At',
            cell: props => moment(props.getValue())
                .format('DD/MM/YYYY HH:mm'),
        })
    ]

    return (
        <>
            {/* @ts-ignore */}
            <FilteredTableV2
                extraButtons={[
                    <Button size={'sm'} color={'secondary'} onClick={() => {
                        navigate('/products/pricelists/new')
                    }}>New Price List</Button>,
                    <ButtonIcon icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={fetchData}/>
                ]}
                showPagination
                showPageSizeOptions
                defaultPageSize={20}
                withFilter={'includesString'}
                columns={columns}
                data={priceLists}
                minRows={10}
                defaultSorted={defaultSorted || [{
                    id: 'status',
                    desc: false
                }]}
                onRowClick={(data: PriceList) => navigate(`/products/pricelists/${data.id}`)}
                />
        </>
    )
}

export default PriceListsTable;