import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader, InputGroup, Form, Row, Col, Button, Spinner} from "reactstrap";
import { CSVLink } from 'react-csv';

class Serving extends Component {

  constructor(props) {
    super(props);
    this.pcRef = React.createRef();
    this.handleSearch = this.handleSearch.bind(this);
    this.exportAsCSV = this.exportAsCSV.bind(this);
  }

  handleSearch(e) {
    e.preventDefault();
    const postcodes = this.pcRef.current.value.split(/\r?\n/);
    let done = {};
    postcodes.filter(pc => pc !== '').forEach((pc) => {
      if(!(pc in done)) {
        done[pc] = true;
        // fix up the postcode
        pc = pc.replace(/\s/g, '').toUpperCase();
        pc = pc.slice(0, pc.length-3) + " " + pc.slice(-3);
        if (!(pc in this.props.serving)) {
          this.props.dispatch({type: 'EXCHANGE_ADD_SERVING', postcode: pc});
          this.props.dispatch({type: 'EXCHANGE_FETCH_SERVING', payload: {postcode: pc}});
        }
      }
    });
  }

  exportAsCSV() {
    return Object.keys(this.props.serving).map((pc) => {
      return {
        postcode: pc,
        mdf: this.props.serving[pc].data?.mdf,
        name: this.props.serving[pc].data?.name
      };
    });
  }

  render() {
    return(<div>
      <Card className={"card-accent-primary"}>
        <CardHeader>
          <Row>
            <Col>
          Serving Exchange Lookup
            </Col>
            <Col className={'d-flex justify-content-end'}>
              {Object.keys(this.props.serving).length > 0 &&
              <CSVLink data={this.exportAsCSV()} filename='exchanges.csv' style={{textDecoration: 'none'}} className={'btn btn-sm btn-primary'}>
                <i className="icon-cloud-download"/> Export
              </CSVLink>
              }
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row><Col md={3}>
          <Form onSubmit={this.handleSearch}>
            <InputGroup>
              <textarea type="textarea" className="form-control" placeholder="Postcodes, one per line" ref={this.pcRef} rows={20}/>
            </InputGroup>
            <div className="clearfix" style={{ marginTop: 10 + 'px', marginBottom: 20 + 'px' }}>
              <Button type="submit" color="primary" className="float-right" style={{ minWidth: 113 + 'px', minHeight: 35 + 'px' }}>Check</Button>
            </div>
          </Form>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col>Postcode</Col>
                  <Col>Exchange MDF</Col>
                  <Col>Exchange Name</Col>
                </Row>
              </CardHeader>
              <CardBody>
                {Object.keys(this.props.serving).map((pc) => {
                  return <Row>
                    <Col>{pc}</Col>
                    <Col>{this.props.serving[pc].status === 0 ? <Spinner size={'sm'}/> : this.props.serving[pc].status === 1 ? this.props.serving[pc].data.mdf : <p className={"text-danger"}>{this.props.serving[pc].data}</p>}</Col>
                    <Col>{this.props.serving[pc].status === 0 ? '' : this.props.serving[pc].data.name}</Col>
                  </Row>
                })}
              </CardBody>
            </Card>
          </Col>
          </Row>
        </CardBody>
      </Card>
        </div>)
  }
}

function mapStateToProps({discovery}) {
  return {
    serving: discovery.serving
  };
}

export default connect(mapStateToProps)(Serving);
