import { defaultErrorFeedback } from './feedback';
import { CarrierCircuitService } from '../utils/ServiceDB/CarrierCircuit';
import AuditService from '../utils/Audit';
import { ORDER_SET_AUDIT_HISTORY } from './order';

export const CARRIER_CIRCUIT_SET_DATA = 'CARRIER_CIRCUIT_SET_DATA';
export const CARRIER_CIRCUIT_SET_FIELD = 'CARRIER_CIRCUIT_SET_FIELD';
export const CARRIER_CIRCUIT_SET_FIELDS = 'CARRIER_CIRCUIT_SET_FIELDS';
export const CARRIER_CIRCUIT_REMOVE_PRODUCT = 'CARRIER_CIRCUIT_REMOVE_PRODUCT';
export const CARRIER_CIRCUIT_ADD_NOTE = 'CARRIER_CIRCUIT_ADD_NOTE';
export const CARRIER_CIRCUIT_UPDATE_NOTE = 'CARRIER_CIRCUIT_UPDATE_NOTE';
export const CARRIER_CIRCUIT_REMOVE_NOTE = 'CARRIER_CIRCUIT_REMOVE_NOTE';
export const CARRIER_CIRCUIT_RESET_DATA = 'CARRIER_CIRCUIT_RESET_DATA';
export const CARRIER_CIRCUIT_SET_AUDIT_HISTORY = 'CARRIER_CIRCUIT_SET_AUDIT_HISTORY';

function setCarrierCircuitData(data) {
  return {
    type: CARRIER_CIRCUIT_SET_DATA,
    data
  };
}

export function updateCarrierCircuitField(field, value) {
  return {
    type: CARRIER_CIRCUIT_SET_FIELD,
    field,
    value
  };
}

export function updateCarrierCircuitFields(fields) {
  return {
    type: CARRIER_CIRCUIT_SET_FIELDS,
    fields
  };
}

export function resetCarrierCircuit() {
  return {
    type: CARRIER_CIRCUIT_RESET_DATA
  };
}

export function removeCarrierCircuitProduct(id) {
  return {
    type: CARRIER_CIRCUIT_REMOVE_PRODUCT,
    id
  };
}

export function addCarrierCircuitNote(note) {
  return {
    type: CARRIER_CIRCUIT_ADD_NOTE,
    note
  };
}

export function updateCarrierCircuitNote(note) {
  return {
    type: CARRIER_CIRCUIT_UPDATE_NOTE,
    note
  };
}

export function removeCarrierCircuitNote(note) {
  return {
    type: CARRIER_CIRCUIT_REMOVE_NOTE,
    note
  };
}

export function getCarrierCircuit(id, include = []) {
  return (dispatch) => {
    return CarrierCircuitService.getCarrierCircuit(id, include).then((result) => {
      if (result.status === 200) {
        dispatch(setCarrierCircuitData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createCarrierCircuit(args, include = []) {
  return (dispatch) => {
    return CarrierCircuitService.createCarrierCircuit(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setCarrierCircuitData(result.data));
        return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      }
      dispatch(defaultErrorFeedback());
      return null;
    });
  };
}

export function updateCarrierCircuit(id, args, include = []) {
  return (dispatch) => {
    return CarrierCircuitService.updateCarrierCircuit(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setCarrierCircuitData(result.data));
        return result.data
      }else if(result.status === 422){
        return {errors: result.data}
      }
      dispatch(defaultErrorFeedback());
      return null;
    });
  };
}

export function deleteCarrierCircuit(id) {
  return (dispatch) => {
    return CarrierCircuitService.deleteCarrierCircuit(id)
      .then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function getCarrierCircuitAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('sdb/connections/carrier-circuits', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: CARRIER_CIRCUIT_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}