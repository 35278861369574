import { apiRequest } from '../Api/ApiRequest';
import { stringify } from 'query-string';

export function getRadiusAttributes(username) {
  return apiRequest({
    url: '/radius/attributes/' + username,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function radiusApiPut(url, data) {
  return apiRequest({
    url: url,
    method: 'put',
    headers:
            {
              'Content-Type': 'application/json'
            },
    data: JSON.stringify(data)
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function radiusApiPost(url, data = null) {
  return apiRequest({
    url: url,
    method: 'post',
    headers:
            {
              'Content-Type': 'application/json'
            },
    data: JSON.stringify(data)
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function radiusApiDelete(url, data) {
  return apiRequest({
    url: url,
    method: 'delete',
    headers:
            {
              'Content-Type': 'application/json'
            },
    data: JSON.stringify(data)
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function radiusApiGetQuery(url, data = {}, query) {
  return apiRequest({
    url: url + '?' + stringify({ ...query }),
    method: 'get',
    headers:
            {
              'Content-Type': 'application/json'
            },
    data: JSON.stringify(data)
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
