import {
  ORDER_RESET_ORDERS,
  ORDER_SET_ORDERS,
  ORDERS_REMOVE_ORDER_FROM_LIST,
  ORDERS_REPLACE_LIST
} from '../actions/orders';


const initialState = {
  list: [],
}


export function orders(state = initialState, action){

  switch (action.type) {
    case ORDER_SET_ORDERS:
      return {
        ...state,
        list: [...action.orders]
      }
    case ORDER_RESET_ORDERS:
      return {
        ...state,
        list: []
      }
    case ORDERS_REMOVE_ORDER_FROM_LIST:
      return {
        ...state,
        list: state.list.filter(order => order.id !== action.order.id),
        filteredList: state.filteredList.filter(order => order.id !== action.order.id)
      }
    case ORDERS_REPLACE_LIST:
      return {
        ...state,
        list: state.list.map(order => {
          if (order.id === action.order.id) {
            return action.order;
          }
          return order;
        }),
        filteredList: state.list.map(order => {
          if (order.id === action.order.id) {
            return action.order;
          }
          return order;
        })
      };
    default:
      return state;
  }
}
