import { PRODUCT_GROUPS_SET } from '../actions/productGroups';

const initialState = {
  searchString: '',
  list: [],
  filteredList: []
}

export function productGroups(state = initialState, action){
  switch (action.type) {
    case PRODUCT_GROUPS_SET:
      return {
        ...state,
        list: action.groups
      }
    default:
      return state
  }

}
