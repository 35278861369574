const form = {
  reportType: {
    label: 'Report Type',
    type: 'select',
    mandatory: true
  },
  period: {
    label: 'Period',
    type: 'select',
    mandatory: true,
    show: (field, data) => data.showPeriodField
  },
  periodFrom: {
    label: 'From',
    type: 'date',
    readOnly: (key, field, data) => data.period !== 'custom',
    show: (field, data) => data.showPeriodField
  },
  periodTo: {
    label: 'To',
    type: 'date',
    readOnly: (key, field, data) => data.period !== 'custom',
    show: (field, data) => data.showPeriodField
  }
}

export default form;
