import React from 'react';
import { FormGroup } from 'reactstrap';

const Callout = ({ colour, header, text, textColour }) => {
  const className = colour ? 'callout callout-' + colour : 'callout b-l-0 m-0';
  const textClass = textColour ? `h4 text-${textColour}` : 'h4';
  return (
        <FormGroup>
            <div className={className}>
                <small className="text-muted">{header}</small>
                <br/>
                <strong className={textClass}>{text}</strong>
            </div>
        </FormGroup>
  );
};

export default Callout;
