import {VENDORS_ADD_VENDOR, VENDORS_SET_VENDORS, VENDORS_UPDATE_VENDOR} from '../actions/vendors';

const initialState = {
  vendors: []
};

const vendors = (state = initialState, action) => {
  switch (action.type) {
    case VENDORS_SET_VENDORS:
      return {
        vendors: [...action.data]
      };
    case VENDORS_ADD_VENDOR:
      return {
        ...state,
        vendors: [...state.vendors, action.data]
      };
    case VENDORS_UPDATE_VENDOR:
      return {
        ...state,
        vendors: state.vendors.map(vendor => (vendor.id === action.data?.id) ? action.data : vendor)
      };
    default:
      return state;
  }
};

export default vendors;