import { defaultErrorFeedback } from './feedback';
import CampaignService from '../utils/Campaigns';

export const CAMPAIGN_SET_LIST = 'CAMPAIGN_SET_LIST';


function setList(list){
  return {
    type: CAMPAIGN_SET_LIST,
    list
  }
}

export const getCampaigns = () => {
  return (dispatch) => {
    return CampaignService.getAll()
      .then((result) => {
        if (result.status === 200) {
          dispatch(setList(result.data));
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
  };
};
