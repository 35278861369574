import { NNI_RESET_DATA, NNI_SET_DATA, NNI_SET_FIELD } from '../actions/nni';

const initialState = {
  data: {
    connections: []
  },
  original: {
    connections: []
  }
};
const nni = (state = initialState, action) => {
  switch (action.type) {
    case NNI_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case NNI_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case NNI_RESET_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default nni;