import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Row, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { MapEditor } from 'react-network-diagrams-hso';
import { SpinnerInfo } from '../../components/Spinner/Spinner';
import { WeathermapService } from '../../utils/Weathermaps/WeathermapService';
import { errorFeedback, successFeedback } from '../../actions/feedback';
import { useDispatch } from 'react-redux'
import { stylesMap, nodeSizeMap, edgeThicknessMap } from './Styles';
import update from "immutability-helper";
import { useParams } from 'react-router-dom'

class WeatherMapEditorLegacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: {}
    };
    this.handleTopologyChanged = this.handleTopologyChanged.bind(this);
    this.saveTopology = this.saveTopology.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    WeathermapService.getWeathermap(this.props.params.mapName).then((result) => {
      if (result.status === 200) {
        this.setState({ loading: false, data: result.data });
      }
    }).catch((error) => {
      alert(error);
    });
  }

  handleTopologyChanged(topo) {
    this.setState({
      data: update(this.state.data, {
        'edges': { $set: topo.edges },
        'nodes': { $set: topo.nodes },
      })
    });
  }

  handleChange(event) {
    if(['acl_companies', 'acl_users'].includes(event.target.name)) {
      const fn = event.target.name.split('_');
      console.log(event.target.value.split(',')[0].length);
      this.setState({'data': update(this.state.data, {
        'acl': {
          [fn[1]]: {
            $set: (event.target.value.split(',')[0].length > 0 ? event.target.value.split(',') : [])
          }
        }
      })});
    }
    else {
      this.setState({data: {...this.state.data, [event.target.name]: event.target.value}});
    }
  }

  saveTopology() {
    WeathermapService.saveWeatherMap(this.props.params.mapName, JSON.stringify(this.state.data, null, 2)).then((result) => {
      if (result.status === 200) {
        this.props.dispatch(successFeedback('Saved weathermap'));
      } else {
        this.props.dispatch(errorFeedback('Save Failed'));
      }
    }).catch((error) => {
      alert(error);
    });
  }

  render() {
    const bounds = {
      x1: 0,
      y1: 0,
      x2: 225,
      y2: 100
    };

    return (
            <div className="animated fadeIn">
                {(this.state.loading || this.state.tloading)
                && <div className="row justify-content-center">
                    <SpinnerInfo/>
                </div>
                }
                {!this.state.loading && !this.state.tloading
                    && <div>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>Map Editor</CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                        <Form>
                                            <FormGroup>
                                                <Label for="mapName">Title</Label>
                                                <Input type="text" name="name" id="mapName" placeholder="Map Title" onChange={this.handleChange} value={this.state.data.name}/>
                                            </FormGroup>
                                            <FormGroup>
                                              <Label for="description">Description</Label>
                                              <Input type="textarea" name="description" id="description" onChange={this.handleChange} value={this.state.data.description}/>
                                            </FormGroup>
                                            <FormGroup>
                                              <Label for="acl_companies">Shared With Companies</Label>
                                              <Input type="text" name="acl_companies" id="acl_companies" onChange={this.handleChange} value={this.state.data.acl.companies.join(',')}/>
                                              <FormText color="muted">Comma separated list of company UUIDs to share with.</FormText>
                                            </FormGroup>
                                            <FormGroup>
                                              <Label for="acl_companies">Shared With Users</Label>
                                              <Input type="text" name="acl_users" id="acl_users" onChange={this.handleChange} value={this.state.data.acl.users.join(',')}/>
                                              <FormText color="muted">Comma separated list of usernames to share with. Must belong to one of the companies listed above.</FormText>
                                            </FormGroup>
                                        </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                    <MapEditor
                                        bounds={bounds}
                                        topology={this.state.data}
                                        autoSize={true}
                                        edgeDrawingMethod="bidirectionalArrow"
                                        edgeThicknessMap={edgeThicknessMap}
                                        nodeSizeMap={nodeSizeMap}
                                        stylesMap={stylesMap}
                                        gridSize={this.state.gridSize}
                                        onTopologyChange={this.handleTopologyChanged} />
                                    <p><Button color="primary" onClick={this.saveTopology}>Save</Button></p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    </div>
                    }
            </div>
    );
  }
}

const WeatherMapEditor = () => {
  const params = useParams()
  const dispatch = useDispatch();
  return <WeatherMapEditorLegacy dispatch={dispatch} params={params} />;
}

export default WeatherMapEditor;
