import React from 'react';

const TableCellHighlightOnHover = (
  {value, onClick}
) => {
  return (
    <span onClick={onClick} className={'td-clickable'}>{value}</span>
  )
}

export default TableCellHighlightOnHover