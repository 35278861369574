import { netApiRequest } from '../Api/ApiRequest';

export function getPrefixLists() {
  const url = '/prefixlists';
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function getPrefixListContents(list) {
  const url = '/prefixlists/' + list + '/data';
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function getPrefixListInfo(list) {
  const url = '/prefixlists/' + list;
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function savePrefixListInfo(list, data) {
  const url = '/prefixlists/' + list;
  return netApiRequest({
    url,
    method: 'put',
    data
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function addPrefix(list, data) {
  const url = '/prefixlists/' + list + '/data';
  return netApiRequest({
    url,
    method: 'post',
    data
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function deletePrefix(list, data) {
  const url = '/prefixlists/' + list + '/data';
  return netApiRequest({
    url,
    method: 'delete',
    data: data
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function deletePrefixList(list) {
  const url = '/prefixlists/' + list;
  return netApiRequest({
    url,
    method: 'delete'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function addJob(data) {
  const url = '/jobs';
  return netApiRequest({
    url,
    method: 'put',
    data
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function importPrefixList(list, router) {
  const url = '/prefixlists/' + list + '/import';
  return netApiRequest({
    url,
    method: 'post',
    data: { router: router }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}
