import {api_getShiftData, api_getTimeOffData} from '../utils/Shifts/ShiftsService';
import { errorFeedback } from './feedback';

export const SHIFTS_SET = 'SHIFTS_SET';
export const TIMEOFF_SET = 'TIMEOFF_SET';

export function getShiftData(team, days) {
  return (dispatch) => {
    return api_getShiftData(team, days).then((result) => {
      if (result.status === 200) {
        dispatch({
          type: SHIFTS_SET,
          teamid: team,
          data: result.data
        });
      } else {
        dispatch(errorFeedback('Could not fetch shift data'));
      }
    });
  };
}

export function getTimeOffData(team, days) {
  return (dispatch) => {
    return api_getTimeOffData(team, days).then((result) => {
      if (result.status === 200) {
        dispatch({
          type: TIMEOFF_SET,
          teamid: team,
          data: result.data
        });
      } else {
        dispatch(errorFeedback('Could not fetch time off data'));
      }
    });
  };
}
