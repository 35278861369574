import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, CardBody, Col, Row } from 'reactstrap';
import { ButtonIcon } from '../../../components/ButtonIcon';
import FilteredTable from '../../../components/FilteredTable';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getServices } from '../../../actions/ServiceDB/logicalServices';
import { getServiceTypeFields } from '../../../actions/ServiceDB/logicalServiceType';
import isEmpty from 'lodash.isempty';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import { canAccessServiceDb } from '../../../utils/Auth/AuthService';

const LogicalServices = ({
  logicalServiceType,
  logicalServiceTypes,
  logicalServices
}) => {

  // router
  const navigate = useNavigate();
  const { serviceType } = useParams()

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const label = useMemo(() => logicalServiceTypes?.find(service => service.slug === serviceType)?.label, [logicalServiceTypes, serviceType]);

  const includes = Object.entries(logicalServiceType.fields)
    .filter(([, field]) => !isEmpty(field.entity))
    .map(([key]) => key);

  useEffect(() => {
    setLoading(true);
    dispatch(getServiceTypeFields(serviceType));
  }, [serviceType]);

  useEffect(() => {
    if (label) {
      dispatch(addBreadcrumbs([
        { name: label }
      ]));
    }

    return () => {
      dispatch(resetBreadcrumbs());
    };

  }, [label]);

  useEffect(() => {
    if (!isEmpty(logicalServiceType.fields)) {
      fetch();
    }
  }, [logicalServiceType.fields]);

  const fetch = () => {
    setLoading(true);
    dispatch(getServices(serviceType, includes))
      .then(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      filterable: true,
      sortable: true,
      Header: 'ID',
      accessor: 'id',
      width: 100
    },
    ...Object.entries(logicalServiceType.fields).map(([key, field]) => {
      const column = {
        filterable: true,
        sortable: true,
        Header: field.label
      };
      if (field.type === 'select-async-multi') {
        column.id = key;
        column.accessor = (row) => row[key]?.map(entity => entity.name).join(', ');
      } else {
        column.accessor = (!isEmpty(field.entity) ? `${field.entity}.name` : key);
      }
      return column;
    }),
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 50,
      Cell: props => (
        <Link to={`/sdb/services/${serviceType}/${props.value}`}><i
          className="icon-arrow-right"/></Link>
      )
    }
  ];

  return (
    <Row>
      <Col className={'d-flex'}>
        <Card className="w-100">
          <CardBody>
            <FilteredTable
              columns={columns}
              data={logicalServices}
              minRows={1}
              filterable={false}
              loading={loading}
              hideFilter={true}
              onRowClick={(row) => navigate(`/sdb/services/${serviceType}/${row.id}`)}
              extraButtons={[
                <ButtonIcon
                  icon="fa fa-refresh"
                  tooltip="Refresh"
                  onClick={() => fetch()}/>,
                canAccessServiceDb() ? (
                  <ButtonIcon
                    icon="fa fa-plus"
                    tooltip="Add New"
                    onClick={() => navigate(`/sdb/services/${serviceType}/new`)}/>
                ) : ''
              ]}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

function mapStateToProps({ logicalServiceType, logicalServices, helpers, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    logicalServiceType,
    logicalServiceTypes: helpers.serviceDb.logicalServiceTypes,
    logicalServices: logicalServices.logicalServices
  };
}

export default connect(mapStateToProps)(LogicalServices);
