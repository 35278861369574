import { Badge, Button } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import React, { useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { ButtonIcon } from '../../ButtonIcon';
import TableCellHighlightOnHover from '../../TableCellHighlightOnHover';
import { SelectMod } from '../../Selects/SelectMod';
import { useNavigate } from 'react-router-dom'


const statusMap = {
  Available: 'success',
  Reserved: 'warning',
  Deployed: 'info',
}

const statusOptions = [
  {label: 'All', value: null},
  {label: 'Available', value: 'available'},
  {label: 'Deployed', value: 'deployed'},
  {label: 'Reserved', value: 'reserved'}
]
const StockItemsTable = ({
  excludeColumns,
  includeColumns,
  stockItems,
  fetchData,
  optionSets,
  minRows,
  sortBy,
  withoutNew,
  withoutFetch,
  onDetach,
  canEdit,
  extraButtons,
  deviceModel
}) => {

  const navigate = useNavigate();

  const [statusFilter, setStatusFilter] = useState({
    value: null,
    label: 'All'
  });
  const onStatusFilterChange = (selected, tableProps) => {
    tableProps.column.setFilterValue(selected.value)
    setStatusFilter(selected)
  };

  const getStatusOptions = () => {
    return statusOptions.concat(optionSets.disposalStatus.options)
  }

  const getStatusLabel = (stockItem) => {
    if(stockItem.disposalStatus){
      return optionSets.disposalStatus.options.find(option => option.value === stockItem.disposalStatus)?.label ?? ''
    }
    return stockItem.deployed ? 'Deployed' : isEmpty(stockItem.reservedFor) ? 'Available' : 'Reserved'
  }

  const getStatusClass = (statusLabel) => {
    return statusMap[statusLabel] ?? 'danger'
  }

  const columns = [
    {
      filterable: true,
      header: 'Asset Tag',
      accessorKey: 'assetTag'
    },
    {
      filterable: true,
      header: 'Serial',
      accessorKey: 'serialNumber'
    },
    {
      filterable: true,
      header: 'Device Model',
      id: 'deviceModel',
      accessorFn: row => {
        return `${row.deviceModel?.vendor?.name} ${row.deviceModel?.name}`
      },
      cell: (props) => {
        return <div>
          <TableCellHighlightOnHover value={props.getValue()} onClick={(event) => {
            event.stopPropagation()
            navigate(`/sdb/device-models/${props.row.original.deviceModel?.id}`)
          }}/>
        </div>
      }
    },
    {
      filterable: true,
      enableSorting: false,
      header:  (props) => {
        return <SelectMod
          options={getStatusOptions()}
          isSearchable
          value={statusFilter}
          onChange={(selected) => onStatusFilterChange(selected, props)}
          size={'sm'}
        />
      },
      id: 'deployed',
      accessorFn: row => getStatusLabel(row),
      cell: props => {
        return <Badge className={`p-2 faded-${getStatusClass(props.getValue())}`}
                      style={{ minWidth: '70px' }}>
          {props.getValue()}
        </Badge>
      },
      filterFn: (row, columnId, value) => {
        if(value === 'available'){
          return !row.original.deployed && isEmpty(row.original.reservedFor) && !row.original.disposalStatus
        } else if(value === 'deployed'){
          return row.original.deployed
        } else if(value === 'reserved'){
          return !isEmpty(row.original.reservedFor)
        } else if(value){
          return row.original.disposalStatus === value
        }
        else {
          return true
        }

      },
    },
    {
      filterable: true,
      header: 'Active Licenses',
      accessorKey: 'activeLicensesCount',
      width: 200,
      cell: ( props) => (
        <div className={"d-flex justify-content-between"}>
          <Badge className={`p-2 faded-${(props.getValue() > 0 ? 'success' : 'danger')}`}
                 style={{ minWidth: '40px' }}>
            {props.row.original.activeLicensesCount > 0 ? 'Yes' : 'No'}
          </Badge>
          {onDetach && canEdit &&
            <Button
              className={'hover-content'}
              size={'sm'}
              onClick={(e) => {
                e.stopPropagation();
                onDetach(props.row.original.id);
              }}
            >Detach</Button>
          }
        </div>
      )
    },
    {
      filterable: true,
      header: 'Reserved For',
      accessorKey: 'reservedFor',
      accessorFn: row => !isEmpty(row.reservedFor) ? (row.reservedFor.data.orderNumber ?? row.reservedFor.data.name) : '' ,
    },
  ];

  const getColumns = () => {
    if(excludeColumns){
      return  columns.filter(column => !excludeColumns.includes(column.accessorKey ?? column.id))
    }
    return includeColumns ? columns.filter(column => includeColumns.includes(column.accessorKey)) : columns
  }

  const getButtons = () => {
    const buttons = []
    if(!withoutNew){
      buttons.push(  <Button size={'sm'} color={'secondary'} onClick={() => {
        navigate(`/sdb/stock/new${deviceModel ? `?deviceModel=${deviceModel.id}` : ''}`)
      }}>New Stock</Button>)
    }
    if(!withoutFetch){
      buttons.push(<ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>)
    }

    return extraButtons? buttons.concat(extraButtons) : buttons
  }

  return (
    <FilteredTableV2
      withFilter={'includesString'}
      minRows={minRows ?? 10}
      columns={getColumns()}
      data={stockItems}
      noDataText="No Stock Items"
      showPagination
      defaultPageSize={minRows ?? 20}
      defaultSorted={sortBy ?? [{
        id: 'deviceModel',
        desc: false
      }]}
      onRowClick={(vendor) => {
        navigate(`/sdb/stock/${vendor.id}`)
      }}
      extraButtons={getButtons()}
    />
  )
}

export default StockItemsTable
