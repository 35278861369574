import toastr from 'toastr';
import Ring from 'ringjs';
import update from 'immutability-helper';
import { v4 } from 'uuid';



const initialState = {
  pastes: new Ring(50)
};

export default function socketioPaste(state = initialState, action) {
  switch (action.type) {
    case 'PASTE':
      const id = v4();
      const newPaste = state.pastes;
      newPaste.push(action);
      toastr.info(action.data + "<br/><span class='small' id='"+id+"'>Click to copy</span>", "New Paste", {
        closeButton: true,
        timeOut: 0,
        extendedTimeOut: 0,
        onclick: () => {
          navigator.clipboard.writeText(action.data);
          document.getElementById(id).textContent="Data Copied to Clipboard!";
          document.getElementById(id).classList.add("font-weight-bold");
        }
      });
      return update(state,
          {
            pastes: { $set: newPaste }
          });
    default:
      return state;
  }
}
