import update from 'immutability-helper';
import { UPDATE_APP_VERSION_INFO, SET_CURRENT_UI_VERSION } from '../actions/appVersion';

const initialState = {
  name: '',
  version: '',
  uiversion: '',
  mode: ''
};

export default function appVersion(state = initialState, action) {
  switch (action.type) {
    case UPDATE_APP_VERSION_INFO:
      return update(state, { $set: action.data });
    case SET_CURRENT_UI_VERSION:
      return update(state, { uiversion: { $set: action.version } });
    default:
      return state;
  }
}
