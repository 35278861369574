import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const PortService = {
  getPort: (id, include = []) => {
    return apiRequest({
      url: `/sdb/ports/${id}${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  createPort: (data, include = []) => {
    return apiRequest({
      url: `/sdb/ports${queryStringFromFields({ include })}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  updatePort: (id, data, include = []) => {
    return apiRequest({
      url: `/sdb/ports/${id}${queryStringFromFields({ include })}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  deletePort: (id) => {
    return apiRequest({
      url: `/sdb/ports/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
};