import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { CardTitle } from 'reactstrap'
import Header from '../../components/Header/'
import Sidebar from '../../components/Sidebar/'
import Breadcrumb from '../../components/Breadcrumb/'
import Aside from '../../components/Aside/'
import Footer from '../../components/Footer/'
import {
  getAPIToken,
  getAuthStatus, isFieldOps, isLoggedIn, isNoc
} from '../../utils/Auth/AuthService'
import Confirmation from '../../components/Modals/Confirmation'
import Loadable from 'react-loading-overlay'
import { authenticateSocket, addListeners, joinRooms, ping } from '../../actions/socketio'
import {
  AUTH_SET_DETAILS
} from '../../actions/authentication'
import HsoBar from '../../components/HsoBar/HsoBar'
import moment from 'moment'
import { initBGP } from '../../actions/BGP'
import HeaderAlerts from '../../components/HeaderAlerts/HeaderAlerts'
import { getSignaturesToSignCount } from '../../actions/signatures'
import Dialogue from '../../components/Modals/Dialogue'
import LogTailer from '../../components/LogTailer/LogTailer'
import { getShiftData, getTimeOffData } from '../../actions/shifts'
import { apm } from '../../index'
import {
  getCaseSubjects,
  getDefaultPricingToolProducts,
  getDocumentOptionSets,
  getModelOptionSets,
  getPricingToolHelpers,
  getServiceDbL2tpOptions,
  getServiceDbLogicalServiceTypes,
  getServiceDbNniOptions,
  getSuppliers,
  getSystemUsers
} from '../../actions/helpers'
import { getAppVersionData } from '../../actions/appVersion'
import { Environment } from '../../utils/Helpers/Environment'
import { getAllPriceLists } from '../../actions/priceLists'
import { getProductSLAs } from '../../actions/productSLAs'
import { setAppInitialised } from '../../actions/app'
import { getVendors } from '../../actions/vendors'
import { getDeviceModels } from '../../actions/deviceModels'
import { PriceListEnums } from '../../utils/Constants/PriceList';

CardTitle.defaultProps.tag = 'h5'

class Full extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      data: {},
      loaded: false,
      timer: null,
    }
    this.periodicTasks = this.periodicTasks.bind(this)
  }

  periodicTasks () {
    try {
      if (isLoggedIn() && (isNoc(this.props.auth.account.permissions) || isFieldOps(this.props.auth.account.permissions))) {
        this.props.dispatch({ type: 'DO_FETCH_ALARMS' })
      }

    } catch (e) {
      console.error(e)
    }
  }

  getAuthDetails () {
    getAuthStatus().then((result) => {
      this.props.dispatch(addListeners(['ADMINACTION']))
      getAPIToken().then(token => this.props.dispatch(authenticateSocket({ jwt: token })))
      this.props.dispatch(getSignaturesToSignCount())
      this.props.dispatch(joinRooms({ rooms: result.permissions?.filter(x => x !== null) }))
      this.props.dispatch({
        type: AUTH_SET_DETAILS,
        result
      })
      this.setState({
        loaded: true,
        data: result
      })
      if (result.status !== 401 && result.email) {
        apm.setUserContext({
          username: result.username,
          email: result.email.toLowerCase()
        })
      }

    })
  }

  componentDidMount () {
    document.title = !Environment.isProduction() ? 'hSo:Admin (' + process.env.REACT_APP_ENV + ')' : 'hSo:Admin'
    this.getAuthDetails()
    const promises = []
    promises.push(this.props.dispatch(getModelOptionSets()).then(() => this.props.dispatch(getDocumentOptionSets())))
    promises.push(this.props.dispatch(getSuppliers()))
    promises.push(this.props.dispatch(getPricingToolHelpers()))
    promises.push(this.props.dispatch(getAllPriceLists()).then((result) => {
      if (result) {
        const currentPriceList = result.find(list => list.type.id === PriceListEnums.type.ENTERPRISE && list.status.id === PriceListEnums.status.ACTIVE)
        this.props.dispatch(getDefaultPricingToolProducts(currentPriceList.id))
      }
    }))
    promises.push(this.props.dispatch(getProductSLAs()))
    promises.push(this.props.dispatch(getSystemUsers()))
    promises.push(this.props.dispatch(getCaseSubjects()))
    promises.push(this.props.dispatch(getServiceDbLogicalServiceTypes()))
    promises.push(this.props.dispatch(getServiceDbL2tpOptions()))
    promises.push(this.props.dispatch(getServiceDbNniOptions()))
    promises.push(this.props.dispatch(getVendors()))
    promises.push(this.props.dispatch(getDeviceModels()))
    promises.push(this.props.dispatch(getAppVersionData()))
    Promise.all(promises).then(() => {
      this.props.dispatch(setAppInitialised())
    })
    this.props.dispatch(getShiftData('dca01ca2-8dd7-4391-a2c6-fd3bce952024', 30))
    this.props.dispatch(getTimeOffData('dca01ca2-8dd7-4391-a2c6-fd3bce952024', 30))
    this.props.dispatch(getShiftData('c2a0b36b-26e9-4104-a471-1c1a9c9c6951', 30))
    this.props.dispatch(getTimeOffData('c2a0b36b-26e9-4104-a471-1c1a9c9c6951', 30))

  }

  componentDidUpdate () {
    if (this.state.loaded) {
      if (isLoggedIn() && (isNoc(this.props.auth.account.permissions) || isFieldOps(this.props.auth.account.permissions))) {
        this.props.dispatch({ type: 'DO_FETCH_ALARMS' })

        if (this.props.lastBGPUpdate === null || moment.duration(moment(new Date())
          .diff(moment(this.props.lastBGPUpdate)))
          .asSeconds() >= 300) {
          this.props.dispatch(initBGP())
        }
      }
      if (this.state.timer == null) {
        let timer = setInterval(this.periodicTasks, 30000)
        this.setState({ timer })
      }
    }
  }

  componentWillUnmount () {
    clearInterval(this.state.timer)
  }

  render () {
    if (this.state.loaded === false) {
      return (
        <div className="app flex-row align-items-center animated fadeIn">
          <div className="container">
            <div className="row justify-content-center">
              <Loadable active={!this.state.loaded} spinner color={'rgb(62,81,91)'} spinnerSize={'50px'}/>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="app">
        <Header/>
        <div className="app-body">
          <Sidebar {...this.props}/>
          <main className="main">
            <HsoBar/>
            <HeaderAlerts/>
            <Breadcrumb/>
            <div className="container-fluid">
              <Outlet/>
            </div>
          </main>
          <Aside/>
        </div>
        <Footer/>
        <Confirmation/>
        <Dialogue/>
        <LogTailer/>
      </div>
    )
  }
}

function mapStateToProps ({
  app,
  dialogs,
  authenticationState,
  BGP,
  helpers,
  socketioStatus
}) {
  return {
    app,
    confirm: dialogs.confirm,
    auth: authenticationState,
    inactive: authenticationState.inactive,
    lastBGPUpdate: BGP.lastUpdate,
    helpers,
    socketioStatus
  }
}

export default connect(mapStateToProps)(Full)
