import { defaultErrorFeedback } from './feedback';
import { PortService } from '../utils/ServiceDB/Port';
import AuditService from '../utils/Audit';

export const PORT_SET_DATA = 'PORT_SET_DATA';
export const PORT_SET_FIELD = 'PORT_SET_FIELD';
export const PORT_RESET_DATA = 'PORT_RESET_DATA';
export const PORT_SET_AUDIT_HISTORY = 'PORT_SET_AUDIT_HISTORY';

export const PORT_ADD_NOTE = 'PORT_ADD_NOTE';
export const PORT_UPDATE_NOTE = 'PORT_UPDATE_NOTE';
export const PORT_REMOVE_NOTE = 'PORT_REMOVE_NOTE';


export const setPortData = (data) => ({
  type: PORT_SET_DATA,
  data
});
export const setPortField = (field, value) => ({
  type: PORT_SET_FIELD,
  field,
  value
});

export const resetPortData = () => ({
  type: PORT_RESET_DATA
});

export function addPortNote(note) {
  return {
    type: PORT_ADD_NOTE,
    note
  };
}

export function updatePortNote(note) {
  return {
    type: PORT_UPDATE_NOTE,
    note
  };
}

export function removePortNote(note) {
  return {
    type: PORT_REMOVE_NOTE,
    note
  };
}

export const getPort = (id, include = []) => (dispatch) => {
  return PortService.getPort(id, include).then((result) => {
    if (result.status === 200) {
      dispatch(setPortData(result.data));
      return result.data;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });
};

export const updatePort = (id, data, include = []) => (dispatch) => {
  return PortService.updatePort(id, data, include).then((result) => {
    if (result.status === 200) {
      dispatch(setPortData(result.data));
      return result.data
    } else if(result.status === 422){
      return {errors: result.data}
    } else {
      dispatch(defaultErrorFeedback());
    }
    return result;
  });
};

export const createPort = (data, include = []) => (dispatch) => {
  return PortService.createPort(data, include).then((result) => {
    if (result.status === 200) {
      dispatch(setPortData(result.data));
      return result.data
    } else if(result.status === 422){
      return {errors: result.data}
    }else {
      dispatch(defaultErrorFeedback());
    }
  });
};

export const deletePort = (id) => (dispatch) => {
  return PortService.deletePort(id).then((result) => {
    if (result.status === 204) {
      return true;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });
};


export function getPortAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('sdb/ports', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: PORT_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}