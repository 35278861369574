
const CrossConnectForm = (handleClick) =>  {
  return {
      id: {
        label: 'ID',
        type: 'text',
        plaintext: true,
        width: 12,
        show: (field, data) => data.id
      },
      name: {
        label: 'Name',
        type: 'text',
        mandatory: true,
        width: 12
      },
      type: {
        label: 'Type',
        type: 'select',
        mandatory: true,
        width: 12
      },
      presentationType: {
        label: 'Presentation Type',
        type: 'select',
        mandatory: true,
        width: 12
      },
      supplier: {
        label: 'Supplier',
        type: 'select',
        mandatory: true
      },
      reference: {
        label: 'Reference',
        type: 'text',
        mandatory: true,
      },
      aEnd: {
        label: 'A End',
        type: 'select-async',
        placeholder: 'Search racks...',
        entity: 'rack',
        handleClick: () => handleClick('aEnd'),
      },
      bEnd: {
        label: 'B End',
        type: 'select-async',
        entity: 'rack',
        placeholder: 'Search racks...',
        handleClick: () => {
          handleClick('bEnd')
        },
      },
    }
};

export default CrossConnectForm;