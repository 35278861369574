import {
  PRICING_TYPES_ADD_TYPE_TO_LIST, PRICING_TYPES_REPLACE_TYPE_IN_LIST,
  PRICING_TYPES_SET_LIST,
  PRICING_TYPES_SET_SEARCH_STRING, PRICING_TYPES_SET_SELECTED_TYPE,
  PRICING_TYPES_SET_VALIDATION_ERRORS, PRICING_TYPES_UPDATE_SELECTED_TYPE, PRICING_TYPES_UPDATE_SELECTED_TYPE_DATA
} from '../actions/pricingTypes';
import cloneDeep from 'lodash.clonedeep';

const initialState = {
  searchString: '',
  list: [],
  filteredList: [],
  selected: {},
  errors: []
};

export function pricingTypes(state = initialState, action) {

  switch (action.type) {
    case PRICING_TYPES_SET_LIST:
      return {
        ...state,
        list: action.list
      }
    case PRICING_TYPES_SET_SEARCH_STRING:
      const searchString = action.search !== undefined ? action.search : state.searchString;
      const filteredList = state.list.filter((pricingType) => {
        return (String(pricingType.name)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
        );
      });

      return {
        ...state,
        searchString,
        filteredList
      };
    case PRICING_TYPES_SET_VALIDATION_ERRORS:
      return {
        ...state,
        errors: action.errors
      }
    case PRICING_TYPES_ADD_TYPE_TO_LIST:
      return {
        ...state,
        list: [...state.list, action.pricingType]
      };
    case PRICING_TYPES_SET_SELECTED_TYPE:
      return {
        ...state,
        selected: cloneDeep(action.selected),
        selectedOriginal: cloneDeep(action.selected),
      };
    case PRICING_TYPES_UPDATE_SELECTED_TYPE:
      return {
        ...state,
        selected: {...state.selected, ...action.field}
      }
    case PRICING_TYPES_UPDATE_SELECTED_TYPE_DATA:
      return {
        ...state,
        selected: {...state.selected, data: {...state.selected.data, ...action.field}}
      }
    case PRICING_TYPES_REPLACE_TYPE_IN_LIST:
      return {
        ...state,
        list: state.list.map(item => {
          if(item.id === action.pricingType.id){
            return action.pricingType
          }
          return item
        })
      }
    default:
      return state
  }
}
