import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

const AuditService = {
  get: (entityType, id) => {
    return apiRequest({
      url: `/${entityType}/${id}/audits`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}

export default AuditService