import React, { useEffect, useState } from 'react';
import {getShiftCheckinReport} from "../../utils/ShiftAPI";
import {Badge, Card, CardBody, Col, Row, Spinner} from "reactstrap";
import FilteredTableV2 from '../../components/FilteredTableV2';
import moment from "moment";
import ReactTooltip from 'react-tooltip';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import LoadingOverlay from '../../components/LoadingOverlay';


const ShiftTracking = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [mapMarker, setMapMarker] = useState(undefined);
    const [pagination, setPagination] = useState({pageIndex : 0, pageSize: 10});
    const [zoom, setZoom] = useState(10);
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState({
        lat: 51.513434792220444,
        lng: -0.07071461883896776
    });

    const { isLoaded } = useJsApiLoader({
        id: 'shift-track-gmaps',
        googleMapsApiKey: "AIzaSyAHDmF6XVixd7Pw3SCJgOgjbxgA3RHtSDs"
    })

    const containerStyle = {
        width: '100%',
        height: '600px'
    };

    const fetch = (
        pageFetch = page,
        pageSizeFetch = pageSize
    ) => {
        setLoading(true);
        if(pageFetch != 0) {
            pageFetch = pageFetch - 1;
        }
        getShiftCheckinReport(pageFetch, pageSizeFetch).then((d) => {
            setLoading(false);
            setData(d.data);
        });
    };

    useEffect(() => {
        fetch(0, 10);
    }, []);


    const columns = [{
        id: 'user',
        filterable: false,
        sortable: false,
        header: 'User',
        accessorKey: 'username',
        cell: (props) => {
            let color = "dark";
            if(moment(props.row?.original.createdAt).hour() >= 7 && moment(props.row?.original.createdAt).hour() <= 18) {
                color = "warning";
            }
            return(<>{props.row?.original.username}<Badge color={color}>{props.row?.original.createdAt}</Badge></>)
        }
    }, {
        id: 'status',
        filterable: false,
        sortable: false,
        header: 'Status',
        accessorKey: 'status',
        cell: (props) => {
            if (props.row?.original.status === 'complete') {
                return (<Badge color="success">{props.row?.original.status}</Badge>)
            }
            else if(props.row?.original.status === 'failed') {
                return (<Badge color="danger">{props.row?.original.status}</Badge>)
            }
            else {
                return (<Badge color="warning">{props.row?.original.status}</Badge>)
            }
        }
    },
        {
            id: 'flags',
            filterable: false,
            sortable: false,
            header: 'Flags',
            accessorKey: 'chasedAt',
            cell: (props) => {
                let flags = [];
                if(props.row?.original.chasedAt) {
                    flags.push(<>
                        <Badge color="warning" data-tip={"c_"+props.row?.original.id} data-for={"c_"+props.row?.original.id}>CHASED</Badge>
                        <ReactTooltip id={"c_"+props.row?.original.id} type="dark"
                                      place="left"
                                      effect="float">{props.row?.original.chasedAt}</ReactTooltip>
                    </>)
                }
                if(props.row?.original.escalatedAt) {
                    flags.push(<>
                        <Badge color="danger" data-tip={"e_"+props.row?.original.id} data-for={"e_"+props.row?.original.id}>ESCALATED</Badge>
                        <ReactTooltip id={"e_"+props.row?.original.id} type="dark"
                                      place="left"
                                      effect="float">{props.row?.original.escalatedAt}</ReactTooltip>
                    </>)
                }
                return flags;
            }
        },
        {
            id: 'responded',
            filterable: false,
            sortable: false,
            header: 'Responded',
            accessorKey: 'respondedAt',
            cell: (props) => {
                if(!props.row?.original.respondedAt) {
                    return (<span></span>)
                }
                const start = moment(props.row?.original.createdAt);
                const end = moment(props.row?.original.respondedAt);
                return (
                    <span>{moment.duration(end.diff(start)).humanize()}</span>
                )
            }
        }
    ];

    if(!data) {
        return (<Spinner/>);
    }
    return (
        <Row>
            <Col className={'d-flex'}>
                <Card className="w-100">
                    <CardBody>
                        <LoadingOverlay loading={loading}>
                        <FilteredTableV2
                            noDataText="No Checkins found."
                            data={data.records}
                            pages={data.totalPages}
                            columns={columns}
                            onFetchData={(page, size) => {
                                fetch(page, size);
                            }}
                            minRows={4}
                            filterable={false}
                            pageSize={10}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                            defaultSorted={[{ id: 'createdAt', desc: true }]}
                            showPagination={data.totalPages > 1}
                            onRowClick={(d) => {
                                if(d.location) {
                                    setMapMarker({lat: d.location.coords.latitude, lng: d.location.coords.longitude});
                                }
                            }}
                            pagination={pagination}
                            onPaginationChange={setPagination}
                        />
                        </LoadingOverlay>
                    </CardBody>
                </Card>
            </Col>
            <Col>
                {isLoaded &&
                    <GoogleMap
                        onLoad={map => {
                            setMap(map)
                        }}
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        onCenterChanged={() => {
                            if(map) {
                                setCenter(map.getCenter());
                            }
                        }}
                        onZoomChanged={() => {
                            if(map) {
                                setZoom(map.getZoom());
                            }
                        }}
                    >
                        {mapMarker &&
                        <Marker position={mapMarker}/>
                        }
                        <></>
                    </GoogleMap>}
            </Col>
        </Row>
    );
}

export default ShiftTracking;
