import {apiRequest} from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';
import isEmpty from 'lodash.isempty';

export const DeviceDeploymentService = {
    getDeviceDeployments: (page, pageSize, search, sorting, include, withs, searchFields, searchJoin) => {
        let query = {
            search,
            searchFields,
            searchJoin,
            include,
            with: withs,
            sortedBy: !isEmpty(sorting) ? (sorting[0].desc ? 'desc' : 'asc') : null,
            orderBy: !isEmpty(sorting) ? sorting[0].id : null,
            page,
            pageSize
        }
        if(isEmpty(search)){

            delete query.search
        }
        return apiRequest({
            url: `/sdb/device-deployments${queryStringFromFields(query)}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    getDeviceDeployment: (id, include, withs) => {
        return apiRequest({
            url: `/sdb/device-deployments/${id}${queryStringFromFields({ include, with: withs })}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    createDeviceDeployment: (data, include = []) => {
        return apiRequest({
            url: `/sdb/device-deployments${queryStringFromFields({ include })}`,
            method: 'post',
            data
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    updateDeviceDeployment: (id, data, include = []) => {
        return apiRequest({
            url: `/sdb/device-deployments/${id}${queryStringFromFields({ include })}`,
            method: 'put',
            data
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    deleteDeviceDeployment: (id) => {
        return apiRequest({
            url: `/sdb/device-deployments/${id}`,
            method: 'delete'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    syncZabbixInterfaces: (id) => {
        return apiRequest({
            url: '/sdb/device-deployments/' + id + '/importInterfaces',
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    getZabbixData: (hostnames) => {
        let query = {
            search: `hostname:${hostnames.join(',')}`,
            searchFields: 'hostname:in',
            include: 'items.zabbix'
        };
        return apiRequest({
            url: `/sdb/device-deployments${queryStringFromFields(query)}`,
            method: 'get'
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    }

};
