import { defaultErrorFeedback, successFeedback } from './feedback';
import { api_fetchWIPReport, api_updateOrderBatch } from '../utils/ServiceDelivery/WIP';
import { api_updateLineItemBatch } from '../utils/LineItem/LineItemService';
import { api_fetchProjectManagers } from '../utils/ServiceDelivery/Users';
import { api_updateProductBatch } from '../utils/Products/ProductService';

export const WIP_REPORT_UPDATE_ITEM = 'WIP_REPORT_UPDATE_ITEM';
export const WIP_REPORT_SET_ITEMS = 'WIP_REPORT_SET_ITEMS';
export const WIP_REPORT_SET_USERS = 'WIP_REPORT_SET_USERS';
export const WIP_REPORT_ADD_TO_BATCH = 'WIP_REPORT_ADD_TO_BATCH';
export const WIP_REPORT_REMOVE_FROM_BATCH = 'WIP_REPORT_REMOVE_FROM_BATCH';
export const WIP_REPORT_RESET_BATCH = 'WIP_REPORT_RESET_BATCH';
export const WIP_REPORT_UPDATE_TCV_CHART = 'WIP_REPORT_UPDATE_TCV_CHART';
export const WIP_REPORT_UPDATE_LINE_ITEM_CHART = 'WIP_REPORT_UPDATE_LINE_ITEM_CHART';
export const WIP_REPORT_ADD_BATCH_PARAMETER = 'WIP_REPORT_ADD_BATCH_PARAMETER';
export const WIP_REPORT_REMOVE_BATCH_PARAMETER = 'WIP_REPORT_REMOVE_BATCH_PARAMETER';
export const WIP_REPORT_RESET_BATCH_PARAMETERS = 'WIP_REPORT_RESET_BATCH_PARAMETERS';
export const WIP_REPORT_UPDATE_BATCH_PARAMETER = 'WIP_REPORT_UPDATE_BATCH_PARAMETER';
export const WIP_REPORT_TOGGLE_BATCH_UPDATE_IN_PROGRESS = 'WIP_REPORT_TOGGLE_BATCH_UPDATE_IN_PROGRESS';

function setWIPReportItems(data) {
  return {
    type: WIP_REPORT_SET_ITEMS,
    data
  };
}

export function updateWIPReportItem(id, args) {
  return {
    type: WIP_REPORT_UPDATE_ITEM,
    id,
    args
  };
}

function setProjectManagers(data) {
  return {
    type: WIP_REPORT_SET_USERS,
    users: data
  };
}

export function addItemToBatch(item) {
  return {
    type: WIP_REPORT_ADD_TO_BATCH,
    item
  };
}

export function removeItemFromBatch(item) {
  return {
    type: WIP_REPORT_REMOVE_FROM_BATCH,
    item
  };
}

export function resetLineItemBatch() {
  return {
    type: WIP_REPORT_RESET_BATCH
  };
}

export function updateTCVChart(data) {
  return {
    type: WIP_REPORT_UPDATE_TCV_CHART,
    data
  };
}

export function updateLineItemChart(data) {
  return {
    type: WIP_REPORT_UPDATE_LINE_ITEM_CHART,
    data
  };
}

export function addBatchParameter(entity, parameter) {
  return {
    type: WIP_REPORT_ADD_BATCH_PARAMETER,
    entity,
    parameter
  };
}

export function removeBatchParameter(entity, parameter) {
  return {
    type: WIP_REPORT_REMOVE_BATCH_PARAMETER,
    parameter,
    entity
  };
}

export function resetBatchParameters(entity, parameter) {
  return {
    type: WIP_REPORT_RESET_BATCH_PARAMETERS
  };
}

export function updateBatchParameter(entity, parameter, value) {
  return {
    type: WIP_REPORT_UPDATE_BATCH_PARAMETER,
    entity,
    parameter,
    value
  };
}

export function toggleBatchUpdateInProgress() {
  return {
    type: WIP_REPORT_TOGGLE_BATCH_UPDATE_IN_PROGRESS
  };
}

export function getWipReport() {
  return (dispatch) => {
    return api_fetchWIPReport().then((result) => {
      if (result.status === 200) {
        dispatch(setWIPReportItems(result.data));
        return result.data;
      }

      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function getProjectManagers() {
  return (dispatch) => {
    return api_fetchProjectManagers().then((result) => {
      if (result.status === 200) {
        dispatch(setProjectManagers(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function updateProductBatch(args) {
  return (dispatch) => {
    return api_updateProductBatch(args).then((result) => {
      if (result.status === 422) {
        return {errors: result.data};
      }
      else if (result.status === 204) {
        dispatch(successFeedback('Product batch updated!'));
      }
      else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function updateOrderBatch(args) {
  return (dispatch) => {
    return api_updateOrderBatch(args).then((result) => {
      if (result.status === 422) {
        return {errors: result.data};
      }
      else if (result.status === 204) {
        dispatch(successFeedback('Order batch updated!'));
      }else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}
