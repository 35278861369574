import { Component } from 'react';
import { connect } from 'react-redux';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { FaPlug } from 'react-icons/fa';
import { Environment } from '../../utils/Helpers/Environment';

class Footer extends Component {
  render() {
    let color = '';
    let statusText = 'Unknown - Houston, we have a problem!';
    if (this.props.socketStatus === 'authenticated') {
      color = 'text-success';
      statusText = 'Authenticated - all good to go!';
    }
    if (this.props.socketStatus === 'disconnected') {

      color = 'text-danger';
      statusText = 'Disconnected! Danger Will Robinson!';
    }
    return (
          <footer className={`${!Environment.isProduction() ? 'bg-success' : ''} app-footer`} >
              <span style={{ flexGrow: 2 }}> &copy; 2023 <a href="https://www.hso.co.uk">HighSpeed Office Limited</a></span>
              <span style={{ flexGrow: 1, textAlign: 'right' }}>API Build: {this.props.appVersion.version} {this.props.appVersion.mode !== 'production' && <Badge color="danger">{this.props.appVersion.mode}</Badge>}</span>
              <span style={{ flexGrow: 1, textAlign: 'right' }}>Portal Build: {process.env.REACT_APP_GIT_SHA}</span>
              {process.env.NODE_ENV !== 'production' && <span style={{ flexGrow: 1, textAlign: 'right' }}>Portal Mode: <Badge color="danger">{process.env.NODE_ENV}</Badge></span> }
              <span style={{ marginLeft: '16px', fontWeight: 'bold' }}><FaPlug className={color} id="tooltippop"/></span>
              <UncontrolledTooltip placement="top" target="tooltippop">
                  {statusText}
              </UncontrolledTooltip>
          </footer>
    );
  }
}

function mapStateToProps(state) {
  return {
    socketStatus: state.socketioStatus.status,
    appVersion: state.appVersion
  };
}

export default connect(mapStateToProps)(Footer);
