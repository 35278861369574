import { apiRequest } from '../Api/ApiRequest';

export function api_getShiftData(team, days) {
  return apiRequest({
    url: '/shifts/schedule/' + team + '/' + days,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}


export function api_getTimeOffData(team, days) {
  return apiRequest({
    url: '/shifts/timeoff/' + team + '/' + days,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
