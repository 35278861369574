import { defaultErrorFeedback, errorFeedback, successFeedback } from './feedback';
import {
  api_convertQuoteToOrder,
  api_createAccountQuote,
  api_getQuote,
  api_updatePreferredQuote,
  api_updateQuote
} from '../utils/Quotes';
import { api_getAccountContacts, api_getAccountSites } from '../utils/Account/AccountServiceDeprecated';
import { api_getCompareV2Quote } from '../utils/PricingTool';
import {store} from '../index'
import AuditService from "../utils/Audit";

export const QUOTE_SET_DATA = 'QUOTE_SET_DATA';
export const QUOTE_UPDATE_FIELD = 'QUOTE_UPDATE_FIELD';
export const QUOTE_UPDATE_QUOTE_PRODUCT_FIELD = 'QUOTE_UPDATE_QUOTE_PRODUCT_FIELD';
export const QUOTE_RESET = 'QUOTE_RESET';
export const QUOTE_RESET_ORDER_FORM = 'QUOTE_RESET_ORDER_FORM';
export const QUOTE_CLONE = 'QUOTE_CLONE';
export const QUOTE_ADD_PRODUCT = 'QUOTE_ADD_PRODUCT';
export const QUOTE_ADD_COMPARE_SERVICES = 'QUOTE_ADD_COMPARE_SERVICES';
export const QUOTE_ADD_COMPARE_V2_SERVICES = 'QUOTE_ADD_COMPARE_V2_SERVICES';
export const QUOTE_ADD_COMPARE_V2_SITES = 'QUOTE_ADD_COMPARE_V2_SITES';
export const QUOTE_REMOVE_PRODUCT = 'QUOTE_REMOVE_PRODUCT';
export const QUOTE_CLONE_PRODUCT = 'QUOTE_CLONE_PRODUCT';
export const QUOTE_SET_SITES = 'QUOTE_SET_SITES';
export const QUOTE_UPDATE_PRODUCT_FIELD = 'QUOTE_UPDATE_PRODUCT_FIELD';
export const QUOTE_TRACK_PRODUCT_FIELD_CHANGE = 'QUOTE_TRACK_PRODUCT_FIELD_CHANGE';
export const QUOTE_RESET_TRACK_PRODUCT_FIELD_CHANGE = 'QUOTE_RESET_TRACK_PRODUCT_FIELD_CHANGE';
export const QUOTE_UPDATE_ORDER_FORM_NETWORK_SERVICE_ORDER = 'QUOTE_UPDATE_ORDER_FORM_NETWORK_SERVICE_ORDER';
export const QUOTE_UPDATE_ORDER_FORM_FIELD = 'QUOTE_UPDATE_ORDER_FORM_FIELD';
export const QUOTE_UPDATE_ORDER_FORM_OTHER_SERVICE_ORDER = 'QUOTE_UPDATE_ORDER_FORM_OTHER_SERVICE_ORDER';
export const QUOTE_TOGGLE_ORDER_FORM_LOADING = 'QUOTE_TOGGLE_ORDER_FORM_LOADING';
export const QUOTE_TOGGLE_CANCELLATION_FORM_LOADING = 'QUOTE_TOGGLE_CANCELLATION_FORM_LOADING';
export const QUOTE_ADD_ORDER_FORM_SERVICE_LOCATION = 'QUOTE_ADD_ORDER_FORM_SERVICE_LOCATION';
export const QUOTE_RESET_ORDER_FORM_SERVICE_LOCATIONS = 'QUOTE_RESET_ORDER_FORM_SERVICE_LOCATIONS';
export const QUOTE_REMOVE_ORDER_FORM_SERVICE_LOCATION = 'QUOTE_REMOVE_ORDER_FORM_SERVICE_LOCATION';
export const QUOTE_UPDATE_ORDER_FORM_SERVICE_LOCATION_FIELD = 'QUOTE_UPDATE_ORDER_FORM_SERVICE_LOCATION_FIELD';
export const QUOTE_SET_LOADER = 'QUOTE_SET_LOADER';
export const QUOTE_SET_PRICING_TOOL_PRODUCTS = 'QUOTE_SET_PRICING_TOOL_PRODUCTS';
export const QUOTE_RESET_PRODUCT_LINKED_PRODUCTS = 'QUOTE_RESET_PRODUCT_LINKED_PRODUCTS';
export const QUOTE_ADD_NOTE = 'QUOTE_ADD_NOTE';
export const QUOTE_UPDATE_NOTE = 'QUOTE_UPDATE_NOTE';
export const QUOTE_REMOVE_NOTE = 'QUOTE_REMOVE_NOTE';
export const QUOTE_SET_AUDIT_HISTORY = 'QUOTE_SET_AUDIT_HISTORY';


export function setQuoteData(data) {
  return {
    type: QUOTE_SET_DATA,
    data
  };
}

function setSites(sites) {
  return {
    type: QUOTE_SET_SITES,
    sites
  };
}

export function updateQuoteField(field) {
  return {
    type: QUOTE_UPDATE_FIELD,
    field
  };
}

export function updateQuoteServiceProductField(id, field) {
  return {
    type: QUOTE_UPDATE_PRODUCT_FIELD,
    id,
    field
  };
}

export function updateQuoteOrderFormField(field) {
  return {
    type: QUOTE_UPDATE_ORDER_FORM_FIELD,
    field
  };
}

export function updateQuoteOrderFormNetworkServiceOrder(from, to) {
  return {
    type: QUOTE_UPDATE_ORDER_FORM_NETWORK_SERVICE_ORDER,
    from,
    to
  };
}

export function updateQuoteOrderFormOtherServiceOrder(from, to) {
  return {
    type: QUOTE_UPDATE_ORDER_FORM_OTHER_SERVICE_ORDER,
    from,
    to
  };
}

export function addQuoteOrderFormServiceLocation(location) {
  return {
    type: QUOTE_ADD_ORDER_FORM_SERVICE_LOCATION,
    location
  };
}

export function resetQuoteOrderFormServiceLocations() {
  return {
    type: QUOTE_RESET_ORDER_FORM_SERVICE_LOCATIONS
  };
}

export function resetQuoteOrderForm() {
  return {
    type: QUOTE_RESET_ORDER_FORM
  };
}

export function removeQuoteOrderFormServiceLocation(id) {
  return {
    type: QUOTE_REMOVE_ORDER_FORM_SERVICE_LOCATION,
    id
  };
}

export function updateQuoteOrderFormLocationField(siteId, field) {
  return {
    type: QUOTE_UPDATE_ORDER_FORM_SERVICE_LOCATION_FIELD,
    siteId,
    field
  };
}

export function updateQuoteProductField(id, field) {
  return {
    type: QUOTE_UPDATE_QUOTE_PRODUCT_FIELD,
    id,
    field
  };
}

export function addQuoteProduct(product) {
  return {
    type: QUOTE_ADD_PRODUCT,
    product
  };
}

export function addCompareV2QuoteServices(quote) {
  const products = store.getState().helpers.pricingTool.products
  return {
    type: QUOTE_ADD_COMPARE_V2_SERVICES,
    quote,
    products
  };
}

export function addCompareV2QuoteSites(quote) {
  return {
    type: QUOTE_ADD_COMPARE_V2_SITES,
    quote
  };
}

export function removeQuoteProduct(index) {
  return {
    type: QUOTE_REMOVE_PRODUCT,
    index
  };
}

export function cloneQuoteProduct(index) {
  return {
    type: QUOTE_CLONE_PRODUCT,
    index
  };
}

export function resetQuote() {
  return {
    type: QUOTE_RESET
  };
}

export function cloneQuote() {
  return {
    type: QUOTE_CLONE
  };
}

export function setQuoteLoader(load) {
  return {
    type: QUOTE_SET_LOADER,
    load
  };
}

export function toggleQuoteOrderFormLoading() {
  return {
    type: QUOTE_TOGGLE_ORDER_FORM_LOADING
  };
}

export function toggleQuoteCancellationFormLoading() {
  return {
    type: QUOTE_TOGGLE_CANCELLATION_FORM_LOADING
  };
}

export function resetQuoteProductLinkedProducts(productIndex) {
  return {
    type: QUOTE_RESET_PRODUCT_LINKED_PRODUCTS,
    productIndex
  };
}

export function addQuoteNote(note){
  return {
    type: QUOTE_ADD_NOTE,
    note
  }
}

export function updateQuoteNote(note){
  return {
    type: QUOTE_UPDATE_NOTE,
    note
  }
}

export function removeQuoteNote(note){
  return {
    type: QUOTE_REMOVE_NOTE,
    note
  }
}


export function getQuote(id) {
  return (dispatch) => {
    return api_getQuote(id, [
      'products',
      'notes',
      'notes.createdBy',
      'owner',
      'customer',
      'products.sla',
      'products.priceListItem',
      'products.linkedProducts',
    ], [
      'quoteProducts',
      'notes',
      'notes.createdBy',
      'owner',
      'company',
      'quoteProducts.sla',
      'quoteProducts.priceListItem',
      'quoteProducts.linkedOrderProducts',
    ])
      .then((result) => {
        if (result.status === 200) {
          dispatch(setQuoteData(result.data));
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };
}

export function makeQuotePreferred(userId, id) {
  return (dispatch) => {
    return api_updatePreferredQuote(userId, id)
      .then((result) => {
        if (result.status === 204) {
          return true;
        } else {
          dispatch(errorFeedback('Could not set preferred quote'));
          return false;
        }
      });
  };
}

export function updateQuote(id, data) {
  return (dispatch) => {
    return api_updateQuote(id, data, [
      'products',
      'notes',
      'notes.createdBy',
      'owner',
      'customer',
      'products.sla',
      'products.priceListItem',
      'products.linkedProducts',
    ], [
      'quoteProducts',
      'notes',
      'notes.createdBy',
      'owner',
      'company',
      'quoteProducts.sla',
      'quoteProducts.priceListItem',
      'quoteProducts.linkedOrderProducts',
    ])
      .then((result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Quote updated!'));
          dispatch(setQuoteData(result.data));
          return true;
        } else if (result.status === 422) {
          return {errors: result.data};
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };

}

export function convertQuoteToOrder(userId, id, data) {
  return (dispatch) => {
    return api_convertQuoteToOrder(userId, id, data)
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        } else if (result.status === 422) {
          return {errors: result.data};
        }else {
          dispatch(errorFeedback('There was an error with your request'));
          return false;
        }
      });
  };
}

export function addCompareV2Quote(quoteId) {
  return (dispatch) => {
    return api_getCompareV2Quote(quoteId)
      .then((result) => {
        if (result.status === 200) {
          dispatch(addCompareV2QuoteServices(result.data));
          return true;
        } else if (result.status === 404) {
          dispatch(errorFeedback('Quote not found!'));
        } else {
          dispatch(errorFeedback('There was an error with your request'));
          return false;
        }
      });
  };
}

export function cloneCompareV2Quote(quoteId) {
  return (dispatch) => {
    return api_getCompareV2Quote(quoteId)
      .then((result) => {
        if (result.status === 200) {
          dispatch(addCompareV2QuoteSites(result.data));
          return true;
        } else if (result.status === 404) {
          dispatch(errorFeedback('Quote not found!'));
        } else {
          dispatch(errorFeedback('There was an error with your request'));
          return false;
        }
      });
  };
}

export function createAccountQuote(accountId, args) {
  return (dispatch) => {
    return api_createAccountQuote(accountId, args, [
      'products',
      'notes',
      'owner',
      'customer',
      'products.sla',
      'products.priceListItem',
      'products.linkedProducts',
    ], [
      'notes',
      'owner',
      'company',
      'quoteProducts',
      'quoteProducts.sla',
      'quoteProducts.priceListItem',
      'quoteProducts.linkedOrderProducts',
    ])
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        } else if (result.status === 422) {
          return {errors: result.data};
        }else {
          dispatch(errorFeedback('There was an error with your request'));
          return false;
        }
      });
  };
}

export function getAccountContacts(accountId) {
  return (dispatch) => {
    return api_getAccountContacts(accountId)
      .then((result) => {
        if (result.status === 200) {
          return result.data
        } else {
          dispatch(errorFeedback('There was an error with your request'));
          return false
        }
      });
  };
}

export function getAccountSites(accountId) {
  return (dispatch) => {
    return api_getAccountSites(accountId)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setSites(result.data));
          return result.data
        } else {
          dispatch(errorFeedback('There was an error with your request'));
        }
      });
  };
}

export function getQuoteAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('quotes', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: QUOTE_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }
    })
  }
}






