import {
  api_activatePriceList,
  api_createPriceList, api_createPriceListItem, api_deletePriceList, api_deletePriceListItem, api_disablePriceList,
  api_getPriceList, api_lookupPriceListItem, api_updatePriceList, api_updatePriceListItem
} from '../utils/PriceLists';
import { defaultErrorFeedback } from './feedback';
import { api_searchAccounts } from '../utils/Account/AccountServiceDeprecated';
import { addPriceList, removePriceList, updatePriceListInList } from './priceLists';


export const PRICE_LIST_SET_DATA = 'PRICE_LIST_SET_DATA';
export const PRICE_LIST_RESET_DATA = 'PRICE_LIST_RESET_DATA';
export const PRICE_LIST_SET_FIELD_VALUE = 'PRICE_LIST_SET_FIELD_VALUE';
export const PRICE_LIST_SET_ORIGINAL_FIELD_VALUE = 'PRICE_LIST_SET_ORIGINAL_FIELD_VALUE';
export const PRICE_LIST_SET_SEARCH_STRING = 'PRICE_LIST_SET_SEARCH_STRING';
export const PRICE_LIST_ADD_ITEM = 'PRICE_LIST_ADD_ITEM';
export const PRICE_LIST_REMOVE_ITEM = 'PRICE_LIST_REMOVE_ITEM';
export const PRICE_LIST_REPLACE_ITEM = 'PRICE_LIST_REPLACE_ITEM';
export const PRICE_LIST_UPDATE_ITEM_FIELD = 'PRICE_LIST_UPDATE_ITEM_FIELD';
export const PRICE_LIST_CLONE = 'PRICE_LIST_CLONE';


const priceListParams = {
  includes: ['type', 'status', 'company', 'items', 'items.template', 'items.template.productGroup', 'items.pricing', 'items.sla'],
  withs: ['type', 'status', 'company','items', 'items.productTemplate', 'items.productTemplate.productGroup','items.pricingType', 'items.productSLA']
}

const priceListItemParams = {
  includes: ['template', 'pricing', 'sla', 'template.pricingType', 'template.productSLA'],
  withs: ['productTemplate', 'pricingType', 'productSLA', 'productTemplate.pricingType', 'productTemplate.sla']
}


function setPriceListData(data){
  return {
    type: PRICE_LIST_SET_DATA,
    data
  }
}

export function resetPriceList(){
  return {
    type: PRICE_LIST_RESET_DATA
  }
}

export function updatePriceListField(field, value){
  return {
    type: PRICE_LIST_SET_FIELD_VALUE,
    field: {[field] : value}
  }
}

export function updatePriceListOriginalField(field, value){
  return {
    type: PRICE_LIST_SET_ORIGINAL_FIELD_VALUE,
    field: {[field] : value}
  }
}

export function updatePriceListItemField(id, field){
  return {
    type: PRICE_LIST_UPDATE_ITEM_FIELD,
    field,
    id
  }
}


export function addPriceListItem(item) {
  return {
    type: PRICE_LIST_ADD_ITEM,
    item
  };
}

export function removePriceListItem(id, fromOriginal = false) {
  return {
    type: PRICE_LIST_REMOVE_ITEM,
    id,
    fromOriginal
  };
}

export function replacePriceListItem(item, oldItem) {
  return {
    type: PRICE_LIST_REPLACE_ITEM,
    oldItem,
    item
  };
}

export function clonePriceList() {
  return {
    type: PRICE_LIST_CLONE
  };
}

export function getPriceList(id){
  return (dispatch) => {
    return api_getPriceList(id, priceListParams).then(
      (result) => {
        if(result.status === 200){
          dispatch(setPriceListData(result.data))
        }else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}

export function searchAccounts(searchString) {
  return (dispatch) => {
    return api_searchAccounts(searchString, true).then(
      (result) => {
        if (result.status === 200) {
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return [];
      }
    );
  };
}

export function createPriceList(data){
  return (dispatch) => {
    return api_createPriceList(data, priceListParams).then(
      (result) => {
        if(result.status === 200){
          dispatch(setPriceListData(result.data))
          dispatch(addPriceList(result.data))
          return result.data
        }else if(result.status === 422){
          return {errors: result.data}
        } else{
          dispatch(defaultErrorFeedback());
          return false;
        }
      }
    )
  }
}

export function activatePriceList(id){
  return (dispatch) => {
    return api_activatePriceList(id, priceListParams).then(
      (result) => {
        if(result.status === 200){
          dispatch(updatePriceListField('status', result.data.status))
          dispatch(updatePriceListOriginalField('status', result.data.status))
          dispatch(updatePriceListInList(id, {status: result.data.status}))
        }else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}

export function disablePriceList(id){
  return (dispatch) => {
    return api_disablePriceList(id, priceListParams).then(
      (result) => {
        if(result.status === 200){
          dispatch(updatePriceListField('status', result.data.status))
          dispatch(updatePriceListInList(id, {status: result.data.status}))
        }else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}

export function deletePriceList(id){
  return (dispatch) => {
    return api_deletePriceList(id).then(
      (result) => {
        if(result.status === 204){
          dispatch(removePriceList(id))
          return true
        }else{
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    )
  }
}

export function updatePriceList(id, data){
  return (dispatch) => {
    return api_updatePriceList(id, data, priceListParams).then(
      (result) => {
        if(result.status === 200){
          dispatch(setPriceListData(result.data))
          return true
        }else{
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    )
  }
}

export function createPriceListItem(id, item){
  return (dispatch) => {
    return api_createPriceListItem(id, item, priceListItemParams).then(
      (result) => {
        if(result.status === 200){
          dispatch(replacePriceListItem(result.data, item))
          return true
        }else{
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    )
  }
}

export function updatePriceListItem(id, itemId, data){
  return (dispatch) => {
    return api_updatePriceListItem(id, itemId, data, priceListItemParams).then(
      (result) => {
        if(result.status === 200){
          dispatch(replacePriceListItem(result.data))
          return true
        }else{
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    )
  }
}

export function deletePriceListItem(id, itemId){
  return (dispatch) => {
    return api_deletePriceListItem(id, itemId).then(
      (result) => {
        if(result.status === 200){
          dispatch(removePriceListItem(result.data.id, true))
          return true
        }else{
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    )
  }
}

export function lookupPriceListItem(priceListId, search){
  return (dispatch) => {
    return api_lookupPriceListItem(priceListId, search, priceListItemParams).then(
      (result) => {
        if(result.status === 200){
          return result.data
        }else{
          dispatch(defaultErrorFeedback());
          return false
        }
      }
    )
  }
}
