import React, { useEffect, useMemo, useState } from 'react'
import {
  Card, CardBody, CardTitle, Col, Row
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux'
import {
  createContact, deleteContact, getContactAuditHistory,
  getContactData,
  resetContact, restoreContact, setContactData,
  updateContact,
  updateContactField
} from '../../actions/contact'
import generateFormFields from '../../helpers/FormFieldGenerator';
import { warningFeedback } from '../../actions/feedback';
import ContactForm from './form';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import resolveArgs from '../../helpers/ArgumentResolver';
import LoadingOverlay from '../../components/LoadingOverlay';
import classnames from 'classnames';
import SiteContacts from '../../components/Tables/SiteContacts';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../components/ButtonIcon';
import { setConfirmDialog } from '../../actions/dialogs';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import CollapsibleCard from '../../components/CollapsibleCard';
import { getAccountSites } from '../../actions/account';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import DeactivatedEntityWarning from '../../components/Alerts/DeactivatedEntityWarning';
import { formValidator } from '../../helpers/FormValidator';
import { useParams } from 'react-router-dom'
import AuditHistory from "../../components/AuditHistory";
import ApiErrorResolver from '../../helpers/ApiErrorResolver'

const Contact = ({
  onCreated = null,
  onUpdated = null,
  toggleModal = null,
  accountId = null,
  id = null,
}) => {

  // router
  const params = useParams()

  const { original, form, audits } = useSelector(state => state.contact);
  const account = useSelector(state => state.account);
  const countries = useSelector(state => state.helpers.optionSets.account.addressCountry);
  const contactHelpers = useSelector(state => state.helpers.optionSets.contact);

  // redux
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [auditsLoading, setAuditsLoading] = useState(false);

  const contactId = useMemo(() => id || params.id, [id, params.id])
  const isNew = useMemo(() => !id && !original.id, [id, original.id]);
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);

  useEffect(() => {
    if (!isNew) {
      refreshData();
    } else {
      dispatch(setContactData({
        addressLine1: account.form.addressLine1,
        addressLine2: account.form.addressLine2,
        addressLine3: account.form.addressLine3,
        addressCity: account.form.addressCity,
        addressCounty: account.form.addressCounty,
        addressCountry:account.form.addressCountry,
        addressPostcode: account.form.addressPostcode,
        company: {...account.form}
      }));
    }
    if (!contactId) {
      setLoading(false);
    }
    return () => {
      dispatch(resetContact());
    };
  }, []);

  useEffect(() => {
    if(isEmpty(account.sites) && (accountId || account.original.accountid) ){
      dispatch(getAccountSites(accountId || account.original.accountid, ['primaryContact'])).then((sites) => setSites(sites || []))
    }
  }, [accountId]);

  useEffect(() => {
    if(!isEmpty(account.sites)){
      setSites(account.sites)
    }
  }, [account.sites]);

  const refreshData = () => {
    setLoading(true);
    dispatch(
        getContactData(
          contactId || original.id
        )
      )
      .then(() => setLoading(false));
  };
  const validated = () => {
    let errorArr = formValidator(ContactForm.generalFields, form)
    if (isNew && form.email && account.contacts) {
      const foundEmail = account.contacts.find((accountContact) => accountContact.email === form.email);
      if (foundEmail) {
        errorArr.push(`Contact with email ${foundEmail.email} already exists`);
      }
    }
    setErrors(errorArr);
    return isEmpty(errorArr);
  };
  const handleInput = (event) => {
    dispatch(updateContactField(event.target.id, event.target.value));
  };
  const handleSelectInput = (key, selected) => {
    let selectedValues;
    if (key === 'addressCountry') {
      selectedValues = selected ? selected.value : null;
    } else {
      selectedValues = selected ? selected.map((select) => {
        return select.value;
      }) : [];
    }
    dispatch(updateContactField(key, selectedValues));
  };

  const save = () => {
    if (validated()) {
      if (!isNew) {
        let toSave = diff(original, form);
        if (original.contactTypes !== form.contactTypes) {
          toSave = { ...toSave, contactTypes: form.contactTypes };
        } else {
          delete toSave.contactTypes;
        }
        if (!isEmpty(toSave)) {
          setLoading(true);
          dispatch(updateContact(original.id, resolveArgs(toSave)))
            .then((result) => {
              if (result && onUpdated) {
                onUpdated(result);
              }
              setLoading(false);
            });
        } else {
          dispatch(warningFeedback('nothing to update'));
        }
      } else {
        setLoading(true);
        dispatch(createContact(accountId || account.original.accountid, resolveArgs(form)))
          .then((result) => {
            if (result && onCreated) {
              onCreated(result);
            }
            setLoading(false);
          });
      }
    }
  };

  const handleOnDelete = () => {
    dispatch(setConfirmDialog({
        color: 'danger',
        text: 'This will deactivate the Contact and Portal User if applicable.',
        proceed: () => {
          setLoading(true)
          dispatch(deleteContact(original.id))
            .then((result) => {
              if (result && original && onUpdated) {
                onUpdated({ ...original, isActive: false, portalUser: null })
              }
              setLoading(false)
            })
        },
        cancel: () => {},
      },
    ))
  }

  const handleOnRestore = () => {
    dispatch(setConfirmDialog({
        color: 'danger',
        text: 'This will reactivate the Contact and Portal User if applicable.',
        proceed: () => {
          setLoading(true)
          dispatch(restoreContact(original.id, {include: 'portalUser'}))
            .then((result) => {
              if (result) {
                if (result.errors) {
                  setErrors(ApiErrorResolver(result.errors))
                } else if (onUpdated) {
                  onUpdated(result)
                }
              }
              setLoading(false)
            })
        },
        cancel: () => {},
      },
    ))
  }

  const getSelectedOption = (field) => {
    let selected = [];
    if (field === 'addressCountry') {
      selected = countries.options.filter((country) => {
        return form[field] && form[field] === country.value;
      })
    } else if (field === 'contactTypes') {
      selected = contactHelpers.type.options.filter((type) => {
        return form[field] && form[field].includes(type.value);
      });
    }
    return selected;
  };
  const getSelectOptions = (field) => {
    if (field === 'addressCountry') {
      return countries.options.map((country) => {
        return {
          ...country
        };
      });
    } else if (field === 'contactTypes') {
      return contactHelpers.type.options.map((contact) => {
        return {
          value: contact.value,
          label: contact.label
        };
      });
    }
    return [];
  };
  const hasChanges = () => {
    const changes = diff(original, form);
    return !isEmpty(changes)
  }

  const onClosing = () => {
    if(hasChanges() || isNew){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => toggleModal()
      }))
    }else{
      toggleModal()
    }
  }

  const getAudits = () => {
    toggleAuditsLoading();
    dispatch(getContactAuditHistory(original.id)).then(() => toggleAuditsLoading())
  }

  return (
    <LoadingOverlay loading={loading}>
      <Card className='mb-0'>
        <CardBody className={'bg-light border-0'}>
          <Row className='mb-2'>
            <Col className='d-flex'>
              <CardTitle>
                {original.name}
              </CardTitle>
              <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ml-auto')}>
                {!loading && !isNew && hasChanges() && (
                  <UnsavedChangesAlert save={save}/>
                )}
                {!isNew && original.isActive && (
                  <ButtonIcon  disabled={loading} icon={'fa fa-trash'} tooltip={'Deactivate'}  onClick={handleOnDelete}/>
                )}
                <ButtonIcon  disabled={loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'}  onClick={save}/>
                <ButtonIcon  disabled={loading} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={refreshData}/>
                {toggleModal &&
                  <ButtonIcon onClick={onClosing} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                }
              </div>
            </Col>
          </Row>
          <FormValidationErrors errors={errors}/>
          <DeactivatedEntityWarning
            deactivated={!isEmpty(original.id) && !original.isActive}
            message={'This contact has been deactivated.'}
            onRestore={handleOnRestore}
          />
          <Row className={"d-flex"}>
            <Col className={"d-flex flex-1"}>
              <EntityMainFormCard>
                <Row form>
                  {generateFormFields({
                    fields: ContactForm.generalFields,
                    handleInput,
                    getSelectOptions,
                    getSelectedOption,
                    handleSelectInput,
                    data: form
                  })}
                </Row>
              </EntityMainFormCard>
            </Col>
          </Row>
          <Row className="d-flex">
            <Col>
              <CollapsibleCard
                open={false}
                title={'Sites'}
              >
                <SiteContacts
                  sites={form.sites}
                  accountSites={sites}
                  contactTypes={contactHelpers.type.options}
                />
              </CollapsibleCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <CollapsibleCard
                  title={'Audit History'}
                  onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
              >
                <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
              </CollapsibleCard>
            </Col>
          </Row>
        </CardBody>
      </Card>
      </LoadingOverlay>
  );
};

export default Contact;
