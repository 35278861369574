import isEmpty from 'lodash.isempty';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import FilteredTableV2 from '../../FilteredTableV2';
import React, { useState } from 'react';
import { Badge, Button } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { ButtonIcon } from '../../ButtonIcon';
import CreditRequest from '../../../views/CreditRequest';
import HeadlessModal from '../../Modals/HeadlessModal';
import numeral from 'numeral';
import business from 'moment-business';
import { CreditRequestEnums } from '../../../utils/Constants/CreditRequest';
import { resetCreditRequest } from '../../../actions/creditRequest';
import { checkPermission, inCreditRequestGroups } from '../../../utils/Auth/AuthService';

const CreditRequestsTable = ({
  account,
  creditRequests,
  fetchData,
  users,
  user,
  optionSets,
  onCreated,
  onUpdated,
  dispatch
}) => {

  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(null);
  const toggleModal = () => setShowModal(prevState => !prevState)

  const getStatusBGClass = (value) => {
    if (value === CreditRequestEnums.status.APPROVED) {
      return 'success';
    } else if (value === CreditRequestEnums.status.REJECTED || value === CreditRequestEnums.status.CANCELED){
      return 'danger';
    } else if (value === CreditRequestEnums.status.NOT_STARTED){
      return 'secondary';
    }
    return 'warning'
  }

  const columns = [
    {
      header: 'Request',
      accessorKey: 'requestNumber',
    },
    {
      header: 'Subject',
      accessorKey: 'subject',
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      cell: props => (
        '£' + numeral(props.getValue())
          .format('0,0.00')
      )
    },
    {
      header: 'Reason',
      accessorKey: 'reason',
      cell: props => optionSets?.reason?.options.find(option => option.value === props.getValue())?.label ?? ''
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: props => {
        const value =  optionSets?.status?.options.find(option => option.value === props.getValue())?.label ?? ''
        return <Badge style={{minWidth: '86px'}} className={`p-2 faded-${getStatusBGClass(props.getValue())}`}>{value}</Badge>
      },
    },
    {
      header: 'Authorised By',
      accessorKey: 'authorisedBy',
      cell: props => users?.find(option => option.id === props.getValue())?.name ?? ''
    },
    {
      header: 'Created By',
      accessorKey: 'createdBy',
      cell: props => users?.find(option => option.id === props.getValue())?.name ?? ''
    },
    {
      header: 'Created On',
      accessorKey: 'createdAt',
      cell: props => moment(props.getValue())
        .format('DD/MM/YYYY HH:mm:ss')
    },
  ]

  return (
    <>
      <FilteredTableV2
        minRows={10}
        columns={columns}
        showPagination={creditRequests.length > 10}
        defaultPageSize={10}
        data={creditRequests}
        noDataText={'No Credit Requests'}
        loading={false}
        extraButtons={[
          <Button disabled={!inCreditRequestGroups(user.permissions)} size={'sm'} color={'secondary'} onClick={() => {
            setId(null)
            toggleModal()
          }}>New Request</Button>,
          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
        ]}
        onRowClick={creditRequest => {
          toggleModal()
          setId(creditRequest.id)
        }}
        defaultSorted={[
          {id: 'createdAt', desc: true}
        ]}
      />
      <HeadlessModal
        open={showModal}
        onClosed={() => setId(null)}
        size={'xlg'}
        toggle={() => {
          toggleModal()
          dispatch(resetCreditRequest())
        }}
      >
        <CreditRequest
          account={account}
          id={id}
          closeModal={() => {
            setId(null)
            toggleModal()
          }}
          onCreated={(creditRequest) => onCreated(creditRequest)}
          onUpdated={(creditRequest) => onUpdated(creditRequest)}
        />
      </HeadlessModal>
    </>

  )
}
const mapStateToProps = ({
  helpers,
  authenticationState
}) => ({
  optionSets: helpers.optionSets.creditRequest,
  users: helpers.systemUsers,
  user: authenticationState.account
});
export default connect(mapStateToProps)(CreditRequestsTable)