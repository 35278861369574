import { BlockService } from '../../../utils/ServiceDB/Ipam/Block';
import { defaultErrorFeedback } from '../../feedback';

export const IPAM_BLOCK_SET_DATA = 'IPAM_BLOCK_SET_DATA';
export const IPAM_BLOCK_SET_FIELD = 'IPAM_BLOCK_SET_FIELD';
export const IPAM_BLOCK_RESET_DATA = 'IPAM_BLOCK_RESET_DATA';
export const IPAM_BLOCK_ADD_SUBNET = 'IPAM_BLOCK_ADD_SUBNET';
export const IPAM_BLOCK_UPDATE_SUBNET = 'IPAM_BLOCK_UPDATE_SUBNET';
export const IPAM_BLOCK_REMOVE_SUBNET = 'IPAM_BLOCK_REMOVE_SUBNET';

export function getBlock(id, include = []) {
  return (dispatch) => {
    return BlockService.get(id, include).then((result) => {
      if (result.status === 200) {
        dispatch(setBlockData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createBlock(args, include = []) {
  return (dispatch) => {
    return BlockService.create(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setBlockData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function updateBlock(id, args, include = []) {
  return (dispatch) => {
    return BlockService.update(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setBlockData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function deleteBlock(id) {
  return (dispatch) => {
    return BlockService.delete(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function addNewBlockSubnet(subnetData) {
  return {
    type: IPAM_BLOCK_ADD_SUBNET,
    subnetData
  };
}

export function updateBlockSubnet(subnetData) {
  return {
    type: IPAM_BLOCK_UPDATE_SUBNET,
    subnetData
  };
}

export function removeBlockSubnet(subnetId) {
  return {
    type: IPAM_BLOCK_REMOVE_SUBNET,
    subnetId
  };
}

function setBlockData(data) {
  return {
    type: IPAM_BLOCK_SET_DATA,
    data
  };
}

export function setBlockField(field, value) {
  return {
    type: IPAM_BLOCK_SET_FIELD,
    field,
    value
  };
}

export function resetBlock() {
  return {
    type: IPAM_BLOCK_RESET_DATA
  };
}
