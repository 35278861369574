import { BILLING_NEW_LINE_ITEMS_SET_ITEMS, BILLING_NEW_LINE_ITEMS_SET_LOADING } from '../actions/billingNewLineItems';

const initialState = {
  loading: false,
  items: []
};

export default function billingNewLineItems(state = initialState, action) {
  switch (action.type) {
    case BILLING_NEW_LINE_ITEMS_SET_ITEMS:
      return {
        ...state,
        items: action.items
      };
    case BILLING_NEW_LINE_ITEMS_SET_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
}
