import React, { Component } from 'react';

class HsoBar extends Component {
  render() {
    let height = this.props.height ? this.props.height : '5px';
    let topBorder = this.props.topBorder ? this.props.topBorder : '0.1px';
    return (
            <div style={{ height: height }}>
                <div style={{ float: 'left', width: '40px', backgroundColor: '#6BA4B8', height: height }}>{' '}</div>
                <div style={{ float: 'left', width: '100px', backgroundColor: '#333F48', height: height, borderTopWidth: topBorder, borderTopColor: '#3b7184', borderTopStyle: 'solid' }}>{' '}</div>
                <div style={{ width: '100%', backgroundColor: '#FF9E1B', height: height }}>{' '}</div>
            </div>
    );
  }
}

export default HsoBar;
