import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, UncontrolledAlert} from "reactstrap";
import {PulseLoader} from "react-spinners";
import React, {useState} from "react";
import {takeSnap} from "../../actions/jsnapy";
import LaddaButton, {ZOOM_OUT} from "react-ladda";

const AddRouter = props => {
    const {dispatch, snapCreating} = props;
    const [hostName, setHostName] = useState('');
    const [snapName, setSnapName] = useState('');

    return (
        <Row className="w-100 p-0">
            <Col className="d-flex justify-content-center mt-2 w-100">
                <Col sm={6}>
                    <Card className="card-accent-info animated fadeIn m-0">
                        <CardBody className="pb-0">
                            <Form>
                                <FormGroup row>
                                    <Label for="username" size="sm" lg={4}>Hostname</Label>
                                    <Col lg={8}>
                                        <Input
                                            bsSize="sm"
                                            type="text"
                                            id="username"
                                            onChange={(e)=>setHostName(e.target.value)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="snapname" size="sm" lg={4}>SnapName</Label>
                                    <Col lg={8}>
                                        <Input
                                            bsSize="sm"
                                            type="text"
                                            id="snapname"
                                            onChange={(e)=>setSnapName(e.target.value)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col lg={8}>
                                        <LaddaButton
                                            className="btn btn-primary btn-ladda px-4"
                                            loading={Boolean(snapCreating)}
                                            data-style={ZOOM_OUT}
                                            data-color="blue"
                                            onClick={()=>dispatch(takeSnap(snapName, hostName))}
                                            disabled={snapName.length <= 0 || hostName.length <= 13 }
                                        >Add</LaddaButton>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Col>
        </Row>
    )
}

export default AddRouter;