import { netApiRequest } from '../Api/ApiRequest';

export function getNetAPIDevices() {
  const url = '/devices';
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function getNetAPIRrGroups(detail = true) {
  const url = '/rrgroups'+(detail === true ? '?include=members' : '');
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function setNetAPIRrGroup(group, dev, is_primary = false, is_secondary = false) {
  const url = '/rrgroups/'+group+'/members';
  return netApiRequest({
    url,
    method: 'put',
    data: {
      dev,
      is_primary,
      is_secondary
    }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function delNetAPIRrGroup(group, id) {
  const url = '/rrgroups/'+group+'/members';
  return netApiRequest({
    url,
    method: 'delete',
    data: {
      id
    }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function getNetAPINetworks() {
  const url = '/networks';
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function updateNetApiDevice(device, data) {
  const url = '/devices/'+device;
  return netApiRequest({
    url,
    method: 'put',
    data
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function setNetApiRrGroupMemberMode(group, memberid, mode) {
  const url = '/rrgroups/'+group+'/members/'+memberid+'/'+mode;
  return netApiRequest({
    url,
    method: 'post',
    data: {}
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function addNetApiRrGroup(group_name, vrr_1, vrr_2) {
  const url = '/rrgroups';
  return netApiRequest({
    url,
    method: 'post',
    data: {group_name, vrr_1, vrr_2}
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function submitArpScannerJob(hostname) {
  const url = '/jobs';
  return netApiRequest({
    url,
    method: 'put',
    data: {
      "job": "tasks.monitoring.arp_pps",
      params: [hostname]
    }
  }).then(response => response)
      .catch(function (error) {
        return Promise.reject(error.response || error.message);
      });
}
