import { LICENSES_SET_LICENSES } from '../../actions/ServiceDB/licenses';
import moment from 'moment/moment';

const initialState = {
  licenses: [],
  searchString: '',
  searchExpired: '',
  searchAvailable: '',
  filteredLicenses: []
};
const licenses = (state = initialState, action) => {
  switch (action.type) {
    case LICENSES_SET_LICENSES:
      return {
        ...state,
        licenses: action.data
      };
    default:
      return state;
  }
};

export default licenses;