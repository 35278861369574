import { useBlocker } from 'react-router-dom';
import { useEffect } from 'react';
import { setConfirmDialog } from '../../actions/dialogs';
import { useDispatch } from 'react-redux';


export const NavigationBlocker = ({
  shouldBlock
}) => {
  const dispatch = useDispatch();

  const isAccountsPath = (path) => /\/accounts\/([0-9a-fA-F-]+)\//.test(path);

  const movingFromAccountsToAccounts = (current, next) => {
    const currentUUID = extractAccountUUID(current)
    const nextUUID = extractAccountUUID(next)
    return isAccountsPath(current) && isAccountsPath(next) && currentUUID === nextUUID
  }

  const extractAccountUUID = (url) => {
    const regex = /\/accounts\/([0-9a-fA-F-]+)\//;
    const match = url.match(regex);
    return match ? match[1] : null;
  }


  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      shouldBlock &&
      currentLocation.pathname !== nextLocation.pathname &&
      !movingFromAccountsToAccounts(currentLocation.pathname, nextLocation.pathname)

  );
  useEffect(() => {
    if(blocker.state === 'blocked'){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => blocker.proceed(),
        cancel: () => blocker.reset(),
      }))
    }
  }, [blocker.state]);

  return (
    <></>
  )
}

