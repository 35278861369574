import React, {FunctionComponent, PropsWithChildren} from "react";
import {createBrowserRouter, RouteObject, RouterProvider, useRoutes} from 'react-router-dom';
import Account from './views/Account';
import AccountPreview from './views/Billing/AccountPreview';
import AccountServices from './views/Reports/AccountServices';
import Accounts from './views/Accounts';
import Address from './views/ServiceDB/Ipam/Address';
import Admin from './views/Billing/Admin';
import Alarms from './views/Alarms/Alarms';
import ArpPpsScanner from "./views/NetAPI/ArpPpsScanner";
import Billing from './views/Billing/Billing';
import BillingRun from './views/Billing/BillingRun';
import Block from './views/ServiceDB/Ipam/Block';
import Blocks from './views/ServiceDB/Ipam/Blocks';
import CarrierCircuit from './views/ServiceDB/CarrierCircuit';
import CarrierCircuits from './views/ServiceDB/CarrierCircuits';
import Cases from './views/Cases';
import CeleryQueues from './views/Developers/CeleryQueues';
import Circuit from './views/Circuit';
import CircuitReconcilliation from './views/Reports/CircuitReconcilliation';
import Circuits from './views/Circuits';
import Compliance from './views/Compliance/Compliance';
import ComplianceHost from './views/Compliance/ComplianceHost';
import ConfigBackupHost from './views/Compliance/ConfigBackupHost';
import ConfigBackups from './views/Compliance/ConfigBackups';
import ConfigTemplate from './views/ConfigTemplate/Template'
import ConfigTemplateList from "./views/ConfigTemplate";
import Connection from "./views/ServiceDB/Connection";
import Connections from "./views/ServiceDB/Connections";
import Contact from './views/Contact';
import CoreCheck from './views/CoreCheck/CoreCheck';
import CoreCheckResult from './views/CoreCheck/CoreCheckResult';
import CoreSnap from './views/CoreSnap/CoreSnap';
import CoreSnapResults from "./views/CoreSnap/CoreSnapResults";
import CrossConnect from "./views/ServiceDB/CrossConnect";
import CrossConnects from './views/ServiceDB/CrossConnects';
import DNS from './views/DNS/DNS';
import DNSZone from './views/DNS/DNSZone';
import Dashboard from './views/Dashboard/index';
import Device from "./views/NetAPI/Devices/Device";
import DeviceCheck from './views/DeviceCheck/DeviceCheck';
import DeviceDeployment from "./views/ServiceDB/DeviceDeployment";
import DeviceDeployments from './views/ServiceDB/DeviceDeployments';
import DeviceModel from './views/ServiceDB/DeviceModel';
import DeviceModels from "./views/ServiceDB/DeviceModels";
import DeviceResult from './views/DeviceCheck/DeviceResult';
import Devices from "./views/NetAPI/Devices/Devices";
import Domain from './views/ServiceDB/Ipam/Domain';
import Domains from './views/ServiceDB/Ipam/Domains';
import Exchanges from "./views/Discovery/Exchanges/Exchanges";
import ExtTraffic from './views/ExtTraffic/ExtTraffic';
import Full from "./containers/Full/Full";
import GlobalSearch from './views/GlobalSearch';
import IpTable from './views/IpTable/IpTable';
import K8s from './views/Developers/K8s';
import LOAGenerator from './views/ServiceDelivery/LOAGenerator';
import Lead from "./views/Lead";
import LeadTimesReport from './views/ServiceDelivery/LeadTimesReport';
import Leads from './views/Leads';
import License from './views/ServiceDB/License';
import Licenses from './views/ServiceDB/Licenses';
import LogicalService from './views/ServiceDB/LogicalService';
import LogicalServices from './views/ServiceDB/LogicalServices';
import Logs from './views/Developers/Logs';
import NNI from './views/ServiceDB/NNI';
import NNIs from "./views/ServiceDB/NNIs";
import Opportunities from './views/Opportunities';
import Opportunity from './views/Opportunity';
import OpticalSystem from "./views/ServiceDB/OpticalSystem";
import OpticalSystems from "./views/ServiceDB/OpticalSystems";
import Order from './views/Order';
import Pops from "./views/Discovery/Pops/Pops";
import PrefixList from './views/PrefixLists/PrefixList';
import PrefixLists from './views/PrefixLists/PrefixLists';
import PriceList from './views/PriceList';
import PriceLists from './views/PriceLists';
import PricingTool from './views/PricingTool';
import PricingToolQuote from './views/PricingToolQuote';
import PricingTypes from './views/PricingTypes';
import ProductSLAs from './views/ProductSLAs';
import ProductTemplates from './views/ProductTemplates';
import Queues from './views/Developers/Queues';
import Rack from './views/ServiceDB/Rack';
import Racks from './views/ServiceDB/Racks';
import Radius from './views/Radius/Radius';
import RadiusUser from './views/Radius/RadiusUser';
import RealtimeTraffic from './views/RealtimeTraffic/RealtimeTraffic';
import Reports from './views/Reports/index';
import RrGroups from "./views/NetAPI/RrGroups/RrGroups";
import Sales from './views/Sales';
import SalesOrders from './views/Sales/Orders';
import SalesReports from './views/Sales/Reports';
import ServiceDelivery from './views/ServiceDelivery';
import ServiceDeliveryOrders from './views/ServiceDelivery/Orders';
import Serving from "./views/Discovery/Serving/Serving";
import ShadowServer from './views/Compliance/ShadowServer';
import ShadowServerHost from "./views/Compliance/ShadowServerHost";
import ShiftTracking from "./views/ShiftTracking";
import Signature from './views/Signature';
import Signatures from './views/Signatures';
import StatusMessages from './views/Status/StatusMessages';
import Stock from "./views/ServiceDB/Stock";
import StockItem from "./views/ServiceDB/StockItem";
import Subnet from './views/ServiceDB/Ipam/Subnet';
import Vendor from './views/ServiceDB/Vendor';
import Vendors from "./views/ServiceDB/Vendors";
import Wallboard from "./views/Wallboard/WallBoard";
import WeatherMap from './views/WeatherMaps/WeatherMap';
import WeatherMapEditor from './views/WeatherMaps/WeatherMapEditor';
import WeatherMaps from './views/WeatherMaps/WeatherMaps';
import WipReport from './views/ServiceDelivery/WipReport';
import {GenericNotFound} from "./components/Errors/Errors";

const App: FunctionComponent<PropsWithChildren> = () => {

  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Full/>,
      children: [
        {path: 'dashboard', element: <Dashboard/>},
        {path: 'toolbox/arppps', element: <ArpPpsScanner/>},
        {path: 'toolbox/dcheck', element: <DeviceCheck/>},
        {path: 'toolbox/dcheck/:uuid', element: <DeviceResult/>},
        {path: 'toolbox/prefixlists', element: <PrefixLists/>},
        {path: 'toolbox/prefixlists/:listName', element: <PrefixList/>},
        {path: 'toolbox/iptable', element: <IpTable/>},
        {path: 'toolbox/devices', element: <Devices/>},
        {path: 'toolbox/devices/:hostname', element: <Device/>},
        {path: 'toolbox/dns', element: <DNS/>},
        {path: 'toolbox/dns/:ZoneID', element: <DNSZone/>},
        {path: 'toolbox/exttraffic', element: <ExtTraffic/>},
        {path: 'toolbox/alarms', element: <Alarms/>},
        {path: 'toolbox/maps', element: <WeatherMaps/>},
        {path: 'toolbox/maps/:mapName', element: <WeatherMap/>},
        {path: 'toolbox/maps/:mapName/edit', element: <WeatherMapEditor/>},
        {path: 'toolbox/status', element: <StatusMessages/>},
        {path: 'toolbox/realtime', element: <RealtimeTraffic/>},
        {path: 'toolbox/configtemplate', element: <ConfigTemplateList/>},
        {path: 'toolbox/configtemplate/:entryName', element: <ConfigTemplate/>},
        {path: 'toolbox/coreconfig', element: <CoreCheck/>},
        {path: 'toolbox/coreconfig/:uuid', element: <CoreCheckResult/>},
        {path: 'toolbox/coresnap', element: <CoreSnap/>},
        {path: 'toolbox/coresnap/:uuid', element: <CoreSnapResults/>},
        {path: 'toolbox/radius', element: <Radius/>},
        {path: 'toolbox/radius/:username', element: <RadiusUser/>},
        {path: 'toolbox/rrgroups', element: <RrGroups/>},
        {path: 'toolbox/wallboard', element: <Wallboard/>},
        {path: 'accounts', element: <Accounts/>},
        {path: 'accounts/:accountNumber', element: <Account/>},
        {path: 'accounts/:accountNumber/:tab', element: <Account/>},
        {path: 'billing/accounts', element: <AccountPreview/>},
        {path: 'billing/admin', element: <Admin/>},
        {path: 'billing/run/:runId', element: <BillingRun/>},
        {path: 'billing', element: <Billing/>},
        {path: 'products/pricelists/:id', element: <PriceList/>},
        {path: 'products/pricelists', element: <PriceLists/>},
        {path: 'products/pricing', element: <PricingTypes/>},
        {path: 'products/slas', element: <ProductSLAs/>},
        {path: 'products/templates', element: <ProductTemplates/>},
        {path: 'service-delivery', element: <ServiceDelivery/>},
        {path: 'service-delivery/orders', element: <ServiceDeliveryOrders/>},
        {path: 'service-delivery/orders/:id', element: <Order/>},
        {path: 'service-delivery/wip', element: <WipReport/>},
        {path: 'service-delivery/loa', element: <LOAGenerator/>},
        {path: 'service-delivery/lead-times', element: <LeadTimesReport/>},
        {path: 'reports', element: <Reports/>},
        {path: 'reports/circuitrecon', element: <CircuitReconcilliation/>},
        {path: 'reports/services', element: <AccountServices/>},
        {path: 'signatures', element: <Signatures/>},
        {path: 'signatures/:sigId', element: <Signature/>},
        {path: 'developers/queues', element: <Queues/>},
        {path: 'developers/cqueues', element: <CeleryQueues/>},
        {path: 'developers/logs', element: <Logs/>},
        {path: 'cases', element: <Cases/>},
        {path: 'circuits', element: <Circuits/>},
        {path: 'circuits/:id', element: <Circuit/>},
        {path: 'sales', element: <Sales/>},
        {path: 'sales/pricing-tool', element: <PricingTool/>},
        {path: 'sales/pricing-tool/:id', element: <PricingToolQuote/>},
        {path: 'sales/opportunities', element: <Opportunities/>},
        {path: 'sales/opportunities/:opportunityId', element: <Opportunity/>},
        {path: 'sales/orders', element: <SalesOrders/>},
        {path: 'sales/orders/:id', element: <Order/>},
        {path: 'sales/leads', element: <Leads/>},
        {path: 'sales/leads/:leadId', element: <Lead/>},
        {path: 'sales/reports', element: <SalesReports/>},
        {path: 'compliance', element: <Compliance/>},
        {path: 'compliance/shadowserver', element: <ShadowServer/>},
        {path: 'compliance/shadowserver/:ipaddress', element: <ShadowServerHost/>},
        {path: 'compliance/configbackup', element: <ConfigBackups/>},
        {path: 'compliance/configbackup/:hostName', element: <ConfigBackupHost/>},
        {path: 'compliance/:hostName', element: <ComplianceHost/>},
        {path: 'developers/k8s', element: <K8s/>},
        {path: 'discovery/pops', element: <Pops/>},
        {path: 'discovery/exchanges', element: <Exchanges/>},
        {path: 'discovery/serving', element: <Serving/>},
        {path: 'sdb/racks', element: <Racks/>},
        {path: 'sdb/racks/:rackId', element: <Rack/>},
        {path: 'sdb/device-deployments', element: <DeviceDeployments/>},
        {path: 'sdb/device-deployments/:deviceDeploymentId', element: <DeviceDeployment/>},
        {path: 'sdb/device-models', element: <DeviceModels/>},
        {path: 'sdb/device-models/:deviceModelId', element: <DeviceModel/>},
        {path: 'sdb/nnis', element: <NNIs/>},
        {path: 'sdb/nnis/:nniId', element: <NNI/>},
        {path: 'sdb/vendors', element: <Vendors/>},
        {path: 'sdb/vendors/:vendorId', element: <Vendor/>},
        {path: 'sdb/optical-systems', element: <OpticalSystems/>},
        {path: 'sdb/optical-systems/:opticalSystemId', element: <OpticalSystem/>},
        {path: 'sdb/connections', element: <Connections/>},
        {path: 'sdb/connections/:connectionId', element: <Connection/>},
        {path: 'sdb/stock', element: <Stock/>},
        {path: 'sdb/stock/:stockItemId', element: <StockItem/>},
        {path: 'sdb/licenses', element: <Licenses/>},
        {path: 'sdb/licenses/:licenseId', element: <License/>},
        {path: 'sdb/carrier-circuits', element: <CarrierCircuits/>},
        {path: 'sdb/carrier-circuits/:carrierCircuitId', element: <CarrierCircuit/>},
        {path: 'sdb/cross-connects', element: <CrossConnects/>},
        {path: 'sdb/cross-connects/:crossConnectId', element: <CrossConnect/>},
        {path: 'sdb/ipam/domains', element: <Domains/>},
        {path: 'sdb/ipam/domains/:domainId', element: <Domain/>},
        {path: 'sdb/ipam/blocks', element: <Blocks/>},
        {path: 'sdb/ipam/blocks/:blockId', element: <Block/>},
        {path: 'sdb/ipam/subnets/:subnetId', element: <Subnet/>},
        {path: 'sdb/ipam/addresses/:addressId', element: <Address/>},
        {path: 'sdb/services/:serviceType', element: <LogicalServices/>},
        {path: 'sdb/services/:serviceType/:serviceId', element: <LogicalService/>},
        {path: 'global-search', element: <GlobalSearch/>},
        {path: 'shifttracking', element: <ShiftTracking/>},
        {path: '/', element: <Dashboard/>},
        {path: '*', element: <GenericNotFound/>},
      ]
    }
  ];
  const router = createBrowserRouter(routes)

  return (
      <RouterProvider router={router} />
  );
}

export default App;
