import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap';
import { getOpticalSystems } from '../../../actions/opticalSystems';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import FilteredTable from '../../../components/FilteredTable';
import { ButtonIcon } from '../../../components/ButtonIcon';
import { canAccessServiceDb } from '../../../utils/Auth/AuthService';

const OpticalSystems = ({ opticalSystems }) => {

  // router
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    dispatch(getOpticalSystems())
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  const columns = [
    {
      filterable: true,
      sortable: true,
      Header: 'ID',
      accessor: 'id',
      width: 100
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Name',
      accessor: 'name'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Type',
      accessor: 'type'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Circuit ID',
      accessor: 'carrierCircuit.circuitId'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Carrier',
      accessor: 'carrierCircuit.supplier.name'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'A End',
      accessor: 'aEnd.hostname'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'B End',
      accessor: 'bEnd.hostname'
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 50,
      Cell: props => (
        <Link to={`/sdb/optical-systems/${props.value}`}><i className="icon-arrow-right"/></Link>
      )
    }
  ];
  return (
    <Row>
      <Col className={'d-flex'}>
        <Card className="w-100">
          <CardBody>
            <FilteredTable
              columns={columns}
              data={opticalSystems}
              minRows={1}
              filterable={false}
              hideFilter={true}
              loading={loading}
              onRowClick={(row) => navigate(`/sdb/optical-systems/${row.id}`)}
              extraButtons={[
                <ButtonIcon
                  icon="fa fa-refresh"
                  tooltip="Refresh"
                  onClick={fetch}/>,
                canAccessServiceDb() ? (
                  <ButtonIcon
                    icon="fa fa-plus"
                    tooltip="Add New"
                    onClick={() => navigate('/sdb/optical-systems/new')}/>
                ) : ''
              ]}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

function mapStateToProps({ opticalSystems, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    opticalSystems: opticalSystems.opticalSystems
  };
}

export default connect(mapStateToProps)(OpticalSystems);
