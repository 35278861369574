import {
  HELPERS_SET_ENTITY_OPTION_SETS,
  HELPERS_SET_CRM_SUBJECTS,
  HELPERS_SET_CRM_SUPPLIERS,
  HELPERS_SET_SYSTEM_USERS,
  HELPERS_SET_PRODUCT_LIST,
  HELPERS_SET_PRICING_TOOL_CARRIERS,
  HELPERS_SET_PRICING_TOOL_KITS,
  HELPERS_SET_PRICING_TOOL_FEASIBILITIES,
  HELPERS_SET_PRICING_TOOL_INSTALL_TYPES,
  HELPERS_SET_PRICING_TOOL_NNIS,
  HELPERS_SET_PRICING_TOOL_TECHNOLOGIES,
  HELPERS_SET_DOCUMENT_OPTION_SETS,
  HELPERS_SET_PRICING_TOOL_CARRIER_PRODUCTS,
  HELPERS_SET_LOGICAL_SERVICE_TYPES,
  HELPERS_SET_DEFAULT_PRICING_TOOL_PRODUCTS,
  HELPERS_SET_SERVICE_DB_L2TP_OPTIONS,
  HELPERS_SET_SERVICE_DB_NNI_OPTIONS, HELPERS_ADD_SUPPLIER_CRM_SUPPLIERS
} from '../actions/helpers';

const initialState = {
  optionSets: {},
  suppliers: {},
  systemUsers: {},
  subjects: [],
  products: [],
  pricingTool: {
    kit: [],
    carrier: [],
    feasibility: [],
    install: [],
    technology: [],
    carrierProducts: [],
    products: []
  },
  serviceDb: {
    logicalServiceTypes: [],
    l2tp: [],
    nni: []
  }
};

export default function helpers(state = initialState, action) {
  switch (action.type) {
    case HELPERS_SET_ENTITY_OPTION_SETS:
      return {
        ...state,
        optionSets: action.optionSets
      };
    case HELPERS_SET_CRM_SUPPLIERS:
      return {
        ...state,
        suppliers: action.suppliers
      };
    case HELPERS_ADD_SUPPLIER_CRM_SUPPLIERS:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [action.supplier.id]: action.supplier
        },
      };
    case HELPERS_SET_SYSTEM_USERS:
      action.users.sort((a, b) => a.name.localeCompare(b.name));
      return {
        ...state,
        systemUsers: action.users
      };
    case HELPERS_SET_CRM_SUBJECTS:
      return {
        ...state,
        subjects: action.subjects
      };
    case HELPERS_SET_PRODUCT_LIST:
      return {
        ...state,
        products: action.data
      };
    case HELPERS_SET_PRICING_TOOL_CARRIERS:
      return {
        ...state,
        pricingTool: {
          ...state.pricingTool,
          carrier: action.carriers
        }
      };
    case HELPERS_SET_PRICING_TOOL_KITS:
      return {
        ...state,
        pricingTool: {
          ...state.pricingTool,
          kit: action.kits
        }
      };
    case HELPERS_SET_PRICING_TOOL_FEASIBILITIES:
      return {
        ...state,
        pricingTool: {
          ...state.pricingTool,
          feasibility: action.feasibilities
        }
      };
    case HELPERS_SET_PRICING_TOOL_INSTALL_TYPES:
      return {
        ...state,
        pricingTool: {
          ...state.pricingTool,
          install: action.installs
        }
      };
    case HELPERS_SET_PRICING_TOOL_NNIS:
      return {
        ...state,
        pricingTool: {
          ...state.pricingTool,
          nnis: action.nnis
        }
      };
    case HELPERS_SET_PRICING_TOOL_TECHNOLOGIES:
      return {
        ...state,
        pricingTool: {
          ...state.pricingTool,
          technology: action.technologies
        }
      };
    case HELPERS_SET_PRICING_TOOL_CARRIER_PRODUCTS:
      return {
        ...state,
        pricingTool: {
          ...state.pricingTool,
          carrierProducts: action.products
        }
      };
    case HELPERS_SET_DOCUMENT_OPTION_SETS:
      return {
        ...state,
        optionSets: {
          ...state.optionSets,
          document: action.optionSets
        }
      }
    case HELPERS_SET_LOGICAL_SERVICE_TYPES:
      return {
        ...state,
        serviceDb: {
          ...state.serviceDb,
          logicalServiceTypes: action.logicalServiceTypes
        }
      }
    case HELPERS_SET_SERVICE_DB_L2TP_OPTIONS:
      return {
        ...state,
        serviceDb: {
          ...state.serviceDb,
          l2tp: action.options
        }
      }
    case HELPERS_SET_SERVICE_DB_NNI_OPTIONS:
      return {
        ...state,
        serviceDb: {
          ...state.serviceDb,
          nni: action.options
        }
      }
    case HELPERS_SET_DEFAULT_PRICING_TOOL_PRODUCTS:
      return {
        ...state,
        pricingTool: {
          ...state.pricingTool,
          products: action.products
        }
      }
    default:
      return state;
  }
}
