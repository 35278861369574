import moment from 'moment';

export const ACCOUNTS_REPORTS_RESET = 'ACCOUNTS_REPORTS_RESET';
export const ACCOUNTS_REPORTS_SET_SERVICE_INCIDENTS_DATA = 'ACCOUNTS_REPORTS_SET_SERVICE_INCIDENTS_DATA';
export const ACCOUNTS_REPORTS_DELETE_SERVICE_INCIDENT = 'ACCOUNTS_REPORTS_DELETE_SERVICE_INCIDENT';
export const ACCOUNTS_REPORTS_SET_SERVICE_INCIDENT = 'ACCOUNTS_REPORTS_SET_SERVICE_INCIDENT';
export const ACCOUNTS_REPORTS_SET_SERVICE_UTILISATION_SERVICES = 'ACCOUNTS_REPORTS_SET_SERVICE_UTILISATION_SERVICES';
export const ACCOUNTS_REPORTS_DELETE_SERVICE_UTILISATION_SERVICE = 'ACCOUNTS_REPORTS_DELETE_SERVICE_UTILISATION_SERVICE';
export const ACCOUNTS_REPORTS_SET_SERVICE_UTILISATION_SERVICE = 'ACCOUNTS_REPORTS_SET_SERVICE_UTILISATION_SERVICE';
export const ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_DATA = 'ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_DATA';
export const ACCOUNTS_REPORTS_DELETE_SERVICE_AVAILABILITY_INCIDENT = 'ACCOUNTS_REPORTS_DELETE_SERVICE_AVAILABILITY_INCIDENT';
export const ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_INCIDENT = 'ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_INCIDENT';
export const ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_CONFIG_ITEMS = 'ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_CONFIG_ITEMS';
export const ACCOUNTS_REPORTS_DELETE_SERVICE_AVAILABILITY_CONFIG_ITEM = 'ACCOUNTS_REPORTS_DELETE_SERVICE_AVAILABILITY_CONFIG_ITEM';

const initialState = {
  serviceIncidents: {
    incidents: []
  },
  serviceUtilisation: {
    services: []
  },
  serviceAvailability: {
    incidents: [],
    configItems: []
  }
};

export default function accountReports(state = initialState, action) {
  switch (action.type) {
    case ACCOUNTS_REPORTS_SET_SERVICE_INCIDENTS_DATA:
      return {
        ...state,
        serviceIncidents: {
          ...state.serviceIncidents,
          incidents: action.data.map(incident => {
            const {
              caseId,
              customer,
              title,
              priority,
              status,
              state,
              type,
              totalElapsedTime,
              createdOn,
              modifiedOn
            } = incident;
            return {
              createdOn: moment(createdOn).format('YYYY-MM-DD HH:mm'),
              closedOn: incident.state !== 0 ? moment(modifiedOn).format('YYYY-MM-DD HH:mm') : '',
              caseNumber: caseId,
              customer,
              title,
              subject: action.subjects.find((subject) => subject.id === incident.subject),
              downtime: totalElapsedTime,
              priority,
              status,
              state,
              type
            };
          })
        }
      };
    case ACCOUNTS_REPORTS_DELETE_SERVICE_INCIDENT:
      return {
        ...state,
        serviceIncidents: {
          ...state.serviceIncidents,
          incidents: state.serviceIncidents.incidents.filter(incident => {
            return incident.caseNumber !== action.caseNumber;
          })
        }
      };
    case ACCOUNTS_REPORTS_SET_SERVICE_INCIDENT:
      return {
        ...state,
        serviceIncidents: {
          ...state.serviceIncidents,
          incidents: state.serviceIncidents.incidents.map(incident => {
            return incident.caseNumber === action.theCase.caseNumber ? action.theCase : incident;
          })
        }
      };
    case ACCOUNTS_REPORTS_SET_SERVICE_UTILISATION_SERVICES:
      return {
        ...state,
        serviceUtilisation: {
          ...state.serviceUtilisation,
          services: action.data
        }
      };
    case ACCOUNTS_REPORTS_DELETE_SERVICE_UTILISATION_SERVICE:
      return {
        ...state,
        serviceUtilisation: {
          ...state.serviceUtilisation,
          services: state.serviceUtilisation.services.filter(service => {
            return service.id !== action.id;
          })
        }
      };
    case ACCOUNTS_REPORTS_SET_SERVICE_UTILISATION_SERVICE:
      return {
        ...state,
        serviceUtilisation: {
          ...state.serviceUtilisation,
          services: state.serviceUtilisation.services.map(service => {
            return service.id === action.service.id ? action.service : service;
          })
        }
      };
    case ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_DATA:
      return {
        ...state,
        serviceAvailability: {
          ...state.serviceAvailability,
          incidents: action.data.map(incident => {
            const {
              caseId,
              customer,
              title,
              priority,
              state,
              totalhSoElapsedTime,
              totalSupplierElapsedTime,
              totalCustomerElapsedTime,
              totalElapsedTime,
              faultyDeviceId,
              createdOn,
              modifiedOn
            } = incident;
            return {
              caseNumber: caseId,
              createdOn: moment(createdOn).format('YYYY-MM-DD HH:mm'),
              closedOn: incident.state !== 0 ? moment(modifiedOn).format('YYYY-MM-DD HH:mm') : '',
              customer,
              title,
              totalElapsedTime: totalElapsedTime || 0,
              totalhSoAndSupplierElapsedTime: totalhSoElapsedTime + totalSupplierElapsedTime,
              totalCustomerElapsedTime: totalCustomerElapsedTime || 0,
              priority,
              state,
              faultyDeviceId
            };
          })
        }
      };
    case ACCOUNTS_REPORTS_DELETE_SERVICE_AVAILABILITY_INCIDENT:
      return {
        ...state,
        serviceAvailability: {
          ...state.serviceAvailability,
          incidents: state.serviceAvailability.incidents.filter(incident => {
            return incident.caseNumber !== action.caseNumber;
          })
        }
      };
    case ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_INCIDENT:
      return {
        ...state,
        serviceAvailability: {
          ...state.serviceAvailability,
          incidents: state.serviceAvailability.incidents.map(incident => {
            return incident.caseNumber === action.theCase.caseNumber ? action.theCase : incident;
          })
        }
      };
    case ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_CONFIG_ITEMS:
      return {
        ...state,
        serviceAvailability: {
          ...state.serviceAvailability,
          configItems: action.data.map(({ id, name, assignedTo }) => ({ id, name, assignedTo }))
        }
      };
    case ACCOUNTS_REPORTS_DELETE_SERVICE_AVAILABILITY_CONFIG_ITEM:
      return {
        ...state,
        serviceAvailability: {
          ...state.serviceAvailability,
          configItems: state.serviceAvailability.configItems.filter(configItem => {
            return configItem.id !== action.id;
          })
        }
      };
    case ACCOUNTS_REPORTS_RESET:
      return {
        ...initialState
      }
    default:
      return state;
  }
}
