import React, { Fragment } from 'react';
import numeral from 'numeral';
import ReactTable from 'react-table-v6';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

const defaultColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    minWidth: 300,
    Cell: props => (
            <a href={props.original.crmUrl} target="_blank" className={'text-primary text-wrap'}>{props.value}</a>
    )
  },
  {
    Header: 'Valid To',
    accessor: 'validTo',
    sortMethod: (a, b) => {
      a = a ? moment(a, 'YYYY-MM-DD') : moment().add(10, 'years');
      b = b ? moment(b, 'YYYY-MM-DD') : moment().add(10, 'years');
      return b.isBefore(a) ? 1 : -1;
    }
  },
  {
    Header: 'Billing Start',
    accessor: 'billStart'
  },
  {
    Header: 'Billing End',
    accessor: 'billEnd'
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: props => (
      '£' + numeral(props.value).format('0,0.00')
    )
  },
  {
    Header: 'In Credit',
    accessor: 'inCredit',
    width: 100,
    Cell: props => (
      props.value ? 'Yes' : 'No'
    )
  }
];
export const BillingItems = ({ items, columns }) => {
  return (
        <ReactTable
            className="animated fadeIn -highlight -striped"
            NoDataComponent={() => null}
            data={items}
            columns={columns || defaultColumns}
            minRows="2"
            defaultPageSize={10}
            filterable={items.length > 10}
            showPagination={items.length > 10}
            resizable={false}
            getTheadProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadTrProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadThProps={() =>{
              return {
                style: { border: 'none', whiteSpace: 'unset' }
              };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                style: { border: 'none', whiteSpace: 'unset', fontSize: '10pt' }
              };
            }}
            defaultSorted={[{ id: 'name', desc: false }]}
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
        />
  );
};
