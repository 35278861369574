import React, { Component } from 'react';

import {getShadowServerData} from "../../actions/shadowServer";
import {SpinnerInfo} from "../../components/Spinner/Spinner";
import {CardBody, CardHeader, Card, Row, Col, Badge} from "reactstrap";
import ReactTable from "react-table-v6";
import { connect, useDispatch } from 'react-redux'
import {
  Chart as ChartJS,
  Filler,
  ArcElement, Tooltip
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { getRandomColours } from '../../helpers/Colours';


import moment from 'moment';
import { useNavigate } from 'react-router-dom'

ChartJS.register(Filler, ArcElement, Tooltip);

class ShadowServerLegacy extends Component {
  componentDidMount() {

    if(moment().diff(moment(this.props.shadowServer.lastLoaded)) > 10800000) {
      this.props.dispatch(getShadowServerData(['0.0.0.0/0']))
    }
  }

  render() {
    if(this.props.shadowServer.loading === true) {
      return (<div className="row justify-content-center">
        <SpinnerInfo/>
      </div>);
    }
    const columns = [
      {
        id: 'ipaddress',
        filterable: true,
        sortable: true,
        Header: 'IP Address',
        accessor: 'ipaddress'
      },
      {
        id: 'routetype',
        filterable: true,
        sortable: true,
        Header: 'Type',
        accessor: (d) => {
          let code = '';
          if(d.interface.length > 0) {
            if(d.interface.filter(f => f.hostname !== 'LNS').length > 0) {
              code = code + 'i';
            }
            if(d.interface.filter(f => f.hostname === 'LNS').length > 0) {
              code = code + 'l';
            }
          }
          if(d.staticroute.length > 0) {
            code = code+'s';
          }
          return code
        },
        Cell: (d) => {
          return(
            <span>
              {d.original.interface.length !== 0 && d.original.interface.filter(f => f.hostname !== 'LNS').length > 0 &&
                <Badge color={"primary"}>Interface Route ({d.original.interface.filter(f => f.hostname !== 'LNS').length})</Badge>
              }{' '}
              {d.original.interface.length !== 0 && d.original.interface.filter(f => f.hostname === 'LNS').length > 0 &&
                <Badge color={'info'}>LNS ({d.original.interface.filter(f => f.hostname === 'LNS').length})</Badge>
              }{' '}
              {d.original.staticroute.length !== 0 &&
                <Badge color={"secondary"}>Static Route ({d.original.staticroute.length})</Badge>
              }
            </span>
          )
        }
      },
      {
        id: 'hsomanaged',
        filterable: true,
        sortable: true,
        Header: 'hSo Managed',
        accessor: (d) => {
          if(d.interface.length === 0) {
            return 0;
          }
          let match = 0;
          for(let i=0; i<=d.interface.length; i++) {
            if(d.interface[i]?.ipaddress === d.ipaddress) {
              match = 1;
            }
          }
          return match;
        },
        Cell: (d) => {
          return <span>{d.value > 0 && <Badge color={"warning"}>hSo</Badge>}</span>
        }
      },
      {
        id: 'count',
        filterable: false,
        sortable: true,
        Header: 'Issue Count',
        accessor: d => d.data.length
      }
    ];
    let hsomanaged = 0;
    let piedata = {
      labels: [],
      datasets: [
        {
          'label': 'Data',
          'data': [],
          backgroundColor: getRandomColours(30)
        }
      ],
    };
    this.props.shadowServer.data.forEach((d) => {
      hsomanaged = hsomanaged + d.interface.filter(i => i?.ipaddress === d.ipaddress).length;
      d.data.forEach((r) => {
        if(r['report'] !== 'device_id') {
          if (piedata.labels.indexOf(r['report']) === -1) {
            piedata.labels.push(r['report']);
            piedata.datasets[0].data.push(0);
          }
          piedata.datasets[0].data[piedata.labels.indexOf(r['report'])] += 1;
        }
      });
    });
    return(<Row>
      <Col>
        <Card  className="w-100 card-accent-primary">
          <CardHeader>ShadowServer Report for {this.props.shadowServer.latestDate}</CardHeader>
          <CardBody>
            <Row>
              <Col md={3}>
                <Card>
                  <CardBody className="p-0">
                    <i className="fa fa-info-circle bg-info p-4 font-2xl mr-3 float-left"></i>
                    <h5 className="mb-0 pt-3">{this.props.shadowServer.data.length}</h5>
                    <div className="text-muted text-uppercase font-weight-bold font-xs">Hosts</div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody className="p-0">
                    <i className="fa fa-warning bg-warning p-4 font-2xl mr-3 float-left"></i>
                    <h5 className="mb-0 pt-3">{hsomanaged}</h5>
                    <div className="text-muted text-uppercase font-weight-bold font-xs">hSo Managed</div>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>By Type</CardHeader>
                  <CardBody>
                    <Pie
                      data={piedata}
                      options={
                        {
                          plugins:
                            {
                              legend: {
                                display: false
                              },
                            }
                        }
                      }
                    />
                  </CardBody>
                </Card>

              </Col>
              <Col>
                <ReactTable
                  getTheadProps={() =>{
                    return {
                      style: { border: 'none' }
                    };
                  }}
                  getTheadTrProps={() =>{
                    return {
                      style: { border: 'none' }
                    };
                  }}
                  getTheadThProps={() =>{
                    return {
                      style: { border: 'none' }
                    };
                  }}
                  getTdProps={(state, rowInfo, column) =>{
                    return {
                      style: { border: 'none', fontWeight: column.Header === 'Name' ? 'bold' : 'regular' }
                    };
                  }}
                  getTrProps={(state, rowInfo) =>{
                    return {
                      style: { cursor: rowInfo ? 'pointer' : 'auto' },
                      onClick: () => {
                        this.props.navigate('/compliance/shadowserver/' + rowInfo.original.ipaddress);
                      }
                    };
                  }}
                  className="animated fadeIn -highlight -striped"
                  noDataText="No reported hosts found."
                  data={this.props.shadowServer.data}
                  columns={columns}
                  minRows="1"
                  filterable={false}
                  defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                  defaultSorted={[{ id: 'count', desc: true }]}
                  showPagination={this.props.shadowServer.data.length > 20}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>)
  }
}

const ShadowServer = ({shadowServer}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return <ShadowServerLegacy shadowServer={shadowServer} dispatch={dispatch} navigate={navigate} />
}

function mapStateToProps(state) {
  return {
    shadowServer: state.shadowServer
  };
}

export default connect(mapStateToProps)(ShadowServer);
