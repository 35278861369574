import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge } from 'reactstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import JSONPretty from 'react-json-pretty';
import JSONPretty1337 from 'react-json-pretty/dist/1337';
import { checkPermission, getAPIToken } from '../../utils/Auth/AuthService';
import moment from 'moment';
import { Environment } from '../../utils/Helpers/Environment';
import TesterProfile from '../TesterProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCopy} from "@fortawesome/free-regular-svg-icons";

var jwtDecode = require('jwt-decode');



const Aside = ({auth, toasts, BGP, pastes}) => {

  const [activeTab, setActiveTab] = useState('1');
  const [token, setToken] = useState();
  const [spinning, setSpinning] = useState();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    getAPIToken().then(tok => {
      setToken(jwtDecode(tok))
    })
  }, [])

  const getCalloutClass = (type) => {
    const colmap = {
      success: 'callout-success',
      warning: 'callout-warning',
      error: 'callout-danger'
    };
    if (type in colmap) {
      return colmap[type];
    }
    return 'callout-info';
  }
    return (
        <aside className="aside-menu">
          <Nav tabs>
              <NavItem>
                  <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1') }}>
                      <i className="icon-speech"></i><Badge pill>{toasts.length}</Badge>
                  </NavLink>
              </NavItem>
            <NavItem>
                <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2') }}>
                    <i className="icon-settings"></i>
                </NavLink>
            </NavItem>
              <NavItem>
                  <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3') }}>
                      <i className="icon-people"></i>
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5') }}>
                      <i className="fa fa-fw fa-copy"></i>
                  </NavLink>
              </NavItem>
            {
              (checkPermission('QA Testers', auth.account.permissions) && !Environment.isProduction())
              &&
              <NavItem>
                <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4') }}>
                  <i className="icon-equalizer"></i>
                </NavLink>
              </NavItem>
            }

          </Nav>
          <TabContent activeTab={activeTab}>
              <TabPane tabId="1" className="p-1">
                  <ListGroup>
                      {toasts.reverse().map((toast, idx) => <div className={'callout ' + getCalloutClass(toast.msgtype) + ' m-0 py-3'} key={'co_' + idx}>
                              <small className="text-muted">{moment(toast.timestamp).format()}</small>
                              <div>{toast.title}</div>
                              <small>{toast.msg}</small>
                              {toast.link
                              && <p><small><Link to={toast.link} target="_blank"><i className="icon-link"></i></Link></small></p>
                              }
                          </div>)}
                  </ListGroup>
              </TabPane>
            <TabPane tabId="2" className="p-1">
                <ListGroup>
                    {(checkPermission('NOC', auth.account.permissions) || checkPermission('FieldOps', auth.account.permissions))
                    && <ListGroupItem style={{ fontSize: '10px' }}>
                        <ListGroupItemHeading>Last BGP LS Update</ListGroupItemHeading>
                        <ListGroupItemText>
                            {BGP.lastUpdate !== null
                            && moment(BGP.lastUpdate).format()
                        }
                        </ListGroupItemText>
                    </ListGroupItem>
                    }
                  <ListGroupItem style={{ fontSize: '10px' }}>
                    <ListGroupItemHeading>API Endpoint</ListGroupItemHeading>
                    <ListGroupItemText>{process.env.REACT_APP_API_URL}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem style={{ fontSize: '10px' }}>
                    <ListGroupItemHeading>OAuth2 Token</ListGroupItemHeading>
                    <JSONPretty id="json-pretty" data={token} theme={JSONPretty1337}></JSONPretty>
                  </ListGroupItem>
                  <ListGroupItem style={{ fontSize: '10px' }}>
                    <ListGroupItemHeading>Last Portal Activity</ListGroupItemHeading>
                    <p>{moment(new Date(auth.lastActivity * 1000))
                      .format('YYYY-MM-DD HH:mm:ss')}</p>
                  </ListGroupItem>
                </ListGroup>
            </TabPane>
              <TabPane tabId="3" className="p-1">
                  <ul style={{ fontSize: '10px', listStyle: 'none', paddingLeft: '14px' }}>
                      {auth.account.permissions.sort().map((perm, index) => <li key={index}>{perm}</li>)}
                  </ul>
              </TabPane>
            {
              (checkPermission('QA Testers', auth.account.permissions) && !Environment.isProduction())
              &&
              <TabPane tabId="4" className="p-1">
                <TesterProfile/>
              </TabPane>
            }
              <TabPane tabId="5" className="p-1">
                  <ListGroup>
                      {pastes.map((paste, idx) => {
                          return (
                              <div className={'callout m-0 py-3'} key={'paste_' + idx}>
                                  <div><FontAwesomeIcon icon={faCopy} spin={spinning === idx} onClick={() => {
                                      setSpinning(idx);
                                      navigator.clipboard.writeText(paste.data);
                                      setTimeout(setSpinning.bind(null, undefined), 1000);
                                  }}/> {paste.data}</div>
                              </div>
                          );
                      })}
                  </ListGroup>
              </TabPane>

          </TabContent>
        </aside>
    );
}

function mapStateToProps(state) {
    return {
        auth: state.authenticationState,
        BGP: state.BGP,
        toasts: state.socketioToast.messages.isEmpty ? [] : state.socketioToast.messages.toArray(),
        pastes: state.socketioPaste.pastes.isEmpty ? [] : state.socketioPaste.pastes.toArray()
  };
}

export default connect(mapStateToProps)(Aside);
