import { LOGICAL_SERVICE_TYPE_SET_FIELDS } from '../../actions/ServiceDB/logicalServiceType';

const initialState = {
  fields: {}
};
const logicalServiceType = (state = initialState, action) => {
  switch (action.type) {
    case LOGICAL_SERVICE_TYPE_SET_FIELDS:
      return {
        ...state,
        fields: action.fields
      };
    default:
      return state;
  }
};

export default logicalServiceType;