export const BREADCRUMBS_SET_ITEMS = 'BREADCRUMBS_SET_ITEMS';
export const BREADCRUMBS_RESET_ITEMS = 'BREADCRUMBS_RESET_ITEMS';


export const addBreadcrumbs = (items) => {
  return {
    type: BREADCRUMBS_SET_ITEMS,
    items
  }
}

export const resetBreadcrumbs = () => {
  return {
    type: BREADCRUMBS_RESET_ITEMS
  }
}
