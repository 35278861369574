import { defaultErrorFeedback } from './feedback';
import BillTemplateGroupService from '../utils/BillTemplateGroup';

export const BILL_TEMPLATE_GROUP_SET_DATA = 'BILL_TEMPLATE_GROUP_SET_DATA'

export const BILL_TEMPLATE_GROUP_SET_FORM_FIELD = 'BILL_TEMPLATE_GROUP_SET_FORM_FIELD'

export const BILL_TEMPLATE_GROUP_RESET = 'BILL_TEMPLATE_GROUP_RESET'
export const BILL_TEMPLATE_GROUP_REMOVE_PRODUCT = 'BILL_TEMPLATE_GROUP_REMOVE_PRODUCT'
export const BILL_TEMPLATE_GROUP_REMOVE_PRODUCT_FROM_ORIGINAL = 'BILL_TEMPLATE_GROUP_REMOVE_PRODUCT_FROM_ORIGINAL'

export const updateBillTemplateGroupFormField = (field) => {
  return {
    type: BILL_TEMPLATE_GROUP_SET_FORM_FIELD,
    field
  }
}

export const resetBillTemplateGroup = () => {
  return {
    type: BILL_TEMPLATE_GROUP_RESET
  }
}

export const removeBillTemplateGroupProduct = (id) =>{
  return {
    type: BILL_TEMPLATE_GROUP_REMOVE_PRODUCT,
    id
  }
}

export const removeBillTemplateGroupProductFromOriginal = (id) =>{
  return {
    type: BILL_TEMPLATE_GROUP_REMOVE_PRODUCT_FROM_ORIGINAL,
    id
  }
}
export function getBillTemplateGroup(templateId, id) {
  return (dispatch) =>{
    return BillTemplateGroupService.get(templateId, id).then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATE_GROUP_SET_DATA, data: result.data});
      }else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

export function createBillTemplateGroup(templateId, data) {
  return (dispatch) =>{
    return BillTemplateGroupService.create(templateId, data).then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATE_GROUP_SET_DATA, data: result.data});
        return result.data
      }else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}
export function updateBillTemplateGroup(templateId, groupId, data) {
  return (dispatch) =>{
    return BillTemplateGroupService.update(templateId, groupId, data).then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATE_GROUP_SET_DATA, data: result.data});
      }else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

export function deactivateBillTemplateGroup(templateId, groupId) {
  return (dispatch) =>{
    return BillTemplateGroupService.deactivate(templateId, groupId).then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATE_GROUP_SET_DATA, data: result.data});
        return result.data
      }else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

export function restoreBillTemplateGroup(templateId, groupId) {
  return (dispatch) =>{
    return BillTemplateGroupService.restore(templateId, groupId).then((result) => {
      if (result.status === 200) {
        dispatch({type: BILL_TEMPLATE_GROUP_SET_DATA, data: result.data});
        return result.data
      }else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}
