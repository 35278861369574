import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { cancelDialogue } from '../../actions/dialogs';
import { connect } from 'react-redux';

class Dialogue extends Component {
    cancel = () =>{
      this.props.dispatch(cancelDialogue());
    }

    render() {
      const {
        text,
        color,
        title,
        noToggle
      } = this.props.dialogue;
      return (
        <Modal isOpen={this.props.dialogue.open} className={'modal-' + color} backdrop={'static'}>
          <ModalHeader toggle={noToggle ? () => {} : this.cancel}>
            {title}
          </ModalHeader>

          <ModalBody>
            <p>{text}</p>
          </ModalBody>
        </Modal>
      );
    }
}

function mapStateToProps({ dialogs }) {
  return {
    dialogue: dialogs.dialogue
  };
}
export default connect(mapStateToProps)(Dialogue);
