import numeral from 'numeral';
import moment from 'moment/moment';
import React, { useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import BillArtifactsModal from '../../Modals/BillArtifactsModal';
import { api_downloadBillArtifacts } from '../../../utils/Billing/BillingService';
import fileDownload from 'js-file-download';
import { defaultErrorFeedback } from '../../../actions/feedback';
import { Button } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { ButtonIcon } from '../../ButtonIcon';
import omit from 'lodash.omit';
import { connect } from 'react-redux';

const BillsTable = ({
  bills,
  fetchData,
  dispatch
}) => {
  const [showModal, setModal] = useState(false);
  const [modalArtifacts, setModalArtifacts] = useState([]);
  const [billId, setBillId] = useState('');
  const toggleModal = () => {
    setModal(!showModal);
  };
  const showArtifacts = (artifacts, modalBillId) => {
    setModalArtifacts(artifacts);
    setBillId(modalBillId);
    toggleModal();
  };

  const download = (id, documentId) => {
    api_downloadBillArtifacts(id, documentId).then((result) => {
      if(result.status === 200){
        const splitContentDisposition = result.headers['content-disposition'].split('=');
        fileDownload(result.data,  splitContentDisposition[1]);
      } else{
        dispatch(defaultErrorFeedback());
      }
    });
  }
  const columns = [
    {
      header: 'Invoice Id',
      accessorKey: 'id'
    },
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Total Value',
      accessorKey: 'totalValue',
      cell: props => {
        return '£' + numeral(props.getValue())
          .format('0,0.00');
      }
    },
    {
      header: 'Date',
      accessorKey: 'date',
      cell: props => {
        return moment(props.getValue())
          .format('DD/MM/YYYY');
      }
    },
    {
      header: 'Payment Method',
      accessorKey: 'payment_method'
    },
    {
      header: 'Credit',
      accessorKey: 'credit',
      cell: props => {
        const credit = props.getValue();
        return credit || credit === 0 ? '£' + numeral(credit)
          .format('0,0.00') : '';
      }
    },
    {
      header: 'To Pay',
      accessorKey: 'toPay',
      cell: props => {
        const toPay = props.getValue();
        return toPay || toPay === 0 ? '£' + numeral(toPay)
          .format('0,0.00') : '';
      }

    },
    {
      header: 'Files',
      accessorKey: 'artifacts',
      cell: props => {
        return props.getValue() ? (
          <div
            className="text-info"
            style={{ cursor: 'pointer' }}
            onClick={()=>{ showArtifacts(props.getValue(), props.row.original.id); }}
          ><i className="fa fa-download"></i></div>
        ) : '';
      }
    }
  ];
  const getExportData = () => {
    return bills.map(bill => omit(bill, ['artifacts']))
  }

  return (
    <>
      <FilteredTableV2
        withFilter={'includesString'}
        minRows={10}
        columns={columns}
        data={bills}
        noDataText="No account bills"
        showPagination={bills.length > 20}
        defaultPageSize={20}
        defaultSorted={[{
          id: 'id',
          desc: true
        }]}
        extraButtons={[
          <div data-for={'export'} data-tip={'export'} >
            <CSVLink id={'export'}
                     data={getExportData()} filename={`contacts-${moment().format('DD-MM-YYYY')}.csv`}
                     style={{ textDecoration: 'none' }}>

              <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
            </CSVLink>
          </div>,

          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
        ]}
      />
      <BillArtifactsModal toggle={toggleModal} isOpen={showModal} billId={billId}
                          modalArtifacts={modalArtifacts}
                          handleDownload={download}></BillArtifactsModal>
    </>

  )
}

export default connect()(BillsTable)