import {apiRequest} from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';
import isEmpty from 'lodash.isempty';

export const ConnectionService = {
    getConnections: (page, pageSize, search, sorting, include, withs, portWith, nniWith) => {
        let query = {
            sortedBy: !isEmpty(sorting) ? (sorting[0].desc ? 'desc' : 'asc') : null,
            orderBy: !isEmpty(sorting) ? sorting[0].id : null,
            page,
            pageSize,
            search,
            include,
            with: withs,
            portWith,
            nniWith
        };
        if(isEmpty(search)){

            delete query.search
        }
        return apiRequest({
            url: `/sdb/connections${queryStringFromFields(query)}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    getConnection: (id, include, withs, portWith, nniWith) => {
        return apiRequest({
            url: `/sdb/connections/${id}${queryStringFromFields({ include, with: withs, portWith, nniWith })}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    createConnection: (data, include, withs, portWith, nniWith) => {
        return apiRequest({
            url: `/sdb/connections${queryStringFromFields({ include, with: withs, portWith, nniWith })}`,
            method: 'post',
            data
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    updateConnection: (id, data, include, withs, portWith, nniWith) => {
        return apiRequest({
            url: `/sdb/connections/${id}${queryStringFromFields({ include, with: withs, portWith, nniWith })}`,
            method: 'put',
            data
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    deleteConnection: (id) => {
        return apiRequest({
            url: `/sdb/connections/${id}`,
            method: 'delete'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    syncConnection: (id) => {
        return apiRequest({
            url: `/sdb/connections/${id}/sync`,
            method: 'post'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    }

};
