import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import ConnectionsTable from '../../../components/Tables/Connections';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { getConnections } from '../../../actions/connections';
import debounce from 'debounce-promise';
import { useNavigate } from 'react-router-dom'

const Connections = ({
  table,
  connections
}) => {

  // router
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }


  useEffect(() => {
    fetchData(page, pageSize, '', [{id: 'name', desc:false}]);
  }, []);

  const fetchData = (page, size, searchString, sorting) => {
    const includes = ['items.aEnd', 'items.bEnd', 'items.aEnd.port.deviceDeployment', 'items.aEnd.deviceDeployment', 'items.bEnd.port.deviceDeployment', 'items.bEnd.deviceDeployment'].join(',');
    const withs = ['aEnd', 'bEnd'].join(';');
    const portWiths = ['deviceDeployment'].join(';');
    const nniWiths = ['port', 'port.deviceDeployment'].join(';');
    toggleLoading()
    dispatch(getConnections(page, pageSize, searchString, sorting, includes, withs, portWiths, nniWiths))
      .then(() => toggleLoading());
  };

  const debounced = debounce((page, size, searchString, sorting) => fetchData(page, size, searchString, sorting), 500);

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <ConnectionsTable
              connections={connections}
              table={table}
              fetchData={(page, size, searchString, sorting) => debounced(page, size, searchString, sorting)}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  );
};

function mapStateToProps({ connections }) {
  return {
    connections: connections.connections,
    table: connections.table
  };
}

export default connect(mapStateToProps)(Connections);
