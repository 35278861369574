import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const CrossConnectService = {
  getCrossConnects: (include = []) => {
    return apiRequest({
      url: `/sdb/connections/cross-connects${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getCrossConnectsAvailable: (include = []) => {
    return apiRequest({
      url: `/sdb/connections/cross-connects/available${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getCrossConnect: (id, include = []) => {
    return apiRequest({
      url: `/sdb/connections/cross-connects/${id}${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  createCrossConnect: (data, include = []) => {
    return apiRequest({
      url: `/sdb/connections/cross-connects${queryStringFromFields({ include })}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  updateCrossConnect: (id, data, include = []) => {
    return apiRequest({
      url: `/sdb/connections/cross-connects/${id}${queryStringFromFields({ include })}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  deleteCrossConnect: (id) => {
    return apiRequest({
      url: `/sdb/connections/cross-connects/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }

};