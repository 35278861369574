import update from "immutability-helper";
import {
  NETAPI_SET_LOADING_STATE, NETAPI_SET_DEVICES_DATA, NETAPI_SET_RRGROUPS_DATA, NETAPI_SET_NETWORKS_DATA
} from "../actions/netapi";

const initialState = {
  devices: {
    loading: 0,
    data: []
  },
  rrgroups: {
    loading: 0,
    data: []
  },
  networks: {
    loading: 0,
    data: []
  }
};

export default function netapi(state = initialState, action) {
  switch (action.type) {
    case NETAPI_SET_LOADING_STATE:
      return update(state, {
        [action.item]: { loading: { $set: action.state } }
      });
    case NETAPI_SET_DEVICES_DATA:
      return update(state, {
        devices: {
          data: { $set: action.data }
        }
      });
    case NETAPI_SET_RRGROUPS_DATA:
      return update(state, {
        rrgroups: {
          data: { $set: action.data }
        }
      });
    case NETAPI_SET_NETWORKS_DATA:
      return update(state, {
        networks: {
          data: { $set: action.data }
        }
      });
    default:
      return state;
  }
}