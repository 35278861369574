import {apiRequest} from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const DeviceModelService = {
    getDeviceModels: (search, include, withs) => {
        return apiRequest({
            url: `/sdb/device-models${queryStringFromFields({ search, include, with: withs })}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    createDeviceModel: (data, include, withs) => {
        return apiRequest({
            url: `/sdb/device-models${queryStringFromFields({ include, with: withs })}`,
            method: 'post',
            data
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    getDeviceModel: (id, include, withs) => {
        return apiRequest({
            url: `/sdb/device-models/${id}${queryStringFromFields({ include, with: withs })}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    updateDeviceModel: (id, data, include, withs) => {
        return apiRequest({
            url: `/sdb/device-models/${id}${queryStringFromFields({ include, with: withs })}`,
            method: 'put',
            data
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    deleteDeviceModel: (id) => {
        return apiRequest({
            url: `/sdb/device-models/${id}`,
            method: 'delete'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    }
};