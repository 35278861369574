const RackForm = () => {
  return {
    name: {
      label: 'Name',
      type: 'text',
      mandatory: true,
      width: 12
    },
    size: {
      label: 'Size',
      type: 'number',
      mandatory: true,
      min: 1,
      width: 12
    },
    hsoManaged: {
      label: 'Managed by HSO?',
      type: 'select-boolean',
      mandatory: true,
      width: 12
    },
    site: {
      label: 'Location',
      entity: 'site',
      type: 'select-async',
      mandatory: true,
      placeholder: 'Search by name or account number...',
      optionLabelCallback: (opt) => `${opt.name} (${opt.accountnumber})`,
      width: 12
    },
    floor: {
      label: 'Floor',
      entity: 'site',
      type: 'text',
      mandatory: false,
      width: 12
    },
    room: {
      label: 'Room',
      entity: 'site',
      type: 'text',
      mandatory: false,
      width: 12
    }
  }
}

export default RackForm;