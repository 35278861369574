import {
  SET_BILLING_PREVIEW_DATE,
  SET_BILLING_PREVIEW_SEARCH_OPTIONS,
  SET_BILLING_PREVIEW_ACCOUNT,
  SET_BILLING_PREVIEW_BILLS,
  SET_BILLING_PREVIEW_LOADING,
  SET_BILLING_PREVIEW_LAST_BILLED_DATE,
  SET_BILLING_PREVIEW_ERROR
} from '../actions/billingPreview';

const initialState = {
  account: null,
  billingDate: null,
  lastBilledDate: null,
  searchOptions: [],
  bills: [],
  loading: false,
  error: null
};

export default function billingPreview(state = initialState, action) {
  switch (action.type) {
    case SET_BILLING_PREVIEW_DATE:
      return {
        ...state,
        billingDate: action.date
      };
    case SET_BILLING_PREVIEW_LAST_BILLED_DATE:
      return {
        ...state,
        lastBilledDate: action.date
      };
    case SET_BILLING_PREVIEW_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: action.searchOptions
      };
    case SET_BILLING_PREVIEW_ACCOUNT:
      return {
        ...state,
        account: action.account
      };
    case SET_BILLING_PREVIEW_BILLS:
      return {
        ...state,
        bills: action.bills
      };
    case SET_BILLING_PREVIEW_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case SET_BILLING_PREVIEW_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
