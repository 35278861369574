import {
  LEADS_ADD_LEAD_TO_LIST,
  LEADS_REMOVE_LEAD_FROM_LIST, LEADS_REPLACE_LEAD_IN_LIST,
  LEADS_SET_LEADS
} from '../actions/leads';

const initialState = {
  list: []
};

export function leads(state = initialState, action) {

  switch (action.type) {
    case LEADS_SET_LEADS:
      return {
        ...state,
        list: [...action.leads]
      };
    case LEADS_REMOVE_LEAD_FROM_LIST:
      return {
        ...state,
        list: state.list.filter(lead => lead.id !== action.lead.id),
      };
    case LEADS_ADD_LEAD_TO_LIST:
      return {
        ...state,
        list: [...state.list, action.lead]
      };
    case LEADS_REPLACE_LEAD_IN_LIST:
      return {
        ...state,
        list: state.list.map(lead => {
          if (lead.id === action.lead.id) {
            return action.lead;
          }
          return lead;
        })
      };
    default:
      return state;
  }
}
