export default {
  subject: {
    label: 'Subject',
    type: 'text',
    mandatory: true
  },
  relatedTo: {
    label: 'Related To',
    type: 'text',
    clickable: true,
    readOnly: true,
    isDisabled: (data) => !generateUrl(data),
    callBack: (key, field, data) => callBack(key, field, data)
  },
  type: {
    label: 'Type',
    type: 'select',
    mandatory: true
  },
  priority: {
    label: 'Priority',
    type: 'select',
    mandatory: true
  },
  statusReason: {
    label: 'Status',
    type: 'select',
    mandatory: true,
    readOnly: (key, field, data) => !data.id
  },
  stallReason: {
    label: 'Stall Reason',
    type: 'select',
    readOnly: (key, field, data) => !data.id
  },
  description: {
    label: 'Description',
    type: 'textarea',
    width: 12,
    mandatory: true,
    max:2000
  }

};

const callBack = (key, field, data) => {
  if (key === 'relatedTo') {
    return data[key]?.data !== undefined ? data[key].data.name ?? data[key].data.subject : '';
  }
  return null;
};

const generateUrl = (data) => {
  if(!data.relatedTo?.type){
    return null
  }
  if(data.relatedTo.type === 'order'){
    return `/sales/orders/${data.relatedTo.data.id}`
  }
  if(data.relatedTo.type === 'opportunity'){
    return `/sales/opportunities/${data.relatedTo.data.id}`
  }

  return null
}



