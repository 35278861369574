import { apiRequest } from '../Api/ApiRequest';

export function api_getServiceDbLogicalServiceTypes() {
  return apiRequest({
    url: '/sdb/services',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getServiceDbLogicalServiceTypeFields(serviceTypeName) {
  return apiRequest({
    url: `/sdb/services/${serviceTypeName}/fields`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
