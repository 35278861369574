import React, { Component } from 'react';
import { Alert, UncontrolledCollapse, Button, Card, CardBody, CardHeader, CardText, Row, Col, Progress, Form,
  InputGroup, InputGroupAddon, Badge, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
  Table } from 'reactstrap';
import { getJobStatus } from '../../utils/DeviceCheck/DeviceCheckService';
import Reward from 'react-rewards';

class DeviceGroupResult extends Component {
  render() {
    const severities = [
      'Unknown', 'Information', 'Warning', 'Average', 'High', 'Disaster'
    ];
    const sevcolors = [
      '#AEAEAE',
      '#D6F6FF',
      '#FFF6A5',
      '#FFB689',
      '#FF9999',
      '#FF3838'
    ];
    switch (this.props.rmethod) {
      case 'oxidized':
        if (this.props.result === true) {
          return (<div style={{ paddingLeft: '36px' }}>
                        <Row><Col>Device is in Oxidized</Col><Col>Yes!</Col></Row>
                        <Row><Col>Last Collection</Col><Col>{this.props.data.last != null ? this.props.data.last.end : 'Never'}</Col></Row>
                        <Row><Col>Successful Collection</Col>
                            <Col>{this.props.data.last != null && this.props.data.last.status === 'success'
                              ? <Badge color="success">OK</Badge> : <Badge color="danger">Failed</Badge>}
                            </Col>
                        </Row>
                    </div>);
        }
        return (<p>Host not in Oxidized</p>);
      case 'list':
        if (this.props.data !== undefined) {
          if (typeof this.props.data === 'string') {
            return (
                            <ul style={{ paddingLeft: '36px' }}><li>{this.props.data}</li></ul>
            );
          }
          return (<ul style={{ paddingLeft: '36px' }}>{this.props.data.map((tp) => {
            return (<li key={tp}>{tp}</li>);
          })}</ul>);
        }
        break;
      case 'alarmlist':
        if (this.props.data !== undefined) {
          return (<div style={{ paddingLeft: '36px' }}>{this.props.data.map((al, index) => {
            return (<Row key={index}><Col md={1}><Badge
                            style={{ backgroundColor: sevcolors[al.priority] }}>{severities[al.priority]}</Badge></Col><Col>{al.description}</Col></Row>);
          })}</div>);
        }
        break;
      case 'statuslist':
        if (this.props.data !== undefined) {
          return (<div style={{ paddingLeft: '36px' }}>
                        {Object.entries(this.props.data).map(([key, value], index) => {
                          return (
                                <Row key={index}><Col>{key}</Col><Col>{value.result === true ? <Badge color="success">OK</Badge>
                                  : <span><Badge color="danger">Failed</Badge> {value.data}</span>}</Col></Row>);
                        })}
                    </div>);
        }
        break;
      case 'crmcicheck':
        if (this.props.result === false) {
          return (<p>{this.props.data}</p>);
        }

        if (this.props.data !== undefined) {
          return (<div style={{ paddingLeft: '36px' }}>
                            <Row><Col><b>Status</b></Col><Col>{this.props.data['statuscode@OData.Community.Display.V1.FormattedValue']}</Col></Row>
                            <Row><Col><b>Serial Number</b></Col><Col>{this.props.data.new_serialnumber}</Col></Row>
                            <Row><Col><b>Type</b></Col><Col>{this.props.data['sam7_citype@OData.Community.Display.V1.FormattedValue']}</Col></Row>
                        </div>);
        }
        break;
      case 'cihascircuits':
        if (this.props.data !== undefined) {
          if (this.props.result === true) {
            let elm = [];
            elm.push(<thead key={'mofos'}><tr><th><b>Internal Ref.</b></th><th><b>Type</b></th><th><b>A End</b></th><th><b>B End</b></th><th><b>Speed</b></th><th><b>Name</b></th><th><b>Carrier Ref.</b></th></tr></thead>);
            let rows = [];
            for (let i = 0; i < this.props.data.length; i++) {
              rows.push(<tr key={'ri' + i}><td>{this.props.data[i].internalref}</td><td>{this.props.data[i].type}</td><td>{this.props.data[i].aend}</td><td>{this.props.data[i].bend}</td><td>{this.props.data[i].cdr ? this.props.data[i].cdr : <i className="icon-question"></i>}{this.props.data[i].cdrunit} on {this.props.data[i].interface ? this.props.data[i].interface : <i className="icon-question"></i>}{this.props.data[i].interfaceunit}</td><td>{this.props.data[i].name}</td><td>{this.props.data[i].carrierref}</td></tr>);
            }
            elm.push(<tbody key={'brow'}>{rows}</tbody>);
            return (<div style={{ paddingLeft: '36px' }}><Table striped>{elm}</Table></div>);
          }
          return (<p style={{ paddingLeft: '36px' }}>No circuits found</p>);
        }
        break;
      default:
        if (typeof this.props.data === 'undefined') {
          return (<p style={{ paddingLeft: '36px' }}>No data</p>);
        }

        if (typeof this.props.data === 'string') {
          return (<p style={{ paddingLeft: '36px' }}>{this.props.data}</p>);
        }

        if (typeof this.props.data === 'object') {
          return (
                        <div style={{ paddingLeft: '36px' }}>{Object.entries(this.props.data).map(([key, value], idx) => {
                          return (<div key={'f' + idx}><Row><Col><b>{key}</b></Col><Col>{typeof value === 'string' ? value : 'Object'}</Col></Row></div>);
                        })}</div>
          );
        }
        return (<p style={{ paddingLeft: '36px' }}>Don't know how to render this: {typeof this.props.data}</p>);
    }
    console.log(this.props);
    return null;
  }
}

class DeviceResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 0,
      res: {},
      tfails: 0
    };
  }

  componentDidMount() {
    getJobStatus(this.props.uuid).then((r2) => {
      let fails = 0;
      Object.entries(r2.data.meta).map(([group, results], index)=> {
        Object.entries(results).map(([key, value], index2) => {
          if (value.result !== true) {
            fails += 1;
          }
        });
      });
      this.setState({ res: r2.data.meta, state: 2, tfails: fails });
    }).catch((error) => {
      this.setState({
        state: 0,
        processing: false,
        errors: {
          errMessage: 'Fatal Error (' + error.status + ') ' + error.statusText
        }
      });
    });
  }

  componentDidUpdate() {
    if (this.state.state === 2 && this.state.res != null && this.state.tfails === 0) {
      this.rewards = 0;
      this.interval = setInterval(() => {
        this.rewards++;
        Reward.rewardMe();
        if (this.rewards > 4) {
          clearInterval(this.interval);
        }
      }, 500);
    }
  }

  render() {
    if (this.state.state !== 2 || this.state.res == null) {
      return (<Alert color="danger">Invalid Request</Alert>);
    }
    let content = [];
    let rows = [];
    let tfails = 0;
    Object.entries(this.state.res).map(([group, results], index)=> {
      let tr = [];
      let fails = 0;
      let total = 0;
      Object.entries(results).map(([key, value], index2) => {
        total += 1;
        let color = '';
        let icon = 'icon-check';
        let ic = '#448D38';
        if (value.result !== true) { color = 'danger'; ic = '#FF5452'; icon = 'icon-close'; fails += 1; }
        let ttitle = key;
        if ('title' in value) {
          ttitle = value.title;
        }

        tr.push(
                    <ListGroupItem color={color} key={key}>
                        <ListGroupItemHeading><i className={icon} style={{ color: ic, marginRight: '8px' }}></i> {ttitle}</ListGroupItemHeading>
                        <DeviceGroupResult {...value} testName={key}/>
                    </ListGroupItem>
        );
      });
      tfails += fails;
      let tog = 'toggler_' + group;
      let color = 'success';
      let defopen = false;
      if (fails > 0) { color = 'danger'; defopen = true; }
      rows.push(
                <Col key={group}>
                    <Card>
                        <CardHeader><Row><Col md={10}>{group}</Col><Col md={1}><p style={{ textAlign: 'right', margin: 0, padding: 0, color: '#808080' }}>Failed: <Badge color={color}>{fails}/{total}</Badge></p></Col><Col md={1}><p style={{ textAlign: 'right', margin: 0, padding: 0 }}><Button color="link" id={tog} style={{ margin: '0', padding: 0 }}>
                            <i className="icon-menu"></i>
                        </Button></p></Col></Row></CardHeader>
                        <UncontrolledCollapse toggler={'#' + tog} key={tog}>
                            <CardBody><ListGroup>{tr}</ListGroup></CardBody>
                        </UncontrolledCollapse>
                    </Card>
                </Col>
      );
      content.push(<Row key={'k' + index}>{rows}</Row>);
      rows = [];
    });
    content.push(<Row key={'pr'}>{rows}</Row>);

    return (
                 <Card className="card-accent-primary">
                    <CardHeader>{this.state.res.Monitoring.zabbixhost.data} Results</CardHeader>
                     <CardBody>
                        {content}
                         <div style={{ textAlign: 'center', zIndex: 999999 }}>
                             <Reward
                                 ref={(ref) => { this.reward = ref; }}
                                 type='emoji'
                                 lifetime={3000}
                                 spread={360}
                                 size={80}
                                 zIndex={999999999999999}
                             ><span>&nbsp;</span></Reward></div>
                     </CardBody>
                </Card>
    );
  }
}

export default DeviceResult;
