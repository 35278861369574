import { connect, useDispatch } from 'react-redux'
import {
  Button, Form,
  Input, InputGroup, InputGroupAddon,
  Nav,
  NavbarBrand,
  NavbarToggler
} from 'reactstrap';
import HeaderDropdown from './HeaderDropdown';
import HeaderZabbixAlarms from './HeaderZabbixAlarms';
import { checkPermission } from '../../utils/Auth/AuthService';
import {setGlobalSearchPattern } from '../../actions/globalSearch';
import { useNavigate } from 'react-router-dom'

const Header = ({auth, pattern}) =>{
  // redux
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  const sidebarMinimize = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  const asideToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/global-search')
  }

  return (
    <header className={ `${process.env.REACT_APP_HEADER_CLASS !== undefined ? process.env.REACT_APP_HEADER_CLASS : ''} app-header navbar`}>
      <Nav className="mr-auto">
        <NavbarToggler className="d-lg-none" onClick={mobileSidebarToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
        <NavbarBrand href="#" className={`${process.env.REACT_APP_HEADER_CLASS !== undefined ? process.env.REACT_APP_HEADER_CLASS : ''} d-md-down-none`}></NavbarBrand>
        <NavbarToggler className="d-md-down-none" onClick={sidebarMinimize}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
      </Nav>
      <Nav className="mx-auto" navbar>
        {(checkPermission('NOC', auth.account.permissions) || checkPermission('FieldOps', auth.account.permissions))
        && <HeaderZabbixAlarms/>
        }
      </Nav>
      <Nav className="ml-auto">
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Input
              className="bg-secondary border-0 shadow-none"
              value={pattern}
              onChange={(e) => dispatch(setGlobalSearchPattern(e.target.value))}
              type={'text'}
            />
            <InputGroupAddon addonType={"append"}>
              <Button
                onClick={handleSubmit}
                className={"shadow-none"}
              >
                <i className={ "fa fa-search"}/>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>

      </Nav>

      <Nav>
        <HeaderDropdown accnt/>
        <NavbarToggler className="d-md-down-none" onClick={asideToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
      </Nav>
    </header>
  );
}


function mapStateToProps({ globalSearch, authenticationState }) {
  return {
    auth: authenticationState,
    pattern: globalSearch.pattern
  };
}

export default connect(mapStateToProps)(Header);
