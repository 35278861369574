import { IPAM_DOMAINS_SET_DATA } from '../../../actions/ServiceDB/Ipam/domains';

const initialState = {
  domains: []
};
const domains = (state = initialState, action) => {
  switch (action.type) {
    case IPAM_DOMAINS_SET_DATA:
      return {
        ...state,
        domains: action.data
      }
    default:
      return state;
  }
};

export default domains;