import { LOGICAL_SERVICES_SET_DATA } from '../../actions/ServiceDB/logicalServices';

const initialState = {
  logicalServices: []
};
const logicalServices = (state = initialState, action) => {
  switch (action.type) {
    case LOGICAL_SERVICES_SET_DATA:
      return {
        ...state,
        logicalServices: action.data
      }
    default:
      return state;
  }
};

export default logicalServices;