import {
  Badge,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import LoadingOverlay from '../../components/LoadingOverlay';
import FilteredTable from '../../components/FilteredTable';
import ExtraButtons from '../../utils/Helpers/ExtraButtons';
import React, { useEffect, useState } from 'react';
import { getAllPriceLists, setPriceListsSearchString } from '../../actions/priceLists';
import { BorderedText } from '../../components/BorderedText';
import moment from 'moment';
import FilteredTableV2 from '../../components/FilteredTableV2';
import { ButtonIcon } from '../../components/ButtonIcon';
import { useNavigate } from 'react-router-dom'
import ProductTemplatesTable from '../../components/Tables/ProductTemplates';
import PriceListsTable from '../../components/Tables/PriceLists';

const PriceLists = ({
  priceLists
}) => {

  // router
  const navigate = useNavigate()

  // redux
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
      setLoading(true)
      dispatch(getAllPriceLists()).then(() => {
        setLoading(false)
      })
  }
  const isFiltered = () => {
    return Boolean(priceLists.searchString);
  };

  const resolveStatusColour = (status) => {
    switch (status){
      case 'Active':
        return 'success'
      case 'Draft':
        return 'warning'
      default:
        return 'danger'
    }
  }

  const resolveTypeColour = (type) => {
    switch (type){
      case 'enterprise':
        return 'primary'
      case 'wholesale':
        return 'success'
      default:
        return 'info'
    }
  }

  const onChangeSearch = (e) => {
    dispatch(setPriceListsSearchString(e.target.value));
  };
  const columns = [
    {
      filterable: false,
      Header: 'Name',
      accessor: 'name'
    },
    {
      filterable: false,
      Header: 'Account',
      accessor: 'company.name'
    },
    {
      filterable: false,
      Header: 'Type',
      accessor: 'type.name',
      Cell: props => <BorderedText borderColour={resolveTypeColour(props.value)} textColour={resolveTypeColour(props.value)} text={props.value}/>,
    },
    {
      filterable: false,
      Header: 'Status',
      accessor: 'status',
      Cell: props => <Badge color={resolveStatusColour(props.value.name)}>{props.value.name}</Badge>,
      sortMethod: (a, b) => {
        return a.id > b.id ? 1 : -1;
      }
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Created',
      accessor: 'createdAt',
      Cell: props => moment(props.value)
        .format('DD/MM/YYYY HH:mm'),
      width: 200
    },
  ]
  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <PriceListsTable priceLists={priceLists.list} fetchData={getData}/>
            {/*<FilteredTable
              filterable={false}
              extraButtons={ExtraButtons({
                others: [
                  <ButtonIcon
                    icon={'fa fa-refresh'}
                    onClick={() => getData()}
                    colour={'secondary'}
                    tooltip={'reload'}
                  />,
                  <ButtonIcon
                    icon={'fa fa-plus'}
                    onClick={() => navigate(`/products/pricelists/new`)}
                    colour={'secondary'}
                    tooltip={'new'}
                  />,

                ]
              })}
              onChange={onChangeSearch}
              value={priceLists.searchString}
              columns={columns}
              data={isFiltered() ? priceLists.filteredList : priceLists.list}
              minRows={10}
              defaultSorted={{
                id: 'status',
                desc: false
              }}
              onRowClick={(data) => navigate(`/products/pricelists/${data.id}`)}
              showPagination={priceLists.searchString ? priceLists.filteredList.length > 20 : priceLists.list.length > 20}
            />*/}
          </LoadingOverlay>
        </CardBody>
      </Card>

    </div>
  );
}
function mapStateToProps({
  priceLists,
  authenticationState,
}) {
  return {
    priceLists,
    user: authenticationState.account
  };
}
export default connect(mapStateToProps)(PriceLists)
