import { apiRequest } from '../Api/ApiRequest';

export function api_getAccountCircuits(accountId, circuitName) {
  let url = circuitName ? '/accounts/' + accountId + '/circuits?search=' + circuitName : '/accounts/' + accountId + '/circuits';
  return apiRequest({
    url: url,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_searchCircuits(search) {

  return apiRequest({
    url: `/circuits?search=${search}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_associateCircuit(productId, circuitId) {
  return apiRequest({
    url: '/products/' + productId + '/circuit',
    method: 'post',
    data: { circuitId }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_createCircuit(circuit) {
  return apiRequest({
    url: '/circuits?include=notes,order,products,products.site,cases',
    method: 'post',
    data: { ...circuit }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateCircuit(id, args) {
  return apiRequest({
    url: '/circuits/' + id + '?include=notes,order,products,products.site,cases',
    method: 'put',
    data: args
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getCircuit(id) {
  return apiRequest({
    url: '/circuits/' + id + '?include=notes,order,products,products.site,cases',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export const api_getCircuitEmails = (id) => {
  return apiRequest({
    url: `/circuits/${id}/emails`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};
