import { Badge, Button, Spinner } from 'reactstrap';
import { equipColors } from '../../../views/ServiceDB/constants';
import ZabbixStatusIcon from '../../Zabbix/ZabbixStatusIcon';
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react';
import { ButtonIcon } from '../../ButtonIcon';
import FilteredTableV2 from '../../FilteredTableV2';
import isEqual from 'lodash.isequal';

const DeviceDeploymentsTable = ({
  fetchData,
  deployments,
  deploymentTypes,
  table
}) => {

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({pageIndex : 0, pageSize: 20})
  const [sorting, setSorting] = useState([{id: 'hostname', desc: true}])


  const onSorting = (sortingState, searchString) => {
    const fixedSorting = getSorting(sortingState)
    if(!isEqual(fixedSorting, sorting)){
      fetchData(pagination.pageIndex + 1, pagination.pageSize, searchString, fixedSorting )
      setSorting(fixedSorting)
    }
  }

  const getSorting = (sortingState) => {
    return sortingState.map(sorting => {
      if(sorting.id === 'stockItem_assetTag'){
        return {
          ...sorting,
          id: 'sr_stock:stock_item_id|sr_stock.asset_tag'
        }
      }
      if(sorting.id === 'stockItem_serialNumber'){
        return {
          ...sorting,
          id: 'sr_stock:stock_item_id|sr_stock.serial_number'
        }
      }
      if(sorting.id === 'assignedTo_name'){
        return {
          ...sorting,
          id: 'sites|sites.name'
        }
      }
      if(sorting.id === 'type'){
        return {
          ...sorting,
          id: 'sr_device_deployment_types:type_id|sr_device_deployment_types.name'
        }
      }
      return sorting
    })
  }

  const columns = [
    {
      header: 'Hostname',
      accessorKey: 'hostname'
    },
    {
      header: 'Zabbix',
      enableSorting: false,
      accessorKey: 'zabbix',
      maxSize: 50,
      cell: props => props.getValue() ? <ZabbixStatusIcon {...props.getValue()} /> : <Spinner style={{height: 20, width: 20}} size="xs"/>
    },
    {
      header: 'Type',
      accessorKey: 'type',
      maxSize: 50,
      cell: props => (
        <>
          <Badge
            style={{ backgroundColor: equipColors[deploymentTypes.options.find(type => type.value === props.getValue())?.label ?? ''], textTransform: 'uppercase' }}
            color="danger">{deploymentTypes.options.find(type => type.value === props.getValue())?.label ?? ''}</Badge>
        </>
      ),
    },
    {
      header: 'Model',
      id: 'stockItem.deviceModel.name',
      accessorFn: (row) => `${row.stockItem?.deviceModel?.vendor?.name} ${row.stockItem?.deviceModel.name}`,
    },
    {
      header: 'Asset Tag',
      accessorKey: 'stockItem.assetTag'
    },
    {
      header: 'Serial Number',
      accessorKey: 'stockItem.serialNumber'
    },
    {
      header: 'Assigned To',
      accessorKey: 'site.name'
    }
  ];

  return (
    <FilteredTableV2
      withServerSideFilter={(searchString) => {
        fetchData(1, pagination.pageSize, searchString, getSorting(sorting))
      }}
      minRows={pagination.pageSize}
      data={deployments}
      columns={columns}
      onFetchData={(page, size, searchString) => {
        fetchData(page, size, searchString, getSorting(sorting))
      }}
      defaultSorted={sorting}
      pages={table.lastPage}
      pageSize={table.perPage}
      onPaginationChange={setPagination}
      pagination={pagination}
      showPagination
      defaultPageSize={20}
      onRowClick={(deployment) => navigate(`/sdb/device-deployments/${deployment.id}`)}
      extraButtons={[
        <Button size={'sm'} color={'secondary'} onClick={() => {
          navigate(`/sdb/device-deployments/new`)
        }}>New Deployment</Button>,
        <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={() => fetchData(pagination.pageIndex + 1, pagination.pageSize, '', getSorting(sorting))}/>
      ]}
      withServerSideSorting
      onSorting={(sorting, searchString) => onSorting(sorting, searchString)}
    />
  )
}

export default DeviceDeploymentsTable
