import {
  BILLING_RUN_BILL_SET_BILL,
  BILLING_RUN_BILL_SET_CREDIT_ERROR,
  BILLING_RUN_BILL_SET_EDIT_ERRORS,
  BILLING_RUN_BILL_SET_LOADING,
  BILLING_RUN_BILL_TOGGLE_CREDIT_MODAL,
  BILLING_RUN_BILL_TOGGLE_CREDIT_PROCESSING,
  BILLING_RUN_BILL_TOGGLE_EDIT_MODAL,
  BILLING_RUN_BILL_TOGGLE_EDIT_PROCESSING,
  BILLING_RUN_BILL_TOGGLE_SENDING,
  BILLING_RUN_BILL_UPDATE_CREDIT,
  BILLING_RUN_BILL_UPDATE_EDIT_FIELD,
  BILLING_RUN_BILL_SET_PAID
} from '../actions/billingRunBill';

const initialState = {
  bill: null,
  loading: false,
  sending: false,
  credit: {
    showModal: false,
    processing: false,
    value: 0,
    error: ''
  },
  edit: {
    showModal: false,
    processing: false,
    errors: [],
    fields: {}
  }
};

export function billingRunBill(state = initialState, action) {
  switch (action.type) {
    case BILLING_RUN_BILL_SET_BILL:
      return {
        ...state,
        bill: action.bill
      };
    case BILLING_RUN_BILL_SET_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case BILLING_RUN_BILL_TOGGLE_SENDING:
      return {
        ...state,
        sending: !state.sending
      };
    case BILLING_RUN_BILL_TOGGLE_CREDIT_MODAL:
      return {
        ...state,
        credit: { ...state.credit, showModal: !state.credit.showModal }
      };
    case BILLING_RUN_BILL_UPDATE_CREDIT:
      return {
        ...state,
        credit: { ...state.credit, value: action.value }
      };
    case BILLING_RUN_BILL_SET_CREDIT_ERROR:
      return {
        ...state,
        credit: { ...state.credit, error: action.error }
      };
    case BILLING_RUN_BILL_TOGGLE_CREDIT_PROCESSING:
      return {
        ...state,
        credit: { ...state.credit, processing: !state.credit.processing }
      };
    case BILLING_RUN_BILL_TOGGLE_EDIT_MODAL:
      const fields = state.edit.showModal ? initialState.edit.fields : { ...state.bill };
      return {
        ...state,
        edit: { ...state.edit, showModal: !state.edit.showModal, fields, errors: [] }
      };
    case BILLING_RUN_BILL_UPDATE_EDIT_FIELD:
      return {
        ...state,
        edit: { ...state.edit, fields: { ...state.edit.fields, ...action.field } }
      };
    case BILLING_RUN_BILL_SET_EDIT_ERRORS:
      return {
        ...state,
        edit: { ...state.edit, errors: action.errors }
      };
    case BILLING_RUN_BILL_TOGGLE_EDIT_PROCESSING:
      return {
        ...state,
        edit: { ...state.edit, processing: !state.edit.processing }
      };
    case BILLING_RUN_BILL_SET_PAID:
      return {
        ...state,
        bill: { ...state.bill, paid: action.paid}
      }
    default:
      return state;
  }
}
