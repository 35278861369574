import isEmpty from 'lodash.isempty';
import { Link } from 'react-router-dom';
import React from 'react';

export default {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true
  },
  domain: {
    label: 'Domain',
    labelCallback: (data) => <>
      Domain {!isEmpty(data.domain) ? <Link to={`/sdb/ipam/domains/${data.domain.id}`}><i className="fa fa-link"/></Link> : ''}
    </>,
    type: 'select-async',
    entity: 'domain',
    mandatory: true,
    readOnly: (key, field, data) => data.domainProvided
  },
  owner: {
    label: 'Owner',
    type: 'select-async',
    entity: 'site',
    mandatory: true,
    optionLabelCallback: (opt) => `${opt.name} (${opt.accountnumber})`
  },
  mask: {
    label: 'Mask',
    type: 'number',
    mandatory: true,
  },
  networkAddress: {
    label: 'Network Address',
    type: 'text',
    mandatory: true
  },
  description: {
    label: 'Description',
    type: 'textarea',
    mandatory: false,
    width: 12
  },
}