import { STOCK_SET_STOCK_ITEMS } from '../actions/stock';

const initialState = {
  stock: []
};
const stock = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_SET_STOCK_ITEMS:
      return {
        stock: [...action.data]
      };
    default:
      return state;
  }
};

export default stock;