import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button} from "reactstrap";
import {
    EXPAND_CODE_WINDOW, LINK_TEMPLATE, LINKING_TEMPLATE,
    SAVING_TEMPLATE, SET_TEMPLATE_ERROR
} from "../../actions/configTemplate";
import {linkChildTemplate, putConfigTemplate} from "../../utils/ConfigTemplate";
import {successFeedback} from "../../actions/feedback";
import {json} from "@codemirror/lang-json";
import {useCodeMirror} from '@uiw/react-codemirror';
import {autocompletion} from "@codemirror/autocomplete";
import {checkPermission} from "../../utils/Auth/AuthService";
import {materialDark} from "@uiw/codemirror-theme-material";
import ReactTooltip from "react-tooltip";

const TabTemplate = ({activeData, setTemplate, setActiveData, getActiveEntry}) => {
    const [buttonColor, setButtonColor] = useState("info");
    const editor = useRef();
    const [editable, setEditable] = useState(false);

    const dispatch = useDispatch()
    const auth = useSelector(state => state.authenticationState);
    const {
        activeEntry,
        expandCodeWindow,
        templateError,
        removeTemplateEditor
    } = useSelector(state => state.configTemplate);

    const {
        template_entries: templateEntries
    } = useSelector(state => state.configTemplate);

    const {
        required_templates: requiredTemplates
    } = useSelector(state => state.configTemplate.activeEntry);

    const handleChange = data => setActiveData(data);

    useEffect(() => {
        setEditable(!!checkPermission("ConfigTemplates", auth.account.permissions))
    })

    useEffect(() => {
        if (editor.current) {
            setContainer(editor.current);
        }
    }, [editor.current]);

    useEffect(() => {
        if (removeTemplateEditor && view) {
            removeLinkedTemplateText()
        }
    }, [removeTemplateEditor]);

    const {view, setContainer} = useCodeMirror({
        container: editor.current,
        extensions: [json(), autocompletion({override: [myCompletions]})],
        theme: materialDark,
        basicSetup: {highlightActiveLine: false, highlightActiveLineGutter: false},
        value: activeData,
        onChange: handleChange,
        editable: editable,
        minHeight: "100%",
        maxHeight: "100%"
    });

    // Remove linked template entries from Codemirror
    const removeLinkedTemplateText = () => {
        let lineNum = 1
        const changes = [];
        for (let i = view.state.doc.iterLines(); !i.next().done;) {
            const re = `{% include "(${removeTemplateEditor.id})" %} {# '(.*)' #}`
            const regex = new RegExp(re, "i")
            const line = view.state.doc.line(lineNum)
            const result = regex.exec(line.text)
            if (result) {
                const startPos = line.from + result.index
                changes.push({from: startPos, to: startPos + result[0].length, insert: ""})
            }
            lineNum += 1
        }

        if (changes.length > 0) {
            view.dispatch({changes})
        }
    }

    const linkTemplate = (id, description) => {
        dispatch({type: LINKING_TEMPLATE, data: true})
        linkChildTemplate(activeEntry.id, {child: id}).then(response => {
            if (response.status === 200) {
                dispatch(
                    {
                        type: LINK_TEMPLATE,
                        data: {id, description}
                    })
                getActiveEntry(activeEntry.id);
            }
        })
        dispatch({type: LINKING_TEMPLATE, data: false})
    }

    // Only link template if it isn't already linked
    const completionResult = (entry, requiredTemplates) => {
        if (!requiredTemplates.find(e => e.id === entry.id)) {
            linkTemplate(entry.id, entry.description)
        }
    }

    const getOptions = (from, to) => {
        let options = []
        // Put all the templates in a list except itself
        if (templateEntries.length !== undefined && templateEntries.length > 0) {
            templateEntries.forEach((e) => {
                if (e.id !== activeEntry.id) {
                    options.push(
                        {
                            label: `link {% include ${e.id} %} {# ${e.description} #}`,
                            type: "function",
                            id: e.id,
                            apply: () => {
                                completionResult({id: e.id, description: e.description}, requiredTemplates)
                                view.dispatch({
                                    changes: {
                                        from: from,
                                        to: to,
                                        insert: `{% include "${e.id}" %} {# '${e.description}' #}`
                                    }
                                })
                            }
                        }
                    )
                }
            })
        }
        return options
    }

    function myCompletions(context) {
        let word = context.matchBefore(/\w*/)
        if (word.from === word.to && !context.explicit)
            return null
        return {
            from: word.from,
            options: getOptions(word.from, word.to)
        }
    }

    const toggleSaveButtonColor = () => {
        setButtonColor("danger");
        setTimeout(() => setButtonColor("info"), 500);
    }

    const saveTemplate = template => {
        if (activeData === null) {
            return
        }
        toggleSaveButtonColor("red")
        dispatch({type: SAVING_TEMPLATE, saving: true});
        dispatch({type: SET_TEMPLATE_ERROR, error: false})
        putConfigTemplate(activeEntry.id, {'template': template})
            .then((result) => {
                if (result.status === 201) {
                    setTemplate(result.data['template']);
                    dispatch({type: SAVING_TEMPLATE, saving: false});
                    dispatch(successFeedback('Saved template'));
                } else {
                    dispatch({type: SAVING_TEMPLATE, saving: false})
                    dispatch({type: SET_TEMPLATE_ERROR, error: result})
                }
            })
            .catch(error => {
                dispatch({type: SAVING_TEMPLATE, saving: false})
                dispatch({type: SET_TEMPLATE_ERROR, error: error.data.message})
            })
    }

    return (
        <div style={{height: "100%"}}>
            <div style={{position: "absolute", right: 30, top: 10, zIndex: 3, width: 330, height: 30}}>
                <Button size='sm'
                        className='m-1 float-right'
                        style={{top: 50, right: 30, zIndex: 10, height: 33, width: 33}}
                        color='info'
                        data-for="expandCode"
                        data-tip="expandCode"
                        onClick={() => dispatch({type: EXPAND_CODE_WINDOW, expand: !expandCodeWindow})}
                ><i className="fa fa-expand fa-1x"/>
                </Button>
            </div>
            {checkPermission("ConfigTemplates", auth.account.permissions) ?
                <div style={{position: "absolute", right: 30, top: 40, zIndex: 3, width: 35, height: 35}}
                     className='mt-2'
                ><Button
                    size='sm'
                    className='m-1 mt-2 float-right'
                    data-for="saveTemplate"
                    data-tip="saveTemplate"
                    style={{zIndex: 10, cursor: "pointer", height: 33, width: 33}}
                    color={buttonColor}
                    onClick={() => saveTemplate(activeData)}
                ><i className="fa fa-save"/>
                </Button>
                    <ReactTooltip
                        id="saveTemplate"
                        type="dark"
                        place="right"
                        effect="float"
                        multiline={false}
                    >Save Template
                    </ReactTooltip>
                </div>
                : null}

            <div style={{height: 700}}>
                <div
                    className='h-100 font-sm animated fadeIn'
                    ref={editor}
                />
            </div>
            {
                templateError ? <Alert color="warning">{templateError}</Alert> : null
            }
        </div>
    )
}

export default TabTemplate;
