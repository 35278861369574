import { compareApiRequest } from '../Api/ApiRequest';

export function api_getAddressesByPostcode(postcode) {
  return compareApiRequest({
    url: `/address/postcode/${postcode}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}
