import update from 'immutability-helper';
import { SET_SOCKET_AUTHENTICATED, SOCKET_CONNECT, SOCKET_DISCONNECT } from '../actions/socketio';

const initialState = {
  status: 'unknown'
};

export default function socketioStatus(state = initialState, action) {
  switch (action.type) {
    case SET_SOCKET_AUTHENTICATED:
      return update(state, {
        status: { $set: 'authenticated' }
      });
    case SOCKET_DISCONNECT:
      return update(state, {
        status: { $set: 'disconnected' }
      });
    case SOCKET_CONNECT:
      return update(state, {
        status: { $set: 'unknown' }
      });
    default:
      return state;
  }
}
