import React, { Component } from 'react';
import { getConfigBackupHost, getConfigBackupIteration } from '../../utils/Compliance/ComplianceService';
import { SpinnerInfo } from '../../components/Spinner/Spinner';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactDiffViewer from 'react-diff-viewer';
import update from 'immutability-helper';
import { SelectMod } from '../../components/Selects/SelectMod';
import moment from 'moment';
import downloader from 'js-file-download';
import { useParams } from 'react-router-dom'

class ConfigBackupHostLegacy extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      selectleft: {},
      selectright: {},
      configs: { left: undefined, right: undefined }
    };
  }

  loadData = () => {
    this.setState({ loading: true });
    getConfigBackupHost(this.props.params.hostName).then((result) => {
      if (result.status === 200) {
        result.data.backuprefs.forEach((d, idx) => {
          // eslint-disable-next-line no-param-reassign
          result.data.backuprefs[idx].rev = idx;
        });
        this.setState({
          loading: false,
          data: result.data,
          selectleft: result.data.backuprefs[1] ? result.data.backuprefs[1] : undefined,
          selectright: result.data.backuprefs[0]
        });
      }
    }).catch((error) => {
      alert(error);
    });
  };

  loadConfigIteration = () => {
    getConfigBackupIteration(this.props.params.hostName, 0).then((result) => {
      if (result.status === 200) {
        this.setState(update(this.state, {
          configs: {
            right: { $set: result.data.config }
          }
        }));
      }
    }).catch((error) => {
      alert(error);
    });
    getConfigBackupIteration(this.props.params.hostName, 1).then((result) => {
      if (result.status === 200) {
        this.setState(update(this.state, {
          configs: {
            left: { $set: result.data.config }
          }
        }));
      }
    });
  };

  fileDownload = (side) => {
    downloader(this.state.configs[side], this.props.params.hostName + '_config_'
      + this.state['select' + side].date_modified.$date + '.txt');
  }

  handleRevChange = (opt, side) => {
    this.setState(update(this.state, {
      $set: {
        ['select' + side]: opt
      }
    }));
    getConfigBackupIteration(this.props.params.hostName, opt.rev).then((result) => {
      if (result.status === 200) {
        this.setState(update(this.state, {
          configs: {
            [side]: { $set: result.data.config }
          }
        }));
      }
    }).catch((error) => {
      alert(error);
    });
  };

  componentDidMount() {
    this.loadData();
    this.loadConfigIteration();
  }

  render() {
    console.log(this.state);
    return (
      <div>
        {this.state.loading
        && <div className="row justify-content-center">
          <SpinnerInfo/>
        </div>
        }
        {!this.state.loading && this.state.data.backuprefs !== undefined
        && <Row>
          <Col className={'d-flex'}>
            <Card className="w-100 card-accent-primary">
              <CardHeader>
                {this.props.params.hostName}
              </CardHeader>
              <CardBody>
                <Row style={{ marginBottom: '8px' }}>
                  <Col md={4}>
                    <SelectMod
                      name="left"
                      id={'left'}
                      options={this.state.data.backuprefs}
                      value={this.state.selectleft}
                      getOptionLabel={(ob) => 'Revision ' + ob.rev + ': ' + moment(ob.date_modified).fromNow() + ' (' + moment(ob.date_modified).format() + ')'}
                      getOptionValue={({ rev }) => rev}
                      onChange={(opt) => this.handleRevChange(opt, 'left')}/>

                  </Col>
                  <Col md={2}>
                    <Button onClick={() => this.fileDownload('left')} color="primary" disabled={this.state['selectleft'] === undefined}><i
                      className="fa fa-download"/> Download</Button>
                  </Col>
                  <Col md={4}>
                    <SelectMod
                      name="right"
                      id={'right'}
                      options={this.state.data.backuprefs}
                      value={this.state.selectright}
                      getOptionLabel={(ob) => 'Revision ' + ob.rev + ': ' + moment(ob.date_modified).fromNow() + ' (' + moment(ob.date_modified).format() + ')'}
                      getOptionValue={({ rev }) => rev}
                      onChange={(opt) => this.handleRevChange(opt, 'right')}/>
                  </Col>
                  <Col md={2}>
                    <Button onClick={() => this.fileDownload('right')} color="primary" disabled={this.state['selectright'] === undefined}><i
                      className="fa fa-download"/> Download</Button>
                  </Col>
                </Row>
                {this.state.configs.left !== undefined && this.state.configs.right !== undefined
                && <Row>
                  <Col md={12}>
                    <ReactDiffViewer
                      oldValue={this.state.configs.left}
                      newValue={this.state.configs.right}
                      splitView={true}
                      disableWordDiff={true}
                      styles={{ content: { wordBreak: 'break-all', width: 'auto' } }}
                    />
                  </Col>
                </Row>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>}
      </div>
    );
  }
}

const ConfigBackupHost = () => {
  const params = useParams();
  return <ConfigBackupHostLegacy params={params}/>
}

export default ConfigBackupHost;
