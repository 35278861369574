import { call, put, takeEvery, actionChannel, take } from 'redux-saga/effects'
import {api_getServingExchange} from "../utils/Discovery/DiscoveryService";


export default function* sagaFetchServingExchange() {
  const channel = yield actionChannel("EXCHANGE_FETCH_SERVING");
  while (true) {
    const action = yield take(channel);
    try {
      const serving = yield call(api_getServingExchange, action.payload.postcode);
      if(serving.status === 200) {
        yield put({type: "SET_SERVING_EXCHANGE_LIST", serving: serving.data, postcode: action.payload.postcode});
      }
      else {
        yield put({type: "SET_SERVING_EXCHANGE_LIST_FAILED", message: serving.data.message, postcode: action.payload.postcode});
      }
    } catch (e) {
      yield put({type: "SET_SERVING_EXCHANGE_LIST_FAILED", message: e.message, postcode: action.payload.postcode});
    }
  }
}