import { api_getNewLineItems } from '../utils/Billing/LineItems';

export const BILLING_NEW_LINE_ITEMS_SET_ITEMS = 'BILLING_NEW_LINE_ITEMS_SET_ITEMS';
export const BILLING_NEW_LINE_ITEMS_SET_LOADING = 'BILLING_NEW_LINE_ITEMS_SET_LOADING';

export function getBillingNewLineItems() {
  return (dispatch) => {
    return api_getNewLineItems().then((result) => {
      if (result.status === 200) {
        dispatch({ type: BILLING_NEW_LINE_ITEMS_SET_ITEMS, items: result.data });
      }
    });
  };
}
