import {
  configEntriesAll,
  postConfigEntry,
  deleteEntry as deleteTemplateEntry, configTemplateRequest
} from "../utils/ConfigTemplate";

export const FETCHING_ENTRIES = 'FETCHING_ENTRIES';
export const LOAD_TEMPLATE_ENTRIES = 'LOAD_TEMPLATE_ENTRIES';
export const ADD_TEMPLATE_ENTRY = 'ADD_TEMPLATE_ENTRY';
export const DELETE_TEMPLATE_ENTRY = 'DELETE_TEMPLATE_ENTRY';
export const ADDING_ENTRY = 'ADDING_ENTRY';
export const DELETING_ENTRY = 'DELETING_ENTRY';
export const GENERATED = 'GENERATED';
export const SET_ACTIVE_ENTRY = 'SET_ACTIVE_ENTRY';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const LINK_TEMPLATE = 'LINK_TEMPLATE';
export const UNLINK_TEMPLATE = 'UNLINK_TEMPLATE';
export const REMOVE_TEMPLATE_EDITOR = 'REMOVE_TEMPLATE_EDITOR';
export const SAVING_TEMPLATE = 'SAVING_TEMPLATE';
export const FETCHING_ACTIVE_ENTRY = 'FETCHING_ACTIVE_ENTRY';
export const EXPAND_CODE_WINDOW = 'EXPAND_CODE_WINDOW';
export const UPDATE_JSON_ARGUMENT_DATA = 'UPDATE_JSON_ARGUMENT_DATA';
export const SET_TEMPLATE_ERROR = 'SET_TEMPLATE_ERROR';
export const SET_SCHEMA = 'SET_SCHEMA';
export const SET_NOTE = 'SET_NOTE';
export const UPDATING_ENTRY = 'UPDATING_ENTRY';
export const RENAME_ENTRY = 'RENAME_ENTRY';
export const SET_RENAME_LIVE = 'SET_RENAME_LIVE';
export const LINKING_TEMPLATE = 'LINKING_TEMPLATE';
export const FETCH_ENTRIES_FAILURE = 'FETCH_ENTRIES_FAILURE'

export function getEntries() {
  return (dispatch) => {
    dispatch({type: SET_TEMPLATE_ERROR, error: false})
    dispatch({type: FETCH_ENTRIES_FAILURE, data: false})
    dispatch({
      type: FETCHING_ENTRIES, result: true
    });
    dispatch({type: SET_ACTIVE_ENTRY, data: {}})

    configEntriesAll().then(result => {
      if (result.status === 200) {
        dispatch({
          type: FETCHING_ENTRIES, result: false
        });
        dispatch({
          type: LOAD_TEMPLATE_ENTRIES, data: result.data
        })
      } else {
        dispatch({type: LOAD_TEMPLATE_ENTRIES, data: []})
        dispatch({type: FETCH_ENTRIES_FAILURE, data: true})

      }
    })
  }
}

export function addEntry(newEntry) {
  return (dispatch) => {
    dispatch({
      type: ADDING_ENTRY, result: false
    });
    postConfigEntry(newEntry).then(result => {
      if (result.status === 201) {
        dispatch({
          type: ADDING_ENTRY, result: false
        });
        dispatch({
          type: ADD_TEMPLATE_ENTRY, data: result.data
        })
      }
    })
  }
}

export function deleteEntry(entry) {
  return (dispatch) => {
    dispatch({
      type: DELETING_ENTRY, result: entry
    });
    deleteTemplateEntry(entry).then(result => {
      if (result.status === 200) {
        dispatch({
          type: DELETING_ENTRY, result: false
        });
        dispatch({
          type: DELETE_TEMPLATE_ENTRY, data: entry
        })
      }
    })
  }
}

export function renameEntry(id, renameText) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_ENTRY, result: true
    });
    configTemplateRequest(
        id,
        `configtemplate/${id}`,
        'put',
        JSON.stringify({
          description: renameText
        })).then(response => {
      if (response.status === 200) {
        dispatch({type: RENAME_ENTRY, data: {id: id, renameText: renameText}})
        dispatch({type: SET_RENAME_LIVE, data: undefined})
      }
    })
    dispatch({
      type: UPDATING_ENTRY, result: false
    });
  }
}

export function unlinkTemplate(parentId, childId, line) {
  return (dispatch) => {
    dispatch({
      type: LINKING_TEMPLATE, result: true
    });
    configTemplateRequest(
        parentId,
        `configtemplate/${parentId}/template/link`,
        'delete',
        JSON.stringify({
          child: childId
        })).then(response => {
      if (response.status === 204) {
        dispatch({type: UNLINK_TEMPLATE, childTemplate: childId})
        dispatch({type: REMOVE_TEMPLATE_EDITOR, data: line})
        dispatch({type: LINKING_TEMPLATE, data: false})
      }
    })
  }
}
