import {
  STOCK_ITEM_ADD_LICENSE, STOCK_ITEM_ADD_NOTE,
  STOCK_ITEM_REMOVE_LICENSE, STOCK_ITEM_REMOVE_NOTE,
  STOCK_ITEM_RESET_DATA, STOCK_ITEM_SET_AUDIT_HISTORY,
  STOCK_ITEM_SET_DATA,
  STOCK_ITEM_SET_FIELD,
  STOCK_ITEM_UPDATE_LICENSE, STOCK_ITEM_UPDATE_NOTE
} from '../actions/stockItem';
import { StockItemEnums } from '../utils/Constants/StockItem'

const initialState = {
  data: {
    owner: StockItemEnums.owner.hso,
    licenses: []
  },
  original: {
    owner: StockItemEnums.owner.hso,
    licenses: []
  },
  audits: []
};
const stockItem = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_ITEM_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case STOCK_ITEM_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case STOCK_ITEM_RESET_DATA:
      return {
        ...initialState
      };
    case STOCK_ITEM_ADD_LICENSE:
      return {
        ...state,
        data: { ...state.data, licenses: [...state.data.licenses, action.licenseData] },
        original: { ...state.original, licenses: [...state.original.licenses, action.licenseData] }
      };
    case STOCK_ITEM_UPDATE_LICENSE:
      const updatedLicensesWithUpdatedLicense = state.data.licenses.map((license) => license.id === action.licenseData.id ? action.licenseData : license);
      return {
        ...state,
        data: { ...state.data, licenses: updatedLicensesWithUpdatedLicense },
        original: { ...state.original, licenses: updatedLicensesWithUpdatedLicense }
      };
    case STOCK_ITEM_REMOVE_LICENSE:
      const updatedLicensesWithoutDelete = state.data.licenses.filter((license) => license.id !== action.licenseId);
      return {
        ...state,
        data: { ...state.data, licenses: updatedLicensesWithoutDelete },
        original: { ...state.original, licenses: updatedLicensesWithoutDelete }
      };
    case STOCK_ITEM_ADD_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: [...state.data.notes, action.note]
        },
        original: {
          ...state.original,
          notes: [...state.original.notes, action.note]
        },

      }
    case STOCK_ITEM_UPDATE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
        original: {
          ...state.original,
          notes: state.original.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
      }
    case STOCK_ITEM_REMOVE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.filter(note => note.id !== action.note.id)
        },
        original: {
          ...state.original,
          notes: state.original.notes.filter(note => note.id !== action.note.id)
        }
      }
    case STOCK_ITEM_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;
  }
}

export default stockItem;
