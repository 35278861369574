import moment from 'moment/moment';
import { FiAlignLeft } from 'react-icons/all';
import FilteredTableV2 from '../../FilteredTableV2';

const AccountLogsTable = ({
  logs
}) => {

  const columns = [
    {
      header: 'Id',
      accessorKey: 'id',
      width: 50
    },
    {
      header: 'Log',
      accessorKey: 'message'
    },
    {
      header: 'Contact',
      accessorKey: 'data.contact_email'
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      Cell: props => moment(props.value)
        .format('DD/MM/YYYY HH:mm')
    },
  ];

  return (
    <>
      <FilteredTableV2
        data={logs}
        columns={columns}
        minRows={10}
        showPagination
        defaultSorted={[{id: 'createdAt', desc: true}]}
        noDataText={'No Logs'}
      />
    </>
  )
}

export default AccountLogsTable