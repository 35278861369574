import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import FilteredTable from '../../components/FilteredTable';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { setQuotes, setQuoteSearch } from '../../actions/quotes';
import { Badge } from 'reactstrap';
import FilteredTableV2 from '../../components/FilteredTableV2';
import { resolveCurrencySign } from '../../utils/Helpers/Currency';
import Currency from '../../components/Currency';

const Quotes = (props) => {
  const {
    quotes,
    optionSets,
    opportunityQuotes,
    priceList
  } = props;
  const {
    list
  } = quotes;

  useEffect(() => {
    if (opportunityQuotes) {
      props.dispatch(setQuotes(opportunityQuotes));
    }
  }, [opportunityQuotes]);

  const onQuoteSearchChange = (event) => {
    props.dispatch(setQuoteSearch(event.target.value));

  };

  let columns = [
    {
      header: 'Number',
      accessorKey: 'number',
      minSize: 150
    },
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 150
    },
    {
      header: 'Status',
      accessorKey: 'statusReason',
      cell: props => <div>{optionSets.statusReason.options.find(x => x.value === props.getValue() && x.status === props.row.original.status ).label}</div>

    },
    {
      header: 'TCV',
      accessorKey: 'totalAmount',
      cell: props => <Currency currency={priceList?.currency.id} value={props.getValue()}/>
    },
    {
      header: 'Created On',
      accessorKey: 'createdOn',
      minSize: 150,
      cell: props => moment(props.getValue())
        .format('DD/MM/YYYY HH:mm:ss')
    },
    {
      header: '',
      accessorKey: 'preferredQuote',
      cell: props => {
        if (props.getValue()) {
          return <Badge color={'success'}>preferred</Badge>;
        }
        return '';
      },
      maxWidth: 90
    }

  ];

  return (
    <>
      <FilteredTableV2
        data={list}
        columns={columns}
        minRows={5}
        showPagination={list.length > 5}
        defaultPageSize={5}
        pageSizeOptions={[5,10,20,50]}
        showPageSizeOptions={true}
        defaultSorted={[{ id: 'createdOn', desc: true }]}
        onRowClick={props.onRowClick}
        noDataText={'No Quotes'}
      />
    </>
  );
};

function mapStateToProps({
  authenticationState,
  helpers,
  quotes
}) {
  return {
    user: authenticationState.account,
    optionSets: helpers.optionSets.quote,
    quotes
  };
}

export default connect(mapStateToProps)(Quotes);
