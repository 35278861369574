import { APP_SET_INITIALISED } from '../actions/app';

const initialState = {
  initialised: false
};
export function app(state = initialState, action = {}) {
  switch (action.type) {
    case APP_SET_INITIALISED:
      return {
        ...state,
        initialised: true
      }
    default: {
      return state
    }
  }
}