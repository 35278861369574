import React, { useEffect, useState } from 'react';
import {Card, CardBody } from 'reactstrap';
import { connect, useDispatch } from 'react-redux'
import {
  getLicenses
} from '../../../actions/ServiceDB/licenses';
import LoadingOverlay from '../../../components/LoadingOverlay';
import LicencesTable from '../../../components/Tables/Licenses';

const Licenses = ({
  licenses,
}) => {
  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    dispatch(getLicenses(['deviceModel', 'deviceModel.vendor']))
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <LicencesTable
              licenses={licenses}
              fetchData={fetch}
              minRows={20}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  );
};

function mapStateToProps({
  licenses,
  authenticationState
}) {
  return {
    permissions: authenticationState.account.permissions,
    licenses: licenses.licenses
  };
}

export default connect(mapStateToProps)(Licenses);
