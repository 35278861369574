import React, { Component } from 'react';
import { addComplianceDeviceException, getComplianceDevice, deleteComplianceDeviceException, getCveForCpe } from '../../utils/Compliance/ComplianceService';
import { Link, useParams } from 'react-router-dom'
import { SpinnerInfo } from '../../components/Spinner/Spinner';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, Input, FormGroup, Label, Form, UncontrolledTooltip, CardFooter
} from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { checkPermission } from '../../utils/Auth/AuthService';
import { connect, useDispatch } from 'react-redux'
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { addJob } from '../../utils/PrefixLists/PrefixListService';
import { errorFeedback, successFeedback } from '../../actions/feedback';
import { LOGTAIL_ADD_MESSAGE } from '../../actions/logtail';

class ComplianceHostLegacy extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addExceptionModal: false,
      addExceptionData: {},
      data: [],
      submittingScan: false,
      cvedata: [],
      cveloading: true,
      cveModal: false,
      cveModalData: {}
    };
  }

  viewCve = (cve) => {
    this.setState({ cveModal: true, cveModalData: cve });
  }

  closeCve = () => {
    this.setState({ cveModal: false });
  }

  addException = (port, protocol) => {
    this.setState({ addExceptionModal: true, addExceptionData: { port, protocol } });
  }

  delException = (port, protocol) => {
    deleteComplianceDeviceException(this.state.data.hostname, port, protocol).then(() => {
      this.loadData();
    }).catch((error) => {
      alert(error);
    });
  }

  closeModal = () => { this.setState({ addExceptionModal: false }); };

  handleReasonChange = (event) => {
    this.setState(update(this.state, { addExceptionData: { reason: { $set: event.target.value } } }));
  };

  handleAddException = () => {
    if (this.state.addExceptionData.reason === undefined || this.state.addExceptionData.reason.length < 5) {
      alert('Fill in a reason!');
      return false;
    }
    addComplianceDeviceException(this.state.data.hostname, this.state.addExceptionData.port,
      this.state.addExceptionData.protocol, this.state.addExceptionData.reason).then(() => {
      this.setState({ addExceptionData: {}, addExceptionModal: false });
      this.loadData();
    }).catch((error) => {
      alert(error);
    });
  };

  loadData = () => {
    this.setState({ loading: true });
    getComplianceDevice(this.props.params.hostName).then((result) => {
      if (result.status === 200) {
        this.setState({ loading: false, data: result.data });
        getCveForCpe(result.data.os_cpe).then((r2) => {
          if (r2.status === 200) {
            this.setState({cvedata: r2.data, cveloading: false});
          }
        });
      }
    }).catch((error) => {
      alert(error);
    });
  };

  submitScan = () => {
    this.setState({ submittingScan: true });
    addJob({
      job: 'tasks.portscan',
      params: [
        JSON.stringify({
          hostname: this.state.data.hostname,
          ip: this.state.data.ipaddress
        })
      ]
    }).then((result) => {
      if (result.status === 200) {
        this.props.dispatch(successFeedback('Portscan requested. It may take some time to process.'));
        this.props.dispatch({
          type: LOGTAIL_ADD_MESSAGE,
          msg: 'Portscan requested for ' + this.state.data.hostname,
          msgtype: 'success',
          date: Date.now()
        });
        this.setState({ submittingScan: false });
      }
    }).catch(() => {
      this.props.dispatch(errorFeedback('Portscan Request Failed'));
      this.setState({ submittingScan: false });
    });
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    const pmap = {
      'Protocol.TCP': 'tcp',
      'Protocol.UDP': 'udp'
    };
    const cmap = {
      'CRITICAL': 'danger',
      'HIGH': 'warning',
      'MEDIUM': 'info',
      'LOW': 'success'
    };
    let exceptions = {};
    if (this.state.data.portexceptions !== undefined) {
      this.state.data.portexceptions.forEach((p) => {
        exceptions[p.port_num + '/' + pmap[p.protocol]] = p;
      });
    }
    return (
      <div>
        {this.state.loading
          && <div className="row justify-content-center">
            <SpinnerInfo/>
          </div>
        }
        {!this.state.loading && this.state.data.hostname
          && <div>
            <Row>
              <Col md={3}>
                <h1>{this.state.data.hostname}</h1>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody className="p-0">
                    <i className="fa fa-info-circle bg-info p-4 font-2xl mr-3 float-left"></i>
                    <h5 className="mb-0 pt-3">{this.state.data.open_port_summary ? this.state.data.open_port_summary.ports_open : 'n/a'}</h5>
                    <div className="text-muted text-uppercase font-weight-bold font-xs">Open Ports</div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody className="p-0">
                    <i className="fa fa-check-circle-o bg-warning p-4 font-2xl mr-3 float-left"></i>
                    <h5 className="mb-0 pt-3">{this.state.data.open_port_summary ? this.state.data.open_port_summary.ports_accepted : 'n/a'}</h5>
                    <div className="text-muted text-uppercase font-weight-bold font-xs">Accepted Ports</div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody className="p-0">
                    <i className="fa fa-warning bg-danger p-4 font-2xl mr-3 float-left"></i>
                    <h5 className="mb-0 pt-3">{this.state.data.open_port_summary ? this.state.data.open_port_summary.ports_illegal : 'n/a'}</h5>
                    <div className="text-muted text-uppercase font-weight-bold font-xs">Illegal Ports</div>
                  </CardBody>
                </Card>
              </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card className="w-100 card-accent-danger">
                <CardHeader><Row><Col>Current Port Status</Col><Col style={{ textAlign: 'right' }}>
                  {(checkPermission('NOC', this.props.auth.account.permissions) || checkPermission('FieldOps', this.props.auth.account.permissions))
                  && <LaddaButton
                    className="btn btn-primary btn-ladda btn-sm px-4"
                    loading={this.state.submittingScan}
                    data-color="blue"
                    data-style={ZOOM_OUT}
                    onClick={this.submitScan}
                  >
                    Run Scan Now
                  </LaddaButton>
                  }</Col></Row></CardHeader>
                <CardBody>
                  {(this.state.data.portscans[0] === undefined || this.state.data.portscans[0].results === undefined) && <p className="text-center">No port scan results</p>}
                  {(this.state.data.portscans[0] !== undefined && this.state.data.portscans[0].results !== undefined)
                  && <div>
                    {this.state.data.portscans[0].results?.ports.map((port) => {
                      return (
                        <Row>
                          <Col>{port.portid}</Col>
                          <Col>{port.protocol}</Col>
                          <Col>{port.service.name}</Col><Col>{port.reason}</Col>
                          <Col>
                            <Badge id={'tt_' + port.portid + '_' + port.protocol} color={port.state === 'open' ? exceptions[port.portid + '/' + port.protocol] !== undefined ? 'warning' : 'danger' : 'success'}>{exceptions[port.portid + '/' + port.protocol] !== undefined ? 'open-excepted' : port.state}</Badge>
                            {exceptions[port.portid + '/' + port.protocol] !== undefined && <UncontrolledTooltip target={'tt_' + port.portid + '_' + port.protocol}>{exceptions[port.portid + '/' + port.protocol].added_by} @ {moment(exceptions[port.portid + '/' + port.protocol].date_modified).format()}: {exceptions[port.portid + '/' + port.protocol].reason}</UncontrolledTooltip>}
                          </Col>
                          {(checkPermission('NOC', this.props.auth.account.permissions) || checkPermission('FieldOps', this.props.auth.account.permissions))
                          && <Col>
                            {port.state === 'open' ? exceptions[port.portid + '/' + port.protocol] !== undefined
                              ? <Link to={'#'} onClick={() => this.delException(port.portid, port.protocol)}><i
                                className="fa fa-trash"/></Link>
                              : <Link to={'#'} onClick={() => this.addException(port.portid, port.protocol)}><i
                                className="fa fa-commenting-o"/> </Link> : ''}
                          </Col>
                          }
                        </Row>
                      );
                    })
                    }
                  </div>
                  }
                </CardBody>
                {this.state.data.portscans[0] !== undefined
                && <CardFooter
                  className="small text-muted">{this.state.data.portscans[0].stats.args}<br/>{this.state.data.portscans[0].runtime.summary}
                </CardFooter>
                }
              </Card>
              <Card className="w-100 card-accent-primary">
                <CardHeader>Port Scan History</CardHeader>
                <CardBody>
                  <Row style={{ borderBottom: '1px solid #eaeaea', marginBottom: '6px', fontWeight: 'bold' }}><Col>Date</Col><Col>Elapsed Time</Col><Col>Detected Ports</Col></Row>
                  {this.state.data.portscans.map((ps) => {
                    return (<Row><Col>{moment(ps.date_modified).format()}</Col><Col>{ps.runtime.elapsed}</Col><Col>{ps.results?.ports !== undefined ? ps.results?.ports?.length : 0}</Col></Row>);
                  })}
                </CardBody>
              </Card>
              <Card className="w-100 card-accent-primary">
                <CardHeader><Row><Col>Check History</Col></Row></CardHeader>
                <CardBody>
                  <Row style={{ borderBottom: '1px solid #eaeaea', marginBottom: '6px', fontWeight: 'bold' }}><Col>Date</Col><Col>Check Type</Col><Col>Run By</Col><Col md={1}>&nbsp;</Col></Row>
                  {this.state.data.checks.map((check) => {
                    return (<Row><Col>{moment(check.date_modified).format()}</Col><Col>{check.check_type}</Col><Col>{check.user}</Col><Col md={1}>{check.check_type === 'cpe' ? <Link to={'/toolbox/dcheck/' + check.celery_id}><i className="fa fa-search"></i></Link> : <Link to={'/toolbox/coreconfig/' + check.celery_id}><i className="fa fa-search"></i></Link> }</Col></Row>);
                  })}
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="w-100 card-accent-warning">
                <CardHeader>{this.state.cvedata.length} Possible CVE for device</CardHeader>
                <CardBody>
                  {this.state.cveloading
                    && <div className="row justify-content-center">
                      <SpinnerInfo/>
                    </div>
                  }
                  {this.state.cvedata.map((cve) => {
                    let score = 0;
                    let tc = 'default';
                    let vector = '';
                    if('cvssMetricV31' in cve.metrics) {
                      score = cve.metrics.cvssMetricV31[0].cvssData.baseScore
                      tc = cmap[cve.metrics.cvssMetricV31[0].cvssData.baseSeverity]
                      vector = cve.metrics.cvssMetricV31[0].cvssData.vectorString
                    }
                    else if('cvssMetricV2' in cve.metrics) {
                      score = cve.metrics.cvssMetricV2[0].cvssData.baseScore
                      tc = cmap[cve.metrics.cvssMetricV2[0].baseSeverity]
                      vector = cve.metrics.cvssMetricV2[0].cvssData.vectorString
                    }
                    return (<>
                      <Row>
                        <Col><Link to={"#"} onClick={(e) => { e.preventDefault(); this.viewCve(cve); }}>{cve.id}</Link></Col>
                        <Col>{moment(cve.published).format('YYYY-MM-DD')}</Col>
                        <Col><Badge color={tc}>{score}</Badge></Col>
                        <Col>{vector}</Col>
                      </Row>
                      <Row style={{marginBottom: '12px'}}>
                        <Col className="small">
                        {cve.descriptions[0].value.substring(0, 300)}...
                        </Col>
                      </Row>
                    </>);
                  })}
                </CardBody>
              </Card>
              <Card className="w-100 card-accent-info">
                <CardHeader>Net API Config CPE</CardHeader>
                <CardBody>
                  {this.state.data.os_cpe.map((v) => {
                    return (<Row><Col><Badge>{v}</Badge></Col></Row>)
                  })}
                </CardBody>
              </Card>
              <Card className="w-100 card-accent-info">
                <CardHeader>OS Automated Detection</CardHeader>
                <CardBody>
                  {(this.state.data.portscans[0] === undefined || this.state.data.portscans[0].results === undefined) && <p className="text-center">No port scan results</p>}
                  {(this.state.data.portscans[0] !== undefined && this.state.data.portscans[0].results !== undefined && this.state.data.portscans[0].results?.osmatch.length > 0)
                    && <div>
                      {this.state.data.portscans[0].results?.osmatch.map((os) => {
                        return (
                          <Row>
                            <Col md={2}>{os.accuracy}%</Col>
                            <Col md={2}>{os.osclass.type}</Col>
                            <Col>{os.name}</Col>
                            <Col md={3}><Badge>{os.cpe}</Badge></Col>
                          </Row>
                        );
                      })}
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
        </Row>
            {'id' in this.state.cveModalData &&
            <Modal size="xl" isOpen={this.state.cveModal}>
              <ModalHeader><i className="fa fa-bug"></i> {this.state.cveModalData.id} Detail</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={8}>
                    <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Description</div>
                    {this.state.cveModalData.descriptions[0].value}
                    <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Severity</div>
                    {this.state.cveModalData.metrics?.cvssMetricV31.map((metric) => {
                      return(<Card>
                        <CardHeader>
                          <Row>
                          <Col>{metric.source}</Col>
                          <Col>{metric.type}</Col>
                          <Col className="text-right"><Badge color={cmap[metric.cvssData.baseSeverity]}>{metric.cvssData.baseScore} {metric.cvssData.baseSeverity}</Badge></Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <small>{metric.cvssData.vectorString}</small>
                          <Row>
                            <Col>
                              <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Impact Score</div>
                              {metric.impactScore}
                              <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Attack Vector</div>
                              {metric.cvssData.attackVector}
                              <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Attack Complexity</div>
                              {metric.cvssData.attackComplexity}
                              <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Privileges Required</div>
                              {metric.cvssData.privilegesRequired}
                              <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">User Interaction</div>
                              {metric.cvssData.userInteraction}
                            </Col>
                            <Col>
                              <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Exploitability Score</div>
                              {metric.exploitabilityScore}
                              <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Scope</div>
                              {metric.cvssData.scope}
                              <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Confidentiality Impact</div>
                              {metric.cvssData.confidentialityImpact}
                              <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Integrity Impact</div>
                              {metric.cvssData.integrityImpact}
                              <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Availability Impact</div>
                              {metric.cvssData.availabilityImpact}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>)
                    })}
                  </Col>
                  <Col md={4}>
                    <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Published</div>
                    <div>{this.state.cveModalData.published}</div>
                    <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Updated</div>
                    <div>{this.state.cveModalData.lastModified}</div>
                    <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Source</div>
                    <div>{this.state.cveModalData.sourceIdentifier}</div>
                    <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">References</div>
                    <div>{this.state.cveModalData.references.map((ref) => {
                      return (<Row><Col>
                        {ref.tags.map((tag) => { return (<Badge pill={true}>{tag}</Badge>) })}<br/>
                        <Link to={{ pathname: ref.url }} target="_blank" >{ref.url}</Link>
                      </Col>
                      </Row>)
                    })}
                    </div>
                  </Col>
                </Row>
                <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Configurations</div>
                <Row>
                  <Col>
                  {this.state.cveModalData.configurations.map((config) => {
                    return(<Card>
                      <CardHeader>Match Group ({config.operator ? config.operator : "OR"})</CardHeader>
                      <CardBody>
                        <Row>
                      {config.nodes.map((nd) => {
                        return (<Col>
                       <Card>
                          <CardHeader>Node Group({nd.operator})</CardHeader>
                          <CardBody>
                            <ul style={{listStyleType: "none"}}>
                            {nd.cpeMatch.map((cpem) => {
                              let hl = false;
                              this.state.data.os_cpe.forEach((el) => {
                                if(cpem.criteria.startsWith((el+":"))) {
                                  hl = true;
                                }
                              });
                              if(hl === true) {
                                return(
                                  <li><span style={{backgroundColor: "#ffdc00"}}>{cpem.criteria}</span></li>
                                )
                              }
                              return(
                                <li>{cpem.criteria}</li>
                              )
                            })}
                            </ul>
                          </CardBody>
                       </Card></Col>);
                      })}
                        </Row>
                      </CardBody>
                    </Card>)

                  })}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.closeCve}>Close</Button>
              </ModalFooter>
            </Modal>
            }
          <Modal isOpen={this.state.addExceptionModal}>
            <ModalHeader>Add Exception</ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="port">Port</Label>
                  <Input type={'text'} name="port" id="port" value={this.state.addExceptionData.port} disabled={true}></Input>
                </FormGroup>
                <FormGroup>
                  <Label for="prot">Protocol</Label>
                  <Input type={'text'} name="prot" id="prot" value={this.state.addExceptionData.protocol} disabled={true}></Input>
                </FormGroup>
                <FormGroup>
                  <Label for="reason">Reason for Exception</Label>
                  <Input type={'text'} name="reason" id="reason" value={this.state.addExceptionData.reason} onChange={this.handleReasonChange}></Input>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.handleAddException}>Add Exception</Button>{' '}
              <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </div>}
      </div>
    );
  }
}

const ComplianceHost = ({auth}) => {
  const dispatch = useDispatch();
  const params = useParams();
  return <ComplianceHostLegacy auth={auth} dispatch={dispatch} params={params}/>
}

function mapStateToProps(state) {
  return {
    auth: state.authenticationState
  };
}

export default connect(mapStateToProps)(ComplianceHost);
