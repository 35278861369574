import React, { Component } from 'react';
import {
  Row, Col, Card,
  CardBody,
  CardHeader, Button, Input, Label
} from 'reactstrap';
import {SelectMod} from "../../components/Selects/SelectMod";
import {
  api_reloadScreens,
  api_reloadScreensQueue,
  api_setPrivacy,
  api_toast
} from "../../utils/Wallboard/WallboardService";
import {connect} from "react-redux";


class WallBoard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'ttype': 'default'
    };
    this.message = React.createRef();
    this.screenid = React.createRef();

    this.reloadQueue = this.reloadQueue.bind(this);
    this.toast = this.toast.bind(this);
  }

  setPrivacy(mode) {
    api_setPrivacy(mode).then((r) => {
      if(r.status !== 204) {
        this.props.dispatch({ type: 'TOAST', title: 'There was an error with your request', msgtype: 'error' });
      }
      else {
        this.props.dispatch({ type: 'TOAST', title: 'Privacy mode set', msgtype: 'success' });
      }
    });
  }

  reloadScreens() {
    api_reloadScreens().then((r) => {
      if(r.status !== 204) {
        this.props.dispatch({ type: 'TOAST', title: 'There was an error with your request', msgtype: 'error' });
      }
      else {
        this.props.dispatch({ type: 'TOAST', title: 'Screens reloaded', msgtype: 'success' });
      }
    });
  }

  toast() {
    api_toast(this.message.value, this.state.ttype).then((r) => {
      if(r.status !== 204) {
        this.props.dispatch({ type: 'TOAST', title: 'There was an error with your request', msgtype: 'error' });
      }
      else {
        this.props.dispatch({ type: 'TOAST', title: 'Message sent!', msgtype: 'success' });
      }
    });
  }

  reloadQueue() {
    api_reloadScreensQueue(this.screenid.value).then((r) => {
      if(r.status !== 204) {
        this.props.dispatch({ type: 'TOAST', title: 'There was an error with your request', msgtype: 'error' });
      }
      else {
        this.props.dispatch({ type: 'TOAST', title: 'Qureue reloaded', msgtype: 'success' });
        this.screenid.value = '';
      }
    });
  }


  render() {
    return(<Row>
      <Col>
        <Card className="card-accent-info">
          <CardHeader>Wallboard Controller</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <h4>Send Message</h4>
                <p>
                  <Input type={"text"} placeholder={"Enter message here"} innerRef={input => (this.message = input)}/>
                </p>
                <p>
                  <Label>Type</Label>
                  <SelectMod
                    options={[
                      {id: 'info', name: 'info'},
                      {id: 'success', name: 'success'},
                      {id: 'warning', name: 'warning'},
                      {id: 'error', name: 'error'},
                      {id: 'default', name: 'default'}
                    ]}
                    getOptionLabel={o => o.name}
                    getOptionValue={o => o.id}
                    onChange={o => this.setState({ttype: o.id})}
                  />
                </p>
                <p><Button color={"primary"} onClick={() => this.toast()}>Send</Button></p>
              </Col>
              <Col>
                <h4>Privacy</h4>
                <p><Button color={"success"} onClick={() => this.setPrivacy('on')}>On</Button>{' '}
                  <Button color={"danger"} onClick={() => this.setPrivacy('off')}>Off</Button></p>
              </Col>
              <Col>
                <h4>Reload Screens</h4>
                <p><Button color={"primary"} onClick={() => this.reloadScreens()}>Reload All Screens</Button></p>
              </Col>
              <Col>
                <h4>Refresh Queue</h4>
                <p>
                  <Input type={"text"} placeholder={"Queue/Screen ID"} innerRef={input => (this.screenid = input)}/>
                </p>
                <p><Button color={"primary"} onClick={() => this.reloadQueue()}>Refresh</Button></p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>)
  }
}

export default connect()(WallBoard);