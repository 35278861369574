import { apiRequest } from '../Api/ApiRequest';

export function api_getServiceDbNniOptions() {
  return apiRequest({
    url: '/sdb/nnis?include=carrier',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}