import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FilteredTableV2 from "../../components/FilteredTableV2";
import {ScaleLoader} from "react-spinners";
import {SET_ACTIVE_ENTRY, unlinkTemplate} from "../../actions/configTemplate";
import {checkPermission} from "../../utils/Auth/AuthService";
import {configEntry} from "../../utils/ConfigTemplate";
import {useNavigate} from 'react-router-dom'
import {SpinnerInfo} from "../../components/Spinner/Spinner";


const LinkedTemplates = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {activeEntry} = useSelector(state => state.configTemplate);
    const {required_templates: requiredTemplates} = useSelector(state => state.configTemplate.activeEntry);
    const auth = useSelector(state => state.authenticationState);
    const {linkingTemplate} = useSelector(state => state.configTemplate);

    const getActiveEntry = (entryName) => {
        configEntry(entryName).then(result => {
            if (result.status === 200) {
                dispatch({type: SET_ACTIVE_ENTRY, data: result.data})
            }
        });
    }

    const createColumns = () => {
        return (
            [
                {
                    header: "Description",
                    accessorKey: "description",
                    cell: (rowData) => {
                        return <>
                            <p className="d-inline">{rowData.getValue()}</p>
                            <i style={{cursor: "pointer"}}
                               className="fa fa-search ml-1"
                               onClick={
                                   () => {
                                       getActiveEntry(rowData.row.original.id)
                                       navigate('/toolbox/configtemplate/' + rowData.row.original.id)
                                   }
                               }
                            />
                        </>
                    }
                },
                {
                    header: "ID",
                    accessorKey: "id",
                },
                {
                    header: "Unlink",
                    size: 1,
                    cell: (e) =>
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center">
                                {
                                    checkPermission("ConfigTemplates", auth.account.permissions)
                                        ?
                                        <i
                                            style={{cursor: 'pointer', color: "red"}}
                                            className='fa fa-trash'
                                            onClick={() => dispatch(unlinkTemplate(activeEntry.id, e.row.original.id, e.row.original))}
                                        />
                                        : null
                                }
                            </Col>
                        </Row>
                }
            ]
        )
    }
    if (!activeEntry) {
        return <SpinnerInfo/>
    }
    return (
        <Card className='card-accent-warning fadeIn animated'>
            <CardHeader style={{paddingBottom: '8px'}}>
                <Row>
                    <Col md={9} className='d-flex justify-content-start align-items-center fadeIn animated'>
                        <h5 className="m-0 mirror d-inline">Linked Templates</h5>
                    </Col>
                    <Col md={3} className='d-flex justify-content-end align-items-center fadeIn animated'>
                        {linkingTemplate ? <ScaleLoader color="red" height="30"/> : null}
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                {activeEntry ?
                    <FilteredTableV2
                        columns={createColumns()}
                        data={requiredTemplates ?? []}
                        hideFilter
                        hideNoData
                        loading={false}
                    />
                    : <SpinnerInfo/>
                }

            </CardBody>
        </Card>
    )
}

export default LinkedTemplates;
