import { apiRequest } from '../Api/ApiRequest';

export const NoteService = {

  get: (id) => {
    return apiRequest({
      url: `/notes/${id}?include=createdBy`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  create: (data) => {
    return apiRequest({
      url: `/notes?include=createdBy`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  downloadAttachment: (id) => {
    return apiRequest({
      url: `/notes/${id}/attachment`,
      method: 'get',
      responseType: 'blob'
    })
      .then(
        resp => resp
      ).catch(
        error => error
      );
  },
  update: (id, data) => {
    return apiRequest({
      url: `/notes/${id}?include=createdBy`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  delete: (id) => {
    return apiRequest({
      url: `/notes/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}
