import React, { useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { ButtonIcon } from '../../ButtonIcon';
import { Badge, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import BillTemplate from '../../../views/Billing/BillTemplate';
import HeadlessModal from '../../Modals/HeadlessModal';
import moment from 'moment/moment';

const BillTemplatesTable = ({
  templates,
  fetchData,
  onCreate,
  onUpdate
}) => {
  const [showModal, setShowModal] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const toggleModal = () => setShowModal(prevState => !prevState)

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 250,
    },
    {
      header: 'Bill Ref',
      accessorKey: 'reference'
    },
    {
      header: 'Bill Payer',
      accessorKey: 'company',
      cell: props => props.getValue()?.name ?? ''
    },
    {
      header: 'PO Number',
      accessorKey: 'poNumber'
    },
    {
      header: 'Type',
      accessorKey: 'default',
      cell: props =>
        <Badge style={{minWidth: '86px'}} className={`p-2 faded-${props.getValue() ? 'info' : 'warning'}`}>{props.getValue() ? 'Default Bill' : 'Other'}</Badge>

    },
    {
      header: 'Status',
      accessorKey: 'isActive',
      cell: props =>
        <Badge style={{minWidth: '86px'}} className={`p-2 faded-${props.getValue() ? 'success' : 'warning'}`}>{props.getValue() ? 'Active' : 'Deactivated'}</Badge>

    },
    {
      header: 'Created On',
      accessorKey: 'createdOn',
      cell: (props) => {
        return moment(props.getValue())
          .format('DD/MM/YYYY HH:mm:ss');
      }

    },
    {
      header: 'Modified On',
      accessorKey: 'modifiedOn',
      cell: (props) => {
        return moment(props.getValue())
          .format('DD/MM/YYYY HH:mm:ss');
      }
    },
  ];
  const tableFilter = (row, columnId, value) => {
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.poNumber)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.company.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.reference)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }

  return <>
    <FilteredTableV2
      withFilter={tableFilter}
      withDeactivatedRow
      minRows={20}
      columns={columns}
      data={templates}
      noDataText="No bill templates"
      showPagination
      defaultPageSize={20}
      defaultSorted={[{
        id: 'createdOn',
        desc: true
      }]}
      onRowClick={(template) => {
        setTemplateId(template.id)
        toggleModal()
      }}
      extraButtons={[
        <Button
          onClick={toggleModal}
          colour={'secondary'}
          size='sm'
          color='secondary'>
          New Template
        </Button>,
        <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
      ]}
    />
    <HeadlessModal
      open={showModal}
      onClosed={() => setTemplateId(null)}
      size={'xlg'}
      toggle={toggleModal}
    >
      <BillTemplate
        id={templateId}
        closeModal={toggleModal}
        onCreate={onCreate}
        onUpdate={onUpdate}
      />
    </HeadlessModal>
  </>
}

export default BillTemplatesTable;