import { TASKS_REMOVE_TASK, TASKS_SET_LIST, TASKS_UPDATE_TASK } from '../actions/tasks';

const initialState = {
  list: [],
  showModal: false
}
export function tasks(state = initialState, action) {

  switch (action.type) {
    case TASKS_SET_LIST:
      return {
        ...state,
        list: action.list

      }
    case TASKS_REMOVE_TASK:
      return {
        ...state,
        list: state.list.filter(task => task.id !== action.task.id)
      }
    case TASKS_UPDATE_TASK:
      return {
        ...state,
        list: state.list.map(task => {
          if(task.id === action.task.id){
            return action.task
          }
          return task
        })
      }
    default:
      return state
  }

}
