import {
  IPAM_DOMAIN_ADD_BLOCK, IPAM_DOMAIN_REMOVE_BLOCK,
  IPAM_DOMAIN_RESET_DATA,
  IPAM_DOMAIN_SET_DATA,
  IPAM_DOMAIN_SET_FIELD, IPAM_DOMAIN_UPDATE_BLOCK
} from '../../../actions/ServiceDB/Ipam/domain';

const initialState = {
  data: {
    blocks: []
  },
  original: {
    blocks: []
  }
};
const domain = (state = initialState, action) => {
  switch (action.type) {
    case IPAM_DOMAIN_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case IPAM_DOMAIN_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case IPAM_DOMAIN_RESET_DATA:
      return {
        ...initialState
      };
    case IPAM_DOMAIN_ADD_BLOCK:
      return {
        ...state,
        data: { ...state.data, blocks: [...state.data.blocks, action.blockData] },
        original: { ...state.original, blocks: [...state.original.blocks, action.blockData] }
      };
    case IPAM_DOMAIN_UPDATE_BLOCK:
      const updatedBlocksWithUpdatedBlock = state.data.blocks.map((block) => block.id === action.blockData.id ? action.blockData : block);
      return {
        ...state,
        data: { ...state.data, blocks: updatedBlocksWithUpdatedBlock },
        original: { ...state.original, blocks: updatedBlocksWithUpdatedBlock }
      };
    case IPAM_DOMAIN_REMOVE_BLOCK:
      const updatedBlocksWithoutDelete = state.data.blocks.filter((block) => block.id !== action.blockId);
      return {
        ...state,
        data: { ...state.data, blocks: updatedBlocksWithoutDelete },
        original: { ...state.original, blocks: updatedBlocksWithoutDelete }
      };
    default:
      return state;
  }
};

export default domain;