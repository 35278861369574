import { errorFeedback } from './feedback';
import { authenticateSocket } from './socketio';

export const AUTH_SET_LAST_ACTIVITY = 'AUTH_SET_LAST_ACTIVITY';
export const AUTH_SET_DETAILS = 'AUTH_SET_DETAILS';
export const AUTH_SET_AUTHENTICATED = 'AUTH_SET_AUTHENTICATED';
export const AUTH_ADD_TESTER_AD_GROUP = 'AUTH_ADD_TESTER_AD_GROUP';
export const AUTH_SET_TESTER_AD_GROUPS = 'AUTH_SET_TESTER_AD_GROUPS';
export const AUTH_REMOVE_TESTER_AD_GROUP = 'AUTH_REMOVE_TESTER_AD_GROUP';
export const AUTH_RESET_TESTER_AD_GROUPS = 'AUTH_RESET_TESTER_AD_GROUPS';
export const AUTH_RESET = 'AUTH_RESET';
export const TOGGLE_INACTIVE ='TOGGLE_INACTIVE'
export const AUTH_SET_INTERACTION_REQUIRED ='AUTH_SET_INTERACTION_REQUIRED'


export function setLastActivity(timestamp) {
  return {
    type: AUTH_SET_LAST_ACTIVITY,
    timestamp
  };
}

export function addTesterAdGroup(group) {
  return {
    type: AUTH_ADD_TESTER_AD_GROUP,
    group
  };
}

export function setTesterAdGroups(groups) {
  return {
    type: AUTH_SET_TESTER_AD_GROUPS,
    groups
  };
}

export function removeTesterAdGroup(group) {
  return {
    type: AUTH_REMOVE_TESTER_AD_GROUP,
    group
  };
}

export function resetTesterADGroups(permissions) {
  return {
    type: AUTH_RESET_TESTER_AD_GROUPS
  };
}

export function setAuthInteractionRequired(required){
  return {
    type: AUTH_SET_INTERACTION_REQUIRED,
    required
  }
}

