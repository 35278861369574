import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  createBlock,
  deleteBlock,
  getBlock,
  resetBlock,
  setBlockField,
  updateBlock
} from '../../../../actions/ServiceDB/Ipam/block';
import GenericCardForm from '../../../../components/GenericCardForm';
import { Col, Row } from 'reactstrap';
import SiteService from '../../../../utils/Site/SiteService';
import form from './form';
import { DomainService } from '../../../../utils/ServiceDB/Ipam/Domain';
import isEmpty from 'lodash.isempty';
import Subnets from './Subnets';
import Validator from '../../../../helpers/Validator';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../../actions/breadcrumbs';
import { canAccessServiceDb } from '../../../../utils/Auth/AuthService';
import { useNavigate, useParams } from 'react-router-dom'

const Block = ({
  id = null,
  domain = null,
  hideSubnets = null,
  data,
  original,
  onCreated = null,
  onUpdated = null,
  onDeleted = null
}) => {

  // router
  const navigate = useNavigate();
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const blockId = useMemo(() => (id || params.blockId), [id, params.blockId])
  const isNew = useMemo(() => blockId === 'new' && !original.id, [blockId, original.id])

  useEffect(() => {
    if (original.name) {
      dispatch(addBreadcrumbs([{ name: original.name }]))
    }

    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [original.name]);

  const aSyncOptions = {
    domain: (search) => DomainService.list([], [{ id: 'name', value: search }])
      .then((result) => result.data || []),
    site: (search) => SiteService.list([], [
      { id: 'id', value: search },
      { id: 'name', value: search }
    ], [], 'or', [], true)
      .then((result) => result.data || [])
  };

  const requestStructure = (data) => ({
    name: data.name,
    description: data.description,
    domainId: data.domain?.id,
    ownerId: data.owner?.accountnumber,
    networkAddress: data.networkAddress,
    mask: data.mask,
  });

  const fetch = () => {
    setLoading(true);
    dispatch(getBlock(blockId, ['owner', 'domain', 'subnets']))
      .then(() => setLoading(false));
  };

  const extraFieldValidation = (key, value) => {
    const errorArr = [];
    if (key === 'networkAddress' && !(Validator.Ipam.validIpv4(data[key]) || Validator.Ipam.validIpv6(data[key]))) {
      errorArr.push(`The ${value.label} is invalid`);
    }
    if (key === 'mask' && (Validator.Ipam.validIpv4(data.networkAddress) || Validator.Ipam.validIpv6(data.networkAddress))) {
      if (!Validator.Ipam.validMask(data.networkAddress, data[key])) {
        errorArr.push(`The ${value.label} must be between 1 and ${Validator.Ipam.validIpv4(data.networkAddress) ? '32' : '128'}.`);
      }
    }
    return errorArr;
  };

  return (
    <Row>
      <Col lg={hideSubnets ? 12 : 6}>
        <GenericCardForm
          title={data.name || 'New Block'}
          isNew={isNew}
          form={form}
          data={data}
          original={original}
          extraFormData={{ domainProvided: !isEmpty(domain) }}
          extraFieldValidation={extraFieldValidation}
          aSyncOptions={aSyncOptions}
          requestStructure={requestStructure}
          onFetch={fetch}
          onFetchAll={() => {
            const promises = [];
            if (domain) {
              promises.push(dispatch(setBlockField('domain', domain)));
            }
            return Promise.all(promises);
          }}
          onReset={() => dispatch(resetBlock())}
          setField={(field, value) => dispatch(setBlockField(field, value))}
          onCreate={(toCreate) => dispatch(createBlock(toCreate, ['owner', 'domain', 'subnets']))}
          onCreated={(result) => onCreated ? onCreated(result) : navigate(`/sdb/ipam/blocks/${result.id}`)}
          onUpdate={(toUpdate) => dispatch(updateBlock(blockId, toUpdate, ['owner', 'domain', 'subnets']))}
          onUpdated={(result) => onUpdated ? onUpdated(result) : {}}
          onDelete={() => dispatch(deleteBlock(blockId))}
          onDeleted={() => onDeleted ? onDeleted(blockId) : navigate('/sdb/ipam/blocks')}
          canEdit={canAccessServiceDb()}
          deleteButtonDisabled={original.subnets.length > 0}
          deleteButtonDisabledTooltip="Block has existing subnets"
        />
      </Col>
      {!isNew && !hideSubnets ? (
        <Col lg={6}>
          <Subnets
            subnets={original.subnets}
            loading={loading}
            block={original}
            canAddAndDelete={canAccessServiceDb()}
          />
        </Col>
      ) : ''}
    </Row>
  );
};

function mapStateToProps({ ipamBlock, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    data: ipamBlock.data,
    original: ipamBlock.original,
  };
}

export default connect(mapStateToProps)(Block);
