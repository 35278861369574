import FilteredTableV2 from '../../FilteredTableV2';
import React from 'react';

const SiteContacts = ({
  contacts,
  accountContacts,
  sites,
  accountSites,
  contactTypes
}) => {

  const resolveData = () => {
    return contacts ? resolveContactData() : resolveSiteData()
  }
  const resolveSiteData = () => {

    return Object.values(sites.reduce((carry, contactSite) => {
      if(!carry[ contactSite.id]){
        const site = accountSites.find(site => site.accountid === contactSite.id);
        if(site){
          carry[contactSite.id] = {
            id: contactSite.id,
            name: site.name,
            types: []
          };
        }else{
          return carry;
        }
      }
      carry[contactSite.id].types.push(contactSite.type);
      return carry;
    }, {}));

  };

  const resolveContactData = () => {

    return Object.values(contacts.reduce((carry, siteContact) => {
      if(!carry[ siteContact.id]){
        const contact = accountContacts.find(contact => contact.id === siteContact.id);
        if(contact){
          carry[siteContact.id] = {
            id: siteContact.id,
            name: contact.fullName,
            types: []
          };
        }else{
          return carry;
        }
      }
      carry[siteContact.id].types.push(siteContact.type);
      return carry;
    }, {}));

  }
  const columns = [
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Contact Types',
      accessorKey: 'types',
      cell: props => {
        return props.getValue().map((val) => {

          const typeFound = contactTypes.find(type => type.value === val);
          if (typeFound) {

            return <span key={`${props.row.original.name  }${  val}`}
                         className="meta-button mr-2 mt-1 d-inline-block">{typeFound.label}</span>;

          }
          return <></>
        });
      }
    },
  ]

  const tableFilter = (row, columnId, value) => {
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      row.original.types.filter(val => {
        const typeFound = contactTypes.find(type => type.value === val);
        return typeFound && String(typeFound.label).toLowerCase().indexOf(value.toLowerCase()) !== -1
      }).length > 0
    )
  }

  return <FilteredTableV2
    minRows={10}
    columns={columns}
    data={resolveData()}
    showPagination={resolveData().length > 10}
    defaultPageSize={10}
    pageSizeOptions={[10,20,50,100]}
    noDataText={'No Contacts'}
    sortable={false}
    withFilter={tableFilter}
  />


}

export default SiteContacts;
