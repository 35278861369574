import {SHIFTS_SET, TIMEOFF_SET} from '../actions/shifts';
import update from 'immutability-helper';

const initialState = {
  lastUpdate: null,
  shiftdata: {},
  timeoffdata: {}
};

export default function shifts(state = initialState, action) {
  switch (action.type) {
    case SHIFTS_SET:
      return update(state, {
        lastUpdate: {
          $set: Date.now()
        },
        shiftdata: {
          [action.teamid]: {
            $set: action.data
          }
        }
      });
    case TIMEOFF_SET:
      return update(state, {
        lastUpdate: {
          $set: Date.now()
        },
        timeoffdata: {
          [action.teamid]: {
            $set: action.data
          }
        }
      });
    default:
      return state;
  }
}
