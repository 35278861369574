import React, { Fragment } from 'react';
import ReactTable from 'react-table-v6';
import { Button, UncontrolledTooltip } from 'reactstrap';

export const FailedJobs = ({ jobs, retry }) => {
  const columns = [
    {
      Header: 'Job Id',
      accessor: 'id',
      width: 100,
      sortable: false
    },
    {
      Header: 'Billing Run',
      accessor: 'billingRun',
      width: 100
    },
    {
      Header: 'Account',
      accessor: 'account',
      minWidth: 150,
      Cell: props => (
                <a href={props.original.accountUrl} target="_blank" className={'text-primary text-wrap'}>{props.value}</a>
      )
    },
    {
      Header: 'Error',
      accessor: 'error',
      minWidth: 300
    },
    {
      Header: 'Failed At',
      accessor: 'failedAt'
    },
    {
      Header: () => <Button color={'warning'} onClick={retry.bind(null, 'all')} size={'sm'}>Retry All</Button>,
      accessor: 'id',
      width: 100,
      sortable: false,
      Cell: props => (
                <Fragment>
                    <div onClick={retry.bind(this, props.value)} style={{ cursor: 'pointer' }} id={'tooltip' + props.value} className={'h3 text-center text-success mb-0'}><i className="icon icon-refresh"></i></div>
                    <UncontrolledTooltip placement="top" target={'tooltip' + props.value}>
                        Retry
                    </UncontrolledTooltip>
                </Fragment>
      )
    }
  ];

  return (
        <ReactTable
            className="animated fadeIn -highlight -striped"
            NoDataComponent={() => null}
            data={jobs}
            columns={columns}
            minRows="2"
            filterable={jobs.length > 20}
            showPagination={jobs.length > 20}
            resizable={false}
            getTheadProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadTrProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadThProps={() =>{
              return {
                style: { border: 'none', whiteSpace: 'unset' }
              };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                style: { border: 'none', whiteSpace: 'unset', fontSize: '10pt' }
              };
            }}
            defaultSorted={[{ id: 'validTo', desc: false }]}
        />
  );
};
