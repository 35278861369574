import { apiRequest } from '../Api/ApiRequest'

export function api_getUserOpportunities(user){
  return apiRequest({
    url: '/users/'+user+'/opportunities?search=status:0&with=company;owner;priceList;priceList.currency&include=priceList,priceList.currency',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getOpportunities(search = "search=status:0&with=company;owner;priceList;priceList.currency&include=priceList,priceList.currency"){
  return apiRequest({
    url: `/opportunities?${search}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
