import Avatar from '../../Avatar';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { checkPermission } from '../../../utils/Auth/AuthService';
import React, { useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { sign } from 'chart.js/helpers';
import isEmpty from 'lodash.isempty';
import { nanoid } from 'nanoid';

const AccountSignaturesTable = ({
  handleFinalDownload,
  handleCurrentDownload,
  signatures,
  fetchData,
  user
}) => {

  const [pagination, setPagination] = useState({pageIndex : 0, pageSize: 20})
  const [sorting, setSorting] = useState([{ id: 'updatedAt', desc: true }])

  const onSorting = (sortingState, searchString) => {
    if(sortingState !== sorting){
      fetchData(pagination.pageIndex + 1, pagination.pageSize, searchString, sortingState )
      setSorting(sortingState)
    }
  }
  const handleDownload = (sigId, status = 'signed') => {
    if (status === 'signed') {
      handleFinalDownload(sigId);
    } else {
      handleCurrentDownload(sigId);
    }
  };

  const getReference = (relatedTo) =>{
    const type = relatedTo.type
    let reference = ''
    switch (type){
      case 'quote':
        reference = relatedTo.data.number
        break
      case 'creditRequest':
        reference = relatedTo.data.requestNumber
        break
      case 'order':
        reference = relatedTo.data.orderNumber
        break
      default:
        reference = relatedTo.data?.name ?? ''
    }

    return reference
  }
  const getBadgeColor = (status) => {
    switch (status) {
      case 'draft':
        return 'secondary';
      case 'declined':
        return 'danger';
      case 'signed':
        return 'success';
      default:
        return 'warning';
    }
  };
  const getSignatory = (tag, data, emailOnly = false) => {
    const signatory  = data.signatories.find(signatory => signatory.documentTag === tag)

    if(emailOnly){
      return signatory?.signatory.data?.email ?? ''
    }
    return (
      <div>
          <span id={`sigav_${tag}-${data.id}`}>
            <Avatar name={signatory?.signatory.data?.name}
                    email={signatory?.signatory.data?.email}
                    size={32}
                    className="img-avatar"/>
          </span>
        <UncontrolledTooltip target={`sigav_${tag}-${data.id}`} placement="right">
          {signatory?.signatory.data?.name}
        </UncontrolledTooltip>
      </div>
    )

  }
  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
      maxSize: 60
    },
    {
      header: 'Title',
      accessorKey: 'title',
      minSize: 300
    },
    {
      header: 'Owner',
      accessorKey: 'owner',
      cell: (props) => <>
        <span id={'avatar_' + props.row.original.id}>
                      <Avatar name={props.getValue().name} email={props.getValue().email}
                              size={32} className="img-avatar"/>
                    </span>
        <UncontrolledTooltip target={'avatar_' + props.row.original.id} placement="right">
          {props.getValue().name}
        </UncontrolledTooltip>
      </>,
      maxSize: 30
    },
    {
      header: 'hSo Sig',
      cell: (props) => getSignatory(1, props.row.original),
      maxSize: 30
    },
    {
      header: 'Recipient',
      cell: (props) => getSignatory(0, props.row.original, true),
      maxSize: 30
    },
    {
      header: 'Reference',
      accessorKey: 'relatedTo',
      cell: props => getReference(props.getValue()) ?? ''

    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (props) =>  <Badge style={{minWidth: '86px'}} className={`p-2 faded-${getBadgeColor(props.getValue())}`}>{props.getValue()}</Badge>
    },
    {
      header: 'Update At',
      accessorKey: 'updatedAt',
      cell: (props) => moment(props.getValue()).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      header: 'Document',
      id: nanoid(),
      cell: (props) => (props.row.original.owner.id === user.cui || checkPermission('Management', user.permissions)) && props.row.original.status !== 'draft' && props.row.original.status !== 'declined' ? (
        <Button size={'sm'} onClick={(e) => {
          e.preventDefault();
          handleDownload(props.row.original.id, props.row.original.status);
        }}>Download</Button>
      ) : ''
    }
  ];

  return (
    <>
      <FilteredTableV2
        withServerSideFilter={(searchString) => fetchData(1, pagination.pageSize, searchString, sorting)}
        minRows={10}
        data={signatures.items}
        columns={columns}
        noDataText={'No Signatures'}
        pages={signatures.table.lastPage}
        pageSize={signatures.table.perPage}
        onPaginationChange={setPagination}
        pagination={pagination}
        showPagination
        defaultPageSize={50}
        onFetchData={(page, size, searchString) => {
          fetchData(page, size, searchString, sorting)
        }}
        defaultSorted={sorting}
        withServerSideSorting
        onSorting={(sorting, searchString) => onSorting(sorting, searchString)}
      />
    </>
  )
}

export default AccountSignaturesTable