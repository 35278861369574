import { BILLING_RUN_TOGGLE_RUNS_LOADING, BILLING_RUNS_ADD_RUN, BILLING_RUNS_REMOVE_RUN, BILLING_RUNS_SET_RUNS } from '../actions/billingRuns';
import { BILLING_RUN_UPDATE_RUN } from '../actions/billingRun';

const initialState = {
  runs: [],
  loading: false
};

export default function billingRuns(state = initialState, action) {
  switch (action.type) {
    case BILLING_RUN_TOGGLE_RUNS_LOADING:
      return {
        ...state,
        loading: !state.loading
      };

    case BILLING_RUNS_SET_RUNS:
      return {
        ...state,
        runs: action.runs
      };

    case BILLING_RUNS_ADD_RUN:
      return {
        ...state,
        runs: [...state.runs, action.data]
      };
    case BILLING_RUNS_REMOVE_RUN:
      return {
        ...state,
        runs: state.runs.filter(run => run.id !== action.id)
      };
    case BILLING_RUN_UPDATE_RUN:
      const data = action.data ? action.data : action.run;
      return {
        ...state,
        runs: state.runs.map(run => {
          if (run.id === data.id) {
            return data;
          }
          return run;
        })
      };
    default:
      return state;
  }
}
