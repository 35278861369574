import { NavLink, useLocation } from 'react-router-dom'
import {
  canAccessServiceDb,
  checkPermission,
  isSales,
  isSalesManager,
  isSDManager,
  isServiceDelivery, isShiftAdmin
} from '../../utils/Auth/AuthService';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import { Environment } from '../../utils/Helpers/Environment';
import startsWith from 'lodash.startswith'

const Sidebar = ({ auth, logicalServiceTypes, signaturecount }) =>{

  const location = useLocation();

  const handleClick = (e) => {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  const activeRoute = (routeName) => {
    return startsWith(location.pathname, routeName) ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
  }

  // secondLevelActive(routeName) {
  //   return props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
  // }
    return (

      <div className={`${!Environment.isProduction() ? 'bg-success' : ''} sidebar`}>
        <nav className="sidebar-nav">
          <ul className="nav">
            <li className="nav-item">
              <NavLink to={'/dashboard'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-speedometer"></i> Dashboard</NavLink>
            </li>
              <li className="nav-item">
                  <NavLink to={'/accounts'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-people"></i> Accounts</NavLink>
              </li>
              {(checkPermission('Billing', auth.account.permissions))
              && <li className={activeRoute('/billing')}>
                  <NavLink to={'/billing'} className="nav-link"><i className="icon-wallet"></i> Billing</NavLink>
              </li>
              }
            <li className={activeRoute('/compliance')}>
              <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}><i
                className="icon-umbrella"></i> Compliance</a>
              <ul className="nav-dropdown-items">
                {(checkPermission('NOC', auth.account.permissions) || checkPermission('FieldOps', auth.account.permissions)) &&
                <li className="nav-item">
                  <NavLink to={'/compliance/configbackup'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                    className="fa fa-archive"></i> Config Backups</NavLink>
                </li>
                }
                <li className="nav-item">
                  <NavLink to={'/compliance'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                    className="icon-eyeglass"></i> Security Scans</NavLink>
                </li>
                {(checkPermission('NOC', auth.account.permissions) || checkPermission('FieldOps', auth.account.permissions)) &&
                <li className="nav-item">
                  <NavLink to={'/compliance/shadowserver'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                    className="fa fa-shield"></i> Shadow Server</NavLink>
                </li>
                }
              </ul>
            </li>
            {!Environment.isProduction()
              && <li className={activeRoute('/cases')}>
                <NavLink to={'/cases'} className="nav-link" ><i className="icon-note"></i> Cases</NavLink>
              </li>
            }
             {/* <li className={activeRoute('/circuits')}>
                  <NavLink to={'/circuits'} className="nav-link" ><i className="icon-crop"></i> Circuits</NavLink>
              </li>*/}
            <li className={activeRoute('/discovery')}>
              <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}><i className="icon-map"></i> Discovery</a>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <NavLink to={'/discovery/exchanges'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className={"icon-cursor"}></i> Closest Exchanges</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={'/discovery/pops'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className={"icon-cursor"}></i> Closest PoPs</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={'/discovery/serving'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className={"icon-cursor"}></i> Serving Exchanges</NavLink>
                </li>
              </ul>
            </li>
              {(checkPermission('NOC', auth.account.permissions) || checkPermission('FieldOps', auth.account.permissions))
              && <li className={activeRoute('/toolbox')}>
                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}><i className="icon-wrench"></i> NOC Toolbox</a>
                  <ul className="nav-dropdown-items">
                      <li className="nav-item">
                        <NavLink to={'/toolbox/alarms'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-bell"></i> Alarms</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to={'/toolbox/arppps'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-graph"></i> ARP Scanner</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to={'/toolbox/configtemplate'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="fa fa-puzzle-piece"></i> Config Templates</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink to={'/toolbox/coreconfig'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="fa fa-wrench"></i> Core Config Checker</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink to={'/toolbox/coresnap'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="fa fa-camera-retro"></i> Core Snapshots</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink to={'/toolbox/dcheck'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-directions"></i> Device Checker</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink to={'/toolbox/dns'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-organization"></i> DNS</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink to={'/toolbox/exttraffic'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-equalizer"></i> External Traffic</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink to={'/toolbox/iptable'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-calculator"></i> IP Table</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to={'/toolbox/devices'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="fa fa-cubes"></i> NetAPI Devices</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink to={'/toolbox/prefixlists'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-share"></i> Prefix Lists</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink to={'/toolbox/radius'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-lock" /> Radius</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to={'/toolbox/realtime'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-graph"></i> Realtime Traffic</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to={'/toolbox/rrgroups'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="fa fa-object-group"></i> Route Reflector Groups</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink to={'/toolbox/status'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-info"></i> Status Site</NavLink>
                      </li>
                      {(checkPermission('NOC Admin', auth.account.permissions)) &&
                        <li className="nav-item">
                          <NavLink to={'/toolbox/wallboard'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-screen-desktop"></i> Wallboard Control</NavLink>
                        </li>
                      }
                      <li className="nav-item">
                          <NavLink to={'/toolbox/maps'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="icon-map"></i> Weather Maps</NavLink>
                      </li>
                  </ul>
              </li>
              }
            {checkPermission('PreSales', auth.account.permissions) &&
              <li className={activeRoute('/products')}>
                <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}><i
                  className="icon-layers"></i> Products</a>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <NavLink to={'/products/pricelists'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="icon-list"></i> Price Lists</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/products/pricing'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-dollar"></i> Pricing</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/products/slas'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-android"></i> SLAs</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/products/templates'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-file"></i> Templates</NavLink>
                  </li>
                </ul>
              </li>
            }
              <li className={activeRoute('/reports')}>
                <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}><i
                  className="icon-chart"></i> Reports</a>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <NavLink to={'/reports/circuitrecon'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="icon-link"></i> Circuit Reconciliation</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/reports/services'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                        className="fa fa-phone"></i> Account Services</NavLink>
                  </li>
                  {isShiftAdmin(auth.account.permissions)
                      &&
                  <li className="nav-item">
                    <NavLink to={'/shifttracking'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                        className="fa fa-check-circle-o"></i> Shift Checkins</NavLink>
                  </li>}
                </ul>
              </li>
              {
                canAccessServiceDb() ? (
              <li className={activeRoute("/sdb")}>
                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}><i className="fa fa-database"></i> Service DB</a>
                <ul className="nav-dropdown-items">
                  <li className="nav-title">
                    Physical
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/vendors'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-shopping-bag"></i> Vendors</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/device-models'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-clone"></i> Device Models</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/stock'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-barcode"></i> Stock</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/licenses'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-certificate"></i> Licenses</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/device-deployments'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-hdd-o"></i> Device Deployments</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/racks'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-server"></i> Racks</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/connections'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-plug"></i> Connections</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/nnis'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-product-hunt"></i> NNIs</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/carrier-circuits'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-link"></i> Carrier Circuits</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/cross-connects'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-connectdevelop"></i> Cross Connects</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/sdb/optical-systems'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-random"></i> Optical Systems</NavLink>
                  </li>
                  {!Environment.isProduction() &&
                    <>
                      <li className="nav-title">
                        IPAM
                      </li>
                      <li className="nav-item">
                        <NavLink to={'/sdb/ipam/domains'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                          className="fa fa-map-signs"></i> IP Domains</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to={'/sdb/ipam/blocks'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                          className="fa fa-location-arrow"></i> IP Blocks</NavLink>
                      </li>
                      <li className="nav-title">
                        Logical
                      </li>
                      <li className="nav-item">
                        {logicalServiceTypes.map((service, index) => (
                          <NavLink key={`serviceType-${index}`} to={`/sdb/services/${service.slug}`} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                            className="fa fa-cubes"></i> {service.label}</NavLink>
                        ))}
                      </li>
                    </>
                  }
                </ul>
              </li>
                ) : ''}
            {checkPermission(['Sales', 'PreSales', 'KenyaSales', 'Marketing'], auth.account.permissions)
              && <li className={activeRoute('/sales')}>
                <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}><i
                  className="fa fa-money"></i> Sales</a>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <NavLink to={'/sales/leads'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-road"></i> Leads</NavLink>
                  </li>
                  {isSales(auth.account.permissions) &&
                    <>
                      <li className="nav-item">
                        <NavLink to={'/sales/opportunities'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                          className="fa fa-binoculars"></i> Opportunities</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to={'/sales/orders'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                          className="fa fa-list-alt"></i> Orders</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to={'/sales/pricing-tool'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                          className="fa fa-calculator"></i> Pricing Tool</NavLink>
                      </li>
                    </>
                  }
                  {isSalesManager(auth.account.permissions) &&
                  <li className="nav-item">
                    <NavLink to={'/sales/reports'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i className="fa fa-list"></i> Reports</NavLink>
                  </li>
                }
              </ul>
            </li>
            }
              {isServiceDelivery(auth.account.permissions)

                  && <li className={activeRoute('/service-delivery')}>
                      <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}><i className="icon-magic-wand"></i> Service Delivery</a>
                      <ul className="nav-dropdown-items">
                        <li className={activeRoute('/service-delivery/orders')}>
                          <NavLink to={'/service-delivery/orders'} className="nav-link" ><i className="fa fa-list-alt"></i> Orders</NavLink>
                        </li>
                      </ul>
                      <ul className="nav-dropdown-items">
                          <li className={activeRoute('/service-delivery/wip')}>
                              <NavLink to={'/service-delivery/wip'} className="nav-link" ><i className="icon-rocket"></i> WIP</NavLink>
                          </li>
                      </ul>
                      {isSDManager()
                          && <ul className="nav-dropdown-items">
                              <li className={activeRoute('/service-delivery/wip')}>
                                  <NavLink to={'/service-delivery/lead-times'} className="nav-link" ><i className="icon-clock"></i> Lead Times</NavLink>
                              </li>
                          </ul>
                      }
                      <ul className="nav-dropdown-items">
                          <li className={activeRoute('/service-delivery/loa')}>
                              <NavLink to={'/service-delivery/loa'} className="nav-link" ><i className="icon-wrench"></i> LOA Generator</NavLink>
                          </li>
                      </ul>
                  </li>
              }
              <li className={activeRoute('/signatures')}>
                  <NavLink to={'/signatures'} className="nav-link" ><i className="icon-pencil"></i> Signatures{signaturecount > 0 && <Badge color="warning">{signaturecount}</Badge>}</NavLink>
              </li>
              {checkPermission('Developers', auth.account.permissions)
              && <li className={activeRoute('/developers')}>
                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}><i
                    className="icon-book-open"></i> Developers</a>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <NavLink to={'/developers/cqueues'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-list"></i> Celery Queues</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/developers/k8s'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-server"></i> K8s</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/developers/logs'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="fa fa-bug"></i> Logs</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/developers/queues'} className={({ isActive }) => `nav-link${isActive ? " active" : ""}`}><i
                      className="icon-link"></i> Lumen Queues</NavLink>
                  </li>

                </ul>
              </li>
              }
          </ul>
        </nav>
      </div>
    );
}

function mapStateToProps(state) {
  return {
    auth: state.authenticationState,
    logicalServiceTypes: state.helpers.serviceDb.logicalServiceTypes,
    signaturecount: state.signatures.toSign
  };
}

export default connect(mapStateToProps)(Sidebar);
