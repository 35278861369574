import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';


export const OpportunityService = {
  relatedServices: (id) => {
    return apiRequest({
      url: `/opportunities/${id}/related-services`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
}
export function api_getUserOpportunity(userId, id, include, withArr){
  return apiRequest({
    url: `/opportunities/${id}${queryStringFromFields({include, with: withArr.join(';')})}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_closeOpportunity(user, opportunity, statusReason){
  return apiRequest({
    url: `/opportunities/${opportunity}/lose`,
    method: 'put',
    data: {
      statusReason
    }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_createUserOpportunity(user, args, include, withArr){
  return apiRequest({
    url: `/users/${user}/opportunities${queryStringFromFields({include, with: withArr.join(';')})}`,
    method: 'post',
    data: {...args}
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateUserOpportunity(user, opportunity, args, include, withArr) {
  return apiRequest({
    url: `/opportunities/${opportunity}${queryStringFromFields({include, with: withArr.join(';')})}`,
    method: 'put',
    data: args
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_restoreUserOpportunity(user, opportunity, include, withArr) {
  return apiRequest({
    url: `/opportunities/${opportunity}/restore${queryStringFromFields({include, with: withArr.join(';')})}`,
    method: 'put',
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
