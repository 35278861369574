import { ConnectionService } from '../utils/ServiceDB/Connection';
import { defaultErrorFeedback } from './feedback';

export const CONNECTIONS_SET_CONNECTIONS = 'CONNECTIONS_SET_CONNECTIONS';

function setConnections(data) {
  return {
    type: CONNECTIONS_SET_CONNECTIONS,
    data
  }
}
export function getConnections(page, pageSize, searchString, sorting, includes, withs, portWiths, nniWiths) {
  return (dispatch) => {
    return ConnectionService.getConnections(page, pageSize, searchString, sorting, includes, withs, portWiths, nniWiths)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setConnections(result.data));
          return true;
        }
        dispatch(defaultErrorFeedback());
        return false;
      });
  };
}