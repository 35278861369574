import update from 'immutability-helper';
import {
  AUTH_SET_AUTHENTICATED,
  AUTH_SET_DETAILS,
  AUTH_SET_LAST_ACTIVITY, AUTH_ADD_TESTER_AD_GROUP, AUTH_REMOVE_TESTER_AD_GROUP, AUTH_RESET,
  TOGGLE_INACTIVE, AUTH_RESET_TESTER_AD_GROUPS, AUTH_SET_TESTER_AD_GROUPS, AUTH_SET_INTERACTION_REQUIRED
} from '../actions/authentication';
import { USER_LOGOUT } from '../actions/user';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import { stateMap } from '../utils/Cases/CasesService';

const initialState = {
  authentication: {},
  account: {
    permissions: [],
    originalPermissions: []
  },
  testerProfile: [],
  lastActivity: moment()
    .unix(),
  inactive:false,
  interactionRequired: false
};

export default function authenticationState(state = initialState, action) {
  switch (action.type) {
    case AUTH_SET_AUTHENTICATED:
      return update(state, {
        authentication: { $set: action.result.data }
      });
    case AUTH_SET_DETAILS:
      return {
        ...state,
        account: {
          ...action.result,
          permissions: !isEmpty(state.account.permissions) ? state.account.permissions : action.result.permissions,
          originalPermissions: action.result.permissions,
        }
      };
    case AUTH_SET_LAST_ACTIVITY:
      return {
        ...state,
        lastActivity: action.timestamp
      };
    case AUTH_ADD_TESTER_AD_GROUP:
      return {
        ...state,
        account: {
          ...state.account,
          permissions: [...state.account.permissions, action.group]
        },
        testerProfile: [...state.testerProfile, action.group]
      };
    case AUTH_REMOVE_TESTER_AD_GROUP:
      return {
        ...state,
        account: {
          ...state.account,
          permissions: state.account.permissions.filter((group) => group !== action.group)
        },
        testerProfile: state.testerProfile.filter(group => group !== action.group)
      };
    case AUTH_RESET_TESTER_AD_GROUPS:
      return {
        ...state,
        account: {
          ...state.account,
          permissions: [...state.account.originalPermissions]
        },
        testerProfile: []
      };
    case AUTH_SET_TESTER_AD_GROUPS:
      return {
        ...state,
        testerProfile: [...action.groups]
      }
    case AUTH_RESET:
      return {
        ...initialState
      };
    case TOGGLE_INACTIVE:
      return {
        ...state,
        inactive: !state.inactive,
        lastActivity: Math.ceil(new Date().getTime() / 1000)
      };
    case AUTH_SET_INTERACTION_REQUIRED:
      return{
        ...state,
        interactionRequired: action.required
      }
    default:
      return state;
  }
}
