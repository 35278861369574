import { Modal, ModalBody } from 'reactstrap';

const HeadlessModal = ({
  children,
  open,
  onClosed,
  onOpened,
  size,
  toggle,
  backdrop
}) => {

  return (
    <Modal
      isOpen={open}
      className={`modal-${size}`}
      onClosed={onClosed}
      toggle={toggle}
      onOpened={onOpened}
      backdrop={backdrop || 'static'}
    >
      <ModalBody className='p-0 mb-0'>
        {children}
      </ModalBody>
    </Modal>
  )
}

export default HeadlessModal;
