import { apiRequest, customerApiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';
export function api_searchAccounts(searchString) {
  let query = {
    search:`name:${searchString};accountNumber:${searchString}`,
    searchFields: 'name:like;accountNumber:like',
  }
  return apiRequest({
    url: `/accounts${queryStringFromFields(query)}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_createAccount(data){
  return apiRequest({
    url:`/accounts`,
    method: 'post',
    data: data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getAccountBills(accountIdOrNumber) {
  return apiRequest({
    url: '/accounts/' + accountIdOrNumber + '/bills',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getAccountCases(accountIdOrNumber, state = '0', from = null, to = null, legacy = null, include = null) {
  const params = { state, from, to, legacy: legacy ? Number(legacy) : null, include };
  const query = Object.keys(params)
    .map(key => params[key] !== null ? key + '=' + params[key] : '')
    .filter(param => param !== '')
    .join('&');
  return apiRequest({
    url: `/accounts/${accountIdOrNumber}/cases${query ? `?${query}` : ''}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getAccountSites(accountIdOrNumber, include = ['primaryContact']) {
  return apiRequest({
    url: `/accounts/${accountIdOrNumber}/sites${queryStringFromFields({include})}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getAccountPartnerships(accountIdOrNumber, include = ['primaryContact']) {
  return apiRequest({
    url: `/accounts/${accountIdOrNumber}/partnerships${queryStringFromFields({include})}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getRelatedSites(accountIdOrNumber, includes = ['primaryContact']) {
  return apiRequest({
    url: `/accounts/${accountIdOrNumber}/sites/related?include=${includes.join(',')}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getAccountConfigItems(accountIdOrNumber, activeOnly = false) {
  return apiRequest({
    url: `/accounts/${accountIdOrNumber}/configitems?include=assignedTo,location,manufacturer&activeOnly=${activeOnly ? '1' : '0'}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getAccountOrdersData(accountIdOrNumber) {
  return apiRequest({
    url: '/accounts/' + accountIdOrNumber + '/orders?include=contact,owner&with=contact;owner',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getUserToken(contactId) {
  return customerApiRequest({
    url: '/emulate/',
    method: 'post',
    data: { userid: contactId }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function api_activatePortalAccess(accountId, contactId) {
  return apiRequest({
    url: '/accounts/' + accountId + '/activate',
    method: 'post',
    data: { contactId }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_dropAccountCache(idOrNumber) {
  return apiRequest({
    url: '/accounts/' + idOrNumber + '/cache',
    method: 'put'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getAccountServicesData(idOrNumber, allServices) {
  let query = {
    with:'site;company',
    include: ['company', 'site']
  }
  return apiRequest({
    url: `/accounts/${idOrNumber}/services${queryStringFromFields(query)}`,
    method: 'GET'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_addNewAccountSite(idOrNumber, args) {
  return apiRequest({
    url: `/accounts/${idOrNumber}/sites?include=primaryContact`,
    method: 'post',
    data: args
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_updateAccountInfo(idOrNumber, args) {
  return apiRequest({
    url: `/accounts/${idOrNumber}`,
    method: 'put',
    data: args
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getAccountContacts(idOrNumber){
  return apiRequest({
    url: `/accounts/${idOrNumber}/contacts`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getAccountLogs(idOrNumber){
  return apiRequest({
    url: `/accounts/${idOrNumber}/logs`,
    method: 'get'
  })
  .then(
    resp => resp
  )
  .catch(
    error => error
  );
}

export function api_getAccountDocuments(idOrNumber) {
  return apiRequest({
    url: `/accounts/${idOrNumber}/documents`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getDocument(accoundIdOrNumber, id) {
  return apiRequest({
    url: `/accounts/${accoundIdOrNumber}/documents/${id}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_downloadDocument(accoundIdOrNumber, id) {
  return apiRequest({
    url: `/accounts/${accoundIdOrNumber}/documents/${id}/download`,
    method: 'get',
    responseType: 'blob'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_uploadDocument(accountIdOrNumber, data) {
  const formData = new FormData();
  Object.entries(data).forEach(([field, value]) => {
    formData.append(field, value);
  });
  return apiRequest({
    url: `/accounts/${accountIdOrNumber}/documents`,
    method: 'post',
    data: formData
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_deleteDocument(accountIdOrNumber, documentId) {
  return apiRequest({
    url: `/accounts/${accountIdOrNumber}/documents/${documentId}`,
    method: 'delete'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateDocument(accountIdOrNumber, documentId, data) {
  return apiRequest({
    url: `/accounts/${accountIdOrNumber}/documents/${documentId}`,
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export const AccountServiceDeprecated = {
  activate: (accountId, contactId) =>{
    return apiRequest({
      url: '/accounts/activate',
      method: 'post',
      data: { accountId: accountId, contactId: contactId }
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getActiveAccounts: () => {
    return apiRequest({
      url: '/accounts',
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getAccountData: (accountId) => {
    return apiRequest({
      url: '/accounts/' + accountId + '?include=contacts,portalUser',
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getUserToken: (accountId, contactId) =>{
    return apiRequest({
      url: '/accounts/' + accountId + '/emulate',
      method: 'post',
      data: { contactId: contactId }
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
};
