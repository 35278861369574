import {
  CONNECTION_ADD_COMPONENT, CONNECTION_ADD_NOTE,
  CONNECTION_ADD_SERVICE, CONNECTION_ADD_SERVICES,
  CONNECTION_DESTROY_COMPONENT,
  CONNECTION_MOVE_COMPONENT,
  CONNECTION_PERSIST_COMPONENT,
  CONNECTION_REMOVE_COMPONENT, CONNECTION_REMOVE_NOTE,
  CONNECTION_REMOVE_SERVICE,
  CONNECTION_RESET_DATA, CONNECTION_RESET_SERVICES, CONNECTION_SET_AUDIT_HISTORY,
  CONNECTION_SET_COMPONENT_BY_TYPE_AND_ID,
  CONNECTION_SET_DATA,
  CONNECTION_SET_FIELD,
  CONNECTION_UPDATE_COMPONENT, CONNECTION_UPDATE_NOTE,
  CONNECTION_UPDATE_SERVICE
} from '../actions/connection';

const initialState = {
  data: {
    components: [],
    notes: []
  },
  original: {
    components: [],
    notes: []
  },
  logicalServices: [],
  audits: []
};

export default function connection(state = initialState, action) {
  switch (action.type) {
    case CONNECTION_SET_DATA:
      const newData = { ...action.data };
      const newOriginal = { ...action.data };
      if (action.mergeComponents) {
        newData.components = [...state.data.components];
        newOriginal.components = [...state.original.components];
      }
      return {
        ...state,
        data: newData,
        original: newOriginal
      };
    case CONNECTION_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case CONNECTION_ADD_COMPONENT:
      return {
        ...state,
        data: {
          ...state.data,
          components: [...state.data.components, action.data]
            .map(component => component.order >= action.data.order && component.id !== action.data.id ? {
              ...component,
              order: component.order + 1
            } : component)
            .sort((a, b) => a.order - b.order)
        }
      };
    case CONNECTION_UPDATE_COMPONENT:
      return {
        ...state,
        data: {
          ...state.data,
          components: state.data.components.map(component => {
            if ((!action.id && !component.id) || action.id === component.id) {
              return { ...component, data: {...component.data, ...action.data} };
            }
            return component;
          })
        }
      };
    case CONNECTION_PERSIST_COMPONENT:
      const dataComponents = state.data.components.map(component => {
        if ((!action.id && !component.id) || action.id === component.id) {
          return { ...component, ...action.data };
        }
        return component;
      });
      return {
        ...state,
        data: {
          ...state.data,
          components: dataComponents
        },
        original: {
          ...state.original,
          components: dataComponents
        }
      };
    case CONNECTION_SET_COMPONENT_BY_TYPE_AND_ID:
      return {
        ...state,
        data: {
          ...state.data,
          components: state.data.components.map(component => {
            if (action.componentType === component.type && action.data.id === component.data.id) {
              return { ...component, data: { ...action.data } };
            }
            return component;
          })
        },
        original: {
          ...state.original,
          components: state.original.components.map(component => {
            if (action.componentType === component.type && action.data.id === component.data.id) {
              return { ...component, data: { ...action.data } };
            }
            return component;
          })
        }
      };
    case CONNECTION_REMOVE_COMPONENT:
      return {
        ...state,
        data: {
          ...state.data,
          components: state.data.components.filter(component => !((!action.id && !component.id) || action.id === component.id))
            .map((component, index) => ({ ...component, order: index + 1 }))
        }
      };
    case CONNECTION_MOVE_COMPONENT:
      const componentToMove = state.data.components.find(component => component.id === action.id);
      const newOrder = componentToMove.order + (action.direction === 'up' ? 1 : -1);
      return {
        ...state,
        data: {
          ...state.data,
          components: state.data.components.map(component => {
            let order = Number.parseInt(component.order);
            if (component.id === action.id) {
              order = newOrder;
            } else if (Number.parseInt(component.order) === newOrder) {
              order = Number.parseInt(componentToMove.order); // swap places with componentToMove
            }
            return {
              ...component,
              order
            };
          })
            .sort((a, b) => a.order - b.order)
        }
      };
    case CONNECTION_DESTROY_COMPONENT:
      return {
        ...state,
        original: {
          ...state.original,
          components: state.original.components.filter(component => !((!action.id && !component.id) || action.id === component.id))
            .map((component, index) => ({ ...component, order: index + 1 }))
        }
      };
    case CONNECTION_ADD_SERVICES:
      return {
        ...state,
        logicalServices: [...state.logicalServices, ...action.logicalServices]
      };
    case CONNECTION_ADD_SERVICE:
      return {
        ...state,
        logicalServices: [...state.logicalServices, { ...action.serviceData, type: action.serviceType }]
      };
    case CONNECTION_UPDATE_SERVICE:
      return {
        ...state,
        logicalServices: state.logicalServices
          .map((service) => service.type === action.serviceType && service.id === action.serviceData.id ? {
            ...action.serviceData,
            type: action.serviceType
          } : service)
      };
    case CONNECTION_REMOVE_SERVICE:
      return {
        ...state,
        logicalServices: state.logicalServices
          .filter((service) => !(service.type === action.serviceType && service.id === action.serviceId))
      };
    case CONNECTION_RESET_SERVICES:
      return {
        ...state,
        logicalServices: [ ...initialState.logicalServices ]
      };
    case CONNECTION_RESET_DATA:
      return {
        ...initialState
      };
    case CONNECTION_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    case CONNECTION_ADD_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: [...state.data.notes, action.note]
        },
        original: {
          ...state.original,
          notes: [...state.original.notes, action.note]
        },

      }
    case CONNECTION_UPDATE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
        original: {
          ...state.original,
          notes: state.original.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
      }
    case CONNECTION_REMOVE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.filter(note => note.id !== action.note.id)
        },
        original: {
          ...state.original,
          notes: state.original.notes.filter(note => note.id !== action.note.id)
        }
      }
    default:
      return state;
  }
}