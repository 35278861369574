import React  from 'react';
import { Badge, Card, CardBody} from 'reactstrap';
import FilteredTable from '../../../components/FilteredTable';
import { Link, useNavigate } from 'react-router-dom'
import Connection from '../Connection';
import { useDispatch } from 'react-redux'

const OpticalSystemConnections = ({ connections }) => {

  // router
  const navigate = useNavigate();

  const NNILink = ({ nni }) => (
    <><Link to={`/sdb/nnis/${nni.id}`}>{nni.name}</Link> <Badge color={'primary'}>NNI</Badge></>
  );
  const DeviceDeploymentLink = ({ deviceDeployment }) => (
    <Link to={`/sdb/device-deployments/${deviceDeployment.id}`}>{deviceDeployment.hostname}</Link>
  )

  const getEndDevice = (end) => {
    if (end.type === undefined) {
      return (<span>Wires Only</span>);
    }
    if (end.type === 'nni') {
      return <NNILink nni={end.data}/>
    }
    return <DeviceDeploymentLink deviceDeployment={end.data.deviceDeployment}/>
  }

  const columns = [
    {
      Header: 'Connection',
      accessor: 'name'
    },
    {
      Header: 'Channel',
      accessor: 'wavelength.channel',
      width: 90
    },
    {
      Header: 'A End',
      accessor: 'aEnd',
      Cell: ({ value }) => getEndDevice(value)
    },
    {
      Header: 'B End',
      accessor: 'bEnd',
      Cell: ({ value }) => getEndDevice(value)
    }
  ];

  return (<>
    <Card>
      <CardBody>
        <FilteredTable
          hideFilter={true}
          data={connections}
          columns={columns}
          showPagination={connections.length > 10}
          defaultSorted={{ id: 'name', desc: false }}
          onRowClick={(connection) => navigate(`/sdb/connections/${connection.id}`)}
        />
      </CardBody>
    </Card>
  </>);

};

export default OpticalSystemConnections;
