import { defaultErrorFeedback } from './feedback';
import { api_getProductGroups } from '../utils/Products/ProductService';

export const PRODUCT_GROUPS_SET = 'PRODUCT_GROUPS_SET'


const setProductGroups = (groups) => {
  return {
    type: PRODUCT_GROUPS_SET,
    groups
  }
}

export function getProductGroups(){
  return (dispatch) => {
    return api_getProductGroups().then(
      (result) => {
        if(result.status === 200){
          dispatch(setProductGroups(result.data))
        }else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}
