import React from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { Button } from 'reactstrap';
import { ButtonIcon } from '../../ButtonIcon';
import { useNavigate } from 'react-router-dom'

const NnisTable = ({
  nnis,
  fetchData,
  minRows,
  extraButtons,
  withoutNew,
  withoutFetch
}) => {

  const navigate = useNavigate();

  const columns = [

    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Reference',
      accessorKey: 'ref'
    },
    {
      header: 'Bearer',
      accessorKey: 'bearerSpeed',
      cell: props => props.getValue() ? `${props.getValue()}G` : ''
    },
    {
      header: 'Carrier',
      accessorKey: 'carrier.name'
    },
    {
      header: 'Device',
      accessorKey: 'port.deviceDeployment.hostname'
    },
    {
      header: 'Port',
      accessorKey: 'port.name'
    },
    {
      header: '# of Connected Circuits',
      accessorKey: 'connectionCount'
    }
  ];

  const getButtons = () => {
    const buttons = []
    if(!withoutNew){
      buttons.push( <Button size={'sm'} color={'secondary'} onClick={() => {
        navigate(`/sdb/nnis/new`)
      }}>New NNI</Button>)
    }
    if(!withoutFetch){
      buttons.push( <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>)
    }
    return extraButtons? buttons.concat(extraButtons) : buttons
  }

  return (
    <FilteredTableV2
      withFilter={'includesString'}
      minRows={minRows ?? 20}
      columns={columns}
      data={nnis ?? []}
      noDataText="No NNIs"
      showPagination={nnis?.length > (minRows ?? 20)}
      defaultPageSize={minRows ?? 20}
      defaultSorted={[{
        id: 'name',
        desc: false
      }]}
      onRowClick={(nni) => {
        navigate(`/sdb/nnis/${nni.id}`)
      }}
      extraButtons={getButtons()}
    />
  )
}

export default NnisTable;
