export const ProductTemplateForm = {
  name: {
    label: 'Name',
    type: 'text',
    plaintext: true
  },
  productCode: {
    label: 'Product Code',
    type: 'select',
    plaintext: true
  },
  productGroup: {
    label: 'Group',
    type: 'select',
    mandatory: true
  },
  pricingType: {
    label: 'Pricing',
    type: 'select',
    mandatory: true
  },
  affinityBillingCodeInstall: {
    label: 'Billing Code - Install',
    type: 'text'
  },
  affinityBillingCodeRental: {
    label: 'Billing Code - Rental',
    type: 'text'
  },
  productSLA: {
    label: 'SLA',
    type: 'select'
  }
}
