import { deepFreeze } from '../Helpers/DeepFreeze';


export const DeviceDeploymentEnums = deepFreeze({
  type: {
    core: 1,
    cpe: 2,
    server: 3,
    switch: 4,
    mux: 5,
    pdu: 6,
    panel: 7,
    nte: 8
  },
});