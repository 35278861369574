import FilteredTableV2 from '../../FilteredTableV2';
import { Button } from 'reactstrap';
import { ButtonIcon } from '../../ButtonIcon';
import { useNavigate } from 'react-router-dom'
import {isSdbManager} from "../../../utils/Auth/AuthService";
import React, {useCallback} from "react";

const VendorsTable = ({
  vendors,
  fetchData
}) => {

  const navigate = useNavigate();

  const columns = [
    {
      filterable: true,
      sortable: true,
      header: 'Name',
      accessorKey: 'name'
    },
    {
      filterable: false,
      sortable: true,
      header: 'Number of Models',
      accessorKey: 'deviceModelCount'
    }
  ]

  const getButtons = () => {
    const buttons  = []
    if(isSdbManager()){
      buttons.push(
        <Button size={'sm'} color={'secondary'} onClick={() => {
          navigate(`/sdb/vendors/new`)
        }}>New Vendor</Button>
      )
    }
    buttons.push(<ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>)
    return buttons;
  }

  return (
    <FilteredTableV2
      withFilter={'includesString'}
      minRows={10}
      columns={columns}
      data={vendors}
      noDataText="No Vendors"
      showPagination={vendors.length > 10}
      defaultPageSize={10}
      defaultSorted={[{
        id: 'name',
        desc: false
      }]}
      onRowClick={(vendor) => {
        navigate(`/sdb/vendors/${vendor.id}`)
      }}
      extraButtons={getButtons()}
    />
  )

}


export default VendorsTable;
