import {
  PRODUCT_SLAS_ADD_SLA, PRODUCT_SLAS_RESET_FILE,
  PRODUCT_SLAS_SET,
  PRODUCT_SLAS_SET_SEARCH_STRING,
  PRODUCT_SLAS_SET_SLA,
  PRODUCT_SLAS_SET_SLA_FIELD, PRODUCT_SLAS_SET_SLA_FILE, PRODUCT_SLAS_UPDATE_SLA
} from '../actions/productSLAs';

const initialState = {
  searchString: '',
  list: [],
  filteredList: [],
  sla: {
    form: {
      name: '',
      file: '',
    },
    original: {
      name: '',
      file: '',
    }
  },
  file: {}
}

export function productSLAs(state = initialState, action){
  switch (action.type) {
    case PRODUCT_SLAS_SET:
      return {
        ...state,
        list: action.slas
      }
    case PRODUCT_SLAS_SET_SEARCH_STRING:
      const searchString = action.searchString !== undefined ? action.searchString : state.searchString;
      const filteredList = state.list.filter((sla) => {
        return (String(sla.name)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1 ||
          String(sla.s3Key)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
        );
      });

      return {
        ...state,
        searchString,
        filteredList
      };
    case PRODUCT_SLAS_UPDATE_SLA:
      return {
        ...state,
        list: state.list.map(sla => {
          if(sla.id === action.sla.id){
            return action.sla
          }
          return sla
        }),
        filteredList: state.filteredList.map(sla => {
          if(sla.id === action.sla.id){
            return action.sla
          }
          return sla
        })
      }
    case PRODUCT_SLAS_SET_SLA:
      return {
        ...state,
        sla: {
          form: action.sla,
          original: action.sla,
        }
      }
    case PRODUCT_SLAS_SET_SLA_FIELD:
      return {
        ...state,
        sla: {
          ...state.sla,
          form: {
            ...state.sla.form,
            ...action.field
          }
        }
      }
    case PRODUCT_SLAS_SET_SLA_FILE:
      return {
        ...state,
        file: {...action.file}
      }
    case PRODUCT_SLAS_ADD_SLA:
      return {
        ...state,
        list: [...state.list, action.sla]
      }
    case PRODUCT_SLAS_RESET_FILE:
      return {
        ...state,
        sla: {
          ...state.sla,
          form: {
            ...state.sla.form,
            s3Key: null,
            s3Bucket: null,
          }
        }
      }
    default:
      return state
  }

}
