import Ring from 'ringjs';
import { LOGTAIL_ADD_MESSAGE, LOGTAIL_CLEAR_MESSAGES, LOGTAIL_CLOSE_DIALOG } from '../actions/logtail';
import update from 'immutability-helper';

const initialState = {
  display: false,
  messages: Ring(50)
};

export default function LogTail(state = initialState, action) {
  switch (action.type) {
    case LOGTAIL_ADD_MESSAGE:
      const newMessage = state.messages;
      newMessage.push(action);
      return update(state,
        {
          messages: { $set: newMessage },
          display: { $set: true }
        });
    case LOGTAIL_CLOSE_DIALOG:
      return update(state,
        {
          display: { $set: false }
        });
    case LOGTAIL_CLEAR_MESSAGES:
      return update(state,
        {
          messages: { $set: Ring(50) },
          display: { $set: false }
        });
    default:
      return state;
  }
}
