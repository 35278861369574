import {apiRequest} from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const StockItemService = {
    list: ({ search, includes, withs } = {}) => {
        let query = {
            search,
            include: includes,
            with: withs
        };
        return apiRequest({
            url: `/sdb/stock${queryStringFromFields(query)}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    get: (id, { includes, withs } = {}) => {
        return apiRequest({
            url: `/sdb/stock/${id}${queryStringFromFields({ include: includes, with: withs })}`,
            method: 'get'
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    create: (data, { includes, withs } = {}) => {
        return apiRequest({
            url: `/sdb/stock${queryStringFromFields({ include: includes, with: withs })}`,
            method: 'post',
            data
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    update: (id, data, { includes, withs } = {}) => {
        return apiRequest({
            url: `/sdb/stock/${id}${queryStringFromFields({ include: includes, with: withs })}`,
            method: 'put',
            data
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    delete: (id) => {
        return apiRequest({
            url: `/sdb/stock/${id}`,
            method: 'delete'
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    attachLicense: (id, licenseId) => {
        return apiRequest({
            url: `/sdb/stock/${id}/licenses/${licenseId}`,
            method: 'put'
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    detachLicense: (id, licenseId) => {
        return apiRequest({
            url: `/sdb/stock/${id}/licenses/${licenseId}`,
            method: 'delete'
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },

};
