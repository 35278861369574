import { apiRequest } from '../Api/ApiRequest';

export function api_getZabbixProxies() {
  return apiRequest({
    url: '/alarms/proxies',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getInterfaces(proxy, host, community) {
  return apiRequest({
    url: '/alarms/snmpInterfaces',
    method: 'post',
    data: {
      proxy: proxy,
      host: host,
      community: community
    }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
