import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getOpportunities,
  getUserOpportunities, setOpportunitiesData
} from '../../actions/opportunities';
import LoadingOverlay from '../../components/LoadingOverlay';
import { isSalesManager } from '../../utils/Auth/AuthService';
import {
  CardBody,
  Card
} from 'reactstrap';
import OpportunitiesTable from '../../components/Tables/Opportunities';
const Opportunities = (props) => {
  const {
    opportunities,
    opportunityHelpers,
    user,
    dispatch
  } = props;
  const [loadingOpportunities, setLoadingOpportunities] = useState(false);

  useEffect(() => {
    if (!opportunities.list.length) {
      getData();
    }
  }, []);

  const getData = () => {
    setLoadingOpportunities(true);
    dispatch(setOpportunitiesData([]))
    if (isSalesManager(user.permissions)) {
      dispatch(getOpportunities(user.cui))
        .then(() => setLoadingOpportunities(false));
    } else {
      dispatch(getUserOpportunities(user.cui))
        .then(() => setLoadingOpportunities(false));
    }
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loadingOpportunities}>
            <OpportunitiesTable
              excludeColumns={['status']}
              opportunities={opportunities.list}
              user={user}
              fetchData={getData}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>

    </div>
  );

};

function mapStateToProps({
  opportunities,
  authenticationState,
  helpers
}) {
  return {
    opportunities,
    user: authenticationState.account,
    opportunityHelpers: helpers.optionSets.opportunity
  };
}

export default connect(mapStateToProps)(Opportunities);
