import {apiRequest} from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';
import isEmpty from 'lodash.isempty';

export const RackService = {
    list: (page, pageSize, search, sorting) => {
        let query = {
            search,
            with: 'site',
            include: ['items.site', 'items.deviceDeployments', 'items.deviceDeployments.stockItem'],
            sortedBy: !isEmpty(sorting) ? (sorting[0].desc ? 'desc' : 'asc') : null,
            orderBy: !isEmpty(sorting) ? sorting[0].id : null,
            page,
            pageSize
        }
        if(isEmpty(search)){

            delete query.search
        }
        return apiRequest({
            url: `/sdb/racks${queryStringFromFields(query)}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    getRack: (id, include = []) => {
        return apiRequest({
            url: `/sdb/racks/${id}${queryStringFromFields({ include })}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    search: (term, include = []) => {
        return apiRequest({
            url: `/sdb/racks/search${queryStringFromFields({ term, include })}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    createRack: (data, include = []) => {
        return apiRequest({
            url: `/sdb/racks${queryStringFromFields({ include })}`,
            method: 'post',
            data
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    updateRack: (id, data, include = []) => {
        return apiRequest({
            url: `/sdb/racks/${id}${queryStringFromFields({ include })}`,
            method: 'put',
            data
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    deleteRack: (id) => {
        return apiRequest({
            url: `/sdb/racks/${id}`,
            method: 'delete'
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
};