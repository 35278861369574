import { Link, useNavigate } from 'react-router-dom'
import { Badge, Button } from 'reactstrap';
import FilteredTableV2 from '../../FilteredTableV2';
import { ButtonIcon } from '../../ButtonIcon';
import { CSVLink } from 'react-csv';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { SelectMod } from '../../Selects/SelectMod';
import { isSales, isSalesManager } from '../../../utils/Auth/AuthService';
import HeadlessModal from '../../Modals/HeadlessModal';
import Opportunity from '../../../views/Opportunity';
import { OpportunityEnums } from '../../../utils/Constants/Opportunity';
import { CurrencyEnums } from '../../../utils/Constants/Currency';
import Currency from '../../Currency';
import { PriceListEnums } from '../../../utils/Constants/PriceList';

const OpportunitiesTable = ({
  excludeColumns,
  includeColumns,
  opportunities,
  opportunityHelpers,
  openInModal,
  fetchData,
  user,
  account,
  priceLists
}) => {

  const navigate = useNavigate();

  const [showOpportunityModal, setShowOpportunityModal] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [userFilter, setUserFilter] = useState({
    value: null,
    label: 'All Owners'
  });
  const toggle = () => setShowOpportunityModal(prevState => !prevState);

  const getStatusColour = (status) => {
    if(status === OpportunityEnums.status.OPEN){
      return 'faded-warning'
    }
    return status === OpportunityEnums.status.LOST ? 'faded-danger' : 'faded-success'
  }
  const getPriceListCurrency = (opportunity) => {
    return priceLists.find((priceList) => opportunity.priceList === priceList.uuid || opportunity.priceList?.uuid === priceList.uuid )?.currency?.id ?? CurrencyEnums.currency.GBP
  }

  const getCurrentPriceList = (priceListType) => {
    return priceLists.find((priceList) => priceList.status.id === PriceListEnums.status.ACTIVE && priceList.type?.id === priceListType)
  }

  let columns = [
    {
      header: 'Topic',
      accessorKey: 'name',
      minSize: 250,
    },
    {
      header: 'Number',
      accessorKey: 'number',
      enableSorting: false
    },
    {
      header: 'Customer',
      accessorKey: 'customer',
      cell: props => {
        if (props.row.original.customerType === 'account') {
          return <Link className={'td-clickable'}
            to={`/accounts/${props.row.original.customerId || props.getValue().accountid}`}>{props.getValue().name || props.getValue()}</Link>;
        }
        return <>
          <div className='d-flex justify-content-between' data-for={props.row.original.id} data-tip={props.row.original.id}>
            <div>{props.getValue()}</div>
            <div className='text-danger ml-1'>
              <i className='fa fa-exclamation-circle'/>
            </div>
          </div>
          <ReactTooltip
            id={props.row.original.id}
            type="dark"
            place="right"
            effect="float"
            multiline={false}
          >Customer must be an account!
          </ReactTooltip>
        </>;
      }
    },
    {
      header: 'Type',
      accessorKey: 'type',
      cell: props => (
        <div>{opportunityHelpers.type.options.find(x => x.value === props.getValue()).label}</div>
      )
    },
    {
      header: 'Probability',
      accessorKey: 'probabilityBand',
      cell: props => (
        <div>{opportunityHelpers.probabilityBand.options.find(x => x.value === props.getValue()).label}</div>
      )
    },
    {
      header: 'Estimate Close',
      accessorKey: 'estCloseDate',
      cell: props => props.getValue() ? moment(props.getValue())
        .format('DD/MM/YYYY') : ''
    },
    {
      header: 'TCV',
      accessorKey: 'estimatedTCV',
      cell: props => <Currency currency={props.row.original.priceList?.currency?.id} value={props.getValue()}/>
    },
    {
      header: 'Modified',
      accessorKey: 'modifiedOn',
      cell: props => props.getValue() ? moment(props.getValue())
        .format('DD/MM/YYYY HH:mm') : ''
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: props => <Badge style={{minWidth: '86px'}} className={`${getStatusColour(props.getValue())} p-2`}>{opportunityHelpers.status.options.find(option => option.value === props.getValue())?.label ?? '' }</Badge>
    },
    {
      header: (props) => {
        return <SelectMod
          options={getOwnersOptions()}
          isSearchable
          value={userFilter}
          onChange={(selected) => onUserFilterChange(selected, props)}
          size={'sm'}
        />
      },
      enableSorting: false,
      enableHiding: !isSalesManager(user.permissions),
      accessorKey: 'ownerUser',
      filterFn: (row, columnId, value) => {
        return !value || row.original.ownerUserId === value
      },
      minSize: 200
    }
  ];

  const getColumns = () => {
    if(excludeColumns){
      return  columns.filter(column => !excludeColumns.includes(column.accessorKey))
    }
    return includeColumns ? columns.filter(column => includeColumns.includes(column.accessorKey)) : columns
  }
  const onUserFilterChange = (selected, tableProps) => {
    tableProps.column.setFilterValue(selected.value)
    setUserFilter(selected)
  };

  const handleRowClick = (opportunity) => {
    if(opportunity.customerType !== 'account'){
      return
    }
    if(openInModal){
      setSelectedOpportunity(opportunity)
      toggle()
    }else{
      navigate(`/sales/opportunities/${opportunity.id}`)

    }
  };

  const getOwnersOptions = () => {
    let keep = {};
    return opportunities.reduce((result, opportunity) => {
      if (!keep[opportunity.ownerUserId]) {
        keep[opportunity.ownerUserId] = true;
        result.push(
          {
            value: opportunity.ownerUserId,
            label: opportunity.ownerUser
          }
        );
      }
      return result;
    }, [{
      value: null,
      label: 'All Owners'
    }]);
  };
  const exportAsCSV = () => {
    return opportunities.map((opportunity) => {
      return {
        topic: opportunity.name,
        customer: opportunity.customer,
        type: opportunityHelpers.type.options.find(x => x.value === opportunity.type).label,
        probability: opportunityHelpers.probabilityBand.options.find(x => x.value === opportunity.probabilityBand).label,
        owner: opportunity.ownerUser,
        status: opportunityHelpers.statusReason.options.find(x => x.value === opportunity.statusReason).label,
        estimateClose: opportunity.estCloseDate ? moment(opportunity.estCloseDate)
          .format('DD/MM/YYYY') : '',
        tcv: opportunity.estimatedTCV,
        yov: opportunity.yearOneValue,
        mrr: opportunity.totalMonthly,
        term: opportunity.contractTerm,
        priceList: opportunity.priceList?.name,
        currency: opportunity.priceList?.currency?.name,
      };
    });
  };

  const tableFilter = (row, columnId, value) => {
    const status = opportunityHelpers.type.options.find(x => x.value === row.original.type)?.label
    const probabilityBand = opportunityHelpers.probabilityBand.options.find(x => x.value === row.original.probabilityBand)?.label
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.customer)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.ownerUser)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(status)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(probabilityBand)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(String(row.original.estimatedTCV))
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.number)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }


  return (
    <>
      <FilteredTableV2
        enableColumnFilters={false}
        minRows={10}
        columns={getColumns()}
        data={opportunities}
        onRowClick={handleRowClick}
        showPagination={opportunities.length > 20}
        defaultSorted={[{
          id: 'modifiedOn',
          desc: true
        }]}
        defaultPageSize={50}
        withFilter={tableFilter}
        noDataText={'No Opportunities'}
        extraButtons={[
          <Button
            disabled={!isSalesManager(user.permissions) && !isSales(user.permissions)}
            size={'sm'}
            color={'secondary'}
            onClick={() => {
              if(account){
                navigate(`/sales/opportunities/new?account=${account.name}&accountId=${account.accountid}&priceListType=${account.priceListType}`)
              }else{
                navigate(`/sales/opportunities/new`)
              }

            }}
          >
            New Opportunity
          </Button>,
          <div data-for={'export'} data-tip={'export'} >
            <CSVLink id={'export'}
                     data={exportAsCSV()} filename={`opportunities-${moment().format('DD-MM-YYYY')}.csv`}
                     style={{ textDecoration: 'none' }}>

              <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
            </CSVLink>
          </div>,

          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
        ]}
      />
      <HeadlessModal size={'xxlg'} open={showOpportunityModal} toggle={toggle}>
        <Opportunity id={selectedOpportunity?.id}/>
      </HeadlessModal>
    </>

  );
}


function mapStateToProps({
  authenticationState,
  helpers,
  priceLists
}) {
  return {
    user: authenticationState.account,
    opportunityHelpers: helpers.optionSets.opportunity,
    priceLists: priceLists.list
  };
}

export default connect(mapStateToProps)(OpportunitiesTable);
