import {
  DOCUMENT_RESET_DOCUMENT,
  DOCUMENT_SET_DOCUMENT,
  DOCUMENT_UPDATE_FORM_FIELD
} from '../actions/document';

const initialState = {
  document: {},
  documentForm: {}
};

export default function document(state = initialState, action) {

  switch (action.type) {
    case DOCUMENT_SET_DOCUMENT:
      return {
        ...state,
        document: action.document,
        documentForm: action.document
      };
    case DOCUMENT_RESET_DOCUMENT:
      return {
        ...initialState
      };
    case DOCUMENT_UPDATE_FORM_FIELD:
      return {
        ...state,
        documentForm: {
          ...state.documentForm,
          [action.field]: action.value
        }
      };
    default:
      return state;
  }

}
