import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export function api_getTask(id) {
  return apiRequest({
    url: `/tasks/${id}?include=notes,notes.createdBy,relatedTo,assignedTo,createdBy`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getUserTasks2(userId, activeOnly = true) {
  let query =  activeOnly ? `?search=status:0` : ''
  return apiRequest({
    url: `/tasks${query}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getUserTasks(status = []) {
  let query = {
    search:`status:0`,
    searchFields: 'status:in',
    with:'assignedTo',
    include: ['assignedTo']
  }
  return apiRequest({
    url: `/tasks${queryStringFromFields(query)}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_createTask(data) {
  return apiRequest({
    url: `/tasks?include=notes,relatedTo,assignedTo,createdBy`,
    method: 'post',
    data
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_updateTask(id, data) {
  return apiRequest({
    url: `/tasks/${id}?include=notes,relatedTo,assignedTo,createdBy`,
    method: 'put',
    data
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export const api_createTaskNote = (data) => {
  return apiRequest({
    url: `/notes`,
    method: 'post',
    data
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
};
