import {
  api_generateCompareQuote,
  api_getCompareV2Quote, api_updateQuote,
  api_updateQuoteSitePricePreferred
} from '../utils/PricingTool';
import { defaultErrorFeedback, errorFeedback, warningFeedback } from './feedback';
import { api_getAddressesByPostcode } from '../utils/PostcodeLookup';

export const PRICING_TOOL_QUOTE_SET_NOT_FOUND = 'PRICING_TOOL_QUOTE_SET_NOT_FOUND';
export const PRICING_TOOL_QUOTE_SET_DATA = 'PRICING_TOOL_QUOTE_SET_DATA';
export const PRICING_TOOL_QUOTE_RESET = 'PRICING_TOOL_QUOTE_RESET';
export const PRICING_TOOL_QUOTE_UPDATE_FIELD = 'PRICING_TOOL_QUOTE_UPDATE_FIELD';
export const PRICING_TOOL_QUOTE_RESET_SITES = 'PRICING_TOOL_QUOTE_RESET_SITES';
export const PRICING_TOOL_QUOTE_UPDATE_SITE_PREFERRED_PRICE = 'PRICING_TOOL_QUOTE_UPDATE_SITE_PREFERRED_PRICE';
export const PRICING_TOOL_QUOTE_UPDATE_ORIGINAL = 'PRICING_TOOL_QUOTE_UPDATE_ORIGINAL';
export const PRICING_TOOL_QUOTE_REQUEST_UPDATE_FIELD = 'PRICING_TOOL_QUOTE_REQUEST_UPDATE_FIELD';
export const PRICING_TOOL_QUOTE_REQUEST_ADD_SITE = 'PRICING_TOOL_QUOTE_REQUEST_ADD_SITE';
export const PRICING_TOOL_QUOTE_REQUEST_REMOVE_SITE = 'PRICING_TOOL_QUOTE_REQUEST_REMOVE_SITE';
export const PRICING_TOOL_QUOTE_REQUEST_UPDATE_SITE_FIELD = 'PRICING_TOOL_QUOTE_REQUEST_UPDATE_SITE_FIELD';
export const PRICING_TOOL_QUOTE_REQUEST_UPDATE_SITE_SOLUTION = 'PRICING_TOOL_QUOTE_REQUEST_UPDATE_SITE_SOLUTION';
export const PRICING_TOOL_QUOTE_RESTORE_REQUEST = 'PRICING_TOOL_QUOTE_RESTORE_REQUEST';

function setNotFound(state) {
  return {
    type: PRICING_TOOL_QUOTE_SET_NOT_FOUND,
    state
  };

}

export function setPricingToolQuote(quote) {
  return {
    type: PRICING_TOOL_QUOTE_SET_DATA,
    quote
  };
}

export function resetPricingToolQuote() {
  return {
    type: PRICING_TOOL_QUOTE_RESET
  };
}

export function updatePricingToolQuoteField(field) {
  return {
    type: PRICING_TOOL_QUOTE_UPDATE_FIELD,
    field
  };
}

export function updatePricingToolQuoteSitePreferredPrice(siteId, priceId) {
  return {
    type: PRICING_TOOL_QUOTE_UPDATE_SITE_PREFERRED_PRICE,
    siteId,
    priceId
  };
}

export function updatePricingToolQuoteRequestField(field) {
  return {
    type: PRICING_TOOL_QUOTE_REQUEST_UPDATE_FIELD,
    field
  };
}

export function updatePricingToolQuoteRequestSiteField(siteIndex, field) {
  return {
    type: PRICING_TOOL_QUOTE_REQUEST_UPDATE_SITE_FIELD,
    siteIndex,
    field
  };
}

export function updatePricingToolQuoteRequestSiteSolution(siteIndex, solution) {
  return {
    type: PRICING_TOOL_QUOTE_REQUEST_UPDATE_SITE_SOLUTION,
    siteIndex,
    solution
  };
}

export function addPricingToolQuoteRequestSite(fields) {
  return {
    type: PRICING_TOOL_QUOTE_REQUEST_ADD_SITE,
    fields
  };
}

export function removePricingToolQuoteRequestSite(siteId) {
  return {
    type: PRICING_TOOL_QUOTE_REQUEST_REMOVE_SITE,
    siteId
  };
}

export function restoreQuoteRequest() {
  return {
    type: PRICING_TOOL_QUOTE_RESTORE_REQUEST
  };
}

export function getPricingToolQuote(id, includes = []) {
  return (dispatch) => {
    return api_getCompareV2Quote(id, includes)
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        } else if (result.status === 404) {
          dispatch(setNotFound(true));
          return false;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };
}

export function updateQuotePricePreferred(priceId, preferred) {
  return (dispatch) => {
    return api_updateQuoteSitePricePreferred(priceId, preferred)
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: PRICING_TOOL_QUOTE_UPDATE_ORIGINAL });
          return true;
        } else if (result.status === 404) {
          dispatch(warningFeedback('Price not found'));
          return false;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };
}

export function updateQuote(quoteId, args) {
  return (dispatch) => {
    return api_updateQuote(quoteId, args)
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: PRICING_TOOL_QUOTE_UPDATE_ORIGINAL });
          return true;
        } else if (result.status === 404) {
          dispatch(setNotFound(true));
          return false;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };
}

export function lookupSitePostcode(siteIndex, postcode, field) {
  return (dispatch) => {
    return api_getAddressesByPostcode(postcode)
      .then((result) => {
        if (result.status === 200) {
          dispatch(updatePricingToolQuoteRequestSiteField(siteIndex, { [field]: result.data }));
          return result.data
        } else if (result.status === 422) {
          dispatch(updatePricingToolQuoteRequestSiteField(siteIndex, { errors: ['invalid postcode'] }));
        } else {
          dispatch(errorFeedback('There was an error with your request'));
        }
        return false
      });
  };
}

export function generateCompareQuote(args) {
  return (dispatch) => {
    return api_generateCompareQuote(args)
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        } else if (result.status === 422) {
          dispatch(errorFeedback(JSON.stringify(result.data)));
        }
        return false;
      });
  };
}

