import { StockItemService } from '../utils/ServiceDB/Stock';
import { defaultErrorFeedback } from './feedback';
import AuditService from '../utils/Audit';

export const STOCK_ITEM_SET_DATA = 'STOCK_ITEM_SET_DATA';
export const STOCK_ITEM_SET_FIELD = 'STOCK_ITEM_SET_FIELD';
export const STOCK_ITEM_RESET_DATA = 'STOCK_ITEM_RESET_DATA';
export const STOCK_ITEM_ADD_LICENSE = 'STOCK_ITEM_ADD_LICENSE';
export const STOCK_ITEM_UPDATE_LICENSE = 'STOCK_ITEM_UPDATE_LICENSE';
export const STOCK_ITEM_REMOVE_LICENSE = 'STOCK_ITEM_REMOVE_LICENSE';

export const STOCK_ITEM_ADD_NOTE = 'STOCK_ITEM_ADD_NOTE';
export const STOCK_ITEM_UPDATE_NOTE = 'STOCK_ITEM_UPDATE_NOTE';
export const STOCK_ITEM_REMOVE_NOTE = 'STOCK_ITEM_REMOVE_NOTE';
export const STOCK_ITEM_SET_AUDIT_HISTORY = 'STOCK_ITEM_SET_AUDIT_HISTORY';


export function addStockItemNote(note) {
  return {
    type: STOCK_ITEM_ADD_NOTE,
    note
  };
}

export function updateStockItemNote(note) {
  return {
    type: STOCK_ITEM_UPDATE_NOTE,
    note
  };
}

export function removeStockItemNote(note) {
  return {
    type: STOCK_ITEM_REMOVE_NOTE,
    note
  };
}

export function removeStockItemLicense(licenseId) {
  return {
    type: STOCK_ITEM_REMOVE_LICENSE,
    licenseId
  };
}

function setStockItemData(data) {
  return {
    type: STOCK_ITEM_SET_DATA,
    data
  };
}

export function setStockItemField(field, value) {
  return {
    type: STOCK_ITEM_SET_FIELD,
    field,
    value
  };
}

export function resetStockItem() {
  return {
    type: STOCK_ITEM_RESET_DATA
  };
}

export function addNewStockItemLicense(licenseData) {
  return {
    type: STOCK_ITEM_ADD_LICENSE,
    licenseData
  };
}


export function getStockItemAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('sdb/stock', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: STOCK_ITEM_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}

export function getStockItem(id, { includes, withs } = {}) {
  return (dispatch) => {
    return StockItemService.get(id, { includes, withs }).then((result) => {
      if (result.status === 200) {
        dispatch(setStockItemData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createStockItem(args, { includes, withs } = {}) {
  return (dispatch) => {
    return StockItemService.create(args, { includes, withs }).then((result) => {
      if (result.status === 200) {
        dispatch(setStockItemData(result.data));return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      }else {
        dispatch(defaultErrorFeedback());
        return null
      }
    });
  };
}

export function updateStockItem(id, args, { includes, withs } = {}) {
  return (dispatch) => {
    return StockItemService.update(id, args, { includes, withs }).then((result) => {
      if (result.status === 200) {
        dispatch(setStockItemData(result.data));
      } else if(result.status === 422){
        return {errors: result.data}
      }else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function deleteStockItem(id) {
  return (dispatch) => {
    return StockItemService.delete(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function attachStockItemLicense(id, licenseId) {
  return (dispatch) => {
    return StockItemService.attachLicense(id, licenseId).then((result) => {
      if (result.status === 200) {
        dispatch(addNewStockItemLicense(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}


export function detachStockItemLicense(id, licenseId) {
  return (dispatch) => {
    return StockItemService.detachLicense(id, licenseId).then((result) => {
      if (result.status === 204) {
        dispatch(removeStockItemLicense(licenseId));
        return true;
      } else {
        dispatch(defaultErrorFeedback());
        return false;
      }
    });
  };
}

