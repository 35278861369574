const partnershipForm = {
  readOnly : {
    partner: {
      label: 'Partner',
      type: 'text',
      readOnly: true,
      callBack: (key, field, data) => data.partner?.name || '',
      width: 12
    },
    customer: {
      label: 'Customer',
      type: 'text',
      readOnly: true,
      callBack: (key, field, data) => data.customer?.name || '',
      width: 12
    },
    type: {
      label: 'Partnership Type',
      type: 'text',
      readOnly: true,
      callBack: (key, field, data, optionSets) => optionSets.type.options.find(x => x.value === data['type'])?.label || '',
      width: 12
    }
  },
  new: {
    partner: {
      label: 'Partner',
      type: 'text',
      mandatory: true,
      readOnly: true,
      callBack: (key, field, data) => data.partner?.name || '',
      width: 12
    },
    customer: {
      label: 'Customer',
      type: 'select-async',
      entity: 'account',
      minSearchLength: 4,
      mandatory: true,
      width: 12
    },
    type: {
      label: 'Partnership Type',
      type: 'select',
      mandatory: true,
      width: 12
    }
  }

}

export default partnershipForm;
