import { Badge } from 'reactstrap';
import { equipColors } from '../constants';
import ZabbixStatusBadge from '../../../components/Zabbix/ZabbixStatusBadge';
import React from 'react';
import { DeviceDeploymentEnums } from '../../../utils/Constants/DeviceDeployment';

const DeviceDeploymentComponentForm = {
  type: {
    label: 'Type',
    type: 'custom',
    callBack: (key, field, data, deploymentTypes) => {
      const type = deploymentTypes.options.find(type => type.value === data.type)
      return <Badge color={'danger'} style={{ backgroundColor: equipColors[type?.label ?? ''] }}>{type?.label ?? ''}</Badge>
    },
    show: (field, data) => hasStock(data)
  },
  zabbix: {
    label: 'Zabbix Status',
    type: 'custom',
    callBack: (key, field, data) => <ZabbixStatusBadge {...data.zabbix} />,
    show: (field, data) => hasStock(data)
  },
  serialNumber: {
    label: 'Serial Number',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => data.stockItem?.serialNumber ?? '',
    show: (field, data) => hasStock(data)
  },
  assetTag: {
    label: 'Asset Tag',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => data.stockItem?.assetTag ?? '',
    show: (field, data) => hasStock(data)},
  vendor: {
    label: 'Vendor',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => data.stockItem?.deviceModel.vendor.name ?? '',
    show: (field, data) => hasStock(data)
  },
  model: {
    label: 'Model',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => data.stockItem?.deviceModel.name ?? '',
    show: (field, data) => hasStock(data)
  },

  psuCount: {
    label: 'PSUs',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => data.psuCount ?? 0,
    show: (field, data) => !hasType([DeviceDeploymentEnums.type.mux, DeviceDeploymentEnums.type.pdu, DeviceDeploymentEnums.type.panel], data.type)
  },
  connectionsCount: {
    label: 'Connections',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => data.connectionsCount ?? 0,
    show: (field, data) => !hasType([DeviceDeploymentEnums.type.mux, DeviceDeploymentEnums.type.pdu, DeviceDeploymentEnums.type.panel], data.type)
  },
  nnisCount: {
    label: 'NNIs',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => data.nnisCount ?? 0,
    show: (field, data) => !hasType([DeviceDeploymentEnums.type.mux, DeviceDeploymentEnums.type.pdu, DeviceDeploymentEnums.type.panel], data.type)
  },

  wavelengthsCount: {
    label: 'Wavelengths',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => data.wavelengthsCount ?? 0,
    show: (field, data) => hasType([DeviceDeploymentEnums.type.mux], data.type)
  },

  site: {
    label: 'Assigned To',
    type: 'text',
    width: 12,
    plaintext: true,
    callBack: (key, field, data) => data.site.name ?? ''
  },
  ownedBy: {
    label: 'Owned By',
    type: 'text',
    width: 12,
    plaintext: true,
    callBack: (key, field, data) => data.owner ?? ''
  },
}

const hasStock = (data) => {
  return data.stockItem?.id
}

const hasType = (types, type) => {
  return types.includes(type)
}


export default DeviceDeploymentComponentForm