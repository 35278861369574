import { apiRequest } from '../Api/ApiRequest';

export function api_fetchWIPReport() {
  return apiRequest({
    url: '/service-delivery/wip',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_exportWIP() {
  return apiRequest({
    url: '/service-delivery/wip?output=csv',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getLineItem(id) {
  return apiRequest({
    url: '/lineitems/' + id,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getOrder(id) {
  return apiRequest({
    url: '/orders/' + id + '?include=notes',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getProduct(id) {
  return apiRequest({
    url: '/products/' + id + '?include=notes',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateOrder(id, data) {
  return apiRequest({
    url: '/orders/' + id,
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updateOrderBatch(data) {
  return apiRequest({
    url: '/orders',
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_addOrderNote(id, note) {
  return apiRequest({
    url: '/orders/' + id + '/notes',
    method: 'post',
    data: { ...note }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_addCircuitNote(id, note) {
  return apiRequest({
    url: '/circuits/' + id + '/notes',
    method: 'post',
    data: { ...note }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_addProductNote(id, note) {
  return apiRequest({
    url: '/products/' + id + '/notes',
    method: 'post',
    data: { ...note }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
