import { api_multiSearch } from '../utils/MultiSearch/MultiSearchService';
import { defaultErrorFeedback } from './feedback';

export const MULTISEARCH_SET_RESULTS = 'MULTISEARCH_SET_RESULTS';
export const MULTISEARCH_SET_LOADING = 'MULTISEARCH_SET_LOADING';

export function doMultiSearch(ref, account = null) {
  return (dispatch) =>{
    return api_multiSearch(ref, account).then((result) => {
      if (result.status === 200) {
        return result.data
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}
