import classnames from 'classnames';
import { useState } from 'react';

const ClickableRow = ({
  row,
  id,
  children,
  onRowClick,
  withDeactivatedRow
}) => {

  const [isSelectingText, setIsSelectingText] = useState(false);

  const handleMouseDown = () => {
    setIsSelectingText(false);
  };

  const handleMouseMove = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      setIsSelectingText(true);
    }
  };

  const handleClick = (e) => {
    if (isSelectingText) {
      e.preventDefault();
      e.stopPropagation();
    } else if(onRowClick) {
      onRowClick(e)
    }
  };

  return (
    <tr
      key={id}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      className={classnames({'hoverPointer' : onRowClick, 'deactivatedRow' : withDeactivatedRow && !row.original.isActive})}
    >{children}</tr>
  )
}

export default ClickableRow;
