import {connect} from "react-redux";
import {Row, Card, CardBody, CardHeader, Col} from "reactstrap";
import React, {useEffect, useState} from "react";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {UPDATE_JSON_ARGUMENT_DATA} from "../../actions/configTemplate";
import {useNavigate} from "react-router-dom";

const GenerateTable = ({schema, dispatch, linkedTemplates, activeEntry}) => {
    const [formData, setFormData] = useState({});
    const [linkedSchema, setLinkedSchema] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        dispatch({type: UPDATE_JSON_ARGUMENT_DATA, data: JSON.stringify({})});
    }, []);

    useEffect(() => {
        if (linkedTemplates) {
            createLinkedSchemas()
        }
    }, [activeEntry]);

    const createLinkedSchemas = () => {
        let schema = {
            "type": "object",
            "properties": {}
        }
        let schemaProperties = {}
        const linkedTemplate = Object.entries(linkedTemplates)
        if (linkedTemplate.length > 0) {
            Object.entries(linkedTemplates).forEach((e) => {
                schemaProperties = {...schemaProperties, ...e[1].schema.properties}
            })
            schema.properties = schemaProperties
            const newSchema = {...schema}
            setLinkedSchema(newSchema);
        }
    }

    const generateUISchema = (schema) => {
        const capitalise = (sentence) => {
            let split = sentence.split(" ")
            let newWord = [];

            for (let i = 0; i < split.length; i++) {
                newWord.push(split[i].slice(0, 1).toUpperCase() + split[i].slice(1))
            }
            return newWord.join(" ")
        }

        const uiSchema = {
            'ui:options': {
                label: true,
            }
        }

        if (typeof schema === 'object' && schema.length > 0) {
            Object.entries(schema.properties).forEach((data => {
                    const name = data[0]

                    uiSchema[name] = {
                        "ui:style": {fontWeight: "bold"},
                        "ui:title": capitalise(name.replace(/_/g, " "))
                    }
                }
            ));
        }
        return uiSchema;
    }
    const newSchema = {...schema}

    const updateFormData = (data) => {
        let updatedFormData = Object.assign({}, data.formData)
        setFormData(Object.assign({}, updatedFormData))
        dispatch({type: UPDATE_JSON_ARGUMENT_DATA, data: JSON.stringify(updatedFormData)});
    }

    return (
        <Card className='card-accent-warning fadeIn animated'>
            <CardHeader style={{paddingBottom: '8px'}}>
                <Row className="justify-content-center align-content-center align-items-center">
                    <Col>
                        <h5 className="m-0 mirror d-inline">Fields</h5>
                    </Col>
                </Row>
            </CardHeader>

            <CardBody>
                <Row>
                    <Col>
                        <h5>Direct fields</h5>
                        <Form
                            liveValidate={true}
                            schema={newSchema}
                            formData={formData}
                            validator={validator}
                            children={[]}
                            uiSchema={generateUISchema(schema)}
                            onChange={updateFormData}
                            // onError={log('errors')}s
                            showErrorList={false}
                        />
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <h5>Linked template fields</h5>
                        <Form
                            liveValidate={true}
                            schema={linkedSchema}
                            formData={formData}
                            validator={validator}
                            children={[]}
                            uiSchema={generateUISchema(schema)}
                            onChange={updateFormData}
                            showErrorList={false}
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

function mapStateToProps({configTemplate}) {
    return {
        schema: configTemplate.activeEntry.schema,
        activeEntry: configTemplate.activeEntry,
        linkedTemplates: configTemplate.activeEntry.required_templates,
        configTemplate: configTemplate,
        templateEntries: configTemplate.template_entries
    };
}

export default connect(mapStateToProps)(GenerateTable);