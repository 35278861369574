import isEmpty from 'lodash.isempty';
import { Button } from 'reactstrap';
import moment from 'moment';
import { resolveCurrencySign } from '../../utils/Helpers/Currency';

const OpportunityForm = (isNew, withLead, hasQuote, currency, handleClick) => {

  const form = {
    estimatedTCV: {
      label: 'TCV',
      type: 'text',
      plaintext: true,
      format: {money: currency},
      width: 4
    },
    totalInstall: {
      label: 'NRR',
      type: 'text',
      plaintext: true,
      format: {money: currency},
      width: 4
    },
    totalMonthly: {
      label: 'MRR',
      type: 'text',
      plaintext: true,
      format: {money: currency},
      width: 4
    },
    yearOneValue: {
      label: 'YOV',
      type: 'text',
      plaintext: true,
      format: {money: currency},
      width: 4
    },
    contractTerm: {
      label: 'Term',
      type: 'text',
      width: 4,
      plaintext: true
    },
    name: {
      label: 'Topic',
      type: 'text',
      mandatory: true,
      width: 12
    },
    customer: {
      label: 'Customer',
      type: 'select-async',
      entity: 'account',
      placeholder: 'Enter account name...',
      mandatory: true,
      clearable: false,
      width: 12
    },
    lead: {
      label: 'Originating Lead',
      type: 'text',
      width: 12,
      plaintext: true,
      callBack: (key, field, data) => !isEmpty(data.lead) && data.lead.topic ? data.lead.topic : 'N/A'
    },
    owner: {
      label: 'Owner',
      type: 'select-async',
      placeholder: 'Enter contact name...',
      entity: 'user',
      mandatory: true
    },
    contact: {
      label: 'Contact',
      type: 'select',
      entity: 'contact',
      handleClick: () => {
        handleClick('contact')
      },
      clearable: true,
      readOnly: (key, field, data, permissions) => isEmpty(data.customer)
    },
    type: {
      label: 'Type',
      type: 'select',
      mandatory: true
    },
    probabilityBand: {
      label: 'Probability Band',
      type: 'select',
      mandatory: true
    },
    estCloseDate: {
      label: 'Estimated Close Date',
      type: 'date',
      mandatory: true,
      min: moment().startOf('date').toDate()
    },
    followUpDate: {
      label: 'Follow-up Deadline',
      type: 'datetime',
      min: new Date()
    },
    requiredDeliveryDate: {
      label: 'Required Delivery Date',
      type: 'date',
      min: new Date()
    },
    priceList: {
      label: 'Price List',
      type: 'select',
      mandatory: true,
    },
  }

  if(hasQuote){
    form.priceList = {
      label: 'Price List',
      type: 'text',
      plaintext: true,
      callBack: (key, field, data) => data.priceList?.name ?? 'N/A',
    }
  }
  if(!isNew){
    form.customer = {
      label: 'Customer',
      type: 'button-link',
      width: 12,
      getLink: (data) => data.customer ? `/accounts/${data.customer.accountid}` : '#',
      isDisabled: (data) => !data.customer,
      callBack: (key, field, data) => data[key] !== undefined && data[key] !== null ? data[key].name : '',
    }
  }
  if(withLead){
    form.lead = {
      label: 'Originating Lead',
      type: 'button-link',
      width: 12,
      getLink: (data) => !isEmpty(data.lead) ? `/sales/leads/${data.lead.id}` : '#',
      isDisabled: (data) => isEmpty(data.lead),
      callBack: (key, field, data) => data[key] !== undefined && data[key] !== null ? data[key].topic : '',
    }
  }

  return form

}

export default OpportunityForm

