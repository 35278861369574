import { Card, CardBody } from 'reactstrap';
import classnames from 'classnames';

const EntityMainFormCard = ({
  children,
  grow
}) => {


  return (
    <Card className={classnames('border-top', 'shadow-sm', { 'flex-1': grow })}>
      <CardBody>
        {children}
      </CardBody>
    </Card>

  )
}

export default EntityMainFormCard