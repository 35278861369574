import {
  api_createUserLead,
  api_disqualifyUserLead,
  api_getUserLead,
  api_qualifyUserLead,
  api_updateUserLead
} from '../utils/Leads';
import { defaultErrorFeedback, successFeedback } from './feedback';
import AuditService from "../utils/Audit";

export const LEAD_SET_DATA = 'LEAD_SET_DATA';
export const LEAD_RESET = 'LEAD_RESET';
export const LEADS_UPDATE_FORM_FIELD = 'LEADS_UPDATE_FORM_FIELD';
export const LEAD_SET_AUDIT_HISTORY = 'LEAD_SET_AUDIT_HISTORY';

export function setLead(lead) {
  return {
    type: LEAD_SET_DATA,
    lead
  };
}

export function updateLeadFormField(field) {
  return {
    type: LEADS_UPDATE_FORM_FIELD,
    field
  };
}

export function resetLead() {
  return {
    type: LEAD_RESET
  };
}

export const getUserLead = (userId, id) => {
  return (dispatch) => {
    return api_getUserLead(userId, id)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setLead(result.data));
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
  };
};

export const updateUserLead = (userId, id, data) => {
  return (dispatch) => {
    return api_updateUserLead(userId, id, data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Lead updated!'));
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };
};

export const qualifyUserLead = (userId, id) => {
  return (dispatch) => {
    return api_qualifyUserLead(userId, id)
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };
};

export const disqualifyUserLead = (userId, id, statusReason) => {
  return (dispatch) => {
    return api_disqualifyUserLead(userId, id, statusReason)
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };
};

export const createUserLead = (userId, id, data) => {
  return (dispatch) => {
    return api_createUserLead(userId, id, data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Lead created!'));
          return result.data;
        } else if (result.status === 422) {
          return {errors: result.data};
        } else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  };
};

export function getLeadAuditHistory(id){
    return (dispatch) => {
        return AuditService.get('leads', id).then((result)=>{
            if (result.status === 200) {
                dispatch({ type: LEAD_SET_AUDIT_HISTORY, data: result.data });
            }else{
                dispatch(defaultErrorFeedback());
            }
        })
    }
}
