import {
  RACK_ADD_NOTE,
  RACK_REMOVE_NOTE,
  RACK_RESET_DATA,
  RACK_SET_AUDIT_HISTORY,
  RACK_SET_DATA,
  RACK_SET_FIELD,
  RACK_UPDATE_NOTE
} from '../actions/rack';

const initialState = {
  data: {
    size: 42,
    name: '',
    deviceDeployments: [],
    notes: []
  },
  original: {
    size: 42,
    name: '',
    deviceDeployments: [],
    notes: []
  },
  audits: []
};
const rack = (state = initialState, action) => {
  switch (action.type) {
    case RACK_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case RACK_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case RACK_RESET_DATA:
      return {
        ...initialState
      };
    case RACK_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    case RACK_ADD_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: [...state.data.notes, action.note]
        },
        original: {
          ...state.original,
          notes: [...state.original.notes, action.note]
        },

      }
    case RACK_UPDATE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
        original: {
          ...state.original,
          notes: state.original.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
      }
    case RACK_REMOVE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.filter(note => note.id !== action.note.id)
        },
        original: {
          ...state.original,
          notes: state.original.notes.filter(note => note.id !== action.note.id)
        }
      }
    default:
      return state;
  }
};

export default rack;