import { CardText } from 'reactstrap';
import isEmpty from 'lodash.isempty';

const StandardPricing = () => {
  return (
    <div className="animated fadeIn">
      <CardText>This is the default pricing using the install and rental price set up on the price list item.</CardText>
    </div>
  )
}

export default StandardPricing;

