import { apiRequest } from '../Api/ApiRequest';

export function api_generateServiceAvailabilityReport(accountId, from, to, incidents, configItems) {
  return apiRequest({
    url: `accounts/${accountId}/generate-report/service-availability`,
    method: 'post',
    responseType: 'blob',
    data: { from, to, incidents, configItems }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
