
export const Environment = {

  isProduction: () =>{
    return process.env.REACT_APP_ENV === 'prod'
  },
  isDev: () => {
    return process.env.REACT_APP_ENV === 'dev'
  }
}
