import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Badge, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu,
  DropdownItem, UncontrolledPopover, PopoverBody, PopoverHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AckModal from './AckModal';
import FilterModal from './FilterModal';
import { ackAlarmWithCase } from '../../actions/alarms';
import Loadable from 'react-loading-overlay';
import CaseSummary from '../../components/Cases/CaseSummary';

var moment = require('moment');

class Alarms extends Component {
    static contextTypes = {
      router: PropTypes.shape({
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
          replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
      }).isRequired
    };

    constructor(props) {
      super(props);

      this.state = {
        loading: false,
        modal: false,
        fmodal: false,
        eventid: null,
        sortBy: 'lastchange',
        filterAbove: 0
      };
      this.modalAction = this.modalAction.bind(this);
      this.fmodalAction = this.fmodalAction.bind(this);
      this.createCase = this.createCase.bind(this);
      this.setSort = this.setSort.bind(this);
      this.setFilter = this.setFilter.bind(this);
    }

    sort = alarms => {
      return alarms.sort((a, b) => {
        let aValue = a[this.state.sortBy];
        let bValue = b[this.state.sortBy];

        // Support string comparison
        const sortTable = { true: 1, false: -1 };

        // Order descending (Order.DESC)
        return bValue === aValue ? 0 : sortTable[bValue > aValue];
      });
    };

    findCases(events) {
      let cases = {};
      let ix = 0;
      for (let i = 0; i < events.length; i++) {
        let caseref = events[i].message.match(/CAS-([0-9]{6})-([A-Z0-9]{4})/i);
        if (caseref !== null) {
          cases[caseref[0]] = 1;
        }
        ix = events[i].eventid;
      }
      if (Object.keys(cases).length > 0) {
        return (<p>{Object.keys(cases).map((cr) => <div key={cr}>
                    <Badge color="warning" id={'b_' + ix + '_' + cr}>{cr.toUpperCase()}</Badge>
                    <UncontrolledPopover trigger="legacy" placement="bottom" target={'b_' + ix + '_' + cr}>
                        <PopoverHeader>{cr.toUpperCase()}</PopoverHeader>
                        <PopoverBody>
<CaseSummary caseId={cr.toUpperCase()}/>
                        </PopoverBody>
                    </UncontrolledPopover>
                </div>)}</p>);
      }

      return (<div></div>);
    }

    modalAction(event, trigger) {
      if (this.state.modal === true) {
        this.setState({ modal: false, eventid: null, trigger: null });
      } else {
        this.setState({ modal: true, eventid: event, trigger: trigger });
      }
    }

    fmodalAction(event, trigger) {
      if (this.state.fmodal === true) {
        this.setState({ fmodal: false, eventid: null, trigger: null });
      } else {
        this.setState({ fmodal: true, eventid: event, trigger: trigger });
      }
    }

    createCase(event, trigger) {
      this.props.dispatch(ackAlarmWithCase(event, trigger));
    }

    setSort(sortBy) {
      this.setState({ sortBy: sortBy });
    }

    setFilter(filterAbove) {
      this.setState({ filterAbove: filterAbove });
    }

    render() {
      const colMap = { 0: '#AFAFAF', 1: '#67c2ef', 2: '#FFC859', 3: '#FFA059', 4: '#E97659', 5: '#E45959' };
      const priMap = { 0: 'Unclassified', 1: 'Informational', 2: 'Warning', 3: 'Average', 4: 'High', 5: 'Disaster' };
      const sortoptions = {
        lastchange: 'Last Change',
        priority: 'Priority'
      };

      if (this.props.alarms.length <= 0) {
        return (<div className="row justify-content-center">
                <Loadable active={true} spinner color={'rgb(62,81,91)'} spinnerSize={'60px'} />
            </div>);
      }
      return (
            <div className="animate fadeIn">
                <Card className="card-accent-primary">
                    <CardHeader style={{ paddingBottom: '8px' }}>
                        <Row>
                            <Col md={6}>{this.props.alarms.length} Current Alarms</Col>
                            <Col md={6}>
                                <UncontrolledButtonDropdown size="sm" style={{ float: 'right' }}>
                                    <DropdownToggle caret color="primary">
                                        <i className="fa fa-fw fa-sort"></i> Sort By
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {Object.keys(sortoptions).map((so) => <DropdownItem key={so} style={{
                                          fontWeight: this.state.sortBy === so ? 'bold' : 'normal'
                                        }} onClick={(e) => this.setSort(so)}>{sortoptions[so]}</DropdownItem>)}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                <UncontrolledButtonDropdown size="sm" style={{ float: 'right', marginRight: '10px' }}>
                                    <DropdownToggle caret color="primary">
                                        <i className="fa fa-fw fa-filter" style={{ color: colMap[this.state.filterAbove] }}></i> Filter Level
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {Object.keys(priMap).map((pri) => <DropdownItem key={'p' + pri} style={{
                                          fontWeight: this.state.filterAbove === pri ? 'bold' : 'normal',
                                          backgroundColor: colMap[pri]
                                        }} onClick={(e) => this.setFilter(pri)}>{priMap[pri]}</DropdownItem>)}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                {this.sort(this.props.alarms.filter(alarm => alarm.priority >= this.state.filterAbove)).map((alarm) => <Row key={alarm.triggerid} style={{ padding: '5px', borderLeftColor: colMap[alarm.priority], borderBottomWidth: '1px', marginBottom: '10px' }} className={'callout callout-info'}>
                        <Col md={2} style={{ whiteSpace: 'nowrap' }}>{alarm.lastEvent.eventid in this.props.acks
                          ? <div>
                                <Badge color="success" id={'acks_' + alarm.lastEvent.eventid}>Acknowledged ({this.props.acks[alarm.lastEvent.eventid].length})</Badge>
                                <UncontrolledPopover trigger="legacy" placement="bottom" target={'acks_' + alarm.lastEvent.eventid}>
                                    <PopoverHeader>Acknowledges</PopoverHeader>
                                    <PopoverBody>
                                        {this.props.acks[alarm.lastEvent.eventid].map((ack) => <div key={ack.acknowledgeid}>
                                                {ack.alias} acked {moment(ack.clock * 1000).fromNow()}
                                            <pre style={{ backgroundColor: '#eaeaea', border: '1px solid #eaeaea', borderRadius: '4px', padding: '4px' }}>{ack.message}</pre>
                                            </div>)}
                                    </PopoverBody>
                                </UncontrolledPopover>
                                {alarm.lastEvent.eventid in this.props.filters && <div><Badge color="dark"><i className="icon-eye"></i> Filtered</Badge></div>}
                                {alarm.lastEvent.eventid in this.props.acks && this.findCases(this.props.acks[alarm.lastEvent.eventid])}
                            </div>
                          : <Badge>No Ack</Badge>}</Col>
                        <Col md={2}>{moment(alarm.lastchange * 1000).fromNow()}</Col>
                        <Col md={2}>{alarm.hosts[0].host}</Col>
                        <Col md={5}>
                            {alarm.description}
                            {(alarm.triggerid in this.props.processing && this.props.processing[alarm.triggerid] === true) && <Loadable active={true} spinner color={'rgb(62,81,91)'} spinnerSize={'24px'} style={{ float: 'right' }}/>}
                        </Col>
                        <Col md={1}>
                            <UncontrolledButtonDropdown size="sm">
                                <DropdownToggle caret color="info" style={{ backgroundColor: colMap[alarm.priority], borderColor: colMap[alarm.priority] }}>
                                    Actions
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={(e) => this.createCase(alarm.lastEvent.eventid, alarm.triggerid)}>Create CRM Ticket</DropdownItem>
                                    <DropdownItem onClick={(e) => this.modalAction(alarm.lastEvent.eventid, alarm.triggerid)}>Acknowledge</DropdownItem>
                                    <DropdownItem onClick={(e) => this.fmodalAction(alarm.lastEvent.eventid, alarm.triggerid)}>Filter</DropdownItem>
                                    <DropdownItem onClick={() => window.open('https://zabbix.hso-group.net/tr_events.php?triggerid=' + alarm.triggerid + '&eventid=' + alarm.lastEvent.eventid, '_blank')}>View Event in Zabbix</DropdownItem>
                                    <DropdownItem onClick={() => window.open('https://zabbix.hso-group.net/search.php?search=' + alarm.hosts[0].host, '_blank')}>View Host in Zabbix</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                    </Row>)}
                    </CardBody>
                </Card>
                <AckModal show={this.state.modal} event={this.state.eventid} trigger={this.state.trigger} parentSetState={(obj) => { this.setState(obj); }}/>
                <FilterModal show={this.state.fmodal} event={this.state.eventid} trigger={this.state.trigger} parentSetState={(obj) => { this.setState(obj); }}/>
            </div>
      );
    }
}

function mapStateToProps({ zabbixAlarms }) {
  return {
    alarms: zabbixAlarms.alarms,
    acks: zabbixAlarms.acks,
    filters: zabbixAlarms.filters,
    processing: zabbixAlarms.processing
  };
}

export default connect(mapStateToProps)(Alarms);
