import React, { Fragment } from 'react';
import { Badge, Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import BillPreviewItems from './BillPreviewItems';
import numeral from 'numeral';
import Callout from '../../components/Callout/Callout';
import { api_exportBillItems, api_getVoiceProductArtifacts } from '../../utils/Billing/BillingService';
import fileDownload from 'js-file-download';
import Loadable from 'react-loading-overlay';
import { defaultErrorFeedback } from '../../actions/feedback';
import { connect } from 'react-redux';

class BillPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      loading: false
    };
  }

    toggle = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab
        });
      }
    }

    downloadXls = () =>{
      const { billItems, billRef } = this.props.data;
      api_exportBillItems(billItems, billRef).then(
        (result) => {
          if (result.status === 200) {
            let splitContentDisposition = result.headers['content-disposition'].split('=');
            fileDownload(result.data, splitContentDisposition[1]);
          } else {
            this.props.dispatch(defaultErrorFeedback());
          }
          this.setState({ loading: false });
        }
      );
    }

    downloadVoiceArtifact = (item) => {
      this.setState({ loading: true });
      api_getVoiceProductArtifacts(item.id, this.props.data.billRef, item.artifact).then(
        (result) => {
          if (result.status === 200) {
            let splitContentDisposition = result.headers['content-disposition'].split('=');
            fileDownload(result.data, splitContentDisposition[1]);
          } else {
            this.props.dispatch(defaultErrorFeedback());
          }
          this.setState({ loading: false });
        }
      );
    }

    render() {
      const { billItems, hasGroupedItems, excludedItems, inCreditItems, retiringItems } = this.props.data;
      const errorItems = excludedItems.filter(item => item.errors.length > 0);
      const excludedNoErrors = excludedItems.filter(item => item.errors.length === 0);
      const ceasePending = billItems.filter(item => item.ceasePending);
      return (
            <Loadable animate={false} active={this.state.loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                <Row >
                    <Col className={'d-flex flex-row align-items-start'}>
                        <Callout header={'Bill Ref'} text={<a href={this.props.data.crmUrl} target="_blank" >{this.props.data.billRef}</a>}/>
                        <Callout header={'Total Value (Excl. VAT)'} text={'£' + numeral(this.props.data.total).format('0,0.00')}/>
                        <Callout header={'Bill Payer'} text={this.props.data.payer}/>
                        <div className="ml-auto d-flex flex-row">
                            <Button outline size={'small'} color={'success'} onClick={this.downloadXls}>.xlsx <i className="icon-cloud-download"></i></Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Nav tabs>
                            <NavItem>
                                <NavLink href="#"
                                         className={classnames({ active: this.state.activeTab === 0 })}
                                         onClick={() => {
                                           this.toggle(0);
                                         }}>Items  <Badge color="success">{billItems.length}</Badge>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                         className={classnames({ active: this.state.activeTab === 1 })}
                                         onClick={() => {
                                           this.toggle(1);
                                         }}>In Credit <Badge color="info">{inCreditItems.length}</Badge>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                         className={classnames({ active: this.state.activeTab === 2 })}
                                         onClick={() => {
                                           this.toggle(2);
                                         }}>Cease Pending <Badge color="warning">{ceasePending.length}</Badge>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                         className={classnames({ active: this.state.activeTab === 3 })}
                                         onClick={() => {
                                           this.toggle(3);
                                         }}>Ceasing <Badge color="warning">{retiringItems.length}</Badge>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                         className={classnames({ active: this.state.activeTab === 4 })}
                                         onClick={() => {
                                           this.toggle(4);
                                         }}>Excluded <Badge color="warning">{excludedNoErrors.length}</Badge>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                         className={classnames({ active: this.state.activeTab === 5 })}
                                         onClick={() => {
                                           this.toggle(5);
                                         }}>Errors <Badge color="danger">{errorItems.length}</Badge>
                                </NavLink>
                            </NavItem>
                            <TabContent className={'col-md-12 d-flex flex-column'} activeTab={this.state.activeTab}>
                                <TabPane tabId={0}>
                                    <Row>
                                        <Col className="mt-3">
                                            <BillPreviewItems items={billItems} hasGroupedItems={hasGroupedItems} tableType={'included'} downloadVoiceArtifact={this.downloadVoiceArtifact}/>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={1}>
                                    <Row>
                                        <Col className="mt-3">
                                            <BillPreviewItems items={inCreditItems} tableType={'inCredit'}/>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <Row>
                                        <Col className="mt-3">
                                            <BillPreviewItems items={ceasePending} tableType={'ceasePending'}/>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={3}>
                                    <Row>
                                        <Col className="mt-3">
                                            <BillPreviewItems items={retiringItems} tableType={'retiring'}/>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={4}>
                                    <Row>
                                        <Col className="mt-3">
                                            <BillPreviewItems items={excludedNoErrors} tableType={'excluded'}/>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={5}>
                                    <Row>
                                        <Col className="mt-3">
                                            <BillPreviewItems items={errorItems} tableType={'errors'}/>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Nav>
                    </Col>
                </Row>

            </Loadable>

      );
    }
}

export default connect()(BillPreview);
