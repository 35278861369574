import { Badge } from 'reactstrap';
import React from 'react';

const ZabbixStatusBadge = ({
  snmp, agent, id
}) => {

  const getBadgeColour = () => {
    if(id){
      return snmp || agent === 1 ? 'success' : 'danger'
    }
    return 'warning'
  }

  const getBadgeText = () => {
    let text = "Host not found";
    if(id){
      text = "Host found and "
      if(!snmp && agent !== 1){
        text += " not ";
      }
      text += "available";
    }
    return text
  }

  return (
    <Badge className={`p-2 text-wrap faded-${getBadgeColour()}`}>{getBadgeText()}</Badge>
  )


}

export default ZabbixStatusBadge