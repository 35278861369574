import {Alert, Button, Col} from "reactstrap";
import React, {useState} from "react";
import Row from "reactstrap/es/Row";
import {connect} from "react-redux";
import {generateConfigTemplate} from "../../utils/ConfigTemplate";
import {json} from "@codemirror/lang-json";
import {materialDark} from '@uiw/codemirror-theme-material';
import CodeMirror from '@uiw/react-codemirror';
import {SpinnerInfo} from "../../components/Spinner/Spinner";


const TabGenerate = (
    {
      id, dispatch, generated, jsonArgumentData
    }) => {
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [generating, setGenerating] = useState(false);

  const WrappedButton = () => {
    if (generated) {
      return (
          <div style={{position: "absolute", right: 30, top: 10, zIndex: 3, width: 330, height: 30}}>
            <GenerateButton/>
          </div>
      )
    }
    return <GenerateButton/>
  }
  const GenerateButton = () => (
      <Button size='sm'
              style={generated.length > 0 ? {top: 10, right: 20, zIndex: 10}
                  : {}
              } color='success' className='m-1 float-right' onClick={() => generate()}>
        <i className="fa fa-save"/> Generate
      </Button>
  )

  const renderError = err => {
    setErrorFlag(true);
    setErrorText(err);
    setTimeout(() => {
          setErrorFlag(false)
          setErrorText(null)
        }
        , 3000)
    setGenerating(false);
    dispatch({type: "GENERATED", result: false});
  }

  const check_json = json => {
    try {
      JSON.parse(json);

    } catch (e) {
      if (e instanceof SyntaxError) {
        renderError("Invalid JSON in arguments");
        return false;
      }
    }
    return true
  }

  const generate = () => {
    setErrorFlag(false);
    setGenerating(true);
    if (!check_json(jsonArgumentData)) {
      setGenerating(false);
      return
    }

    generateConfigTemplate(id, JSON.parse(jsonArgumentData)).then((response) => {

      if (response.status === 200) {
        dispatch({type: "GENERATED", result: response.data['template']});
        setGenerating(false);
      } else if (response.status === 400) {
        renderError(response.data['message']);
        setGenerating(false);
      }
    }).catch(err => renderError(err.data.message))
  }

  const displayGeneratedData = () => {
    if (generating) {
      return (
          <SpinnerInfo/>
      )
    }

    if (generated.length > 0) {
      return (
          <div style={{height: 600}}>
            <CodeMirror value={generated}
                        className='h-100 font-sm animated fadeIn'
                        minHeight="100%"
                        maxHeight="100%"
                        extensions={[json()]}
                        theme={materialDark}
                        basicSetup={{highlightActiveLine: false, highlightActiveLineGutter: false}}
            />
          </div>
      )
    }
    return null;
  }

  return <>
    <Row className='pt-1 pb-0'>
      <Col>
        <WrappedButton/>
        {
          errorFlag ?
              <Row>
                <div className='pt-1'><Alert color='warning'>{errorText}</Alert></div>
              </Row>
              : null
        }
      </Col>
    </Row>
    {displayGeneratedData()}
  </>
}

function mapStateToProps({configTemplate}) {
  return {
    generated: configTemplate.generated ? configTemplate.generated['data'] : "",
    templateEntries: configTemplate.template_entries,
    activeEntry: configTemplate.activeEntry,
    jsonArgumentData: configTemplate.jsonArgumentData
  };
}

export default connect(mapStateToProps)(TabGenerate);
