import React, {useEffect, useState,} from 'react';
import { connect, useDispatch } from 'react-redux'
import {
    Badge,
    Button,
    Card, CardBody,
    CardHeader,
    Col,
    ListGroup,
    ListGroupItem, PopoverBody, PopoverHeader,
    Row,
    UncontrolledCollapse, UncontrolledPopover
} from 'reactstrap';
import {SpinnerInfo} from "../../components/Spinner/Spinner";
import {getJobStatus} from "../../utils/DeviceCheck/DeviceCheckService";
import {SNAP_CHECKING} from "../../actions/jsnapy";
import { useNavigate, useParams } from 'react-router-dom'


const CoreSnapCheckResults = ({snapChecking}) => {

    // router
    const navigate = useNavigate();
    const { uuid } = useParams();

    // redux
    const dispatch = useDispatch();

    const [data, setData] = useState();

    useEffect(() => {
        dispatch({type: SNAP_CHECKING, result: true});
        getData();
    }, []);

    const getData = () => {
        let interval = setInterval(() => {
            getJobStatus(uuid).then((r) => {
                if (r.data.status === 'SUCCESS') {
                    clearInterval(interval);
                    setData(r.data);
                    dispatch({type: SNAP_CHECKING, result: false});
                }
            }).catch(() => {
                clearInterval(interval);
                dispatch({type: SNAP_CHECKING, result: false});
            })
        }, 500)
    }

    const prettifyTestName = uglyTestName => uglyTestName.replace(/_|diff/g, ' ');
    if (snapChecking || data === undefined) {
        return (
          <Row className="animated fadeIn d-flex justify-content-center">
              <Col className="d-flex justify-content-center" md={6} lg={4}>
                  <Card className="card-accent-secondary w-100">
                      <CardBody>
                          <p className="m-0 p-0 d-inline">Running checks...</p>
                          <SpinnerInfo/>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
        )
    }
    if (data) {
        return (
          <Row className="animated fadeIn d-flex justify-content-center">
              <Col xs={12} className="d-flex justify-content-end mb-1">
                  <Button  color="primary" className="m-1" onClick={() => navigate(-1)}>
                      <i className="icon-camrecorder"/> Snaps
                  </Button>
                  <Button color="success" id="UncontrolledPopover" className="m-1">
                      <i className="icon-share"/> Share
                  </Button>
                  <UncontrolledPopover placement="bottom" target="UncontrolledPopover">
                      <PopoverHeader>Sharing Link</PopoverHeader>
                      <PopoverBody>{`https://admin.hso-group.net/toolbox/coresnap/${uuid}`}</PopoverBody>
                  </UncontrolledPopover>
              </Col>
              <Col xs={12}>
                  <Card className="card-accent-secondary w-100">
                      <CardHeader>
                          <Row>
                              <Col md={3}><h5 className="m-0">{data.meta.result[0].hostname}</h5></Col>
                              <Col className="d-flex justify-content-end align-items-center">
                                  <h6 className="m-0">
                                      Comparing pre
                                      snapshot <b>{data.meta.result[0].pre}</b> with <b>{data.meta.result[0].post}</b>
                                  </h6>
                              </Col>

                          </Row>
                      </CardHeader>
                      <CardBody>
                          <ListGroup>
                              {
                                  Object.values(data.meta.result[0].test_results).map((e, k) => {
                                      const {test_name: testName, failed, passed} = e[0];
                                      let tog = 'toggler_' + k;
                                      let tr = [];
                                      failed.forEach((sub, k) => {
                                          tr.push(
                                            <ListGroupItem key={'failed' + k}>
                                                <i
                                                  className="fa fa-times-circle fa-1x pr-2"
                                                  style={{
                                                      color: 'red'
                                                  }}
                                                />
                                                {sub.message}
                                            </ListGroupItem>
                                          );
                                      });
                                      passed.forEach((sub, k) => {
                                          tr.push(
                                            <ListGroupItem key={'passed' + k}>
                                                <i
                                                  className="fa fa-check-circle fa-1x pr-2"
                                                  style={{
                                                      color: 'green'
                                                  }}
                                                />
                                                {sub.message}
                                            </ListGroupItem>
                                          );
                                      });
                                      return (
                                        <Card key={k}>
                                            <CardHeader>
                                                <Row>
                                                    <Col md={9}>
                                                        {prettifyTestName(testName)}
                                                    </Col><
                                                  Col md={2}>
                                                    <p style={{
                                                        textAlign: 'right',
                                                        margin: 0,
                                                        padding: 0,
                                                        color: '#808080'
                                                    }}>
                                                        {failed.length === 0 ? 'Passed: ' : 'Failed: '}
                                                        <Badge
                                                          color={failed.length === 0 ? 'success' : 'danger'}>
                                                            {failed.length}/{passed.length + failed.length}
                                                        </Badge>
                                                    </p>
                                                </Col>
                                                    <Col md={1}>
                                                        <p style={{textAlign: 'right', margin: 0, padding: 0}}>
                                                            <Button color="link" id={tog}
                                                                    style={{margin: '0', padding: 0}}>
                                                                <i className="icon-menu" />
                                                            </Button>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <UncontrolledCollapse toggler={'#' + tog} key={tog}>
                                                <CardBody><ListGroup>{tr}</ListGroup></CardBody>
                                            </UncontrolledCollapse>
                                        </Card>
                                      )
                                  })
                              }
                          </ListGroup>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
        )
    }
}


function mapStateToProps({jsnapy}) {
    return {
        snapChecking: jsnapy.snapChecking,
    };
}

export default connect(mapStateToProps)(CoreSnapCheckResults);
