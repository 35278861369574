import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const api_getPartnership = (accountId, customerId, include = []) => {
  return apiRequest({
    url: `/accounts/${accountId}/partnerships/${customerId}${queryStringFromFields({ include })}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
};

export const api_createPartnership = (accountId, data, include = []) => {
  return apiRequest({
    url: `/accounts/${accountId}/partnerships${queryStringFromFields({ include })}`,
    method: 'post',
    data
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
};

export const api_deletePartnership = (accountId, customerId) => {
  return apiRequest({
    url: `/accounts/${accountId}/partnerships/${customerId}`,
    method: 'delete'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}
