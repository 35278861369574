import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  addCarrierCircuitNote,
  createCarrierCircuit, deleteCarrierCircuit,
  getCarrierCircuit, getCarrierCircuitAuditHistory, removeCarrierCircuitNote, removeCarrierCircuitProduct,
  resetCarrierCircuit, updateCarrierCircuit,
  updateCarrierCircuitField, updateCarrierCircuitFields, updateCarrierCircuitNote
} from '../../../actions/carrierCircuit'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import isEmpty from 'lodash.isempty'
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs'
import { RackService } from '../../../utils/ServiceDB/Rack'
import { NNIService } from '../../../utils/ServiceDB/NNI'
import moment from 'moment'
import LoadingOverlay from '../../../components/LoadingOverlay'
import { Badge, Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import classnames from 'classnames'
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert'
import { ButtonIcon } from '../../../components/ButtonIcon'
import FormValidationErrors from '../../../components/Errors/FormValidationErrors'
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard'
import EntitySubFormCard from '../../../components/Cards/EntitySubFormCard'
import CollapsibleCard from '../../../components/CollapsibleCard'
import { diff } from 'deep-object-diff'
import GenericForm from '../../../components/GenericForm'
import { formValidator } from '../../../helpers/FormValidator'
import resolveArgs from '../../../helpers/ArgumentResolver'
import pick from 'lodash.pick'
import CarrierCircuitForm from './form'
import HeadlessModal from '../../../components/Modals/HeadlessModal'
import Rack from '../Rack'
import NNI from '../NNI'
import { setConfirmDialog } from '../../../actions/dialogs'
import { canCreateCarrierCircuit } from '../../../utils/Auth/AuthService'
import omitBy from 'lodash.omitby'
import isNull from 'lodash.isnull'
import ApiErrorResolver from '../../../helpers/ApiErrorResolver'
import { searchProducts } from '../../../actions/circuit'
import DeactivatedEntityWarning from '../../../components/Alerts/DeactivatedEntityWarning'
import { ProductEnums } from '../../../utils/Constants/Product'
import bandwidthConverter from '../../../helpers/bandwidthConverter'
import OrderProductsTable from '../../../components/Tables/OrderProducts'
import { createCircuitAssociation, deleteCircuitAssociation, removeProductCircuit } from '../../../actions/product'
import Notes from '../../../components/Notes'
import AuditHistory from '../../../components/AuditHistory'
import CircuitLookupModal from '../../../components/Modals/CircuitLookupModal'
import { CarrierCircuitEnums } from '../../../utils/Constants/CarrierCircuits'
import queryString from 'query-string'
import { NavigationBlocker } from '../../../components/NavigationBlocker';

const include = [
  'aEnd',
  'aEnd.carrier',
  'bEnd',
  'opticalSystem',
  'component',
  'component.connection',
  'component.connection.aEnd',
  'component.connection.bEnd',
  'component.connection.aEnd.deviceDeployment',
  'component.connection.bEnd.deviceDeployment',
  'crmCircuit',
  'orderProducts',
  'orderProducts.site',
  'notes',
  'notes.createdBy'
]

const entityInitialState = { type: '', id: '' }
const CarrierCircuit = ({
  permissions,
  carrierCircuitTypes,
  carrierCircuitOrderStatuses,
  carrierCircuitBearerSpeeds,
  suppliers,
  l2tpOptions,
  nniOptions,
  crmServiceTypes,
  data,
  original,
  audits,
  id = null,
  closeModal = null,
  onCreated = null,
  onUpdated = null,
  orderProduct = null,
}) => {

  //router
  const location = useLocation();
  const navigate = useNavigate()
  const params = useParams()

  // redux
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [auditsLoading, setAuditsLoading] = useState(false)
  const [productsLoading, setProductsLoading] = useState(false)
  const [showEntityModal, setShowEntityModal] = useState(false)
  const [showExistingCircuitModal, setShowExistingCircuitModal] = useState(false)
  const [entity, setEntity] = useState(entityInitialState)

  const carrierCircuitId = useMemo(() => (id || params.carrierCircuitId), [id, params.carrierCircuitId])
  const isNew = useMemo(() => carrierCircuitId === 'new' && !original.id, [carrierCircuitId, original.id])

  const queryParams = isNew ? queryString.parse(location?.search) : null

  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }

  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState)

  const toggleProductsLoading = () => {
    setProductsLoading(prevState => !prevState)
  }
  const toggleEntityModal = () => {
    setShowEntityModal(prevState => !prevState)
  }

  const toggleExistingCircuitModal = () => setShowExistingCircuitModal(prevState => !prevState)

  useEffect(() => {
    if (!isNew) {
      fetchData()
    } else if (!isEmpty(queryParams.nni)) {
      fetchNni(queryParams.nni)
    }
    return () => dispatch(resetCarrierCircuit())
  }, [])

  useEffect(() => {
    if (!closeModal) {
      dispatch(addBreadcrumbs([{ name: original.name ?? 'New Circuit' }]))
    }

    return () => {
      if (!closeModal) {
        dispatch(resetBreadcrumbs())
      }

    }
  }, [original.name])

  useEffect(() => {
    if (data?.type && data.type !== original.type) {
      setField('bearerSpeed', null)
      setField('cdr', null)
    }
    if (data.type && isL2tpType() && data.aEnd?.type !== 'l2tp') {
      setField('aEndType', 'l2tp')
    }
    if (data.type && !isL2tpType() && data.aEnd?.type === 'l2tp') {
      setField('aEndType', null)
    }
    if (data.type && data.type !== CarrierCircuitEnums.type.ETHERNET && data.aSTag) {
      setField('aSTag', null)
    }
  }, [data.type])

  useEffect(() => {
    if (data.aEnd.type === 'nni' && data.type !== CarrierCircuitEnums.type.ETHERNET) {
      setField('type', CarrierCircuitEnums.type.ETHERNET)
    }
  }, [data.aEnd.type])

  useEffect(() => {
    if (entity.id) {
      toggleEntityModal()
    }
  }, [entity])

  const fetchData = () => {
    toggleLoading()
    setErrors([])
    dispatch(getCarrierCircuit(original.uuid ?? carrierCircuitId, include)).then(() => toggleLoading())
  }

  const fetchNni = (id) => {
    toggleLoading()
    NNIService.getNNI(id).then(result => {
      if (result.status === 200) {
        dispatch(updateCarrierCircuitField('type', CarrierCircuitEnums.type.ETHERNET))
        dispatch(updateCarrierCircuitField('aEnd', { type: 'nni', data: result.data }))
        dispatch(updateCarrierCircuitField('supplier', result.data.carrier))
      }
      toggleLoading()
    })
  }
  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getCarrierCircuitAuditHistory(original.uuid)).then(() => toggleAuditsLoading())
  }

  const save = () => {
    if (validated()) {
      if (isNew) {
        toggleLoading()
        dispatch(createCarrierCircuit(omitBy(requestStructure(data), isNull), include)).then((result) => {
          if (result?.errors) {
            setErrors(ApiErrorResolver(result.errors))
            toggleLoading()
          } else if (result) {
            if (orderProduct) {
              dispatch(createCircuitAssociation(orderProduct.id, result.uuid)).then(result2 => {
                if (result2 && onCreated) {
                  onCreated(result)
                }
                toggleLoading()
              })
            } else {
              onCreated?.(result)
              toggleLoading()
            }
            if (!closeModal) {
              navigate(`/sdb/carrier-circuits/${result.uuid}`)
            }
          } else {
            toggleLoading()
          }
        })
      } else {
        const toSave = diff(original, data)
        toggleLoading()
        dispatch(updateCarrierCircuit(carrierCircuitId, resolveArgs(pick(requestStructure(data), Object.keys(toSave))), include)).then((result) => {
          if (result?.errors) {
            setErrors(ApiErrorResolver(result.errors))
          } else if (result && onUpdated) {
            onUpdated(result)
          }
          toggleLoading()
        })
      }
    }
  }

  const validated = () => {
    const form = CarrierCircuitForm()
    let errorArr = formValidator({
      ...form.main,
      ...form.supplier,
      ...form.connectionAEnd,
      ...form.connectionBEnd
    }, { ...data, aEndType: data.aEnd?.type }, extraFieldValidation)
    setErrors(errorArr)
    return isEmpty(errorArr)
  }

  const handleClick = (entityType) => {
    if (entityType === 'bEnd') {
      setEntity({ type: 'rack', id: data.bEnd.id })
    }
    if (entityType === 'aEnd' && data.aEnd.type === 'nni') {
      setEntity({ type: 'nni', id: data.aEnd.data.id, size: 'xxlg' })
    }
    if (entityType === 'aEnd' && data.aEnd.type === 'rack') {
      setEntity({ type: 'rack', id: data.aEnd.data.id })
    }
  }
  const isL2tpType = () => {
    const type = carrierCircuitTypes.find(type => type.value === data.type)
    return ['SoGEA', 'FTTP', 'ADSL', 'SoADSL'].includes(type?.label)
  }

  const isEthernet = () => {
    return data.type === CarrierCircuitEnums.type.ETHERNET
  }

  const options = {
    type: carrierCircuitTypes.map(type => {
      return {
        ...type,
        isDisabled: data.aEnd.type === 'nni' && type.value !== CarrierCircuitEnums.type.ETHERNET
      }
    }),
    serviceType: crmServiceTypes.options.filter(type => [1, 2, 3, 6].includes(type.value)),
    orderStatus: carrierCircuitOrderStatuses,
    supplier: Object.values(suppliers).map(({ id, name, accountNumber, isActive }) => ({
      value: id,
      label: `${name} (${accountNumber})`,
      isDisabled: !isActive
    })),
    aEndType: [{ value: 'rack', label: 'Rack', isDisabled: isL2tpType() }, {
      value: 'nni',
      label: 'NNI',
      isDisabled: isL2tpType() || !isEthernet()
    }, { value: 'l2tp', label: 'L2TP', isDisabled: !isL2tpType() }],
    bearerSpeed: data.type ? carrierCircuitBearerSpeeds.map(speed => {
      return {
        ...speed,
        label: bandwidthConverter(speed.label),
        isDisabled: !speed.types.includes(data.type)
      }
    }) : [],
    cdr: [
      { value: 10, label: '10Mbps' },
      { value: 20, label: '20Mbps' },
      { value: 30, label: '30Mbps' },
      { value: 40, label: '40Mbps' },
      { value: 50, label: '50Mbps' },
      { value: 60, label: '60Mbps' },
      { value: 70, label: '70Mbps' },
      { value: 80, label: '80Mbps' },
      { value: 90, label: '90Mbps' },
      { value: 100, label: '100Mbps' },
      { value: 200, label: '200Mbps' },
      { value: 300, label: '300Mbps' },
      { value: 400, label: '400Mbps' },
      { value: 500, label: '500Mbps' },
      { value: 600, label: '600Mbps' },
      { value: 700, label: '700Mbps' },
      { value: 800, label: '800Mbps' },
      { value: 900, label: '900Mbps' },
      { value: 1000, label: '1Gbps' },
      { value: 2000, label: '2Gbps' },
      { value: 5000, label: '5Gbps' },
      { value: 10000, label: '10Gbps' }
    ],
    aEnd: data.aEnd?.type === 'nni' ? [...nniOptions] : [...l2tpOptions]
  }

  const selectValue = {
    supplier: () => options.supplier.find(opt => data.supplier?.accountid ? data.supplier?.accountid === opt.value : data.supplier === opt.value) || null,
    aEnd: () => {
      return data.aEnd?.type === 'l2tp' ?
        l2tpOptions.find(option => option.id === (data.aEnd.data ? data.aEnd.data.id : data.aEnd.data)) ?? null :
        nniOptions.find(option => option.id === (data.aEnd.data ? data.aEnd.data.id : data.aEnd.data)) ?? null
    },
    serviceType: () => crmServiceTypes.options.find(opt => opt.value === (data.serviceType !== undefined ? data.serviceType : data.crmCircuit?.circuitType)) ?? null

  }

  const isProductOptionDisabled = (lifecycleStage) => {
    return lifecycleStage === ProductEnums.lifecycleStage.CEASED ||
      lifecycleStage === ProductEnums.lifecycleStage.CANCELED
  }

  const aSyncOptions = {
    nni: (search) => NNIService.list(['carrier'], [
      { id: 'name', value: search },
      { id: 'carrier.name', value: search },
      { id: 'carrier.account_number', value: search }
    ])
      .then((result) => result?.data || []),
    rack: (search) => {
      return RackService.list(1, 100, search)
        .then((result) => result?.data?.items || [])
    },
    orderProduct: search => {
      return dispatch(searchProducts([], [
        { id: 'productNumber', value: search }
      ], [], 'or', [], undefined)).then((result) => result.map(product => ({
        ...product,
        isDisabled: isProductOptionDisabled(product.lifecycleStage)
      })))
    }
  }

  const extraFieldValidation = (key, value) => {
    const errorArr = []
    if (key === 'cdr' && data[key] > carrierCircuitBearerSpeeds.find(speed => speed.value === data.bearerSpeed)?.label) {
      errorArr.push(`${value.label} must be lower or equal to the bearer speed.`)
    }

    if (key === 'aEnd' && !isEmpty(data.aEnd?.type) && isEmpty(data[key]?.data)) {
      errorArr.push(`You must provide a valid ${value.label}.`)
    }
    if (
      key === 'aEnd' &&
      !isEmpty(data.aEnd?.data) &&
      (data.aEnd.type === 'l2tp' || data.aEnd.type === 'nni') &&
      data.aEnd.data.carrier?.accountid !== data.supplier &&
      data.aEnd.data.carrier !== data.supplier
    ) {
      errorArr.push(`A End carrier and selected supplier must be identical.`)
    }
    return errorArr
  }

  const requestStructure = (data) => ({
    name: data.name,
    type: data.type,
    serviceType: data.serviceType,
    bearerSpeed: data.bearerSpeed,
    cdr: data.cdr,
    description: data.description,
    supplier: data.supplier?.accountid ?? data.supplier,
    orderStatus: data.orderStatus,
    carrierJobNumber: data.carrierJobNumber,
    secondaryCarrierReference: data.secondaryCarrierReference,
    carrierReference: data.carrierReference,
    tailCarrierReference: data.tailCarrierReference,
    etherwayCode: data.etherwayCode,
    etherflowCode: data.etherflowCode,
    aSTag: data.aSTag,
    bSTag: data.bSTag,
    aEndType: data.aEnd?.type,
    aEnd: data.aEnd?.data?.id,
    bEnd: data.bEnd?.id,
    orderDate: data.orderDate ? moment(data.orderDate).format('YYYY-MM-DD') : null,
    carrierAcceptedDate: data.carrierAcceptedDate ? moment(data.carrierAcceptedDate).format('YYYY-MM-DD') : null,
    carrierDeliveryDate: data.carrierDeliveryDate ? moment(data.carrierDeliveryDate).format('YYYY-MM-DD') : null,
    actualDeliveryDate: data.actualDeliveryDate ? moment(data.actualDeliveryDate).format('YYYY-MM-DD') : null,
    cancellationDate: data.cancellationDate ? moment(data.cancellationDate).format('YYYY-MM-DD') : null,
    ceaseDate: data.ceaseDate ? moment(data.ceaseDate).format('YYYY-MM-DD') : null,
    orderProduct: data.orderProduct?.id,
    radiusUsername: data.radiusUsername,
    uuid: data.crmCircuitId
  })

  const setField = (field, value) => {
    if (field === 'aEndType') {
      dispatch(updateCarrierCircuitField('aEnd', { type: value, data: {} }))
      return
    } else if (field === 'aEnd' && data.aEnd.type === 'l2tp') {
      dispatch(updateCarrierCircuitField('aEnd', {
        ...data.aEnd,
        data: l2tpOptions.find(option => option.id === value)
      }))
      return
    } else if (field === 'aEnd' && data.aEnd.type === 'nni') {
      dispatch(updateCarrierCircuitField('aEnd', {
        ...data.aEnd,
        data: nniOptions.find(option => option.id === value)
      }))
      return
    } else if (field === 'aEnd') {
      dispatch(updateCarrierCircuitField('aEnd', { ...data.aEnd, data: (value || {}) }))
      return
    } else if (field === 'serviceType') {
      dispatch(updateCarrierCircuitField('serviceType', value))
    }
    dispatch(updateCarrierCircuitField(field, value ?? null))
  }

  const onClose = () => {
    if (hasChanges()) {
      dispatch(setConfirmDialog({
        color: 'danger',
        text: 'You have unsaved changes! Closing this window will result losing the changes you\'ve made.',
        proceed: () => closeModal()
      }))
    } else {
      closeModal()
    }
  }
  const hasChanges = () => {
    const changes = diff(original, data)
    return !isEmpty(changes)
  }

  const getEntityComponent = () => {
    if (entity.type === 'rack') {
      return <Rack
        id={entity.id}
        closeModal={() => {
          toggleEntityModal()
          setEntity(entityInitialState)
        }}
      />
    }
    if (entity.type === 'nni') {
      return <NNI
        id={entity.id}
        closeModal={() => {
          toggleEntityModal()
          setEntity(entityInitialState)
        }}
      />
    }
  }
  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: 'You are about to delete this carrier circuit!',
      proceed: () => {
        toggleLoading()
        dispatch(deleteCarrierCircuit(id)).then(result => {
          toggleLoading()
          if (result) {
            navigate('/sdb/carrier-circuits')
          }
        })
      }
    }))
  }
  const canDelete = () => {
    return isEmpty(original.deviceModel) && isEmpty(original.component)
  }

  const isProductAssignable = () => {
    return isEmpty(data.orderProduct) ||
      (data.orderProduct?.lifecycleStage !== ProductEnums.lifecycleStage.CEASED &&
        data.orderProduct?.lifecycleStage !== ProductEnums.lifecycleStage.CANCELED)
  }

  const onRemoveProductAssociation = (productId) => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: 'You are about to remove the link between this product and the circuit!',
      proceed: () => {
        toggleProductsLoading()
        dispatch(deleteCircuitAssociation(productId, original.id)).then(result => {
          if (result) {
            dispatch(removeCarrierCircuitProduct(productId))
          }
          toggleProductsLoading()
        })
      }
    }))
  }

  return (
    <>
      <div className="animated fadeIn">
        <NavigationBlocker shouldBlock={hasChanges()}/>
        <LoadingOverlay loading={loading}>
          <Card className="bg-light border-0 mb-3">
            <CardBody>
              <Row className="mb-2">
                <Col className="d-flex justify-content-between">
                  <CardTitle>
                    {original.name || 'New Circuit'}
                    <Badge className={'ml-2 p-2 faded-info'}>ServiceDB</Badge>
                  </CardTitle>
                  <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn')}>
                    {
                      hasChanges() && <UnsavedChangesAlert save={save}/>
                    }
                    {
                      isNew &&
                      <Button size={'sm'} color={'secondary'} className={'ml-2'} onClick={toggleExistingCircuitModal}>Import
                        From CRM</Button>
                    }
                    {!isNew && <ButtonIcon
                      disabled={!canDelete()}
                      icon={'fa fa-trash'}
                      tooltip={canDelete() ? 'Delete' : `Carrier circuit has existing ${!isEmpty(original.deviceModel) ? 'device model' : 'connection'}`}
                      onClick={onDeleting}
                    />}
                    <ButtonIcon disabled={!canCreateCarrierCircuit(permissions) || loading || !hasChanges()}
                                icon={'fa fa-save'} tooltip={'Save'} onClick={save}/>
                    <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'} onClick={fetchData}/>
                    {closeModal &&
                      <ButtonIcon onClick={onClose} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>
                    }
                  </div>
                </Col>
              </Row>
              <FormValidationErrors errors={errors}/>
              <DeactivatedEntityWarning deactivated={!isProductAssignable()}
                                        message={'The associated Product is not in use.'}/>
              <Row className={'d-flex'}>
                <Col className={'d-flex col-12 col-sm-12 col-md-6 col-lg-6'}>
                  <EntityMainFormCard grow>
                    <GenericForm
                      data={data}
                      form={CarrierCircuitForm(handleClick, carrierCircuitTypes).main}
                      options={options}
                      selectValue={selectValue}
                      aSyncOptions={aSyncOptions}
                      setField={setField}
                    />
                  </EntityMainFormCard>
                </Col>
                <Col className={'d-flex col-12 col-sm-12 col-md-6 col-lg-6'}>
                  <EntitySubFormCard title={'Carrier'}>
                    <GenericForm
                      data={data}
                      form={CarrierCircuitForm().supplier}
                      options={options}
                      selectValue={selectValue}
                      aSyncOptions={aSyncOptions}
                      setField={setField}
                    />
                  </EntitySubFormCard>
                </Col>
                <Col className={'d-flex col-12 col-sm-12 col-md-6 col-lg-6'}>
                  <EntitySubFormCard title={'A End'} grow>
                    <GenericForm
                      data={{ ...data, aEndType: data.aEnd?.type }}
                      form={CarrierCircuitForm(handleClick).connectionAEnd}
                      options={options}
                      selectValue={selectValue}
                      aSyncOptions={aSyncOptions}
                      setField={setField}
                    />
                  </EntitySubFormCard>
                </Col>
                <Col className={'d-flex col-12 col-sm-12 col-md-6 col-lg-6'}>
                  <EntitySubFormCard title={'B End'} grow>
                    <GenericForm
                      data={data}
                      form={CarrierCircuitForm(handleClick).connectionBEnd}
                      options={options}
                      selectValue={selectValue}
                      aSyncOptions={aSyncOptions}
                      setField={setField}
                    />
                  </EntitySubFormCard>
                </Col>
              </Row>
              {!isNew &&
                <>
                  <Row>
                    <Col>
                      <CollapsibleCard
                        title={'Linked Products'}
                      >
                        <LoadingOverlay loading={productsLoading}>
                          <OrderProductsTable
                            excludeColumns={['term', 'install', 'rental']}
                            fetchData={fetchData}
                            remove={(event, productId) => {
                              event.stopPropagation()
                              onRemoveProductAssociation(productId)
                            }}
                            products={original.orderProducts ?? []}
                          />
                        </LoadingOverlay>

                      </CollapsibleCard>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CollapsibleCard
                        title={'Notes'}
                      >
                        <Notes
                          withNew
                          notes={data.notes}
                          relatedTo={{ type: 'carrierCircuit', data }}
                          onCreated={note => dispatch(addCarrierCircuitNote(note))}
                          onUpdated={note => dispatch(updateCarrierCircuitNote(note))}
                          onDeleted={note => dispatch(removeCarrierCircuitNote(note))}
                        />
                      </CollapsibleCard>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CollapsibleCard
                        title={'Cases'}
                      >
                        Coming soon...
                      </CollapsibleCard>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CollapsibleCard
                        title={'Audit History'}
                        onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                      >
                        <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                      </CollapsibleCard>
                    </Col>
                  </Row>
                </>
              }
            </CardBody>
          </Card>
        </LoadingOverlay>
        <HeadlessModal
          open={showEntityModal}
          onClosed={() => setEntity(entityInitialState)}
          size={entity.size ?? 'xxlg'}
          toggle={toggleEntityModal}
        >
          <Row>
            <Col>
              {getEntityComponent()}
            </Col>
          </Row>

        </HeadlessModal>
        <CircuitLookupModal
          account={orderProduct?.company}
          open={showExistingCircuitModal}
          toggle={toggleExistingCircuitModal}
          addCircuit={circuit => {
            toggleExistingCircuitModal()
            dispatch(updateCarrierCircuitFields({
              'crmCircuitId': circuit.id,
              'name': circuit.name,
              'radiusUsername': circuit.radiusUsername,
              'supplier': circuit.supplier.accountid,
              'carrierJobNumber': circuit.carrierJobNumber,
              'carrierReference': circuit.carrierCircuitNumber,
              'tailCarrierReference': circuit.tailCarrierRef,
              'carrierAcceptedDate': circuit.carrierAcceptedDate,
              'carrierDeliveryDate': circuit.carrierDeliveryDate,
              'actualDeliveryDate': circuit.actualDeliveryDate,
              'cancellationDate': circuit.cancellationSubmittedDate,
              'ceaseDate': circuit.ceaseDate,
            }))
          }
          }

          circuits={[]}
        />
      </div>
    </>

  );
};

function mapStateToProps({ carrierCircuit, authenticationState, helpers }) {
  return {
    permissions: authenticationState.account.permissions,
    carrierCircuitTypes: helpers.optionSets.carrierCircuit?.type.options,
    carrierCircuitOrderStatuses: helpers.optionSets.carrierCircuit?.orderStatus.options,
    carrierCircuitBearerSpeeds: helpers.optionSets.carrierCircuit?.bearerSpeed.options,
    suppliers: helpers.suppliers,
    l2tpOptions: helpers.serviceDb.l2tp,
    nniOptions: helpers.serviceDb.nni,
    crmServiceTypes: helpers.optionSets.circuit.circuitType,
    data: carrierCircuit.data,
    original: carrierCircuit.original,
    audits: carrierCircuit.audits
  };
}

export default connect(mapStateToProps)(CarrierCircuit);
