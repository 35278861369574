import axios from 'axios';

export const ExtTrafficService = {
  getExtTraffic: () => {
    const url = 'https://admin0.sov.uk.goscomb.net/~dang/traffic.json';
    return axios.get(url).then(response => response)
      .catch(function (error) {
        return Promise.reject(error.response || error.message);
      });
  }
};
