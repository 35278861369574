import React from 'react';

class ServiceDelivery extends React.Component {
  render() {
    return (
            <div className="animated fadeIn">

            </div>
    );
  }
}

export default ServiceDelivery;
