import { defaultErrorFeedback } from '../../feedback';
import { AddressService } from '../../../utils/ServiceDB/Ipam/Address';
import { SubnetService } from '../../../utils/ServiceDB/Ipam/Subnet';

export const IPAM_ADDRESS_SET_DATA = 'IPAM_ADDRESS_SET_DATA';
export const IPAM_ADDRESS_SET_FIELD = 'IPAM_ADDRESS_SET_FIELD';
export const IPAM_ADDRESS_RESET_DATA = 'IPAM_ADDRESS_RESET_DATA';

export function getAddress(id, include = []) {
  return (dispatch) => {
    return AddressService.get(id, include).then((result) => {
      if (result.status === 200) {
        dispatch(setAddressData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createAddress(args, include = []) {
  return (dispatch) => {
    return AddressService.create(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setAddressData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function updateAddress(id, args, include = []) {
  return (dispatch) => {
    return AddressService.update(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setAddressData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
      return result;
    });
  };
}

export function deleteAddress(id) {
  return (dispatch) => {
    return AddressService.delete(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

function setAddressData(data) {
  return {
    type: IPAM_ADDRESS_SET_DATA,
    data
  };
}

export function setAddressField(field, value) {
  return {
    type: IPAM_ADDRESS_SET_FIELD,
    field,
    value
  };
}

export function resetAddress() {
  return {
    type: IPAM_ADDRESS_RESET_DATA
  };
}

export function getNextAddressForSubnet(subnetId) {
  return (dispatch) => {
    return SubnetService.getNextAddress(subnetId).then((result) => {
      if (result.status === 200) {
        dispatch(setAddressField('address', result.data.result));
      }
      return result;
    });
  };
}
