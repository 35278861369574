import {
  ACCOUNTS_RESET_ACCOUNT,
  ACCOUNTS_SET_ACCOUNT,
  ACCOUNTS_UPDATE_ACCOUNT_INFO_FIELD,
  ACCOUNTS_SET_EMULATOR,
  ACCOUNTS_SET_PORTAL_ACTIVATION_CONTACT,
  ACCOUNTS_SET_SEARCH_RESULTS,
  ACCOUNTS_SET_SEARCH_STRING,
  ACCOUNTS_TOGGLE_ACCOUNT_LOADING,
  ACCOUNTS_TOGGLE_CACHE_REQUEST_PROCESSING,
  ACCOUNTS_TOGGLE_CONTACT_EMULATING,
  ACCOUNTS_TOGGLE_SEARCH_LOADING,
  ACCOUNTS_CONTACTS_SET_SEARCH_STRING,
  ACCOUNTS_SET_BILLS,
  ACCOUNTS_BILLS_SET_SEARCH_STRING,
  ACCOUNTS_SET_SERVICES,
  ACCOUNTS_SERVICE_SET_SEARCH_STRING,
  ACCOUNTS_SET_CASES,
  ACCOUNTS_CASES_SET_SEARCH_STRING,
  ACCOUNTS_SET_ACCOUNT_INFO_FORM_DATA,
  ACCOUNTS_SET_ORDERS,
  ACCOUNTS_ORDERS_SET_SEARCH_STRING,
  ACCOUNT_UPDATE_ORDER_IN_TABLE,
  ACCOUNT_UPDATE_CASE_IN_TABLE,
  ACCOUNT_UPDATE_CONTACT_IN_TABLE,
  ACCOUNTS_SET_SITES,
  ACCOUNTS_SITES_SET_SEARCH_STRING,
  ACCOUNTS_CIRCUITS_SET_SEARCH_STRING,
  ACCOUNTS_SET_SITES_SITE,
  ACCOUNTS_SERVICES_SET_SERVICE,
  ACCOUNTS_ADD_SITES_SITE,
  ACCOUNTS_SET_CONFIG_ITEMS,
  ACCOUNTS_CONFIG_ITEM_SET_SEARCH_STRING,
  ACCOUNTS_SET_CONFIG_ITEMS_ITEM,
  ACCOUNT_ADD_CONTACTS_CONTACT,
  ACCOUNT_ADD_CASES_CASE,
  ACCOUNTS_SET_CIRCUITS,
  ACCOUNTS_SET_LOGS,
  ACCOUNTS_LOGS_SET_SEARCH_STRING,
  ACCOUNTS_SET_DOCUMENTS,
  ACCOUNTS_DOCUMENTS_SET_SEARCH_STRING,
  ACCOUNTS_DOCUMENTS_SET_DOCUMENT,
  ACCOUNTS_DOCUMENTS_REMOVE_DOCUMENT,
  ACCOUNTS_DOCUMENTS_UPDATE_DOCUMENT,
  ACCOUNTS_SET_SIGNATURES,
  ACCOUNTS_SET_PORTAL_ACTIVATION_ERRORS,
  ACCOUNTS_ADD_LOGS_LOG,
  ACCOUNTS_SET_PARTNERSHIPS,
  ACCOUNTS_PARTNERSHIPS_SET_PARTNERSHIP,
  ACCOUNTS_PARTNERSHIPS_REMOVE_PARTNERSHIP, ACCOUNTS_PARTNERSHIPS_SET_SEARCH_STRING
} from '../actions/accounts';
import moment from 'moment';

const initialState = {
  searchString: '',
  accounts: [],
  account: {
    sites: [],
    partnerships: [],
    contacts: [],
    bills: [],
    services: [],
    cases: [],
    orders: [],
    configItems: [],
    circuits: [],
    logs: [],
    documents: []
  },
  accountInfoForm: {},
  accountLoading: false,
  cacheProcessing: false,
  searchLoading: false,
  emulating: false,
  emulator: {
    url: null,
    portal: null
  },
  portalActivation: {
    contact: {},
    errors: []
  }
};

const resolveState = (state) => {
  switch (state) {
    case 0:
      return 'Active';
    case 1:
      return 'Inactive';
    case 2:
      return 'Canceled';
    default:
      return state;
  }
};

export default function accounts(state = initialState, action) {
  let filtered;

  switch (action.type) {
    case ACCOUNTS_SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString
      };
    case ACCOUNTS_SET_SEARCH_RESULTS:
      return {
        ...state,
        accounts: action.accounts
      };
    case ACCOUNTS_TOGGLE_SEARCH_LOADING:
      return {
        ...state,
        searchLoading: !state.searchLoading
      };
    case ACCOUNTS_SET_ACCOUNT:
      return {
        ...state,
        account: { ...state.account, ...action.account }
      };
    case ACCOUNTS_SET_ACCOUNT_INFO_FORM_DATA:
      return {
        ...state,
        accountInfoForm: { ...state.accountInfoForm, ...action.data }
      };
    case ACCOUNTS_RESET_ACCOUNT:
      return {
        ...initialState,
        accounts: [...state.accounts]
      };
    case ACCOUNTS_TOGGLE_ACCOUNT_LOADING:
      return {
        ...state,
        accountLoading: !state.accountLoading
      };
    case ACCOUNTS_TOGGLE_CONTACT_EMULATING:
      return {
        ...state,
        emulating: !state.emulating
      };
    case ACCOUNTS_SET_EMULATOR:
      return {
        ...state,
        emulator: action.emulator
      };
    case ACCOUNTS_UPDATE_ACCOUNT_INFO_FIELD:
      return {
        ...state,
        accountInfoForm: {
          ...state.accountInfoForm,
          [action.field]: action.value
        }
      };
    case ACCOUNTS_SET_PORTAL_ACTIVATION_CONTACT:
      return {
        ...state,
        portalActivation: {
          ...state.portalActivation,
          contact: action.contact
        }
      };
    case ACCOUNTS_SET_PORTAL_ACTIVATION_ERRORS:
      return {
        ...state,
        portalActivation: {
          ...state.portalActivation,
          errors: action.errors
        }
      };
    case ACCOUNTS_TOGGLE_CACHE_REQUEST_PROCESSING:
      return {
        ...state,
        cacheProcessing: !state.cacheProcessing
      };
    case ACCOUNTS_CONTACTS_SET_SEARCH_STRING:
      filtered = state.account.contacts.filter((contact) => {
        return String(contact.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(contact.email).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(contact.phone).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || (contact.portalUser && String(JSON.stringify(contact.portalUser.username))
            .toLowerCase().indexOf(action.search.toLowerCase()) !== -1)
          || (contact.portalUser && String(JSON.stringify(contact.portalUser.role)).toLowerCase()
            .indexOf(action.search.toLowerCase()) !== -1);
      });

      return {
        ...state,
        contactSearch: action.search,
        filteredContacts: filtered
      };
    case ACCOUNTS_SET_BILLS:
      return {
        ...state,
        account: {
          ...state.account,
          bills: action.bills
        }
      };
    case ACCOUNTS_SET_SERVICES:
      return {
        ...state,
        account: {
          ...state.account,
          services: action.services
        }
      };
    case ACCOUNTS_SERVICES_SET_SERVICE:
      return {
        ...state,
        account: {
          ...state.account,
          services: state.account.services.map((service) => action.id === service.id ? { ...service, ...action.service } : service)
        }
      };
    case ACCOUNTS_SET_ORDERS:
      return {
        ...state,
        account: {
          ...state.account,
          orders: action.orders
        }
      };
    case ACCOUNTS_SET_LOGS:
      return {
        ...state,
        account: {
          ...state.account,
          logs: action.logs
        }
      };

    case ACCOUNTS_ADD_LOGS_LOG:
      return {
        ...state,
        account: {
          ...state.account,
          logs: state.account.logs.concat(action.data)
        }
      };

    case ACCOUNTS_BILLS_SET_SEARCH_STRING:
      filtered = state.account.bills.filter((bill) => {
        const altDate = moment(bill.date)
          .format('DD/MM/YYYY');
        return String(bill.id).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(bill.date).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(bill.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(altDate).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(bill.payment_method).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(JSON.stringify(bill.artifacts)).toLowerCase()
            .indexOf(action.search.toLowerCase()) !== -1;
      });

      return {
        ...state,
        billSearch: action.search,
        filteredBills: filtered
      };

    case ACCOUNTS_SERVICE_SET_SEARCH_STRING:
      filtered = state.account.services.filter((service) => {
        const goLiveDate = moment(service.goLiveDate)
          .format('DD/MM/YYYY');
        return String(service.orderNumber).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(service.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(goLiveDate).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(service.productCode).toLowerCase().indexOf(action.search.toLowerCase()) !== -1;
      });

      return {
        ...state,
        serviceSearch: action.search,
        filteredServices: filtered
      };

    case ACCOUNTS_ORDERS_SET_SEARCH_STRING:
      filtered = state.account.orders.filter((order) => {
        const type = action.optionSets.type.options.find(({ value }) => value === order.type);
        return String(order.orderNumber).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(order.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(order.owner.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || (type && String(type.label).toLowerCase().indexOf(action.search.toLowerCase()) !== -1)
          || (order.contact && String(JSON.stringify(order.contact.name)).toLowerCase()
            .indexOf(action.search.toLowerCase()) !== -1);
      });

      return {
        ...state,
        orderSearch: action.search,
        filteredOrders: filtered
      };

    case ACCOUNTS_SET_CASES:
      return {
        ...state,
        account: {
          ...state.account,
          cases: action.cases
        }
      };

    case ACCOUNTS_SET_SITES:
      return {
        ...state,
        account: {
          ...state.account,
          sites: action.sites
        }
      };

    case ACCOUNTS_SET_PARTNERSHIPS:
      return {
        ...state,
        account: {
          ...state.account,
          partnerships: action.partnerships
        }
      };

    case ACCOUNTS_SET_CIRCUITS:
      return {
        ...state,
        account: {
          ...state.account,
          circuits: action.circuits
        }
      };

    case ACCOUNTS_SET_CONFIG_ITEMS:
      return {
        ...state,
        account: {
          ...state.account,
          configItems: action.configItems
        }
      };

    case ACCOUNTS_ADD_SITES_SITE:
      return {
        ...state,
        account: {
          ...state.account,
          sites: state.account.sites.concat(action.data)
        }
      };

    case ACCOUNTS_SET_SITES_SITE:
      return {
        ...state,
        account: {
          ...state.account,
          sites: state.account.sites.map((site) => {
            return action.data.accountnumber === site.accountnumber ? { ...action.data } : site;
          })
        }
      };

    case ACCOUNTS_CASES_SET_SEARCH_STRING:
      filtered = state.account.cases.filter((theCase) => {
        const altCreatedDate = moment(theCase.createdOn).format('DD/MM/YYYY');
        const altModifiedDate = moment(theCase.modifiedOn).format('DD/MM/YYYY');
        const statusOption = action.optionSets.status.options.find(({ value }) => value === theCase.status);
        const status = Number.isInteger(theCase.status) && statusOption ? statusOption.label : theCase.status;
        const caseState = resolveState(theCase.state);
        return String(theCase.caseId).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(theCase.title).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(status).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(caseState).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(altCreatedDate).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(altModifiedDate).toLowerCase().indexOf(action.search.toLowerCase()) !== -1;
      });

      return {
        ...state,
        caseSearch: action.search,
        filteredCases: filtered
      };

    case ACCOUNTS_SITES_SET_SEARCH_STRING:
      filtered = state.account.sites.filter((site) => {
        return String(site.accountnumber).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(site.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1;
      });

      return {
        ...state,
        siteSearch: action.search,
        filteredSites: filtered
      };

    case ACCOUNTS_PARTNERSHIPS_SET_SEARCH_STRING:
      filtered = state.account.partnerships.filter((partnership) => {
        const typeOption = action.optionSets.type.options.find(({ value }) => value === partnership.type);
        const type = Number.isInteger(partnership.type) && typeOption ? typeOption.label : partnership.type;
        return String(partnership.partner.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(partnership.customer.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(type).toLowerCase().indexOf(action.search.toLowerCase()) !== -1;
      });

      return {
        ...state,
        partnershipSearch: action.search,
        filteredPartnerships: filtered
      };

    case ACCOUNTS_CONFIG_ITEM_SET_SEARCH_STRING:
      filtered = state.account.configItems.filter((configItem) => {
        const assignedTo = configItem.assignedTo ? configItem.assignedTo.name : '';
        const location = configItem.location ? configItem.location.name : '';
        const status = action.optionSets.statusCode.options.find(({ value }) => value === configItem.statusCode);
        const model = action.optionSets.model.options.find(({ value }) => value === configItem.model);
        return String(configItem.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(assignedTo).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(location).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || (status && String(status.label)
            .toLowerCase()
            .indexOf(action.search.toLowerCase()) !== -1)
          || (model && String(model.label)
            .toLowerCase()
            .indexOf(action.search.toLowerCase()) !== -1)
          || String(configItem.managementIp)
            .toLowerCase()
            .indexOf(action.search.toLowerCase()) !== -1;
      });

      return {
        ...state,
        configItemSearch: action.search,
        filteredConfigItems: filtered
      };

    case ACCOUNTS_SET_CONFIG_ITEMS_ITEM:
      return {
        ...state,
        account: {
          ...state.account,
          configItems: state.account.configItems.map((configItem) => action.data.id === configItem.id ? action.data : configItem)
        }
      };

    case ACCOUNTS_CIRCUITS_SET_SEARCH_STRING:
      filtered = state.account.circuits.filter((circuit) => {
        return String(circuit.id).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(circuit.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(circuit.circuitRef).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(circuit.assignedTo.name)
            .toLowerCase()
            .indexOf(action.search.toLowerCase()) !== -1;
      });

      return {
        ...state,
        circuitSearch: action.search,
        filteredCircuits: filtered
      };

    case ACCOUNTS_LOGS_SET_SEARCH_STRING:
      filtered = state.account.logs.filter((log) => {
        const user = action.systemUsers.find(({ value }) => value === log.id);
        return String(log.id).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(log.contactEmail).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || (user && String(user.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1);
      });

      return {
        ...state,
        logsSearch: action.search,
        filteredLogs: filtered
      };

    case ACCOUNT_ADD_CASES_CASE:
      return {
        ...state,
        account: {
          ...state.account,
          cases: state.account.cases.concat(action.theCase)
        }
      };

    case ACCOUNT_UPDATE_CASE_IN_TABLE:
      return {
        ...state,
        account: {
          ...state.account,
          cases: state.account.cases.map((theCase) => {
            if (theCase.caseId === action.theCase.caseId) {
              return action.theCase;
            }
            return theCase;
          })
        }
      };

    case ACCOUNT_UPDATE_ORDER_IN_TABLE:
      return {
        ...state,
        account: {
          ...state.account,
          orders: state.account.orders.map((order) => {
            if (order.id === action.order.id) {
              return action.order;
            }
            return order;
          })
        }
      };
    case ACCOUNTS_SET_SIGNATURES:
      return {
        ...state,
        account: {
          ...state.account,
          signatures: action.signatures
        }
      };

    case ACCOUNTS_SET_DOCUMENTS:
      return {
        ...state,
        account: {
          ...state.account,
          documents: action.documents
        }
      };

    case ACCOUNTS_DOCUMENTS_SET_SEARCH_STRING:
      filtered = state.account.documents.filter((document) => {
        return String(document.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || String(document.type).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
          || action.optionSets.status.find((status) => status.value === document.status)
            ?.label
            ?.toLowerCase()
            .indexOf(action.search.toLowerCase()) !== -1
          || moment(document.updatedAt)
            .format('DD/MM/YYYY HH:mm')
            .indexOf(action.search.toLowerCase()) !== -1;
      });

      return {
        ...state,
        documentsSearch: action.search,
        filteredDocuments: filtered
      };

    case ACCOUNTS_DOCUMENTS_SET_DOCUMENT:
      return {
        ...state,
        account: {
          ...state.account,
          documents: state.account.documents.find(document => document.id === action.data.id)
            ? state.account.documents.map(document => document.id === action.data.id ? action.data : document)
            : [...state.account.documents, action.data]
        }
      };

    case ACCOUNTS_DOCUMENTS_REMOVE_DOCUMENT:
      return {
        ...state,
        account: {
          ...state.account,
          documents: state.account.documents.filter(document => document.id !== action.documentId)
        }
      };

    case ACCOUNTS_DOCUMENTS_UPDATE_DOCUMENT:
      return {
        ...state,
        account: {
          ...state.account,
          documents: state.account.documents.map(document => document.id === action.data.id ? action.data : document)
        }
      };


    case ACCOUNTS_PARTNERSHIPS_SET_PARTNERSHIP:
      return {
        ...state,
        account: {
          ...state.account,
          partnerships: state.account.partnerships.find(partnership => partnership.customerId === action.data.customerId)
            ? state.account.partnerships.map(partnership => partnership.customerId === action.data.customerId ? action.data : partnership)
            : [...state.account.partnerships, action.data]
        }
      };

    case ACCOUNTS_PARTNERSHIPS_REMOVE_PARTNERSHIP:
      return {
        ...state,
        account: {
          ...state.account,
          partnerships: state.account.partnerships.filter(partnership => partnership.customerId !== action.customerId)
        }
      };

    default:
      return state;
  }
}
