export const BGPSTART = 'BGPSTART';

export const BGPCLEAR = 'BGPCLEAR';
export const BGPINIT = 'BGPINIT';
export const BGPNODEUPDATE = 'BGPNODEUPDATE';
export const BGPEDGEUPDATE = 'BGPEDGEUPDATE';
export const BGPEDGEREMOVE = 'BGPEDGEREMOVE';

export function initBGP() {
  return (dispatch) => {
    dispatch({
      type: BGPSTART
    });
  };
}
