import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loadable from 'react-loading-overlay';
import { Badge } from 'reactstrap';

import { errorFeedback } from '../../actions/feedback';
import { CasesService } from '../../utils/Cases/CasesService';
import { connect } from 'react-redux';

class CaseSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      case: {}
    };
  }

  componentDidMount() {
    CasesService.getCaseSummary(this.props.caseId).then((result) => {
      if (result.status === 200) {
        this.setState({
          case: result.data,
          loading: false
        });
      } else {
        this.props.dispatch(errorFeedback(result.data.error));
      }
    }).catch((error) => {
      this.props.dispatch(errorFeedback(error));
    });
  }

  render() {
    if (this.state.loading == true) {
      return (<div className="row justify-content-center">
                Loading <Loadable active={true} spinner color={'rgb(62,81,91)'} spinnerSize={'20px'} style={{ marginLeft: '20px' }}/>
            </div>);
    }
    return (
            <div>
                <p><b>Title</b><br/><Link target="_blank" to={this.state.case.crmUrl}>{this.state.case.title} <i className="icon-share-alt"/></Link></p>
                <p><b>State</b><br/>{this.state.case.state === 'Active' ? <Badge color="success">Active</Badge> : <Badge color="danger">Closed</Badge>}</p>
                <p><b>Status</b><br/>{this.state.case.status}</p>
                <p><b>Created</b><br/>{this.state.case.createdOn}</p>
                <p><b>Modified</b><br/>{this.state.case.modifiedOn}</p>
            </div>
    );
  }
}

function mapStateToProps({ zabbixAlarms }) {
  return {};
}

export default connect(mapStateToProps)(CaseSummary);
