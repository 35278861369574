import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import classnames from 'classnames';
import { detailedDiff, diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import { connect } from 'react-redux';
import LoadingOverlay from '../../components/LoadingOverlay';
import { setConfirmDialog } from '../../actions/dialogs';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../components/ButtonIcon';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import generateFormFields from '../../helpers/FormFieldGenerator';
import CreditRequestForm from './form';
import {
  addCreditRequestProduct, authoriseCreditRequest, cancelCreditRequest,
  createAccountCreditRequest,
  getCreditRequest,
  removeCreditRequestProduct,
  resetCreditRequest,
  resetCreditRequestInvoices,
  resetCreditRequestProducts,
  setCreditRequestInvoices,
  updateCreditRequest,
  updateCreditRequestFormField,
  updateCreditRequestProduct
} from '../../actions/creditRequest';
import { CreditRequestEnums } from '../../utils/Constants/CreditRequest';
import EntitySubFormCard from '../../components/Cards/EntitySubFormCard';
import { SelectMod } from '../../components/Selects/SelectMod';
import CreditRequestProductsTable from '../../components/Tables/CreditRequestProducts';
import { formValidator } from '../../helpers/FormValidator';
import resolveArgs from '../../helpers/ArgumentResolver';
import omit from 'lodash.omit';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import isFloat from 'validator/lib/isFloat';
import DeactivatedEntityWarning from '../../components/Alerts/DeactivatedEntityWarning';
import moment from 'moment';
import { inCreditRequestGroups } from '../../utils/Auth/AuthService';
import CollapsibleCard from '../../components/CollapsibleCard';
import TasksTable from '../../components/Tables/Tasks';
import { TaskEnums } from '../../utils/Constants/Task';

const CreditRequest = ({
  account,
  services,
  bills,
  optionSets,
  taskOptionSets,
  users,
  user,
  creditRequest : {
    form,
    original
  },
  closeModal,
  dispatch,
  id,
  onCreated,
  onUpdated
}) => {

  const [loading, setLoading] = useState(false);
  const isNew = !id && !original.id
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if(isNew){
      dispatch(updateCreditRequestFormField({company: account}))
      dispatch(updateCreditRequestFormField({status: CreditRequestEnums.status.NOT_STARTED}))
      dispatch(updateCreditRequestFormField({reason: CreditRequestEnums.reason.SLA_BREACH}))
    }else{
      refreshData()
    }
  }, [])

  useEffect(() => {
    if(form.reason === CreditRequestEnums.reason.INVOICE_ERROR){
      dispatch(updateCreditRequestFormField({products: []}))
    }
  }, [form.reason])
  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }
  const hasChanges = () => {
    const changes = diff(original, form);
    return !isEmpty(changes) && !hasStarted()
  }
  const onClosing = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => {
          closeModal()
          dispatch(resetCreditRequest())
        }
      }))
    }else if(closeModal){
      closeModal()
      dispatch(resetCreditRequest())
    }
  }

  const onCancelling = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "Cancelling this request would also cancel any outstanding signatures and tasks related.",
      proceed: () => {
        toggleLoading()
        dispatch(cancelCreditRequest(original.id)).then((result) => {
          toggleLoading()
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }else if(result && onUpdated){
            onUpdated(result)
          }
        })
      }
    }))
  }

  const onAuthorising = () => {
    dispatch(setConfirmDialog({
      text: "After authorising you will no longer be able to amend this request.",
      proceed: () => {
        toggleLoading()
        dispatch(authoriseCreditRequest(original.id)).then((result) => {
          toggleLoading()
          if(result?.errors){
            setErrors(ApiErrorResolver(result.errors))
          }else if(result && onUpdated){
            onUpdated(result)
          }
        })
      }
    }))
  }

  const setProduct = (id) => {
    const product = services.find(service => id && service.id === id)
    if(product){
      setSelectedProduct({
        id: product.id,
        name: `${product.productNumber} - ${product.name}`,
        description: '',
        amount: ''
      })
    }else{
      setSelectedProduct(null)
    }

  }

  const addProduct = () =>{
    if(!form.products.find(formProduct => formProduct.id === selectedProduct.id)){
      dispatch(addCreditRequestProduct(selectedProduct))
    }

  }
  const save = () => {
    if(validated()){
      if(isNew){
        const payload = omit(resolveArgs(form), ['company'])
        toggleLoading()
        dispatch(createAccountCreditRequest(account.accountid, {...payload, amount: parseFloat(payload.amount)})).then((result) => {
          toggleLoading()
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }else if(result && onCreated){
            onCreated(result)
          }
        })
      }else{
        const args = diff(original, form)
        if(args.products){
          args.products = resolveProducts()
        }
        if(args.invoices){
          args.invoices = form.invoices
        }
        const payload = resolveArgs(omit(args, ['company']))
        if(payload.amount !== undefined){
          payload.amount = parseFloat(payload.amount)
        }

        toggleLoading()
        dispatch(updateCreditRequest(original.id, payload)).then((result) => {
          toggleLoading()
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }
        })
      }
    }
  }
  const refreshData = () => {
    toggleLoading()
    dispatch(getCreditRequest(id || original.id)).then(() => toggleLoading())
  }

  const resolveProducts = () => {
    return form.products.map(product => {
      if(product.product){
        return {
          description: product.description,
          id: product.id,
          amount: product.amount
        }
      }
      return product
    })
  }

  const validated = () => {
    let errorArr = formValidator(CreditRequestForm(), form);
    if(form.reason === CreditRequestEnums.reason.INVOICE_ERROR && isEmpty(form.invoices)){
      errorArr.push('You must select at least one Invoice to proceed.')
    }
    if(form.reason === CreditRequestEnums.reason.SLA_BREACH && isEmpty(form.products)){
      errorArr.push('You must select at least one Service to proceed.')
    }
    if(form.amount && !isFloat(`${form.amount}`)){
      errorArr.push('You must set a valid Amount.')
    }
    if(!isEmpty(form.products)){
      form.products.forEach((product, index) => {
        if(!product.description){
          errorArr.push(`Product #${index + 1}: You must set a valid Description.`)
        }
        if(!isFloat(`${product.amount}`)){
          errorArr.push(`Product #${index + 1}: You must set a valid Amount.`)
        }
      })
    }
    setErrors(errorArr);
    return isEmpty(errorArr);
  }

  const getServiceOptions = () => {
    return services?.map(service => {
      return {label: `${service.productNumber} - ${service.name}`, value: service.id}
    }) ?? []
  }

  const getInvoiceOptions = () => {
    return bills?.map(bill => {
      return {label: `${bill.id} - ${moment(bill.date)
          .format('DD/MM/YYYY')}`, value: bill.id}
    }) ?? []
  }

  const getSelectedInvoices = () => {
    const invoiceIds = form.invoices.map((invoice) => {
      return invoice.id ?? invoice
    })
    return getInvoiceOptions().filter(option => {
      return invoiceIds.includes(option.value)
    })
  }

  const getSelectOptions = (field) => {
    if(field === 'authorisedBy'){
      return users.filter(user => user.name.toLowerCase() === 'chris handel' || user.name.toLowerCase() === 'chris evans')
    }
    return optionSets[field]?.options ?? []
  }

  const handleSelectInput = (field, selected) =>{

    if(field === 'authorisedBy'){
      dispatch(updateCreditRequestFormField({[field] : selected}))
    }else{
      dispatch(resetCreditRequestInvoices())
      dispatch(resetCreditRequestProducts())
      setSelectedProduct(null)
      dispatch(updateCreditRequestFormField({[field] : selected?.value ?? null}))
    }
  }
  const getSelectedOption = (field) => {
    if(field === 'authorisedBy'){
      return users.filter(user => user.id === (form[field]?.id || form[field]))
    }
    return optionSets[field]?.options.find((option) => option.value === form[field]) ?? null
  }

  const hasStarted = () => {
    return form.status !== CreditRequestEnums.status.NOT_STARTED
  }

  const cancellable = () => {
    return !isNew && form.status === CreditRequestEnums.status.NOT_STARTED
  }

  const getStatusLabel = () => {
    return optionSets.status?.options.find((option) => option.value === form.status)?.label ?? ''
  }
  const getReasonLabel = () => {
    return optionSets.reason?.options.find((option) => option.value === form.reason)?.label ?? ''
  }
  const getUserLabel = (field) => {
    return users.find(user => user.id === form[field])?.name ?? ''
  }

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitle>
                  {original.requestNumber || 'Credit Request'}
                </CardTitle>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ml-auto')}>
                  {
                    hasChanges() && original.status !== CreditRequestEnums.status.PENDING && <UnsavedChangesAlert save={save}/>
                  }
                  {
                    !hasStarted() && !isNew && <Button disabled={hasChanges() || !inCreditRequestGroups(user.permissions)} className={'ml-2 faded-primary'} size={'sm'} color={'primary'} onClick={onAuthorising}>Authorise</Button>
                  }
                  {
                    cancellable() && <Button disabled={hasChanges() || !inCreditRequestGroups(user.permissions)} className={'ml-2 faded-danger'} size={'sm'} color={'danger'} onClick={onCancelling}>Cancel</Button>
                  }
                  <ButtonIcon disabled={loading || !hasChanges() || !inCreditRequestGroups(user.permissions)} icon={'fa fa-save'} tooltip={'Save'}  onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={refreshData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClosing} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }

                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row>
              <Col>
                <EntityMainFormCard>
                  <Row form>
                    {generateFormFields({
                      fields: CreditRequestForm(!hasStarted(), getStatusLabel, getReasonLabel, getUserLabel).general,
                      handleInput: (event) => dispatch(updateCreditRequestFormField({[event.target.id] : event.target.value})),
                      handleSelectInput,
                      getSelectedOption,
                      getSelectOptions,
                      data: form
                    })}
                  </Row>
                </EntityMainFormCard>
              </Col>
            </Row>
            {form.reason !== CreditRequestEnums.reason.INVOICE_ERROR ?
              <Row>
                <Col>
                  <EntitySubFormCard title={'Products'}>
                    {!hasStarted() &&
                      <Row form>
                        <Col md={10}>
                          <SelectMod
                            isClearable
                            options={getServiceOptions()}
                            onChange={(selected) => setProduct(selected?.value ?? null)}
                            value={getServiceOptions().find(option => option.value === selectedProduct?.id) ?? null}
                          />
                        </Col>
                        <Col>
                          <Button disabled={!selectedProduct} block color='light' onClick={addProduct}>Add Product</Button>
                        </Col>
                      </Row>
                    }
                    <Row>
                      <Col>
                        <CreditRequestProductsTable
                          products={form.products}
                          removeProduct={id => dispatch(removeCreditRequestProduct(id))}
                          updateProduct={(id, field) => dispatch(updateCreditRequestProduct(id, field))}
                        />
                      </Col>
                    </Row>
                  </EntitySubFormCard>
                </Col>
              </Row> :
              <Row>
                <Col>
                  <EntitySubFormCard title={'Invoices'}>
                    <Row>
                      <Col>
                        <SelectMod
                          isMulti
                          closeMenuOnSelect={false}
                          isClearable
                          options={getInvoiceOptions()}
                          onChange={selected => {
                            dispatch(setCreditRequestInvoices(selected?.map(option => option.value) ?? []))
                          }}
                          value={getSelectedInvoices()}
                        />
                      </Col>
                    </Row>
                  </EntitySubFormCard>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <CollapsibleCard title={'Tasks'}>
                  <Row>
                    <Col>
                      <TasksTable
                        withNew={false}
                        tasks={form.tasks ?? []}
                        types={taskOptionSets.type}
                        priorities={taskOptionSets.priority}
                        statusReason={taskOptionSets.statusReason}
                      />
                    </Col>
                  </Row>

                </CollapsibleCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  )
}
const mapStateToProps = ({
  creditRequest,
  account,
  helpers,
  authenticationState
}) => ({
  creditRequest,
  optionSets: helpers.optionSets.creditRequest,
  services: account.services,
  bills: account.bills,
  users: helpers.systemUsers,
  user: authenticationState.account,
  taskOptionSets: helpers.optionSets.task
});
export default connect(mapStateToProps)(CreditRequest)