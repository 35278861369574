export const JOIN_ROOMS = 'JOIN_ROOMS';
export const AUTHENTICATE_SOCKET = 'authentication';
export const LEAVE_ROOM = 'LEAVE';
export const ADD_LISTENERS_TO = 'ADD_LISTENERS_TO';
export const UPDATE_PROGRESS = 'PROGRESS';
export const REMOVE_PROGRESS = 'REMOVE_PROGRESS';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SET_SOCKET_AUTHENTICATED = 'authenticated';
export const PING = 'PING';

export function joinRooms(payload) {
  return (dispatch) => {
    dispatch({
      type: JOIN_ROOMS,
      payload
    });
  };
}

export function authenticateSocket(payload) {
  return (dispatch) => {
    dispatch({
      type: AUTHENTICATE_SOCKET,
      payload
    });
  };
}

export function addListeners(payload) {
  return (dispatch) => {
    dispatch({
      type: ADD_LISTENERS_TO,
      payload
    });
  };
}

export function ping() {
  return (dispatch) => {
    dispatch({
      type: PING
    });
  };
}

export function removeProgress(channel) {
  return {
    type: REMOVE_PROGRESS,
    channel
  };
}
