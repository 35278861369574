import FilteredTableV2 from '../../FilteredTableV2';
import { Button } from 'reactstrap';
import { ButtonIcon } from '../../ButtonIcon';
import React, {useCallback} from 'react';
import { useNavigate } from 'react-router-dom'
import {isSdbManager} from "../../../utils/Auth/AuthService";


const DeviceModelsTable = ({
  vendor,
  excludeColumns,
  includeColumns,
  deviceModels,
  fetchData
}) => {

  const navigate = useNavigate();

  const columns = [
    {
      filterable: true,
      sortable: true,
      header: 'Vendor',
      accessorKey: 'vendor.name'
    },
    {
      filterable: true,
      sortable: true,
      header: 'Name',
      accessorKey: 'name'
    },
    {
      filterable: true,
      sortable: true,
      header: 'Rack Units',
      accessorKey: 'rackUnits'
    },
    {
      filterable: false,
      sortable: true,
      header: 'In Stock',
      accessorKey: 'totalStock'
    },
    {
      filterable: false,
      sortable: true,
      header: 'Deployed',
      accessorKey: 'deployed'
    }
  ];

  const getColumns = () => {
    if(excludeColumns){
      return  columns.filter(column => !excludeColumns.includes(column.accessorKey))
    }
    return includeColumns ? columns.filter(column => includeColumns.includes(column.accessorKey)) : columns
  }

  const getButtons = useCallback(() => {
    const buttons  = []
    if(isSdbManager()){
      buttons.push(
          <Button size={'sm'} color={'secondary'} onClick={() => {
            navigate(`/sdb/device-models/new?vendor=${vendor?.id}`)
          }}>New Device Model</Button>
      )
    }
    buttons.push(<ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>)
    return buttons;
  }, [fetchData, navigate, vendor?.id]);


  return (
    <FilteredTableV2
      withFilter={'includesString'}
      minRows={10}
      columns={getColumns()}
      data={deviceModels}
      noDataText="No Device Models"
      showPagination={deviceModels.length > 20}
      defaultPageSize={20}
      defaultSorted={[{
        id: 'name',
        desc: false
      }]}
      onRowClick={(deviceModel) => {
        navigate(`/sdb/device-models/${deviceModel.id}`)
      }}
      extraButtons={getButtons()}
    />
  )

}

export default DeviceModelsTable
