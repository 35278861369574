import { Badge, Button } from 'reactstrap';
import { ButtonIcon } from '../../ButtonIcon';
import FilteredTableV2 from '../../FilteredTableV2';
import React, { useState } from 'react';
import moment from 'moment';
import BillTemplateGroup from '../../../views/Billing/BillTemplateGroup';
import HeadlessModal from '../../Modals/HeadlessModal';

const BillTemplateGroupProductsTable = ({
  products,
  onRemove
}) => {

  const columns = [
    {
      header: 'Product Number',
      accessorKey: 'productNumber',
      minSize: 250,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 250,
    },
    {
      header: '',
      accessorKey: 'id',
      maxSize: 150,
      cell: props => {
        return (
          <div className={'d-flex justify-content-end'}>
            <ButtonIcon
            outline
            icon={'fa fa-remove'}
            onClick={() => onRemove(props.row.original.id)}
            colour={'danger'}
            tooltip={'remove'}
            />
          </div>)
      }
    }
  ]

  return <>
    <FilteredTableV2
      withFilter={'includesString'}
      minRows={5}
      columns={columns}
      data={products}
      noDataText="No products"
      showPagination={products.length > 5}
      defaultPageSize={5}
      defaultSorted={[{
        id: 'name',
        desc: false
      }]}
    />
  </>
}

export default BillTemplateGroupProductsTable