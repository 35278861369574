import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import FilteredTable from '../../../../components/FilteredTable';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { getBlocks } from '../../../../actions/ServiceDB/Ipam/blocks';
import { canAccessServiceDb } from '../../../../utils/Auth/AuthService';

const Blocks = ({ blocks }) => {

  // router
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    dispatch(getBlocks(['owner', 'domain']))
      .then(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetch();
  }, []);

  const columns = [
    {
      filterable: true,
      sortable: true,
      Header: 'ID',
      accessor: 'id',
      width: 100
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Domain',
      accessor: 'domain.name'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Name',
      accessor: 'name'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Owner',
      accessor: 'owner.name'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Network Address',
      accessor: 'networkAddress',
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Mask',
      accessor: 'mask',
      Cell: props => `/${props.row.mask}`
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 50,
      Cell: props => (
        <Link to={'/sdb/ipam/blocks/' + props.value}><i className="icon-arrow-right"/></Link>
      )
    }
  ];
  return (
    <Row>
      <Col className={'d-flex'}>
        <Card className="w-100">
          <CardBody>
            <FilteredTable
              columns={columns}
              data={blocks}
              minRows={1}
              filterable={false}
              loading={loading}
              hideFilter={true}
              onRowClick={(row) => navigate(`/sdb/ipam/blocks/${row.id}`)}
              extraButtons={[
                <ButtonIcon
                  icon="fa fa-refresh"
                  tooltip="Refresh"
                  onClick={fetch}/>,
                canAccessServiceDb() ? (
                  <ButtonIcon
                    icon="fa fa-plus"
                    tooltip="Add New"
                    onClick={() => navigate('/sdb/ipam/blocks/new')}/>
                ) : ''
              ]}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

function mapStateToProps({ ipamBlocks, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    blocks: ipamBlocks.blocks
  };
}

export default connect(mapStateToProps)(Blocks);
