import startsWith from 'lodash.startswith'

export const calculateCost = (components, typeOfCharge) => {
  return components.reduce((result, component) => {
    if (component.typeOfCharge === typeOfCharge) {
      result = result + component.currentCost;
    }
    return result;
  }, 0);
};

export const getProductTemplate = (productCode) => {
  const split = productCode.split('-');
  return `${split[0]}-${split[1]}`;
};

export const getProductCodeForPricingToolTech = ( tech) => {
  if(!tech){
    return 'HSO10-011'
  }
  if(startsWith(tech, 'gea')){
    return 'HSO10-018'
  }
  return 'HSO10-010'
}

export const getProductForPricingToolKit = ( solution) => {
  switch(solution){
    case 'p2p' :
    case 'internet' :
      return 'HSO10-921'
    case 'mpls':
      return 'HSO10-922'
    case 'sd-wan':
      return 'HSO30-262'
    default:
      return 'HSO10-922'
  }
}
