import { defaultErrorFeedback } from '../feedback';
import { LicenseService } from '../../utils/ServiceDB/License';

export const LICENSES_SET_LICENSES = 'LICENSES_SET_LICENSES';

function setLicenses(licenses) {
  return {
    type: LICENSES_SET_LICENSES,
    data: licenses
  };
}

export function getLicenses(include = [], search = [], orderBy = 'id', sortedBy = 'asc') {
  return (dispatch) => {
    return LicenseService.list(include, search, orderBy, sortedBy).then((result) => {
      if (result.status === 200) {
        dispatch(setLicenses(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}
