import { deepFreeze } from '../Helpers/DeepFreeze';

export const AccountEnums = deepFreeze({
  type: {
    CUSTOMER: 3,
    RESALE_PARTNER: 9,
    RESALE_CUSTOMER: 200009,
    BUSINESS_PARTNER: 5,
    BUSINESS_CUSTOMER: 200012,
    CONSULTANT_PARTNER: 2,
    CONSULTANT_CUSTOMER: 2,
    SUPPLIER: 10,
    EX_CUSTOMER: 200000,
    CUSTOMER_SUB_ACCOUNT: 200018,
  },
  billingFrequency: {
    MONTHLY: 2,
    QUARTERLY: 3
  }
});


