import React, { useState } from 'react';
import PatchLeadForm from './form';
import GenericForm from '../../../../../components/GenericForm';

const PatchLead = ({
  data,
  update
}) => {


  const options = {
    type: [
      { value: 'copper', label: 'Copper' },
      { value: 'smf', label: 'Singlemode Fibre' },
      { value: 'mmf', label: 'Multimode Fibre' }
    ],
    color: [
      { value: 'red', label: 'Red', color: '#ff0000' },
      { value: 'pink', label: 'Pink', color: '#ff00d5' },
      { value: 'yellow', label: 'Yellow', color: '#deba13' },
      { value: 'blue', label: 'Blue', color: '#084cce' },
      { value: 'green', label: 'Green', color: '#3c9a00' },
      { value: 'grey', label: 'Grey', color: '#7d7d7d' }
    ]
  };

  return (
      <GenericForm
        data={data}
        form={PatchLeadForm}
        setField={(field, value) => update(field, value)}
        options={options}
      />
  );
};

export default PatchLead;