import { CarrierCircuitService } from '../utils/ServiceDB/CarrierCircuit';
import { defaultErrorFeedback } from './feedback';

export const CARRIER_CIRCUITS_SET_DATA = 'CARRIER_CIRCUITS_SET_DATA';

const setCarrierCircuits = (carrierCircuits) => ({
  type: CARRIER_CIRCUITS_SET_DATA,
  carrierCircuits
});

export const getCarrierCircuits = (page, size, searchString, sorting) => {
  return (dispatch) => {
    return CarrierCircuitService.getCarrierCircuits(page, size, searchString, sorting)
      .then(result => {
        if (result.status === 200) {
          dispatch(setCarrierCircuits(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      });
  };
};