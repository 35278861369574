import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Alert,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  FormGroup, Spinner
} from 'reactstrap';
import { SelectMod } from '../../components/Selects/SelectMod';
import {
  generateLeadTimesReport, setLeadTimesReportLineItems,
  setLeadTimesReportQuarter,
  setLeadTimesReportYear, setLeadTimesReportYearOptions
} from '../../actions/leadTimesReport';
import ReactTable from 'react-table-v6';
import Loadable from 'react-loading-overlay';
import { CSVLink } from 'react-csv';

const LeadTimesReport = (props) => {
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const { dispatch, leadTimesReport } = props;
  const { year, yearOptions, quarter, lineItems } = leadTimesReport;

  useEffect(() => {
    const currentYear = moment().year();
    dispatch(setLeadTimesReportYearOptions(getYearOptions()));
    dispatch(setLeadTimesReportYear(getYearOptions(
      currentYear
    )));
  }, []);

  const getYearOptions = (year = null) => {
    let options = [{ value: 2019, label: '2019' }];
    const startYear = moment('01-01-2019', 'DD-MM-YYYY');
    const now = moment();
    const diffYears = now.diff(startYear, 'years');
    for (let step = 0; step < diffYears; step++) {
      options.push({ value: startYear.add(1, 'year').year(), label: startYear.year().toString() });
    }
    if (year) {
      return options.filter(option => year === option.value)[0];
    }
    return options;
  };

  const quarters = [
    { value: 1, label: 'Quarter 1' },
    { value: 2, label: 'Quarter 2' },
    { value: 3, label: 'Quarter 3' },
    { value: 4, label: 'Quarter 4' }
  ];

  const generate = () => {
    if (year) {
      dispatch(setLeadTimesReportLineItems([]));
      setLoading(true);
      setNoData(false);
      dispatch(generateLeadTimesReport(year.value, quarter ? quarter.value : null)).then(
        (result) => {
          if (result.length === 0) {
            setNoData(true);
          }
          setLoading(false);
        }
      );
    }
  };

  const exportAsCSV = () => {
    return lineItems.map((item) => {
      return {
        order: item.orderNumber,
        lineItem: item.lineItem,
        PM: item.PM,
        redFileReceived: item.redFileReceived ? moment(item.redFileReceived).format('DD/MM/YYYY') : '',
        readyForService: item.readyForService ? moment(item.readyForService).format('DD/MM/YYYY') : '',
        goLiveDate: item.goLiveDate ? moment(item.goLiveDate).format('DD/MM/YYYY') : '',
        days: item.daysBetween
      };
    });
  };
  const columns = [
    {
      Header: 'Order',
      accessor: 'orderNumber',
      Cell: props => (
                <a href={props.original.orderLink} target="_blank" className={'text-primary text-wrap'}>{props.value}</a>
      )
    },
    {
      Header: 'Line Item',
      accessor: 'lineItem',
      Cell: props => (
                <a href={props.original.lineItemLink} target="_blank" className={'text-primary text-wrap'}>{props.value}</a>
      )
    },
    {
      Header: 'PM',
      accessor: 'PM'
    },
    {
      Header: 'Red File Received',
      accessor: 'redFileReceived',
      Cell: props => {
        return props.value ? moment(props.value).format('DD/MM/YYYY') : '';
      }
    },
    {
      Header: 'Ready For Service',
      accessor: 'readyForService',
      Cell: props => {
        return props.value ? moment(props.value).format('DD/MM/YYYY') : '';
      }
    },
    {
      Header: 'Go Live Date',
      accessor: 'goLiveDate',
      Cell: props => {
        return props.value ? moment(props.value).format('DD/MM/YYYY') : '';
      }
    },
    {
      Header: 'Lead Time (Days)',
      accessor: 'daysBetween'
    }
  ];
  return (
        <div className="animated fadeIn">
            <Row>
                <Col md={4}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <SelectMod
                                            options={yearOptions}
                                            isSearchable
                                            value={year}
                                            onChange={(option) => dispatch(setLeadTimesReportYear(option))}
                                            placeholder={'Select year...'}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <SelectMod
                                            options={quarters}
                                            isSearchable
                                            isClearable
                                            value={quarter}
                                            onChange={(option) => dispatch(setLeadTimesReportQuarter(option))}
                                            placeholder={'Select quarter or leave blank...'}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        style={{ minWidth: '100px' }}
                                        onClick={generate}
                                        color={'primary'}>
                                        {loading ? <Spinner size="sm" color={'light'}/> : 'Generate!'}
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {noData
                && <Row>
                    <Col>
                        <Alert color={'warning'} className={'text-center'}>Looks like nothing got done in this period!</Alert>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Loadable active={loading} spinner color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                                <Row>
                                    <Col className={'d-flex justify-content-end'}>
                                        <CSVLink className="pt-1 px-1 h3" data={exportAsCSV()} filename='lead-times.csv' style={{ textDecoration: 'none' }}>
                                            <i className="icon-cloud-download"/>
                                        </CSVLink>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ReactTable
                                            getTheadProps={() => {
                                              return {
                                                style: { borderBottom: 'none' }
                                              };
                                            }}
                                            getTheadThProps={
                                                () => {
                                                  return { style: { whiteSpace: 'unset' } };
                                                }
                                            }
                                            getTdProps={(state, rowInfo, column, instance) => {
                                              return {
                                                style: {
                                                  border: 'none',
                                                  fontWeight: column.Header === 'Name' ? 'bold' : 'regular',
                                                  whiteSpace: 'unset',
                                                  fontSize: '10pt',
                                                  cursor: rowInfo && column && column.Header !== undefined ? 'pointer' : 'auto'
                                                }
                                              };
                                            }}
                                            className="animated fadeIn -highlight -striped"
                                            data={lineItems}
                                            noDataText={'It looks like nothing got done in this period!'}
                                            columns={columns}
                                            minRows={20}
                                            defaultPageSize={50}
                                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                                            defaultSorted={[{ id: 'daysBetween', desc: true }]}
                                            filterable={true}
                                            showPagination={true}
                                        />
                                    </Col>
                                </Row>

                            </Loadable>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
  );
};

function mapStateToProps({ leadTimesReport }) {
  return {
    leadTimesReport
  };
}

export default connect(mapStateToProps)(LeadTimesReport);
