import { defaultErrorFeedback, errorFeedback, successFeedback } from './feedback'
import { api_searchAccounts } from '../utils/Account/AccountServiceDeprecated'
import {
  api_closeOpportunity,
  api_createUserOpportunity,
  api_getUserOpportunity, api_restoreUserOpportunity,
  api_updateUserOpportunity, OpportunityService
} from '../utils/Opportunity';
import AccountService from '../utils/Account';
import AuditService from "../utils/Audit";

export const OPPORTUNITY_SET_DATA = 'OPPORTUNITY_SET_DATA';
export const OPPORTUNITY_UPDATE_FIELD = 'OPPORTUNITY_UPDATE_FIELD';
export const OPPORTUNITY_RESET = 'OPPORTUNITY_RESET';
export const OPPORTUNITY_TOGGLE_LOADING = 'OPPORTUNITY_TOGGLE_LOADING';
export const OPPORTUNITY_ADD_TASK = 'OPPORTUNITY_ADD_TASK';
export const OPPORTUNITY_UPDATE_TASK = 'OPPORTUNITY_UPDATE_TASK';
export const OPPORTUNITY_SET_QUOTE_REF_IN_PROGRESS = 'OPPORTUNITY_SET_QUOTE_REF_IN_PROGRESS';
export const OPPORTUNITY_SET_AUDIT_HISTORY = 'OPPORTUNITY_SET_AUDIT_HISTORY';

const withAndIncludeCommon = ['owner', 'quotes', 'tasks', 'tasks.assignedTo','lead', 'contact', 'priceList','priceList.currency'];
const includeArr = ['customer', ...withAndIncludeCommon];
const withArr = ['company', ...withAndIncludeCommon];

export function setOpportunityData(data) {
  return {
    type: OPPORTUNITY_SET_DATA,
    data
  };
}

export function toggleOpportunityLoading() {
  return {
    type: OPPORTUNITY_TOGGLE_LOADING
  }
}

export function updateOpportunityField(field){
  return {
    type: OPPORTUNITY_UPDATE_FIELD,
    field
  }
}

export function resetOpportunity() {
  return {
    type: OPPORTUNITY_RESET
  };
}

export function addOpportunityTask(task) {
  return {
    type: OPPORTUNITY_ADD_TASK,
    task
  };
}

export function updateOpportunityTask(task) {
  return {
    type: OPPORTUNITY_UPDATE_TASK,
    task
  };
}

export function updateQuoteRefInProgress(ref) {
  return {
    type: OPPORTUNITY_SET_QUOTE_REF_IN_PROGRESS,
    ref
  };
}

export function getOpportunity(userId, id) {
  return (dispatch) => {
    return api_getUserOpportunity(userId, id, includeArr, withArr)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setOpportunityData(result.data));
          return result.data
        } else {
          dispatch(defaultErrorFeedback());
          return false
        }
      });
  }
}

export function closeOpportunity(userId, opId, statusReason) {
  return(dispatch) => {
    return api_closeOpportunity(userId, opId, statusReason).then((result) => {
      if (result.status === 204) {
        return true
      }
      else {
        dispatch(errorFeedback("Could not close opportunity"));
      }
    });
  }
}

export function searchAccounts(searchString) {
  return (dispatch) => {
    return AccountService.search(searchString, true).then(
      (result) => {
        if (result.status === 200) {
          return result.data
        } else {
          dispatch(defaultErrorFeedback());
        }
      }
    );
  };
}

export function createUserOpportunity(userId, args) {
  return (dispatch) => {
    return api_createUserOpportunity(userId, args, includeArr, withArr)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch(setOpportunityData(result.data));
            dispatch(successFeedback('Opportunity created'));
            return result.data;
          }
          else if (result.status === 422) {
            return {errors: result.data};
          }else {
            dispatch(defaultErrorFeedback());
            return false;
          }
        }
    );
  };
}

export function updateOpportunity(userId, id, args){
  return (dispatch) => {
    return api_updateUserOpportunity(userId, id, args, includeArr, withArr)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setOpportunityData(result.data));
          dispatch(successFeedback('Opportunity updated'));
          return result.data;
        }
        else if (result.status === 422) {
          return {errors: result.data};
        }else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  }
}

export function restoreOpportunity(userId, id){
  return (dispatch) => {
    return api_restoreUserOpportunity(userId, id, includeArr, withArr)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setOpportunityData(result.data));
          dispatch(successFeedback('Opportunity restored'));
          return result.data;
        }
        else if (result.status === 422) {
          return {errors: result.data};
        }else {
          dispatch(defaultErrorFeedback());
          return false;
        }
      });
  }
}

export function getOpportunityAuditHistory(id){
    return (dispatch) => {
        return AuditService.get('opportunities', id).then((result)=>{
            if (result.status === 200) {
                dispatch({ type: OPPORTUNITY_SET_AUDIT_HISTORY, data: result.data });
            }else{
                dispatch(defaultErrorFeedback());
            }

        })
    }
}

export function getOpportunityRelatedServices(id){
  return (dispatch) => {
    return OpportunityService.relatedServices(id).then((result)=>{
      if (result.status === 200) {
        return result.data
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}