import { deepFreeze } from '../Helpers/DeepFreeze';

export const TaskEnums = deepFreeze({
  status: {
    OPEN: 0,
    COMPLETED: 1,
    CANCELED: 2
  },
  type: {
    SALES_FEASIBILITY: 1
  }
});
