import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TimeSeries
} from 'pondjs';
import _ from 'underscore';
import { AreaChart, ChartContainer, ChartRow, Charts, Resizable, styler, YAxis, Legend } from 'react-timeseries-charts';
import { connect } from 'react-redux';
import { resetRealtimeTraffic, stopRealtimeTraffic, startRealtimeTraffic } from '../../actions/realtimeTraffic';
import { Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import Select from 'react-select';
import { api_getInterfaces, api_getZabbixProxies } from '../../utils/RealtimeTraffic/RealtimeTrafficService';
import { SpinnerInfo } from '../../components/Spinner/Spinner';
import { format } from 'd3-format';

class TrafficGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: {}
    };
    this.handleTrackerChanged = this.handleTrackerChanged.bind(this);
  }

  handleTrackerChanged(t) {
    this.setState({ tracker: t });
  }

  bitsToSize(bits) {
    const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
    if (bits === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(Math.abs(bits)) / Math.log(1000)), 10);
    if (i === 0) return `${bits} ${sizes[i]})`;
    return `${(bits / (1000 ** i)).toFixed(1)} ${sizes[i]}`;
  }

  render() {
    const upDownStyle = styler([{ key: 'i_rate', color: '#6BA4B8' }, { key: 'o_rate', color: '#FF9E1B' }]);
    const axistype = 'linear';

    let rateSeries = this.props.data.rate({ fieldSpec: ['i', 'o'] });
    const max = _.max([rateSeries.max('i_rate'), rateSeries.max('o_rate')]);

    const f = format('.2f');
    let inValue; let
      outValue;
    if (this.state.tracker) {
      const index = rateSeries.bisect(this.state.tracker);
      const trackerEvent = rateSeries.at(index);
      inValue = `${f(trackerEvent.get('i_rate'))}`;
      outValue = `${f(trackerEvent.get('o_rate'))}`;
    }
    const tracker = this.state.tracker ? `${this.state.tracker}` : '';

    return (
            <div>
            <Resizable>
                <ChartContainer
                    enablePanZoom={false}
                    timeRange={this.props.data.range()}
                    maxTime={this.props.data.range().end()}
                    minTime={this.props.data.range().begin()}
                    minDuration={1000 * 60 * 60}
                    onTrackerChanged={this.handleTrackerChanged}
                    trackerPosition={this.state.tracker}
                >
                    <ChartRow height="250" debug={false}>
                        <Charts>
                            <AreaChart
                                axis="traffic"
                                series={rateSeries}
                                columns={{
                                  up: ['i_rate'],
                                  down: ['o_rate']
                                }}
                                style={upDownStyle}
                                interpolation="curveBasis"
                            />
                        </Charts>
                        <YAxis
                            id="traffic"
                            label="Traffic (bps)"
                            labelOffset={0}
                            absolute={true}
                            min={-max}
                            max={max}
                            width="60"
                            type={axistype}
                        />
                    </ChartRow>
                </ChartContainer>
            </Resizable>
                <Row>
                    <Col md={6}>
                        <p style={{ color: '#808080', fontSize: '11px' }}>{tracker}</p>
                    </Col>
                    <Col md={6}>
                        <Legend
                            type="swatch"
                            align="right"
                            marginBottom={0}
                            style={upDownStyle}
                            highlight={this.state.highlight}
                            onHighlightChange={highlight => this.setState({ highlight })}
                            selection={this.state.selection}
                            onSelectionChange={selection => this.setState({ selection })}
                            categories={[
                              { key: 'i_rate', label: 'Inbound', value: this.bitsToSize(inValue) },
                              { key: 'o_rate', label: 'Outbound', value: this.bitsToSize(outValue) }
                            ]} />
                    </Col>
                </Row>
            </div>
    );
  }
}

class RealtimeTraffic extends Component {
    static contextTypes = {
      router: PropTypes.shape({
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
          replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
      }).isRequired
    };

    constructor(props) {
      super(props);
      this.state = {
        proxiesLoading: true,
        proxies: [],
        selectedProxy: null,
        interfacesLoading: false,
        interfaces: [],
        interfaceError: false,
        selectedInterface: false,
        running: false
      };

      this.hostName = React.createRef();
      this.community = React.createRef();

      this.setProxy = this.setProxy.bind(this);
      this.setInterface = this.setInterface.bind(this);
      this.getInterfaces = this.getInterfaces.bind(this);
      this.startGraph = this.startGraph.bind(this);
      this.stopGraph = this.stopGraph.bind(this);
    }

    componentDidMount() {
      this.props.dispatch(resetRealtimeTraffic());
      api_getZabbixProxies().then((result) => {
        if (result.status === 200) {
          this.setState({ proxiesLoading: false, proxies: result.data });
        }
      }).catch((error) => {
        alert(error);
      });
    }

    setProxy(proxy) {
      this.setState({ selectedProxy: proxy.proxy });
    }

    setInterface(int) {
      this.setState({ selectedInterface: int.i });
    }

    startGraph() {
      this.props.dispatch(startRealtimeTraffic({
        host: this.hostName.value,
        community: this.community.value,
        interface: parseInt(this.state.selectedInterface),
        proxy: this.state.selectedProxy
      }));
      this.setState({ running: true });
    }

    stopGraph() {
      this.props.dispatch(stopRealtimeTraffic());
      this.setState({ running: false });
    }

    getInterfaces() {
      this.setState({ interfacesLoading: true, interfaceError: false });
      if (this.state.selectedProxy !== null && this.hostName.value.length > 5 && this.community.value.length > 4) {
        api_getInterfaces(this.state.selectedProxy, this.hostName.value, this.community.value).then((result) => {
          if (result.data.status === 1) {
            this.setState({ interfacesLoading: false, interfaces: result.data.data });
          } else {
            this.setState({ interfacesLoading: false, interfaceError: result.data.error });
          }
        }).catch((error) => {
          this.setState({ interfacesLoading: false, interfaceError: error });
        });
      }
    }

    componentWillUnmount() {
      this.props.dispatch(stopRealtimeTraffic());
    }

    render() {
      const eventSeries = new TimeSeries({
        name: 'raw',
        events: this.props.data
      });
      return (
                <Row>
                    <Col md={4}>
                        <Card className="card-accent-primary">
                            <CardHeader>Realtime Traffic</CardHeader>
                            <CardBody>
                                <Form>
                                    <FormGroup>
                                        <Label>Proxy</Label>
                                        <Select
                                            isLoading={this.state.proxiesLoading}
                                            options={this.state.proxies}
                                            getOptionLabel={(opt) => opt.proxy}
                                            getOptionValue={(opt) => opt.proxy}
                                            onChange={this.setProxy}
                                            isDisabled={this.state.running}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Host</Label>
                                        <Input type="text" placeholder="e.g. pe-r-00.thn.uk.hso-group.net" innerRef={input => (this.hostName = input)} onBlur={this.getInterfaces} disabled={this.state.running}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Community</Label>
                                        <Input type="text" placeholder="e.g. public" innerRef={input => (this.community = input)} onBlur={this.getInterfaces} disabled={this.state.running}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Interface</Label>
                                        {this.state.interfaceError
                                        && <Alert color="danger">{this.state.interfaceError}</Alert>
                                        }
                                        <Select
                                            isLoading={this.state.interfacesLoading}
                                            options={this.state.interfaces}
                                            getOptionLabel={(opt) => opt.n}
                                            getOptionValue={(opt) => opt.i}
                                            isDisabled={!((this.state.interfaces.length >= 1 && this.state.running === false))}
                                            onChange={this.setInterface}
                                        />
                                    </FormGroup>
                                    {this.state.running === true
                                    && <Button color="danger" onClick={this.stopGraph}>Stop</Button>
                                    }
                                    <Button color="primary" style={{ float: 'right' }} disabled={!!((this.state.selectedInterface === false || this.state.running === true))} onClick={this.startGraph}>Show me a graph</Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    {this.state.running
                    && <Col md={8}>
                        <Card className="card-accent-primary">
                            <CardHeader>Behold Ze Data!</CardHeader>
                            <CardBody>
                                {eventSeries.count() >= 3
                                  ? <TrafficGraph data={eventSeries}/>
                                  : <div>
                                        <SpinnerInfo/>
                                        <div className="row justify-content-center">Populating some datapoints (got {eventSeries.count()}) before drawing a pretty graph for you. Please sit tight...</div>
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    }
                </Row>
      );
    }
}

function mapStateToProps({ realtimeTraffic }) {
  return {
    data: realtimeTraffic.data.toArray()
  };
}

export default connect(mapStateToProps)(RealtimeTraffic);
