import {submitDeleteSnap, submitGetSnaps, submitTakeSnap} from '../utils/CoreSnap/CoreSnapService';

export const GET_SNAPS = 'GET_SNAPS';
export const SNAP_FETCHING = 'SNAP_FETCHING';
export const SNAP_DELETING = 'SNAP_DELETING';
export const SNAPSHOT = 'SNAPSHOT';
export const SNAP_CREATING = 'SNAP_CREATING';
export const SNAP_CHECKING = 'SNAP_CHECKING';
export const SNAP_ERROR = 'SNAP_ERROR';
export const SNAP_FETCH_ERROR = 'SNAP_FETCH_ERROR';
export const DELETE_HOST = 'DELETE_HOST';


export function fetchSnaps() {
    return (dispatch) => {
        dispatch({
            type: SNAP_FETCHING, result: true
        });
        submitGetSnaps().then((result) => {
            if (result.status === 200) {
                dispatch({
                    type: GET_SNAPS, data: result.data
                });
                dispatch({
                    type: SNAP_FETCHING, result: false
                });
            }
        }).catch(e =>  {
            dispatch({type: SNAP_FETCHING, result: false})
            dispatch({type: SNAP_FETCH_ERROR, result: e})
        });
    };
}

export function deleteSnaps(router) {
    return (dispatch) => {
        dispatch({
            type: SNAP_DELETING, result: true
        });
        submitDeleteSnap(router).then((result) => {
            if (result.status === 200) {
                dispatch({
                    type: SNAP_DELETING, result: false
                });
                return dispatch({
                    type: DELETE_HOST, data: router.replace('.hso-group.net', '')
                });
            }
        });
    };
}

export function takeSnap(snapName, router) {
    return (dispatch) => {
        dispatch({
            type: SNAP_CREATING, result: router
        });
        submitTakeSnap(snapName, router + ".hso-group.net");
    };
}

