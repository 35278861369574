import {
  ACCOUNT_ADD_CONTACT, ACCOUNT_ADD_CREDIT_REQUEST,
  ACCOUNT_ADD_DOCUMENT, ACCOUNT_ADD_ORDER, ACCOUNT_ADD_PARTNERSHIP, ACCOUNT_ADD_SITE,
  ACCOUNT_REMOVE_DOCUMENT, ACCOUNT_REMOVE_PARTNERSHIP,
  ACCOUNT_RESET, ACCOUNT_SET_AUDIT_HISTORY,
  ACCOUNT_SET_BILLS,
  ACCOUNT_SET_CASES,
  ACCOUNT_SET_CIRCUITS,
  ACCOUNT_SET_CONFIG_ITEMS,
  ACCOUNT_SET_CONTACTS, ACCOUNT_SET_CREDIT_REQUESTS,
  ACCOUNT_SET_DOCUMENTS,
  ACCOUNT_SET_INFO_DATA,
  ACCOUNT_SET_INFO_FIELD,
  ACCOUNT_SET_LOGS,
  ACCOUNT_SET_OPPORTUNITIES,
  ACCOUNT_SET_ORDERS, ACCOUNT_SET_PARTNERSHIPS,
  ACCOUNT_SET_SERVICES,
  ACCOUNT_SET_SIGNATURES,
  ACCOUNT_SET_SITES,
  ACCOUNT_UPDATE_CONTACT, ACCOUNT_UPDATE_CREDIT_REQUEST,
  ACCOUNT_UPDATE_DOCUMENT, ACCOUNT_UPDATE_SITE
} from '../actions/account';

const initialState = {
  form: {},
  original: {},
  sites: [],
  partnerships: [],
  contacts: [],
  bills: [],
  services: [],
  cases: [],
  orders: [],
  configItems: [],
  circuits: [],
  logs: [],
  documents: [],
  opportunities: [],
  signatures: {
    items: [],
    table: {
      lastPage: 1
    }
  },
  creditRequests: [],
  audits: []
}
export default function account(state = initialState, action) {

  switch (action.type) {
    case ACCOUNT_RESET:
      return {
        ...initialState
      }
    case ACCOUNT_SET_INFO_DATA:
      return {
        ...state,
        form: action.data,
        original: action.data,
      }
    case ACCOUNT_SET_INFO_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.field
        },
      }
    case ACCOUNT_SET_CONTACTS:
      return {
        ...state,
        contacts: action.contacts
      }
    case ACCOUNT_SET_SITES:
      return {
        ...state,
        sites: action.sites
      }
    case ACCOUNT_SET_PARTNERSHIPS:
      return {
        ...state,
        partnerships: action.partnerships
      }
    case ACCOUNT_SET_ORDERS:
      return {
        ...state,
        orders: action.orders
      }
    case ACCOUNT_SET_SERVICES:
      return {
        ...state,
        services: action.services
      }
    case ACCOUNT_SET_BILLS:
      return {
        ...state,
        bills: action.bills
      }
    case ACCOUNT_SET_CREDIT_REQUESTS:
      return {
        ...state,
        creditRequests: action.creditRequests
      }
    case ACCOUNT_ADD_CREDIT_REQUEST:
      return {
        ...state,
        creditRequests: [...state.creditRequests, action.creditRequest]
      }
    case ACCOUNT_UPDATE_CREDIT_REQUEST:
      return {
        ...state,
        creditRequests: state.creditRequests.map(creditRequest => {
          if(creditRequest.id === action.creditRequest.id){
            return action.creditRequest
          }
          return creditRequest
        })
      }
    case ACCOUNT_SET_CASES:
      return {
        ...state,
        cases: action.cases
      }
    case ACCOUNT_SET_CONFIG_ITEMS:
      return {
        ...state,
        configItems: action.configItems
      }
    case ACCOUNT_SET_CIRCUITS:
      return {
        ...state,
        circuits: action.circuits
      }
    case ACCOUNT_SET_DOCUMENTS:
      return {
        ...state,
        documents: action.documents
      }
    case ACCOUNT_SET_SIGNATURES:
      const { currentPage, total, perPage, path, lastPage, items } = action.signatures;
      return {
        ...state,
        signatures: {
          ...state.signatures,
          items,
          table: {currentPage, total, perPage, path, lastPage}
        },
      }
    case ACCOUNT_SET_LOGS:
      return {
        ...state,
        logs: action.logs
      }
    case ACCOUNT_SET_OPPORTUNITIES:
      return {
        ...state,
        opportunities: action.opportunities
      }
    case ACCOUNT_ADD_CONTACT:
      return {
        ...state,
        contacts: state.contacts.concat(action.contact)
      };

    case ACCOUNT_UPDATE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.map((contact) => {
          if (contact.id === action.contact.id) {
            return { ...contact, ...action.contact };
          }
          return contact;
        })
      };
    case ACCOUNT_ADD_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.document]
      }
    case ACCOUNT_UPDATE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.map(document => {
          if(document.id === action.document.id){
            return action.document
          }
          return document
        })
      }
    case ACCOUNT_REMOVE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter(document => document.id !== action.documentId)
      }
    case ACCOUNT_ADD_SITE:
      return {
        ...state,
        sites: [...state.sites, action.site]
      }
    case ACCOUNT_UPDATE_SITE:
      return {
        ...state,
        sites: state.sites.map(site => {
          if(site.accountid === action.site.accountid){
            return action.site
          }
          return site
        })
      }
    case ACCOUNT_ADD_PARTNERSHIP:
      return {
        ...state,
        partnerships: [...state.partnerships, action.partnership]
      }
    case ACCOUNT_REMOVE_PARTNERSHIP:
      return {
        ...state,
        partnerships: state.partnerships.filter(partnership => partnership.customerId !== action.customerId)
      }
    case ACCOUNT_ADD_ORDER:
      return {
        ...state,
        orders: [action.order, ...state.orders]
      }
    case ACCOUNT_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;
  }
}