import {
  DIRECT_DEBITS_ADD_TO_MANDATES, DIRECT_DEBITS_LOADING_AUDDIS,
  DIRECT_DEBITS_LOADING_TABLE, DIRECT_DEBITS_REMOVE_MANDATE, DIRECT_DEBITS_RESET_AUDDIS,
  DIRECT_DEBITS_RESET_NEW_MANDATE, DIRECT_DEBITS_SET_AUDDIS_DATE_FROM, DIRECT_DEBITS_SET_AUDDIS_DATE_TO, DIRECT_DEBITS_SET_MANDATES,
  DIRECT_DEBITS_SET_NEW_MANDATE,
  DIRECT_DEBITS_SET_SEARCH_OPTIONS,
  DIRECT_DEBITS_SET_SELECTED_MANDATE,
  DIRECT_DEBITS_UPDATE_NEW_MANDATE
} from '../actions/directDebits';

const initialState = {
  searchOptions: [],
  selected: null,
  loadingTable: false,
  mandates: {},
  newMandate: {
    accountName: '',
    accountSortCode: '',
    accountNumber: '',
    mandateRef: '',
    loading: false,
    created: false,
    failed: false,
    errors: {}
  },
  auddis: {
    fromDate: null,
    toDate: null,
    loading: false
  }
};
export default function directDebits(state = initialState, action) {
  let mandates = Object.assign({}, state.mandates);
  switch (action.type) {
    case DIRECT_DEBITS_SET_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: action.options
      };
    case DIRECT_DEBITS_SET_SELECTED_MANDATE:
      return {
        ...state,
        selected: action.option
      };
    case DIRECT_DEBITS_UPDATE_NEW_MANDATE:
      let newMandate = Object.assign({}, state.newMandate);
      return {
        ...state,
        newMandate: { ...newMandate, [action.field]: action.value }
      };
    case DIRECT_DEBITS_SET_NEW_MANDATE:
      return {
        ...state,
        newMandate: action.mandate
      };
    case DIRECT_DEBITS_RESET_NEW_MANDATE:
      return {
        ...state,
        newMandate: initialState.newMandate
      };
    case DIRECT_DEBITS_LOADING_TABLE:
      return {
        ...state,
        loadingTable: action.loading
      };
    case DIRECT_DEBITS_SET_MANDATES:
      return {
        ...state,
        mandates: action.mandates
      };
    case DIRECT_DEBITS_REMOVE_MANDATE:
      delete (mandates[action.mandateId]);
      return {
        ...state,
        mandates: { ...mandates }
      };
    case DIRECT_DEBITS_ADD_TO_MANDATES:
      return {
        ...state,
        mandates: { ...mandates, [action.mandate.id]: action.mandate }
      };
    case DIRECT_DEBITS_SET_AUDDIS_DATE_FROM: {
      return {
        ...state,
        auddis: { ...state.auddis, fromDate: action.date }
      };
    }
    case DIRECT_DEBITS_SET_AUDDIS_DATE_TO: {
      return {
        ...state,
        auddis: { ...state.auddis, toDate: action.date }
      };
    }
    case DIRECT_DEBITS_LOADING_AUDDIS:
      return {
        ...state,
        auddis: { ...state.auddis, loading: action.loading }
      };
    case DIRECT_DEBITS_RESET_AUDDIS:
      return {
        ...state,
        auddis: { ...initialState.auddis }
      };
    default:
      return state;
  }
}
