import { NNIService } from '../utils/ServiceDB/NNI';
import { defaultErrorFeedback } from './feedback';

export const NNIS_SET_DATA = 'NNIS_SET_DATA';

function setNNIs(data) {
  return {
    type: NNIS_SET_DATA,
    data
  };
}

export function getNNIs(include = []) {
  return (dispatch) => {
    return NNIService.list(include).then((result) => {
      if (result.status === 200) {
        dispatch(setNNIs(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}