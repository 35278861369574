import { defaultErrorFeedback, errorFeedback } from './feedback';
import { api_createProductSLA, api_getProductSLAs, api_updateProductSLA } from '../utils/Products/ProductService';


export const PRODUCT_SLAS_SET = 'PRODUCT_SLAS_SET'
export const PRODUCT_SLAS_RESET_FILE = 'PRODUCT_SLAS_RESET_FILE'
export const PRODUCT_SLAS_SET_SEARCH_STRING = 'PRODUCT_SLAS_SET_SEARCH_STRING'
export const PRODUCT_SLAS_UPDATE_SLA = 'PRODUCT_SLAS_UPDATE_SLA'
export const PRODUCT_SLAS_ADD_SLA = 'PRODUCT_SLAS_ADD_SLA'
export const PRODUCT_SLAS_SET_SLA = 'PRODUCT_SLAS_SET_SLA'
export const PRODUCT_SLAS_SET_SLA_FIELD = 'PRODUCT_SLAS_SET_SLA_FIELD'
export const PRODUCT_SLAS_SET_SLA_FILE = 'PRODUCT_SLAS_SET_SLA_FILE'


const setSLAs = (slas) =>{
  return {
    type: PRODUCT_SLAS_SET,
    slas
  }
}

export const resetProductSLAFile = () =>{
  return {
    type: PRODUCT_SLAS_RESET_FILE
  }
}

export const setProductSLASearchString = (searchString) => {
  return {
    type: PRODUCT_SLAS_SET_SEARCH_STRING,
    searchString
  }
}

const updateSLAInList = (sla) =>{
  return {
    type: PRODUCT_SLAS_UPDATE_SLA,
    sla
  }
}

const addSLAToList = (sla) =>{
  return {
    type: PRODUCT_SLAS_ADD_SLA,
    sla
  }
}

export const setProductSLA = (sla) => {
  return {
    type: PRODUCT_SLAS_SET_SLA,
    sla
  }
}

export const updateProductSLAField = (field) => {
  return {
    type: PRODUCT_SLAS_SET_SLA_FIELD,
    field
  }
}

export const updateProductSLAFile = (file) => {
  return {
    type: PRODUCT_SLAS_SET_SLA_FILE,
    file
  }
}

export function getProductSLAs(){
  return (dispatch) => {
    return api_getProductSLAs().then(
      (result) => {
        if(result.status === 200){
          dispatch(setSLAs(result.data))
        } else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}

export function createProductSLA(data){
  return (dispatch) => {
    return api_createProductSLA(data).then(
      (result) => {
        if(result.status === 200){
          dispatch(setProductSLA(result.data))
          dispatch(addSLAToList(result.data))
        } else if(result.status === 422){
          return {errors: result.data}
        } else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}

export function updateProductSLA(id, data){
  return (dispatch) => {
    return api_updateProductSLA(id, data).then(
      (result) => {
        if(result.status === 200){
          dispatch(setProductSLA(result.data))
          dispatch(updateSLAInList(result.data))
        } else if(result.status === 422){
          return {errors: result.data}
        } else{
          dispatch(defaultErrorFeedback());
        }
      }
    )
  }
}


