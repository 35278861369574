import { BILLING_BILLINGBOOTH_SET_SUMMARY, BILLING_BILLINGBOOTH_TOGGLE_LOADING } from '../actions/billingBooth';

const initialState = {
  loading: false,
  summary: {
    tariffs: {},
    errors: [],
    createdAt: {}
  }
};

export default function billingBooth(state = initialState, action) {
  switch (action.type) {
    case BILLING_BILLINGBOOTH_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case BILLING_BILLINGBOOTH_SET_SUMMARY:
      return {
        ...state,
        summary: action.summary
      };

    default:
      return state;
  }
}
