import { api_getAccountCases, api_getAccountConfigItems } from '../utils/Account/AccountServiceDeprecated';
import { defaultErrorFeedback } from './feedback';
import {
  ACCOUNTS_REPORTS_RESET,
  ACCOUNTS_REPORTS_DELETE_SERVICE_INCIDENT,
  ACCOUNTS_REPORTS_SET_SERVICE_INCIDENTS_DATA,
  ACCOUNTS_REPORTS_SET_SERVICE_INCIDENT,
  ACCOUNTS_REPORTS_SET_SERVICE_UTILISATION_SERVICES,
  ACCOUNTS_REPORTS_DELETE_SERVICE_UTILISATION_SERVICE,
  ACCOUNTS_REPORTS_SET_SERVICE_UTILISATION_SERVICE,
  ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_DATA,
  ACCOUNTS_REPORTS_DELETE_SERVICE_AVAILABILITY_INCIDENT,
  ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_INCIDENT,
  ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_CONFIG_ITEMS,
  ACCOUNTS_REPORTS_DELETE_SERVICE_AVAILABILITY_CONFIG_ITEM
} from '../reducers/accountReports';
import fileDownload from 'js-file-download';
import { api_generateServiceIncidentsReport } from '../utils/Reports/serviceIncidents';
import {
  api_getAccountServiceUtilisationReport,
  api_getAccountServiceUtilisationReportData
} from '../utils/Reports/serviceUtilisation';
import { api_generateServiceAvailabilityReport } from '../utils/Reports/serviceAvailability';
import { CASE_STATE_RESOLVED } from '../utils/Cases/CasesService';

export const resetReports = () => ({
  type: ACCOUNTS_REPORTS_RESET
})

export const getServiceIncidents = (idOrNumber, from, to, subjects) => (dispatch) => api_getAccountCases(idOrNumber, null, from, to, null, 'customer')
  .then((result) => {
    if (result.status === 200) {
      dispatch({
        type: ACCOUNTS_REPORTS_SET_SERVICE_INCIDENTS_DATA,
        data: result.data,
        subjects
      });
      return result;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });

export const deleteIncident = (caseNumber) => {
  return {
    type: ACCOUNTS_REPORTS_DELETE_SERVICE_INCIDENT,
    caseNumber
  };
};
export const setIncident = (theCase) => {
  return {
    type: ACCOUNTS_REPORTS_SET_SERVICE_INCIDENT,
    theCase
  };
};

export const downloadServiceIncidentsReport = (idOrNumber, from, to, data) => (dispatch) => api_generateServiceIncidentsReport(idOrNumber, from, to, data)
  .then((result) => {
    if (result.status === 200) {
      const splitContentDisposition = result.headers['content-disposition'].split('=');
      fileDownload(result.data, splitContentDisposition[1]);
      return true;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });

export const getServiceUtilisationServices = (idOrNumber) => (dispatch) => api_getAccountServiceUtilisationReportData(idOrNumber)
  .then((result) => {
    if (result.status === 200) {
      dispatch({
        type: ACCOUNTS_REPORTS_SET_SERVICE_UTILISATION_SERVICES,
        data: result.data
      });
      return result;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });

export const deleteService = (id) => {
  return {
    type: ACCOUNTS_REPORTS_DELETE_SERVICE_UTILISATION_SERVICE,
    id
  };
};
export const setService = (service) => {
  return {
    type: ACCOUNTS_REPORTS_SET_SERVICE_UTILISATION_SERVICE,
    service
  };
};

export const downloadServiceUtilisationReport = (idOrNumber, from, to, data) => (dispatch) => api_getAccountServiceUtilisationReport(idOrNumber, from, to, data)
  .then((result) => {
    if (result.status === 204) {
      return true;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });

export const getServiceAvailabilityIncidents = (idOrNumber, from, to, subjects) => (dispatch) => api_getAccountCases(idOrNumber, CASE_STATE_RESOLVED, from, to, null, 'customer')
  .then((result) => {
    if (result.status === 200) {
      dispatch({
        type: ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_DATA,
        data: result.data,
        subjects
      });
      return result;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });


export const deleteAvailabilityIncident = (caseNumber) => {
  return {
    type: ACCOUNTS_REPORTS_DELETE_SERVICE_AVAILABILITY_INCIDENT,
    caseNumber
  };
};
export const setAvailabilityIncident = (theCase) => {
  return {
    type: ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_INCIDENT,
    theCase
  };
};

export const downloadServiceAvailabilityReport = (idOrNumber, from, to, incidents, configItems) => (dispatch) => api_generateServiceAvailabilityReport(idOrNumber, from, to, incidents, configItems)
  .then((result) => {
    if (result.status === 200) {
      const splitContentDisposition = result.headers['content-disposition'].split('=');
      fileDownload(result.data, splitContentDisposition[1].replaceAll('"', ''));
      return true;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });

export const getServiceAvailabilityConfigItems = (idOrNumber) => (dispatch) => api_getAccountConfigItems(idOrNumber, true)
  .then((result) => {
    if (result.status === 200) {
      dispatch({
        type: ACCOUNTS_REPORTS_SET_SERVICE_AVAILABILITY_CONFIG_ITEMS,
        data: result.data
      });
      return result;
    }
    dispatch(defaultErrorFeedback());
    return false;
  });


export const deleteAvailabilityConfigItem = (id) => {
  return {
    type: ACCOUNTS_REPORTS_DELETE_SERVICE_AVAILABILITY_CONFIG_ITEM,
    id
  };
};
