import { Badge, Button } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { ButtonIcon } from '../../ButtonIcon';
import bandwidthConverter from '../../../helpers/bandwidthConverter';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';

const getTypeColour = (type) => {
  switch (type){
    case 1: return 'primary'
    case 2: return 'secondary'
    case 3: return 'warning'
    default:
     return 'info'
  }
}
const CarrierCircuitsTable = ({
  noRowClick,
  excludeColumns,
  includeColumns,
  circuits,
  table,
  fetchData,
  circuitOptionSets,
  suppliers,
  minRows,
  connect,
  createNew,
  withoutNew,
  closeModal
}) => {

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({pageIndex : 0, pageSize: 20})
  const [sorting, setSorting] = useState([{  id: 'name', desc: false}])

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
      minSize: 30
    },
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 300
    },
    {
      header: 'Type',
      accessorKey: 'type',
      cell: (props) =>
        <Badge className={`p-2 faded-${getTypeColour(props.getValue()?.id)}`}>
          {props.getValue()?.name}
        </Badge>,
    },
    {
      header: 'Carrier',
      accessorKey: 'supplier',
      cell: props => suppliers[props.getValue()]?.name ?? ''
    },
    {
      header: 'Carrier Ref',
      accessorKey: 'carrierReference',
    },
    {
      enableSorting: false,
      header: 'Bearer',
      accessorKey: 'bearerSpeed',
      cell: (props) => {
        const value = circuitOptionSets?.bearerSpeed.options.find(type => type.value === props.getValue())?.label
        return value ? bandwidthConverter(value) : ''
      }
    },
    {
      enableSorting: false,
      header: 'CDR',
      accessorKey: 'cdr',
      cell: props => props.getValue() ? bandwidthConverter(props.getValue()) : ''
    },
    {
      header: 'A S Tag',
      accessorKey: 'aSTag',
    },
    {
      header: 'B S Tag',
      accessorKey: 'bSTag',
    },

    {
      enableSorting: false,
      header: 'Connection/OpticalSystem',
      accessorKey: 'component',
      cell: (props) => {
        if (!isEmpty(props.getValue()?.connection)) {
          return (
            <span onClick={e => e.stopPropagation()}>
            <Link to={`/sdb/connections/${props.row.original.component?.connection?.id}`}>
              <Badge className="p-2 faded-success">{props.getValue().connection.name}</Badge>
            </Link>
            </span>
          );
        } else if (!isEmpty(props.row.original.opticalSystem)) {
          return (
            <span onClick={e => e.stopPropagation()}>
            <Link to={`/sdb/optical-systems/${props.row.original.opticalSystem?.id}`}>
              <Badge className="p-2 faded-info">{props.row.original.opticalSystem.name}</Badge>
            </Link>
            </span>
          );
        } else if(connect){
          return <Button size={'sm'} color="primary" onClick={(event) => {
            event.stopPropagation()
            connect(props.row.original)
          }}>Connect</Button>;
        } else {
          return <Badge color="secondary">Disconnected</Badge>;
        }
      }
    }
  ];

  const onSorting = (sortingState, searchString) => {
    const fixedSorting = getSorting(sortingState)
    if(!isEqual(fixedSorting, sorting)){
      fetchData(pagination.pageIndex + 1, pagination.pageSize, searchString,fixedSorting )
      setSorting(fixedSorting)
    }
  }

  const getSorting = (sortingState) => {
    return  sortingState.map(sorting => {
      if(sorting.id === 'supplier'){
        return {
          ...sorting,
          id: 'companies:supplier_id|companies.name'
        }
      }
      if(sorting.id === 'opticalSystem'){
        return {
          ...sorting,
          id: 'sr_optical_systems:id|sr_optical_systems.name'
        }
      }
      if(sorting.id === 'type'){
        return {
          ...sorting,
          id: 'sr_carrier_circuit_types:type_id|sr_carrier_circuit_types.name'
        }
      }
      return sorting
    })
  }

  const getColumns = () => {
    if(excludeColumns){
      return  columns.filter(column => !excludeColumns.includes(column.accessorKey))
    }
    return includeColumns ? columns.filter(column => includeColumns.includes(column.accessorKey)) : columns
  }

  const getButtons = () => {
    const buttons = []

    if(!withoutNew){
      buttons.push( <Button
        size={'sm'}
        color={'secondary'}
        onClick={createNew}
      >New Circuit</Button>)
    }
    buttons.push(<ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>)

    if(closeModal){
      buttons.push( <ButtonIcon onClick={closeModal} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>)
    }

    return buttons
  }

  return (
    <>
      {table ?
        <FilteredTableV2
          withServerSideFilter={(searchString) => {
            fetchData(1, pagination.pageSize, searchString, getSorting(sorting))
          }}
          minRows={pagination.pageSize}
          data={circuits}
          columns={columns}
          onFetchData={(page, size, searchString) => {
            fetchData(page, size, searchString, getSorting(sorting))
          }}
          defaultSorted={sorting}
          pages={table.lastPage}
          pageSize={table.perPage}
          onPaginationChange={setPagination}
          pagination={pagination}
          showPagination
          defaultPageSize={20}
          onRowClick={(circuit) => !noRowClick ? navigate(`/sdb/carrier-circuits/${circuit.uuid}`) : () => {}}
          extraButtons={[
            <Button size={'sm'} color={'secondary'} onClick={() => {
              navigate(`/sdb/carrier-circuits/new`)
            }}>New Circuit</Button>,
            <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={() => fetchData(pagination.pageIndex + 1, pagination.pageSize, '', getSorting(sorting))}/>
          ]}
          withServerSideSorting
          onSorting={(sorting, searchString) => onSorting(sorting, searchString)}
        /> :
        <FilteredTableV2
          withFilter={'includesString'}
          minRows={minRows ?? 10}
          data={circuits}
          columns={getColumns()}
          showPagination
          defaultPageSize={20}
          onRowClick={(circuit) => !noRowClick ? navigate(`/sdb/carrier-circuits/${circuit.uuid}`) : () => {}}
          extraButtons={getButtons()}
        />
      }

    </>
  )
}

export default CarrierCircuitsTable;
