import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const LicenseService = {
  list: (include = [], search = [], orderBy = 'id', sortedBy = 'asc') => {
    let query = {
      orderBy,
      sortedBy,
      search,
      include
    };
    return apiRequest({
      url: `/sdb/licenses${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  listAvailable: (include = [], search = [], orderBy = 'id', sortedBy = 'asc') => {
    let query = {
      orderBy,
      sortedBy,
      search,
      include
    };
    return apiRequest({
      url: `/sdb/licenses/available${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  get: (id, include = []) => {
    return apiRequest({
      url: `/sdb/licenses/${id}${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  create: (data, include = []) => {
    return apiRequest({
      url: `/sdb/licenses${queryStringFromFields({ include })}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  update: (id, data, include = []) => {
    return apiRequest({
      url: `/sdb/licenses/${id}${queryStringFromFields({ include })}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  delete: (id) => {
    return apiRequest({
      url: `/sdb/licenses/${id}`,
      method: 'delete',
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  attachStockItem: (id, stockItemId) => {
    return apiRequest({
      url: `/sdb/licenses/${id}/stock/${stockItemId}?include=deviceModel`,
      method: 'put',
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  detachStockItem: (id, stockItemId) => {
    return apiRequest({
      url: `/sdb/licenses/${id}/stock/${stockItemId}`,
      method: 'delete',
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
};
