import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getWipReport } from '../../../actions/wipReport';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import WipReportTable from '../../../components/Tables/WipReport';
import LoadingOverlay from '../../../components/LoadingOverlay';
import isEmpty from 'lodash.isempty';
import numeral from 'numeral';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Filler,
  ArcElement, Tooltip, Legend
} from 'chart.js';
import EntitySubFormCard from '../../../components/Cards/EntitySubFormCard';
import { canAccessServiceDb } from '../../../utils/Auth/AuthService';


ChartJS.register(Filler, ArcElement, Tooltip, Legend);

const colours = ['#6F84FF', '#FF495F', '#7dd7c5', '#FF5CF5', '#FF9653', '#FEFF49', '#2F4F4F', '#7B68EE'];
const WipReport = ({
  users,
  wipReport,
  dispatch
}) => {

  const [loading, setLoading] = useState(false)
  const [tcvChart, setTcvChart] = useState({datasets: []})
  const [lineitemChart, setLineitemChart] = useState({datasets: []})
  const toggleLoading = () => setLoading(prevState => !prevState);
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const owners = resolveOwnersToNames(Object.keys(wipReport.totalValueByPM));
    setTcvChart(prevState => getTCVChartData(owners))
    setLineitemChart(prevState => getLineItemChartData(owners))
  },[wipReport.items])
  const fetchData = () => {
    toggleLoading()
    dispatch(getWipReport()).then((result) => {
      toggleLoading()
    })
  }

  const getTCVChartData = (owners) => {
    return {
      datasets: [{ data: Object.values(wipReport.totalValueByPM), backgroundColor: colours.slice(0, owners.length) }], labels: owners
    };
  }

  const getLineItemChartData = (owners) => {
    return {
      datasets: [{ data: Object.values(wipReport.totalItemsByPM), backgroundColor: colours.slice(0, owners.length) }], labels: owners
    };
  }

  const resolveOwnersToNames = (owners) => {
    return owners.map(owner => users.find(user => user.id === owner)?.name ?? 'Unknown Owner');
  }

  return (

    <div className="animated fadeIn">
      <Card className='bg-light border-0 mb-0'>
        <CardBody>
          <LoadingOverlay loading={loading}>
            {canAccessServiceDb() && !isEmpty(tcvChart.datasets) &&

              <Row>
                <Col>
                  <EntitySubFormCard title={'TCV by PM'}>
                    <div className="chart-wrapper" style={{ minHeight: '100px', marginTop: `${20}px` }}>
                      <Pie
                        data={tcvChart}
                        options={{
                          title: {
                            display: true,
                            text: 'TCV by PM'
                          },
                          maintainAspectRatio: false,
                          legend: {
                            display: true
                          },
                          tooltips: {
                            callbacks: {
                              label(tooltipItems, data) {
                                return `${data.labels[tooltipItems.index]}: £${numeral(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]).format('0,0.00')}`;
                              }
                            }
                          }
                        }}
                      />
                    </div>
                  </EntitySubFormCard>
                </Col>
                <Col>
                  <EntitySubFormCard title={'Line Items by PM'}>
                    <div className="chart-wrapper" style={{ minHeight: '100px', marginTop: `${20}px` }}>
                      <Pie
                        data={lineitemChart}
                        options={{
                          title: {
                            display: true,
                            text: 'Line Items by PM'
                          },
                          maintainAspectRatio: false,
                          legend: {
                            display: true
                          },
                          tooltips: {
                            callbacks: {
                              label(tooltipItems, data) {
                                return `${data.labels[tooltipItems.index]}: ${data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]}`;
                              }
                            }
                          }
                        }}
                      />
                    </div>
                  </EntitySubFormCard>
                </Col>
              </Row>
            }

            <Row>
              <Col>
                <EntitySubFormCard title={`Total Value: £${numeral(wipReport.total).format('0,0.00')}`}>
                  <WipReportTable fetchData={fetchData}/>
                </EntitySubFormCard>
              </Col>
            </Row>
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  )


}

function mapStateToProps({ wipReport, authenticationState, helpers }) {
  return {
    wipReport,
    users: helpers.systemUsers,
  };
}
export default connect(mapStateToProps)(WipReport);