import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { ForbiddenError, FatalError } from '../../components/Errors/Errors';
import {store} from '../../index';
import { USER_LOGOUT } from '../../actions/user';
import { getAPIToken, getCustomerAPIToken, isQATester, logoutAndRedirect } from '../Auth/AuthService';
const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

client.interceptors.request.use(
  function(config){
    if(process.env.REACT_APP_ENV !== 'prod' && isQATester()){
      config.headers['hso-request-tester-profile'] = btoa(encodeURIComponent(store.getState().authenticationState.testerProfile.join(',')))
    }
    return config;
  }
)

const compareClient = axios.create({
  baseURL: process.env.REACT_APP_COMPARE_API_URL
});

compareClient.interceptors.request.use(
  function(config){
    if(process.env.REACT_APP_ENV !== 'prod' && isQATester()){
      config.headers['hso-request-tester-profile'] = btoa(encodeURIComponent(store.getState().authenticationState.testerProfile.join(',')))
    }
    return config;
  }
)

const netApiClient = axios.create({
  baseURL: process.env.REACT_APP_NETAPI_URL
});

const identityApiClient = axios.create({
  baseURL: process.env.REACT_APP_HSOID_URL
});

const shiftApiClient = axios.create({
  baseURL: process.env.REACT_APP_SHIFTTRACKER_URL
});

const DEBUG = process.env.REACT_APP_DEBUG;

function apiRequest(options) {
  const onSuccess = function (response) {
    if (DEBUG) {
      console.debug('Request Successful!', response);
    }
    return response;
  };

  const onError = function (error) {
    if (DEBUG) {
      console.error('Request Failed:', error.config);
    }

    if (error.response) {
      if (DEBUG) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
      }
      if (error.response.status === 403) {
        ReactDOM.render(<ForbiddenError/>, document.getElementById('root'));
        return Promise.reject(error.response || error.message);
      }
      if (error.response.status === 401) {
        store.dispatch({ type: USER_LOGOUT });
        logoutAndRedirect();
      } else if (error.response.status === 404) {
        return Promise.reject(error.response || error.message);
      } else if (error.response.status === 422) {
        return Promise.reject(error.response || error.message);
      } else {
        store.dispatch({ type: 'TOAST', title: 'There was an error with your request', msgtype: 'error' });
        return Promise.reject(error.response || error.message);
      }
    } else {
      if (DEBUG) {
        console.error('Error Message:', error.message);
      }
      store.dispatch({ type: 'TOAST', title: 'There was an error with your request', msgtype: 'error' });
      return Promise.reject(error.response || error.message);
    }
    return Promise.reject(error.response || error.message);
  };

  return getAPIToken().then(token => {
    if(!('headers' in options)) {
      options['headers'] = {};
    }
    options['headers']['Authorization'] = 'bearer ' + token
    return client(options)
      .then(onSuccess)
      .catch(onError);
  });
}

function compareApiRequest(options) {
  const onSuccess = function (response) {
    if (DEBUG) {
      console.debug('Request Successful!', response);
    }
    return response;
  };

  const onError = function (error) {
    if (DEBUG) {
      console.error('Request Failed:', error.config);
    }

    if (error.response) {
      if (DEBUG) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
      }
      if (error.response.status !== 404) {
        store.dispatch({
          type: 'TOAST',
          title: 'There was an error with your request',
          msgtype: 'error'
        });
      }
      return Promise.reject(error.response || error.message);
    } else {
      if (DEBUG) {
        console.error('Error Message:', error.message);
      }
      store.dispatch({
        type: 'TOAST',
        title: 'There was an error with your request',
        msgtype: 'error'
      });
      return Promise.reject(error.response || error.message);
    }
  };

  return getAPIToken('api://hso-compare/Accesss').then(token => {
    if(!('headers' in options)) {
      options['headers'] = {};
    }
    options['headers']['Authorization'] = 'bearer ' + token
    return compareClient(options)
      .then(onSuccess)
      .catch(onError);
  });

}

function netApiRequest(options){
  return getAPIToken("api://netapi/Access").then(token => {
    if(!('headers' in options)) {
      options['headers'] = {};
    }
    options['headers']['Authorization'] = 'bearer ' + token
    return netApiClient(options)
  });
}

function customerApiRequest(options){
  return getCustomerAPIToken().then(token => {
    options['headers'] = {};
    options['headers']['Authorization'] = 'bearer ' + token
    return identityApiClient(options)
  });
}

export { apiRequest, compareApiRequest, netApiRequest, customerApiRequest};

export function getApiData() {
  return apiRequest({
    url: '/',
    method: 'get'
  })
    .then(resp => resp)
    .catch(error => error);
}

export function shiftApiRequest(options){
  return getAPIToken("api://947369c4-bdac-42df-9af1-9b28385d6984/Shift.Access").then(token => {
    if(!('headers' in options)) {
      options['headers'] = {};
    }
    options['headers']['Authorization'] = 'bearer ' + token
    return shiftApiClient(options)
  });
}
