import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

const withs = 'notes;notes.createdBy;tasks;tasks.assignedTo;company;contact;owner;engineer;orderProducts;orderProducts.site;quote;quote.opportunity;creditCheckSigner;creditCheckSigner;salesCheckSigner;legalCheckSigner;technicalCheckSigner;businessCheckSigner;deliveryCheckSigner;priceList;priceList.currency'
const includes =  ['contact', 'company', 'products', 'products.site', 'products.circuits', 'quote', 'engineer', 'owner', 'quote.opportunity', 'tasks', 'tasks.assignedTo', 'notes', 'notes.createdBy', 'cases', 'cases.owner', 'priceList', 'priceList.currency']


const OrderService = {
  get: (orderId) => {
    let query = {
      with: withs,
      include: includes
    }

    return apiRequest({
      url: `/orders/${orderId}${queryStringFromFields(query)}`,
      method: 'get'
    })
      .then(
        resp => resp
      )
      .catch(
        error => error
      );
  },
  update: (orderId, data) => {
    let query = {
      with: withs,
      include: includes
    }
    return apiRequest({
      url: `/orders/${orderId}${queryStringFromFields(query)}`,
      method: 'put',
      data
    })
      .catch(
        error => error
      );
  },
  list: ({ template, search, searchFields, includes, withs } = {}) => {
    let query = {
      search,
      searchFields,
      template,
      include: includes,
      with: withs,
    }
    return apiRequest({
      url: `/orders${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  products: {
    create: (orderId, data) => {
      return apiRequest({
        url: `/orders/${orderId}/products?include=order`,
        method: 'post',
        data
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  }
}

export const api_getOrderEmails = (orderId) => {
  return apiRequest({
    url: `/orders/${orderId}/emails`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export function api_getOrderCases(orderId){
  return apiRequest({
    url: `/orders/${orderId}/cases`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export default OrderService


