import { apiRequest } from '../Api/ApiRequest';

export function api_multiSearch(searchString, account) {
  return apiRequest({
    url: '/multisearch?company=' + account + '&ref=' + searchString,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
