import {
  api_createContact, api_deleteContact,
  api_getContactData, api_restoreContact,
  api_updateContact
} from '../utils/Contact/ContactService'
import { defaultErrorFeedback, successFeedback } from './feedback';
import AuditService from "../utils/Audit";

export const CONTACT_SET_DATA = 'CONTACT_SET_DATA';
export const CONTACT_REPLACE_DATA = 'CONTACT_REPLACE_DATA';
export const CONTACT_UPDATE_FIELD = 'CONTACT_UPDATE_FIELD';
export const CONTACT_RESET_DATA = 'CONTACT_RESET_DATA';
export const CONTACT_SET_AUDIT_HISTORY = 'CONTACT_SET_AUDIT_HISTORY';

export function resetContact() {
  return {
    type: CONTACT_RESET_DATA
  };
}

export function updateContactField(field, value) {
  return {
    type: CONTACT_UPDATE_FIELD,
    field,
    value
  };
}

export function createContact(accountid, data) {
  return (dispatch) => {
    return api_createContact(accountid, data).then((result) => {
      if (result.status === 200) {
        dispatch({ type: CONTACT_REPLACE_DATA, contact: { ...result.data, ...data } });
        dispatch(successFeedback('Contact created!'));
        return { ...result.data, ...data };
      }
      dispatch(defaultErrorFeedback());
      return null;
    });
  };
}

export const setContactData = (data) => {
  return {
    type: CONTACT_SET_DATA,
    contact: data
  };
}

export const getContactData = (contactId) => {
  return (dispatch) => {
    return api_getContactData(contactId).then((result) => {
      if (result.status === 200) {
        dispatch(setContactData(result.data));
        return result.data;
      }
      if (result.status === 404) {
        return result;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
};

export function updateContact(contactId, args) {
  return (dispatch) => {
    return api_updateContact(contactId, args).then((result) => {
      if (result.status === 200) {
        dispatch(setContactData({ ...result.data, ...args }));
        dispatch(successFeedback('Contact updated!'));
        return { ...result.data, ...args };
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function deleteContact(contactId) {
  return (dispatch) => {
    return api_deleteContact(contactId).then((result) => {
      if (result.status === 204) {
        dispatch(setContactData({ isActive: false }));
        dispatch(successFeedback('Contact updated!'));
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function restoreContact(contactId, query = {}) {
  return (dispatch) => {
    return api_restoreContact(contactId, query).then((result) => {
      if (result.status === 200) {
        dispatch(setContactData(result.data));
        dispatch(successFeedback('Contact updated!'));
        return result.data;
      } else if (result.status === 422) {
        return { errors: result.data }
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function getContactAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('contacts', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: CONTACT_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }
    })
  }
}
