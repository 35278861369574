import { Badge, Button } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { ButtonIcon } from '../../ButtonIcon';
import HeadlessModal from '../../Modals/HeadlessModal';
import Contact from '../../../views/Contact';
import isEmpty from 'lodash.isempty';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import omit from 'lodash.omit';

const ContactsTable = ({
  showContact,
  contacts,
  fetchData,
  emulate,
  onCreated,
  onUpdated
}) => {

  const [selectedContact, setSelectedContact] = useState(null)
  const [showContactModal, setShowContactModal] = useState(false)
  const toggleModal = () => {
    setShowContactModal(prevState => !prevState)
  }

  useEffect(() => {
    if(showContact){
      setSelectedContact({id: showContact})
      toggleModal()
    }
  }, [showContact]);

  const getExportData = () => {
    return contacts.map(contact => omit(contact, ['id', 'portalUser']))
  }
  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 200
    },
    {
      filterable: false,
      header: 'Email',
      accessorKey: 'email',
      minSize: 150
    },
    {
      filterable: false,
      header: 'Phone',
      accessorKey: 'phone'
    },
    {
      filterable: false,
      header: 'Portal User',
      accessorKey: 'portalUser.username',
      accessorFn: (row => !isEmpty(row.portalUser) ? row.portalUser.username : ''),
    },
    {
      header: 'Portal User Role',
      filterable: false,
      accessorKey: 'portalUser.role',
      accessorFn: (row => !isEmpty(row.portalUser) ? row.portalUser.role : ''),
    },
    {
      header: '',
      filterable: false,
      sortable: false,
      accessorKey: 'portalUser',
      cell: (props) => {
        return props.getValue()?.active ? (
          <Button
            size={'sm'}
            color={'primary'}
            onClick={ (event) => {
              event.stopPropagation();
              emulate(props.row.original);
            }}
            disabled={emulate === undefined || !props.row.original.isActive}
          >
            Emulate
          </Button>
        ) : '';
      }
    },
    {
      header: 'Status',
      accessorKey: 'isActive',
      cell: props =>
        <Badge style={{minWidth: '86px'}} className={`p-2 faded-${props.getValue() ? 'success' : 'warning'}`}>{props.getValue() ? 'Active' : 'Deactivated'}</Badge>

    },
  ];


  return (
    <>
      <FilteredTableV2
        withDeactivatedRow
        withFilter={'includesString'}
        minRows={20}
        columns={columns}
        data={contacts}
        showPagination={contacts.length > 20}
        defaultPageSize={20}
        noDataText={'No Contacts'}
        onRowClick={(contact) => {
           setSelectedContact(contact)
           toggleModal()

        }}
        defaultSorted={[{ id: 'isActive', desc: true }, {id: 'name', desc: false}]}
        extraButtons={[
          <Button size={'sm'} color={'secondary'} onClick={() => {
            setSelectedContact(null)
            toggleModal()
          }}>New Contact</Button>,
          <div data-for={'export'} data-tip={'export'} >
            <CSVLink id={'export'}
                     data={getExportData()} filename={`contacts-${moment().format('DD-MM-YYYY')}.csv`}
                     style={{ textDecoration: 'none' }}>

              <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
            </CSVLink>
          </div>,

          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
        ]}
      />
      <HeadlessModal
        open={showContactModal}
        size={'xlg'}
        toggle={toggleModal}
        onClosed={() => setSelectedContact(null)}
      >
        <Contact
          id={selectedContact?.id}
          toggleModal={toggleModal}
          onCreated={onCreated}
          onUpdated={onUpdated}
        />
      </HeadlessModal>
    </>

  )
}

export default ContactsTable