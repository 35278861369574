import {
  WIP_REPORT_UPDATE_ITEM,
  WIP_REPORT_SET_ITEMS,
  WIP_REPORT_SET_USERS,
  WIP_REPORT_ADD_TO_BATCH,
  WIP_REPORT_REMOVE_FROM_BATCH,
  WIP_REPORT_RESET_BATCH,
  WIP_REPORT_UPDATE_TCV_CHART,
  WIP_REPORT_ADD_BATCH_PARAMETER,
  WIP_REPORT_UPDATE_BATCH_PARAMETER,
  WIP_REPORT_REMOVE_BATCH_PARAMETER,
  WIP_REPORT_TOGGLE_BATCH_UPDATE_IN_PROGRESS, WIP_REPORT_RESET_BATCH_PARAMETERS, WIP_REPORT_UPDATE_LINE_ITEM_CHART
} from '../actions/wipReport';

const initialState = {
  items: [],
  totalValueByPM: {},
  totalItemsByPM: {},
  total: 0,
  tcvChart: null,
  lineItemChart: null
};

export default function wipReport(state = initialState, action) {
  switch (action.type) {
    case WIP_REPORT_UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map(item => {
         if(item.id === action.id){
           return {
             ...item,
             ...action.args
           }
         }
         return item
        })
      };
    case WIP_REPORT_SET_ITEMS:
      return {
        ...state,
        ...action.data
      };
    case WIP_REPORT_SET_USERS:
      return {
        ...state,
        users: action.users
      };
    case WIP_REPORT_ADD_TO_BATCH:
      return {
        ...state,
        batch: { ...state.batch, items: [...state.batch.items, action.item] }
      };
    case WIP_REPORT_REMOVE_FROM_BATCH:
      return {
        ...state,
        batch: { ...state.batch, items: state.batch.items.filter(item => item !== action.item) }
      };
    case WIP_REPORT_RESET_BATCH:
      return {
        ...state,
        batch: { ...initialState.batch }
      };
    case WIP_REPORT_UPDATE_TCV_CHART:
      return {
        ...state,
        tcvChart: action.data
      };
    case WIP_REPORT_UPDATE_LINE_ITEM_CHART:
      return {
        ...state,
        lineItemChart: action.data
      };
    case WIP_REPORT_ADD_BATCH_PARAMETER:
      return {
        ...state,
        batch: { ...state.batch, parameters: [...state.batch.parameters, { entity: action.entity, parameter: action.parameter }] }
      };
    case WIP_REPORT_REMOVE_BATCH_PARAMETER:
      return {
        ...state,
        batch: { ...state.batch, parameters: state.batch.parameters.filter(param => param.parameter.name !== action.parameter.name || param.entity !== action.entity) }
      };
    case WIP_REPORT_RESET_BATCH_PARAMETERS:
      return {
        ...state,
        batch: { ...state.batch, parameters: [] }
      };
    case WIP_REPORT_UPDATE_BATCH_PARAMETER:
      const updatedParameters = state.batch.parameters.map((param) => {
        if (param.entity === action.entity && param.parameter.name === action.parameter) {
          param.parameter.value = action.value;
        }
        return param;
      });
      return {
        ...state,
        batch: { ...state.batch, parameters: updatedParameters }
      };
    case WIP_REPORT_TOGGLE_BATCH_UPDATE_IN_PROGRESS:
      return {
        ...state,
        batch: { ...state.batch, inProgress: !state.batch.inProgress }
      };
    default:
      return state;
  }
}
