import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getOrders, resetOrders } from '../../../actions/orders';
import { OrderEnums } from '../../../utils/Constants/Order';
import { Card, CardBody, Col, Row } from 'reactstrap';
import LoadingOverlay from '../../../components/LoadingOverlay';
import OrdersTable from '../../../components/Tables/Orders';
import { checkPermission, isSalesManager } from '../../../utils/Auth/AuthService'

const inFlightOrders = [
  OrderEnums.statusReason.CANCEL_WAITING_FOR_FINAL_BILL,
  OrderEnums.statusReason.RED_FILE_APPROVED,
  OrderEnums.statusReason.RED_FILE_REJECTED,
  OrderEnums.statusReason.RED_FILE_UNDER_REVIEW,
  OrderEnums.statusReason.RED_FILE_REJECTED_INTO_SD,
  OrderEnums.statusReason.RED_FILE_ACCEPTED_INTO_SD,
  OrderEnums.statusReason.SALES_INITIAL,
  OrderEnums.statusReason.CANCEL_INITIAL_PERIOD_CHECKED,
  OrderEnums.statusReason.ON_HOLD_CUSTOMER,
  OrderEnums.statusReason.CANCEL_PENDING,
  OrderEnums.statusReason.CANCEL_SD_CHECKS,
  OrderEnums.statusReason.CANCEL_FINANCE_PROCESSING,
  OrderEnums.statusReason.SALES_SIGNED_CONTRACT_ORDER,
  OrderEnums.statusReason.HANDOFF_TO_BILLING,
]
const SalesOrders = ({
  user,
  orders,
  dispatch
}) => {
  const [loadingOrders, setLoadingOrders] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const toggleLoading = () => setLoadingOrders(prevState => !prevState)

  const getData = () => {
    dispatch(resetOrders())
    toggleLoading()
    dispatch(getOrders({
      template: isSalesManager (user.permissions) ? 'SalesTeamOrdersInFlight' : 'SalesTeamMemberOrdersInFlight',
      includes: ['company', 'owner', 'priceList', 'priceList.currency'].join(','),
      withs: ['company', 'owner', 'priceList', 'priceList.currency'].join(';')
  }))
      .then(() => toggleLoading());
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loadingOrders}>
            <Row>
              <Col>
                <OrdersTable
                  fetchData={getData}
                  excludeColumns={['nextCustomerUpdate']}
                />
              </Col>
            </Row>
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  )
}

function mapStateToProps({ orders, authenticationState }) {
  return {
    orders,
    user: authenticationState.account,
  };
}
export default connect(mapStateToProps)(SalesOrders);