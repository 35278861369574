export const SET_CONFIRM_DIALOG = 'SET_CONFIRM_DIALOG';
export const SET_CONFIRM_CANCELLED = 'SET_CONFIRM_CANCELLED';
export const SET_CONFIRM_PROCEED = 'SET_CONFIRM_PROCEED';
export const SET_DIALOGUE = 'SET_DIALOGUE';
export const SET_DIALOGUE_CANCELLED = 'SET_DIALOGUE_CANCELLED';

export function setDialogue(dialogue) {
  return {
    type: SET_DIALOGUE,
    dialogue
  };
}

export function cancelDialogue() {
  return {
    type: SET_DIALOGUE_CANCELLED
  };
}

export function setConfirmDialog(dialog) {
  return {
    type: SET_CONFIRM_DIALOG,
    dialog: dialog
  };
}

export function cancelConfirmDialog() {
  return {
    type: SET_CONFIRM_CANCELLED
  };
}

export function proceedConfirmDialog() {
  return {
    type: SET_CONFIRM_PROCEED
  };
}
