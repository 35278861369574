import React, { Component } from 'react';
import { connect } from 'react-redux';
import { api_getK8sContexts, api_updateK8sImage } from '../../../utils/K8s';
import { SelectMod } from '../../../components/Selects/SelectMod';
import { CardBody, CardHeader, Label, Row, Col, Card, FormGroup, Input, Button } from 'reactstrap';
import { errorFeedback, successFeedback } from '../../../actions/feedback';

class K8s extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      contexts: [],
      activeContext: undefined,
      image: ''
    };
  }

  getContexts = () => {
    api_getK8sContexts().then((result) => {
      this.setState({ contexts: result.data });
    });
  };

  handleInput = (selectedOption) => {
    this.setState({ activeContext: selectedOption });
  };

  handleImageUpdate = (e) => {
    this.setState({ image: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    api_updateK8sImage(this.state.activeContext.name, this.state.image).then((result) => {
      this.props.dispatch(successFeedback('Image update submitted, check the logs'));
    }).catch(() => {
      this.props.dispatch(errorFeedback('No magic possible, soz'));
    });
  }

  componentDidMount() {
    this.getContexts();
  }

  render() {
    return (<div>
      <Row>
        <Col md={6}>
          <Card>
            <CardHeader>Push a Kubernetes Image</CardHeader>
            <CardBody>
              <Label>Context</Label>
              <SelectMod
                isClearable={true}
                cacheOptions={true}
                getOptionLabel={({ name }) => name }
                getOptionValue={({ name }) => name}
                options={this.state.contexts}
                onChange={this.handleInput}
              />
              <FormGroup>
                <Label>Image Name</Label>
                <Input placeholder=" ghcr.io/highspeedoffice/<image>:<tag>" type="text" onChange={this.handleImageUpdate}/>
              </FormGroup>
              <Button color={'danger'} onClick={this.handleSubmit} disabled={this.state.activeContext === undefined || this.state.image.length < 5}>Push it Real Good!</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>);
  }
}

export default connect()(K8s);
