import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  applyBillCredit,
  BILLING_RUN_BILL_SET_BILL,
  BILLING_RUN_BILL_SET_CREDIT_ERROR,
  BILLING_RUN_BILL_SET_LOADING,
  BILLING_RUN_BILL_SET_PAID,
  BILLING_RUN_BILL_TOGGLE_CREDIT_MODAL,
  BILLING_RUN_BILL_TOGGLE_CREDIT_PROCESSING,
  BILLING_RUN_BILL_TOGGLE_EDIT_MODAL,
  BILLING_RUN_BILL_TOGGLE_SENDING,
  BILLING_RUN_BILL_UPDATE_CREDIT,
  getBill,
  rerunBill, setBillEditErrors,
  toggleBillActiveState, toggleBillEditProcessing, updateBill, updateBillEditField
} from '../../actions/billingRunBill';
import Loadable from 'react-loading-overlay';
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
  Alert,
  InputGroupAddon
} from 'reactstrap';
import { BillingItems } from './BillingItems';
import { api_downloadInvoice, api_markInvoiceAsPaid, api_triggerBillEmailRun } from '../../utils/Billing/BillingService';
import fileDownload from 'js-file-download';
import { setConfirmDialog } from '../../actions/dialogs';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { successFeedback, warningFeedback } from '../../actions/feedback';
import { BILLING_RUN_TOGGLE_BILL_MODAL } from '../../actions/billingRun';
import isFloat from 'validator/lib/isFloat';
import numeral from 'numeral';
import BillingRunBillEdit, { BillEditFormFields } from './BillingRunBillEdit';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';

class BillingRunBill extends React.Component {
  componentDidMount() {
    this.getBill();
  }

  componentWillUnmount() {
    this.props.dispatch({ type: BILLING_RUN_BILL_SET_BILL, bill: {} });
  }

    getBill = () => {
      this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: true });
      this.props.dispatch(getBill(this.props.billingRunBill.bill.billingRunId, this.props.billingRunBill.bill.id)).then(() => {
        this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: false });
      });
    }

    downloadPdf = () => {
      const { bill } = this.props.billingRunBill;
      this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: true });
      api_downloadInvoice(bill.id).then((result) => {
        if (result.status === 200) {
          let splitContentDisposition = result.headers['content-disposition'].split('=');
          fileDownload(result.data, splitContentDisposition[1]);
        }
        this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: false });
      });
    }

    markBillPaid = () => {
      const { bill } = this.props.billingRunBill;
      this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: true });
      api_markInvoiceAsPaid(bill.id).then((result)=>{
        if (result) {
          this.props.dispatch({type: BILLING_RUN_BILL_SET_PAID, paid: true})
        }
        this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: false });
      })
    }

    downloadXls = () =>{
      const { bill } = this.props.billingRunBill;
      this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: true });
      api_downloadInvoice(bill.id, 'xlsx').then((result) => {
        if (result.status === 200) {
          let splitContentDisposition = result.headers['content-disposition'].split('=');
          fileDownload(result.data, splitContentDisposition[1]);
        }
        this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: false });
      });
    }

    toggleCreditModal = () => {
      this.props.dispatch({ type: BILLING_RUN_BILL_SET_CREDIT_ERROR, error: '' });
      this.props.dispatch({ type: BILLING_RUN_BILL_UPDATE_CREDIT, value: 0 });
      this.props.dispatch({ type: BILLING_RUN_BILL_TOGGLE_CREDIT_MODAL });
    }

    toggleEditBillModal = () => {
      this.props.dispatch({ type: BILLING_RUN_BILL_TOGGLE_EDIT_MODAL });
    }

    handleCreditInput = (event) => {
      this.props.dispatch({ type: BILLING_RUN_BILL_SET_CREDIT_ERROR, error: '' });
      if (!isFloat(event.target.value)) {
        this.props.dispatch({ type: BILLING_RUN_BILL_SET_CREDIT_ERROR, error: 'invalid credit value' });
      }
      this.props.dispatch({ type: BILLING_RUN_BILL_UPDATE_CREDIT, value: event.target.value });
    }

    applyCredit = () => {
      const { bill, credit } = this.props.billingRunBill;
      this.props.dispatch({ type: BILLING_RUN_BILL_TOGGLE_CREDIT_PROCESSING });
      this.props.dispatch(applyBillCredit(bill.id, credit.value)).then((result) => {
        if (result) {
          this.toggleCreditModal();
          this.getBill();
        }
        this.props.dispatch({ type: BILLING_RUN_BILL_TOGGLE_CREDIT_PROCESSING });
      });
    }

    rerunBill = () => {
      const { bill } = this.props.billingRunBill;
      this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: true });
      this.props.dispatch(setConfirmDialog({
        color: 'danger',
        text: 'This action will remove the account and its references from this billing run and will attempt to run it again.',
        proceed: () => this.props.dispatch(rerunBill(bill.billingRunId, bill.id)).then(() => {
          this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: false });
          this.props.dispatch({ type: BILLING_RUN_TOGGLE_BILL_MODAL });
        }),
        cancel: () => this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: false })
      }));
    }

    excludeBill = () => {
      const { bill } = this.props.billingRunBill;
      this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: true });
      this.props.dispatch(setConfirmDialog({
        color: 'danger',
        text: 'This action will exclude this bill from the run and will be completed without it.',
        proceed: () => this.props.dispatch(toggleBillActiveState(bill.billingRunId, bill.id, false)).then(() => {
          this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: false });
          this.props.dispatch({ type: BILLING_RUN_TOGGLE_BILL_MODAL });
        }),
        cancel: () => this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: false })
      }));
    }

    includeBill = () => {
      const { bill } = this.props.billingRunBill;
      this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: true });
      this.props.dispatch(setConfirmDialog({
        color: 'danger',
        text: 'This action will include this bill in the run and will be completed with it.',
        proceed: () => this.props.dispatch(toggleBillActiveState(bill.billingRunId, bill.id, true)).then(() => {
          this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: false });
          this.props.dispatch({ type: BILLING_RUN_TOGGLE_BILL_MODAL });
        }),
        cancel: () => this.props.dispatch({ type: BILLING_RUN_BILL_SET_LOADING, loading: false })
      }));
    }

    sendEmails = () => {
      const { bill } = this.props.billingRunBill;
      this.props.dispatch({ type: BILLING_RUN_BILL_TOGGLE_SENDING });
      this.props.dispatch(setConfirmDialog({
        color: 'warning',
        text: 'This action will send this bill out to its recipients according to the template.',
        proceed: () => api_triggerBillEmailRun(bill.billingRunId, bill.id).then(() => {
          this.props.dispatch(successFeedback('Bill ' + bill.id + ' sent.'));
          this.props.dispatch({ type: BILLING_RUN_BILL_TOGGLE_SENDING });
        }),
        cancel: () => this.props.dispatch({ type: BILLING_RUN_BILL_TOGGLE_SENDING })
      }));
    }

    isEditable = () => {
      const { data } = this.props.billingRun;
      return data.status !== 'staging';
    }

    handleEditInput = (event) => {
      this.props.dispatch(updateBillEditField({ [event.target.id]: event.target.value }));
    }

    validBillEdit = () => {
      const { edit } = this.props.billingRunBill;
      let errors = [];
      this.props.dispatch(setBillEditErrors(errors));
      for (const [key, value] of Object.entries(BillEditFormFields)) {
        if (value.mandatory && (edit.fields[key] === undefined || edit.fields[key].length === 0)) {
          errors.push(`You must provide a valid ${value.label}`);
        }
      }
      if (errors.length === 0) {
        return true;
      }
      this.props.dispatch(setBillEditErrors(errors));
    }

    saveEditedBill = () => {
      const { edit, bill } = this.props.billingRunBill;
      const toSave = diff(bill, edit.fields);
      if (isEmpty(toSave)) {
        this.props.dispatch(warningFeedback('No changes detected!'));
      }
      if (this.validBillEdit() && !isEmpty(toSave)) {
        this.props.dispatch(toggleBillEditProcessing());
        this.props.dispatch(updateBill(bill.id, toSave)).then(() => this.props.dispatch(toggleBillEditProcessing()));
      }
    }

    render() {
      const { loading, sending, bill, credit, edit } = this.props.billingRunBill;
      const { data } = this.props.billingRun;
      return (
            <Loadable style={{ minHeight: '300px' }} animate={false} active={loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                {bill.credit > 0
                    && <Row>
                        <Col>
                            <Alert className={'align-self-start'} color={'warning'}>
                                £{numeral(bill.credit).format('0,0.00')} in Credit
                            </Alert>
                        </Col>
                    </Row>

                }
                <Row>
                    <Col className="d-flex justify-content-end">
                        {this.isEditable()
                            && <Button size={'small'} color={'info'} className={'mr-1'} onClick={this.toggleEditBillModal}>Edit</Button>
                        }
                        {data.status !== 'completed'
                          ? <Fragment>
                                {bill.active && data.status === 'staging'
                                    && <Fragment>
                                        <Button size={'small'} color={'info'} className={'mr-1'} onClick={this.toggleCreditModal}>Credit</Button>
                                        <Button size={'small'} color={'warning'} className={'mr-1'} onClick={this.rerunBill}>Re-run</Button>
                                    </Fragment>
                                }
                                {bill.active
                                  ? <Button size={'small'} color={'danger'} className={'mr-1'} onClick={this.excludeBill}>Exclude</Button>
                                  : <Button size={'small'} color={'danger'} className={'mr-1'} onClick={this.includeBill}>Include</Button>
                                }

                            </Fragment>
                          : <LaddaButton
                                className="btn btn-sm btn-warning btn-ladda px-4 mr-1"
                                loading={sending}
                                data-color="black"
                                data-style={ZOOM_OUT}
                                onClick={this.sendEmails}
                            >
                                <div className={'h4 mb-0'} id="mail"><i className="fa fa-envelope"></i></div>
                                <UncontrolledTooltip placement="top" target={'mail'}>
                                    Send bill
                                </UncontrolledTooltip>
                            </LaddaButton>
                        }
                        {bill.paymentMethod === 'Credit Card' ?
                          <Button  disabled={bill.paid} outline size={'small'} color={!bill.paid ?'primary':'success'} className={'mr-1'} onClick={this.markBillPaid}>{!bill.paid?'Mark as paid':'Paid'}</Button>:''
                        }
                        <Button outline size={'small'} color={'primary'} className={'mr-1'} onClick={this.downloadPdf}>.pdf</Button>
                        <Button outline size={'small'} color={'primary'} className={'mr-1'} onClick={this.downloadXls}>.xlsx</Button>
                    </Col>
                </Row>
                <Row className={'mt-3 mb-3'}>
                    <Col>
                        <BillingItems items={bill.billItems ? bill.billItems : []}/>
                    </Col>
                </Row>
                <Modal isOpen={credit.showModal} toggle={this.toggleCreditModal} backdrop={'static'} color={'info'}>
                    <ModalHeader toggle={this.toggleCreditModal}>
                        Credit
                    </ModalHeader>
                    <ModalBody>
                        <Loadable animate={false} active={credit.processing} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                        <FormGroup>
                            <InputGroup>
                                <Input placeholder="credit amount" onChange={this.handleCreditInput} value={credit.value || ''}/>
                                <InputGroupAddon addonType="append">
                                    <Button onClick={this.applyCredit} disabled={!credit.value || credit.error}>Apply</Button>
                                </InputGroupAddon>
                            </InputGroup>
                            {credit.error
                                && <div className={'mt-2'}>
                                    <Alert color={'danger'}>{credit.error}</Alert>
                                </div>
                            }
                        </FormGroup>
                        </Loadable>
                    </ModalBody>
                </Modal>
                <Modal isOpen={edit.showModal} toggle={this.toggleEditBillModal} backdrop={'static'} color={'info'}>
                    <ModalHeader toggle={this.toggleEditBillModal}>
                        {bill.account} - {bill.id}
                    </ModalHeader>
                    <ModalBody>
                        <Loadable animate={false} className={'p-2'} active={edit.processing} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                            <BillingRunBillEdit
                                edit={edit}
                                handleInput={this.handleEditInput}
                                save={this.saveEditedBill}
                            />
                        </Loadable>
                    </ModalBody>
                </Modal>
            </Loadable>
      );
    }
}

function mapStateToProps({ billingRunBill, billingRun }) {
  return {
    billingRunBill,
    billingRun
  };
}
export default connect(mapStateToProps)(BillingRunBill);
