import { api_getActiveCustomers, api_startBillingRun } from '../utils/Billing/BillingService';
import { defaultErrorFeedback, errorFeedback } from './feedback';
import AccountService from '../utils/Account';

export const BILLING_RUN_SET_ACCOUNT_SEARCH_OPTIONS = 'BILLING_RUN_SET_ACCOUNT_SEARCH_OPTIONS';
export const BILLING_RUN_SET_ACCOUNT = 'BILLING_RUN_SET_ACCOUNT';
export const BILLING_RUN_SET_ACCOUNT_RUN_LOADING = 'BILLING_RUN_SET_ACCOUNT_RUN_LOADING';
export const BILLING_RUN_SET_BATCH_RUN_LOADING = 'BILLING_RUN_SET_BATCH_RUN_LOADING';

export function setAccountToRun(account) {
  return {
    type: BILLING_RUN_SET_ACCOUNT,
    account
  };
}
function setSearchOptions(searchOptions) {
  return {
    type: BILLING_RUN_SET_ACCOUNT_SEARCH_OPTIONS,
    searchOptions
  };
}

function setAccountLoading(loading) {
  return {
    type: BILLING_RUN_SET_ACCOUNT_RUN_LOADING,
    loading: loading === true
  };
}

function setBatchLoading(loading) {
  return {
    type: BILLING_RUN_SET_BATCH_RUN_LOADING,
    loading: loading === true
  };
}

export function searchActiveBillingCustomers(searchString) {
  return (dispatch) => {
    return AccountService.search(searchString, true).then((result) => {
      if (result.status === 200) {
        dispatch(setSearchOptions(result.data));
      } else {
        dispatch(defaultErrorFeedback());
        dispatch(setSearchOptions([]));
      }
    });
  };
}

export function startBillingRun(accountNumber) {
  return (dispatch) => {
    dispatch(accountNumber ? setAccountLoading(true) : setBatchLoading(true));
    api_startBillingRun(accountNumber).then((result) => {
      if (result.status === 200) {
        if (result.data.error) {
          dispatch(errorFeedback(result.data.error));
        }
      } else {
        dispatch(defaultErrorFeedback());
      }
      dispatch(accountNumber ? setAccountLoading(false) : setBatchLoading(false));
    });
  };
}
