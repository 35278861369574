import { defaultErrorFeedback } from './feedback';
import { api_generateBillingBoothSummary, api_getBillingBoothSummary } from '../utils/Billing/BillingService';

export const BILLING_BILLINGBOOTH_SET_SUMMARY = 'BILLING_BILLINGBOOTH_SET_SUMMARY';
export const BILLING_BILLINGBOOTH_TOGGLE_LOADING = 'BILLING_BILLINGBOOTH_TOGGLE_LOADING';

function setSummary(summary) {
  return { type: BILLING_BILLINGBOOTH_SET_SUMMARY, summary };
}

export function toggleBillingBoothLoading() {
  return { type: BILLING_BILLINGBOOTH_TOGGLE_LOADING };
}

export function getSummary() {
  return (dispatch) =>{
    return api_getBillingBoothSummary().then((result) => {
      if (result.status === 200) {
        dispatch(setSummary(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function generateSummary() {
  return (dispatch) =>{
    return api_generateBillingBoothSummary().then((result) => {
      if (result.status === 200) {
        dispatch(setSummary(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}
