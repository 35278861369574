import { api_createEntityNote } from '../utils/Notes/Notes';
import { defaultErrorFeedback, successFeedback } from './feedback';
import { api_getLineItem, api_getOrder, api_getProduct, api_updateOrder } from '../utils/ServiceDelivery/WIP';
import { api_updateLineItem } from '../utils/LineItem/LineItemService';
import { api_getCircuit } from '../utils/Circuits/CircuitService';
export const WIP_ITEM_SET_ITEM = 'WIP_ITEM_SET_ITEM';
export const WIP_ITEM_UPDATE_ITEM = 'WIP_ITEM_UPDATE_ITEM';
export const WIP_ITEM_ADD_NOTE = 'WIP_ITEM_ADD_NOTE';
export const WIP_ITEM_SET_ENTITY = 'WIP_ITEM_SET_ENTITY';
export const WIP_ITEM_RESET = 'WIP_ITEM_RESET';
export const WIP_ITEM_TOGGLE_CIRCUIT_VIEW = 'WIP_ITEM_TOGGLE_CIRCUIT_VIEW';

const entityAPIRouteMap = {
  order: 'orders',
  product: 'products',
  circuit: 'circuits',
  limeItem: 'lineitems'
};
export function setWIPItem(item) {
  return { type: WIP_ITEM_SET_ITEM, item };
}

export function resetWIPItem() {
  return { type: WIP_ITEM_RESET };
}

export function setWIPItemEntity(entityType, item) {
  return { type: WIP_ITEM_SET_ENTITY, entityType, item };
}

export function updateWIPItem(entity, field, value) {
  return {
    type: WIP_ITEM_UPDATE_ITEM,
    entity,
    field,
    value
  };
}

export function toggleCircuitView() {
  return {
    type: WIP_ITEM_TOGGLE_CIRCUIT_VIEW
  };
}

export function setWIPItemOrder(id) {
  return (dispatch) => {
    return api_getOrder(id).then((result) => {
      if (result.status === 200) {
        dispatch(setWIPItemEntity('order', result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function setWIPItemCircuit(id) {
  return (dispatch) => {
    return api_getCircuit(id).then((result) => {
      if (result.status === 200) {
        dispatch(setWIPItemEntity('circuit', result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function setWIPItemProduct(id) {
  return (dispatch) => {
    return api_getProduct(id).then((result) => {
      if (result.status === 200) {
        dispatch(setWIPItemEntity('product', result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

function addWIPItemNote(entityType, note) {
  return {
    type: WIP_ITEM_ADD_NOTE,
    entityType,
    note
  };
}

export function createEntityNote(type, id, note) {
  return (dispatch) => {
    return api_createEntityNote(entityAPIRouteMap[type], id, note).then((result) => {
      if (result.status === 200) {
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function saveEntity(type, id, args) {
  return (dispatch) => {
    if (type === 'lineItem') {
      return api_updateLineItem(id, args).then((result) => {
        if (result.status === 200) {
          dispatch(successFeedback('lineItem updated!'));
          return true;
        }

        dispatch(defaultErrorFeedback());
        return false;
      });
    }
    if (type === 'order') {
      return api_updateOrder(id, args).then((result) => {
        if (result.status === 200) {
          dispatch(successFeedback('order updated!'));
          return true;
        }

        dispatch(defaultErrorFeedback());
        return false;
      });
    }
  };
}
