import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Row, Label, Progress } from 'reactstrap';
import { connect } from 'react-redux';
import 'react-widgets/dist/css/react-widgets.css';
import { SelectMod } from '../../components/Selects/SelectMod';
import { downloadCdrCsv, submitCdrRun } from '../../utils/DialogicCdrs/DialogicCdrsService';
import { joinRooms } from '../../actions/socketio';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import fileDownload from 'js-file-download';
import { errorFeedback } from '../../actions/feedback';
Moment.locale('en');
momentLocalizer();

class GenerateCdrs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        loaded: false,
        processing: false
      },
      errors: {},
      state: 0,
      progress: 0,
      taskname: '',
      res: {},
      platform: null,
      month: {
        i: Moment().subtract(1, 'months').format('M'),
        t: Moment().subtract(1, 'months').format('MMMM')
      },
      year: {
        i: Moment().subtract(1, 'months').year(),
        t: Moment().subtract(1, 'months').year()
      }
    };
  }

    static contextTypes = {
      router: PropTypes.shape({
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
          replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
      }).isRequired
    };

    componentDidUpdate() {
      if (this.state.state === 2) {
        downloadCdrCsv(this.state.testid).then((result) => {
          if (result.status === 200) {
            let splitContentDisposition = result.headers['content-disposition'].split('=');
            fileDownload(result.data, splitContentDisposition[1]);
          }
        });
      }
    }

    handleMonth = (selectedOption) => {
      this.setState({ month: selectedOption });
    };

    handleYear = (selectedOption) => {
      this.setState({ year: selectedOption });
    };

    handlePlatform = (selectedOption) => {
      this.setState({ platform: selectedOption });
    };

    handleSubmit = () => {
      if (this.state.platform === null) {
        this.props.dispatch(errorFeedback('You must select a platform'));
        return;
      }
      submitCdrRun(this.state.month.i, this.state.year.i, this.state.platform.i).then((result) => {
        if (result.status === 200) {
          this.props.dispatch(joinRooms({ rooms: [result.data.uuid] }));
          this.setState({ state: 1, testid: result.data.uuid });
          this.interval = setInterval(() => {
            if (this.props.progressBars[result.data.uuid] !== undefined) {
              if (this.props.progressBars[result.data.uuid].status === 'COMPLETE') {
                this.setState({ state: 2 });
                clearInterval(this.interval);
              }
            }
          }, 500);
        }
      }).catch((error) => {
        this.setState({
          state: 0,
          processing: false,
          errors: {
            errMessage: 'Fatal Error (' + error.status + ') ' + error.statusText
          }
        });
      });
    };

    reset = () => {
      this.setState({ state: 0 });
    }

    render() {
      if (this.state.state === 1) {
        if (this.props.progressBars[this.state.testid] !== undefined) {
          let progress = (this.props.progressBars[this.state.testid].current / this.props.progressBars[this.state.testid].total) * 100;
          return (<Row className="animated fadeIn" style={{ marginTop: '40px', marginBottom: '40px' }}>
                    <Col md={{ size: 4, offset: 4 }}>
                        <Progress value={progress}/>
                        <p>File {this.props.progressBars[this.state.testid].current} of {this.props.progressBars[this.state.testid].total}: {this.props.progressBars[this.state.testid].message}</p>
                    </Col>
                </Row>);
        }
        return (<Row className="animated fadeIn" style={{ marginTop: '40px', marginBottom: '40px' }}>
                    <Col md={{ size: 4, offset: 4 }}>
                        <p style={{ textAlign: 'center' }}>Initializing funky progress bar...</p>
                    </Col>
                </Row>);
      }
      if (this.state.state === 2) {
        return (<Row className="animated fadeIn" style={{ marginTop: '40px', marginBottom: '40px' }}>
                <Col md={{ size: 4, offset: 4 }}>
                    <p style={{ textAlign: 'center', fontSize: '80px' }}><i className="icon-cloud-download"/></p>
                    <p style={{ textAlign: 'center' }}>Downloading file...</p>
                    <p style={{ textAlign: 'center' }}><Button onClick={this.reset}>Start Again</Button></p>
                </Col>
            </Row>);
      }
      let years = [];
      for (let i = 2019; i <= new Date().getFullYear(); i++) {
        years.push({ i: i, t: i });
      }
      return (
            <div className="animated fadeIn" style={{ marginTop: '40px', marginBottom: '40px' }}>
                <Col md={{ size: 6, offset: 2 }}>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Platform</Label>
                                <SelectMod
                                    value={this.state.platform}
                                    options={[
                                      { i: 'tasks.generate_audiocodes_cdr', t: 'Audiocodes' },
                                      { i: 'tasks.generate_broadcloud_cdr', t: 'Broadcloud' },
                                      { i: 'tasks.generate_dialogic_cdr', t: 'Dialogic' }
                                    ]}
                                    getOptionLabel={({ t }) => t}
                                    getOptionValue={({ i }) => i}
                                    onChange={this.handlePlatform}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Month</Label>
                                <SelectMod
                                    value={this.state.month}
                                    options={[
                                      { i: 1, t: 'January' },
                                      { i: 2, t: 'February' },
                                      { i: 3, t: 'March' },
                                      { i: 4, t: 'April' },
                                      { i: 5, t: 'May' },
                                      { i: 6, t: 'June' },
                                      { i: 7, t: 'July' },
                                      { i: 8, t: 'August' },
                                      { i: 9, t: 'September' },
                                      { i: 10, t: 'October' },
                                      { i: 11, t: 'November' },
                                      { i: 12, t: 'December' }
                                    ]}
                                    getOptionLabel={({ t }) => t}
                                    getOptionValue={({ i }) => i}
                                    onChange={this.handleMonth}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={3}>
                            <FormGroup>
                                <Label>Year</Label>
                                <SelectMod
                                    value={this.state.year}
                                    options={years}
                                    getOptionLabel={({ t }) => t}
                                    getOptionValue={({ i }) => i}
                                    onChange={this.handleYear}
                                />
                            </FormGroup>
                        </Col>
                      <Col md={3}>
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Button block color="primary" className="text-white" onClick={this.handleSubmit}>GENERATE</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </div>
      );
    }
}

function mapStateToProps(state) {
  return {
    progressBars: state.socketioProgress.progressBars
  };
}

export default connect(mapStateToProps)(GenerateCdrs);
