import Avatar from '../../Avatar';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { useNavigate } from 'react-router-dom'
import { ButtonIcon } from '../../ButtonIcon';
import { SelectMod } from '../../Selects/SelectMod';

export const SignatureStatusOptions = [
  { value: null, label: 'All' },
  { value: 'draft', label: 'Draft'},
  { value: 'sending', label: 'Sending'},
  { value: 'sent', label: 'Sent'},
  { value: 'signed', label: 'Signed'},
  { value: 'declined', label: 'Declined'},
  { value: 'hso', label: 'hSo'},
]
const SignaturesTable = ({
  fetchData,
  signatures,
  table,
  user
}) => {

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({pageIndex : 0, pageSize: 20})
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState([{ id: 'updatedAt', desc: true }])
  const [statusFilter, setStatusFilter] = useState({
    value: null,
    label: 'All'
  });
  const currentStatusFilter = useRef(null)
  const onStatusFilterChange = (selected) => {
    setStatusFilter(selected)
  };

  useEffect(() => {
    if(currentStatusFilter.current){
      fetchData(pagination.pageIndex + 1, pagination.pageSize, getSearch(search), sorting)
    }
    currentStatusFilter.current = statusFilter

  }, [statusFilter])
  const getSearch = (searchString) => {
    if(!statusFilter.value || searchString){
      return {search: searchString}
    }
    return {search: `status:${statusFilter.value}`}
  }
  const getReference = (relatedTo) =>{
    const type = relatedTo.type
    let reference = ''
    switch (type){
      case 'quote':
        reference = relatedTo.data.number
        break
      case 'creditRequest':
        reference = relatedTo.data.requestNumber
        break
      case 'order':
        reference = relatedTo.data.orderNumber
        break
      default:
        reference = relatedTo.data?.name ?? ''
    }

    return reference
  }

  const getSignatory = (tag, data, emailOnly = false) => {
    const signatory  = data.signatories.find(signatory => signatory.documentTag === tag)

    if(emailOnly){
      return signatory?.signatory.data?.email ?? ''
    }
    return (
      <div>
          <span id={`sigav_${tag}-${data.id}`}>
            <Avatar name={signatory?.signatory.data?.name}
                    email={signatory?.signatory.data?.email}
                    size={32}
                    className="img-avatar"/>
          </span>
        <UncontrolledTooltip target={`sigav_${tag}-${data.id}`} placement="right">
          {signatory?.signatory.data?.name}
        </UncontrolledTooltip>
      </div>
    )

  }

  const getBadgeColor = (status) => {
    switch (status) {
      case 'draft':
        return 'secondary';
      case 'declined':
        return 'danger';
      case 'signed':
        return 'success';
      default:
        return 'warning';
    }
  };

  const onSorting = (sortingState, searchString) => {
    if(sortingState !== sorting){
      fetchData(pagination.pageIndex + 1, pagination.pageSize, getSearch(searchString),sortingState )
      setSorting(sortingState)
    }
  }
  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
      maxSize: 30
    },
    {
      header: 'Subject',
      accessorKey: 'title',
      minSize: 300
    },
    {
      header: 'Owner',
      accessorKey: 'owner',
      cell: (props) => <>
        <span id={'avatar_' + props.row.original.id}>
                      <Avatar name={props.getValue().name} email={props.getValue().email}
                              size={32} className="img-avatar"/>
                    </span>
        <UncontrolledTooltip target={'avatar_' + props.row.original.id} placement="right">
          {props.getValue().name}
        </UncontrolledTooltip>
      </>,
      maxSize: 30
    },
    {
      header: 'hSo Sig',
      cell: (props) => getSignatory(1, props.row.original),
      maxSize: 30
    },
    {
      header: 'Recipient',
      cell: (props) => getSignatory(0, props.row.original, true),
      maxSize: 30
    },
    {
      header: 'Reference',
      accessorKey: 'relatedTo',
      cell: props => getReference(props.getValue()) ?? ''

    },
    {
      header: 'Doc Count',
      accessorKey: 'docCount',

    },
    {
      header: (props) => {
        return <SelectMod
          options={SignatureStatusOptions}
          isSearchable
          value={statusFilter}
          onChange={(selected) => onStatusFilterChange(selected, props)}
          size={'sm'}
        />
      },
      accessorKey: 'status',
      enableSorting: false,
      cell: (props) =>  <Badge style={{minWidth: '86px'}} className={`p-2 faded-${getBadgeColor(props.getValue())}`}>{props.getValue()}</Badge>
    },
    {
      header: 'Updated At',
      accessorKey: 'updatedAt',
      cell: (props) => moment(props.getValue()).format('DD/MM/YYYY HH:mm:ss')
    },
  ];

  return (
    <>
      <FilteredTableV2
        withServerSideFilter={(searchString) => {
          setSearch(searchString)
          fetchData(1, pagination.pageSize, getSearch(searchString), sorting)
        }}
        minRows={10}
        data={signatures}
        columns={columns}
        onFetchData={(page, size, searchString) => {
          fetchData(page, size, getSearch(searchString), sorting)
        }}
        defaultSorted={sorting}
        pages={table.lastPage}
        pageSize={table.perPage}
        onPaginationChange={setPagination}
        pagination={pagination}
        showPagination
        defaultPageSize={50}
        onRowClick={(signature) => navigate('/signatures/' + signature.id)}
        extraButtons={[
          <Button size={'sm'} color={'secondary'} onClick={() => {
            navigate('/signatures/new')
          }}>New Signature</Button>,
          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={() => fetchData(pagination.pageIndex + 1, pagination.pageSize, '', sorting)}/>
        ]}
        withServerSideSorting
        onSorting={(sorting, searchString) => onSorting(sorting, searchString)}
      />
    </>
  )
}

export default SignaturesTable
