import { defaultErrorFeedback } from './feedback';
import {
  api_createPartnership, api_deletePartnership,
  api_getPartnership
} from '../utils/Partnerships/PartnershipsService';

export const PARTNERSHIP_SET_DATA = 'PARTNERSHIP_SET_DATA';
export const PARTNERSHIP_RESET_DATA = 'PARTNERSHIP_RESET_DATA';
export const PARTNERSHIP_UPDATE_FIELD = 'PARTNERSHIP_UPDATE_FIELD';

export function setPartnershipData(data) {
  return {
    type: PARTNERSHIP_SET_DATA,
    data
  };
}

export const getPartnershipData = (accountId, customerId, include = []) => {
  return (dispatch) => {
    return api_getPartnership(accountId, customerId, include).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setPartnershipData(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    )
  }
}

export const resetPartnership = () => {
  return {
    type: PARTNERSHIP_RESET_DATA
  }
}

export const updatePartnershipFormField = (field, value) => {
  return {
    type: PARTNERSHIP_UPDATE_FIELD,
    field,
    value
  };
}

export const createPartnership = (accountId, data, include = []) => {
  return (dispatch) => {
    return api_createPartnership(accountId, data, include).then(
      (result) => {
        if (result.status === 200) {
          dispatch(setPartnershipData(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return false;
      }
    )
  }
}

export const deletePartnership = (accountId, customerId) => {
  return (dispatch) => {
    return api_deletePartnership(accountId, customerId).then(
      (result) => {
        if (result.status !== 204) {
          dispatch(defaultErrorFeedback());
        }
        return result.status === 204;
      }
    )
  }
}
