import { CreditRequestEnums } from '../../utils/Constants/CreditRequest';
import { Badge } from 'reactstrap';
import React from 'react';

const getBadgeColor = (status) => {
  switch (status) {
    case CreditRequestEnums.status.NOT_STARTED:
      return 'secondary';
    case CreditRequestEnums.status.APPROVED:
      return 'success';
    case CreditRequestEnums.status.PENDING:
      return 'warning';
    default:
      return 'danger';
  }
}

const CreditRequestForm = (amendable, getStatusLabel, getReasonLabel,getUserLabel) => {
  return {
    general : {
      status: {
        label: 'Status',
        type: 'custom', callBack: (key, field, data) => <h5>
          <Badge className={`p-2 faded-${getBadgeColor(data.status)}`}>{getStatusLabel() ?? ''}</Badge>
        </h5>
      },
      company: {
        label: 'Customer',
        type: 'text',
        plaintext: true,
        callBack: (key, field, data) => data[key] !== undefined && data[key] !== null ? data[key].name : '',
      },
      subject: {
        label: 'Subject',
        type: 'text',
        mandatory: true,
        plaintext: (key, field, data) => !amendable,
      },
      amount: {
        label: 'Amount',
        type: 'text',
        mandatory: true,
        plaintext: (key, field, data) => !amendable,

      },
      authorisedBy: {
        label: 'Authorised By',
        type: 'select',
        plaintext: (key, field, data) => !amendable,
        mandatory: true,
        callBack: () => getUserLabel('authorisedBy'),
      },
      reason: {
        label: 'Reason',
        type: 'select',
        mandatory: true,
        plaintext: !amendable,
        callBack: () => getReasonLabel() ?? '',
      },
      description: {
        label: 'Description',
        type: 'textarea',
        minRows: 10,
        width: 12,
        mandatory: true,
        plaintext: (key, field, data) => !amendable,

      }
    }
  }
}

export default CreditRequestForm