const routes = {
  '/': 'Home',
  '/dashboard': 'Dashboard',
  '/global-search': 'Global Search',
  '/billing': 'Billing',
  '/billing/accounts': 'Accounts',
  '/billing/reports': 'Reports',
  '/accounts': 'Accounts',
  '/circuits': 'Circuits',
  '/toolbox': 'NOC Toolbox',
  '/toolbox/dcheck': 'Device Checker',
  '/toolbox/dns': 'DNS',
  '/toolbox/prefixlists': 'Prefix Lists',
  '/toolbox/iptable': 'IP Table',
  '/toolbox/maps': 'Weather Maps',
  '/toolbox/exttraffic': 'External Traffic',
  '/toolbox/alarms': 'Alarms',
  '/toolbox/status': 'Status Site',
  '/toolbox/realtime': 'Realtime Traffic',
  '/toolbox/coreconfig': 'Core Config Checker',
  '/toolbox/configtemplate': 'Config Templates',
  '/toolbox/coresnap': 'Core Snapshots',
  '/toolbox/radius': 'Radius',
  '/products': 'Products',
  '/products/groups': 'Groups',
  '/products/templates': 'Templates',
  '/products/pricelists': 'Price Lists',
  '/products/pricing': 'Pricing',
  '/products/slas': 'SLAs',
  '/service-delivery': 'Service Delivery',
  '/service-delivery/wip': 'WIP',
  '/service-delivery/loa': 'LOA Generator',
  '/service-delivery/lead-times': 'Lead Times Report',
  '/service-delivery/orders': 'Orders',
  '/reports': 'Reports',
  '/reports/circuitrecon': 'Circuit Reconciliation',
  '/signatures': 'Signatures',
  '/developers': 'Developers',
  '/developers/logs': 'Logs',
  '/developers/queues': 'Queues',
  '/sales': 'Sales',
  '/sales/leads': 'Leads',
  '/sales/opportunities': 'Opportunities',
  '/sales/orders': 'Orders',
  '/sales/pricing-tool': 'Pricing Tool',
  '/sales/reports': 'Sales Reports',
  '/compliance': 'Compliance',
  '/compliance/security': 'Security Scans',
  '/compliance/services': 'Services',
  '/compliance/configbackup': 'Config Backups',
  '/compliance/shadowserver': 'Shadow Server',
  '/discovery': 'Discovery',
  '/sdb': 'Service Database',
  '/sdb/vendors': 'Vendors',
  '/sdb/racks': 'Racks',
  '/sdb/device-deployments': 'Device Deployments',
  '/sdb/device-models': 'Device Models',
  '/sdb/nnis': 'NNIs',
  '/sdb/optical-systems': 'Optical Systems',
  '/sdb/connections': 'Connections',
  '/sdb/stock': 'Stock',
  '/sdb/licenses': 'Licenses',
  '/sdb/carrier-circuits': 'Carrier Circuits',
  '/sdb/cross-connects': 'Cross Connects',
  '/sdb/ipam': 'IPAM',
  '/sdb/ipam/domains': 'Domains',
  '/sdb/ipam/blocks': 'Blocks',
  '/shifttracking': 'Shift Tracking',
};
export default routes;
