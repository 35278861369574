const CrossConnectComponentForm = (types) => ({
  id: {
    label: 'ID',
    type: 'text',
    plaintext: true,
    width: 12,
    show: (field, data) => data?.id
  },
  name: {
    label: 'Name',
    type: 'text',
    plaintext: true,
    width: 12
  },
  supplier: {
    label: 'Supplier',
    type: 'text',
    plaintext: true,
    callBack: (key, field, data) => data.supplier?.name,
    width: 12
  },
  type: {
    label: 'Type',
    type: 'text',
    plaintext: true,
    width: 6
  },
});

export default CrossConnectComponentForm;