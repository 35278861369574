import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { initBGP } from '../../actions/BGP';

const HeaderAlerts = () => {
  const dispatch = useDispatch();

  const lsdbRefresh = useCallback(() => {
    dispatch(initBGP());
  }, [dispatch]);

  const { zabbixAlarms, socketioStatus, appVersion, BGP } = useSelector(state => ({
    zabbixAlarms: state.zabbixAlarms,
    socketioStatus: state.socketioStatus,
    appVersion: state.appVersion,
    BGP: state.BGP,
  }));

  const inMaint = useMemo(() => {
    let count = 0;
    for (let d in BGP.edges) {
      if (BGP.edges[d].teMetric === 54321) {
        count += 1;
      }
    }
    return count;
  }, [BGP.edges]);

  return (
    <div>
      {socketioStatus.status !== 'authenticated' && (
        <Alert color="danger" style={{ margin: 0, padding: '2px', paddingLeft: '30px' }}>
          <i className="icon-exclamation"></i> Socket server is disconnected. Some features may not work correctly. Please raise with Tamas/Dan ASAP!
        </Alert>
      )}
      {process.env.NODE_ENV === 'production' && appVersion.uiversion !== process.env.REACT_APP_GIT_SHA && (
        <Alert color="danger" style={{ margin: 0, padding: '2px', paddingLeft: '30px' }}>
          <i className="icon-exclamation"></i> Your UI version ({process.env.REACT_APP_GIT_SHA}) is outdated and may not behave correctly. Please log out and force a browser refresh to load the latest code ({appVersion.uiversion}).
        </Alert>
      )}
      {BGP.lastUpdate !== null && moment.duration(moment(new Date()).diff(moment(BGP.lastUpdate))).asSeconds() >= 300 && (
        <Alert color="danger" style={{ margin: 0, padding: '2px', paddingLeft: '30px' }}>
          <i className="icon-exclamation"></i> The BGP Link state DB has not been updated for over 5 minutes. <Link onClick={lsdbRefresh} to={'#'}>Force Refresh</Link>
        </Alert>
      )}
      {inMaint > 0 && (
        <Alert color="danger" style={{ margin: 0, padding: '2px', paddingLeft: '30px' }}>
          <i className="icon-exclamation"></i> There are {Math.ceil(inMaint / 2)} core links set in maintenance mode.
        </Alert>
      )}
      {zabbixAlarms.systemstatus.zabbix !== undefined && zabbixAlarms.systemstatus.zabbix.status === false && (
        <Alert color="danger" style={{ margin: 0, padding: '2px', paddingLeft: '30px' }}>
          <i className="icon-exclamation"></i> Zabbix Server appears to be down, this is bad! PANIC! PANIC! PANIC!
        </Alert>
      )}
    </div>
  );
};

export default HeaderAlerts;
