import ipRegex from 'ip-regex';

const Validator = {
  Ipam: {
    validIpv4: (address) => ipRegex.v4({exact: true}).test(address),
    validIpv6: (address) => ipRegex.v6({exact: true}).test(address),
    validMask: (networkAddress, mask) => {
      if (ipRegex.v4({exact: true}).test(networkAddress)) {
        return mask > 0 && mask <= 32;
      }
      if (ipRegex.v6({exact: true}).test(networkAddress)) {
        return mask > 0 && mask <= 128;
      }
      return false;
    }
  }
}
export default Validator;
