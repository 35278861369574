import React from 'react';

class Reports extends React.Component {
  render() {
    return (
            <div className="animated fadeIn">

            </div>
    );
  }
}

export default Reports;
