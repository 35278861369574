import {getShadowServerInfo, getShadowServerData as api_getShadowServerData} from "../utils/Compliance/ComplianceService";
import moment from 'moment';

export const SHADOWSERVER_SET_DATA = 'SHADOWSERVER_SET_DATA';
export const SHADOWSERVER_SET_LOADING = 'SHADOWSERVER_SET_LOADING';
export const SHADOWSERVER_SET_LOADED = 'SHADOWSERVER_SET_LOADED';
export const SHADOWSERVER_SET_DATE = 'SHADOWSERVER_SET_DATE';
export const SHADOWSERVER_SET_LAST_LOADED = 'SHADOWSERVER_SET_LAST_LOADED';


function setLatestDate(data) {
  return {
    type: SHADOWSERVER_SET_DATE,
    date: data.latest_date
  };
}

function setShadowServerData(data) {
  return {
    type: SHADOWSERVER_SET_DATA,
    data
  };
}

function setShadowServerLoading() {
  return {
    type: SHADOWSERVER_SET_LOADING
  };
}

function setShadowServerLoaded() {
  return {
    type: SHADOWSERVER_SET_LOADED
  };
}

function setShadowServerLastLoaded() {
  return {
    type: SHADOWSERVER_SET_LAST_LOADED,
    date: moment()
  };
}

export function getShadowServerData(ips) {
  return (dispatch) => {
    dispatch(setShadowServerLoading());
    return getShadowServerInfo().then((result) => {
      if (result.status === 200) {
        dispatch(setLatestDate(result.data));
        api_getShadowServerData(result.data.latest_date, ips).then((result) => {
          if (result.status === 200) {
            dispatch(setShadowServerData(result.data));
            dispatch(setShadowServerLoaded());
            dispatch(setShadowServerLastLoaded());
          }
        }).catch((error) => {
          dispatch(setShadowServerLoaded());
        });
      }
    });
  }
}
