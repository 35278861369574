import React from 'react';
import {Card, CardBody, Row, Col} from 'reactstrap';

const GroupAttributeBox = (props) => {
  return (
    <Card className="card-accent-secondary animated fadeIn">
      <CardBody>
        <h6>{props.title}</h6>
        <hr/>
        <Row>
          {
            props.data
              ? props.data.map((value, key) => {
                return (
                  <React.Fragment key={key}>
                    <Col md={3} sm={4}>
                      <p className="d-inline font-sm" key={key}>{value.Attribute}</p>
                    </Col>
                    <Col md={1} sm={1} className="p-0">
                      <p className="d-inline font-sm" key={key}>{value.op}</p>
                    </Col>
                    <Col md={7} sm={6}>
                      <p className="d-inline font-sm" key={key}>{value.Value}</p>
                    </Col>
                  </React.Fragment>
                );
              })
              : null
          }
        </Row>
      </CardBody>
    </Card>
  );
}

export default GroupAttributeBox;
