import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';
import isEmpty from 'lodash.isempty';

const AccountService = {
  get: (idOrNumber, include, withArr) => {
    const query = {
      include : include ?? ['primaryContact', 'legalSignOffUser'],
      with: withArr ? withArr.join(';') : ['primaryContact;legalSignOff']
    }
    return apiRequest({
      url: `/accounts/${idOrNumber}${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  create: (data) => {
    return apiRequest({
      url: `/accounts`,
      method: 'post',
      data
    })
      .then(
        resp => resp
      )
      .catch(
        error => error
      );
  },
  update: (idOrNumber, data, include, withArr) => {
    const query = {
      include : include ?? ['primaryContact', 'legalSignOffUser'],
      with: withArr ? withArr.join(';') : ['primaryContact;legalSignOff']
    }
    return apiRequest({
      url: `/accounts/${idOrNumber}${queryStringFromFields(query)}`,
      method: 'put',
      data
    })
      .then(
        resp => resp
      )
      .catch(
        error => error
      );
  },
  search: (searchString, activeOnly ) => {
    let query = {
      search:`name:${searchString};accountNumber:${searchString}`,
      searchFields: 'name:like;accountNumber:like',
      include: 'primaryContact',
      activeOnly: activeOnly || undefined
    }
    return apiRequest({
      url: `/accounts${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  sites: {
    list: (accountIdOrNumber, include = []) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/sites${queryStringFromFields({include})}`,
        method: 'get'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  partnerships: {
    list: (accountIdOrNumber, include = ['primaryContact']) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/partnerships${queryStringFromFields({include})}`,
        method: 'get'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  contacts: {
    list: (accountIdOrNumber, include = []) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/contacts${queryStringFromFields({include})}`,
        method: 'get'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  orders: {
    list: (accountIdOrNumber) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/orders?include=contact,owner,priceList,priceList.currency&with=contact;owner;priceList;priceList.currency`,
        method: 'get'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    },
    create: (accountIdOrNumber, data) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/orders?include=contact,owner&with=contact;owner`,
        method: 'post',
        data
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  services: {
    list: (accountIdOrNumber) => {
      let query = {
        with:'site;company;order;order.priceList;order.priceList.currency',
        include: ['company', 'site', 'order', 'order.priceList', 'order.priceList.currency']
      }
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/services${queryStringFromFields(query)}`,
        method: 'GET'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  opportunities: {
    list: (accountIdOrNumber) => {
      let query = {
        with:'priceList;priceList.currency',
        include: 'priceList,priceList.currency'
      }
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/opportunities${queryStringFromFields(query)}`,
        method: 'GET'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  bills: {
    list: (accountIdOrNumber) => {
      return apiRequest({
        url: '/accounts/' + accountIdOrNumber + '/bills',
        method: 'get'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  cases: {
    list: (accountIdOrNumber) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/cases?legacy=0`,
        method: 'get'
      })
        .then(
          resp => resp
        )
        .catch(
          error => error
        );
    }
  },
  configItems: {
    list: (accountIdOrNumber, activeOnly) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/configitems?include=assignedTo,location,manufacturer&activeOnly=${activeOnly ? '1' : '0'}`,
        method: 'get'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  circuits: {
    list: (accountIdOrNumber) => {
      return apiRequest({
        url: '/accounts/' + accountIdOrNumber + '/circuits',
        method: 'get'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  documents: {
    list: (accountIdOrNumber) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/documents`,
        method: 'get'
      })
        .then(
          resp => resp
        )
        .catch(
          error => error
        );
    },
    delete: (accountIdOrNumber, documentId) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/documents/${documentId}`,
        method: 'delete'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  logs: {
    list: (accountIdOrNumber) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/logs`,
        method: 'get'
      })
        .then(
          resp => resp
        )
        .catch(
          error => error
        );
    }
  },
  creditRequests: {
    list: (accountIdOrNumber) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/credit-requests`,
        method: 'get'
      })
        .then(
          resp => resp
        )
        .catch(
          error => error
        );
    },
    create: (accountIdOrNumber, data) => {
      return apiRequest({
        url: `/accounts/${accountIdOrNumber}/credit-requests?include=company,invoices,products,products.product`,
        method: 'post',
        data
      })
        .then(
          resp => resp
        )
        .catch(
          error => error
        );
    },
    get: (id) => {
      return apiRequest({
        url: `/credit-requests/${id}?include=company,products,products.product,invoices,tasks,tasks.assignedTo`,
        method: 'get'
      })
        .then(
          resp => resp
        )
        .catch(
          error => error
        );
    },
    update: (id, data) => {
      return apiRequest({
        url: `/credit-requests/${id}?include=company,products,products.product,invoices,tasks,tasks.assignedTo`,
        method: 'put',
        data
      })
        .then(
          resp => resp
        )
        .catch(
          error => error
        );
    },
    authorise: (id) => {
      return apiRequest({
        url: `/credit-requests/${id}/authorise?include=company,products,products.product,invoices,tasks,tasks.assignedTo`,
        method: 'put'
      })
        .then(
          resp => resp
        )
        .catch(
          error => error
        );
    },
    cancel: (id) => {
      return apiRequest({
        url: `/credit-requests/${id}/cancel?include=company,products,products.product,invoices,tasks,tasks.assignedTo`,
        method: 'put'
      })
        .then(
          resp => resp
        )
        .catch(
          error => error
        );
    }
  },
  cache: (idOrNumber) => {
    return apiRequest({
      url: '/accounts/' + idOrNumber + '/cache',
      method: 'put'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  signatures: {
    list: (idOrNumber, page, pageSize, searchString, sorting) => {
      let query = {
        search: searchString,
        include: 'items.owner,items.company,items.relatedTo,items.signatories',
        with:'owner;company;relatedTo;signatories',
        sortedBy: !isEmpty(sorting) ? (sorting[0].desc ? 'desc' : 'asc') : null,
        orderBy: !isEmpty(sorting) ? sorting[0].id : null,
        page,
        pageSize
      }
      if(!searchString){
        delete query.search
      }
      return apiRequest({
        url: `/accounts/${idOrNumber}/signatures${queryStringFromFields(query)}`,
        method: 'get'
      }).then(
        resp => resp
      ).catch(
        error => error
      );
    }
  },
  invite: (accountId, contactId) => {
    return apiRequest({
      url: '/accounts/' + accountId + '/activate',
      method: 'post',
      data: { contactId }
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}

export default AccountService