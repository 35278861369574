import React, { useEffect, useRef, useState } from 'react';
import {
  api_getServiceCheck,
  api_getServiceErrors,
  api_runServiceCheck
} from '../../utils/Compliance/ComplianceService';
import { connect } from 'react-redux';
import { defaultErrorFeedback } from '../../actions/feedback';
import FilteredTable from '../../components/FilteredTable';
import { Link } from 'react-router-dom';
import keyBy from 'lodash.keyby';
import {
  Card,
  CardBody, Spinner
} from 'reactstrap';
import ExtraButtons from '../../utils/Helpers/ExtraButtons';
import LoadingOverlay from '../../components/LoadingOverlay';
import { joinRooms, removeProgress } from '../../actions/socketio';
import { addedDiff, updatedDiff } from 'deep-object-diff';

const AccountService = (props) => {
  const {
    dispatch,
    progressBars
  } = props;
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState({});
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState({});
  const prevProgressBars = useRef({});
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const diffed = addedDiff(prevProgressBars.current, progressBars);
    const service = Object.keys(services)
      .find(productId => {
        return Boolean(diffed[`service-check-${productId}`]);
      });
    if (service) {
      getService(service);
    }
    prevProgressBars.current = { ...progressBars };
  }, [progressBars]);

  const fetchData = () => {
    setLoading(true);
    api_getServiceErrors()
      .then((result) => {
        if (result.status === 200) {
          setServices(keyBy(result.data, 'productId'));
          if (search) {
            filter(search, keyBy(result.data, 'productId'));
          }
        } else {
          props.dispatch(defaultErrorFeedback());
        }
        setLoading(false);
      });
  };

  const runService = (id) => {
    setServices({
      ...services,
      [id]: {
        ...services[id],
        status: 'running'
      }
    });
    if (search) {
      setFiltered((prevState => ({
        ...prevState,
        [id]: {
          ...services[id],
          status: 'running'
        }
      })));
    }
    api_runServiceCheck(id)
      .then(result => {
        if (result.status === 200) {
          props.dispatch(joinRooms({ rooms: [`service-check-${id}`] }));
        } else {
          props.dispatch(defaultErrorFeedback());
        }
      });
  };

  const getService = (id) => {
    api_getServiceCheck(id)
      .then(result => {
        if (result.status === 200) {
          setServices((prevState => ({
            ...prevState,
            [id]: result.data
          })));
          if (search) {
            setFiltered((prevState => ({
              ...prevState,
              [id]: result.data
            })));
          }
        } else if (result.status === 404) {
          setServices((prevState => ({
            ...prevState,
            [id]: {
              ...prevState[id],
              status: 'ok',
              error: ''
            }
          })));
          if (search) {
            setFiltered((prevState => ({
              ...prevState,
              [id]: {
                ...prevState[id],
                status: 'ok',
                error: ''
              }
            })));
          }
        } else {
          setFiltered((prevState => ({
            ...prevState,
            [id]: {
              ...prevState[id],
              status: null
            }
          })));
          props.dispatch(defaultErrorFeedback());
        }
        dispatch(removeProgress(`service-check-${id}`));
      });
  };

  const filter = (searchString, useServices) => {
    setSearch(searchString);
    const result = Object.keys(useServices || services)
      .reduce((result, key) => {
        let service = { ...services[key] };
        if (String(service.accountName)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
          || String(service.productNumber)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
          || String(service.productName)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
          || String(service.error)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1) {
          result[key] = service;
        }
        return result;
      }, {});
    setFiltered(result);
  };

  const columns = [
    {
      filterable: false,
      sortable: true,
      Header: 'Account',
      accessor: 'accountName'
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Service',
      accessor: 'productName',
      minWidth: 200,
      Cell: props => {
        return <a href={props.original.productUrl} target="_blank" rel="noreferrer"
                  className={'text-primary'}>{props.value}</a>;
      }
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Product Code',
      accessor: 'productNumber',
      Cell: props => {
        return <a href={props.original.productUrl} target="_blank" rel="noreferrer"
                  className={'text-primary'}>{props.value}</a>;
      }
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Error',
      accessor: 'error'
    },
    {
      filterable: false,
      sortable: false,
      Header: '',
      accessor: 'productId',
      width: 50,
      Cell: (props) => {
        if (props.original.status === 'ok') {
          return <span className={'text-success'}>OK</span>;
        }
        if (props.original.status === 'running') {
          return <Spinner size={'sm'} type="grow" color="primary"/>;
        }
        return <span className={'text-info hoverPointer'} onClick={() => runService(props.value)}><i
          className='icon-refresh'/>
          </span>;
      }
    }
  ];
  return (

    <div className={'animated fadeIn'}>
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <FilteredTable
              onChange={event => filter(event.target.value)}
              showPagination={Object.keys(services).length > 20}
              data={search ? Object.values(filtered) : Object.values(services)}
              columns={columns}
              extraButtons={ExtraButtons({ refresh: () => {fetchData();} })}
            />
          </LoadingOverlay>

        </CardBody>
      </Card>

    </div>
  );

};

function mapStateToProps({
  socketioProgress
}) {
  return {
    progressBars: socketioProgress.progressBars
  };
}

export default connect(mapStateToProps)(AccountService);
