import { Badge, Button } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import { Link, useNavigate } from 'react-router-dom'
import React from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import { ButtonIcon } from '../../ButtonIcon';

const getTypeColour = (type) => {
  switch (type){
    case 'copper': return 'primary'
    case 'smf': return 'secondary'
    case 'mmf': return 'warning'
    default:
      return 'info'
  }
}

const CrossConnectsTable = ({
  excludeColumns,
  includeColumns,
  connect,
  minRows,
  crossConnects,
  createNew,
  fetchData,
  noRowClick,
  closeModal
}) => {

  const navigate = useNavigate();

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
      minSize: 30
    },
    {
      header: 'Name',
      accessorKey: 'name',
      minSize: 300
    },
    {
      header: 'Type',
      accessorKey: 'type',
      cell: (props) =>
        <Badge className={`p-2 faded-${getTypeColour(props.getValue())}`}>
          {props.getValue()}
        </Badge>,
    },
    {
      header: 'Supplier',
      accessorKey: 'supplier',
      cell: props => props.getValue()?.name ?? ''
    },
    {
      header: 'Supplier Ref',
      accessorKey: 'reference',
    },
    {
      enableSorting: false,
      header: 'Connection',
      accessorKey: 'component',
      cell: (props) => {
        if (!isEmpty(props.getValue()?.connection)) {
          return (
            <span onClick={e => e.stopPropagation()}>
            <Link to={`/sdb/connections/${props.row.original.component?.connection?.id}`}>
              <Badge color="success">{props.getValue().connection.name}</Badge>
            </Link>
            </span>
          );
        } else if(connect){
          return <Button size={'sm'} color="primary" onClick={(event) => {
            event.stopPropagation()
            connect(props.row.original)
          }}>Connect</Button>;
        } else {
          return <Badge color="secondary">Disconnected</Badge>;
        }
      }
    }
  ];
  const getColumns = () => {
    if(excludeColumns){
      return  columns.filter(column => !excludeColumns.includes(column.accessorKey))
    }
    return includeColumns ? columns.filter(column => includeColumns.includes(column.accessorKey)) : columns
  }

  const getButtons = () => {
    const buttons  = []

    if(createNew){
      buttons.push(
        <Button
          size={'sm'}
          color={'secondary'}
          onClick={() => navigate(`/sdb/cross-connects/new`)}
        >New Cross Connect</Button>
      )
    }

    buttons.push(<ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>)

    if(closeModal){
      buttons.push( <ButtonIcon onClick={closeModal} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>)
    }

    return buttons;
  }


  return (
    <FilteredTableV2
      noDataText={'No Cross Connects'}
      withFilter={'includesString'}
      minRows={minRows ?? 20}
      data={crossConnects}
      columns={getColumns()}
      showPagination={crossConnects.length > (minRows ?? 20)}
      defaultPageSize={20}
      onRowClick={(circuit) => !noRowClick ? navigate('/sdb/cross-connects/' + circuit.uuid) : () => {}}
      extraButtons={getButtons()}
    />
  )
}

export default CrossConnectsTable
