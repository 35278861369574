import { api_getActivity } from '../utils/Activity/ActivityService';
import { defaultErrorFeedback } from './feedback';

export const ACTIVITY_SET_DATA = 'ACTIVITY_SET_DATA';

export const getActivity = (id) => {
  return (dispatch) => {
    return api_getActivity(id)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch({
              type: ACTIVITY_SET_DATA,
              data: result.data
            });
            return result.data;
          }
          dispatch(defaultErrorFeedback());
          return false;
        }
      );
  };
};
