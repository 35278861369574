import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import { Alert, Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import ReactTable from 'react-table-v6';
import { deactivateMandate, DIRECT_DEBITS_LOADING_TABLE, DIRECT_DEBITS_RESET_AUDDIS, DIRECT_DEBITS_RESET_NEW_MANDATE, getMandates } from '../../actions/directDebits';
import DirectDebitsNewMandate from './DirectDebitsNewMandate';
import { setConfirmDialog } from '../../actions/dialogs';
import DirectDebitsAuddis from './DirectDebitsAuddis';

class DirectDebits extends React.Component {
    static contextTypes = {
      router: PropTypes.shape({
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
          replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
      }).isRequired
    };

    constructor(props) {
      super(props);
      this.state = {
        showNewMandateModal: false,
        showAuddisModal: false
      };
    }

    componentDidMount() {
      const { mandates } = this.props.directDebits;
      if (Object.keys(mandates).length === 0) {
        this.getDirectDebits();
      }
    }

    getDirectDebits = () => {
      this.props.dispatch({ type: DIRECT_DEBITS_LOADING_TABLE, loading: true });
      this.props.dispatch(getMandates()).then(() => {
        this.props.dispatch({ type: DIRECT_DEBITS_LOADING_TABLE, loading: false });
      });
    }

    reloadDirectDebits = () => {
      this.getDirectDebits();
    }

    toggleNewMandateModal = () => {
      this.props.dispatch({ type: DIRECT_DEBITS_RESET_NEW_MANDATE });
      this.setState({ showNewMandateModal: !this.state.showNewMandateModal });
    }

    toggleAuddisModal = () => {
      this.setState({ showAuddisModal: !this.state.showAuddisModal });
    }

    deactivateMandate = (mandateId) => {
      this.props.dispatch({ type: DIRECT_DEBITS_LOADING_TABLE, loading: true });
      this.props.dispatch(setConfirmDialog({
        color: 'danger',
        text: 'This action will also remove any CRM Bill association to this mandate',
        proceed: () => this.props.dispatch(deactivateMandate(mandateId)),
        cancel: () => this.props.dispatch({ type: DIRECT_DEBITS_LOADING_TABLE, loading: false })
      }));
    }

    render() {
      const { loadingTable, mandates, newMandate } = this.props.directDebits;
      return (
            <Loadable animate={false} active={loadingTable} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
            <div style={{ minHeight: '400px' }}>
                <Row className="mb-2">
                    <Col className="d-flex">
                        <Button className="d-flex align-self-start" size={'sm'} color={'primary'} onClick={this.toggleNewMandateModal}>New Mandate</Button>
                        <Button className="d-flex align-self-start ml-2" size={'sm'} color={'primary'} onClick={this.toggleAuddisModal}>AUDDIS</Button>
                        <div className="h4 text-primary mb-0 ml-auto d-flex " style={{ cursor: 'pointer' }} onClick={this.reloadDirectDebits}><i className="icon-refresh"></i></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {Object.keys(mandates).length > 0
                        && <DirectDebitsTable mandates={mandates} deactivate={this.deactivateMandate}/>
                        }
                    </Col>
                </Row>
                <div>
                    <Modal isOpen={this.state.showNewMandateModal} toggle={this.toggleNewMandateModal} className="modal-lg" backdrop={'static'}>
                        <ModalHeader toggle={this.toggleNewMandateModal}>
                            Create New Mandate
                        </ModalHeader>
                        <ModalBody>
                            {newMandate.created
                                && <Alert color={'success'}>Mandate was successfully created!</Alert>
                            }
                            <DirectDebitsNewMandate/>
                        </ModalBody>
                    </Modal>
                </div>
                <div>
                    <Modal isOpen={this.state.showAuddisModal} toggle={this.toggleAuddisModal} className="modal-lg" backdrop={'static'}>
                        <ModalHeader toggle={this.toggleAuddisModal}>
                            Generate AUDDIS Report
                        </ModalHeader>
                        <ModalBody>
                            <DirectDebitsAuddis/>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </Loadable>
      );
    }
}

const DirectDebitsTable = ({ mandates, deactivate }) => {
  const mandatesArray = Object.values(mandates);
  const columns = [
    {
      Header: 'Account',
      accessor: 'accountName'
    },
    {
      Header: 'Sort Code',
      accessor: 'accountSortCode'
    }, {
      Header: 'Account No',
      accessor: 'accountNumber'
    }, {
      Header: 'Mandate Ref',
      accessor: 'mandateRef'
    }, {
      Header: '',
      accessor: 'id',
      sortable: false,
      filterable: false,
      Cell: props => (
                <div className={'h5 mb-0 text-center'}>
                    <i className="icon-ban dd-action" onClick={() => deactivate(props.value)}></i>
                </div>
      )
    }
  ];

  return (
        <ReactTable
            className="animated fadeIn -highlight"
            NoDataComponent={() => null}
            data={mandatesArray}
            columns={columns}
            minRows="2"
            filterable={true}
            defaultPageSize={10}
            getTheadProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadTrProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadThProps={() =>{
              return {
                style: { border: 'none', whiteSpace: 'unset' }
              };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                style: { whiteSpace: 'unset', fontSize: '10pt' }
                /* onClick: (e, handleOriginal) => {
                        if(!e.target.href && rowInfo){
                            showBillModal(rowInfo.original.id)
                        }
                        if (handleOriginal) {
                            handleOriginal();
                        }
                    } */
              };
            }}
            sortable={true}
            defaultSorted={[{ id: 'name', desc: false }]}
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
        />
  );
};

function mapStateToProps({ directDebits }) {
  return {
    directDebits
  };
}
export default connect(mapStateToProps)(DirectDebits);
