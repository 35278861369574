export const PriceListForms = {
  new : {
    name: {
      label: 'Name',
      type: 'text',
      mandatory: true
    },
    type: {
      label: 'Type',
      type: 'select',
      mandatory: true
    },
    currency: {
      label: 'Currency',
      type: 'select',
      mandatory: true
    },
    company: {
      label: 'Account',
      type: 'select-async',
      show: (field, data) => data.type?.name === 'Portal'
    },

  },
  draft : {
    name: {
      label: 'Name',
      type: 'text',
      mandatory: true
    },
    type: {
      label: 'Type',
      type: 'text',
      plaintext: true,
      callBack: (key, field, data) => data.type?.name
    },
    currency: {
      label: 'Currency',
      type: 'select',
      mandatory: true
    },
    company: {
      label: 'Account',
      type: 'text',
      readOnly: true,
      plaintext: true,
      show: (field, data) => data.type?.name === 'Portal',
      callBack: (key, field, data) => data.company?.name,
    }
  },
  readOnly: {
    name: {
      label: 'Name',
      type: 'text',
      readOnly: true,
      plaintext: true
    },
    type: {
      label: 'Type',
      type: 'text',
      readOnly: true,
      plaintext: true,
      callBack: (key, field, data) => data.type?.name
    },
    currency: {
      label: 'Currency',
      type: 'text',
      readOnly: true,
      plaintext: true,
      callBack: (key, field, data, optionSets) => optionSets.currency.options.find(currency => currency.value === data.currency)?.label ?? ''

    },
    company: {
      label: 'Account',
      type: 'text',
      readOnly: true,
      plaintext: true,
      callBack: (key, field, data) => data.company?.name,
      show: (field, data) => data.type?.name === 'Portal'
    }
  }
};
