import {
  LOGS_SET_ERROR,
  LOGS_SET_LIST,
  LOGS_SET_LOG,
  LOGS_SET_SEARCH_STRING,
  LOGS_TOGGLE_LOG_MODAL
} from '../actions/logs';
import moment from 'moment';

const initialState = {
  loading: false,
  list: [],
  filteredList: [],
  log: {},
  showLogModal: false,
  error: null,
  searchString: ''
};

export default function logs(state = initialState, action) {
  switch (action.type) {
    case LOGS_SET_LIST:
      return {
        ...state,
        list: [...action.data]
      };
    case LOGS_SET_LOG:
      return {
        ...state,
        log: action.log
      };
    case LOGS_TOGGLE_LOG_MODAL:
      return {
        ...state,
        showLogModal: !state.showLogModal
      };
    case LOGS_SET_ERROR:
      return {
        ...state,
        list: [...state.list, action.data]
      };
    case LOGS_SET_SEARCH_STRING:
      const filtered = state.list.filter((log) => {
        return String(log.data.context.url)
            .toLowerCase()
            .indexOf(action.searchString.toLowerCase()) !== -1
          || String(log.data.message)
            .toLowerCase()
            .indexOf(action.searchString.toLowerCase()) !== -1;
      });
      return {
        ...state,
        searchString: action.searchString,
        filteredList: filtered
      };
    default:
      return state;
  }
}
