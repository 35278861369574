import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import FilteredTableV2 from '../../FilteredTableV2';
import { SelectMod } from '../../Selects/SelectMod';
import { Button } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { ButtonIcon } from '../../ButtonIcon';
import omit from 'lodash.omit';
import HeadlessModal from '../../Modals/HeadlessModal';
import Case from '../../../views/Case';
import { connect } from 'react-redux';
import { isServiceDelivery } from '../../../utils/Auth/AuthService';

const stateOptions = [
  {label: 'All', value: null},
  {label: 'Active', value: 0},
  {label: 'Resolved', value: 1},
  {label: 'Canceled', value: 2}
]
const CasesTable = ({
  cases,
  defaultSorted,
  fetchData,
  withNew,
  relatedTo,
  onCreated,
  optionSets,
  minRows,
  user
}) => {

  const [showCaseModal, setShowCaseModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [stateFilter, setStateFilter] = useState({label: 'Active', value: 0});
  const onStateFilterChange = (selected, tableProps) => {
    tableProps.column.setFilterValue(selected.value)
    setStateFilter(selected)
  };
  const toggleCaseModal = () => setShowCaseModal(prevState => !prevState)
  const showCase = (theCase) => {
    setSelectedCase(theCase)
    toggleCaseModal(true)
  }

  const columns = [
    { header: 'Case Number',
      accessorKey: 'caseId',
      maxSize: 120,
      cell: props => {
        return <a href={props.row.original.crmUrl} target="_blank" className={'text-primary'}>{props.getValue()}</a>;
      } },

    { header: 'Title',
      accessorKey: 'title',
      minSize: 400,
      cell: (props) => {
        return <>
                    <div data-tip={props.getValue()}>
                        {props.getValue()}
                    </div>
                    <ReactTooltip/>
                </>;
      } },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: props => optionSets.status.options.find(option => option.value === props.getValue())?.label ?? ''
    },
    {
      header: 'Owner',
      accessorKey: 'owner',
      cell: props => props.getValue()?.name ?? ''
    },
    { header: (props) => {
        return <SelectMod
          options={stateOptions}
          isSearchable
          value={stateFilter}
          onChange={(selected) => onStateFilterChange(selected, props)}
          size={'sm'}
        />
      },
      accessorKey: 'state',
      enableSorting: false,
      filterFn: (row, columnId, value) => {
        return row.original.state === value || value === null
      },
      cell: props => stateOptions.find(option => option.value === props.getValue())?.label ?? ''
    },
    { header: 'Created', accessorKey: 'createdOn' },
    { header: 'Modified', accessorKey: 'modifiedOn' }
  ];

  const getExportData = () => {
    return cases.map(theCase => {
      return {
        ...omit(theCase, ['id'])
      }
    })
  }

  const getButtons = () => {
    const defaultButtons = [
      <div data-for={'export'} data-tip={'export'} >
        <CSVLink id={'export'}
                 data={getExportData()} filename={`circuits-${moment().format('DD-MM-YYYY')}.csv`}
                 style={{ textDecoration: 'none' }}>

          <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
        </CSVLink>
      </div>,

      <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
    ]
    if(withNew){
        defaultButtons.unshift(<Button size={'sm'} color={'secondary'} onClick={() => showCase(null)}>New Case</Button>)
    }

    return defaultButtons;
  }

  const getRelated = () => {
    if(relatedTo?.type === 'order'){
      return {order: relatedTo.data}
    }

    return {}
  }
  return (
    <>
      <FilteredTableV2
        defaultFilterValues={ [
          {
            id: "state",
            value: 0
          }
        ]}
        withFilter={'includesString'}
        columns={columns}
        minRows={minRows ?? 10}
        data={cases}
        showPagination={cases.length > 20}
        pageSizeOptions={[20, 50, 100, 200]}
        onRowClick={theCase => showCase(theCase)}
        noDataText={'No Cases'}
        defaultSorted={defaultSorted || [{
          id: 'createdOn',
          desc: true
        }]}
        extraButtons={getButtons()}

      />
      <HeadlessModal
        size={'xlg'}
        open={showCaseModal}
        toggle={toggleCaseModal}
      >
        <Case serviceDelivery={isServiceDelivery(user.permissions)} id={selectedCase?.caseId} {...getRelated()} toggleModal={toggleCaseModal} onCreated={onCreated}/>
      </HeadlessModal>
    </>
  );
};
const mapStateToProps = ({
  helpers,
  authenticationState
}) => ({
  user: authenticationState.account,
  optionSets: helpers.optionSets.case
})

export default connect(mapStateToProps)(CasesTable)