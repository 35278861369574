import { SET_CONFIRM_CANCELLED, SET_CONFIRM_DIALOG, SET_CONFIRM_PROCEED, SET_DIALOGUE_CANCELLED, SET_DIALOGUE } from '../actions/dialogs';

const initialState = {
  confirm: {
    color: 'danger',
    text: '',
    open: false
  },
  dialogue: {
    color: 'primary',
    text: '',
    title: '',
    open: false
  }
};

export default function dialogs(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIRM_DIALOG:
      return {
        ...state,
        confirm: { ...action.dialog, open: true }
      };
    case SET_CONFIRM_CANCELLED:
      return {
        ...state,
        confirm: initialState
      };
    case SET_CONFIRM_PROCEED:
      let confirm = Object.assign({}, state.confirm);
      confirm.proceed = true;
      return {
        ...state,
        confirm
      };
    case SET_DIALOGUE:
      return {
        ...state,
        dialogue: {
          ...action.dialogue, open: true
        }
      };
    case SET_DIALOGUE_CANCELLED:
      return {
        ...state,
        dialogue: { ...initialState.dialogue, open: false }
      };

    default:
      return state;
  }
}
