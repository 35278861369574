import { RackService } from '../utils/ServiceDB/Rack';
import { defaultErrorFeedback } from './feedback';

export const RACKS_SET_DATA = 'RACKS_SET_DATA';

function setRacks(data) {
  return {
    type: RACKS_SET_DATA,
    data
  };
}

export function getRacks(page, pageSize, searchString, sorting) {
  return (dispatch) => {
    return RackService.list(page, pageSize, searchString, sorting).then((result) => {
      if (result.status === 200) {
        dispatch(setRacks(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}