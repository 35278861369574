import numeral from 'numeral';
import * as moment from 'moment/moment';
import React, { useState } from 'react';
import FilteredTableV2 from '../../FilteredTableV2';
import ExtraButtons from '../../../utils/Helpers/ExtraButtons';
import { Button, Col, Row } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { ButtonIcon } from '../../ButtonIcon';
import Order from '../../../views/Order';
import OrderProduct from '../../../views/OrderProduct';
import Account from '../../../views/Account';
import HeadlessModal from '../../Modals/HeadlessModal';
import TableCellHighlightOnHover from '../../TableCellHighlightOnHover';

const entityInitialState = {type: '', id: ''}

const BillingWipTable = ({
  items,
  fetchData
}) => {
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [entity, setEntity] = useState(entityInitialState);
  const toggleEntityModal = () => setShowEntityModal(prevState => !prevState)
  const handleEntityClick = (entity) => {
    setEntity(entity)
    toggleEntityModal()
  }

  const getEntityComponent = () => {
    if (entity.type === 'order') {
      return <Order id={entity.id} closeModal={toggleEntityModal}/>
    } else if (entity.type === 'product') {
      return <OrderProduct
        closeModal={toggleEntityModal}
        id={entity.id}
      />
    } else if (entity.type === 'account') {
      return <Account id={entity.id} toggleModal={toggleEntityModal} tab={'info'} />
    }
    return ''
  }
  const columns = [
    {
      header: 'Account',
      accessorKey: 'account',
      minSize: 150,
      cell: props => {
        return <div
          onClick={() => handleEntityClick({
              id: props.row.original.accountId,
              type: 'account',
              size: 'xxlg'
            }
          )}
        >
          <TableCellHighlightOnHover value={props.getValue()}/>
        </div>
      }
    },
    {
      header: 'Account Type',
      accessorKey: 'accountType'
    },
    {
      header: 'Order Number',
      accessorKey: 'orderNumber',
      minSize: 120,
      cell: props => {
        return <div
          onClick={() => handleEntityClick({
            id: props.row.original.orderId,
            type: 'order',
            size: 'xxlg'
          })}
        >
          <TableCellHighlightOnHover value={props.getValue()}/>
        </div>
      }
    },
    {
      header: 'Product',
      accessorKey: 'lineItem',
      minSize: 300,
      cell: props => {
        return <div
          onClick={() => handleEntityClick({
            id: props.row.original.lineItemId,
            type: 'product'
          })}
        >
          <TableCellHighlightOnHover value={props.getValue()}/>
        </div>
      }
    },
    {
      header: 'Charge Type',
      accessorKey: 'typeOfCharge'
    },
    {
      header: 'Charge',
      accessorKey: 'currentCost',
      cell: props => (
        '£' + numeral(props.getValue()).format('0,0.00')
      )
    },
    {
      header: 'Billing Cycle',
      accessorKey: 'billingCycle'
    },
    {
      header: 'Go Live Date',
      accessorKey: 'goLiveDate',
    }
  ];


  return (
    <>
      <FilteredTableV2
        withFilter={'includesString'}
        data={items}
        columns={columns}
        minRows={20}
        showPagination
        defaultPageSize={50}
        striped
        extraButtons={ExtraButtons({
          others: [
            <div data-for={'export'} data-tip={'export'} >
              <CSVLink id={'export'}
                       data={[...items]} filename={`billing-wip-${moment().format('DD-MM-YYYY')}.csv`}
                       style={{ textDecoration: 'none' }}>

                <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
              </CSVLink>
            </div>,
            <ButtonIcon icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={fetchData}/>
          ]
        })}
      />
      <HeadlessModal
        open={showEntityModal}
        onClosed={() => setEntity(entityInitialState)}
        size={'xxlg'}
        toggle={toggleEntityModal}
      >
        <Row>
          <Col>
            {getEntityComponent()}
          </Col>
        </Row>

      </HeadlessModal>
    </>
  )
}

export default BillingWipTable