import React, { Component } from 'react';

class GenericNotFound extends Component {
  render() {
    return (
            <div className="flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="clearfix">
                                <h1 className="float-left display-3 mr-4"><i className="fa fa-warning text-danger"></i> 404</h1>
                                <h4 className="pt-3">Oops! You're lost.</h4>
                                <p className="text-muted">The page you are looking for was not found.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
  }
}

class ForbiddenError extends Component {
  render() {
    return (
            <div className="flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="clearfix">
                                <h1 className="float-left display-3 mr-4"><i className="fa fa-lock text-warning"></i> 403</h1>
                                <h4 className="pt-3">Forbidden</h4>
                              <p className="text-muted">You have insufficient permissions to visit this page.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
  }
}

class FatalError extends Component {
  render() {
    return (
            <div className="app flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="clearfix">
                                <h1 className="float-left display-3 mr-4"><i className="fa fa-warning text-danger"></i></h1>
                                <h4 className="pt-3">Fatal Error</h4>
                                <p className="text-muted">Error Message: {this.props.message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
  }
}

export { ForbiddenError, GenericNotFound, FatalError };
