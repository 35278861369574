import thunk from 'redux-thunk';
import { applyMiddleware } from 'redux';
import { AUTHENTICATE_SOCKET, JOIN_ROOMS, LEAVE_ROOM, PING } from '../actions/socketio';
import { STOPLIVETRAFFIC, LIVETRAFFIC } from '../actions/realtimeTraffic';
import { ADDJTISUB, DELALLJTISUBS, DELJTISUB } from '../actions/JTI';
import { BGPSTART } from '../actions/BGP';
import { SOCKET_LOGOUT } from '../actions/user';
import createSagaMiddleware from 'redux-saga';
import SocketIOClient from './socketio/client';
import createSocketMiddleware from './socketio/middleware';

export const sagaMiddleware = createSagaMiddleware();

export const socketIOClient = new SocketIOClient();
socketIOClient.connect(process.env.REACT_APP_SOCKETIO);
// a list of actions to send to socketIO
const resocketMiddleware = createSocketMiddleware(socketIOClient.manager.socket('/'), [
  AUTHENTICATE_SOCKET, SOCKET_LOGOUT,
  JOIN_ROOMS, LEAVE_ROOM,
  PING,
  STOPLIVETRAFFIC, LIVETRAFFIC,
  ADDJTISUB, DELALLJTISUBS, DELJTISUB,
  BGPSTART
]);

export default applyMiddleware(thunk, resocketMiddleware, sagaMiddleware);
