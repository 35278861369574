import {Alert, Button, Col, Row} from 'reactstrap';
import React, {FunctionComponent} from 'react';

type TProps = {
  deactivated: boolean,
  message:  string,
  colour?: 'warning' | 'success' | 'danger',
  onRestore?: () => void,
}
const DeactivatedEntityWarning: FunctionComponent<TProps> = ({
  deactivated,
  message,
  colour,
  onRestore
}) => {


  if(deactivated){
    return (
      <Row>
        <Col>
          <Alert color={colour ?? 'warning'} className="d-flex justify-content-between align-items-center">
            <p className="m-0" >{message}</p>
            {onRestore ? <Button className="btn-sm" onClick={onRestore}>Reactivate</Button> : ''}
          </Alert>
        </Col>
      </Row>
    )
  }
  return (
    <></>
  )
}

export default DeactivatedEntityWarning
