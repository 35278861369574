import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  addDeviceModelNote,
  createDeviceModel,
  deleteDeviceModel,
  getDeviceModel, getDeviceModelAuditHistory, removeDeviceModelNote,
  resetDeviceModel,
  updateDeviceModel,
  updateDeviceModelField, updateDeviceModelNote
} from '../../../actions/deviceModel'
import form from './form'
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs'
import { isSdbManager } from '../../../utils/Auth/AuthService'
import LoadingOverlay from '../../../components/LoadingOverlay'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import classnames from 'classnames'
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert'
import { ButtonIcon } from '../../../components/ButtonIcon'
import FormValidationErrors from '../../../components/Errors/FormValidationErrors'
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard'
import GenericForm from '../../../components/GenericForm'
import CollapsibleCard from '../../../components/CollapsibleCard'
import Notes from '../../../components/Notes'
import isEmpty from 'lodash.isempty'
import AuditHistory from '../../../components/AuditHistory'
import { formValidator } from '../../../helpers/FormValidator'
import { setConfirmDialog } from '../../../actions/dialogs'
import { diff } from 'deep-object-diff'
import resolveArgs from '../../../helpers/ArgumentResolver'
import ApiErrorResolver from '../../../helpers/ApiErrorResolver'
import StockItemsTable from '../../../components/Tables/StockItems'
import queryString from 'query-string'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NavigationBlocker } from '../../../components/NavigationBlocker';

const includes = ['stockItems', 'notes', 'notes.createdBy']
const DeviceModel = ({
  id = null,
  data,
  original,
  vendors,
  audits,
  stockItemOptionSets
}) => {

  // router
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  // redux
  const dispatch = useDispatch()

  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [auditsLoading, setAuditsLoading] = useState(false)

  const deviceModelId = useMemo(() => (id || params.deviceModelId), [id, params.deviceModelId])
  const isNew = useMemo(() => deviceModelId === 'new' && !original.id, [deviceModelId, original.id])

  const toggleLoading = () => setLoading(prevState => !prevState)
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState)
  const queryParams = isNew ? queryString.parse(location?.search) : null

  useEffect(() => {
    if (!isNew) {
      fetchData(deviceModelId)
    } else if (queryParams.vendor) {
      dispatch(updateDeviceModelField('vendor', { id: queryParams.vendor }))
    }
    return () => dispatch(resetDeviceModel())
  }, [])

  useEffect(() => {
    if (original.name || isNew) {
      dispatch(addBreadcrumbs([
        // { name: original.vendor.name, path: `/sdb/vendors/${original.vendor.id}` },
        { name: !isNew ? `${original.vendor?.name} ${original.name}` : 'New' }
      ]))
    }
    return () => {
      dispatch(resetBreadcrumbs())
    }
  }, [original.name])

  const fetchData = () => {
    toggleLoading()
    dispatch(getDeviceModel(deviceModelId, includes)).then(() => toggleLoading())
  }
  const options = {
    rackWidth: [
      { value: 'full', label: 'Full' },
      { value: 'half', label: 'Half' }
    ],
    vendor: vendors.map((vendor) => (
      { value: vendor, label: vendor.name }
    ))
  }

  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getDeviceModelAuditHistory(deviceModelId)).then(() => toggleAuditsLoading())
  }

  const save = () => {
    if (validated()) {
      if (isNew) {
        toggleLoading()
        dispatch(createDeviceModel(resolveArgs(data),)).then(result => {
          if (result.errors) {
            setErrors(ApiErrorResolver(result.errors))
            toggleLoading()
          } else if (result) {
            navigate(`/sdb/device-models/${result.id}`)
            toggleLoading()
          }
        })
      } else {
        const toSave = diff(original, data)
        toggleLoading()
        dispatch(updateDeviceModel(deviceModelId, resolveArgs(toSave), includes)).then(result => {
          if (result.errors) {
            setErrors(ApiErrorResolver(result.errors))
          }
          toggleLoading()
        })
      }
    }
  }
  const validated = () => {
    let errorArr = formValidator(form, data)
    setErrors(errorArr)
    return isEmpty(errorArr)
  }
  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: 'You are about to delete this Device Model!',
      proceed: () => {
        toggleLoading()
        dispatch(deleteDeviceModel(deviceModelId)).then(result => {
          toggleLoading()
          if (result) {
            navigate('/sdb/device-models')
          }
        })
      }
    }))
  }

  const hasChanges = () => {
    const changes = diff(original, data)
    return !isEmpty(changes)
  }

  const canEdit = () => isSdbManager()

  const canDelete = () => canEdit() && original.deployed === 0

  return (
    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <LoadingOverlay loading={loading}>
        <Card className="bg-light border-0 mb-3">
          <CardBody>
            <Row className="mb-2">
              <Col className="d-flex justify-content-between">
                <CardTitle>
                  {original.name ? `${original.vendor?.name} ${original.name}` : 'New Device Model'}
                </CardTitle>
                <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {!isNew && <ButtonIcon
                    disabled={!canDelete()}
                    icon={'fa fa-trash'}
                    tooltip={!canDelete() ? 'Model has existing stock' : !canEdit() ? 'You have no permissions to delete this item!' : 'Delete'}
                    onClick={onDeleting}
                  />}
                  <ButtonIcon disabled={!canEdit() || loading || !hasChanges()}
                              icon={'fa fa-save'} tooltip={'Save'} onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'} onClick={fetchData}/>
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row className={'d-flex'}>
              <Col className={'d-flex col-12 col-sm-12 col-md-6 col-lg-6'}>
                <EntityMainFormCard grow>
                  <GenericForm
                    data={data}
                    form={form}
                    setField={(field, value) => dispatch(updateDeviceModelField(field, value))}
                    options={options}
                  />
                </EntityMainFormCard>
              </Col>
            </Row>
            {!isNew &&
              <>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Stock'}
                      open
                    >
                      <StockItemsTable
                        excludeColumns={['deviceModel']}
                        stockItems={original.stockItems ?? []}
                        optionSets={stockItemOptionSets}
                        fetchData={fetchData}
                        minRows={10}
                        deviceModel={data}
                        sortBy={[{ id: 'assetTag', desc: false }]}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      open
                      title={'Notes'}
                    >
                      <Notes
                        withNew
                        notes={data.notes}
                        relatedTo={{
                          type: 'deviceModel',
                          data
                        }}
                        onCreated={note => dispatch(addDeviceModelNote(note))}
                        onUpdated={note => dispatch(updateDeviceModelNote(note))}
                        onDeleted={note => dispatch(removeDeviceModelNote(note))}
                      />
                    </CollapsibleCard>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CollapsibleCard
                      title={'Audit History'}
                      onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                    >
                      <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                    </CollapsibleCard>
                  </Col>
                </Row>
              </>

            }

          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  )
}

function mapStateToProps ({
  helpers,
  deviceModel,
  vendors,
  authenticationState
}) {
  return {
    permissions: authenticationState.account.permissions,
    vendors: vendors.vendors,
    stockItemOptionSets: helpers.optionSets.stockItem,
    data: deviceModel.data,
    original: deviceModel.original,
    audits: deviceModel.audits,
  }
}

export default connect(mapStateToProps)(DeviceModel)
