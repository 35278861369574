import { apiRequest } from '../Api/ApiRequest';

export function api_generateLOA(data) {
  return apiRequest({
    url: '/loagenerator',
    method: 'post',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_downloadLOA(uuid) {
  return apiRequest({
    url: '/loagenerator/' + uuid + '/download',
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
