import React from 'react';
import { Row } from 'reactstrap';
import { ButtonIcon } from '../../../components/ButtonIcon';
import EntitySubFormCard from '../../../components/Cards/EntitySubFormCard';
import formFieldGenerator from '../../../helpers/FormFieldGenerator';
import DeviceDeploymentComponentForm from './form';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'


const DeploymentInfoComponent = ({ deviceDeployment, deploymentTypes }) => {

  // router
  const navigate = useNavigate();

  return (
    <EntitySubFormCard title={deviceDeployment.hostname} headerExtraRight={
      <>
        <ButtonIcon
          className={'ml-auto'}
          icon="fa fa-arrow-right"
          tooltip="view"
          onClick={() => navigate('/sdb/device-deployments/' + deviceDeployment.id)}
        />
      </>
    }>
      <Row>
        {formFieldGenerator({
          data: deviceDeployment,
          fields: DeviceDeploymentComponentForm,
          optionSets:deploymentTypes
        })}
      </Row>
    </EntitySubFormCard>
  );
};

export default DeploymentInfoComponent;
