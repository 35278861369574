import {
  LEAD_TIMES_REPORT_SET_LINE_ITEMS,
  LEAD_TIMES_REPORT_SET_QUARTER,
  LEAD_TIMES_REPORT_SET_YEAR,
  LEAD_TIMES_REPORT_SET_YEAR_OPTIONS
} from '../actions/leadTimesReport';

const initialState = {
  year: null,
  quarter: null,
  yearOptions: [],
  lineItems: []
};

export default function leadTimesReport(state = initialState, action) {
  switch (action.type) {
    case LEAD_TIMES_REPORT_SET_LINE_ITEMS: {
      return {
        ...state,
        lineItems: action.data
      };
    }
    case LEAD_TIMES_REPORT_SET_YEAR: {
      return {
        ...state,
        year: action.year
      };
    }
    case LEAD_TIMES_REPORT_SET_YEAR_OPTIONS:
      return {
        ...state,
        yearOptions: [...action.options]
      };
    case LEAD_TIMES_REPORT_SET_QUARTER: {
      return {
        ...state,
        quarter: action.quarter
      };
    }
    default:
      return state;
  }
}
