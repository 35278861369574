import {api_getAlarms} from "../utils/Alarms/AlarmsService";
import {setAlarmData} from "../actions/alarms";
import {errorFeedback} from "../actions/feedback";
import {actionChannel, call, put, throttle, take} from "redux-saga/effects";

function* sagaGetAlarmsReal() {
    try {
      const result = yield call(api_getAlarms);
      if(result.status === 200) {
        yield put(setAlarmData(result.data.data, result.data.acks, result.data.filters));
      }
      else {
        yield put(errorFeedback('Could not fetch alarm data'));
      }
    } catch (e) {
      yield put(errorFeedback('Could not fetch alarm data'));
    }
}

export default function* sagaGetAlarms() {
  yield throttle(5000, 'DO_FETCH_ALARMS', sagaGetAlarmsReal);
}