export default {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true,
    show: (field, data) => {
      return data.isNew === false
    }
  },
  type: {
    label: 'Type',
    type: 'select',
    mandatory: true
  },
  status: {
    label: 'Status',
    type: 'select',
    readOnly: true,
    show: (field, data) => {
      return data.isNew === false
    }
  },
  updatedAt: {
    label: 'Last updated',
    type: 'text',
    format: 'datetime',
    readOnly: true,
    show: (field, data) => {
      return data.isNew === false
    }
  }
}
