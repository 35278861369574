import moment from 'moment/moment';

const form = {
  reference: {
    label: 'Reference',
    type: 'text',
    mandatory: true
  },
  activationCode: {
    label: 'Activation Code',
    type: 'text',
    mandatory: false
  },
  quota: {
    label: 'Number of Items Covered',
    type: 'number',
    min: 1,
    max: 255,
    mandatory: false
  },
  deviceModel: {
    label: 'Device Model',
    type: 'select',
    entity: 'deviceModel'
  },
  issuedAt: {
    label: 'Issued Date',
    type: 'date',
    mandatory: true,
    max: moment().startOf('date').toDate()
  },
  supportedUntil: {
    label: 'Supported Until',
    type: 'date',
    mandatory: false
  },
  expiredAt: {
    label: 'Expiry Date',
    type: 'date',
    mandatory: false
  },
  data: {
    label: 'Data',
    type: 'textarea',
    mandatory: false,
    width: 12
  },
};

export default form;