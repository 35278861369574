import { RackService } from '../utils/ServiceDB/Rack';
import { defaultErrorFeedback, successFeedback } from './feedback';
import AuditService from '../utils/Audit';

export const RACK_SET_DATA = 'RACK_SET_DATA';
export const RACK_SET_FIELD = 'RACK_SET_FIELD';
export const RACK_RESET_DATA = 'RACK_RESET_DATA';
export const RACK_SET_AUDIT_HISTORY = 'RACK_SET_AUDIT_HISTORY';
export const RACK_ADD_NOTE = 'RACK_ADD_NOTE';
export const RACK_UPDATE_NOTE = 'RACK_UPDATE_NOTE';
export const RACK_REMOVE_NOTE = 'RACK_REMOVE_NOTE';

export function deleteRack(id) {
  return (dispatch) => {
    return RackService.deleteRack(id).then((result) => {
      if (result.status === 204) {
        return true;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

function setRackData(data) {
  return {
    type: RACK_SET_DATA,
    data
  };
}

export function setRackField(field, value) {
  return {
    type: RACK_SET_FIELD,
    field,
    value
  };
}

export function resetRack() {
  return {
    type: RACK_RESET_DATA
  };
}

export function addRackNote(note) {
  return {
    type: RACK_ADD_NOTE,
    note
  };
}

export function updateRackNote(note) {
  return {
    type: RACK_UPDATE_NOTE,
    note
  };
}

export function removeRackNote(note) {
  return {
    type: RACK_REMOVE_NOTE,
    note
  };
}

export function getRack(id, include = []) {
  return (dispatch) => {
    return RackService.getRack(id, include).then((result) => {
      if (result.status === 200) {
        dispatch(setRackData(result.data));
        return result.data;
      }
      dispatch(defaultErrorFeedback());
      return false;
    });
  };
}

export function createRack(args, include = []) {
  return (dispatch) => {
    return RackService.createRack(args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setRackData(result.data));
        dispatch(successFeedback('Rack created!'));
        return result.data
      } else if(result.status === 422){
        return {errors: result.data}
      } else {
        dispatch(defaultErrorFeedback());
      }
      return null;
    });
  };
}

export function updateRack(id, args, include = []) {
  return (dispatch) => {
    return RackService.updateRack(id, args, include).then((result) => {
      if (result.status === 200) {
        dispatch(setRackData(result.data));
        dispatch(successFeedback('Rack updated!'));
        return result
      } else if(result.status === 422){
        return {errors: result.data}
      } else {
        dispatch(defaultErrorFeedback());
      }
      return null;
    });
  };
}

export function getRackAuditHistory(id){
  return (dispatch) => {
    return AuditService.get('sdb/racks', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: RACK_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}

