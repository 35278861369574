import React from 'react';
import { connect } from 'react-redux';
import Loadable from 'react-loading-overlay';
import { Button, Col, Input, Label, FormGroup } from 'reactstrap';
import isLength from 'validator/lib/isLength';
import isInt from 'validator/lib/isInt';
import { createMandate, DIRECT_DEBITS_SET_NEW_MANDATE, DIRECT_DEBITS_UPDATE_NEW_MANDATE, resetNewMandateErrors } from '../../actions/directDebits';
import { api_getDirectDebitByMandateRef, api_searchDirectDebitMandates } from '../../utils/Billing/DirectDebitService';

class DirectDebitsNewMandate extends React.Component {
    handleInput = (event) => {
      const field = event.target.id;
      let value = event.target.value;
      if (field === 'accountName' || field === 'mandateRef') {
        value = value.substr(0, 18);
      } else if (field === 'accountSortCode') {
        value = value.substr(0, 6);
      } else {
        value = value.substr(0, 8);
      }
      if (field === 'accountName') {
        value = value.toUpperCase();
      }
      this.props.dispatch({ type: DIRECT_DEBITS_UPDATE_NEW_MANDATE, field, value });
    }

    validateMandate = async () => {
      await this.props.dispatch({ type: DIRECT_DEBITS_UPDATE_NEW_MANDATE, field: 'loading', value: true });
      let { newMandate } = this.props.directDebits;
      newMandate.errors = {};
      if (!newMandate.accountName || isLength(newMandate.accountName, { min: 3 }) === false) {
        newMandate.errors.accountName = 'Invalid account name';
      }
      if (!newMandate.accountNumber || isInt(newMandate.accountNumber) === false || !isLength(newMandate.accountNumber, { min: 8, max: 8 })) {
        newMandate.errors.accountNumber = 'Invalid account number';
      }
      if (!newMandate.accountSortCode || isInt(newMandate.accountSortCode) === false || !isLength(newMandate.accountSortCode, { min: 6, max: 6 })) {
        newMandate.errors.accountSortCode = 'Invalid account sort code';
      }
      if (newMandate.mandateRef && /^([a-zA-Z0-9-]+)$/.test(newMandate.mandateRef) === false) {
        newMandate.errors.mandateRef = 'Invalid ref, only a-zA-Z0-9-';
      } else if (newMandate.mandateRef && await this.mandateExists(newMandate.mandateRef)) {
        newMandate.errors.mandateRef = 'Ref is already taken';
      }
      if (newMandate.accountNumber && newMandate.accountSortCode && await this.accountExists(newMandate.accountNumber, newMandate.accountSortCode)) {
        newMandate.errors.accountSortCode = 'Account and Sort Code combination already exists';
        newMandate.errors.accountNumber = 'Account and Sort Code combination already exists';
      }

      this.props.dispatch({ type: DIRECT_DEBITS_SET_NEW_MANDATE, mandate: newMandate });
      if (Object.keys(newMandate.errors).length === 0) {
        return true;
      }
      this.props.dispatch({ type: DIRECT_DEBITS_UPDATE_NEW_MANDATE, field: 'loading', value: false });
      return false;
    }

    mandateExists = (mandateRef) => {
      return api_getDirectDebitByMandateRef(mandateRef).then(
        (result) => {
          return result.status === 200;
        }
      );
    }

    accountExists = (accountNumber, sortCode) => {
      return api_searchDirectDebitMandates({ accountNumber: `${accountNumber}`, accountSortCode: `${sortCode}` }).then(
        (result) => {
          return result.status === 200 && result.data.length > 0;
        }
      );
    }

    createMandate = () => {
      this.props.dispatch({ type: DIRECT_DEBITS_UPDATE_NEW_MANDATE, field: 'loading', value: true });
      this.props.dispatch({ type: DIRECT_DEBITS_UPDATE_NEW_MANDATE, field: 'created', value: false });
      this.props.dispatch(resetNewMandateErrors());
      this.validateMandate().then((noErrors) => {
        if (noErrors) {
          this.props.dispatch(createMandate(this.props.directDebits.newMandate)).then(
            (result) => {
              if (result === undefined) {
                this.props.dispatch({ type: DIRECT_DEBITS_UPDATE_NEW_MANDATE, field: 'loading', value: false });
              } else {
                this.props.dispatch({ type: DIRECT_DEBITS_UPDATE_NEW_MANDATE, field: 'created', value: true });
              }
              this.props.dispatch({ type: DIRECT_DEBITS_UPDATE_NEW_MANDATE, field: 'loading', value: false });
            }
          );
        }
      });
    }

    render() {
      const { newMandate } = this.props.directDebits;
      return (
            <Loadable animate={false} active={newMandate.loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                <FormGroup row >
                    <Col md="4" className="mb-0">
                        <Label htmlFor={'accountName'}><strong>Account</strong></Label>
                    </Col>
                    <Col xs="12" md="8">
                        <Input className={newMandate.errors.accountName ? 'is-invalid' : ''} type="text" id="accountName" onChange={this.handleInput} value={newMandate.accountName}/>
                        {newMandate.errors.accountName
                        && <div className="invalid-feedback">
                            {newMandate.errors.accountName}
                        </div>
                        }
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="4" className="mb-0">
                        <Label htmlFor={'accountSortCode'}><strong>Sort code</strong></Label>
                    </Col>
                    <Col xs="12" md="8">
                        <Input className={newMandate.errors.accountSortCode ? 'is-invalid' : ''} type="text" id="accountSortCode" onChange={this.handleInput} value={newMandate.accountSortCode}/>
                        {newMandate.errors.accountSortCode
                        && <div className="invalid-feedback">
                            {newMandate.errors.accountSortCode}
                        </div>
                        }
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="4" className="mb-0">
                        <Label htmlFor={'accountNumber'}><strong>Account No</strong></Label>
                    </Col>
                    <Col xs="12" md="8">
                        <Input className={newMandate.errors.accountNumber ? 'is-invalid' : ''} type="text" id="accountNumber" onChange={this.handleInput} value={newMandate.accountNumber}/>
                        {newMandate.errors.accountNumber
                        && <div className="invalid-feedback">
                            {newMandate.errors.accountNumber}
                        </div>
                        }
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="4" className="mb-0">
                        <Label htmlFor={'mandateRef'}><strong>Mandate Ref (optional)</strong></Label>
                    </Col>
                    <Col xs="12" md="8">
                        <Input className={newMandate.errors.mandateRef ? 'is-invalid' : ''} type="text" id="mandateRef" onChange={this.handleInput} value={newMandate.mandateRef}/>
                        {newMandate.errors.mandateRef
                        && <div className="invalid-feedback">
                            {newMandate.errors.mandateRef}
                        </div>
                        }
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col>
                        <Button className={'float-right'} color={'primary'} onClick={this.createMandate}>Create</Button>
                    </Col>
                </FormGroup>
            </Loadable>
      );
    }
}

function mapStateToProps({ directDebits }) {
  return {
    directDebits
  };
}
export default connect(mapStateToProps)(DirectDebitsNewMandate);
