import {apiRequest} from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export const OpticalSystemService = {
    getOpticalSystems: (sorted = [], search = [], include = []) => {
        let query = {
            sorted,
            search,
            include
        };
        return apiRequest({
            url: `/sdb/optical-systems${queryStringFromFields(query)}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    getOpticalSystem: (id, include = []) => {
        return apiRequest({
            url: `/sdb/optical-systems/${id}${queryStringFromFields({ include })}`,
            method: 'get'
        }).then(
            resp => resp
        ).catch(
            error => error
        );
    },
    createOpticalSystem: (data, include = []) => {
        return apiRequest({
            url: `/sdb/optical-systems${queryStringFromFields({ include })}`,
            method: 'post',
            data
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    updateOpticalSystem: (id, data, include = []) => {
        return apiRequest({
            url: `/sdb/optical-systems/${id}${queryStringFromFields({ include })}`,
            method: 'put',
            data
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    },
    deleteOpticalSystem: (id) => {
        return apiRequest({
            url: `/sdb/optical-systems/${id}`,
            method: 'delete'
        }).then(
          resp => resp
        ).catch(
          error => error
        );
    }

};