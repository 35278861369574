// @ts-ignore
import { deepFreeze } from '../Helpers/DeepFreeze';

export const CurrencyEnums = deepFreeze({
    currency: {
        GBP: 1,
        USD: 151,
        EUR: 48
    }
});
