import { apiRequest } from '../Api/ApiRequest';

export function api_getAlarms() {
  return apiRequest({
    url: '/alarms',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_ackAlarm(event, message) {
  return apiRequest({
    url: '/alarms/' + event + '/ack',
    method: 'post',
    data: { message: message }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_ackAlarmWithCase(event) {
  return apiRequest({
    url: '/alarms/' + event + '/case',
    method: 'post'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_filterAlarm(event, message, dateto, tags) {
  return apiRequest({
    url: '/alarms/' + event + '/filter',
    method: 'post',
    data: { message: message, dateto: dateto, tags: tags }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_systemStatus() {
  return apiRequest({
    url: '/alarms/systemstatus',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
