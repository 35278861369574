import {
  api_deleteBillingRun,
  api_getBillingRun, api_getBillingRunFailedJobs,
  api_retryAllFailedBillingJobs,
  api_retryFailedBillingJob,
  api_updateBillingRun
} from '../utils/Billing/BillingService';
import { defaultErrorFeedback, errorFeedback } from './feedback';
import { removeBillingRun } from './billingRuns';

export const BILLING_RUN_SET_RUN_DATA = 'BILLING_RUN_SET_RUN_DATA';
export const BILLING_RUN_RESET_RUN_DATA = 'BILLING_RUN_RESET_RUN_DATA';
export const BILLING_RUN_REMOVE_RUN = 'BILLING_RUN_REMOVE_RUN';
export const BILLING_RUN_REMOVE_FAILED_JOB = 'BILLING_RUN_REMOVE_FAILED_JOB';
export const BILLING_RUN_REMOVE_ALL_FAILED_JOBS = 'BILLING_RUN_REMOVE_ALL_FAILED_JOBS';
export const BILLING_RUN_UPDATE_RUN = 'BILLING_RUN_UPDATE_RUN';
export const BILLING_RUN_CREATED = 'BILLING_RUN_CREATED';
export const BILLING_RUN_ADD_BILL = 'BILLING_RUN_ADD_BILL';
export const BILLING_RUN_TOGGLE_PREVIEW_BILL = 'BILLING_RUN_TOGGLE_PREVIEW_BILL';
export const BILLING_RUN_SET_TAX_DATE = 'BILLING_RUN_SET_TAX_DATE';
export const BILLING_RUN_TOGGLE_COMPLETE_MODAL = 'BILLING_RUN_TOGGLE_COMPLETE_MODAL';
export const BILLING_RUN_TOGGLE_BILL_MODAL = 'BILLING_RUN_TOGGLE_BILL_MODAL';
export const BILLING_RUN_TOGGLE_ERROR_MODAL = 'BILLING_RUN_TOGGLE_ERROR_MODAL';
export const BILLING_RUN_TOGGLE_LOADING = 'BILLING_RUN_TOGGLE_LOADING';
export const BILLING_RUN_SET_EMAIL_SENDING = 'BILLING_RUN_SET_EMAIL_SENDING';
export const BILLING_RUN_EXCLUDE_BILL = 'BILLING_RUN_EXCLUDE_BILL';
export const BILLING_RUN_INCLUDE_BILL = 'BILLING_RUN_INCLUDE_BILL';
export const BILLING_RUN_REMOVE_ACCOUNT_BILLS = 'BILLING_RUN_REMOVE_ACCOUNT_BILLS';
export const BILLING_RUN_SET_FAILED_JOBS = 'BILLING_RUN_SET_FAILED_JOBS';

export function resetBillingRunData() {
  return {
    type: BILLING_RUN_RESET_RUN_DATA
  };
}
function setRunData(data) {
  return {
    type: BILLING_RUN_SET_RUN_DATA,
    data
  };
}

function _updateBillingRun(data) {
  return {
    type: BILLING_RUN_UPDATE_RUN,
    data
  };
}

function setFailedJobs(failedJobs) {
  return {
    type: BILLING_RUN_SET_FAILED_JOBS,
    failedJobs
  };
}
function removeFailedJob(runId, jobId) {
  return {
    type: BILLING_RUN_REMOVE_FAILED_JOB,
    runId,
    jobId
  };
}

function removeAllFailedJobs() {
  return {
    type: BILLING_RUN_REMOVE_ALL_FAILED_JOBS
  };
}

export function togglePreviewBill() {
  return {
    type: BILLING_RUN_TOGGLE_PREVIEW_BILL
  };
}

export function toggleRunLoading() {
  return {
    type: BILLING_RUN_TOGGLE_LOADING
  };
}

export function moveBillToExcluded(billId) {
  return {
    type: BILLING_RUN_EXCLUDE_BILL,
    billId
  };
}
export function moveBillToIncluded(billId) {
  return {
    type: BILLING_RUN_INCLUDE_BILL,
    billId
  };
}

export function removeAccountFromRun(billId) {
  return {
    type: BILLING_RUN_REMOVE_ACCOUNT_BILLS,
    billId
  };
}

export function getBillingRun(id, withLogs = false) {
  return (dispatch) =>{
    return api_getBillingRun(id, withLogs).then((result) => {
      if (result.status === 200) {
        dispatch(setRunData(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function getBillingRunFailedJobs(id) {
  return (dispatch) =>{
    return api_getBillingRunFailedJobs(id).then((result) => {
      if (result.status === 200) {
        dispatch(setFailedJobs(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function deleteBillingRun(id) {
  return (dispatch) =>{
    return api_deleteBillingRun(id).then((result) => {
      if (result.status === 200) {
        dispatch(removeBillingRun(id));
        return true;
      }

      dispatch(result.data.message ? errorFeedback(result.data.message) : defaultErrorFeedback());
      return false;
    });
  };
}

export function updateBillingRun(id, data) {
  return (dispatch) =>{
    return api_updateBillingRun(id, data).then((result) => {
      if (result.status === 200) {
        dispatch(_updateBillingRun(result.data));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function retryFailedBillingJob(runId, jobId) {
  return (dispatch) =>{
    return api_retryFailedBillingJob(runId, jobId).then((result) => {
      if (result.status === 200) {
        dispatch(removeFailedJob(runId, jobId));
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

export function retryAllFailedBillingJobs(runId) {
  return (dispatch) =>{
    return api_retryAllFailedBillingJobs(runId).then((result) => {
      if (result.status === 200) {
        dispatch(removeAllFailedJobs());
      } else {
        dispatch(defaultErrorFeedback());
      }
    });
  };
}

/* export function getBillForPreview(runId, billId){
    return (dispatch) =>{
        dispatch(setPreviewBill({}));
        dispatch(togglePreviewBill());
        dispatch(setLoadingInvoicePreview(true));
        return api_getBill(runId, billId).then((result) => {
            if(result.status === 200){
                dispatch(setPreviewBill(result.data))
            }
            else{
                dispatch(defaultErrorFeedback());
            }
            dispatch(setLoadingInvoicePreview(false));
        })
    }
} */
