import { IPAM_BLOCKS_SET_DATA } from '../../../actions/ServiceDB/Ipam/blocks';

const initialState = {
  blocks: []
};
const blocks = (state = initialState, action) => {
  switch (action.type) {
    case IPAM_BLOCKS_SET_DATA:
      return {
        ...state,
        blocks: action.data
      }
    default:
      return state;
  }
};

export default blocks;