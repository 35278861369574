export default {
  info: {
    name: {
      label: 'Name',
      type: 'text',
      plaintext: true,
      width: 2
    },
    id: {
      label: 'Id',
      type: 'text',
      plaintext: true,
      width: 2
    },
    tcv: {
      label: 'TCV',
      type: 'text',
      plaintext: true,
      format: 'money',
      width: 2
    },
    source: {
      label: 'Source',
      type: 'text',
      plaintext: true,
      width: 2
    },
    status: {
      label: 'Status',
      type: 'text',
      plaintext: true,
      width: 2
    },
    feasibility: {
      label: 'Feasibility',
      type: 'text',
      plaintext: true,
      width: 2,
      callBack: (key, field, data) => callBack(key, field, data)
    },
    assigned_to: {
      label: 'Assigned To',
      type: 'select',
      width: 2,
      mandatory: true
    },
  },
  site: {
    address: {
      label: 'Address',
      type: 'text',
      plaintext: true,
      width: 8
    },
    postcode: {
      label: 'Postcode',
      type: 'text',
      plaintext: true,
      width: 4
    },
    technology: {
      label: 'Technology',
      type: 'text',
      plaintext: true,
      width: 4,
      callBack: (key, field, data) => callBack(key, field, data)
    },
    kit: {
      label: 'Kit',
      type: 'text',
      plaintext: true,
      width: 4,
      callBack: (key, field, data) => callBack(key, field, data)
    },
    term: {
      label: 'Term',
      type: 'text',
      plaintext: true,
      width: 4
    },
    installType: {
      label: 'Install Type',
      type: 'text',
      plaintext: true,
      width: 4,
      callBack: (key, field, data) => callBack(key, field, data)
    },
    bearer_speed: {
      label: 'Bearer',
      type: 'text',
      plaintext: true,
      width: 4
    },
    cdr: {
      label: 'CDR',
      type: 'text',
      plaintext: true,
      width: 4
    }
  },
  prices: {
    tcv: {
      label: 'TCV',
      type: 'text',
      plaintext: true,
      format: 'money',
      width: 4
    },
    install: {
      label: 'Install',
      type: 'text',
      plaintext: true,
      format: 'money',
      width: 4
    },
    rental: {
      label: 'Rental',
      type: 'text',
      plaintext: true,
      format: 'money',
      width: 4
    }
  },
  p2pSite: {
    a_address: {
      label: 'A Address',
      type: 'text',
      plaintext: true,
      width: 12
    },
    b_address: {
      label: 'B Address',
      type: 'text',
      plaintext: true,
      width: 12
    },
    kit: {
      label: 'Kit',
      type: 'text',
      plaintext: true,
      width: 4,
      callBack: (key, field, data) => callBack(key, field, data)
    },
    term: {
      label: 'Term',
      type: 'text',
      plaintext: true,
      width: 4
    },
    installType: {
      label: 'Install Type',
      type: 'text',
      plaintext: true,
      width: 4,
      callBack: (key, field, data) => callBack(key, field, data)
    },
    bearer_speed: {
      label: 'Bearer',
      type: 'text',
      plaintext: true,
      width: 4
    },
    cdr: {
      label: 'CDR',
      type: 'text',
      plaintext: true,
      width: 4
    }
  },
  network: {
    ipv4: {
      label: 'IP Address (v4)',
      type: 'text',
      plaintext: true,
      width: 4
    },
    netname: {
      label: 'Net Name',
      type: 'text',
      plaintext: true,
      width: 4
    },
    netdesc: {
      label: 'Net Description',
      type: 'text',
      plaintext: true,
      width: 4,
    },
    dns: {
      label: 'DNS',
      type: 'text',
      plaintext: true,
      width: 4
    }
  }
};

export const wanQuote = {
  postcode: {
    label: 'Postcode',
    type: 'inputgroup',
    format: 'money',
    width: 4,
    button: <i className='fa fa-search'/>,
    mandatory: true
  },
  address_id: {
    label: 'Address',
    type: 'select',
    mandatory: true,
    width: 8,
    placeholder: 'Select address...'
  },
  term: {
    label: 'Term',
    type: 'select',
    mandatory: true,
    width: 4
  },
  technology: {
    label: 'Technology',
    type: 'select',
    mandatory: true,
    width: 4
  },
  install: {
    label: 'Install',
    type: 'select',
    mandatory: true,
    width: 4
  },
  bearer_speed: {
    label: 'Bearer',
    type: 'select',
    mandatory: true,
    width: 4
  },
  cdr: {
    label: 'CDR',
    type: 'select',
    mandatory: true,
    width: 4
  },
  kit: {
    label: 'Kit',
    type: 'select',
    mandatory: true,
    width: 4
  },
  include_carriers: {
    label: 'Include Carriers',
    type: 'select-multi',
    width: 3
  },
  exclude_carriers: {
    label: 'Exclude Carriers',
    type: 'select-multi',
    width: 3
  },
  include_nnis: {
    label: 'Include NNIs',
    type: 'select-multi',
    width: 3
  },
  exclude_nnis: {
    label: 'Exclude NNIs',
    type: 'select-multi',
    width: 3
  }

};

export const quoteHeader = {
  name: {
    label: 'Name',
    type: 'text',
    width: 6,
    mandatory: true
  },
  feasibility: {
    label: 'Feasibility',
    type: 'select',
    width: 2,
    mandatory: true
  }
};

export const p2pQuote = {
  a_postcode: {
    label: 'Postcode A',
    type: 'inputgroup',
    width: 4,
    button: <i className='fa fa-search'/>,
    mandatory: true
  },
  a_address_id: {
    label: 'Address A',
    type: 'select',
    mandatory: true,
    width: 8,
    placeholder: 'Select address...'
  },
  b_postcode: {
    label: 'Postcode B',
    type: 'inputgroup',
    format: 'money',
    width: 4,
    button: <i className='fa fa-search'/>,
    mandatory: true
  },
  b_address_id: {
    label: 'Address B',
    type: 'select',
    mandatory: true,
    width: 8,
    placeholder: 'Select address...'
  },
  term: {
    label: 'Term',
    type: 'select',
    mandatory: true,
    width: 3
  },
  install: {
    label: 'Install',
    type: 'select',
    mandatory: true,
    width: 3
  },
  bearer_speed: {
    label: 'Bearer',
    type: 'select',
    mandatory: true,
    width: 3
  },
  cdr: {
    label: 'CDR',
    type: 'select',
    mandatory: true,
    width: 3
  },
  kit: {
    label: 'Kit',
    type: 'select',
    mandatory: true,
    width: 3
  },
  include_carriers: {
    label: 'Include Carriers',
    type: 'select-multi',
    width: 3
  },
  exclude_carriers: {
    label: 'Exclude Carriers',
    type: 'select-multi',
    width: 3
  }
};

export const wanReprice = {
  header: {
    name: {
      label: 'Name',
      type: 'text',
      width: 6,
      mandatory: true
    }
  },
  sites: {
    address: {
      label: 'Address',
      type: 'text',
      plaintext: true,
      width: 6
    },
    postcode: {
      label: 'Postcode',
      type: 'text',
      plaintext: true,
      width: 2
    },
    carrier: {
      label: 'Carrier',
      type: 'text',
      plaintext: true,
      width: 2,
      callBack: (key, field, data) => callBack(key, field, data)
    },
    kit: {
      label: 'Kit',
      type: 'text',
      plaintext: true,
      width: 2,
      callBack: (key, field, data) => callBack(key, field, data)
    },
    bearer: {
      label: 'Bearer',
      type: 'text',
      width: 3
    },
    cdr: {
      label: 'CDR',
      type: 'text',
      width: 3
    },
    install: {
      label: 'Install',
      type: 'text',
      width: 2
    },
    rental: {
      label: 'Rental',
      type: 'text',
      width: 2
    },
    reference: {
      label: 'Reference',
      type: 'text',
      mandatory: true,
      width: 12
    }
  }
};

export const p2pReprice = {
  header: {
    name: {
      label: 'Name',
      type: 'text',
      width: 6,
      mandatory: true
    }
  },
  sites: {
    a_address: {
      label: 'Address A',
      type: 'text',
      plaintext: true,
      width: 6
    },
    a_postcode: {
      label: 'Postcode A',
      type: 'text',
      plaintext: true,
      width: 2
    },
    b_address: {
      label: 'Address B',
      type: 'text',
      plaintext: true,
      width: 6
    },
    b_postcode: {
      label: 'Postcode B',
      type: 'text',
      plaintext: true,
      width: 2
    },
    carrier: {
      label: 'Carrier',
      type: 'text',
      plaintext: true,
      width: 2,
      callBack: (key, field, data) => callBack(key, field, data)
    },
    kit: {
      label: 'Kit',
      type: 'text',
      plaintext: true,
      width: 2,
      callBack: (key, field, data) => callBack(key, field, data)
    },
    bearer: {
      label: 'Bearer',
      type: 'text',
      width: 3
    },
    cdr: {
      label: 'CDR',
      type: 'text',
      width: 3
    },
    install: {
      label: 'Install',
      type: 'text',
      width: 2
    },
    rental: {
      label: 'Rental',
      type: 'text',
      width: 2
    },
    reference: {
      label: 'Reference',
      type: 'text',
      mandatory: true,
      width: 12
    }
  }
};

export const wanGEAQuote = {
  postcode: {
    label: 'Postcode',
    type: 'inputgroup',
    format: 'money',
    width: 4,
    button: <i className='fa fa-search'/>,
    mandatory: true
  },
  address_id: {
    label: 'Address',
    type: 'select',
    mandatory: true,
    width: 8,
    placeholder: 'Select address...'
  },
  term: {
    label: 'Term',
    type: 'select',
    mandatory: true,
    width: 4
  },
  technology: {
    label: 'Technology',
    type: 'select',
    mandatory: true,
    width: 4
  },
  install: {
    label: 'Install',
    type: 'select',
    mandatory: true,
    width: 4
  },
  product: {
    label: 'Product',
    type: 'select',
    mandatory: true,
    width: 4
  },
  kit: {
    label: 'Kit',
    type: 'select',
    mandatory: true,
    width: 4
  },
}

const callBack = (key, field, data, optionSets) => {

  if (['installType', 'kit', 'feasibility', 'technology', 'carrier'].includes(key)) {
    return data[key] !== undefined && data[key] !== null ? data[key].name : '';
  }
  return null;

};
