import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import { getCarrierCircuits } from '../../../actions/carrierCircuits';
import { Card, CardBody } from 'reactstrap';
import CarrierCircuitsTable from '../../../components/Tables/CarrierCircuits';
import LoadingOverlay from '../../../components/LoadingOverlay';
import debounce from 'debounce-promise';
const CarrierCircuits = ({ circuitOptionSets, carrierCircuits, suppliers , table}) => {

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }
  const fetch = (page, size, searchString, sorting) => {
    toggleLoading()
    dispatch(getCarrierCircuits(page, size, searchString, sorting))
      .then(() => toggleLoading());
  };
  useEffect(() => {
    fetch(page, pageSize, '', [{id: 'name', desc:true}]);
  }, []);

  const debounced = debounce((page, size, searchString, sorting) => fetch(page, size, searchString, sorting), 500);

  return (
    <Card className="w-100">
      <CardBody>
        <LoadingOverlay loading={loading}>
          <CarrierCircuitsTable
            circuits={carrierCircuits}
            fetchData={(page, size, searchString, sorting) => debounced(page, size, searchString, sorting)}
            circuitOptionSets={circuitOptionSets}
            suppliers={suppliers}
            table={table}
          />
        </LoadingOverlay>
      </CardBody>
    </Card>
  );
};

function mapStateToProps({ carrierCircuits, helpers }) {
  return {
    circuitOptionSets: helpers.optionSets.carrierCircuit,
    carrierCircuits: carrierCircuits.carrierCircuits,
    table: carrierCircuits.table,
    suppliers: helpers.suppliers
  };
}

export default connect(mapStateToProps)(CarrierCircuits);
