import { apiRequest } from '../Api/ApiRequest';

export function api_getServiceDbL2tpOptions() {
  return apiRequest({
    url: '/sdb/l2tps?include=carrier',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}