import React, { Component, useEffect, useState } from 'react'
import { getConfigBackups, searchConfigBackups } from '../../utils/Compliance/ComplianceService';
import { SpinnerInfo } from '../../components/Spinner/Spinner';
import { Card, CardBody, Col, Row, Form, Input, Button } from 'reactstrap';
import ReactTable from 'react-table-v6';
import moment from 'moment';
import PropTypes from 'prop-types';
import { errorFeedback } from '../../actions/feedback';
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const ConfigBackups = () => {

  // router
  const navigate = useNavigate();

  // redux

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setLoading(true);
    getConfigBackups().then((result) => {
      if (result.status === 200) {
        setData(result.data);
      }
      setLoading(false);
    }).catch((error) => {
      alert(error);
      setLoading(false);
    });
  }, []);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.length < 5) {
      dispatch(errorFeedback('Must enter a search term of at least 5 characters'));
    } else {
      setLoading(true);
      searchConfigBackups(searchTerm).then((result) => {
        if (result.status === 200) {
          setData(result.data);
        }
        setLoading(false);
      }).catch((error) => {
        alert(error);
        setLoading(false);
      });
    }
  };

  const columns = [
    {
      filterable: true,
      sortable: true,
      Header: 'Hostname',
      accessor: 'hostname'
    },
    {
      id: 'date',
      filterable: false,
      sortable: true,
      Header: 'Modified',
      accessor: d => moment(d.date_modified).format()
    },
    {
      id: 'ago',
      filterable: false,
      sortable: true,
      Header: 'Period',
      accessor: d => moment(d.date_modified).fromNow()
    }
  ];

  return (
    <div>
      {loading && (
        <div className="row justify-content-center">
          <SpinnerInfo />
        </div>
      )}
      {!loading && (
        <Row style={{ marginBottom: '10px' }}>
          <Col className={'d-flex'}>
            <Card className="w-100">
              <CardBody>
                <Form onSubmit={handleSearch}>
                  <Row>
                    <Col md={4}>
                      <Input
                        type="text"
                        name="query"
                        id="query"
                        value={searchTerm}
                        placeholder="search configs for..."
                        onChange={handleSearchTermChange}
                      />
                    </Col>
                    <Col>
                      <Button onClick={handleSearch}>
                        <i className="fa fa-search" /> Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <ReactTable
                  getTheadProps={() => ({ style: { border: 'none' } })}
                  getTheadTrProps={() => ({ style: { border: 'none' } })}
                  getTheadThProps={() => ({ style: { border: 'none' } })}
                  getTdProps={(state, rowInfo, column) => ({
                    style: { border: 'none', fontWeight: column.Header === 'Name' ? 'bold' : 'regular' }
                  })}
                  getTrProps={(state, rowInfo) => ({
                    style: { cursor: rowInfo ? 'pointer' : 'auto' },
                    onClick: () => {
                      navigate('/compliance/configbackup/' + rowInfo.row.hostname);
                    }
                  })}
                  className="animated fadeIn -highlight -striped"
                  noDataText="No backed up hosts found."
                  data={data}
                  columns={columns}
                  minRows="1"
                  filterable={false}
                  defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                  defaultSorted={[{ id: 'date', desc: true }]}
                  showPagination={data.length > 20}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ConfigBackups;
