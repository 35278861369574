import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import React from 'react';
import { CarrierCircuitEnums } from '../../../utils/Constants/CarrierCircuits';
const CarrierCircuitForm = (handleClick, types) =>  {
  return {

    main: {
      id: {
        label: 'ID',
        type: 'text',
        plaintext: true,
        width: 12,
        show: (field, data) => data.id
      },
      name: {
        label: 'Name',
        type: 'text',
        mandatory: true,
        width: 12
      },
      type: {
        label: 'Type',
        type: 'select',
        mandatory: true,
        plaintext: (key, field, data) => Boolean(data.id),
        callBack: (key, field, data) => data.id ? types.find(type => type.value === data.type)?.label : data.type,
        width: 12
      },
      serviceType: {
        label: 'Service Type',
        type: 'select',
        width: 12
      },
      bearerSpeed: {
        label: 'Bearer',
        type: 'select',
        show: (field, data) => data.type && !isEmpty(types.find(type => type.value === data.type)?.bearerSpeeds),
        mandatory: (data) => data.type === CarrierCircuitEnums.type.ETHERNET || data.type === CarrierCircuitEnums.type.LIT_WAVELENGTH,
      },
      cdr: {
        label: 'CDR',
        type: 'select',
        show: (field, data) => data.type === CarrierCircuitEnums.type.ETHERNET,
        mandatory: (data) => data.type === CarrierCircuitEnums.type.ETHERNET,
      },
      radiusUsername: {
        label: 'Radius Username',
        type: 'text',
        show: (field, data) =>  [CarrierCircuitEnums.type.ADSL, CarrierCircuitEnums.type.SOGEA, CarrierCircuitEnums.type.SOADSL,  CarrierCircuitEnums.type.FTTP].includes(data.type),
        callBack: (key, field, data) => data.radiusUsername ?? data.crmCircuit?.radiusUsername
      },
      description: {
        label: 'Description',
        type: 'textarea',
        width: 12,
        rows: 15
      },
      connection: {
        label: 'Connection',
        type: 'custom',
        width: 12,
        callBack: (key, field, data) => data.component?.connection?.id ? <Link to={`/sdb/connections/${data.component?.connection?.id}`}  style={{ textDecoration: 'none' }}>
          <Button className={'faded-success'} block>{data.component?.connection?.name}</Button>
        </Link> : 'n/a',
      }
    },
    supplier: {
      supplier: {
        label: 'Supplier',
        type: 'select',
        mandatory: true
      },
      orderStatus: {
        label: 'Order Status',
        type: 'select',
      },
      carrierReference: {
        label: 'Carrier Reference',
        type: 'text',
      },
      secondaryCarrierReference: {
        label: 'Secondary Reference',
        type: 'text',
      },
      tailCarrierReference: {
        label: 'Tail Reference',
        type: 'text',
      },
      carrierJobNumber: {
        label: 'Job Number',
        type: 'text'
      },
      etherwayCode: {
        label: 'Etherway Code',
        type: 'text'
      },
      etherflowCode: {
        label: 'Etherflow Code',
        type: 'text'
      },
      carrierAcceptedDate: {
        label: 'Accepted Date',
        type: 'date'
      },
      carrierDeliveryDate: {
        label: 'Delivery Date',
        type: 'date'
      },
      actualDeliveryDate: {
        label: 'Actual Delivery Date',
        type: 'date'
      },
      cancellationDate: {
        label: 'Cancellation Date',
        type: 'date'
      },
      orderDate: {
        label: 'Order Date',
        type: 'date'
      },
      ceaseDate: {
        label: 'Cease Date',
        type: 'date'
      },
    },
    connectionAEnd: {
      aEndType: {
        label: 'A End (Type)',
        type: 'select',
        mandatory: true,
        callBack: (key, field, data) => data?.aEnd?.type ?? '',
        plaintext: (key, field, data) => Boolean(data.id),
      },
      aEnd: {
        label: 'A End',
        type: (key, field, data) => data.aEnd?.type !== 'rack' ? 'select' : 'select-async',
        entity: (data) => data.aEnd?.type,
        mandatory: true,
        readOnly: (key, field, data) => !data.aEnd?.type,
        handleClick: () => handleClick('aEnd'),
      },
      aSTag: {
        label: 'A End S-Tag',
        type: 'number',
        min: 1,
        max: 2147483647,
        mandatory: (data) => data?.component?.connection?.aEnd?.type === 'nni' && !data?.bSTag,
        show: (field, data) => data.type === CarrierCircuitEnums.type.ETHERNET
      },
    },
    connectionBEnd: {
      bEndType: {
        label: 'B End (Type)',
        type: 'text',
        callBack: (key, field, data) => 'rack',
        plaintext: true,
      },
      bEnd: {
        label: 'B End',
        type: 'select-async',
        entity: 'rack',
        mandatory: true,
        placeholder: 'Search by name or account number...',
        handleClick: () => {
          handleClick('bEnd')
        },
        optionLabelCallback: (opt) => `${opt.name} (${opt.site?.accountnumber})`,
      },
      bSTag: {
        label: 'B End S-Tag',
        type: 'number',
        min: 1,
        max: 2147483647,
        mandatory: (data) => data?.component?.connection?.aEnd.type === 'nni' && !data?.aSTag
      },
    }
  }
};
export default CarrierCircuitForm;
