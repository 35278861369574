import React from 'react';
import ZabbixStatusBadge from '../../../components/Zabbix/ZabbixStatusBadge';

const DeviceDeploymentForm = (handleClick, inModal) => {
  return {
    main: {
      zabbix: {
        label: 'Zabbix Status',
        type: 'custom',
        callBack: (key, field, data) => <ZabbixStatusBadge {...data.zabbix} />,
        show: (field, data) => Boolean(data.id)
      },
      stockItem: {
        label: 'Stock Item',
        type: 'select-async',
        entity: 'stockItem',
        optionLabelCallback: (opt) => opt.serialNumber ? `${opt.assetTag || ''} (${opt.serialNumber}) [${opt.deviceModel.vendor.name} ${opt.deviceModel.name}]` : '',
        mandatory: true,
        width: 12,
        handleClick: () => handleClick('stockItem'),
      },
      hostname: {
        label: 'Hostname',
        type: 'text',
        mandatory: true,
        width: 12
      },
      description: {
        label: 'Description',
        type: 'text',
        width: 12
      },
      site: {
        label: 'Assigned To',
        type: 'select-async',
        entity: 'site',
        mandatory: true,
        optionLabelCallback: (opt) => `${opt.name} (${opt.accountnumber})`,
        width: 12,
        handleClick: () => {
          handleClick('site')
        },
      },
      type: {
        label: 'Device Type',
        type: 'select',
        mandatory: true
      },
      psuCount: {
        label: 'PSU Count',
        type: 'number',
        mandatory: true,
        min: 0,
        max: 8
      },
      owner: {
        label: 'Owner',
        type: 'select',
        mandatory: true
      },
    },
    rack: {
      rack: {
        label: 'Rack',
        type: 'select-async',
        entity: 'rack',
        mandatory: true,
        optionLabelCallback: (opt) => opt.name ? opt.name + ' (' + opt.size + 'U @' + opt.site?.name + ')' : '',
        readOnly: (key, field, data) => data.definedRack,
        width: 12,
        handleClick: () => handleClick('rack'),
      },
      rackStartPosition: {
        label: 'Starting U Position (from the bottom of the rack)',
        type: 'number',
        mandatory: true,
        min: 1,
        width: inModal ? 12 : 6
      },
      rackOrientation: {
        label: 'Orientation',
        type: 'select',
        mandatory: true,
        width: inModal ? 12 : 6
      },
      rackSide: {
        label: 'Side',
        type: 'select',
        mandatory: (data) => data.stockItem?.deviceModel?.rackWidth !== 'full',
        show: (field, data) => data.stockItem?.deviceModel?.rackWidth !== 'full',
        width: inModal ? 12 : 6
      },
    }
  }
};

export default DeviceDeploymentForm;