import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

const CollapsibleCard = ({
  title,
  open,
  children,
  headerExtraRight,
  headerExtraLeft,
  onEntering
}) => {

  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    setCollapsed(open)
  }, [open])
  const toggle = () => setCollapsed(prevState => !prevState);

  return  (
    <Card className={'shadow-sm'}>
      <CardHeader onClick={toggle} className={"d-flex justify-content-between font-weight-bold bg-secondary text-primary font-lg hoverPointer"}>
        {title}
        {headerExtraRight}
        {headerExtraLeft}
        <span
          className={classnames({'ml-auto': !headerExtraLeft})}
        >
          <i className={`fa fa-lg ${collapsed ? 'fa-caret-up' : 'fa-caret-down'}`}/>
        </span>
      </CardHeader>
      <Collapse
        isOpen={collapsed}
        onEntering={onEntering}
      >
        <CardBody>
          {children}
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default CollapsibleCard;
