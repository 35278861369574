import { Badge } from 'reactstrap';
import React from 'react';

const RedFileSignOffBadge = ({
  state,
  minWidth
}) => {

  const getBadgeColor = () => {
    switch (state) {
      case 'Approved':
        return 'success';
      case 'Rejected':
        return 'danger';
      case 'Pending':
        return 'warning'
      default:
        return '';
    }
  }

  return (
    state ? <Badge style={{minWidth: minWidth || '68px'}} className={`p-2 faded-${getBadgeColor()}`}> { state }</Badge> : ''
  )
}

export default RedFileSignOffBadge