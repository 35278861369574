

const bandwidthConverter = (bandwidth) => {
  if (bandwidth > 999) {
    return (bandwidth / 1000) + 'Gbps';
  } else {
    return bandwidth + 'Mbps';
  }
}

export default bandwidthConverter