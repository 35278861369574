import { deepFreeze } from '../Helpers/DeepFreeze';

export const PriceListEnums = deepFreeze({
  status: {
    ACTIVE: 1,
    DRAFT : 2,
    DISABLED : 3,
  },
  type: {
    ENTERPRISE: 1,
    WHOLESALE: 2,
    PORTAL: 3,
    ENTERPRISE_KENYA: 4,
  }
})