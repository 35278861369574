import React, { useEffect, useState } from 'react';
import {connect} from "react-redux";
import {netapi_getDevices} from "../../../actions/netapi";
import {Card, CardBody, Col, Row, Spinner, Badge} from "reactstrap";
import { Link, useNavigate } from 'react-router-dom'
import ReactTable from "react-table-v6";

const Devices = (props) => {

  // router
  const navigate = useNavigate();

  useEffect(() => {
    props.fetchDevices();
  }, []);

  if(props.netapi.devices.loading !== 0 || props.netapi.devices.data.length === 0) {
    return (<Spinner/>);
  }

  const columns = [{
    filterable: true,
    sortable: true,
    Header: 'Hostname',
    accessor: 'hostname'
  }, {
    filterable: true,
    sortable: true,
    Header: 'IP Address',
    accessor: 'ipaddr'
  },
    {
      filterable: true,
      sortable: true,
      Header: 'Device Type',
      accessor: 'dtype'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Table Type',
      accessor: 'bgp_table_type'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Network',
      accessor: 'network.name'
    }, {
      filterable: true,
      sortable: true,
      Header: 'RR Group',
      accessor: 'rr_group_membership.rrgroup.group_name',
      Cell: (cprops) => {
        if(cprops.value !== undefined) {
          return (<span>
            {cprops.value}{' '}
            {cprops.original.rr_group_membership?.is_primary && <Badge color={"warning"}>PRI</Badge>}
            {cprops.original.rr_group_membership?.is_secondary && <Badge color={"info"}>SEC</Badge>}
          </span>);
        }
        if(props.netapi.devices.data.filter((d) => d.dtype === 'vrr' &&
          d.network.id === cprops.original.network.id).length &&
          cprops.original.dtype !== 'vrr'
        ) {
          return(<span><Badge color={"danger"}>No VRR Set</Badge></span>);
        }
        return(<span>-</span>)
      }
    }, {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'hostname',
      width: 50,
      Cell: props => (
        <Link to={'/toolbox/devices/' + props.value}><i className="icon-arrow-right"></i></Link>
      )
    }
  ];

  const history = props.history;

  return (
    <Row>
      <Col className={'d-flex'}>
        <Card className="w-100">
          <CardBody>
            <ReactTable
              getTheadProps={() =>{
                return {
                  style: { border: 'none' }
                };
              }}
              getTheadTrProps={() =>{
                return {
                  style: { border: 'none' }
                };
              }}
              getTheadThProps={() =>{
                return {
                  style: { border: 'none' }
                };
              }}
              getTdProps={(state, rowInfo, column) =>{
                return {
                  style: { border: 'none', fontWeight: column.Header === 'Name' ? 'bold' : 'regular' }
                };
              }}
              getTrProps={(state, rowInfo, column) =>{
                return {
                  style: { cursor: rowInfo ? 'pointer' : 'auto' },
                  onClick: () => {
                    navigate('/toolbox/devices/' + rowInfo.row.hostname);
                  }
                };
              }}
              className="animated fadeIn -highlight -striped"
              noDataText="No Prefix Lists found."
              data={props.netapi.devices.data}
              columns={columns}
              minRows="1"
              filterable={false}
              defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
              defaultSorted={[{ id: 'hostname', desc: false }]}
              showPagination={props.netapi.devices.data.length > 20}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({netapi}) => ({netapi});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDevices: () => dispatch(netapi_getDevices())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
