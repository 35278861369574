import {netApiRequest} from '../Api/ApiRequest';
import {stringify} from "query-string";

const HEADERS = {"content-type": "application/json"}

export function getJsonSchema(template) {
  return netApiRequest({
    url: `/configtemplate/${template}/generate`,
    method: 'get'
  }).then(
      resp => resp
  ).catch(
      error => Promise.reject(error)
  );
}

export function configEntriesAll(url, data = {}) {
  return netApiRequest({
    url: `/configtemplate`,
    method: 'get',
    data: JSON.stringify(data)
  }).then(
      resp => resp
  ).catch(
      error => error
  );
}

export function postConfigEntry(data = {}) {
  return netApiRequest({
    url: `/configtemplate`,
    method: 'post',
    data: data
  }).then(
      resp => resp
  ).catch(
      error => error
  );
}

export function configEntriesTags(query, data = {}) {
  return netApiRequest({
    url: `/configtemplate?/${stringify({...query})}`,
    method: 'get',
    data: JSON.stringify(data)
  }).then(
      resp => resp
  ).catch(
      error => error
  );
}

export function configEntry(entry) {
  return netApiRequest({
    url: `/configtemplate/${entry}`,
    method: 'get',
  }).then(
      resp => resp
  ).catch(
      error => error
  );
}

export function configTemplate(template) {
  return netApiRequest({
    url: `/configtemplate/${template}/template`,
    method: 'get',
  }).then(
      resp => resp
  ).catch(
      error => error
  );
}

export function putConfigTemplate(template, data = {}) {
  return netApiRequest({
    url: `/configtemplate/${template}/template`,
    method: 'put',
    data: data
  }).then(
      resp => resp
  ).catch(error => Promise.reject(error.response || error.message))
}

export function linkChildTemplate(template, data = {}) {
  return netApiRequest({
    url: `/configtemplate/${template}/template/link`,
    method: 'put',
    data: JSON.stringify(data),
    headers: HEADERS
  }).then(
      resp => resp
  ).catch(
      error => error
  );
}

export function updateEntry(template, data = {}) {
  return netApiRequest({
    url: `/configtemplate/${template}`,
    method: 'put',
    data: JSON.stringify(data)
  }).then(
      resp => resp
  ).catch(
      error => error
  );
}

export function deleteChildTemplate(template, data) {
  return netApiRequest({
    url: `/configtemplate/${template}/template/link`,
    method: 'delete',
    data: JSON.stringify(data),
    headers: HEADERS
  }).then(
      resp => resp
  ).catch(
      error => error
  );
}

export function deleteEntry(template) {
  return netApiRequest({
    url: `/configtemplate/${template}`,
    method: 'delete'
  }).then(
      resp => resp
  ).catch(
      error => error
  );
}

export function generateConfigTemplate(template, data = {}) {
  return netApiRequest({
    url: `/configtemplate/${template}/generate`,
    method: 'post',
    data: JSON.stringify(data),
    headers: HEADERS
  }).then(
      resp => resp
  ).catch(error => Promise.reject(error.response || error.message))

}

export function configTemplateRequest(template, url, method, data = {}) {
  return netApiRequest({
    url: `/` + url,
    method: method,
    data: data,
    headers: HEADERS
  }).then(
      resp => resp
  ).catch(
      error => error
  );
}
