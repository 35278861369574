import React from 'react';
import AsyncCreatable from 'react-select/async-creatable';

const styles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: '34px',
    backgroundColor: state.isDisabled ? '#e4e6eb' : 'white',
    borderColor: '#e4e6eb',
    overflow: 'hidden'
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#e4e6eb' : 'white'
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: '2px 8px'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '2px 8px'
  }),
  loadingIndicator: (styles) => ({
    ...styles,
    padding: '2px 8px'
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 3 // Without this menu will be overlapped by other fields
  })
};
export const AsyncCreatableSelectMod = (props) => {
  return <AsyncCreatable styles={styles} {...props} />;
};
