import React, {Component} from 'react';
import {connect} from "react-redux";
import {Badge, Card, CardHeader, CardBody, Col, PopoverBody, PopoverHeader, Row, UncontrolledPopover} from "reactstrap";
import Loadable from "react-loading-overlay";
import CaseSummary from "../../components/Cases/CaseSummary";
import EntitySubFormCard from '../../components/Cards/EntitySubFormCard';

var moment = require('moment');


class DeviceAlarms extends Component {

    constructor(props) {
        super(props);
        this.findCases = this.findCases.bind(this);
    }

    findCases(events) {
        let cases = {};
        let ix = 0;
        for(let i=0; i<events.length; i++) {
            let caseref = events[i]['message'].match(/CAS-([0-9]{6})-([A-Z0-9]{4})/)
            if(caseref !== null) {
                cases[caseref[0]] = 1;
            }
            ix = events[i]['eventid'];
        }
        if(Object.keys(cases).length > 0) {
            return(<p>{Object.keys(cases).map((cr) =>
                <div key={cr}>
                    <Badge color="warning" id={"b_"+ix+"_"+cr}>{cr}</Badge>
                    <UncontrolledPopover trigger="legacy" placement="bottom" target={"b_"+ix+"_"+cr}>
                        <PopoverHeader>{cr}</PopoverHeader>
                        <PopoverBody>
                            <CaseSummary caseId={cr}/>
                        </PopoverBody>
                    </UncontrolledPopover>
                </div>
            )}</p>)
        }
        else {
            return(<div></div>)
        }
    }

    sort = alarms => {

        return alarms.sort((a, b) => {
            let aValue = a[this.props.sortBy ? this.props.sortBy : 'lastchange'];
            let bValue = b[this.props.sortBy ? this.props.sortBy : 'lastchange'];

            // Support string comparison
            const sortTable = { true: 1, false: -1 };

            // Order descending (Order.DESC)
            return bValue === aValue ? 0 : sortTable[bValue > aValue]
        })
    };

    render() {
        const colMap = {0: '#AFAFAF', 1: '#67c2ef', 2: '#FFC859', 3: '#FFA059', 4: '#E97659', 5: '#E45959'};
        if(this.props.compact === true) {
            return(
              <EntitySubFormCard
                title={`${this.props.alarms.filter(alarm => alarm['hosts'][0]['host'] === this.props.hostname).length} Alarms`}
              >
                  {this.sort(this.props.alarms.filter(alarm => alarm['hosts'][0]['host'] === this.props.hostname)).map((alarm) =>
                    <Row key={alarm.triggerid} style={{padding: '5px', borderLeftColor: colMap[alarm.priority], borderBottomWidth: '1px', marginBottom: '10px'}} className={"callout callout-info"}>
                        <Col md={12}>
                            {alarm.description}
                            {(alarm.triggerid in this.props.processing && this.props.processing[alarm.triggerid] === true)&& <Loadable active={true} spinner color={"rgb(62,81,91)"} spinnerSize={"24px"} style={{float: 'right'}}/>}
                        </Col>
                        <Col md={6}>{moment(alarm.lastchange*1000).fromNow()}</Col>
                        <Col md={6} style={{whiteSpace: 'nowrap'}}>{alarm.lastEvent.eventid in this.props.acks == 1 ?
                          <div>
                              <Badge color="success" id={"acks_"+alarm.lastEvent.eventid}>Acknowledged ({this.props.acks[alarm.lastEvent.eventid].length})</Badge>
                              <UncontrolledPopover trigger="legacy" placement="bottom" target={"acks_"+alarm.lastEvent.eventid}>
                                  <PopoverHeader>Acknowledges</PopoverHeader>
                                  <PopoverBody>
                                      {this.props.acks[alarm.lastEvent.eventid].map((ack) =>
                                        <div key={ack.acknowledgeid}>
                                            {ack['alias']} acked {moment(ack['clock']*1000).fromNow()}
                                            <pre style={{backgroundColor: '#eaeaea', border: '1px solid #eaeaea', borderRadius: '4px', padding: '4px'}}>{ack['message']}</pre>
                                        </div>
                                      )}
                                  </PopoverBody>
                              </UncontrolledPopover>
                              {alarm.lastEvent.eventid in this.props.filters && <div><Badge color="dark"><i className="icon-eye"></i> Filtered</Badge></div>}
                              {alarm.lastEvent.eventid in this.props.acks && this.findCases(this.props.acks[alarm.lastEvent.eventid])}
                          </div>
                          : <Badge>No Ack</Badge>}</Col>
                    </Row>
                  )}
              </EntitySubFormCard>
            )
        }
        return(
            <div>
                <h6>{this.props.alarms.filter(alarm => alarm['hosts'][0]['host'] === this.props.hostname).length} Alarms</h6>
                {this.sort(this.props.alarms.filter(alarm => alarm['hosts'][0]['host'] === this.props.hostname)).map((alarm) =>
                    <Row key={alarm.triggerid} style={{padding: '5px', borderLeftColor: colMap[alarm.priority], borderBottomWidth: '1px', marginBottom: '10px'}} className={"callout callout-info"}>
                        <Col md={2} style={{whiteSpace: 'nowrap'}}>{alarm.lastEvent.eventid in this.props.acks == 1 ?
                            <div>
                                <Badge color="success" id={"acks_"+alarm.lastEvent.eventid}>Acknowledged ({this.props.acks[alarm.lastEvent.eventid].length})</Badge>
                                <UncontrolledPopover trigger="legacy" placement="bottom" target={"acks_"+alarm.lastEvent.eventid}>
                                    <PopoverHeader>Acknowledges</PopoverHeader>
                                    <PopoverBody>
                                        {this.props.acks[alarm.lastEvent.eventid].map((ack) =>
                                            <div key={ack.acknowledgeid}>
                                                {ack['alias']} acked {moment(ack['clock']*1000).fromNow()}
                                                <pre style={{backgroundColor: '#eaeaea', border: '1px solid #eaeaea', borderRadius: '4px', padding: '4px'}}>{ack['message']}</pre>
                                            </div>
                                        )}
                                    </PopoverBody>
                                </UncontrolledPopover>
                                {alarm.lastEvent.eventid in this.props.filters && <div><Badge color="dark"><i className="icon-eye"></i> Filtered</Badge></div>}
                                {alarm.lastEvent.eventid in this.props.acks && this.findCases(this.props.acks[alarm.lastEvent.eventid])}
                            </div>
                            : <Badge>No Ack</Badge>}</Col>
                        <Col md={2}>{moment(alarm.lastchange*1000).fromNow()}</Col>
                        <Col md={2}>{alarm.hosts[0].host}</Col>
                        <Col md={5}>
                            {alarm.description}
                            {(alarm.triggerid in this.props.processing && this.props.processing[alarm.triggerid] === true)&& <Loadable active={true} spinner color={"rgb(62,81,91)"} spinnerSize={"24px"} style={{float: 'right'}}/>}
                        </Col>
                    </Row>
                )}
            </div>
        )
    }
}

function mapStateToProps ({zabbixAlarms}) {
    return {
        alarms: zabbixAlarms.alarms,
        acks: zabbixAlarms.acks,
        filters: zabbixAlarms.filters,
        processing: zabbixAlarms.processing
    }
}

export default connect(mapStateToProps)(DeviceAlarms)
