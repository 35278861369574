import { NNIS_SET_DATA } from '../actions/nnis';

const initialState = {
  nnis: []
};
const nnis = (state = initialState, action) => {
  switch (action.type) {
    case NNIS_SET_DATA:
      return {
        ...state,
        nnis: action.data
      }
    default:
      return state;
  }
};

export default nnis;