import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString';

export function api_getPriceLists() {
  let query = {
    include: 'currency,type,status,company',
    with:  'currency;status;company;type',
  }
  return apiRequest({
    url: `/pricelists${queryStringFromFields(query)}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getPriceList(id, { includes, withs } = {}){
  const query = {
    include: includes?.join(','),
    with: withs?.join(';')
  }
  return apiRequest({
    url: `/pricelists/${id}${queryStringFromFields(query)}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getPriceListItems(id, productCodes, { includes, withs } = {}){
  let query = {
    search: productCodes ? `productTemplate.product_code:${productCodes.join(',')}` : undefined,
    searchFields: productCodes ? `productTemplate.product_code:in` : undefined,
    include: includes?.join(','),
    with: withs?.join(';')
  }
  return apiRequest({
    url: `/pricelists/${id}/items${queryStringFromFields(query)}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_createPriceList(data, { includes, withs } = {}){
  const query = {
    include: includes?.join(','),
    with: withs?.join(';')
  }
  return apiRequest({
    url: `/pricelists${queryStringFromFields(query)}`,
    method: 'post',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_activatePriceList(id, { includes, withs } = {}) {
  const query = {
    include: includes?.join(','),
    with: withs?.join(';')
  }
  return apiRequest({
      url: `/pricelists/${id}/activate${queryStringFromFields(query)}`,
    method: 'put'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_disablePriceList(id, { includes, withs } = {} ){
  const query = {
    include: includes?.join(','),
    with: withs?.join(';')
  }
  return apiRequest({
    url: `/pricelists/${id}/disable${queryStringFromFields(query)}`,
    method: 'put'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_deletePriceList(id){
  return apiRequest({
    url: `/pricelists/${id}`,
    method: 'delete'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updatePriceList(id, data, { includes, withs } = {}){
  let query = {
    include: includes?.join(','),
    with: withs?.join(';')
  }
  return apiRequest({
    url: `/pricelists/${id}${queryStringFromFields(query)}`,
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_createPriceListItem(id, item, { includes, withs } = {}){
  let query = {
    include: includes?.join(','),
    with: withs?.join(';')
  }
  return apiRequest({
    url: `/pricelists/${id}/items${queryStringFromFields(query)}`,
    method: 'post',
    data: item
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updatePriceListItem(id, itemId, data, { includes, withs } = {}){
  let query = {
    include: includes?.join(','),
    with: withs?.join(';')
  }
  return apiRequest({
    url: `/pricelists/${id}/items/${itemId}${queryStringFromFields(query)}`,
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_deletePriceListItem(id, itemId){
  return apiRequest({
    url: `/pricelists/${id}/items/${itemId}`,
    method: 'delete',
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_lookupPriceListItem(id, search, { includes, withs } = {}){
  let query = {
    search: search ? `productTemplate.product_code:${search};productTemplate.name:${search}` : undefined,
    searchFields: search ? `productTemplate.product_code:like;productTemplate.name:like` : undefined,
    include: includes?.join(','),
    with: withs?.join(';')
  }
  return apiRequest({
    url: `/pricelists/${id}/items${queryStringFromFields(query)}`,
    method: 'get',
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

