import { apiRequest } from '../Api/ApiRequest';

export const CasesService = {

  getCaseSummary: (caseId) => {
    return apiRequest({
      url: '/cases/' + caseId + '/summary',
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
};

export const CASE_STATE_ACTIVE = 0;
export const CASE_STATE_RESOLVED = 1;
export const CASE_STATE_CANCELED = 2;

export const stateMap = [
  {'id': CASE_STATE_ACTIVE, 'label': 'Active'},
  {'id': CASE_STATE_RESOLVED, 'label': 'Resolved'},
  {'id': CASE_STATE_CANCELED, 'label': 'Canceled'},
];

export const resolveState = (stateCode) => {
  return stateMap.find(state => state.id === stateCode);
}

export const api_getCase = (caseId, includesArr = []) => {
  let includes = includesArr ? '?include=' + includesArr.join(',') : '';
  return apiRequest({
    url: '/cases/' + caseId + includes,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );

};

export const api_getCases = (args) => {
  return apiRequest({
    url: '/cases',
    method: 'get',
    params: args
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export const api_searchCases = (number) => {
  return apiRequest({
    url: '/cases/search/' + number,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export const api_createCase = (data) => {
  return apiRequest({
    url: '/cases?include=customer,owner',
    method: 'post',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export const api_updateCase = (id, data) => {
  return apiRequest({
    url: `/cases/${id}?include=customer,owner`,
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export const api_getAccountCaseData = (accountId, idOrNumber, includesArr = []) => {
  let includes = includesArr ? '?include=' + includesArr.join(',') : '';
  return apiRequest({
    url: `accounts/${accountId}/cases/${idOrNumber}${includes}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export const api_getCaseNotes = (idOrNumber) => {
  return apiRequest({
    url: `/cases/${idOrNumber}/notes`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export const api_createCaseNote = (id, data) => {
  return apiRequest({
    url: `/cases/${id}/notes`,
    method: 'post',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export const api_getCaseEmails = (idOrNumber) => {
  return apiRequest({
    url: `/cases/${idOrNumber}/emails`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};
