import {
  LEAD_RESET,
  LEAD_SET_AUDIT_HISTORY,
  LEAD_SET_DATA,
  LEADS_UPDATE_FORM_FIELD
} from '../actions/lead';

const initialState = {
  form: {
    bulkEmail: true,
    sendEmail: true,
    callPhone: true,
    postalMail: true,
    followEmail: true,
    marketingMaterial: true,
    calledIn: false,
    potentialBusinessPartner: false,
    emailEnquiry: false
  },
  original: {},
  audits: []
};

export function lead(state = initialState, action) {
  switch (action.type) {
    case LEAD_SET_DATA:
      return {
        ...state,
        form: action.lead,
        original: action.lead
      };
    case LEAD_RESET:
      return {
        ...initialState
      };
    case LEADS_UPDATE_FORM_FIELD:
      return {
        ...state,
        form: { ...state.form, ...action.field }
      };
    case LEAD_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;
  }
}
