import {
  PRICING_TOOL_QUOTE_ADD_SITE,
  PRICING_TOOL_QUOTE_REQUEST_ADD_SITE,
  PRICING_TOOL_QUOTE_REQUEST_REMOVE_SITE,
  PRICING_TOOL_QUOTE_REQUEST_UPDATE_FIELD,
  PRICING_TOOL_QUOTE_REQUEST_UPDATE_SITE_FIELD, PRICING_TOOL_QUOTE_REQUEST_UPDATE_SITE_SOLUTION,
  PRICING_TOOL_QUOTE_RESET,
  PRICING_TOOL_QUOTE_RESET_SITES,
  PRICING_TOOL_QUOTE_RESTORE_P2P_REQUEST,
  PRICING_TOOL_QUOTE_RESTORE_REQUEST,
  PRICING_TOOL_QUOTE_SET_DATA, PRICING_TOOL_QUOTE_SET_NOT_FOUND,
  PRICING_TOOL_QUOTE_SET_SOLUTION,
  PRICING_TOOL_QUOTE_UPDATE_FIELD,
  PRICING_TOOL_QUOTE_UPDATE_ORIGINAL,
  PRICING_TOOL_QUOTE_UPDATE_SITE_PREFERRED_PRICE
} from '../actions/pricingToolQuote';
import { v4 } from 'uuid';
import startsWith from 'lodash.startswith'

const defaultSite = {
  bearer_speed: '',
  cdr: '',
  term: '',
  kit: '',
  install: '',
  postcode: '',
  address_id: '',
  addresses: [],
  udprn: '',
  a_address_id: '',
  b_address_id: '',
  a_postcode: '',
  b_postcode: '',
  a_addresses: [],
  b_addresses: [],
  include_carriers: [],
  exclude_carriers: [],
  include_nnis: [],
  exclude_nnis: []
};
const initialState = {
  notFound: false,
  data: {
    sites: [],
    p2p: [],
    network: {},
    widget: {}
  },
  original: {},
  request: {
    name: '',
    feasibility: null,
    sites: [],
    result: {}
  }
};

export function pricingToolQuote(state = initialState, action) {
  switch (action.type) {
    case PRICING_TOOL_QUOTE_SET_DATA:
      return {
        ...state,
        data: action.quote,
        original: action.quote
      };
    case PRICING_TOOL_QUOTE_RESET:
      return initialState;
    case PRICING_TOOL_QUOTE_UPDATE_FIELD:
      return {
        ...state,
        data: { ...state.data, ...action.field }
      };
    case PRICING_TOOL_QUOTE_UPDATE_ORIGINAL:
      return {
        ...state,
        original: { ...state.data }
      };
    case PRICING_TOOL_QUOTE_REQUEST_UPDATE_FIELD:
      return {
        ...state,
        request: {
          ...state.request,
          ...action.field
        }
      };
    case PRICING_TOOL_QUOTE_REQUEST_UPDATE_SITE_FIELD:
      return {
        ...state,
        request: {
          ...state.request,
          sites: state.request.sites.map((site, index) => {
            if (index === action.siteIndex) {
              return { ...site, ...action.field };
            }
            return site;
          })
        }
      };
    case PRICING_TOOL_QUOTE_REQUEST_UPDATE_SITE_SOLUTION:
      return {
        ...state,
        request: {
          ...state.request,
          sites: state.request.sites.map((site, index) => {
            if (index === action.siteIndex) {
              return { ...defaultSite, solution: action.solution }
            }
            return site;
          })
        }
      };
    case PRICING_TOOL_QUOTE_REQUEST_ADD_SITE:
      return {
        ...state,
        request: {
          ...state.request,
          sites: [...state.request.sites, {
            ...defaultSite,
            ...action.fields,
            uuid: v4()
          }]
        }
      };
    case PRICING_TOOL_QUOTE_REQUEST_REMOVE_SITE:
      return {
        ...state,
        request: {
          ...state.request,
          sites: state.request.sites.filter(site => site.uuid !== action.siteId)
        }
      };
    case PRICING_TOOL_QUOTE_UPDATE_SITE_PREFERRED_PRICE:
      const siteFound = state.data.sites.length ?
        state.data.sites.find(site => site.id === action.siteId)
        : state.data.p2p.find(site => site.id === action.siteId);
      const priceFound = siteFound.prices.find(price => price.id === action.priceId);
      const oldPreferredPrice = siteFound.prices.find(price => price.preferred);
      const resolvePreferred = (site, priceId) => {
        if (site.id === action.siteId) {
          return {
            ...site,
            prices: site.prices.map(price => {
              if (price.preferred) {
                return {
                  ...price,
                  preferred: false
                };
              }
              if (price.id === priceId) {
                return {
                  ...price,
                  preferred: true
                };
              }
              return price;
            })
          };
        } else {
          return site;
        }
      };
      return {
        ...state,
        data: {
          ...state.data,
          rental: state.data.rental - oldPreferredPrice.rental + priceFound.rental,
          install: state.data.install - oldPreferredPrice.install + priceFound.install,
          tcv: state.data.tcv - oldPreferredPrice.tcv + priceFound.tcv,
          sites: state.data.sites.map((site) => {
            return resolvePreferred(site, action.priceId);
          }),
          p2p: state.data.p2p.map((site) => {
            return resolvePreferred(site, action.priceId);
          })
        }
      };
    case PRICING_TOOL_QUOTE_RESTORE_REQUEST:
      let sites = state.data.sites.map(site => {
        return {
          ...site,
          addresses: [{
            id: site.address_id,
            address: site.address,
            place: '',
            postCode: site.postcode
          }],
          install: site.installType.id,
          type: site.type,
          kit: site.kit.id,
          technology: site.technology.id,
          cdr: site.cdr,
          term: site.term,
          bearer: site.bearer_speed,
          product:  startsWith(site.technology.name, 'GEA') ? site.carrier_prices[0]?.product.id : null,
          include_carriers: site.include_carriers.map(carrier => carrier.id),
          exclude_carriers: site.exclude_carriers.map(carrier => carrier.id),
          include_nnis: site.include_nnis.map(nni => nni.id),
          exclude_nnis: site.exclude_nnis.map(nni => nni.id)
        };
      });
      let p2pSites = state.data.p2p.map(site => {
        return {
          ...site,
          a_addresses: [{
            id: site.a_address_id,
            address: site.a_address,
            place: '',
            postCode: site.a_postcode
          }],
          b_addresses: [{
            id: site.b_address_id,
            address: site.b_address,
            place: '',
            postCode: site.b_postcode
          }],
          cdr: site.cdr,
          bearer: site.bearer_speed,
          term: site.term,
          install: site.installType.id,
          kit: site.kit.id,
          include_carriers: site.include_carriers.map(carrier => carrier.id),
          exclude_carriers: site.exclude_carriers.map(carrier => carrier.id)
        };
      })
      return {
        ...state,
        request: {
          ...state.request,
          name: state.data.name,
          feasibility: state.data.feasibility.id,
          sites: sites.concat(p2pSites)
        }
      };
    case PRICING_TOOL_QUOTE_SET_NOT_FOUND:
      return {
        ...state,
        notFound: Boolean(action.state)
      };
    default:
      return state;
  }
}
