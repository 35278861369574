import { netApiRequest } from '../Api/ApiRequest';

export function submitDeviceCheck(device) {
  const url = '/cpecheck/' + device;
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function submitCoreDeviceCheck(device) {
  const url = '/jobs';
  return netApiRequest({
    url,
    method: 'put',
    data: {
      job: 'tasks.router_config_report',
      params: [device]
    }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function getJobStatus(jobid) {
  const url = '/jobs/' + jobid;
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}
