import { Badge } from 'reactstrap';
import React from 'react';

const LifecycleStageBadge = ({
  lifecycleStage,
  minWidth
}) => {

  const getBadgeColor = () => {
    switch (lifecycleStage) {
      case 'In Service':
        return 'success';
      default:
        return 'warning'
    }
  };

  return (
    lifecycleStage ? <Badge style={{minWidth: minWidth || '96px'}} className={`p-2 faded-${getBadgeColor()}`}> { lifecycleStage }</Badge> : ''
  )
}

export default LifecycleStageBadge