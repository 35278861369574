import {
  PRODUCT_SET_DATA,
  PRODUCT_COMPONENT_SET_DATA,
  PRODUCT_SET_FORM_DATA,
  PRODUCT_UPDATE_COMPONENT_FORM_FIELD,
  PRODUCT_UPDATE_FORM_FIELD,
  PRODUCT_UPDATE_DATA,
  PRODUCT_RESET_DATA,
  PRODUCT_ADD_NEW_NOTE,
  PRODUCT_UPDATE_NOTE,
  PRODUCT_ADD_NEW_CASE,
  PRODUCT_CASES_SET_DATA,
  PRODUCT_UPDATE_CASE,
  PRODUCT_ADD_CIRCUIT,
  PRODUCT_UPDATE_CIRCUIT,
  PRODUCT_SET_AUDIT_HISTORY,
  PRODUCT_REMOVE_NOTE, PRODUCT_SET_CIRCUITS, PRODUCT_REMOVE_CIRCUIT
} from '../actions/product';
import { act } from 'react-dom/test-utils';

const initialState = {
  form: {
    notes: [],
    cases: [],
    circuits: [],
    documents: [],
    linkedProducts: []

  },
  original: {
    notes: [],
    cases: [],
    circuits: [],
    documents: [],
    linkedProducts: []
  },
  audits: {}
};

export default function product(state = initialState, action) {
  let notes;
  switch(action.type){
    case PRODUCT_SET_DATA:
      return {
        ...state,
        form: action.product,
        original: action.product,
      }
    case PRODUCT_UPDATE_FORM_FIELD:
      return {
        ...state,
        form: { ...state.form, ...action.data }
      }
    case PRODUCT_RESET_DATA:
      return {
        ...initialState
      };
    case PRODUCT_ADD_NEW_NOTE:
      return {
        ...state,
        form: {
          ...state.form,
          notes: [action.note, ...state.form.notes]
        },
        original: {
          ...state.original,
          notes: [action.note, ...state.original.notes]
        }
      };
    case PRODUCT_UPDATE_NOTE:
      notes = state.original.notes.map(note => {
        if(note.id === action.note.id){
          return action.note
        }
        return note
      })
      return {
        ...state,
        form: {
          ...state.form,
          notes
        },
        original: {
          ...state.original,
          notes
        }
      }
    case PRODUCT_REMOVE_NOTE:
      notes = state.form.notes.filter(note => note.id !== action.note.id)
      return {
        ...state,
        form: {
          ...state.form,
          notes
        },
        original: {
          ...state.original,
          notes
        }
      }
    case PRODUCT_ADD_CIRCUIT:
      return {
        ...state,
        form: {
          ...state.form,
          circuits: [...state.form.circuits, action.circuit]
        },
        original: {
          ...state.original,
          circuits: [...state.original.circuits, action.circuit]
        }
      }
    case PRODUCT_SET_CIRCUITS:
      return {
        ...state,
        form: {
          ...state.form,
          circuits: action.circuits
        },
        original: {
          ...state.original,
          circuits: action.circuits
        }
      }
    case PRODUCT_UPDATE_CIRCUIT:
      return {
        ...state,
        form: {
          ...state.form,
          circuits: state.form.circuits.map(circuit => {
            if(circuit.id === action.circuit.id){
              return action.circuit
            }
            return circuit
          })
        },
        original: {
          ...state.original,
          circuits: state.original.circuits.map(circuit => {
            if(circuit.id === action.circuit.id){
              return action.circuit
            }
            return circuit
          })
        }
      }
    case PRODUCT_REMOVE_CIRCUIT:
      return {
        ...state,
        form: {
          ...state.form,
          circuits: state.form.circuits.filter(circuit => circuit.id !== action.id)
        },
        original: {
          ...state.original,
          circuits: state.original.circuits.filter(circuit => circuit.id !== action.id)
        }
      }
    case PRODUCT_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state
  }
}


export function productRetired(state = initialState, action) {
  let components;
  let newProduct;
  switch (action.type) {
    case PRODUCT_SET_DATA:
      return {
        ...state,
        product: { ...state.product, ...action.product }
      };
    case PRODUCT_RESET_DATA:
      return {
        ...initialState
      };
    case PRODUCT_UPDATE_DATA:
      newProduct = { ...action.product };
      newProduct.components = state.product.components.map((component) => {
        const newDataComponent = action.product.components
          ? action.product.components.find(dataComponent => dataComponent.id === component.id)
          : {};
        return { ...component, ...newDataComponent };
      });
      return {
        ...state,
        product: { ...state.product, ...newProduct }
      };
    case PRODUCT_COMPONENT_SET_DATA:
      components = state.product.components.map((component) => {
        return (component.id === action.id) ? { ...component, ...action.component } : component;
      });
      return {
        ...state,
        product: {
          ...state.product,
          components
        }
      };
    case PRODUCT_SET_FORM_DATA:
      return {
        ...state,
        productForm: { ...state.productForm, ...action.data }
      };
    case PRODUCT_UPDATE_FORM_FIELD:
      return {
        ...state,
        productForm: {
          ...state.productForm,
          [action.field]: action.value
        }
      };
    case PRODUCT_UPDATE_COMPONENT_FORM_FIELD:
      components = state.productForm.components.map((component) => {
        return (component.id === action.id) ? {
          ...component,
          [action.field]: action.value
        } : component;
      });
      return {
        ...state,
        productForm: {
          ...state.productForm,
          components
        }
      };
      case PRODUCT_ADD_NEW_NOTE:
        const notes = state.product.notes || [];
        return {
          ...state,
          product: {
            ...state.product,
            notes: [action.note, ...notes]
          },
          productForm: {
            ...state.productForm,
            notes: [action.note, ...notes]
          }
        };
      case PRODUCT_UPDATE_NOTE:
        const newNotes = notes.map(note => note.id === action.note.id ? action.note : note);
        return {
          ...state,
          product: { ...state.product, notes: newNotes}
        }
      case PRODUCT_CASES_SET_DATA:
        return {
          ...state,
          product: {
            ...state.product,
            cases: action.productCases ? [...action.productCases] : []
          }
        }
      case PRODUCT_ADD_NEW_CASE:
          const cases = state.product.cases || [];
        return {
          ...state,
          product: { ...state.product, cases: [action.data, ...cases] },
        };
      case PRODUCT_UPDATE_CASE:
        const newCases = cases.map(theCase => theCase.caseId === action.theCase.caseId ? action.theCase : theCase);
          return {
            ...state,
            product: { ...state.product, cases: newCases },
        };
    default:
      return state;
  }
}
