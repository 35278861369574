import { apiRequest } from '../Api/ApiRequest';

export function api_getPricingTypes(){
  return apiRequest({
    url: '/pricing-types',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_createPricingType(data){
  return apiRequest({
    url: '/pricing-types',
    method: 'post',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_updatePricingType(id, data){
  return apiRequest({
    url: `/pricing-types/${id}`,
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getPricingType(id){
  return apiRequest({
    url: `/pricing-types/${id}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
