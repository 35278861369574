import FilteredTableV2 from '../../FilteredTableV2';
import React, { useEffect, useState } from 'react';
import HeadlessModal from '../../Modals/HeadlessModal';
import {
  Badge,
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row
} from 'reactstrap';
import OrderProduct from '../../../views/OrderProduct';
import moment from 'moment/moment';
import business from 'moment-business';
import Order from '../../../views/Order';
import ExtraButtons from '../../../utils/Helpers/ExtraButtons';
import { ButtonIcon } from '../../ButtonIcon';
import { CSVLink } from 'react-csv';
import isEmpty from 'lodash.isempty';
import { SelectMod } from '../../Selects/SelectMod';
import TableCellHighlightOnHover from '../../TableCellHighlightOnHover';
import BatchUpdate from '../../../views/ServiceDelivery/BatchUpdate';
import { connect } from 'react-redux';
import { updateWIPReportItem } from '../../../actions/wipReport';
import omitBy from 'lodash.omitby';
import { tableDateCompare } from '../../../utils/Helpers/TableDateCompare';
import Account from '../../../views/Account';

const entityInitialState = {type: '', id: ''}
const WipReportTable = ({
  fetchData,
  products,
  pageSize,
  lifecycleStage,
  dispatch
}) => {

  const [tableData, setTableData] = useState([]);
  const [resetPageIndex, setResetPageIndex] = useState(true);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [showBatchUpdate, setShowBatchUpdate] = useState(false);
  const [entity, setEntity] = useState(entityInitialState);
  const [userFilter, setUserFilter] = useState({
    value: null,
    label: 'All Owners'
  });
  const productFields = ['name', 'lifecycleStage','isDelivered','goLiveDate', 'internalRfsDate', 'installDate', 'readyForServiceDate']
  const orderFields = ['owner', 'nextCustomerUpdate']

  useEffect(() => {
    setTableData(products.map(product => {
      return {
        ...product,
        isSelected: tableData.find(item => item.id === product.id)?.isSelected || false
      }
    }))
  }, [products])

  const toggleEntityModal = () => setShowEntityModal(prevState => !prevState)
  const toggleBatchUpdateModal = () => setShowBatchUpdate(prevState => !prevState)
  const handleEntityClick = (entity) => {
    setEntity(entity)
    toggleEntityModal()
  }

  const handleBatchSelect = (checked, product) => {
    setResetPageIndex(false)
    setTableData(tableData.map(row => {
      if (row.id === product.id) {
        return {
          ...row,
          isSelected: checked
        }
      }
      return row
    }))
  }

  const updateOrder = (order) =>{
    const orderArgs = omitBy(order, (value, key) => {
      return !orderFields.includes(key)
    })
    products.filter(product => product.orderId === order.id).forEach(product => {
      dispatch(updateWIPReportItem(product.id, orderArgs))
    })

  }

  const updateProduct = (orderProduct) =>{
    const productArgs = omitBy(orderProduct, (value, key) => {
      return !productFields.includes(key)
    })
    products.filter(product => product.id === orderProduct.id).forEach(product => {
      dispatch(updateWIPReportItem(product.id, productArgs))
    })
  }
  const resetSelected = () => {
    setTableData(tableData.map(row => {
      return {
        ...row,
        isSelected: false
      }
    }))
  }
  const getSelected = () => {
    return tableData.filter(row => row.isSelected)
  }
  const isAnySelected = () => !isEmpty(tableData.filter(row => row.isSelected))
  const getEntityComponent = () => {
    if (entity.type === 'order') {
      return <Order id={entity.id} closeModal={toggleEntityModal} onUpdated={order => updateOrder(order) }/>
    } else if (entity.type === 'product') {
      return <OrderProduct
        closeModal={toggleEntityModal}
        id={entity.id}
        onUpdated={orderProduct => updateProduct(orderProduct)}
        currency={entity.currency}
      />
    } else if (entity.type === 'account') {
      return <Account id={entity.id} toggleModal={toggleEntityModal} tab={'info'} />
    }
    return ''
  }
  const getDateBGClass = (value) => {
    let today = moment();
    let date = moment(value);
    if (today.isSameOrAfter(date, 'day')) {
      return 'danger';
    } else if (business.addWeekDays(today, 5)
      .isAfter(date)) {
      return 'warning';
    } else {
      return 'success';
    }
  }

  const onUserFilterChange = (selected, tableProps) => {
    setResetPageIndex(true)
    tableProps.column.setFilterValue(selected.value)
    setUserFilter(selected)
  };

  const getOwnersOptions = () => {
    let keep = {};
    return products.reduce((result, product) => {
      if (!keep[product.owner]) {
        keep[product.owner] = true;
        result.push(
          {
            value: product.owner,
            label: product.owner
          }
        );
      }
      return result;
    }, [{
      value: null,
      label: 'All Owners'
    }]);
  };

  const columns = [
    {
      header: () => <input type={'checkbox'} checked={isAnySelected()} onChange={() => resetSelected()}/>,
      cell: (props) => <input type={'checkbox'} checked={props.row.original.isSelected}
                              onChange={(event) => handleBatchSelect(event.target.checked, props.row.original)}/>,
      enableSorting: false,
      accessorKey: 'id',
      maxSize: 50,
    },
    {
      header: 'Customer',
      accessorKey: 'customer',
      minSize: 400,
      cell: props => {
        return <div
          onClick={() => handleEntityClick({
              id: props.row.original.customerId,
              type: 'account',
              size: 'xxlg'
            }
          )}
        >
          <TableCellHighlightOnHover value={props.getValue()}/>
        </div>
      }
    },
    {
      header: 'Order',
      accessorKey: 'order',
      minSize: 200,
      cell: props => {
        return <div
          onClick={() => handleEntityClick({
            id: props.row.original.orderId,
            type: 'order',
            size: 'xxlg'
          })}
        >
          <TableCellHighlightOnHover value={props.getValue()}/>
        </div>
      }
    },
    {
      header: 'Product Number',
      accessorKey: 'productNumber',
      minSize: 230,
      cell: props => {
        return <div
          onClick={() => handleEntityClick({
            id: props.row.original.productId,
            type: 'product',
            currency: props.row.original.currency
          })}
        >
          <TableCellHighlightOnHover value={props.getValue()}/>
        </div>
      }
    },
    {
      header: 'Product',
      accessorKey: 'name',
      minSize: 350,
      cell: props => {
        return <div
          onClick={() => handleEntityClick({
            id: props.row.original.productId,
            type: 'product',
            currency: props.row.original.currency
          })}
        >
          <TableCellHighlightOnHover value={props.getValue()}/>
        </div>
      }
    },

    {
      header: 'Order Type',
      accessorKey: 'orderType',
    },
    {
      header: 'Lifecycle Stage',
      accessorKey: 'lifecycleStage',
      cell: props => Number.isInteger(props.getValue()) ? lifecycleStage.options.find(option => option.value === props.getValue())?.label ?? '' : props.getValue()
    },
    {
      header: 'Accepted to SD',
      accessorKey: 'redFileReceivedDate',
      cell: props => props.getValue() ? moment(props.getValue())
        .format('DD/MM/YYYY') : ''
    },
    {
      header: 'RFS Date',
      accessorKey: 'internalRfsDate',
      cell: props => {
        const value = props.getValue()
        return <Badge style={{minWidth: '86px'}} className={`p-2 faded-${getDateBGClass(value)}`}>{value ? moment(value)
          .format('DD/MM/YYYY') : ''}</Badge>
      },
      sortingFn: (a, b) => tableDateCompare('internalRfsDate', a,b),
    },
    {
      header: 'Next Customer Update',
      accessorKey: 'nextCustomerUpdate',
      cell: props => {
        const value = props.getValue()
        return <Badge style={{minWidth: '86px'}} className={`p-2 faded-${getDateBGClass(value)}`}>{value ? moment(value)
          .format('DD/MM/YYYY') : ''}</Badge>
      },
      sortingFn: (a, b) => tableDateCompare('nextCustomerUpdate', a,b),
    },
    {
      header: (props) => {
        return <SelectMod
          options={getOwnersOptions()}
          isSearchable
          value={userFilter}
          onChange={(selected) => onUserFilterChange(selected, props)}
          size={'sm'}
        />
      },
      enableSorting: false,
      accessorKey: 'owner',
      minSize: 200,
      cell: props => props.getValue().id ?  props.getValue().name : props.getValue(),
      filterFn: (row, columnId, value) => {
        return !value || row.original.owner === value
      },
    },
  ]

  return (
    <>
      <FilteredTableV2
        withFilter={'includesString'}
        autoResetPageIndex={resetPageIndex}
        data={tableData}
        columns={columns}
        minRows={20}
        showPagination={products.length > (pageSize || 50)}
        pageSizeOptions={[50, 100, 200, 500]}
        defaultPageSize={pageSize || 50}
        striped
        defaultSorted={[{
          id: 'internalRfsDate',
          desc: false
        }]}
        extraButtons={ExtraButtons({
          others: [
            <Button
              onClick={toggleBatchUpdateModal}
              colour={'secondary'}
              size='sm'
              color='secondary'
              disabled={!isAnySelected()}
            >

              Edit Selected
            </Button>,
            <div data-for={'export'} data-tip={'export'} >
              <CSVLink id={'export'}
                       data={[...products]} filename={`wip-report-${moment().format('DD-MM-YYYY')}.csv`}
                       style={{ textDecoration: 'none' }}>

                <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
              </CSVLink>
            </div>,
            <ButtonIcon icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={fetchData}/>
          ]
        })}
      />
      <HeadlessModal
        open={showEntityModal}
        onClosed={() => setEntity(entityInitialState)}
        size={'xxlg'}
        toggle={toggleEntityModal}
      >
        <Row>
          <Col>
            {getEntityComponent()}
          </Col>
        </Row>

      </HeadlessModal>
      <HeadlessModal
        open={showBatchUpdate}
        toggle={toggleBatchUpdateModal}
      >
        <BatchUpdate
          products={getSelected()}
          productFields={productFields}
          orderFields={orderFields}
          onClose={toggleBatchUpdateModal}
        />
      </HeadlessModal>
    </>

  )
}
const mapStateToProps = ({
  helpers,
  wipReport
}) => ({
  lifecycleStage: helpers.optionSets.product.lifecycleStage,
  products: wipReport.items,
  systemUsers: helpers.systemUsers
})

export default connect(mapStateToProps)(WipReportTable)