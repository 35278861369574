import React from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

export default {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true
  },
  subnet: {
    label: 'Subnet',
    labelCallback: (data) => <>
      Subnet {!isEmpty(data.subnet) ? <Link to={`/sdb/ipam/subnets/${data.subnet.id}`}><i className="fa fa-link"/></Link> : ''}
    </>,
    type: 'select-async',
    entity: 'subnet',
    readOnly: (key, field, data) => data.subnetProvided,
    optionLabelCallback: (opt) => `${opt.name} (${opt.networkAddress}/${opt.mask})`
  },
  address: {
    label: 'Address',
    labelCallback: (data) => <>
      Address{data.getNextAddressButton ? data.getNextAddressButton() : ''}
    </>,
    type: 'text',
    mandatory: true
  },
  allocatedToType: {
    label: 'Allocated To (Type)',
    type: 'select',
    mandatory: (data) => !isEmpty(data?.allocatedTo?.data) && !data.hideAllocatedTo,
    show: (field, data) => !data.hideAllocatedTo,
  },
  allocatedTo: {
    label: 'Allocated To',
    type: 'select-async',
    entity: 'logicalService',
    mandatory: (data) => !isEmpty(data?.allocatedTo?.type) && !data.hideAllocatedTo,
    show: (field, data) => !data.hideAllocatedTo
  },
  description: {
    label: 'Description',
    type: 'textarea',
    width: 12
  },
}