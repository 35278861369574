import { Alert, Col, Row } from 'reactstrap';
import React from 'react';
import isEmpty from 'lodash.isempty';
const FormValidationErrors = (
  {
    errors
  }
) => {


  if(!isEmpty(errors)){
    return (
      <Row>
        <Col>
          <Alert color={'danger'}>
            {errors.map((error, index) => {
              return <div key={'error' + index}>{error}</div>;
            })}
          </Alert>
        </Col>
      </Row>
    )
  }
  return (
    <></>
  )
}

export default FormValidationErrors;