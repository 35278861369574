import ProductService, {
  api_getProductCircuits
} from '../utils/Products/ProductService';
import { defaultErrorFeedback, successFeedback, warningFeedback } from './feedback';
import { setAccountServicesService } from './accounts';
import AuditService from '../utils/Audit';
import { ORDER_SET_AUDIT_HISTORY } from './order';

export const PRODUCT_SET_DATA = 'PRODUCT_SET_DATA';
export const PRODUCT_RESET_DATA = 'PRODUCT_RESET_DATA';
export const PRODUCT_UPDATE_DATA = 'PRODUCT_UPDATE_DATA';
export const PRODUCT_COMPONENT_SET_DATA = 'PRODUCT_COMPONENT_SET_DATA';
export const PRODUCT_SET_FORM_DATA = 'PRODUCT_SET_FORM_DATA';
export const PRODUCT_UPDATE_FORM_FIELD = 'PRODUCT_UPDATE_FORM_FIELD';
export const PRODUCT_UPDATE_COMPONENT_FORM_FIELD = 'PRODUCT_UPDATE_COMPONENT_FORM_FIELD';
export const PRODUCT_ADD_NEW_NOTE = 'PRODUCT_ADD_NEW_NOTE';
export const PRODUCT_UPDATE_NOTE = 'PRODUCT_UPDATE_NOTE';
export const PRODUCT_REMOVE_NOTE = 'PRODUCT_REMOVE_NOTE';
export const PRODUCT_CASES_SET_DATA = 'PRODUCT_CASES_SET_DATA';
export const PRODUCT_ADD_NEW_CASE = 'PRODUCT_ADD_NEW_CASE';
export const PRODUCT_UPDATE_CASE = 'PRODUCT_UPDATE_CASE';
export const PRODUCT_SET_CIRCUITS = 'PRODUCT_SET_CIRCUITS';
export const PRODUCT_ADD_CIRCUIT = 'PRODUCT_ADD_CIRCUIT';
export const PRODUCT_REMOVE_CIRCUIT = 'PRODUCT_REMOVE_CIRCUIT';
export const PRODUCT_UPDATE_CIRCUIT = 'PRODUCT_UPDATE_CIRCUIT';
export const PRODUCT_SET_AUDIT_HISTORY = 'PRODUCT_SET_AUDIT_HISTORY';

export function setProduct(data) {
  return {
    type: PRODUCT_SET_DATA,
    product: data
  };
}

export function addNewProductNote(note) {
  return {
    type: PRODUCT_ADD_NEW_NOTE,
    note
  };
}

export function updateProductNote(note) {
  return {
    type: PRODUCT_UPDATE_NOTE,
    note
  };
}

export function removeProductNote(note) {
  return {
    type: PRODUCT_REMOVE_NOTE,
    note
  };
}


export function updateProductFormField(data) {
  return {
    type: PRODUCT_UPDATE_FORM_FIELD,
    data
  };
}

export function resetProductData() {
  return {
    type: PRODUCT_RESET_DATA
  };
}


export function addNewProductCircuit(circuit) {
  return {
    type: PRODUCT_ADD_CIRCUIT,
    circuit
  };
}

export function removeProductCircuit(id) {
  return {
    type: PRODUCT_REMOVE_CIRCUIT,
    id
  };
}
export function updateProductCircuit(circuit) {
  return {
    type: PRODUCT_UPDATE_CIRCUIT,
    circuit
  };
}

export function getProduct(id) {
  return (dispatch) => {
    return ProductService.get(id)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch(setProduct(result.data));
          } else {
            dispatch(defaultErrorFeedback());
          }
        }
      );
  };
}

export function getProductCircuits(id) {
  return (dispatch) => {
    return api_getProductCircuits(id)
      .then(
        (result) => {
          if (result.status === 200) {
            dispatch({type: PRODUCT_SET_CIRCUITS, circuits: result.data});
          } else {
            dispatch(defaultErrorFeedback());
          }
        }
      );
  };
}

export function updateProduct(id, data) {
  return (dispatch) => {
    return ProductService.update(id, data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setProduct(result.data));
          dispatch(successFeedback('Product updated!'));
          return result.data
        } else if (result.status === 422) {
          return { errors: result.data };
        } else {
          dispatch(defaultErrorFeedback());
          return false
        }
      });
  };
}

export function getOrderProductAuditHistory(productId){
  return (dispatch) => {
    return AuditService.get('products', productId).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: PRODUCT_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
}

export function deleteCircuitAssociation(productId, circuitId){
  return (dispatch) => {
    return ProductService.circuit.delete(productId, circuitId).then((result)=>{
      if (result.status === 422) {
        return { errors: result.data };
      }
      if (result.status === 204) {
        return true
      }else{
        dispatch(defaultErrorFeedback());
      }
      return false

    })
  }
}

export function createCircuitAssociation(productId, circuitId, relatedType = 'circuit'){
  return (dispatch) => {
    return ProductService[relatedType].create(productId, circuitId).then((result)=>{

      if (result.status === 422) {
        return { errors: result.data };
      }
      if (result.status === 204) {
        return true
      }else{
        dispatch(defaultErrorFeedback());
      }
      return false

    })
  }
}

