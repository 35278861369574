import {compareApiRequest} from '../Api/ApiRequest';

export function api_getClosestHubs(postcode) {
  return compareApiRequest({
    url: `/hubs/distance/${postcode}`,
    method: 'get',
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getClosestExchanges(postcode) {
  return compareApiRequest({
    url: `/exchanges/distance/${postcode}`,
    method: 'get',
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getServingExchange(postcode) {
  return compareApiRequest({
    url: `/exchanges/lookup/${postcode}`,
    method: 'get',
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
