import { apiRequest } from '../../Api/ApiRequest';
import { queryStringFromFields } from '../../Helpers/QueryString';

export const DomainService = {
  list: (include = [], filtered = [], orderBy = 'id', sortedBy = 'asc') => {
    let query = {
      orderBy,
      sortedBy,
      filtered,
      include
    };
    return apiRequest({
      url: `/sdb/ipam/domains${queryStringFromFields(query)}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  get: (id, include = []) => {
    return apiRequest({
      url: `/sdb/ipam/domains/${id}${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  create: (data, include = []) => {
    return apiRequest({
      url: `/sdb/ipam/domains${queryStringFromFields({ include })}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  update: (id, data, include = []) => {
    return apiRequest({
      url: `/sdb/ipam/domains/${id}${queryStringFromFields({ include })}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  delete: (id) => {
    return apiRequest({
      url: `/sdb/ipam/domains/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
}