import {
  IPAM_SUBNET_ADD_ADDRESS,
  IPAM_SUBNET_ADD_CHILD,
  IPAM_SUBNET_REMOVE_ADDRESS,
  IPAM_SUBNET_REMOVE_CHILD,
  IPAM_SUBNET_RESET_DATA,
  IPAM_SUBNET_SET_DATA,
  IPAM_SUBNET_SET_FIELD,
  IPAM_SUBNET_UPDATE_ADDRESS,
  IPAM_SUBNET_UPDATE_CHILD
} from '../../../actions/ServiceDB/Ipam/subnet';

const initialState = {
  data: {
    assignedTo: undefined, // added to reset assignedTo field
    childSubnets: [],
    addresses: []
  },
  original: {
    assignedTo: undefined, // added to reset assignedTo field
    childSubnets: [],
    addresses: []
  }
};
const subnet = (state = initialState, action) => {
  switch (action.type) {
    case IPAM_SUBNET_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case IPAM_SUBNET_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case IPAM_SUBNET_ADD_CHILD:
      return {
        ...state,
        data: { ...state.data, childSubnets: [...state.data.childSubnets, action.subnetData] },
        original: { ...state.original, childSubnets: [...state.original.childSubnets, action.subnetData] }
      };
    case IPAM_SUBNET_UPDATE_CHILD:
      const updatedChildrenWithUpdatedChild = state.data.childSubnets.map((subnet) => subnet.id === action.subnetData.id ? action.subnetData : subnet);
      return {
        ...state,
        data: { ...state.data, childSubnets: updatedChildrenWithUpdatedChild },
        original: { ...state.original, childSubnets: updatedChildrenWithUpdatedChild }
      };
    case IPAM_SUBNET_REMOVE_CHILD:
      const updatedChildrenWithoutDelete = state.data.childSubnets.filter((subnet) => subnet.id !== action.subnetId);
      return {
        ...state,
        data: { ...state.data, childSubnets: updatedChildrenWithoutDelete },
        original: { ...state.original, childSubnets: updatedChildrenWithoutDelete }
      };
    case IPAM_SUBNET_ADD_ADDRESS:
      return {
        ...state,
        data: { ...state.data, addresses: [...state.data.addresses, action.addressData] },
        original: { ...state.original, addresses: [...state.original.addresses, action.addressData] }
      };
    case IPAM_SUBNET_UPDATE_ADDRESS:
      const updatedAddressesWithUpdatedAddress = state.data.addresses.map((address) => address.id === action.addressData.id ? action.addressData : address);
      return {
        ...state,
        data: { ...state.data, addresses: updatedAddressesWithUpdatedAddress },
        original: { ...state.original, addresses: updatedAddressesWithUpdatedAddress }
      };
    case IPAM_SUBNET_REMOVE_ADDRESS:
      const updatedAddressesWithoutDelete = state.data.addresses.filter((address) => address.id !== action.addressId);
      return {
        ...state,
        data: { ...state.data, addresses: updatedAddressesWithoutDelete },
        original: { ...state.original, addresses: updatedAddressesWithoutDelete }
      };
    case IPAM_SUBNET_RESET_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default subnet;