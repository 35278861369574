import { deepFreeze } from '../Helpers/DeepFreeze';


export const ProductEnums = deepFreeze({
  orderType: {
    NEW_ITEM: 100000000,
    CANCEL : 864640000
  },
  billingCycle: {
    MONTHLY: 100000000,
    QUARTERLY: 100000001,
    ANNUALLY: 100000002,
  },
  lifecycleStage: {
    IN_SERVICE: 3,
    CEASE_PENDING: 4,
    CEASED: 6,
    CANCELED: 864640005,
  }
})
