import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FilteredTableV2 from '../../FilteredTableV2';
import TableCellHighlightOnHover from '../../TableCellHighlightOnHover';
import HeadlessModal from '../../Modals/HeadlessModal';
import Partnership from '../../../views/Partnership';
import { Button } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { ButtonIcon } from '../../ButtonIcon';
import { addAccountPartnership, removeAccountPartnership } from '../../../actions/account';

const PartnershipsTable = ({
  optionSets,
  partnerships,
  isCustomer,
  fetchData,
  account
}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedPartnership, setSelectedPartnership] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => {
    setShowModal(prevState => !prevState)
  }

  const columns = {
    partner: [
      {
        header: 'Customer',
        accessorKey: 'customer.name',
        cell: props =>
          <TableCellHighlightOnHover
            onClick={(event) => {
              event.stopPropagation()
              navigate(`/accounts/${props.row.original.customer.accountid}/info`)
            }}
            value={ props.getValue() ?? ''}
          />
      },
      {
        header: 'Partnership Type',
        accessorKey: 'type',
        cell: props => optionSets.type?.options.find(optionSet => optionSet.value === props.getValue())?.label ?? ''
      }
    ],
    customer : [
      {
        header: 'Partner',
        accessorKey: 'partner.name',
        cell: props => <TableCellHighlightOnHover
          onClick={(event) => {
            event.stopPropagation()
            navigate(`/accounts/${props.row.original.partner.accountid}/info`)
          }}
          value={props.getValue() ?? ''}
        />
      },
      {
        header: 'Partnership Type',
        accessorKey: 'type',
        cell: props => optionSets.type?.options.find(optionSet => optionSet.value === props.getValue())?.label ?? ''
      }
    ]
  }

  const getExportData = () => {
    return partnerships.map((partnership) => {
      return {
        partner: partnership.partner.name,
        partnerAccountNumber: partnership.partner.accountnumber,
        customer: partnership.customer.name,
        customerAccountNumber: partnership.customer.accountnumber,
        type: optionSets.type?.options.find(optionSet => optionSet.value === partnership.type)?.label ?? ''
      }
    })
  }

  return (
    <>
      <FilteredTableV2
        minRows={10}
        columns={isCustomer ? columns.customer : columns.partner}
        data={partnerships}
        showPagination={partnerships.length > 20}
        defaultPageSize={20}
        pageSizeOptions={[20,50,100,200]}
        noDataText={'No Partnerships'}
        onRowClick={(partnership) => {
          setSelectedPartnership(partnership)
          toggleModal()

        }}
        extraButtons={[
          <Button
            disabled={isCustomer}
            size={'sm'}
            color={'secondary'}
            onClick={() => {
            setSelectedPartnership(null)
            toggleModal()
          }}>New Partnership</Button>,
          <div data-for={'export'} data-tip={'export'} >
            <CSVLink id={'export'}
                     data={getExportData()} filename={`partnerships-${moment().format('DD-MM-YYYY')}.csv`}
                     style={{ textDecoration: 'none' }}>

              <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
            </CSVLink>
          </div>,

          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
        ]}
      />
      <HeadlessModal
        open={showModal}
        toggle={toggleModal}
        onClosed={() => setSelectedPartnership(null)}
      >
        <Partnership
          customerId={isCustomer ? selectedPartnership?.partnerId : selectedPartnership?.customerId}
          onClose={toggleModal}
          onCreated={(partnership) => {
            dispatch(addAccountPartnership(partnership))
            toggleModal()
          }}
          onDeleted={(id) => {
            dispatch(removeAccountPartnership(id))
            toggleModal()
          }}
        />
      </HeadlessModal>
    </>
  )
}

function mapStateToProps({ helpers }) {
  return {
    optionSets: helpers.optionSets.partnership
  };
}
export default connect(mapStateToProps)(PartnershipsTable);
