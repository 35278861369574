import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../Helpers/QueryString'

export const api_createContact = (accountId, contact) =>{
  return apiRequest({
    url: `/accounts/${accountId}/contacts`,
    method: 'post',
    data: { ...contact }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export const api_getContactData = (contactId)=>{
  return apiRequest({
    url: `/contacts/${contactId}?include=sites,company`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export const api_updateContact = (contactId, args) =>{
  return apiRequest({
    url: `/contacts/${contactId}`,
    method: 'put',
    data: { ...args }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export const api_deleteContact = (contactId) =>{
  return apiRequest({
    url: `/contacts/${contactId}`,
    method: 'delete'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

export const api_restoreContact = (contactId, query = {}) =>{
  return apiRequest({
    url: `/contacts/${contactId}/restore${queryStringFromFields(query)}`,
    method: 'put'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
};

