import React, { Fragment } from 'react';
import generateFormFields from '../../helpers/FormFieldGenerator';
import {
  Alert,
  Button,
  Col,
  Row
} from 'reactstrap';

export const BillEditFormFields = {
  payer: { label: 'Payer', type: 'text', mandatory: true, width: 12 },
  payerAddressLine1: { label: 'Address Line 1', type: 'text', mandatory: true, width: 12 },
  payerAddressLine2: { label: 'Address Line 2', type: 'text', width: 12 },
  payerAddressLine3: { label: 'Address Line 3', type: 'text', width: 12 },
  payerCity: { label: 'City', type: 'text', mandatory: true, width: 12 },
  payerPostcode: { label: 'Postcode', type: 'text', mandatory: true, width: 12 },
  poNumber: { label: 'PO Number', type: 'text', width: 12 }
};

const BillingRunEdit = (props) => {
  const { fields, errors } = props.edit;
  return (
        <>
            <Row>
                <Col>
                    <Alert color={'warning'}>By saving the below details the customer invoice will be regenerated.</Alert>
                </Col>
            </Row>
            {errors.length > 0
            && <Row>
                <Col>
                    <Alert color={'danger'}>
                        {errors.map((error, index) => {
                          return <p className={'mb-0'} key={'error' + index}>{error}</p>;
                        })}
                    </Alert>
                </Col>
            </Row>

            }
            <Row form>
                {generateFormFields({
                  fields: BillEditFormFields,
                  data: fields,
                  handleInput: props.handleInput
                })}
            </Row>
            <Row>
                <Col>
                    <Button color={'primary'} onClick={props.save}>Save</Button>
                </Col>
            </Row>
        </>

  );
};

export default BillingRunEdit;
