import { BGPCLEAR, BGPEDGEUPDATE, BGPEDGEREMOVE, BGPNODEUPDATE, BGPINIT } from '../actions/BGP';
import update from 'immutability-helper';

const initialState = {
  lastUpdate: null,
  edges: {},
  nodes: {}
};

export default function BGP(state = initialState, action) {
  switch (action.type) {
    case BGPCLEAR:
      return initialState;
    case BGPNODEUPDATE:
      return update(state, {
        lastUpdate: {
          $set: Date.now()
        },
        nodes: {
          [action.id]: {
            $set: action.data
          }
        }
      });
    case BGPEDGEUPDATE:
      return update(state, {
        lastUpdate: {
          $set: Date.now()
        },
        edges: {
          [action.id]: {
            $set: action.data
          }
        }
      });
    case BGPEDGEREMOVE:
      return update(state, {
        lastUpdate: {
          $set: Date.now()
        },
        edges: {
          $unset: [action.id]
        }
      });
    case BGPINIT:
      return update(state, {
        lastUpdate: {
          $set: Date.now()
        },
        nodes: {
          $set: action.nodes
        },
        edges: {
          $set: action.edges
        }
      });
    default:
      return state;
  }
}
