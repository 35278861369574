import { netApiRequest } from '../Api/ApiRequest';

export function submitGetSnaps() {
  const url = '/jsnap';
  return netApiRequest({
    url,
    method: 'get'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function submitDeleteSnap(device) {
  const url = '/jsnap/' + device;
  return netApiRequest({
    url,
    method: 'delete'
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function submitTakeSnap(snapName, device) {
  const url = '/jobs';
  return netApiRequest({
    url,
    method: 'put',
    data: {
      job: 'tasks.router_snap',
      params: [snapName, device]
    }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}

export function submitCheckSnaps(pre, post, hostname) {
  const url = '/jobs';
  return netApiRequest({
    url,
    method: 'put',
    data:{
      job: 'tasks.router_check',
      params: [pre, post, hostname]
    }
  }).then(response => response)
    .catch(function (error) {
      return Promise.reject(error.response || error.message);
    });
}
