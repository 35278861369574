import { apiRequest } from '../../Api/ApiRequest';
import { queryStringFromFields } from '../../Helpers/QueryString';

export const SubnetService = {
  list: (include = [], search = [], orderBy, sortedBy, searchJoin) => {
    const query = {
      orderBy,
      sortedBy,
      search,
      include,
      searchJoin
    };
    return apiRequest({
      url: `/sdb/ipam/subnets${queryStringFromFields(query)}`,
      method: 'get',
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  get: (id, include = []) => {
    return apiRequest({
      url: `/sdb/ipam/subnets/${id}${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  create: (data, include = []) => {
    return apiRequest({
      url: `/sdb/ipam/subnets${queryStringFromFields({ include })}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  update: (id, data, include = []) => {
    return apiRequest({
      url: `/sdb/ipam/subnets/${id}${queryStringFromFields({ include })}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  delete: (id) => {
    return apiRequest({
      url: `/sdb/ipam/subnets/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getNextAddress: (id) => {
    return apiRequest({
      url: `/sdb/ipam/subnets/${id}/next-address`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  getNextNetworkAddress: (id, mask) => {
    return apiRequest({
      url: `/sdb/ipam/subnets/${id}/next-network-address?mask=${mask}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  }
}