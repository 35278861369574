import { apiRequest } from '../Api/ApiRequest';

export function api_searchConfigItems(searchString) {
  return apiRequest({
    url: '/configitems/search?name=' + searchString,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
export function api_getConfigItem(id) {
  return apiRequest({
    url: `/configitems/${id}?include=assignedTo,location,manufacturer`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
export function api_getConfigItemNotes(id) {
  return apiRequest({
    url: `/configitems/${id}/notes`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
export function api_createConfigItemNote(id, data) {
  return apiRequest({
    url: `/configitems/${id}/notes`,
    method: 'post',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
export function api_createConfigItem(accountId,data){
  return apiRequest({
    url: `accounts/${accountId}/configitems`,
    method: 'post',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
export function api_updateConfigItem(id, data) {
  return apiRequest({
    url: `/configitems/${id}?include=assignedTo,location,manufacturer`,
    method: 'put',
    data
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
