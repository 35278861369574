import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
import FilteredTable from '../../components/FilteredTable';
import ExtraButtons from '../../utils/Helpers/ExtraButtons';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  createPricingType,
  getAllPricingTypes,
  getPricingType,
  setPricingTypesSearchString,
  setPricingTypeValidationErrors,
  setSelectedPricingType,
  updatePricingType,
  updateSelectedPricingType,
  updateSelectedPricingTypeData
} from '../../actions/pricingTypes';
import moment from 'moment';
import StandardPricing from './StandardPricing';
import UnitPricing from './UnitPricing';
import generateFormFields from '../../helpers/FormFieldGenerator';
import forms from './forms';
import { detailedDiff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';

const PricingTypes = ({
  pricingTypes,
  dispatch
}) => {

  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState(false);
  const availableTemplates = [{label : 'Unit Pricing', value: 'unit'}]
  const editableKeyToFocus = useRef('');

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    dispatch(setSelectedPricingType({}))
    dispatch(getAllPricingTypes()).then(() => {
      setLoading(false)
    })
  }

  const reloadSelected = () => {
    setLoadingType(true);
    dispatch(getPricingType(pricingTypes.selected.id)).then(() => setLoadingType(false))
  }

  const create = (data, errors) => {
    if(validated(errors)){
      setLoadingType(true)
      if(pricingTypes.selected.id === 'new'){
        dispatch(createPricingType({...pricingTypes.selected, data})).then(
          (data) =>{
            if(data){
              dispatch(setSelectedPricingType(data))
            }
            setLoadingType(false)
          }
        )
      }else if(hasChanges()){
        const {name, data} = pricingTypes.selected
        dispatch(updatePricingType(pricingTypes.selected.id, {name, data})).then(
          (data) =>{
            if(data){
              dispatch(setSelectedPricingType(data))
            }
            setLoadingType(false)
          }
        )
      }
    }

  }

  const hasChanges = () => {
    const diff = detailedDiff(pricingTypes.selectedOriginal, pricingTypes.selected);
    return !isEmpty(diff.added) || !isEmpty(diff.deleted) || !isEmpty(diff.updated)
  }

  const validated = (errors) => {
    if(!pricingTypes.selected.name){
      errors.push('Invalid name')
    }
    dispatch(setPricingTypeValidationErrors(errors))
    return errors.length === 0;
  }
  const setName = (name) => {
    dispatch(updateSelectedPricingType({name}))
  }

  const setTemplate = (template) => {
    dispatch(updateSelectedPricingType({template}))
  }

  const setData = (data) => {
    dispatch(updateSelectedPricingTypeData(data))
  }

  const isFiltered = () => {
    return Boolean(pricingTypes.searchString);
  };

  const onChangeSearch = (e) => {
    dispatch(setPricingTypesSearchString(e.target.value));
  };

  const columns = [
    {
      filterable: false,
      Header: 'Name',
      accessor: 'name'
    },
    {
      filterable: false,
      Header: 'Price List Items',
      accessor: 'items'
    },
    {
      filterable: false,
      Header: 'Updated At',
      accessor: 'updatedAt',
      Cell: props => moment(props.value)
        .format('DD/MM/YYYY HH:mm'),
      width: 200
    },

  ]

  const getTemplateOption = (selected) => {
    return availableTemplates.find((template) => template.value === selected.value);
  }
  const resolveTypeComponent = () => {
    if(pricingTypes.selected.template === 'standard'){
      return <StandardPricing/>
    }
    if(pricingTypes.selected.template === 'unit'){
      return <UnitPricing
        pricingType={pricingTypes.selected}
        editableKeyToFocus={editableKeyToFocus}
        create={create}
        errors={pricingTypes.errors}
        updateData={setData}
        hasChanges={hasChanges()}
        getData={reloadSelected}
      />
    }
    return ''
  }
  return (
    <div className="animated fadeIn">
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <LoadingOverlay loading={loading}>
                <FilteredTable
                  filterable={false}
                  extraButtons={ExtraButtons({
                    addNew: () => dispatch(setSelectedPricingType({id: 'new', data: {}})),
                    refresh: getData
                  })}
                  onChange={onChangeSearch}
                  value={pricingTypes.searchString}
                  columns={columns}
                  data={isFiltered() ? pricingTypes.filteredList : pricingTypes.list}
                  minRows={5}
                  defaultSorted={{
                    id: 'modifiedOn',
                    desc: true
                  }}
                  onRowClick={(data) => {
                    dispatch(setPricingTypeValidationErrors([]))
                    dispatch(setSelectedPricingType(data))
                  }}
                  showPagination={pricingTypes.searchString ? pricingTypes.filteredList.length > 20 : pricingTypes.list.length > 20}
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {pricingTypes.selected.id &&
        <LoadingOverlay loading={loadingType}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    {generateFormFields({
                      fields: pricingTypes.selected.id === 'new' ? forms.create : forms.edit,
                      handleInput: (event) => {
                        setName(event.target.value)
                        editableKeyToFocus.current = ''
                      },
                      getSelectOptions: () => availableTemplates,
                      handleSelectInput: (field, selected) => {
                        setTemplate(selected.value)
                        dispatch(setPricingTypeValidationErrors([]))
                      },
                      getSelectedOption: getTemplateOption,
                      data: pricingTypes.selected,
                      optionSets: availableTemplates
                    })}
                  </Row>
                  {resolveTypeComponent()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </LoadingOverlay>
      }
    </div>
  );
}

function mapStateToProps({
  pricingTypes,
  authenticationState,
}) {
  return {
    pricingTypes,
    user: authenticationState.account
  };
}
export default connect(mapStateToProps)(PricingTypes)
