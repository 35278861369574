import {
  CARRIER_CIRCUIT_ADD_NOTE, CARRIER_CIRCUIT_REMOVE_NOTE,
  CARRIER_CIRCUIT_REMOVE_PRODUCT,
  CARRIER_CIRCUIT_RESET_DATA, CARRIER_CIRCUIT_SET_AUDIT_HISTORY,
  CARRIER_CIRCUIT_SET_DATA,
  CARRIER_CIRCUIT_SET_FIELD, CARRIER_CIRCUIT_SET_FIELDS, CARRIER_CIRCUIT_UPDATE_NOTE
} from '../actions/carrierCircuit';

const initialState = {
  data: {
    orderProducts: [],
    aEnd: {

    }
  },
  original: {
    orderProducts: []
  },
  audits:[]
};
const carrierCircuit = (state = initialState, action) => {
  switch (action.type) {
    case CARRIER_CIRCUIT_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case CARRIER_CIRCUIT_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case CARRIER_CIRCUIT_SET_FIELDS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.fields
        }
      };
    case CARRIER_CIRCUIT_RESET_DATA:
      return {
        ...initialState
      };
    case CARRIER_CIRCUIT_REMOVE_PRODUCT:
      return {
        ...state,
        data: {
          ...state.data,
          orderProducts: state.data.orderProducts.filter(product => product.id !== action.id)
        },
        original: {
          ...state.original,
          orderProducts: state.original.orderProducts.filter(product => product.id !== action.id)
        }
    }
    case CARRIER_CIRCUIT_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }

    case CARRIER_CIRCUIT_ADD_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: [...state.data.notes, action.note]
        },
        original: {
          ...state.original,
          notes: [...state.original.notes, action.note]
        },

      }
    case CARRIER_CIRCUIT_UPDATE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
        original: {
          ...state.original,
          notes: state.original.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
      }
    case CARRIER_CIRCUIT_REMOVE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.filter(note => note.id !== action.note.id)
        },
        original: {
          ...state.original,
          notes: state.original.notes.filter(note => note.id !== action.note.id)
        }
      }
    default:
      return state;
  }
}

export default carrierCircuit;