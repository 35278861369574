import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Loadable from 'react-loading-overlay';
import { Button, Col, Label, Row, FormGroup } from 'reactstrap';
import { DateTimePicker } from 'react-widgets';
import { DIRECT_DEBITS_LOADING_AUDDIS, DIRECT_DEBITS_SET_AUDDIS_DATE_FROM, DIRECT_DEBITS_SET_AUDDIS_DATE_TO } from '../../actions/directDebits';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { api_generateAuddisReport } from '../../utils/Billing/DirectDebitService';
import fileDownload from 'js-file-download';
moment.locale('en');
momentLocalizer();

class DirectDebitsAuddis extends React.Component {
  componentDidMount() {
    let fromDate = moment().hour(0).minutes(0).toDate()
      .toISOString();
    this.props.dispatch({ type: DIRECT_DEBITS_SET_AUDDIS_DATE_FROM, date: fromDate });
    this.props.dispatch({ type: DIRECT_DEBITS_SET_AUDDIS_DATE_TO, date: new Date().toISOString() });
  }

    handleDateTo = (date) => {
      this.props.dispatch({ type: DIRECT_DEBITS_SET_AUDDIS_DATE_TO, date: date });
    }

    handleDateFrom= (date) => {
      this.props.dispatch({ type: DIRECT_DEBITS_SET_AUDDIS_DATE_FROM, date: date });
    }

    generate = () =>{
      const { auddis } = this.props.directDebits;
      this.props.dispatch({ type: DIRECT_DEBITS_LOADING_AUDDIS, loading: true });
      api_generateAuddisReport(moment(auddis.fromDate).unix(), moment(auddis.toDate).unix()).then(
        (result) => {
          if (result.status === 200) {
            let splitContentDisposition = result.headers['content-disposition'].split('=');
            fileDownload(result.data, splitContentDisposition[1]);
          }
          this.props.dispatch({ type: DIRECT_DEBITS_LOADING_AUDDIS, loading: false });
        }
      );
    }

    render() {
      const { auddis } = this.props.directDebits;
      return (
            <Loadable animate={false} active={auddis.loading} spinner spinnerSize={'60px'} color={'rgb(62,81,91)'} background={'rgba(255,255,255, 0.8)'}>
                <Row>
                    <Col md={6}>
                        <FormGroup row>
                            <Col md="4">
                                <Label htmlFor={'fromDate'} className={'mb-0'}><strong>Date From</strong></Label>
                            </Col>
                            <Col md="8">
                                <DateTimePicker
                                    id={'fromDate'}
                                    format="DD-MM-YYYY HH:mm"
                                    timeFormat="HH:mm"
                                    time={true}
                                    defaultValue={new Date(auddis.fromDate)}
                                    value={new Date(auddis.fromDate)}
                                    onChange={this.handleDateFrom}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup row>
                            <Col md="4">
                                <Label htmlFor={'toDate'}><strong>Date To</strong></Label>
                            </Col>
                            <Col md="8">
                                <DateTimePicker
                                    id={'toDate'}
                                    format="DD-MM-YYYY HH:mm"
                                    time={true}
                                    defaultValue={new Date(auddis.toDate)}
                                    value={new Date(auddis.toDate)}
                                    onChange={this.handleDateTo}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <Button color={'primary'} onClick={this.generate}>Generate</Button>
                    </Col>
                </Row>
            </Loadable>
      );
    }
}

function mapStateToProps({ directDebits }) {
  return {
    directDebits
  };
}

export default connect(mapStateToProps)(DirectDebitsAuddis);
