import {
    SNAP_CREATING,
    GET_SNAPS,
    SNAPSHOT,
    SNAP_CHECKING,
    SNAP_DELETING,
    SNAP_FETCHING,
    SNAP_ERROR,
    DELETE_HOST,
    SNAP_FETCH_ERROR
} from '../actions/jsnapy';
import update from "immutability-helper";

const initialState = {
    devices: [],
    checks: {},
    snapFetching: false,
    snapDeleting: false,
    snapCreating: false,
    snapChecking: false,
    snapError: false,
    snapFetchError: false,
    routerDeleting: ''
};

export default function jsnapy(state = initialState, action) {
    switch (action.type) {
        case GET_SNAPS:
            return {...state, devices: [...action.data], snapFetching: false}

        case SNAPSHOT:
            if (action.data.result === false) {
                return {...state, snapCreating: false, snapError: action.data}
            }
            const snapIndex = state.devices.findIndex(e => e.hostname === action.data.hostname)
            if (snapIndex === -1) {
                return update(state, {devices: {$push: [action.data]}, snapCreating: {$set: false}});
            }
            return update(state, {
                devices: {[snapIndex]: {['snaps']: {$set: action.data.snaps}}},
                snapCreating: {$set: false}
            });

        case SNAP_CREATING:
            return {...state, snapCreating: action.result}

        case SNAP_FETCHING:
            return {...state, snapFetching: action.result}

        case SNAP_DELETING:
            return {...state, snapDeleting: action.result}

        case SNAP_CHECKING:
            return {...state, snapChecking: action.result}

        case SNAP_ERROR:
            return {...state, snapError: action.result}

        case SNAP_FETCH_ERROR:
            return {...state, snapFetchError: action.result}

        case DELETE_HOST:
            let dhIndex = state.devices.findIndex(e => e.name === action.data)
            return update(state, {devices: {$splice: [[dhIndex, 1]]}});

        default:
            return state;
    }
}

