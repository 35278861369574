import { Card, CardBody, CardHeader } from 'reactstrap';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from '../../LoadingOverlay';
import TasksTable from '../../Tables/Tasks';
import { getUserTasks, removeTaskFromList, updateTaskInList } from '../../../actions/tasks';
import { TaskEnums } from '../../../utils/Constants/Task';
import isEmpty from 'lodash.isempty';

const MyTasks = ({
  app,
  tasks,
  taskOptions,
  dispatch
}) => {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(app.initialised && isEmpty(tasks.list)){
      getTasks()
    }

  }, [app])
  const toggleLoading = () => setLoading(prevState => !prevState)
  const getTasks = () => {
    toggleLoading()
    dispatch(getUserTasks([TaskEnums.status.OPEN])).then(() =>  toggleLoading())
  }

  const onUpdated = (task) => {
    if(task.status !== TaskEnums.status.OPEN ){
      dispatch(removeTaskFromList(task))
    }else{
      dispatch(updateTaskInList(task))
    }
  }

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between">
        <span>Active Tasks</span>
        <span className="hoverPointer" onClick={getTasks}>
          <i className={'fa fa-refresh'}/>
        </span>
      </CardHeader>
      <CardBody>
        <LoadingOverlay loading={loading}>
          <TasksTable
            excludeColumns={['priority', 'type']}
            tasks={tasks.list}
            types={taskOptions?.type ?? []}
            priorities={taskOptions?.priority ?? []}
            statusReason={taskOptions?.statusReason ?? []}
            pageSize={10}
            withNew={false}
            onUpdated={onUpdated}
          />
        </LoadingOverlay>

      </CardBody>
    </Card>
  )
}

const mapStateToProps = ({app,tasks, helpers}) => {
  return {
    app,
    tasks,
    taskOptions: helpers.optionSets.task
  };
};
export default connect(mapStateToProps)(MyTasks);
