import {
  CREDIT_REQUEST_ADD_PRODUCT, CREDIT_REQUEST_REMOVE_PRODUCT,
  CREDIT_REQUEST_RESET, CREDIT_REQUEST_RESET_INVOICES, CREDIT_REQUEST_RESET_PRODUCTS, CREDIT_REQUEST_SET_DATA,
  CREDIT_REQUEST_SET_FORM_FIELD, CREDIT_REQUEST_SET_INVOICES, CREDIT_REQUEST_UPDATE_PRODUCT
} from '../actions/creditRequest';

const initialState = {
  form: {
    products: [],
    invoices: []
  },
  original: {
    products: [],
    invoices: []
  },
};

export function creditRequest(state = initialState, action) {
  switch (action.type) {
    case CREDIT_REQUEST_SET_DATA:
      return {
        ...state,
        form: action.data,
        original: action.data,
      }
    case CREDIT_REQUEST_SET_FORM_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.field
        }
      }
    case CREDIT_REQUEST_RESET:
      return {
        ...initialState
      }
    case CREDIT_REQUEST_ADD_PRODUCT:
      return {
        ...state,
        form: {
          ...state.form,
          products: [ action.product, ...state.form.products]
        }
      }
    case CREDIT_REQUEST_UPDATE_PRODUCT:
      return {
        ...state,
        form: {
          ...state.form,
          products: state.form.products.map(product => {
            if(product.id === action.product){
              return {
                ...product,
                ...action.field
              }
            }
            return product
          })
        }
      }
    case CREDIT_REQUEST_REMOVE_PRODUCT:
      return {
        ...state,
        form: {
          ...state.form,
          products: state.form.products.filter(product => product.id !== action.product)
        }
      }
    case CREDIT_REQUEST_RESET_PRODUCTS:
      return {
        ...state,
        form: {
          ...state.form,
          products: []
        }
      }
    case CREDIT_REQUEST_SET_INVOICES:
      return {
        ...state,
        form: {
          ...state.form,
          invoices: [...action.invoices]
        }
      }
    case CREDIT_REQUEST_RESET_INVOICES:
      return {
        ...state,
        form: {
          ...state.form,
          invoices: []
        }
      }
    default:
      return state
  }
}