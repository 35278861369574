import {
  PRODUCT_TEMPLATES_SET,
  PRODUCT_TEMPLATES_SET_TEMPLATE, PRODUCT_TEMPLATES_SET_TEMPLATE_FIELD,
  PRODUCT_TEMPLATES_UPDATE_TEMPLATE
} from '../actions/productTemplates';

const initialState = {
  list: [],
  template: {
    form: {},
    original: {}
  }
}

export function productTemplates(state = initialState, action){
  switch (action.type) {
    case PRODUCT_TEMPLATES_SET:
      return {
        ...state,
        list: action.templates
      }
    case PRODUCT_TEMPLATES_UPDATE_TEMPLATE:
      return {
        ...state,
        list: state.list.map(template => {
          if(template.id === action.template.id){
            return action.template
          }
          return template
        }),
      }
    case PRODUCT_TEMPLATES_SET_TEMPLATE:
      return {
        ...state,
        template: {
          form: action.template,
          original: action.template,
        }
      }
    case PRODUCT_TEMPLATES_SET_TEMPLATE_FIELD:
      return {
        ...state,
        template: {
          ...state.template,
          form: {
            ...state.template.form,
            ...action.field
          }
        }
      }
    default:
      return state
  }
}
