import {
  BILLING_RUN_SET_TAX_DATE,
  BILLING_RUN_REMOVE_FAILED_JOB,
  BILLING_RUN_TOGGLE_COMPLETE_MODAL,
  BILLING_RUN_TOGGLE_BILL_MODAL,
  BILLING_RUN_ADD_BILL,
  BILLING_RUN_REMOVE_ALL_FAILED_JOBS,
  BILLING_RUN_SET_EMAIL_SENDING,
  BILLING_RUN_TOGGLE_LOADING,
  BILLING_RUN_SET_RUN_DATA,
  BILLING_RUN_RESET_RUN_DATA,
  BILLING_RUN_EXCLUDE_BILL,
  BILLING_RUN_REMOVE_ACCOUNT_BILLS,
  BILLING_RUN_INCLUDE_BILL, BILLING_RUN_SET_FAILED_JOBS, BILLING_RUN_UPDATE_RUN, BILLING_RUN_TOGGLE_ERROR_MODAL
} from '../actions/billingRun';

const initialState = {
  data: {
    bills: [],
    failedJobs: []
  },
  loading: false,
  showCompleteModal: false,
  showBillModal: false,
  showErrorModal: false,
  errorModalText: null,
  taxDate: null,
  sendingEmails: false
};

export default function billingRun(state = initialState, action) {
  switch (action.type) {
    case BILLING_RUN_SET_RUN_DATA:
      return {
        ...state,
        data: action.data
      };
    case BILLING_RUN_RESET_RUN_DATA:
      return {
        ...initialState
      };
    case BILLING_RUN_ADD_BILL:
      const newBills = [...state.data.bills, action.data];
      return {
        ...state,
        data: { ...state.data, bills: newBills }
      };
    case BILLING_RUN_REMOVE_FAILED_JOB:
      return {
        ...state,
        data: { ...state.data, failedJobs: state.data.failedJobs.filter(job => job.id !== action.jobId) }
      };
    case BILLING_RUN_REMOVE_ALL_FAILED_JOBS:
      return {
        ...state,
        data: { ...state.data, failedJobs: [] }
      };
    case BILLING_RUN_SET_FAILED_JOBS:
      return {
        ...state,
        data: { ...state.data, failedJobs: action.failedJobs }
      };
    case BILLING_RUN_SET_TAX_DATE:
      return {
        ...state,
        taxDate: action.date
      };
    case BILLING_RUN_TOGGLE_COMPLETE_MODAL:
      return {
        ...state,
        showCompleteModal: !state.showCompleteModal
      };
    case BILLING_RUN_TOGGLE_BILL_MODAL:
      return {
        ...state,
        showBillModal: !state.showBillModal
      };
    case BILLING_RUN_TOGGLE_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: !state.showErrorModal,
        errorModalText: action.errorText
      };
    case BILLING_RUN_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case BILLING_RUN_SET_EMAIL_SENDING:
      return {
        ...state,
        sendingEmails: action.sendingEmails
      };
    case BILLING_RUN_EXCLUDE_BILL:
      const bills = state.data.bills.map((bill) => {
        if (bill.id === action.billId) {
          bill.active = false;
        }
        return bill;
      });
      return {
        ...state,
        data: { ...state.data, bills }
      };
    case BILLING_RUN_INCLUDE_BILL:
      return {
        ...state,
        data: { ...state.data,
          bills: state.data.bills.map((bill) => {
            if (bill.id === action.billId) {
              bill.active = true;
            }
            return bill;
          }) }
      };
    case BILLING_RUN_REMOVE_ACCOUNT_BILLS:
      const billFound = state.data.bills.filter(bill => bill.id === action.billId);
      return {
        ...state,
        data: { ...state.data, bills: state.data.bills.filter(bill => bill.accountId !== billFound[0].accountId) }
      };
    case BILLING_RUN_UPDATE_RUN:
      return {
        ...state,
        data: { ...state.data, jobs: action.data.jobs, status: action.data.status, taxDate: action.data.taxDate }
      };
    default:
      return state;
  }
}
