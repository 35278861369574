import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import { Alert, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { ButtonIcon } from '../ButtonIcon';
import isEmpty from 'lodash.isempty';
import { diff } from 'deep-object-diff';
import generateFormFields from '../../helpers/FormFieldGenerator';
import resolveArgs from '../../helpers/ArgumentResolver';
import LoadingOverlay from '../LoadingOverlay';
import debounce from 'debounce-promise';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import { setConfirmDialog } from '../../actions/dialogs';

const GenericForm = ({
  formConfig,
  id,
  isNew,
  title,
  data,
  original,
  extraErrors,
  form,
  extraFormData,
  onFetch,
  onFetchAll,
  onFetched,
  onReset,
  setField,
  options,
  aSyncOptions,
  onCreateOption,
  requestStructure,
  extraFieldValidation,
  saveButtonDisabledCallback,
  onCreate,
  onCreated,
  onUpdate,
  onUpdated,
  onDelete,
  onDeleted,
  onSubmitted,
  extraButtonsBefore,
  extraButtons,
  selectValue,
  canEdit,
  deleteButtonDisabled,
  deleteButtonDisabledTooltip
}) => {
  // redux
  const dispatch = useDispatch();

  const handleInput = (event) => {
    setField(event.target.id, event.target.value);
  };

  const handleSelectInput = (key, selected) => {
    setField(key, selected?.value ?? selected?.id);
  };

  const handleInputDate = (key, value) => {
    setField(key, value);
  };

  const handleAsyncSelected = (key, selected) => {
    setField(key, selected);
  };

  const handleAsyncInput = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    if (aSyncOptions && aSyncOptions[entity]) {
      return aSyncOptions[entity](search, key);
    }
  };

  const handleAsyncCreateOption = (key, input) => {
    return onCreateOption[key](input);
  };

  const getSelectOptions = (field) => options[field];

  const getSelectedOption = (field) => {
    if (selectValue && selectValue[field]) {
      return selectValue[field]();
    }
    if(data[field] === null){
      return null
    }
    if (typeof data[field] === 'object' && data[field] !== null && (data[field].id || data[field].accountid)) {
      return options[field]?.find(option => (option.value.id && option.value.id === data[field].id) || (option.value.accountid && option.value.accountid === data[field].accountid));
    }
    return options[field]?.find(option => option.value === data[field]);
  };

  return (
    <Row form>
      {generateFormFields({
        fields: form,
        data: { ...data, ...extraFormData },
        handleInput,
        getSelectOptions,
        getSelectedOption,
        handleSelectInput,
        handleAsyncSelected,
        handleAsyncInput,
        handleInputDate,
        handleAsyncCreateOption
      })}
    </Row>
  );
};

export default connect()(GenericForm);
