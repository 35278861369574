import React from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const OptionsModal = ({ isOpen, close, options, contentText }) => {

  return <Modal isOpen={isOpen} className={`modal-${options.length > 2 ? 'md' : 'sm'} mt-5`} backdrop="static">
    <ModalBody>
      <p className="text-center">{contentText}</p>
      <Row>
        {options.map((option, index) => <Col key={`${option.text}-${index}`}>
          <Button className={`w-100 btn-${option.color || 'info'}`} onClick={(e) => {
            e.preventDefault();
            option.onClick();
            close();
          }}>{option.text}</Button>
        </Col>)}
      </Row>
    </ModalBody>
  </Modal>;
};

export default OptionsModal;
