import moment from 'moment';

export const tableDateCompare = (dateField, a, b, withNullFirst = false) => {
  a = a.original[dateField] ? moment(a.original[dateField]) : withNullFirst ? moment()
    .subtract(10, 'years') : moment()
    .add(10, 'years');
  b = b.original[dateField] ? moment(b.original[dateField]) : withNullFirst ? moment()
    .subtract(10, 'years') : moment()
    .add(10, 'years');
  return b.isBefore(a) ? 1 : -1;
}