import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {addRadiusUser, RADIUS_SET_ADD_USER_ERROR, fetchRadiusGroups, setActiveUser} from '../../actions/radius';
import {PulseLoader} from 'react-spinners';
import {Button, UncontrolledAlert, Input, Label, Form, FormGroup, Row, Col, Card, CardBody, CardHeader} from 'reactstrap';
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom'

const AddUser = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({
    username: '',
    password: '',
    group: 'hsodefault'
  });
  const [inputValFail, setInputValFail] = useState(false);

  const { addingUser, addUserErrors, groups } = useSelector(state => ({
    addingUser: state.radius.addingUser,
    addUserErrors: state.radius.addUserErrors,
    groups: state.radius.groups
  }));

  useEffect(() => {
    dispatch(fetchRadiusGroups());
  }, [dispatch]);

  const updateUserDetails = (prop, value) => {
    setUserDetails(prevState => ({
      ...prevState,
      [prop]: value
    }));
  };

  const addUser = () => {
    dispatch({ type: RADIUS_SET_ADD_USER_ERROR, error: false });
    const fields = Object.entries(userDetails).every(([, value]) => value.length !== 0);
    if (fields) {
      dispatch(addRadiusUser(userDetails)).then(() => {
        dispatch(setActiveUser(userDetails.username));
        navigate('/toolbox/radius/' + userDetails.username);
      });
    } else {
      setInputValFail(true);
      setTimeout(() => {
        setInputValFail(false);
      }, 2000);
    }
  };

  const renderAlert = msg => (
    <FormGroup row className="justify-content-end m-0 p-0">
      <UncontrolledAlert color="danger" className="p-0">
        <p className="font-sm mb-0">{msg}</p>
      </UncontrolledAlert>
    </FormGroup>
  );

  return (
    <>
      <Row>
        <Col>
          <Col>
            <Card className="card-accent-info animated fadeIn">
              <CardHeader>Add User</CardHeader>
              <CardBody className="pb-0">
                <Form>
                  <FormGroup row>
                    <Label for="username" size="sm" sm={12} md={12} lg={4}>Username</Label>
                    <Col md={12} sm={12} lg={8}>
                      <Input
                        bsSize="sm"
                        type="text"
                        id="username"
                        onChange={(e) => updateUserDetails(e.target.id, e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="password" size="sm" sm={12} md={12} lg={4}>Password</Label>
                    <Col md={12} sm={12} lg={8}>
                      <Input
                        bsSize="sm"
                        type="text"
                        id="password"
                        onChange={(e) => updateUserDetails(e.target.id, e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="group" size="sm" md={4}>Group</Label>
                    <Col md={6}>
                      <Input
                        bsSize="sm"
                        type="select"
                        name="select"
                        id="group"
                        value={userDetails.group}
                        onChange={(e) => updateUserDetails(e.target.id, e.target.value)}
                      >
                        {groups.map((e, index) => (
                          <option key={index}>{e}</option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={6} />
                    <Col sm={6} className="d-flex justify-content-end">
                      <Button outline color="danger" size="sm" onClick={addUser}>Create</Button>
                    </Col>
                  </FormGroup>
                  {inputValFail && renderAlert('Please fill in all fields')}
                  {addUserErrors && renderAlert(addUserErrors)}
                  {addingUser && (
                    <FormGroup row className="justify-content-end m-0 p-0">
                      <PulseLoader size={10} color="red" />
                    </FormGroup>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Col>
      </Row>
    </>
  );
};


export default AddUser;
