import {
  LICENSE_ADD_NOTE,
  LICENSE_ADD_STOCK_ITEM, LICENSE_REMOVE_NOTE,
  LICENSE_REMOVE_STOCK_ITEM,
  LICENSE_RESET_DATA, LICENSE_SET_AUDIT_HISTORY,
  LICENSE_SET_DATA,
  LICENSE_SET_FIELD, LICENSE_UPDATE_NOTE,
  LICENSE_UPDATE_STOCK_ITEM
} from '../../actions/ServiceDB/license';

const initialState = {
  data: {
    stockItems: [],
    notes: []
  },
  original: {
    stockItems: [],
    notes: []
  },
  audits: []
};
const license = (state = initialState, action) => {
  switch (action.type) {
    case LICENSE_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case LICENSE_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case LICENSE_RESET_DATA:
      return {
        ...initialState
      };
    case LICENSE_ADD_STOCK_ITEM:
      return {
        ...state,
        data: { ...state.data, stockItems: [...state.data.stockItems, action.stockItemData] },
        original: {
          ...state.original,
          stockItems: [...state.original.stockItems, action.stockItemData]
        }
      };
    case LICENSE_UPDATE_STOCK_ITEM:
      const updatedLicensesWithUpdatedLicense = state.data.stockItems.map((stockItem) => stockItem.id === action.stockItemData.id ? action.stockItemData : stockItem);
      return {
        ...state,
        data: { ...state.data, stockItems: updatedLicensesWithUpdatedLicense },
        original: { ...state.original, stockItems: updatedLicensesWithUpdatedLicense }
      };
    case LICENSE_REMOVE_STOCK_ITEM:
      const updatedLicensesWithoutDelete = state.data.stockItems.filter((stockItem) => stockItem.id !== action.stockItemId);
      return {
        ...state,
        data: { ...state.data, stockItems: updatedLicensesWithoutDelete },
        original: { ...state.original, stockItems: updatedLicensesWithoutDelete }
      };
    case LICENSE_ADD_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: [...state.data.notes, action.note]
        },
        original: {
          ...state.original,
          notes: [...state.original.notes, action.note]
        },

      }
    case LICENSE_UPDATE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
        original: {
          ...state.original,
          notes: state.original.notes.map(note => {
            if(note.id === action.note.id){
              return action.note
            }
            return  note
          })
        },
      }
    case LICENSE_REMOVE_NOTE:
      return {
        ...state,
        data: {
          ...state.data,
          notes: state.data.notes.filter(note => note.id !== action.note.id)
        },
        original: {
          ...state.original,
          notes: state.original.notes.filter(note => note.id !== action.note.id)
        }
      }
    case LICENSE_SET_AUDIT_HISTORY:
      return {
        ...state,
        audits: action.data
      }
    default:
      return state;

  }
};

export default license;