import { apiRequest } from '../Api/ApiRequest';

export function getZones() {
  return apiRequest({
    url: '/dns',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function getZoneRecord(id) {
  return apiRequest({
    url: '/dns/' + id,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function updateZoneRecord(zoneId, recordId, data) {
  return apiRequest({
    url: '/dns/' + zoneId + '/record/' + recordId,
    method: 'put',
    data: { ...data }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function deleteZoneRecord(zoneId, recordId) {
  return apiRequest({
    url: '/dns/' + zoneId + '/' + recordId,
    method: 'delete'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function createNewZoneRecord(zoneId, type, record) {
  return apiRequest({
    url: '/dns/' + zoneId,
    method: 'post',
    data: { type, ...record }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
