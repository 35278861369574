const form = {
  name: {
    label: 'Name',
    type: 'text',
    mandatory: true
  },
  carrierCircuit: {
    label: 'Carrier Circuit',
    type: 'select-async',
    entity: 'carrierCircuit',
    mandatory: true
  },
  type: {
    label: 'Type',
    type: 'select',
    mandatory: true
  },
  channels: {
    label: 'Channels',
    type: 'number',
    min: 1,
    mandatory: true
  },
  startChannel: {
    label: 'Start Channel',
    type: 'number',
    min: 1,
    mandatory: true
  },
  spacing: {
    label: 'Spacing',
    type: 'number',
    min: 0,
    mandatory: true
  },
  aEnd: {
    label: 'A End',
    type: 'select-async',
    entity: 'deviceDeployment',
    mandatory: true
  },
  bEnd: {
    label: 'B End',
    type: 'select-async',
    entity: 'deviceDeployment',
    mandatory: true
  },
}

export default form;