import { apiRequest, compareApiRequest } from '../Api/ApiRequest';

export function api_listQueues() {
  return apiRequest({
    url: '/queues',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_listCompareQueues() {
  return compareApiRequest({
    url: '/queues',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_listQueueFailedJobs(queue, page, size) {
  return apiRequest({
    url: `/queues/${queue}/failed?page=${page}&size=${size}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_listCompareQueueFailedJobs(queue, page, size) {
  return compareApiRequest({
    url: `/queues/${queue}/failed?page=${page}&size=${size}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_deleteQueueFailedJob(id, queue) {
  return apiRequest({
    url: `/queues/failed/${id}?queue=${queue}`,
    method: 'delete'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_deleteCompareQueueFailedJob(id, queue) {
  return compareApiRequest({
    url: `/queues/failed/${id}?queue=${queue}`,
    method: 'delete'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getQueueFailedJob(id) {
  return apiRequest({
    url: `/queues/failed/${id}?include=payload`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getCompareQueueFailedJob(id) {
  return compareApiRequest({
    url: `/queues/failed/${id}?include=payload`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_retryQueueFailedJob(id) {
  return apiRequest({
    url: `/queues/failed/${id}`,
    method: 'put'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_retryCompareQueueFailedJob(id) {
  return compareApiRequest({
    url: `/queues/failed/${id}`,
    method: 'put'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
