import { BREADCRUMBS_RESET_ITEMS, BREADCRUMBS_SET_ITEMS } from '../actions/breadcrumbs';

const initialState = {
  items: []
};

export const breadcrumbs = (state = initialState, action) => {
  switch (action.type) {
    case BREADCRUMBS_SET_ITEMS: {
      return {
        ...state,
        items: action.items
      }
    }
    case BREADCRUMBS_RESET_ITEMS:{
      return {
        ...state,
        items: []
      }
    }
    default:
      return state
  }
}
