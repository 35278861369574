import update from 'immutability-helper';
import { REMOVE_PROGRESS, UPDATE_PROGRESS } from '../actions/socketio';

const initialState = {
  progressBars: {}
};

export default function socketioProgress(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return update(state, {
        progressBars: { [action.channel]: { $set: action } }
      });
    case REMOVE_PROGRESS:
      let progress = { ...state.progressBars };
      delete progress[action.channel];
      return {
        ...state,
        progressBars: progress
      };
    default:
      return state;
  }
}
