import { apiRequest } from '../Api/ApiRequest';
import {queryStringFromFields} from "../Helpers/QueryString";

export function api_getUserLeads(user, search) {
  return apiRequest({
    url: `/users/${user}/leads${queryStringFromFields({search})}`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getLeads(search) {
  return apiRequest({
    url: `/leads${queryStringFromFields({search})}`,
    method: 'get',
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_getUserLead(userId, id) {
  return apiRequest({
    url: `/leads/${id}?include=network`,
    method: 'get'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_updateUserLead(userId, id, data) {
  return apiRequest({
    url: `/leads/${id}`,
    method: 'put',
    data
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_qualifyUserLead(userId, id) {
  return apiRequest({
    url: `/leads/${id}/qualify`,
    method: 'put'
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_disqualifyUserLead(userId, id, statusReason) {
  return apiRequest({
    url: `/leads/${id}/disqualify`,
    method: 'put',
    data: { statusReason }
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}

export function api_createUserLead(userId, data) {
  return apiRequest({
    url: `/leads`,
    method: 'post',
    data
  })
    .then(
      resp => resp
    )
    .catch(
      error => error
    );
}
