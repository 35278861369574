import { apiRequest } from '../Api/ApiRequest';

export function api_getSystemUsers() {
  return apiRequest({
    url: '/users',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
