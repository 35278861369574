import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import FilteredTable from '../../../../components/FilteredTable';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { getDomains } from '../../../../actions/ServiceDB/Ipam/domains';
import { canAccessServiceDb } from '../../../../utils/Auth/AuthService';

const Domains = ({ domains }) => {

  // router
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    dispatch(getDomains(['owner']))
      .then(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetch();
  }, []);

  const columns = [
    {
      filterable: true,
      sortable: true,
      Header: 'ID',
      accessor: 'id',
      width: 100
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Name',
      accessor: 'name'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Owner',
      accessor: 'owner.name'
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 50,
      Cell: props => (
        <Link to={'/sdb/ipam/domains/' + props.value}><i className="icon-arrow-right"/></Link>
      )
    }
  ];
  return (
    <Row>
      <Col className={'d-flex'}>
        <Card className="w-100">
          <CardBody>
            <FilteredTable
              columns={columns}
              data={domains}
              minRows={1}
              filterable={false}
              loading={loading}
              hideFilter={true}
              onRowClick={(row) => navigate(`/sdb/ipam/domains/${row.id}`)}
              extraButtons={[
                <ButtonIcon
                  icon="fa fa-refresh"
                  tooltip="Refresh"
                  onClick={() => fetch()}/>,
                canAccessServiceDb() ? (
                  <ButtonIcon
                    icon="fa fa-plus"
                    tooltip="Add New"
                    onClick={() => navigate('/sdb/ipam/domains/new')}/>
                ) : ''
              ]}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

function mapStateToProps({ ipamDomains, authenticationState }) {
  return {
    permissions: authenticationState.account.permissions,
    domains: ipamDomains.domains
  };
}

export default connect(mapStateToProps)(Domains);
