import { apiRequest } from '../../Api/ApiRequest';
import { queryStringFromFields } from '../../Helpers/QueryString';

export const AddressService = {
  list: (include = [], search = [], orderBy, sortedBy, searchJoin) => {
    const query = {
      orderBy,
      sortedBy,
      search,
      include,
      searchJoin
    };
    return apiRequest({
      method: 'get',
      url: `/sdb/ipam/addresses${queryStringFromFields(query)}`
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  get: (id, include = []) => {
    return apiRequest({
      url: `/sdb/ipam/addresses/${id}${queryStringFromFields({ include })}`,
      method: 'get'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  create: (data, include = []) => {
    return apiRequest({
      url: `/sdb/ipam/addresses${queryStringFromFields({ include })}`,
      method: 'post',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  update: (id, data, include = []) => {
    return apiRequest({
      url: `/sdb/ipam/addresses/${id}${queryStringFromFields({ include })}`,
      method: 'put',
      data
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
  delete: (id) => {
    return apiRequest({
      url: `/sdb/ipam/addresses/${id}`,
      method: 'delete'
    }).then(
      resp => resp
    ).catch(
      error => error
    );
  },
}
