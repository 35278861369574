import {
  api_createTask,
  api_createTaskNote,
  api_getTask,
  api_updateTask
} from '../utils/Task';
import { defaultErrorFeedback } from './feedback';
import AuditService from '../utils/Audit';

export const TASK_SET_DATA = 'TASK_SET_DATA';
export const TASK_SET_FIELD = 'TASK_SET_FIELD';
export const TASK_RESET = 'TASK_RESET';
export const TASK_ADD_NEW_NOTE = 'TASK_ADD_NEW_NOTE';
export const TASK_UPDATE_NOTE = 'TASK_UPDATE_NOTE';
export const TASK_REMOVE_NOTE = 'TASK_REMOVE_NOTE';
export const TASK_SET_AUDIT_HISTORY = 'TASK_SET_AUDIT_HISTORY';

function setTaskData(data) {
  return {
    type: TASK_SET_DATA,
    data
  };
}

export function updateTaskField(field) {
  return {
    type: TASK_SET_FIELD,
    field
  };
}

export function resetTask() {
  return {
    type: TASK_RESET
  };
}

export function addNewTaskNote(note) {
  return {
    type: TASK_ADD_NEW_NOTE,
    note
  };
}

export function updateTaskNote(note) {
  return {
    type: TASK_UPDATE_NOTE,
    note
  };
}

export function removeTaskNote(note) {
  return {
    type: TASK_REMOVE_NOTE,
    note
  };
}

export function getTaskData(id) {
  return (dispatch) => {
    return api_getTask(id)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setTaskData(result.data));
          return true;
        }
        dispatch(defaultErrorFeedback())
        return false;
      });
  };
}

export function createTask(data) {
  return (dispatch) => {
    return api_createTask(data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setTaskData(result.data));
          return result.data;
        }else if (result.status === 422) {
          return {errors: result.data}
        }
        dispatch(defaultErrorFeedback())
        return false;
      });
  };
}

export function updateTask(id, data) {
  return (dispatch) => {
    return api_updateTask(id, data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setTaskData(result.data));
          return result.data;
        }
        else if (result.status === 422) {
          return {errors: result.data}
        }
        dispatch(defaultErrorFeedback())
        return false;
      });
  };
}

export const createTaskNote = (data) => {
  return (dispatch) => {
    return api_createTaskNote(data)
      .then((result) => {
        if (result.status === 200) {
          dispatch(addNewTaskNote(result.data));
          return result.data;
        }
        dispatch(defaultErrorFeedback());
        return null;
      });
  };
};

export const getTaskAuditHistory = (id) => {
  return (dispatch) => {
    return AuditService.get('tasks', id).then((result)=>{
      if (result.status === 200) {
        dispatch({ type: TASK_SET_AUDIT_HISTORY, data: result.data });
      }else{
        dispatch(defaultErrorFeedback());
      }

    })
  }
};
