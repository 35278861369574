import React, { useEffect, useMemo, useState } from 'react'
import { getComplianceDevices } from '../../utils/Compliance/ComplianceService'
import { useNavigate } from 'react-router-dom'
import { SpinnerInfo } from '../../components/Spinner/Spinner'
import { Card, CardBody, Col, Row, Badge } from 'reactstrap'
import ReactTable from 'react-table-v6'
import moment from 'moment'

const Compliance = () => {

  // router
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    getComplianceDevices().then((result) => {
      if (result.status === 200) {
        setLoading(false)
        setData(result.data)
      }
    }).catch((error) => {
      alert(error)
    })

  }, [])

  const columns = useMemo(() => [
    {
      filterable: true,
      sortable: true,
      Header: 'Hostname',
      accessor: 'hostname'
    },
    {
      filterable: true,
      sortable: true,
      Header: 'IP Address',
      accessor: 'ipaddress'
    },
    {
      id: 'cpe',
      filterable: true,
      sortable: true,
      Header: 'CPE',
      accessor: (d) => {
        if (d.os_cpe !== null) {
          return d.os_cpe
        }
        return null
      },
      Cell: props => (
        <div style={{ whiteSpace: 'normal' }}>
          {props.value.map((v) => {
            return (<Badge>{v}</Badge>)
          })}
        </div>
      )
    },
    {
      id: 'date',
      filterable: false,
      sortable: true,
      Header: 'Modified',
      accessor: d => moment(d.date_modified).format()
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Open Ports',
      accessor: 'open_port_summary.ports_open'
    },
    {
      filterable: false,
      Header: 'Illegal Ports',
      accessor: 'open_port_summary.ports_illegal',
      getProps: (state, rowInfo, column) => {
        if (rowInfo === undefined) { return '' }
        return {
          className: rowInfo.row['open_port_summary.ports_illegal'] > 0 ? 'bg-danger' : null
        }
      }
    },
    {
      id: 'check_issue_count',
      filterable: false,
      Header: 'Check Issues',
      accessor: d => d.check_issue_count === undefined ? null : d.check_issue_count,
      getProps: (state, rowInfo, column) => {
        if (rowInfo === undefined) { return '' }
        return {
          className: rowInfo.row.check_issue_count > 0 ? 'bg-warning' : null
        }
      }
    }
  ], [])

  return (
    <div>
      {loading
        && <div className="row justify-content-center">
          <SpinnerInfo/>
        </div>
      }
      {!loading && data.length > 0
        && <Row>
          <Col className={'d-flex'}>
            <Card className="w-100">
              <CardBody>
                {!loading
                  && <ReactTable
                    getTheadProps={() => {
                      return {
                        style: { border: 'none' }
                      }
                    }}
                    getTheadTrProps={() => {
                      return {
                        style: { border: 'none' }
                      }
                    }}
                    getTheadThProps={() => {
                      return {
                        style: { border: 'none' }
                      }
                    }}
                    getTdProps={(state, rowInfo, column) => {
                      return {
                        style: { border: 'none', fontWeight: column.Header === 'Name' ? 'bold' : 'regular' }
                      }
                    }}
                    getTrProps={(state, rowInfo, column) => {
                      return {
                        style: { cursor: rowInfo ? 'pointer' : 'auto' },
                        onClick: () => {
                          navigate('/compliance/' + rowInfo.row.hostname)
                        }
                      }
                    }}
                    className="animated fadeIn -highlight -striped"
                    noDataText="No compliance hosts found."
                    data={data}
                    columns={columns}
                    minRows="1"
                    filterable={false}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                    defaultSorted={[{ id: 'open_port_summary.ports_illegal', desc: true }]}
                    showPagination={data.length > 20}
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>}
    </div>
  )
}

export default Compliance
