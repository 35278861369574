import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import generateFormFields from '../../../helpers/FormFieldGenerator';
import { Alert, Col, Row } from 'reactstrap';
import form from './form';
import LoadingOverlay from '../../../components/LoadingOverlay';
import moment from 'moment';
import { getSites } from '../../../actions/accounts';
import {
  deleteAvailabilityConfigItem,
  deleteAvailabilityIncident,
  deleteIncident,
  deleteService,
  downloadServiceAvailabilityReport,
  downloadServiceIncidentsReport,
  downloadServiceUtilisationReport,
  getServiceAvailabilityConfigItems,
  getServiceAvailabilityIncidents,
  getServiceIncidents,
  getServiceUtilisationServices, resetReports,
  setAvailabilityIncident,
  setIncident, setService
} from '../../../actions/accountReports';
import ServiceIncidents from './ServiceIncidents';
import isEmpty from 'lodash.isempty';
import ServiceUtilisation from './ServiceUtilisation';
import ServiceAvailability from './ServiceAvailability';
import { resolveState } from '../../../utils/Cases/CasesService';
import { ButtonIcon } from '../../../components/ButtonIcon';
import { getAccountSites } from '../../../actions/account';

const AccountReports = ({
  account,
  reportsData,
  optionSets,
  subjects
}) => {
  // redux
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    reportType: 'serviceIncidents',
    period: '',
    periodFrom: '',
    periodTo: ''
  });
  const [loaded, setLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [table, setTable] = useState('');
  const [errors, setErrors] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const FORMS_WITH_REQUIRED_PERIOD_FIELD = ['serviceIncidents', 'serviceAvailability'];

  useEffect(() => {
    if (isEmpty(account.sites) && account.original.accountid) {
      dispatch(getAccountSites(account.original.accountid, ['primaryContact']));
    }
  }, [account.original.accountid]);

  useEffect(() => {
    setSuccessMessage('');
    setErrors([]);
  }, [formData]);

  useEffect(() => {
    setLoaded(false);
    dispatch(resetReports());
    if (formData.reportType && !FORMS_WITH_REQUIRED_PERIOD_FIELD.includes(formData.reportType)) {
      fetch();
    }
  }, [formData.reportType]);

  useEffect(() => {
    if (formData.period && formData.period !== 'custom') {
      const { from, to } = resolvePeriod(formData.period);
      setFormData(prevState => ({
        ...prevState,
        periodFrom: from,
        periodTo: to
      }));
    } else if (!formData.period) {
      setFormData(prevState => ({
        ...prevState,
        periodFrom: '',
        periodTo: ''
      }));
    }
  }, [formData.period]);

  useEffect(() => {
    if (FORMS_WITH_REQUIRED_PERIOD_FIELD.includes(formData.reportType) && formData.periodFrom && formData.periodTo) {
      fetch();
    }
  }, [formData.reportType, formData.periodFrom, formData.periodTo]);

  const options = {
    reportType: [
      {
        value: 'serviceIncidents',
        label: 'Service Incidents'
      },
      {
        value: 'serviceUtilisation',
        label: 'Service Utilisation'
      },
      {
        value: 'serviceAvailability',
        label: 'Service Availability'
      }
    ],
    period: [
      {
        value: '',
        label: 'Select Period'
      },
      {
        value: 'thisMonth',
        label: 'This month'
      },
      {
        value: 'prevMonth',
        label: 'Last month'
      },
      {
        value: 'thisQuarter',
        label: 'This quarter'
      },
      {
        value: 'prevQuarter',
        label: 'Last quarter'
      },
      {
        value: 'custom',
        label: 'Custom Range'
      }
    ]
  };

  const getSelectOptions = (key) => options[key] || [];

  const getSelectedOption = (key) => options[key] ? options[key].find(option => option.value === formData[key]) : [];

  const handleSelectInput = (key, selected) => {
    setFormData(prevState => {
      return {
        ...prevState,
        [key]: selected.value
      };
    });
  };

  const handleInputDate = (key, value) => {
    setFormData(prevState => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const validated = () => {
    let errorArr = [];
    if (formData.period === 'custom' && moment(formData.periodFrom)
      .startOf('day')
      .isSameOrAfter(moment(formData.periodTo).endOf('day'))) {
      errorArr.push('The From field must be before the To field');
    }
    setErrors(errorArr);
    return errorArr.length === 0;

  };

  const resolvePeriod = (period) => {
    let from,
      to;
    switch (period) {
      case 'thisMonth':
        from = moment().startOf('month');
        to = moment();
        break;
      case 'prevMonth':
        from = moment().subtract(1, 'month').startOf('month');
        to = moment().subtract(1, 'month').endOf('month');
        break;
      case 'thisQuarter':
        from = moment().startOf('quarter');
        to = moment();
        break;
      case 'prevQuarter':
        from = moment().subtract(1, 'quarter').startOf('quarter');
        to = moment().subtract(1, 'quarter').endOf('quarter');
        break;
      case 'custom':
        from = moment(formData.periodFrom);
        to = moment(formData.periodTo);
        break;
      default:
        return { from: '', to: '' };
    }
    return { from: from.format('YYYY-MM-DD HH:mm:ss'), to: to.format('YYYY-MM-DD HH:mm:ss') };
  };

  const fetch = () => {
    if (fetching) {
      return;
    }
    setFetching(true);
    if (validated()) {
      const { reportType, periodFrom, periodTo } = formData;
      let getTableData;
      switch (reportType) {
        case 'serviceIncidents':
          setTable('serviceIncidents');
          getTableData = (accountId, from, to) => getServiceIncidents(accountId, from, to, subjects);
          break;
        case 'serviceUtilisation':
          setTable('serviceUtilisation');
          getTableData = (accountId) => getServiceUtilisationServices(accountId);
          break;
        case 'serviceAvailability':
          setTable('serviceAvailability');
          getTableData = (accountId, from, to) => getServiceAvailabilityIncidents(accountId, from, to, subjects);
          break;
        default:
          setTable('notImplemented');
          return;
      }
      const from = moment(periodFrom).format('YYYY-MM-DD H:mm:ss');
      const to = moment(periodTo).format('YYYY-MM-DD H:mm:ss');
      dispatch(getTableData(account.original.accountid, from, to)).then(() => {
        if (reportType === 'serviceAvailability') {
          dispatch(getServiceAvailabilityConfigItems(account.original.accountid)).then(() => {
            setFetching(false);
            setLoaded(true);
          });
        } else {
          setFetching(false);
          setLoaded(true);
        }
      });
    } else {
      setTable('');
    }
  };

  const download = {
    serviceIncidents: (from, to) => {
      const formattedData = reportsData.serviceIncidents.incidents.map(({
        createdOn,
        closedOn,
        caseNumber,
        customer,
        title,
        subject,
        downtime,
        priority,
        status,
        type,
        state
      }) => {
        return {
          createdOn,
          closedOn,
          caseNumber,
          customer: customer.name,
          title,
          subject: subject.name,
          priority: optionSets.priority.options.find(optionSet => optionSet.value === Number(priority))?.label || '',
          downtime,
          status: optionSets.status.options.find(optionSet => optionSet.value === Number(status)).label,
          type: optionSets.type.options.find(optionSet => optionSet.value === Number(type))?.label,
          state: resolveState(state).label
        };
      });
      return dispatch(downloadServiceIncidentsReport(account.original.accountid, from, to, formattedData));
    },
    serviceUtilisation: (from, to) => {
      return dispatch(downloadServiceUtilisationReport(account.original.accountid, from, to, reportsData.serviceUtilisation.services))
        .then(result => {
          setSuccessMessage('Your report is being generated and will be e-mailed to you shortly.')
          return result;
        });
    },
    serviceAvailability: (from, to) => {
      const formattedData = {
        incidents: reportsData.serviceAvailability.incidents.map(({
          caseNumber,
          customer,
          title,
          createdOn,
          priority,
          totalElapsedTime,
          totalhSoAndSupplierElapsedTime,
          totalCustomerElapsedTime,
          state,
          faultyDeviceId
        }) => ({
          caseNumber,
          customer: customer.name,
          title,
          createdOn,
          priority: optionSets.priority.options.find(optionSet => optionSet.value === Number(priority))?.label || '',
          totalElapsedTime,
          totalhSoAndSupplierElapsedTime,
          totalCustomerElapsedTime,
          state: resolveState(state).label,
          faultyDeviceId
        })),
        configItems: reportsData.serviceAvailability.configItems.map(({
          id,
          name,
          assignedTo
        }) => ({ id, name, site: assignedTo.name }))
      };
      return dispatch(downloadServiceAvailabilityReport(account.original.accountid, from, to, formattedData.incidents, formattedData.configItems));
    }
  };

  const showPeriodField = () => {
    if (formData.reportType === 'serviceUtilisation') {
      return !!reportsData.serviceUtilisation.services.length;
    }
    return true;
  };

  const generateAndDownload = () => {
    setGenerating(true);

    const from = moment(formData.periodFrom).format('YYYY-MM-DD H:mm:ss');
    const to = moment(formData.periodTo).format('YYYY-MM-DD H:mm:ss');

    download[table](from, to).then(() => {
      setGenerating(false);
    });
  };

  return (
    <div className="animated fadeIn p-1">
      {errors.length > 0 ? (
        <Row>
          <Col>
            <Alert color={'danger'}>
              {errors.map((error, index) => {
                return <p className="m-0" key={'error' + index}>{error}</p>;
              })}
            </Alert>
          </Col>
        </Row>
      ) : ''}
      {successMessage ? (
        <Row>
          <Col>
            <Alert color={'success'}>
              {successMessage}
            </Alert>
          </Col>
        </Row>
      ) : ''}
      <Row>
        <Col md={10}>
          <Row form>
            {generateFormFields({
              data: { ...formData, showPeriodField: showPeriodField() },
              fields: form,
              getSelectOptions,
              getSelectedOption,
              handleSelectInput,
              handleInputDate,
              colWidth: 3
            })}
          </Row>
        </Col>
        <Col md={2}>
          <div className={'d-flex justify-content-end'} style={{ marginTop: 34 }}>
            {loaded && formData.periodFrom && formData.periodTo ? (
              <ButtonIcon
                size="sm"
                icon="fa fa-download"
                tooltip="Generate and download report"
                loading={generating}
                onClick={generateAndDownload}
              />
            ) : ''}
          </div>
        </Col>
      </Row>
      {table ? (
        <div className="mt-4" style={{ minHeight: 150 }}>
          <LoadingOverlay loading={fetching} text="Creating...">
            {!fetching && loaded ? (
              <>
                {table === 'serviceIncidents' ? (
                  <ServiceIncidents data={reportsData.serviceIncidents.incidents}
                                    remove={(caseNumber) => dispatch(deleteIncident(caseNumber))}
                                    modify={incident => dispatch(setIncident(incident))}
                                    optionSets={optionSets}
                                    subjects={subjects}
                                    sites={account.sites}/>
                ) : ''}
                {table === 'serviceUtilisation' ? (
                  <ServiceUtilisation data={reportsData.serviceUtilisation.services}
                                      remove={(id) => dispatch(deleteService(id))}
                                      modify={service => dispatch(setService(service))}/>
                ) : ''}
                {table === 'serviceAvailability' ? (
                  <ServiceAvailability data={reportsData.serviceAvailability.incidents}
                                       configItems={reportsData.serviceAvailability.configItems}
                                       periodFrom={formData.periodFrom}
                                       periodTo={formData.periodTo}
                                       removeIncident={(caseNumber) => dispatch(deleteAvailabilityIncident(caseNumber))}
                                       modifyIncident={incident => dispatch(setAvailabilityIncident(incident))}
                                       removeConfigItem={(id) => dispatch(deleteAvailabilityConfigItem(id))}
                                       optionSets={optionSets}
                                       subjects={subjects}
                                       sites={account.sites}
                  />
                ) : ''}
                {table === 'notImplemented' ? (
                  <Alert color={'warning'}>This hasn't yet been implemented. Please check back
                    again
                    soon.</Alert>
                ) : ''}
              </>
            ) : ''}
          </LoadingOverlay>
        </div>
      ) : ''}
    </div>
  );

};

const mapStateToProps = ({
  account,
  accountReports,
  helpers
}) => {
  return {
    account,
    optionSets: helpers.optionSets.case,
    subjects: helpers.subjects,
    reportsData: {
      serviceIncidents: accountReports.serviceIncidents,
      serviceUtilisation: accountReports.serviceUtilisation,
      serviceAvailability: accountReports.serviceAvailability,
    }
  };
};
export default connect(mapStateToProps)(AccountReports);
