import update from 'immutability-helper';
import moment from 'moment';

import {
  SHADOWSERVER_SET_DATA,
  SHADOWSERVER_SET_DATE,
  SHADOWSERVER_SET_LAST_LOADED,
  SHADOWSERVER_SET_LOADING,
  SHADOWSERVER_SET_LOADED
} from "../actions/shadowServer";

const initialState = {
  loading: true,
  latestDate: null,
  data: [],
  lastLoaded: moment([2000, 0, 1])
};

export default function shadowServer(state = initialState, action) {
  switch (action.type) {
    case SHADOWSERVER_SET_DATE:
      return update(state, { latestDate: { $set: action.date } })
    case SHADOWSERVER_SET_LOADING:
      return update(state, { loading: { $set: true } });
    case SHADOWSERVER_SET_LOADED:
      return update(state, { loading: { $set: false } });
    case SHADOWSERVER_SET_DATA:
      return update(state, { data: { $set: action.data }});
    case SHADOWSERVER_SET_LAST_LOADED:
      return update(state, { lastLoaded: { $set: action.date } })
    default:
      return state;
  }
}
