import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Alert, Button } from 'reactstrap';
import { BILLING_NEW_LINE_ITEMS_SET_ITEMS, BILLING_NEW_LINE_ITEMS_SET_LOADING, getBillingNewLineItems } from '../../actions/billingNewLineItems';
import ReactTable from 'react-table-v6';
import numeral from 'numeral';
import * as moment from 'moment';
import fileDownload from 'js-file-download';
import { api_downloadNewLineItems } from '../../utils/Billing/LineItems';
import BillingWipTable from '../../components/Tables/BillingWip';
import LoadingOverlay from '../../components/LoadingOverlay';

class NewLineItems extends React.Component {
  componentDidMount() {
    const { items } = this.props.items;

    if (items.length === 0) {
      this.getItems();
    }
  }

    getItems = () => {
      this.props.dispatch({ type: BILLING_NEW_LINE_ITEMS_SET_LOADING, loading: true });
      this.props.dispatch({ type: BILLING_NEW_LINE_ITEMS_SET_ITEMS, items: [] });
      this.props.dispatch(getBillingNewLineItems()).then(() => {
        this.props.dispatch({ type: BILLING_NEW_LINE_ITEMS_SET_LOADING, loading: false });
      });
    }

    download = () => {
      this.props.dispatch({ type: BILLING_NEW_LINE_ITEMS_SET_LOADING, loading: true });
      api_downloadNewLineItems().then(
        (result) => {
          if (result.status === 200) {
            let splitContentDisposition = result.headers['content-disposition'].split('=');
            fileDownload(result.data, splitContentDisposition[1]);
          }
          this.props.dispatch({ type: BILLING_NEW_LINE_ITEMS_SET_LOADING, loading: false });
        }
      );
    }

    render() {
      const { items, loading } = this.props.items;
      return (

                <LoadingOverlay loading={loading}>
                  <Row>
                    <Col>
                      <BillingWipTable items={items} fetchData={this.getItems}/>
                    </Col>

                  </Row>
                </LoadingOverlay>

      );
    }
}

const LineItemsTable = ({ items }) => {
  const columns = [
    {
      Header: 'Account',
      accessor: 'account',
      minWidth: 150,
      Cell: props => (
                <a href={props.original.accountUrl} target="_blank" className="text-primary text-wrap">{props.value} {!props.original.billCheck ? <i className='fa fa-exclamation-circle text-danger'/> : ""}</a>
      )
    },
    {
      Header: 'Account Type',
      accessor: 'accountType'
    }, {
      Header: 'Order Number',
      accessor: 'orderNumber',
      minWidth: 120,
      Cell: props => (
                <a href={props.original.orderUrl} target="_blank" className="text-primary text-wrap">{props.value}</a>
      )
    }, {
      Header: 'Line Item',
      accessor: 'lineItem',
      minWidth: 300,
      Cell: props => (
                <a href={props.original.lineItemUrl} target="_blank" className="text-primary text-wrap">{props.value}</a>
      )
    }, {
      Header: 'Charge Type',
      accessor: 'typeOfCharge'
    }, {
      Header: 'Charge',
      accessor: 'currentCost',
      Cell: props => (
        '£' + numeral(props.value).format('0,0.00')
      )
    }, {
      Header: 'Billing Cycle',
      accessor: 'billingCycle'
    }, {
      Header: 'Go Live Date',
      id: 'goLiveDate',
      accessor: row => moment(row.goLiveDate, 'DD/MM/YYYY').unix(),
      Cell: props => (
        props.original.goLiveDate)

    }
  ];

  return (
        <ReactTable
            className="animated fadeIn -highlight -striped"
            NoDataComponent={() => null}
            data={items}
            columns={columns}
            minRows="2"
            filterable={true}
            resizable={false}
            defaultPageSize={20}
            getTheadProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadTrProps={() =>{
              return {
                style: { border: 'none' }
              };
            }}
            getTheadThProps={() =>{
              return {
                style: { border: 'none', whiteSpace: 'unset' }
              };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                style: { whiteSpace: 'unset', fontSize: '10pt' }
              };
            }}
            sortable={true}
            defaultSorted={[{ id: 'goLiveDate' }]}
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
        />
  );
};

function mapStateToProps({ billingNewLineItems }) {
  return {
    items: billingNewLineItems
  };
}
export default connect(mapStateToProps)(NewLineItems);
