import React from 'react';
import { Routes, Route, Link, useLocation, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import routes from '../../routes';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';

const findRouteName = (url) => routes[url];

const getPaths = (pathname) => {
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr, index) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const BreadcrumbsItem = ({ path }) => {
  const { pathname } = useLocation()
  const routeName = findRouteName(path);
  return (
    routeName ? (
      path === pathname ? (
        <BreadcrumbItem active>{routeName}</BreadcrumbItem>
      ) : (
        <BreadcrumbItem>
          <Link to={path}>
            {routeName}
          </Link>
        </BreadcrumbItem>
      )
    ) : null
  );
};

const Breadcrumbs = ({ breadcrumbs }) => {
  const { pathname } = useLocation();
  const paths = getPaths(pathname);
  const items = paths.map((path, i) => <BreadcrumbsItem key={i} path={path} />);

  if (!isEmpty(breadcrumbs.items)) {
    breadcrumbs.items.forEach((item, index) => {
      if (item.path) {
        items.push(
          <BreadcrumbItem key={paths.length + index}>
            <Link to={item.path}>
              {item.name}
            </Link>
          </BreadcrumbItem>
        );
      } else {
        items.push(
          <BreadcrumbItem key={paths.length + index} active>
            {item.name}
          </BreadcrumbItem>
        );
      }
    });
  }

  return <Breadcrumb>{items}</Breadcrumb>;
};

const mapStateToProps = ({ breadcrumbs }) => ({
  breadcrumbs,
});

const ConnectedBreadcrumbs = connect(mapStateToProps)(Breadcrumbs);

const BreadcrumbsWrapper = (props) => {
  const { path } = useParams();
  return <ConnectedBreadcrumbs path={path} {...props} />;
};

export default (props) => (
  <div>
    <Routes>
      <Route path="*" element={<BreadcrumbsWrapper {...props} />} />
    </Routes>
  </div>
);
