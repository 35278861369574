import { Button } from 'reactstrap';
import React from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

export const AddNewButton = (addNew, size = 'sm', classes = 'ml-2') => {
  if (typeof addNew === 'string') {
    return (
      <Link to={addNew}>
        <Button className={classes} size={size} color={'info'} onClick={() => {}}><i
          className="fa fa-plus"/></Button>
      </Link>
    );
  }
  return <Button className={classes} size={size} color={'info'} onClick={addNew}><i
    className="fa fa-plus"/></Button>;
};

export const RefreshButton = (refresh, size = 'sm', classes = 'ml-2') => (
  <Button className={classes} size={size} color={'warning'} onClick={refresh}><i
    className="fa fa-refresh"/></Button>
);

export const ExportButton = (fileName, exportData, size = 'sm', classes = 'ml-2') => (
  <CSVLink className={`btn-${size} btn btn-primary ${classes}`} data={exportData()} filename={fileName}
           style={{ textDecoration: 'none' }}>
    <i className="fa fa-download"></i>
  </CSVLink>
);

export default (props) => {
  let result = [];
  const size = props.size ? props.size : 'sm';
  const classes = props.classes
    ? props.classes : 'ml-2';

  if (props.others) {
    result.push(...props.others);
  }
  if (props.addNew) {
    result.push(AddNewButton(props.addNew, size, classes));
  }
  if (props.export && props.export.filename && props.export.data) {
    result.push(ExportButton(props.export.filename, props.export.data, size, classes));
  }
  if (props.refresh) {
    result.push(RefreshButton(props.refresh, size, classes));
  }
  return result;
};
