import {
  IPAM_BLOCK_ADD_SUBNET,
  IPAM_BLOCK_REMOVE_SUBNET,
  IPAM_BLOCK_RESET_DATA,
  IPAM_BLOCK_SET_DATA,
  IPAM_BLOCK_SET_FIELD,
  IPAM_BLOCK_UPDATE_SUBNET
} from '../../../actions/ServiceDB/Ipam/block';

const initialState = {
  data: {
    subnets: []
  },
  original: {
    subnets: []
  }
};
const block = (state = initialState, action) => {
  switch (action.type) {
    case IPAM_BLOCK_SET_DATA:
      return {
        ...state,
        data: action.data,
        original: action.data
      };
    case IPAM_BLOCK_SET_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value
        }
      };
    case IPAM_BLOCK_RESET_DATA:
      return {
        ...initialState
      };
    case IPAM_BLOCK_ADD_SUBNET:
      return {
        ...state,
        data: { ...state.data, subnets: [...state.data.subnets, action.subnetData] },
        original: { ...state.original, subnets: [...state.original.subnets, action.subnetData] }
      };
    case IPAM_BLOCK_UPDATE_SUBNET:
      const updatedSubnetsWithUpdatedSubnet = state.data.subnets.map((subnet) => subnet.id === action.subnetData.id ? action.subnetData : subnet);
      return {
        ...state,
        data: { ...state.data, subnets: updatedSubnetsWithUpdatedSubnet },
        original: { ...state.original, subnets: updatedSubnetsWithUpdatedSubnet }
      };
    case IPAM_BLOCK_REMOVE_SUBNET:
      const updatedSubnetsWithoutDelete = state.data.subnets.filter((subnet) => subnet.id !== action.subnetId);
      return {
        ...state,
        data: { ...state.data, subnets: updatedSubnetsWithoutDelete },
        original: { ...state.original, subnets: updatedSubnetsWithoutDelete }
      };
    default:
      return state;
  }
};

export default block;