import { apiRequest } from '../Api/ApiRequest';

export function api_searchOrders(searchString) {
  return apiRequest({
    url: '/orders/search?orderNumber=' + searchString,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_searchAccountOrders(accountId, searchString) {
  return apiRequest({
    url: `/accounts/${accountId}/orders/search?orderNumber=${searchString}`,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
