import { createStore } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import middleware, { socketIOClient, sagaMiddleware } from './middleware';
import rootReducer from './reducers';
import { authenticateSocket, joinRooms } from './actions/socketio';
import { addJTISubs } from './actions/JTI';
import {migrations} from "./reduxmigrations";
import sagaFetchServingExchange from "./sagas/discovery";
import sagaGetAlarms from "./sagas/alarms";
import { rootSaga } from './sagas';
import { getAPIToken, isLoggedIn } from './utils/Auth/AuthService';
import { composeWithDevTools } from '@redux-devtools/extension'

export const persistConfig = {
  whitelist: ['helpers', 'authenticationState', 'shifts', 'appVersion', 'configTemplate', 'priceLists', 'app'],
  key: 'root',
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
  storage
};


const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {

  let store = createStore(persistedReducer, composeWithDevTools(middleware)) // ignore deprecation -> but we must migrate to toolkit
  let persistor = persistStore(store);

  socketIOClient.on('disconnect', (reason) => {
    store.dispatch({ type: 'SOCKET_DISCONNECT' });
    if (reason === 'io server disconnect') {
      socketIOClient.connect();
    } else {
      store.dispatch({ type: 'TOAST', disablePush: true, msgtype: 'error', title: 'Connection to socketio lost: ' + reason });
    }
  });

  socketIOClient.on('reconnect', (attempt) => {
    store.dispatch({ type: 'SOCKET_CONNECT' });
    store.dispatch({ type: 'TOAST', disablePush: true, title: 'Reconnected to socketio on attempt: ' + attempt });
    if (isLoggedIn()) {
      getAPIToken().then(token => {
        store.dispatch(authenticateSocket({ jwt: token}));
      })

      let st = store.getState();
      store.dispatch(joinRooms({ rooms: st.authenticationState.account.permissions }));
      if (Object.keys(st.JTI.subs).length > 0) {
        store.dispatch(addJTISubs(st.JTI.subs));
      }
    }
  });

  socketIOClient.on('connect', () => {
    store.dispatch( {type: 'authenticated'})
  });


  sagaMiddleware.run(sagaFetchServingExchange);
  sagaMiddleware.run(sagaGetAlarms);
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};
