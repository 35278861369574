import isEmpty from 'lodash.isempty';

export function queryStringFromFields(fields = {}, prefix) {
  const buildQuery = (fields = {}, prefix) => {
    const query = Object.keys(fields).filter((k) => fields[k] !== undefined).map((k) => {
      let key = k;
      let value = fields[key];

      if (!value && (value === null || value === undefined || isNaN(value))) {
        value = '';
      }
      if (value === true || value === false) {
        value = Number(value);
      }

      if (fields.constructor === Array) {
        key = `${prefix}[${key}]`;
      } else if (fields.constructor === Object) {
        key = (prefix ? `${prefix}[${key}]` : key);
      }

      if (typeof value === 'object') {
        return buildQuery(value, key); // for nested objects
      }

      return `${key}=${encodeURIComponent(value)}`;
    })
      .filter(value => !isEmpty(value));

    return query.join('&');
  }

  const query = buildQuery(fields, prefix);

  return query ? `?${query}` : '';
}
