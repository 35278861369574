import React, { useEffect, useState } from 'react';
import {  Card, CardBody } from 'reactstrap';
import {
  getDeviceDeployments,
  getDeviceDeploymentsZabbixData
} from '../../../actions/deviceDeployments';
import { connect, useDispatch } from 'react-redux'
import debounce from 'debounce-promise';
import isEmpty from 'lodash.isempty';
import LoadingOverlay from '../../../components/LoadingOverlay';
import DeviceDeploymentsTable from '../../../components/Tables/DeviceDeployments';


const include = 'items.stockItem,items.stockItem.deviceModel,items.stockItem.deviceModel.vendor';
const withs = 'stockItem;stockItem.deviceModel;stockItem.deviceModel.vendor';

const DeviceDeployments = ({
  deviceDeployments,
  deploymentTypes,
  table
}) => {
  // redux
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [zabbixDataFetched, setZabbixDataFetched] = useState(false);

  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }

  useEffect(() => {
    if(isEmpty(deviceDeployments)){
      fetch(page, pageSize, '', [{id: 'hostname', desc: true}]);
    }

  }, []);

  const fetch = (page, size, searchString, sorting) => {
    toggleLoading()
    setZabbixDataFetched(false);
    dispatch(getDeviceDeployments(page, size, searchString, sorting, include, withs))
      .then(() => toggleLoading());
  };


  const debounced = debounce((page, size, searchString, sorting) => fetch(page, size, searchString, sorting), 500);


  useEffect(() => {
    if (!zabbixDataFetched && !isEmpty(deviceDeployments) && deviceDeployments.find(deviceDeployment => isEmpty(deviceDeployment.zabbix))) {
      dispatch(getDeviceDeploymentsZabbixData(deviceDeployments.map(deviceDeployment => deviceDeployment.hostname)))
        .then(setZabbixDataFetched(true));
    }
  }, [deviceDeployments]);

  return (
    <Card className="w-100">
      <CardBody>
        <LoadingOverlay loading={loading}>
          <DeviceDeploymentsTable
            deployments={deviceDeployments}
            fetchData={(page, size, searchString, sorting) => debounced(page, size, searchString, sorting)}
            table={table}
            deploymentTypes={deploymentTypes}
          />
        </LoadingOverlay>
      </CardBody>
    </Card>
  );
};

function mapStateToProps({ deviceDeployments, authenticationState, helpers }) {
  return {
    deploymentTypes: helpers.optionSets.deviceDeployment.type,
    permissions: authenticationState.account.permissions,
    deviceDeployments: deviceDeployments.deviceDeployments,
    table: deviceDeployments.table,
  };
}

export default connect(mapStateToProps)(DeviceDeployments);
