import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import LoadingOverlay from '../../../components/LoadingOverlay';
import OrdersTable from '../../../components/Tables/Orders';
import { getOrders, resetOrders } from '../../../actions/orders';

const ServiceDeliveryOrders = (
  {
    user,
    dispatch
  }
) => {
  const [loading, setLoading] = useState(false)

  const toggleLoading = () => setLoading(prevState => !prevState)

  useEffect(() => {
    getData()
  }, [])
  const getData = () => {
    dispatch(resetOrders())
    toggleLoading()
    dispatch(getOrders({
      template: 'ServiceDeliveryTeamOrdersInDelivery',
      includes: ['company', 'owner'].join(',')
    }))
      .then(() => toggleLoading())
  }

  return (

    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <Row>
              <Col>
                <OrdersTable
                  defaultUserFilter={{id: user.cui, name: `${user.first_name} ${user.last_name}`}}
                  fetchData={getData}
                  includeColumns={['orderNumber', 'name', 'company', 'owner', 'redFileReceivedDate', 'nextCustomerUpdate']}
                  defaultSorted={[{
                    id: 'nextCustomerUpdate',
                    desc: false
                  }]}
                />
              </Col>
            </Row>
          </LoadingOverlay>
        </CardBody>
      </Card>
    </div>
  )

}

function mapStateToProps({ helpers, authenticationState }) {
  return {
    user: authenticationState.account,
    optionSets: helpers.optionSets.order,
  };
}
export default connect(mapStateToProps)(ServiceDeliveryOrders);