import { apiRequest } from '../Api/ApiRequest';

export function api_createDirectDebitMandate(mandate) {
  return apiRequest({
    url: '/billing/direct-debits',
    method: 'post',
    data: { ...mandate }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
export function api_getDirectDebitByMandateRef(mandateRef) {
  return apiRequest({
    url: '/billing/direct-debits/' + mandateRef,
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_deactivateDirectDebit(mandateIdOrRef) {
  return apiRequest({
    url: '/billing/direct-debits/' + mandateIdOrRef,
    method: 'delete'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_searchDirectDebitMandates(args) {
  return apiRequest({
    url: '/billing/direct-debits/search',
    method: 'post',
    data: { ...args }
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_getDirectDebitMandates() {
  return apiRequest({
    url: '/billing/direct-debits',
    method: 'get'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}

export function api_generateAuddisReport(fromDate, toDate) {
  return apiRequest({
    url: '/billing/direct-debits/auddis?' + 'fromDate=' + fromDate + '&toDate=' + toDate,
    method: 'get',
    responseType: 'blob'
  }).then(
    resp => resp
  ).catch(
    error => error
  );
}
