import { defaultErrorFeedback } from './feedback';
import { GlobalSearchService } from '../utils/GlobalSearch';

export const GLOBAL_SEARCH_SET_LIST = 'GLOBAL_SEARCH_SET_LIST'
export const GLOBAL_SEARCH_SET_PATTERN = 'GLOBAL_SEARCH_SET_PATTERN'


function setList(list){
  return {
    type: GLOBAL_SEARCH_SET_LIST,
    list
  }
}


export const setGlobalSearchPattern = (pattern) => {
  return {
    type: GLOBAL_SEARCH_SET_PATTERN,
    pattern
  }
}

export const searchIndex = (pattern) => {
  return (dispatch) => {
    return GlobalSearchService.search(pattern)
      .then((result) => {
        if (result.status === 200) {
          dispatch(setList(result.data));
        } else {
          dispatch(defaultErrorFeedback());
        }
      });
  };
};
