import { CONNECTIONS_SET_CONNECTIONS } from '../actions/connections';

const initialState = {
  connections: [],
  table: {
    lastPage: 1
  }
};

export default function connections(state = initialState, action) {
  switch (action.type) {
    case CONNECTIONS_SET_CONNECTIONS:
      const { currentPage, total, perPage, path, lastPage, items } = action.data;
      return {
        connections: [...items],
        table: {currentPage, total, perPage, path, lastPage}
      }
    default:
      return state;
  }
}